<template>
    <v-app>
      <div class="header-div">
        <h3 class="header-h3">Geçmiş Yıl Zararları Veri Girişi</h3>
      </div>
      <table-component :dynamic-element="tableElements" :model="model" />    
      <Loader></Loader>
      <snackbar-component />
    </v-app>
</template>

<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import XLSX from "xlsx";
import {
  openSnackbar,
  showLoader,
  openTablePage,
  getTable,
  deleteItem,
  closeDialog,
  excelDownloadDefault,
  //getTableModel,
  //showDialog,
  hideLoader,
    updateItem,
  saveItem
  //hashCode
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";
import {axiosUploadTypeArrayBuffer, checkProject} from "../../helper/uploadHelperForData"

export default {
    components: {
    "table-component": tableComponent,
    "upload-component": uploadComponent,
    Loader: Loader,
    "snackbar-component": snackbarComponent,
    },
    data() {
        return {
          tableElements: {},
          model: {},
          renderKey: 0,
        };
    },
    created() {
      checkProject();
      this.loadData();
    },
    watch: {},
    methods: {
        loadData() {
          let that = this
            this.tableElements = {
            sortBy: [],
            sortDesc: false,
            renderKey: 0,
            type: "table",
            tableHeight : 500,
            name: "financialLossUpdateTable",
            class: "",
            infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
            excelDownloadBtnText: "İndir",
            deleteDialogVisible : true,
            hidePagination: true,
            filteredColumn: {},
            filteredColumns: true,
            selectionUrl: "financialLossUpdate",
            initialFilter:{
            [Enum.initialFilter.AND] :
              [
                {
                  projectId: {
                    [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id
                  }
                }
              ]
            },
            toolbars: [
              {
                type: Enum.layoutType.div,
                name: "toolbarsForm",
                inputElements: [],
            }
              ],
              headers: [
                { text: "Yıllar", value: "financialLossYears", width: "100" , divider: true,},
                { text: "Ticari Zararlar", value: `tradingLoss`, width: "100", dataType: "currency", divider: true, align: "right"},
                { text: "Mali Zararlar", value: "annualReport", width: "100", dataType: "currency", divider: true, align: "right"},
                { text: "İtfa Yılları", value: "redemptionYears", width: "100", divider: true, align: "center"},
                { text: "Matrah Arttırımı İle %50 Silinen Zarar", value: "fiftyPercent", width: "100", dataType: "currency", divider: true, align: "right"},
                { text: "Cari Dönem Mahsup Edilen Zararlar", value: "offsetCurrent", width: "100", dataType: "currency", divider: true, align: "right"},
                { text: "Devreden Zararlar", value: "accumulatedLosses", width: "100", dataType: "currency", divider: true, align: "right"},
              ],
              columns: {
                tradingLoss: {
                    type: "v-text-field",
                    editable: true,
                    creatable: false,
                    width: "200",
                    inlineEditable: true,
                    label: "Değer",
                    onSave: function(thisData, model, dynamicElements) {
                      showLoader("Dosyanız Yükleniyor. Lütfen Bekleyiniz.");
                      sendRequest(Enum.requestType.POST,"updateProjectAttachmentValue","",
                      {
                        updateObj: {
                          value: model.tradingLoss,
                          sectionId: model.sectionId,
                          definitionId: model.tradingLoss_definitionId,
                          cookieData: window.$cookies.get("selectedItem")
                        }
                      },
                      function (response) {
                        openTablePage("financialLossUpdateTable")
                      },
                      function (error) {
                        openSnackbar("error", error.response.data.error[0].detail.message);
                        hideLoader();
                      }
                    )
                  },
                },
                annualReport: {
                    type: "v-text-field",
                    editable: true,
                    creatable: false,
                    width: "200",
                    inlineEditable: true,
                    label: "Değer",
                    onSave: function(thisData, model, dynamicElements) {
                      showLoader("Dosyanız Yükleniyor. Lütfen Bekleyiniz.");
                      sendRequest(Enum.requestType.POST,"updateProjectAttachmentValue","",
                      {
                        updateObj: {
                          value: model.annualReport,
                          sectionId: model.sectionId,
                          definitionId: model.annualReport_definitionId,
                          cookieData: window.$cookies.get("selectedItem")
                        }
                      },
                      function (response) {
                        openTablePage("financialLossUpdateTable")
                      },
                      function (error) {
                        openSnackbar(
                          "error",
                          error.response.data.error[0].detail.message
                        );
                        hideLoader();
                      }
                    )
                  },
                },
                redemptionYears: {
                    type: "v-text-field",
                    editable: true,
                    creatable: false,
                    width: "200",
                    inlineEditable: true,
                    label: "Değer",
                    onSave: function(thisData, model, dynamicElements) {
                      showLoader("Dosyanız Yükleniyor. Lütfen Bekleyiniz.");
                      sendRequest(Enum.requestType.POST,"updateProjectAttachmentValue","",
                      {
                        updateObj: {
                          value: model.redemptionYears,
                          sectionId: model.sectionId,
                          definitionId: model.redemptionYears_definitionId,
                          cookieData: window.$cookies.get("selectedItem")
                        }
                      },
                      function (response) {
                        openTablePage("financialLossUpdateTable")
                      },
                      function (error) {
                        openSnackbar(
                          "error",
                          error.response.data.error[0].detail.message
                        );
                        hideLoader();
                      }
                    )
                  },
                },
                fiftyPercent: {
                    type: "v-text-field",
                    editable: true,
                    creatable: false,
                    width: "200",
                    inlineEditable: true,
                    label: "Değer",
                    onSave: function(thisData, model, dynamicElements) {
                      showLoader("Dosyanız Yükleniyor. Lütfen Bekleyiniz.");
                      sendRequest(Enum.requestType.POST,"updateProjectAttachmentValue","",
                      {
                        updateObj: {
                          value: model.fiftyPercent,
                          sectionId: model.sectionId,
                          definitionId: model.fiftyPercent_definitionId,
                          cookieData: window.$cookies.get("selectedItem")
                        }
                      },
                      function (response) {
                        openTablePage("financialLossUpdateTable")
                      },
                      function (error) {
                        openSnackbar(
                          "error",
                          error.response.data.error[0].detail.message
                        );
                        hideLoader();
                      }
                    )
                  },
                },
                offsetCurrent: {
                    type: "v-text-field",
                    editable: true,
                    creatable: false,
                    width: "200",
                    inlineEditable: true,
                    label: "Değer",
                    onSave: function(thisData, model, dynamicElements) {
                      showLoader("Dosyanız Yükleniyor. Lütfen Bekleyiniz.");
                      sendRequest(Enum.requestType.POST,"updateProjectAttachmentValue","",
                      {
                        updateObj: {
                          value: model.offsetCurrent,
                          sectionId: model.sectionId,
                          definitionId: model.offsetCurrent_definitionId,
                          cookieData: window.$cookies.get("selectedItem")
                        }
                      },
                      function (response) {
                        openTablePage("financialLossUpdateTable")
                      },
                      function (error) {
                        openSnackbar(
                          "error",
                          error.response.data.error[0].detail.message
                        );
                        hideLoader();
                      }
                    )
                  },
                },
              },
            }
        }
    }
}

</script>

<style scoped>
::v-deep .threedot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 300px;
}
::v-deep .threedot:hover {
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}
</style>