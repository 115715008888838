<template>
  <v-app>
    <div class="header-div">
      <h3 class="header-h3">FİNANSMAN GİDER KISITLAMASI VERİ GİRİŞİ</h3>
    </div>
      <table-component :dynamic-element="tableElements" :model="model" class="small-page-layout"/>
      <Loader></Loader>
      <!-- <snackbar-component /> -->
  </v-app>
</template>
<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";

import {
openSnackbar,
openTablePage,
showLoader, 
hideLoader 
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import {axiosUploadTypeArrayBuffer, checkProject} from "../../helper/uploadHelperForData"
export default {
  components: {
      "table-component": tableComponent,
      "upload-component": uploadComponent,
      Loader: Loader,
      "snackbar-component": snackbarComponent,
  },

  data() {
      return {
        tableElements: {},
        model: {},
        renderKey: 0,
      };
  },
  created() {
    checkProject();
    this.loadData();
  },
  watch: {},
  methods: {
  loadData() {
    this.tableElements = {
      sortBy: [],
      sortDesc: false,
      renderKey: 0,
      type: "table",
      tableHeight : 530,
      name: "fundingConstraintsTable",
      class: "",
      infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
      deleteDialogVisible : true,
      hidePagination: true,
      //selectedRows: [],
      filteredColumn: {},
      filteredColumns: true,
      selectionUrl: "fundingConstraintsUpdate",
      initialFilter:{
        [Enum.initialFilter.AND] :
        [
          {
            projectId: {
              [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id
            },auditDate: {
              [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").auiditDate
            },preriorDate: {
              [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").preriorDate
            }
          }
        ]
      },
      toolbars: [
        {
          type: Enum.layoutType.div,
          name: "toolbarsForm",
          inputElements: [
            [
                {
                  id: "assigningReceipt",
                  type: "v-btn",
                  variant: "btn",
                  name: "assigningReceipt",
                  inputText: "Mizan'dan Getir",
                  divStyle: "",
                  style: "height: 25px !important;",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    showLoader('Verileriniz Kayıt Ediliyor')
                    sendRequest(Enum.requestType.POST, "fundingConstraintsAutoUpdate", "", 
                    {
                      cookieData: window.$cookies.get("selectedItem"),
                      definitionId: dynamicElements.data[0].definitionId,
                      sectionId: dynamicElements.data[0].sectionId,
                    }, 
                    function (response) {
                      hideLoader();
                      openTablePage('fundingConstraintsTable');
                      openSnackbar("success", "Verileriniz Başarılı Bir Şekilde Getirildi", "2000")
                    },
                      function (error) {
                        errFunc(error);
                        openSnackbar("error", "Verileri Kaydederken Bir Hata Oluştu", "2000")
                        hideLoader();
                        openTablePage(fundingConstraintsTable);
                      }
                      
                    )
                  },
                },
              ],
            ],
          }
        ],
        headers: [
          { text: "Açıklamalar", value: "description", width: "100" , divider: true,},
          { text: "Tutar", value: "value", width: "20%", dataType: "currency" , divider: true, align: "right"},
        ],
      columns: {
        value: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            width: "200",
            inlineEditable: true,
            label: "Değer",
            onSave: function(thisData, model, dynamicElements) {
              showLoader("Dosyanız Yükleniyor. Lütfen Bekleyiniz.");               
              sendRequest(Enum.requestType.POST,"updateProjectAttachmentValue","",
              {
                updateObj: {
                  value: model.value,
                  definitionId: model.definitionId,
                  sectionId: model.sectionId,
                  cookieData: window.$cookies.get("selectedItem")
                }
              },
              function (response) {
                openTablePage("fundingConstraintsTable")
              },
              function (error) {
                openSnackbar(
                  "error",
                  error.response.data.error[0].detail.message
                  
                );
                hideLoader();
              })
            },
          },
        }
      };
  }
},
}
</script>

<style>
.header-div {
text-align: center;
}
</style>