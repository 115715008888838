<template>
    <v-app>
      <div class="header-div">
        <h3 class="header-h3">NAKDİ SERMAYE ARTIŞI KAYDI</h3>
      </div>
      <v-toolbar-items style="margin-left: 25px; height: 30px;">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text @click="downloadDrafts" v-on="on" style="background-color: #4BB543; color: white; border-radius: 5px;">
              Taslak İndir
            </v-btn>
          </template>
        </v-tooltip>
      </v-toolbar-items>
        <table-component :dynamic-element="tableElements" :model="model" class="small-page-layout"/>
        <Loader></Loader>
        <!-- <snackbar-component /> -->
    </v-app>
  </template>
  <script>
  import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
  import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
  import Enum from "../../../frontend/components/Enum.json";
  import Loader from "../../../frontend/components/atoms/loader";
  import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";
  
  import {
  getTable,
  updateItem,
  saveItem,
  deleteItem,
  openSnackbar,
  openTablePage,
  showLoader, 
  hideLoader 
  } from "../../../frontend/components/atoms/helper/elementHelper.js";
  import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
  import {axiosUploadTypeArrayBuffer, checkProject, downloadZipFile} from "../../helper/uploadHelperForData"
  export default {
    components: {
        "table-component": tableComponent,
        "upload-component": uploadComponent,
        Loader: Loader,
        "snackbar-component": snackbarComponent,
    },
  
    data() {
      return {
        tableElements: {},
        model: {},
        renderKey: 0,
      };
    },
    created() {
      checkProject();
      this.loadData();
    },
    watch: {},
    methods: {
      downloadDrafts() {
        downloadZipFile();
      },
      renderCapitalCashTable(thisData, model, dynamicElements) {
        showLoader('Hesaplamalar Yapılıyor')
        let choiceData = {
          cookieData: window.$cookies.get("selectedItem")
        }
        sendRequest(Enum.requestType.POST, "queryCapitalCashTable", "", choiceData, 
        function (response) {
          dynamicElements.data = response.data.data.data;
          model.itemCount = dynamicElements.data.length;
          model.pagesCount = dynamicElements.data.length / 50;
          dynamicElements.forceRender();
          hideLoader();
        },
        function (error) {
         errFunc(error);
        })
    },
    loadData() {
      let that = this;
      this.tableElements = {
        sortBy: [],
        sortDesc: false,
        renderKey: 0,
        type: "table",
        tableHeight : 530,
        name: "capitalCashTable",
        class: "",
        infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
        excelDownloadBtnText: "İndir",
        deleteDialogVisible : true,
        deleteBtnText: "Sil",
        hidePagination: true,
        saveBtnText : 'Yeni Kayıt',
        saveUrl : "capitalCashTableInsert",
        //selectedRows: [],
        //filteredColumn: {},
        //filteredColumns: true,
        showDefaultDeleteButon : false,
        selectionUrl: "queryCapitalCashTable",
        initialFilter:{
            [Enum.initialFilter.AND] :
              [
                {
                  projectId: {
                    [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id
                  }
                }
              ]
         },
        showDefaultDeleteButon : true,
        deleteUrl: "deleteTable",
        deleteBtnSettings : {
            color: "error",
            style:"height: 25px !important; margin-top: 27%",
          },
        saveBtnSettings: {
          style:"height: 25px !important; margin-top: 15%",
        },
        clientEvents : {
           onBeforeSave :function (selectedItem,creatableObjects,model,tableObject) {
             creatableObjects.initialFilter = tableObject.initialFilter
           },
        },
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
              [
                {
                  id: "capitalCash",
                  type: "v-file-input",
                  style:"height: 25px!important; width: 250px;",
                  label: "Dosyanızı Seçiniz",
                  inputHeader: "Kira",
                  name: "capitalCash",
                  multiple: true,
                  onChange: async function(thisData, model, dynamicElements) {
                    let files = model.capitalCash[0];
                    let choiceData = {
                        choice : "capitalCash", 
                        headerAddFlag : true, 
                        fileName : files.name,
                        fileType : 'capitalCash',
                        cookieData: window.$cookies.get("selectedItem")
                    }
                    showLoader('Dosyanız Hazırlanıyor')
                    uploadHelperFormDataQueue([files], "insertToDatabaseFromExcel", Enum.requestType.POST, choiceData, 
                    function (response) {
                      if (response.data.data.errorExcel != null) {
                      openSnackbar("error", "Hatalı Excel Taslağı Yüklediniz", "2000")
                      }
                      hideLoader();
                      that.renderCapitalCashTable(thisData, that.model.capitalCashTable, dynamicElements)
                      openSnackbar("success", "Verileriniz Başarılı Bir Şekilde Kaydedildi", "2000")
                      },
                    function (error) {
                      openSnackbar("error", error.response.data.error[0].detail.message);
                    })
                  }
                }
              ],
            ],
          }
        ],
        headers: [
          { text: "Nakten Artırılan Sermaye", value: "capitalCashIncrease", width: "100", dataType: "currency" , divider: true, align: "right"},
          { text: "TCMB Faiz Oranı", value: "interestRate", width:"100", dataType: "date", dataType: "currency" , divider: true, align: "center"},
          { text: "Sermaye Artışının Tescil Edildiği Ay", value: "registratedMonth", width: "100" , divider: true, align: "right"},
          { text: "İndirim Oranı", value: "discountRate", width: "100", dataType: "currency" , divider: true, align: "center"},
        ],
        columns: {
            capitalCashIncrease: {
            type: "v-text-field",
            label: "Nakten Artırılan Sermaye",
            editable: false,
            creatable: true,
            inlineEditable:false,
          },
          interestRate: {
            type: "v-text-field",
            label: "TCMB Faiz Oranı",
            editable: false,
            creatable: true,
            inlineEditable:false,
          },
          registratedMonth: {
            type: "v-text-field",
            label: "Sermaye Artışının Tescil Edildiği Ay",
            editable: false,
            creatable: true,
            inlineEditable:false,
          },
          discountRate: {
            type: "v-text-field",
            label: "İndirim Oranı",
            editable: false,
            creatable: true,
            inlineEditable:false,
          },
        }
      };
    },
    copyItem(tableName,selectedItem,options,model,onBeforeSave,onAfterSave,errorFunction) {
      if (!Array.isArray(selectedItem)) {
          selectedItem = [selectedItem];
      }
      
      let dynamicElements = getTable(tableName);
      if (options != undefined && options.dialogText != undefined) {
          dynamicElements.deleteDialogText = options.dialogText;
      }
      
      let title = "Yeni Kayıt Eklemek İstediğinize Emin Misiniz?";
      
      dynamicElements.forceRender();
      model.close = "Hayır",
      model.insertItem = "Evet";
      dynamicElements.dialogVisible = true;
      dynamicElements.dialogInfo = {
          name:"insertDialog",
          maxWidth : "1100",
          dialogVisible : "dialogVisible",
          title : title,
          layout : [
              {
                type : Enum.layoutType.div,
                name : "loginForm",
      
                inputElements : [
                ]
              }
          ],
          actions : [
            {
              id : "close",
              type : "v-btn",
              variant : "btn",
              name : "close",
              inputText : "Kapat",
              divStyle:"",
              btnTypeText: true,
              color:"green darken-1",
              classTag : "",
              onClick : function(thisData,model,dialogInfo) {
                var tableObject = getTable(tableName);
                tableObject.dialogVisible = false;
                tableObject.forceRender();
                console.log(thisData,model,dialogInfo);
              }
            },
            {
              id : "insertItem",
              type : "v-btn",
              variant : "btn",
              name : "insertItem",
              inputText : "Ekle",
              divStyle:"",
              btnTypeText: true,
              color:"green darken-1",
              classTag : "",
              onClick : function(thisData,model,dialogInfo) {
                var tableObject = getTable(tableName);
                console.log(thisData,model,dialogInfo);
                tableObject.dialogVisible = false;
                tableObject.forceRender();
                let creatableObjects = {}; 
                sendRequest(Enum.requestType.POST, dynamicElements.saveUrl,"",{selectedItem,creatableObjects, cookieData: window.$cookies.get("selectedItem")},function (res) {
                    openTablePage(tableName);
                    openSnackbar("success", "Eklemeniz Başarılı Bir Şekilde Gerçekleşti");
                },function (error) {
                    if (errorFunction != undefined) {
                        errorFunction(error,selectedItem,creatableObjects,model,tableObject);
                    } else if(tableObject.clientEvents.onSaveError != undefined){
                        tableObject.clientEvents.onSaveError(error,selectedItem,creatableObjects,model,tableObject);
                    }
                });
              }
            },
          ]
      };
    },
  },
}
  
    
  
  </script>
  
  <style>
  .header-div {
  text-align: center;
  }
  </style>