<template>
    <v-dialog v-model="model.ticketModel" persistent width="700px">
        <v-card style="position: relative;">
            <v-icon size="70px" style="position: absolute;top:15px;right:25px"
                color="rgb(250,250,250,0.7)">mdi-alert</v-icon>
            <v-card-title class="text-h5" style="background-color: rgb(41,64,95); color:white;font-weight: 700;">
                Hatalı Fatura Bildir
            </v-card-title>
            <v-card-text style="background-color: rgb(41,64,95);color: rgb(148, 168, 178);">Önem derecesini seçerek
                işleminize devam ediniz.</v-card-text>
            <div class="newTicketDialogContent"
                style="display: flex;flex-direction: column;row-gap: 30px; padding-top: 35px;">
                <div
                    style="display: flex;flex-direction: row;  column-gap: 15px;width: 100%; justify-content: space-between; ">
                    <div style="max-width:200px;">
                        <v-select :items="status" v-model="newTicket.status" label="Önem Derecesi" outlined
                            hide-details="true" dense></v-select>
                    </div>
                    <div
                        style="display: flex;flex-direction: row;background-color:rgb(41,64,95,0.2) ;justify-content: center;align-items: center;border-radius: 6px;padding: 0px 10px 0px 10px;">
                        <div style="color: rgb(41,64,95);font-weight: 500;">Fatura No: </div>
                        <div style="color: rgb(68, 127, 204);">{{ ticketElements.billNo }}</div>
                    </div>
                </div>
                <v-textarea clearable clear-icon="mdi-close-circle" label="Lütfen hataları ve sorularınızı yazınız."
                    model-value="This is clearable text." v-model="newTicket.text" filled rows="8"
                    hide-details="true"></v-textarea>
                <div style="display: flex;flex-direction: row;gap:10px">
                    <v-text-field 
                    label="Telefon Numarası" 
                    style="max-width: 200px;"
                    counter="10"
                    maxLength="10"
                    hint="Lütfen 0 kullanmadan 10 haneli telefon numaranızı giriniz"
                    v-model="newTicket.info.customerTelNo"
                    outlined
                    >
                    </v-text-field>
                    <div style="font-size:15px; color: #00A8FF;font-style: italic;width:150px">Size ulaşabilmemiz için lütfen telefon numaranızı giriniz.</div>
                </div>
            </div>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="closeTicketDialog">
                    İptal
                </v-btn>
                <v-btn color="green darken-1" text @click="createNewBillError()">
                    Gönder
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Enum from "../helper/Enum.json";
import {
    showLoader,
    hideLoader,
    openSnackbar
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import { sendRequest } from "../../../frontend/components/atoms/helper/requestHelper.js";

export default {
    props: {
        ticketElements: {
            type: Object,
            default: () => { },
        },
        model: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {
            status: [Enum.ticketStatus.LOWPRIORITY, Enum.ticketStatus.MEDIUMPRIORITY, Enum.ticketStatus.HIGHPRIORITY],
            newTicket: {
                companyUnit: Enum.companyUnit.TECHNICALSUPPORT,
                status: "",
                text: "",
                info: {
                    billNo: this.ticketElements.billNo,
                    fileName: this.ticketElements.fileName,
                    customerTelNo:""
                }
            },
        }
    },
    methods: {
        createNewBillError() {
            this.newTicket.info.billNo = this.ticketElements.billNo
            this.newTicket.info.fileName = this.ticketElements.fileName
            let that = this
            if (this.newTicket.status != '' && this.newTicket.text != '' && this.newTicket.info.customerTelNo != '') {
                showLoader("Hata Oluşturuluyor")
                sendRequest(Enum.requestType.POST, "newTicket", null, this.newTicket, function (response) {
                    that.closeTicketDialog()
                    hideLoader()
                    openSnackbar("success", "Hata Bildiriminiz Başarılı Şekilde Oluşturuldu");
                },
                    function (error) {
                        if (error.response.data.error[0].code == 499) {
                            openSnackbar("orange", "Talep Oluşturuldu Fakat Mail Bilgilendirmesi Yapılamadı.")
                        } else {

                            openSnackbar("error", "Hata Bildiriminiz Oluşturulamadı")
                        }
                        hideLoader();
                        that.closeTicketDialog();
                    }
                );
            } else {
                openSnackbar("error", "Lütfen Verileri Eksiksiz Giriniz");
            }
        },
        closeTicketDialog() {
            this.newTicket = {
                companyUnit: Enum.companyUnit.TECHNICALSUPPORT,
                info: { fileName: this.ticketElements.fileName, }
            }
            this.model.ticketModel = false
        }
    }

}
</script>

<style>
/* ticket style */
.newTicketDialogContent {
    width: 100%;
    height: 100%;
    padding: 10px 50px;
}
</style>