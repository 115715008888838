<template>
    <v-app>
      <table-component :dynamic-element="dynamicElements" :model="model" style="font-size: 15px;" />
      <loader />
    </v-app>
  </template>
  
  <script>
  import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
  import Enum from "../../../frontend/components/Enum.json";
  import Loader from '../../../frontend/components/atoms/loader';
  import {sendRequest} from "../../../frontend/components/atoms/helper/requestHelper";
  import {axiosUploadTypeArrayBuffer} from "../../helper/uploadHelperForData"
  
  import {
    openSnackbar,
    getTable,
    openTablePage,
    showDialog,
    closeDialog,
    excelDownload,
    getTableModel,
    showTableColoumn,
    hideTableColoumn,
    showLoader,
    hideLoader,
    deleteItem
  } from "../../../frontend/components/atoms/helper/elementHelper.js";
  export default {
    components: { "table-component": tableComponent , 'loader' : Loader},
  
    data() {
      return {
        periodItemsForDelete : [],
        selectedPeriodForDelete : [],
        selectedPeriodForDeleteValid : false, 
        selectPeriodForDeleteDialog : false,
        dynamicElements: {},
        model: {},
        renderKey: 0,
        initialFilterValue: {},
        renderTableName: "eBillsAssistantTable"
      };
    },
  
    created() {
      this.loadData();
    },
    watch: {},
    methods: {
      loadData() {
        let that = this
        this.model = {};
        this.dynamicElements = {
          sortBy: [],
          sortDesc: false,
          clientEvents : {
            onCellClick : function (/*row ve Column Index,prop bilgileri*/) {
              //table ın body sinde
            },
            onBeforeUpdate : function () {
              //console.log('onBeforeUpdate yapıldı')
            },
            onBeforeDelete : function () {
              //console.log('onBeforeDelete yapıldı')
            },
            onBeforeSave : function () {
              //console.log('onBeforeSave yapıldı')
            },
            onAfterUpdate : function () {
              //console.log('onAfterUpdate yapıldı')
            },
            onAfterSave: function () {
              //console.log('onAfterSave yapıldı')
            },
            onAfterDelete :function () {
              //console.log('onAfterDelete yapıldı')
            },
            onBeforeSelect : function () {
              //console.log('onBeforeSelect yapıldı')
            },
            onAfterSelect : function () {
              //console.log('onAfterSelect yapıldı')
            },
            onSelect : function () {
              //console.log('onSelect yapıldı')
            },
            onSaveError: function () {
              //console.log('onSaveError yapıldı')
            },
            onUpdateError : function () {
              //console.log('onUpdateError yapıldı')
            },
            onDeleteError: function () {
              //console.log('onDeleteError yapıldı')
            },
            //table için
            onBeforeunload: function () {
              //console.log('onBeforeunload yapıldı')
            },
            onafterUpload: function () {
              //console.log('onafterUpload yapıldı')
            },
            dataChanged: function () {
              //console.log('dataChanged yapıldı')
            },
            searchOnClick: function () {
              //console.log('searchOnClick yapıldı')
            },
          },
          selectedRows: [],
          renderKey: 0,
          type: "table",
          name: that.renderTableName,
          class: "",
          infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
          updateBtnText: "Güncelle",
          deleteBtnText: "Sil",
          searchBtnText: "Ara",
          clearSearchBtnText: "Temizle",
          filterBtnText: "Filtre",
          excelDownloadBtnText: "İndir",
          tableHeight : 430,
          divStyle: "font-size: 5px",
          //emailBtnText: "Email",
          hidePagination: true,
          filteredColumn: {},
          filteredColumns: true,
          selectionUrl: "gettabledata",
          initialFilter: that.initialFilterValue,
          deleteUrl: "deletefortable",
          passivePullUrl: "passivepullforassistant",
          showDefaultDeleteButon : false,
          toolbars: [
            {
              type: Enum.layoutType.div,
              name: "toolbarsForm",
              inputElements: [
                [
                  {
                    id : "delete",
                    type : "v-btn",
                    variant : "btn",
                    name : "delete",
                    inputText : "Tümünü Sil",
                    divStyle:"",
                    style:"height: 25px !important;",
                    btnTypeText: false,
                    color : "error",
                    prependIcon : "",
                    classTag : "",
                    onClick : function(thisData,model,dynamicElements) {
                      // let options = {
                      //   showDialog : true
                      // }
                      // deleteItem(that.renderTableName,[],options,model)
                      showLoader('Verileriniz Siliniyor') 
                      sendRequest(Enum.requestType.POST, "deleteEBills", "", 
                        {cookieData: window.$cookies.get("selectedItem")}, 
                        function (response) {
                          hideLoader();
                          openSnackbar("green", "Verileriniz Başarıyla Silindi", "3000")
                          openTablePage(that.renderTableName);
                        },
                        function (error) 
                        {
                          errFunc(error);
                        }
                      )
                    }
                  }
                ],
                [
                {
                  id: "clear",
                  type: "v-btn",
                  variant: "btn",
                  name: "clear",
                  inputText: "clearSearchBtnText",
                  style: "height: 25px !important;",
                  divStyle: "",
                  btnTypeText: false,
                  color: "primary",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    let dynamicElement = getTable(that.renderTableName);
                    console.log(thisData,dynamicElements,model);
                    dynamicElement.filteredColumn = {};
                    openTablePage(that.renderTableName);
                  },
                },
                ],
                [
                  {
                    id: "enterDate",
                    type: "v-btn",
                    variant: "btn",
                    name: "enterDate",
                    inputText: "E-Fatura İndir",
                    icon: "mdi-file-excel",
                    style:"height: 25px !important;",
                    divStyle: "",
                    btnTypeText: false,
                    color: "success",
                    prependIcon: "",
                    classTag: "",
                    onClick: function() {
                      let dialogInfo = {
                        name: "muavinTarihliExcel",
                        maxWidth: "500",
                        title: "Muavin Excel İndir",
                        actions: [
                          {
                            id: "excelDownload",
                            type: "v-btn",
                            variant: "btn",
                            name: "excelDownload",
                            divStyle: "",
                            inputText: "İndir",
                            btnTypeText: true,
                            color: "success",
                            classTag: "",
                            icon: "mdi-file-excel",
                            closeBtn: true,
                            onClick: function(thisData, model, dialogInfo) {
                              if (model.startDateForNewExcel === "" && model.endDateForNewExcel === "") {
                                openSnackbar("error","Girilen Tarih Değerleri Boş Olamaz");
                                return false;
                              }
                              if (((model.startDateForNewExcel != undefined) && (model.endDateForNewExcel != undefined))) {
                                let startDateNew = model.startDateForNewExcel.split("/")
                                startDateNew = new Date(startDateNew[2] + "-" + startDateNew[1] + "-" +startDateNew[0])
                                let endDateNew = model.endDateForNewExcel.split("/")
                                endDateNew = new Date(endDateNew[2] + "-" + endDateNew[1] + "-" +endDateNew[0])
                                const diffTime = Math.abs(endDateNew - startDateNew);
                                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                                if (diffDays > 370) {
                                  openSnackbar("error","Seçilen Tarihler Arası 3 Aydan Büyük Olamaz", 2000);
                                  return false;
                                }
                                if (startDateNew > endDateNew) {
                                  openSnackbar("error","Başlangıç Tarihi, Bitiş Tarihinden Büyük Olamaz",2000);
                                  return false;
                                }
                              }
                              showLoader('Dosyanız Hazırlanıyor')
                              sendRequest(Enum.requestType.POST,"eBillDownload","",
                                {
                                  startDate: model.startDateForNewExcel, endDate: model.endDateForNewExcel,
                                  excelDownload : true,
                                  choice : "eBillTable",
                                  cookieData: window.$cookies.get("selectedItem")
                                },
                                function (response) {
                                  if (response.data.data.data != true) {
                                    openSnackbar('error', "İndirilecek Veri Bulunamadı", 3000)
                                    hideLoader();
                                    return false
                                  }
                                  let zipNameStartDate = model.startDateForNewExcel.replaceAll("/", "_")
                                  let zipNameEndDate = model.endDateForNewExcel.replaceAll("/", "_")
                                  let zipName = "Muavin Rapor Excel" + zipNameStartDate + "ve" + zipNameEndDate
                                  axiosUploadTypeArrayBuffer("allExcelZipDownload", "eBillTable", zipName, "Excel İndirildi")
                                },
                                function (error) {
                                  openSnackbar(
                                    "error",
                                    error.response.data.error[0].detail.message
                                  );
                                  hideLoader();
                                }
                              )
                              closeDialog();
                            },
                          },  
                        ],
                        layout: [
                          {
                            type: Enum.layoutType.div,
                            name: "infoTableDialog",
                            dialogVisible: "dialogVisible",
                            inputElements: [
                              [
                               {
                                  id : "startDateForNewExcel",
                                  type : "v-date-picker",
                                  readonly: true,
                                  variant : "text",
                                  name : "startDateForNewExcel",
                                  label : "Başlangıç Tarihi",
                                  value : "",
                                  prependIcon : "fas fa-at",
                                  placeHolder : "",
                                  style : "width: 260px;",
                                  classTag : "",
                                  onClick : function(thisData,model,dynamicElements) {
                                    console.log(thisData,model,dynamicElements);
                                  }
                                },
                              ],
                              [
                               {
                                  id : "endDateForNewExcel",
                                  type : "v-date-picker",
                                  variant : "text",
                                  readonly: true,
                                  name : "endDateForNewExcel",
                                  label : "Bitiş Tarihi",
                                  value : "",
                                  prependIcon : "fas fa-at",
                                  placeHolder : "",
                                  style : "width: 260px;",
                                  classTag : "",
                                  onClick : function(thisData,model,dynamicElements) {
                                    console.log(thisData,model,dynamicElements);
                                  }
                                },
                              ],
                            ],
                          }
                        ],
                        titleActions: [
                          {
                            id: "close",
                            type: "v-btn",
                            variant: "btn",
                            name: "close",
                            divStyle: "",
                            inputText: "Kapat",
                            btnTypeText: true,
                            color: "red",
                            classTag: "",
                            icon: "mdi-close",
                            closeBtn: true,
                            onClick: function(thisData, model, dialogInfo) {
                              console.log(thisData, model, dialogInfo);
                              closeDialog();
                            },
                          },
                        ],
                      }
                      showDialog(dialogInfo);
                    },
                  }
                ],
              ],
            },
          ],
          headers: [
            { text: "Fatura Tarihi",align: "center",value: "billDate",width: "100",divider: true, search: true, excelName: "Satış Faturası / VEDOP Tarihi",dataType: "date" },
            { text: "Fatura No",value: "billNo",align: "center",width: "200",class: 'threedot',divider: true, search: true, excelName: "Satış Faturası Seri No" },
            { text: "Ünvan",value: "name",align: "center",width: "200",class: 'threedot',divider: true, search: true, excelName: "Adı-Soyadı / Ünvanı" },
            { text: "Mamül Adı",align: "center",value: "productName",width: "200",divider: true, search: true, class: 'threedot', },
            { text: "Mamül Kodu",align: "center",value: "productCode",width: "200",divider: true, search: true, class: 'threedot', },
            { text: "Vergi-TC Kimlik Numarası", value: "tinNumber", align: "center", width: "150", divider: true, search: true, excelName: "Vergi Kimlik Numarası / TC Kimlik Numarası" },
            { text: "Hizmetin Miktarı",value: "productAmount",width: "150",align: "center",divider: true, search: true, excelName: "Satılan Mal ve/veya Hizmetin Miktarı",dataType: "currency" },
            { text: "Mamül Ölçü Birimi", value: "unit", align: "center", width: "170", divider: true, search: true, },
            { text: "Hizmetin Birim Tutarı",value: "price",width: "150",align: "center",divider: true, search: true, excelName: "Satılan Mal ve/veya Hizmetin Birim Tutarı",dataType: "currency"},
            { text: "Matrah Tutarı", value: "totalPrice", width: "150", align: "center", divider: true, search: true, excelName: "Satılan Mal ve/veya Hizmetin Tutarı", dataType: "currency"},
            { text: "KDV Oranı", align: "center", value: "vatRate", width: "120", divider: true, search: true, dataType: "currency" },
            { text: "KDV TUTARI", value: "vat", width: "150", align: "center", divider: true, search: true, dataType: "currency" },
            { text: "Para Birimi",value: "currencyType",width: "120",align: "center",divider: true, search: true, },
            { text: "Döviz Kuru", value: "currencyRate", dataType:"currency",width: "120",align: "center",divider: true, search: true,},
            { text: "Matrah Tutarı TL", value: "currencyAmount", dataType:"currency",width: "120",align: "center",divider: true, search: true, },
            { text: "Fatura Tipi", switchValue: {0 : "Satış", 1 : "Alış"}, value: "kind",width: "120",align: "center",divider: true, search: true, },
            { text: "Fatura Türü", value: "eInvoiceType",width: "120",align: "center",divider: true, search: true, switchValue: {0 : "Normal", 1 : "Araç", 2 : "Tevkifat", 3 : "İade", 4 : "İstisna", 5 : "İhraç Kayıtlı", 6: "İletişim Faturası", 7 : "Özel Matrah", 11 : "İhracat"},},
            { text: "B.İ.İ.H.D İşlem Türü",value: "rebateType",width: "120",align: "center",divider: true, search: true, excelName: "Belgeye İlişkin İade Hakkı Doğuran İşlem Türü" },
            { text: "GTIP",value: "gtip",width: "100",align: "center",divider: true, search: true, },
          ],
          // columns: {
          //   billNo: {
          //   type: "v-text-field",
          //   editable: false,
          //   creatable: true,
          //   inlineEditable: false,
          //   //cell için gerekli inputElement hücre özelliştirmesi için
          //   cellElement: {
          //     id: "billNo",
          //     type: "v-btn",
          //     variant: "btn",
          //     name: "billNo",
          //     inputText: "billNo",
          //     divStyle: "",
          //     style: "height:0px;text-decoration: underline;color: blue !important;",
          //     btnTypeText: true,
          //     color: "black",
          //     prependIcon: "",
          //     classTag: "",
          //     onClick: function(thisData, model, dynamicElements) {
          //       console.log(thisData, model, dynamicElements);
          //       let dialogInfo = {
          //         name: "infoTableDialog",
          //         title: model.billNo + " " + "Fatura No",
          //         layout: [
          //           {
          //             type: Enum.layoutType.div,
          //             name: "tableDialog",
          //             tableObject: {
          //               tableHeight: "400",
          //               dialogTable: true,
          //               renderKey: 50,
          //               type: "table",
          //               name: "infoTable",
          //               class: "",
          //               infoTotalRowCountHeaderAssistantTable: "Toplam satır sayısı",
          //               selectionUrl: "ebillReceiptDetail",
          //               hidePagination: false,
          //               initialFilter:{
          //                 [Enum.initialFilter.AND] :
          //                   [
          //                     {
          //                       projectId: {
          //                         [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem") == null ? undefined : window.$cookies.get("selectedItem").id
          //                       }
          //                     }
          //                   ]
          //               },
          //               filteredColumn: {
          //                 billNo: model.billNo,
          //                 assistantDate: model.billDate,
          //               },
          //               toolbars: [],
          //               headers: [
          //                 {
          //                   text: "Hesap Kodu",
          //                   value: "accountCode",
          //                   width: "150",
          //                   search: true,
          //                   sortable: true,
          //                   divider: true, 
          //                 },
          //                 {
          //                   text: "Hesap Adı",
          //                   value: "accountName",
          //                   search: true,
          //                   class: 'threedot',
          //                   divider: true, 
          //                 },
          //                 {
          //                   text: "Tarih",
          //                   value: "billDate",
          //                   width: "120",
          //                   search: true,
          //                   dataType: "date",
          //                   divider: true, 
          //                 },
          //                 {
          //                   text: "Fiş No",
          //                   value: "receiptNumber",
          //                   width: "160",
          //                   search: true,
          //                   divider: true, 
          //                 },
          //                 {
          //                   text: "Açıklama",
          //                   value: "explanation",
          //                   search: true,
          //                   class : 'threedot',
          //                   divider: true, 
          //                 },
          //                 {
          //                   text: "Borç",
          //                   value: "debt",
          //                   width: "150",
          //                   align: "right",
          //                   search: true,
          //                   dataType: "currency",
          //                   divider: true, 
          //                 },
          //                 {
          //                   text: "Alacak",
          //                   value: "credit",
          //                   width: "150",
          //                   align: "right",
          //                   search: true,
          //                   dataType: "currency",
          //                   divider: true, 
          //                 },
          //                 {
          //                   text: "Bakiye",
          //                   value: "balance",
          //                   width: "150",
          //                   align: "right",
          //                   search: true,
          //                   dataType: "currency",
          //                   divider: true, 
          //                 }
          //               ],
          //               footer: [],
          //               singleSelect: true,
          //               itemKey: "id",
          //               columns: {},
          //             },
          //           },
          //         ],
          //         actions: [
          //         ],
          //         titleActions: [
          //           {
          //             id: "close",
          //             type: "v-btn",
          //             variant: "btn",
          //             name: "close",
          //             divStyle: "",
          //             inputText: "Kapat",
          //             btnTypeText: true,
          //             color: "red",
          //             classTag: "",
          //             icon: "mdi-close",
          //             closeBtn: true,
          //             onClick: function(thisData, model, dialogInfo) {
          //               console.log(thisData, model, dialogInfo);
          //               closeDialog();
          //             },
          //           },
          //         ],
          //       };
          //       //var dialogObject = getTableModel(that.renderTableName);
          //       // var tableObject = getTable(that.renderTableName);
          //       // tableObject.filteredColumn.receiptNumber = model.receiptNumber
          //       // tableObject.filteredColumn.assistantDate = model.billDate
          //       showDialog(dialogInfo);
          //     },
          //   },
          // },
          // },
          data: "data",
          showSelecetVisible: false,
          singleSelect: true,
          itemKey: "id",
          footer: [
              {
                type: Enum.layoutType.div,
                name: "footerForm",
                inputElements: [
                  [
                    {
                      id: "pageSize",
                      type: "v-text-field",
                      variant: "number",
                      name: "pageSize",
                      label: "Görünecek Satır Sayısı",
                      placeHolder: "",
                      style: "width: 45%; margin-left: 5%",
                      classTag: "",
                      onChange: function(thisData, model, dynamicElements) {
                        openTablePage(that.renderTableName);
                        console.log(thisData);
                        console.log(dynamicElements);
                        console.log(model);
                      },
                    },
                  ],
                  [
                    {
                      id: "currentPageNo",
                      type: "v-pagination",
                      variant: "pagination",
                      name: "currentPageNo",
                      divStyle: "",
                      style: "width:850px;",
                      classTag: "my-1",
                      length: "pagesCount",
                      onInput: function(thisData, model, dynamicElements) {
                        openTablePage(that.renderTableName);
                        console.log(thisData);
                        console.log(dynamicElements);
                        console.log(model);
                      },
                    },
                  ],
                  [
                    {
                      id: "infoTotalRowCountHeaderAssistantTable",
                      type: "p",
                      variant: "p",
                      name: "infoTotalRowCountHeaderAssistantTable",
                      inputText: "infoTotalRowCountHeaderAssistantTable",
                      divStyle: "",
                      style:
                        "font-size: 12px;font-weight:500;margin:3px 0;color:#666A6F;margin-right: 2%;margin-left: 60%;",
                      classTag: "",
                    },
                    {
                      id: "infoTotalRowCountAssistantTable",
                      type: "p",
                      variant: "p",
                      name: "infoTotalRowCountAssistantTable",
                      inputText: "itemCount",
                      divStyle: "",
                      style:
                        "font-size: 15px;font-weight:500;margin:3px 0;color:#666A6F;margin-left: 82%;",
                      classTag: "",
                    },
                  ],
                ],
              },
          ]
        };
      },
    },
  };
  </script>
  
  <style scoped>
  ::v-deep .threedot {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 200px;
  }
  ::v-deep .threedot:hover {
    overflow: visible;
    text-overflow: unset;
    white-space: normal;
  }
  </style>
  