<template>
    <v-app>
      <div class="header-div">
        <h3 class="header-h3">Geçmiş Yıl Zararları Hesabı</h3>
      </div>
      <table-component :dynamic-element="tableElements" :model="model" />    
      <Loader></Loader>
      <snackbar-component />
    </v-app>
</template>

<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import XLSX from "xlsx";
import {
  openSnackbar,
  showLoader,
  openTablePage,
  getTable,
  deleteItem,
  closeDialog,
  excelDownloadDefault,
  //getTableModel,
  //showDialog,
  hideLoader,
    updateItem,
  saveItem
  //hashCode
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";
import {axiosUploadTypeArrayBuffer, checkProject} from "../../helper/uploadHelperForData"

export default {
    components: {
      "table-component": tableComponent,
      "upload-component": uploadComponent,
      Loader: Loader,
      "snackbar-component": snackbarComponent,
    },
    data() {
      return {
        tableElements: {},
        model: {},
        renderKey: 0,
      };
    },
    created() {
      checkProject();
      this.loadData();
    },
    watch: {},
    methods: {
        loadData() {
          let that = this
            this.tableElements = {
            sortBy: [],
            sortDesc: false,
            renderKey: 0,
            type: "table",
            tableHeight : 500,
            name: "financialLossTable",
            class: "",
            infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
            excelDownloadBtnText: "İndir",
            deleteDialogVisible : true,
            hidePagination: true,
            filteredColumn: {},
            filteredColumns: true,
            selectionUrl: "financialLoss",
            initialFilter:{
            [Enum.initialFilter.AND] :
              [
                {
                  projectId: {
                    [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id
                  }
                }
              ]
            },
                toolbars: [
                  {
                    type: Enum.layoutType.div,
                    name: "toolbarsForm",
                    inputElements: [
                    [
                      {
                        id: "excelDownload",
                        type: "v-btn",
                        variant: "btn",
                        name: "excelDownload",
                        inputText: "excelDownloadBtnText",
                        style:"height: 25px !important;",
                        divStyle: "",
                        icon: "mdi-file-excel",
                        btnTypeText: false,
                        color: "success",
                        prependIcon: "",
                        classTag: "",
                        onClick: function(thisData, model, dynamicElements) {
                          showLoader('Dosyanız Hazırlanıyor')
                          sendRequest(Enum.requestType.POST,"financialLoss","",
                            {
                              excelDownload : true,
                              choice : "financialLossTable",
                              cookieData: window.$cookies.get("selectedItem")
                            },
                            function (response) {
                              if (response.data.data.data != true) {
                                openSnackbar('error', "İndirilecek Veri Bulunamadı", 3000)
                                hideLoader();
                                return false
                              }
                              let zipName = "Mali Zarar Tablosu"
                              axiosUploadTypeArrayBuffer("allExcelZipDownload", "financialLossTable", zipName, "Excel İndirildi")
                            },
                            function (error) {
                              openSnackbar(
                                "error",
                                error.response.data.error[0].detail.message
                              );
                              hideLoader();
                            }
                          )
                        },
                      }
                    ],
                  ],
                }
              ],
              headers: [
                { text: "Yıllar", value: "financialLossYears", width: "100" , divider: true, },
                { text: "Ticari Zararlar", value: `tradingLoss`, width: "100", dataType: "currency", divider: true, align: "right"},
                { text: "Mali Zararlar", value: "annualReport", width: "100", dataType: "currency", divider: true, align: "right"},
                { text: "İtfa Yılları", value: "redemptionYears", width: "100", divider: true, align: "right"},
                { text: "Matrah Arttırımı İle %50 Silinen Zarar", value: "fiftyPercent", width: "100", dataType: "currency", divider: true, align: "right"},
                { text: "Cari Dönem Mahsup Edilen Zararlar", value: "offsetCurrent", width: "100", dataType: "currency", divider: true, align: "right"},
                { text: "Devreden Zararlar", value: "accumulatedLosses", width: "100", dataType: "currency", divider: true, align: "right"},
              ],
            }
        }
    }
}

</script>

<style scoped>
::v-deep .threedot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 300px;
}
::v-deep .threedot:hover {
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}
</style>