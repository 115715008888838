import Vue from "vue";
import Vuex from "vuex";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);
const state = {
  sameAccountTransfer : {},
  loaderText: "",
  loaderToUpdate: false,
  loaderType: "basicShowLoader",
  currentFileCount: undefined,
  totalFileCount: "",
  promiseLength:0,
  loggedUserDefault : {
    userId: null,
    isloggedin: false,
    companyId: null,
    companyName: null,
    token: null,
    userName: null
  },
  snackbars : [],
  snackbarInitialized : false,
  globalDialogInitialized : false,
  loggedUser: {
    userId: null,
    isloggedin: false,
    companyId: null,
    companyName: null,
    token: null,
    userName: null,
  },
  tableModel : {},
  tableList : {},
  loaderText: "",
  loaderToUpdate: false,
  divList: {},
  divModel: {},

  currentDialog : {},
  layouts: {},
  projectInformation : {
    items : [],
  },
  selectedItem : {}
};

const actions = {
  setSameAccountCodes({state,commit}, setObj) {
    commit('SET_SAME_ACCOUNT_CODES', setObj)
  },
  setSnackbar({commit},snackbar){
    snackbar.showing = true;
    snackbar.color = snackbar.color || 'info';
    commit('SET_SNACKBAR',snackbar)
  },
  updateLoggedUserInfo(context, newInfo) {
    context.commit('UPDATE_LOGGED_USER_INFO', newInfo)
  },
  showLoader({commit}, data){
    commit('SHOW_LOADER',data);
  },
  hideLoader({commit}){
    commit('HIDE_LOADER');
  },
  loaderToUpdate({commit},data){
    commit('LOADER_TO_UPDATE',data);
  },
  updateLoggedUserCompany(context, companyId) {
    context.commit('UPDATE_LOGGED_USER_COMPANY', companyId)
  },
  setProjectInformation({commit}, projectInfo){
    commit("SET_PROJECT_INFORMATION", projectInfo)
  },
  logOut(context) {
    context.commit("UPDATE_LOGGED_USER_INFO", loggedUserDefault);
    localStorage.clear();
    router.push("/login");
  },
};

const mutations = {
  SET_SAME_ACCOUNT_CODES(state, tinNumberObj) {
    state.sameAccountTransfer[tinNumberObj.tinNumber] = tinNumberObj.value 
  },
  SET_SNACKBAR(state,snackbar){
    state.snackbars = state.snackbars.concat(snackbar)
  },
  SET_PROJECT_INFORMATION(state, projectInfo){
    state.projectInformation.items = projectInfo.items;
  },

  UPDATE_LOGGED_USER_INFO(state, newInfo) {
    Object.assign(state.loggedUser, newInfo)
  },

  SHOW_LOADER(state, data) {
    if (data.loaderType == 'uploadLoader') {
      state.loaderType = data.loaderType
      state.loaderToUpdate = data.loaderToUpdate
      state.loaderText = data.loaderText
      state.totalFileCount = data.totalFileCount
      state.currentFileCount = data.currentFileCount
    } else if (data.loaderType == 'basicShowLoader') {
      state.loaderType = data.loaderType
      state.loaderToUpdate = data.loaderToUpdate
      state.loaderText = data.loaderText
    }
  },

  HIDE_LOADER(state){
    state.loaderToUpdate = false
  },

  UPDATE_LOGGED_USER_COMPANY(state, companyObj) {
    state.loggedUser.companyId = companyObj.companyId
    state.loggedUser.companyName = companyObj.companyName
  },
};

const store = new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['loggedUser'],
    key: "localhost",
    storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key),
    },
  })],
  state,
  mutations,
  actions
});

export default store;