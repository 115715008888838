<template>
    <v-app>
      <div class="header-div">
        <h3 class="header-h3">GELECEK DÖNEMLERE AİT GİDERLER</h3>
      </div>
      <v-toolbar-items style="margin-left: 25px; height: 30px;">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text @click="downloadDrafts" v-on="on" style="background-color: #4BB543; color: white; border-radius: 5px;">
              Taslak İndir
            </v-btn>
          </template>
        </v-tooltip>
      </v-toolbar-items>
        <table-component :dynamic-element="tableElements" :model="model" class="small-page-layout"/>
        <Loader></Loader>
        <!-- <snackbar-component /> -->
    </v-app>
  </template>
  <script>
  import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
  import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
  import Enum from "../../../frontend/components/Enum.json";
  import Loader from "../../../frontend/components/atoms/loader";
  import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";
  import {
  getTable,
  openSnackbar,
  openTablePage,
  showDialog,
  closeDialog,
  showLoader, 
  hideLoader 
  } from "../../../frontend/components/atoms/helper/elementHelper.js";
  import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
  import {checkProject, downloadZipFile} from "../../helper/uploadHelperForData"
  export default {
    components: {
        "table-component": tableComponent,
        "upload-component": uploadComponent,
        Loader: Loader,
        "snackbar-component": snackbarComponent,
    },
  
    data() {
      return {
        tableElements: {},
        model: {},
        renderKey: 0,
      };
    },
    created() {
      let that = this
      checkProject();
      this.loadData();
    },
    watch: {},
    methods: {
      downloadDrafts() {
        downloadZipFile();
      },
      renderInsuranceTable(thisData, model, dynamicElements) {
        showLoader('Hesaplamalar Yapılıyor')
        let choiceData = {
          cookieData: window.$cookies.get("selectedItem")
        }
        sendRequest(Enum.requestType.POST, "queryinsurancetable", "", choiceData, 
        function (response) {
          dynamicElements.data = response.data.data.data;
          model.itemCount = dynamicElements.data.length;
          model.pagesCount = dynamicElements.data.length / 50;
          dynamicElements.forceRender();
          hideLoader();
        },
        function (error) {
         errFunc(error);
        })
      },
    loadData() {
      let that = this;
      this.tableElements = {
        sortBy: [],
        sortDesc: false,
        renderKey: 0,
        type: "table",
        tableHeight : 530,
        name: "insuranceTable",
        class: "",
        infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
        excelDownloadBtnText: "İndir",
        deleteDialogVisible : true,
        deleteBtnText: "Sil",
        hidePagination: true,
        saveBtnText : 'Yeni Kayıt',
        saveUrl : "insuranceTableInsert",
        //selectedRows: [],
        //filteredColumn: {},
        //filteredColumns: true,
        selectionUrl: "queryinsurancetable",
        initialFilter:{
          [Enum.initialFilter.AND] :
          [
            {
              projectId: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id
              }
            }
          ]
        },
        clientEvents : {
            onBeforeSave :function (selectedItem,creatableObjects,model,tableObject) {
              creatableObjects.initialFilter = tableObject.initialFilter
            },
         },
        showDefaultSaveButon : true,
        showDefaultDeleteButon : true,
        deleteUrl: "deleteTable",
        deleteBtnSettings : {
            color: "error",
            style:"height: 25px !important; margin-top: 27%",
          },
        saveBtnSettings: {
          style:"height: 25px !important; margin-top: 15%",
        },
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
              [
                {
                  id: "insurance",
                  type: "v-file-input",
                  style:"height: 25px!important; width: 250px;",
                  label: "Dosyanızı Seçiniz",
                  inputHeader: "Sigorta",
                  name: "insurance",
                  multiple: true,
                  onChange: async function(thisData, model, dynamicElements) {
                    let files = model.insurance[0];
                    let choiceData = {
                        choice : "insurance", 
                        headerAddFlag : true, 
                        fileName : files.name,
                        fileType : 'insurance',
                        cookieData: window.$cookies.get("selectedItem")
                    }
                    showLoader('Dosyanız Hazırlanıyor')
                    uploadHelperFormDataQueue([files], "insertToDatabaseFromExcel", Enum.requestType.POST, choiceData, 
                    function (response) {
                      if (response.data.data.errorExcel != null) {
                      openSnackbar("error", "Hatalı Excel Taslağı Yüklediniz", "2000")
                    }
                      hideLoader();
                      that.renderInsuranceTable(thisData, that.model.insuranceTable, dynamicElements)
                      openSnackbar("success", "Verileriniz Başarılı Bir Şekilde Kaydedildi", "2000")
                      },
                    function (error) {
                        openSnackbar("error", error.response.data.error[0].detail.message);
                      }
                    )
                  }
                }
              ],
              [
                {
                  id: "additionalInfo",
                  type: "v-btn",
                  variant: "btn",
                  name: "additionalInfo",
                  inputText: "Ek Bilgiler",
                  style:"height: 25px !important; margin-top: 15%",
                  divStyle: "",
                  btnTypeText: false,
                  color: "primary",
                  prependIcon: "",
                  classTag: "",
                  onClick: function() {
                    let dialogInfo = {
                      name: "muavinTarihliExcel",
                      maxWidth: "800",
                      title: "Ek Bilgiler",
                      actions: [
                        {
                          id: "saveAdditionalInfo",
                          type: "v-btn",
                          variant: "btn",
                          name: "saveAdditionalInfo",
                          divStyle: "",
                          inputText: "Kaydet",
                          btnTypeText: true,
                          color: "success",
                          classTag: "",
                          closeBtn: true,
                          onClick: function(thisData, model, dialogInfo) {
                            sendRequest(Enum.requestType.POST,"insuranceBulkUpdate","",
                            {
                              updateObj: {
                                sgkPrim: model.sgkPrim,
                                value689: model.value689,
                                defter: model.defter,
                                cookieData: window.$cookies.get("selectedItem")
                              }
                            },
                            function (response) {
                              closeDialog();
                              openSnackbar("success", "Verileriniz Başarılı Bir Şekilde Kaydedildi", "2000")
                            },
                              function (error) {
                                errFunc(error);
                                openSnackbar("error", "Verileri Kaydederken Bir Hata Oluştu", "2000")
                                closeDialog();
                              }
                            )
                          },
                        },  
                      ],
                      layout: [
                        {
                          type: Enum.layoutType.div,
                          name: "infoTableDialog",
                          dialogVisible: "dialogVisible",
                          inputElements: [
                            [
                             {
                                id : "sgkPrim",
                                type : "v-text-field",
                                readonly: true,
                                variant : "text",
                                name : "sgkPrim",
                                label : "SGK Prim",
                                value : "",
                                placeHolder : "",
                                style : "width: 200px;",
                                classTag : "",
                              },
                            ],
                            [
                              {
                                id : "defter",
                                type : "v-text-field",
                                variant : "text",
                                readonly: true,
                                name : "defter",
                                label : "Defter",
                                value : "",
                                placeHolder : "",
                                style : "width: 200px;",
                                classTag : "",
                              },
                            ],
                            [
                              {
                                id : "value689",
                                type : "v-text-field",
                                variant : "text",
                                readonly: true,
                                name : "value689",
                                label : "689",
                                value : "",
                                placeHolder : "",
                                style : "width: 200px;",
                                classTag : "",
                              },
                            ],
                          ],
                        }
                      ],
                      titleActions: [
                        {
                          id: "close",
                          type: "v-btn",
                          variant: "btn",
                          name: "close",
                          divStyle: "",
                          inputText: "Kapat",
                          btnTypeText: true,
                          color: "red",
                          classTag: "",
                          icon: "mdi-close",
                          closeBtn: true,
                          onClick: function(thisData, model, dialogInfo) {
                            console.log(thisData, model, dialogInfo);
                            closeDialog();
                          },
                        },
                      ],
                    }
                    showDialog(dialogInfo);
                  },
                }
              ],
            ],
          }
        ],
        headers: [
          { text: "Poliçe No", value: "insuranceNo", width: "100", divider: true,},
          { text: "Tutar", value: "amount", width:"100", dataType: "currency" , divider: true, align: "right"},
          { text: "Başlangıç Tarihi", value: "startDate", width: "100", dataType: "date", divider: true,},
          { text: "Bitiş Tarihi", value: "endDate", width: "100",  dataType: "date", divider: true,},
          { text: "Kur", value: "rate", width: "100",  dataType: "currency", divider: true, align: "right"},
          { text: "Araç Kısıtlaması", value: "vehicleRestriction" , width: "100", switchValue: {"true" : "Var", "false" : "Yok"}, divider: true,},
        ],
        columns: {
          insuranceNo: {
            type: "v-text-field",
            label: "Poliçe No",
            editable: false,
            creatable: true,
            inlineEditable:false,
          },
          amount: {
            type: "v-text-field",
            label: "Tutar",
            editable: false,
            creatable: true,
            inlineEditable:false,
          },
          startDate: {
            label: "Başlangıç Tarihi",
            type : "v-date-picker",
            readonly: true,
            editable: false,
            creatable: true,
            inlineEditable:false,
          },
          endDate: {
            label: "Bitiş Tarihi",
            type : "v-date-picker",
            readonly: true,
            editable: false,
            creatable: true,
            inlineEditable:false,
          },
          rate: {
            type: "v-text-field",
            label: "Kur",
            editable: false,
            creatable: true,
            inlineEditable:false,
          },
          vehicleRestriction: {
            type: "v-checkbox",
            label: "Araç Kısıtlaması",
            editable: false,
            creatable: true,
            inlineEditable:false,
          },
        }
      };
    },
    copyItem(tableName,selectedItem,options,model,onBeforeSave,onAfterSave,errorFunction) {
      if (!Array.isArray(selectedItem)) {
          selectedItem = [selectedItem];
      }
      
      let dynamicElements = getTable(tableName);
      if (options != undefined && options.dialogText != undefined) {
          dynamicElements.deleteDialogText = options.dialogText;
      }
      
      let title = "Yeni Kayıt Eklemek İstediğinize Emin Misiniz?";
      
      dynamicElements.forceRender();
      model.close = "Hayır",
      model.insertItem = "Evet";
      dynamicElements.dialogVisible = true;
      dynamicElements.dialogInfo = {
          name:"insertDialog",
          maxWidth : "1100",
          dialogVisible : "dialogVisible",
          title : title,
          layout : [
              {
                type : Enum.layoutType.div,
                name : "loginForm",
      
                inputElements : [
                ]
              }
          ],
          actions : [
            {
              id : "close",
              type : "v-btn",
              variant : "btn",
              name : "close",
              inputText : "Kapat",
              divStyle:"",
              btnTypeText: true,
              color:"green darken-1",
              classTag : "",
              onClick : function(thisData,model,dialogInfo) {
                var tableObject = getTable(tableName);
                tableObject.dialogVisible = false;
                tableObject.forceRender();
                console.log(thisData,model,dialogInfo);
              }
            },
            {
              id : "insertItem",
              type : "v-btn",
              variant : "btn",
              name : "insertItem",
              inputText : "Ekle",
              divStyle:"",
              btnTypeText: true,
              color:"green darken-1",
              classTag : "",
              onClick : function(thisData,model,dialogInfo) {
                var tableObject = getTable(tableName);
                console.log(thisData,model,dialogInfo);
                tableObject.dialogVisible = false;
                tableObject.forceRender();
                let creatableObjects = {}; 
                sendRequest(Enum.requestType.POST, dynamicElements.saveUrl,"",{selectedItem,creatableObjects},function (res) {
                    openTablePage(tableName);
                    openSnackbar("success", "Eklemeniz Başarılı Bir Şekilde Gerçekleşti");
                },function (error) {
                    if (errorFunction != undefined) {
                        errorFunction(error,selectedItem,creatableObjects,model,tableObject);
                    } else if(tableObject.clientEvents.onSaveError != undefined){
                        tableObject.clientEvents.onSaveError(error,selectedItem,creatableObjects,model,tableObject);
                    }
                });
              }
            },
          ]
        };
      },
    },
  }
  
    
  
  </script>
  
  <style>
  .header-div {
  text-align: center;
  }
  </style>