<template>
  <v-app>
    <div class="header-div">
      <h3 class="header-h3">Vergi Hesabı</h3>
    </div>
      <table-component :dynamic-element="tableElements" :model="model" />
      <Loader></Loader>
      <snackbar-component />
  </v-app>
</template>
<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";

import {
openSnackbar,
openTablePage,
showDialog,
closeDialog,
showLoader, 
hideLoader 
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import {axiosUploadTypeArrayBuffer} from "../../helper/uploadHelperForData"
export default {
  components: {
      "table-component": tableComponent,
      "upload-component": uploadComponent,
      Loader: Loader,
      "snackbar-component": snackbarComponent,
  },

  data() {
      return {
        tableElements: {},
        model: {},
        renderKey: 0,
      };
  },
  created() {
      this.loadData();
  },
  watch: {},
  methods: {
  loadData() {
    this.tableElements = {
      sortBy: [],
      sortDesc: false,
      renderKey: 0,
      type: "table",
      tableHeight : 530,
      name: "taxCalculationTable",
      class: "",
      infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
      excelDownloadBtnText: "İndir",
      deleteDialogVisible : true,
      hidePagination: true,
      //selectedRows: [],
      filteredColumn: {},
      filteredColumns: true,
      selectionUrl: "taxCalculation",
      toolbars: [
        {
          type: Enum.layoutType.div,
          name: "toolbarsForm",
          inputElements: [

            [
              {
                id: "excelDownload",
                type: "v-btn",
                variant: "btn",
                name: "excelDownload",
                inputText: "excelDownloadBtnText",
                style:"height: 25px !important;",
                divStyle: "",
                icon: "mdi-file-excel",
                btnTypeText: false,
                color: "success",
                prependIcon: "",
                classTag: "",
                onClick: function(thisData, model, dynamicElements) {
                  showLoader('Dosyanız Hazırlanıyor')
                  sendRequest(Enum.requestType.POST,"taxCalculation","",
                    {
                      excelDownload : true,
                      choice : "taxCalculationTable"
                    },
                    function (response) {
                      if (response.data.data.data != true) {
                        openSnackbar('error', "İndirilecek Veri Bulunamadı", 3000)
                        hideLoader();
                        return false
                      }
                      let zipName = "Vergi Hesabı Rapor"
                      axiosUploadTypeArrayBuffer("allExcelZipDownload", "taxCalculationTable", zipName, "Excel İndirildi")
                    },
                    function (error) {
                      openSnackbar(
                        "error",
                        error.response.data.error[0].detail.message
                      );
                      hideLoader();
                    }
                  )
                },
              }
            ],
          ],
        }
      ],
      headers: [
        { text: "Açıklamalar", value: "description", width: "100"},
        { text: "Değerler", value: "value", width: "100", dataType: "currency"},
        { text: "Definition Id", value: "definitionId", width: "100", dataType: "currency", visible: false},
        { text: "Section Id", value: "sectionId", width: "100", dataType: "currency", visible: false}
      ],
      columns: {
        description: {
            type: "v-text-field",
            editable: false,
            creatable: true,
            inlineEditable: false,
            //cell için gerekli inputElement hücre özelliştirmesi için
            cellElement: {
              id: "description",
              type: "v-btn",
              variant: "btn",
              name: "description",
              inputText: "description",
              divStyle: "",
              editable: true,
              creatable: true,
              inlineEditable: true,
              style: "height:0px;text-decoration: underline;color: blue;",
              btnTypeText: true,
              color: "black",
              prependIcon: "",
              classTag: "",
              onClick: function(thisData, model, dynamicElements) {
                if (model.description == "İstisnalar Ve İndirimler (Zarar Olsa Dahi)" || model.description == "İstisnalar Ve İndirimler (Kazancın Bulunması Halinde)" || model.description == "Mahsup Edilecek Vergiler") {
                  let dialogInfo = {
                  name: "infoTableDialog",
                  title: model.description,
                  layout: [
                    {
                      type: Enum.layoutType.div,
                      name: "tableDialog",
                      tableObject: {
                        tableHeight: "400",
                        dialogTable: true,
                        renderKey: 50,
                        type: "table",
                        name: "infoTable",
                        class: "",
                        infoTotalRowCountHeaderAssistantTable: "Toplam satır sayısı",
                        selectionUrl: "queryvprojectattachment",
                        hidePagination: false,
                        filteredColumn: {
                          choice: model.description
                        },
                        toolbars: [],
                        headers: [
                          {
                            text: "Açıklama",
                            value: "description",
                            width: "160",
                            search: true,
                            divider: true,
                          },
                          {
                            text: "Değer",
                            value: "value",
                            width: "150",
                            align: "right",
                            dataType: "currency",
                          },
                          
                        ],
                        footer: [],
                        singleSelect: true,
                        itemKey: "id",
                        columns: {
                          value: {
                            type: "v-text-field",
                            editable: true,
                            creatable: true,
                            width: "200",
                            inlineEditable: true,
                            label: "Değer",
                            onSave: function(thisData, model, dynamicElements) {
                              showLoader("Dosyanız Yükleniyor. Lütfen Bekleyiniz.");
                              sendRequest(Enum.requestType.POST,"updateProjectAttachmentValue","",
                              {
                                updateObj: {
                                  value: model.value,
                                  sectionId: model.sectionId,
                                  definitionId: model.definitionId
                                }
                              },
                              function (response) {
                                openTablePage("taxCalculationTable")
                              },
                              function (error) {
                                openSnackbar(
                                  "error",
                                  error.response.data.error[0].detail.message
                                );
                                hideLoader();
                              }
                              )
                            },
                          }
                        },
                      },
                    },
                  ],
                  actions: [
                  ],
                  titleActions: [
                    {
                      id: "close",
                      type: "v-btn",
                      variant: "btn",
                      name: "close",
                      divStyle: "",
                      inputText: "Kapat",
                      btnTypeText: true,
                      color: "red",
                      classTag: "",
                      icon: "mdi-close",
                      closeBtn: true,
                      onClick: function(thisData, model, dialogInfo) {
                        console.log(thisData, model, dialogInfo);
                        closeDialog();
                      },
                    },
                  ],
                };
                //var dialogObject = getTableModel("assistantTable");
                // var tableObject = getTable("assistantTable");
                // tableObject.filteredColumn.receiptNumber = model.receiptNumber
                // tableObject.filteredColumn.assistantDate = model.billDate
                showDialog(dialogInfo);
                }
                
              },
            },
          },
       }
    };
  }
},
}

  

</script>

<style>
.header-div {
text-align: center;
}
</style>