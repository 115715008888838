import store from "../../../../vue/src/store/index.js";
import {sendRequest} from "../helper/requestHelper.js";
import Enum from "../../Enum.json";
import XLSX from "xlsx-js-style";
import { router } from "../../../../vue/src//router/router";


export function getElement(name,dynamicElements){
    let result = null;
    dynamicElements.forEach(containers => {
        if (result != null) {
            return;
        }
        containers.inputElements.forEach(columns => {
            if (result != null) {
                return;
            }
            columns.forEach(element => {
                if (element.name == name) {
                    result = element;
                    return ;
                }
            });
        });
    });
    return result;
}


//dynamic Element return eder
export function getTable(tableName){
    return store.state.tableList[tableName];
}
export function getDiv(divName){

    let x = store.state;
    return store.state.divList[divName];
}
export function getTableModel(tableName){
    return store.state.tableModel[tableName];
}
export function getDivModel(divName){
    return store.state.divModel[divName];
}

export function setLoggedUser(name, obj) {
    window.$cookies.set(name, obj);
    router.push("/landing");

}
export function showTableColoumn(tableName , coloumnName){
    var dynamicElement = getTable(tableName);
    let headers = dynamicElement.headers;
    for (let index = 0; index < headers.length; index++) {
        if (headers[index].value == coloumnName) {
            headers[index].visible = true;
        }
    }
}

export function hideTableColoumn(tableName , coloumnName){
    var dynamicElement = getTable(tableName);
    let headers = dynamicElement.headers;
    for (let index = 0; index < headers.length; index++) {
        if (headers[index].value == coloumnName) {
            headers[index].visible = false;
        }
    }
}

export function showLoader(loaderText,loaderType,totalFileCount,currentFileCount) {

    if (loaderType === undefined) {
        
        let data = {
            loaderType: "basicShowLoader",
            loaderToUpdate: true,
            loaderText: loaderText,        
         };
    
         store.dispatch("showLoader", data);} 

    else if (loaderType === "uploadLoader"){
        
        let data = {
            loaderType: "uploadLoader",
            loaderToUpdate: true,
            loaderText: loaderText,            
            totalFileCount: totalFileCount,
            currentFileCount: currentFileCount,
        };

        store.dispatch("showLoader", data);
    }   
}

export function hideLoader() {
    let data = {
        loaderToUpdate: false
    };
    store.dispatch("hideLoader", data);
}


export function openSnackbar(color,text,timeout) {
    if(text != undefined){
        if(!timeout){
            timeout = 10000;
        }
        store.dispatch("setSnackbar", {color : color, text : text, timeout: timeout});
    }
}

export function buttonBooleanSetTimeOut (boo,timeout,thisData) {
    if (!timeout) {
        timeout = 10000;
    }
    setTimeout(function(){
       thisData.disabled = !boo;
    },timeout);
     thisData.disabled = boo;

}

export function openTablePage(tableName,pageNumber,onBeforeSave, forceRender){
    showLoader("Verileriniz Getiriliyor. Lütfen Bekleyiniz.");
    let dynamicElements = getTable(tableName);
    let model = getTableModel(tableName);
    if (dynamicElements.loadType == "staticArray") {
        return dynamicElements.data;
    }
    let filter = {
        tableName : dynamicElements.name,
        currentPageNo : pageNumber == undefined ? model[dynamicElements.name].currentPageNo : pageNumber,
        pageSize : model[dynamicElements.name].pageSize,
        filters : dynamicElements.filteredColumn,
        sortBy : dynamicElements.sortBy,
        sortDesc : dynamicElements.sortDesc,
        initialFilter : dynamicElements.initialFilter ? dynamicElements.initialFilter : ""
    };

    if (!Object.keys(dynamicElements).includes("sendObjectToGetTable")) {
        dynamicElements.sendObjectToGetTable = {};
    }
    sendRequest(Enum.requestType.POST, dynamicElements.selectionUrl, "" , {filter,sendObjectToGetTable : dynamicElements.sendObjectToGetTable},function (res) {
        dynamicElements = getTable(tableName);
        let paginationInfo = res.data.data;
        model[dynamicElements.name].itemCount = paginationInfo.recordsFiltered;
        model[dynamicElements.name].pagesCount = paginationInfo.paginationSize;
        dynamicElements.data  = paginationInfo.data;
        //GECİKME için burda concat yapabilirsin / test için
        if (forceRender != false) {
           dynamicElements.forceRender(); //render//2  //openTablePage fonksiyonun force render'ın çağırıldığı Yer!
        }
        if (onBeforeSave != undefined) {
            onBeforeSave(dynamicElements,model);
        }
        else if (dynamicElements.clientEvents.onafterUpload != undefined) {
            dynamicElements.clientEvents.onafterUpload(dynamicElements);
        }
        hideLoader();
       
    }, function (error) {openSnackbar("error", "Verileriniz Getirilirken Hata Oluştu"); }
    );
    
}
export function saveItem(tableName,selectedItem,options,model,onBeforeSave,onAfterSave,errorFunction) {
    if (!Array.isArray(selectedItem)) {
        selectedItem = [selectedItem];
    }

    let dynamicElements = getTable(tableName);
    if (options != undefined && options.dialogText != undefined) {
        dynamicElements.deleteDialogText = options.dialogText;
    }
    
    let title = "Yeni Kayıt Eklemek İstediğinize Emin Misiniz?";
    
    dynamicElements.forceRender();
    model.close = "Hayır",
    model.insertItem = "Evet";
    dynamicElements.dialogVisible = true;

    let inputElements = [];
    let resultFillInputElements = fillInputElements(dynamicElements,inputElements,dynamicElements.showDialogColumnsCount,"creatable");
    inputElements = resultFillInputElements.inputElements;
    dynamicElements.columns = resultFillInputElements.dynamicElementsColumns;
    dynamicElements.dialogInfo = {
        name:"insertDialog",
        maxWidth : "1100",
        dialogVisible : "dialogVisible",
        title : title,
        layout : [
            {
              type : Enum.layoutType.div,
              name : "insert",
    
              inputElements : inputElements
            }
        ],
        actions : [
          {
            id : "close",
            type : "v-btn",
            variant : "btn",
            name : "close",
            inputText : "Kapat",
            divStyle:"",
            btnTypeText: true,
            color:"green darken-1",
            classTag : "",
            onClick : function(thisData,model,dialogInfo) {
                var tableObject = getTable(tableName);
                tableObject.dialogVisible = false;
                tableObject.forceRender();
            }
          },
          {
            id : "insertItem",
            type : "v-btn",
            variant : "btn",
            name : "insertItem",
            inputText : "Ekle",
            divStyle:"",
            btnTypeText: true,
            color:"green darken-1",
            classTag : "",
            onClick : function(thisData,model,dialogInfo) {
                var tableObject = getTable(tableName);
                tableObject.dialogVisible = false;
                tableObject.forceRender();
                let creatableObjects = {};
                for (let i = 0; i < tableObject.dialogInfo.layout[0].inputElements.length; i++) {
                    for (let j = 0; j < tableObject.dialogInfo.layout[0].inputElements[i].length; j++) {
                        let key = tableObject.dialogInfo.layout[0].inputElements[i][j].name;
                        creatableObjects[key] = model[tableObject.dialogInfo.layout[0].inputElements[i][j].name];
                    }
                }
                if (!Object.keys(tableObject).includes("clientEvents")) {
                    tableObject.clientEvents = {};
                }

                let continueStatus = true;
                if (onBeforeSave != undefined) {
                    onBeforeSave(selectedItem,creatableObjects,model,tableObject);
                }
                else if (tableObject.clientEvents.onBeforeSave != undefined) {
                    continueStatus = tableObject.clientEvents.onBeforeSave(selectedItem,creatableObjects,model,tableObject);
                }
                if (continueStatus == undefined || continueStatus) {
                    sendRequest(Enum.requestType.POST, dynamicElements.saveUrl,"",{selectedItem,creatableObjects,tableName : dynamicElements.name, crudRequestObj : dynamicElements.crudRequestObj},function (res) {
                        if (onAfterSave != undefined) {
                            onAfterSave(selectedItem,creatableObjects,model,tableObject,res);
                        }
                        else if (tableObject.clientEvents.onAfterSave != undefined) {
                            tableObject.clientEvents.onAfterSave(selectedItem,creatableObjects,model,tableObject,res);
                        }
                        openTablePage(tableName);
                        openSnackbar("success", "Eklemeniz Başarılı Bir Şekilde Gerçekleşti");
                    },function (error) {
                        if (errorFunction != undefined) {
                            errorFunction(error,selectedItem,creatableObjects,model,tableObject);
                        } else if(tableObject.clientEvents.onSaveError != undefined){
                            tableObject.clientEvents.onSaveError(error,selectedItem,creatableObjects,model,tableObject);
                        }
                    });
                }
            }
          },
        ]
    };
}
export function updateItem(tableName,selectedItem,options,model,onBeforeUpdate,onAfterUpdate,errorFunction) {
    if (!Array.isArray(selectedItem)) {
        selectedItem = [selectedItem];
    }
    var globalModel = getTableModel(tableName);
    let dynamicElements = getTable(tableName);
    if (options != undefined && options.dialogText != undefined) {
        dynamicElements.deleteDialogText = options.dialogText;
    }

    let title = "";
    if (selectedItem.length > 0) {
        title = "Seçilen Kayıtları Güncellemek İstediğinize Emin Misiniz?";
        dynamicElements.forceRender();
        model.close = "Hayır",
        model.updateItem = "Evet";
        dynamicElements.dialogVisible = true;

        let inputElements = [];
        let resultFillInputElements = fillInputElements(dynamicElements,inputElements,dynamicElements.showDialogColumnsCount,"editable");
        inputElements = resultFillInputElements.inputElements;
        dynamicElements.columns = resultFillInputElements.dynamicElementsColumns;

        let columnsKeys = Object.keys(dynamicElements.columns);
        for (let i = 0; i < columnsKeys.length; i++) {
            delete globalModel[columnsKeys[i]];
        }
        dynamicElements.dialogInfo = {
            name:"updateDialog",
            maxWidth : "auto",
            dialogVisible : "dialogVisible",
            title : title,
            layout : [
                {
                  type : Enum.layoutType.div,
                  name : "loginForm",
      
                  inputElements : inputElements
                }
            ],
            actions : [
              {
                id : "close",
                type : "v-btn",
                variant : "btn",
                name : "close",
                inputText : "Kapat",
                divStyle:"",
                btnTypeText: true,
                color:"green darken-1",
                classTag : "",
                onClick : function(thisData,model,dialogInfo) {
                    var tableObject = getTable(tableName);
                    tableObject.dialogVisible = false;
                    tableObject.forceRender();
                }
              },
              {
                id : "updateItem",
                type : "v-btn",
                variant : "btn",
                name : "updateItem",
                inputText : "Kaydet",
                divStyle:"",
                btnTypeText: true,
                color:"green darken-1",
                classTag : "",
                onClick : function(thisData,model,dialogInfo) {
                    var tableObject = getTable(tableName);
                    tableObject.dialogVisible = false;
                    tableObject.forceRender();
                    let editableObjects = {};
                    for (let i = 0; i < tableObject.dialogInfo.layout[0].inputElements.length; i++) {
                        for (let j = 0; j < tableObject.dialogInfo.layout[0].inputElements[i].length; j++) {
                            let key = tableObject.dialogInfo.layout[0].inputElements[i][j].name;
                            editableObjects[key] = model[tableObject.dialogInfo.layout[0].inputElements[i][j].name];
                        }
                    }

                    if (!Object.keys(tableObject).includes("clientEvents")) {
                        tableObject.clientEvents = {};
                    }

                    if (onBeforeUpdate != undefined) {
                        onBeforeUpdate(selectedItem,editableObjects,model,tableObject);
                    }
                    else {
                        if (tableObject.clientEvents.onBeforeUpdate != undefined) {
                            tableObject.clientEvents.onBeforeUpdate(selectedItem,editableObjects,model,tableObject);
                        }
                    }

                    sendRequest(Enum.requestType.POST, dynamicElements.updateUrl,"",{tableName,selectedItem,editableObjects, cookieData: window.$cookies.get("selectedItem"), crudRequestObj : dynamicElements.crudRequestObj},function (res) {
                        tableObject.selectedRows = [];
                        if (onAfterUpdate != undefined) {
                            onAfterUpdate(selectedItem,editableObjects,model,tableObject,res);
                        }
                        else if (tableObject.clientEvents.onAfterUpdate != undefined) {
                            tableObject.clientEvents.onAfterUpdate(selectedItem,editableObjects,model,tableObject,res);
                        }
                        openTablePage(tableName);
                        openSnackbar("success", "Güncellemeniz Başarılı Bir Şekilde Gerçekleşti");
                    },function (error) {
                        if (errorFunction != undefined) {
                            errorFunction(error,selectedItem,editableObjects,model,tableObject);
                        } else if(tableObject.clientEvents.onErrorUpdate != undefined){
                            tableObject.clientEvents.onErrorUpdate(error,selectedItem,editableObjects,model,tableObject);
                        }
                    });
                }
              },
            ]
        };
    }
    else {
        openSnackbar("error", "Lütfen Satır Seçiniz");
    }
}
export function deleteItem(tableName,selectedItem,options,model,onBeforeDelete,onAfterDelete,errorFunction) {
    
    //debugger GECİKME
    if (!Array.isArray(selectedItem)) {
        selectedItem = [selectedItem];
    }
    //debugger GECİKME
    let dynamicElements = getTable(tableName);
    //debugger GECİKME

    if (dynamicElements.data.length > 0) {
        if (options != undefined && options.dialogText != undefined) {
            dynamicElements.deleteDialogText = options.dialogText;
        }

        let title = "";
        if (selectedItem.length > 0) {
            title = "Seçilen Kayıtları Silmek İstediğinize Emin Misiniz?";
        }
        else {
            title = "Tüm Kayıtları Silmek İstediğinize Emin Misiniz?";
            //debugger GECİKME
        }

        if (options == undefined || options.showDialog == true) {
            dynamicElements.forceRender();
            //debugger GECİKME

            model.close = "Hayır",
            model.deleteItem = "Evet";
            dynamicElements.dialogVisible = true;
            dynamicElements.dialogInfo = 
            {
                name:"deleteDialog",
                maxWidth : "1000",
                dialogVisible : "dialogVisible",
                title : title,
                actions : [
                  {
                    id : "close",
                    type : "v-btn",
                    variant : "btn",
                    name : "close",
                    inputText : "Hayır",
                    divStyle:"",
                    btnTypeText: true,
                    color:"green darken-1",
                    classTag : "",
                    onClick : function(thisData,model,dialogInfo) {
                        //debugger GECİKME
                        var tableObject = getTable(tableName);
                        tableObject.dialogVisible = false;
                        tableObject.forceRender();
                    }
                  },
                  {
                    id : "deleteItem",
                    type : "v-btn",
                    variant : "btn",
                    name : "deleteItem",
                    inputText : "Evet",
                    divStyle:"",
                    btnTypeText: true,
                    color:"green darken-1",
                    classTag : "",
                    onClick : function(thisData,model,dialogInfo) {
                        var tableObject = getTable(tableName);
                        tableObject.dialogVisible = false;
                        tableObject.forceRender();

                        if (!Object.keys(tableObject).includes("clientEvents")) {
                            tableObject.clientEvents = {};
                        }
                        
                        if (onBeforeDelete != undefined) {
                            onBeforeDelete(selectedItem,model,tableObject);
                        }
                        else if (tableObject.clientEvents.onBeforeDelete != undefined) {
                            let controllerResponse = tableObject.clientEvents.onBeforeDelete(selectedItem,model,tableObject);                            ;
                            if (controllerResponse == false) {
                                return false
                            }
                        }
                        showLoader("Verileriniz Siliniyor")

                        //data staticArray ise silme işlemi! //fakeSilme İşlemi
                        if (tableObject.loadType == "staticArray") {
                            if (selectedItem.length > 0 && tableObject.data.includes(selectedItem[0])) {
                                tableObject.data = tableObject.data.filter(function (item) { return !selectedItem.includes(item); });
                                return;
                            } 
                            else {
                                   tableObject.data = []
                                   return;
                                }
                        }


                        sendRequest(Enum.requestType.POST, dynamicElements.deleteUrl,"",{tableName, selectedItem,crudRequestObj : dynamicElements.crudRequestObj, cookieData: window.$cookies.get("selectedItem")},function (res) {
                            tableObject.selectedRows = [];
                            if (onAfterDelete != undefined) {
                                onAfterDelete(selectedItem,model,tableObject,res);
                            }
                            else if (tableObject.clientEvents.onAfterDelete != undefined) {
                                tableObject.clientEvents.onAfterDelete(selectedItem,model,tableObject,res);
                            }
                            hideLoader()
                            openTablePage(tableName);
                            openSnackbar("success", "Veriler Başarılı Bir Şekilde Silindi",2500);
                        },function (error) {
                            if (errorFunction != undefined) {
                                hideLoader()
                                errorFunction(error,selectedItem,model,tableObject);
                            } else if(tableObject.clientEvents.onDeleteError != undefined){
                                tableObject.clientEvents.onDeleteError(selectedItem,model,tableObject);
                            }
                            
                        });
                    }
                  },
                ]
            };
            //debugger GECİKME
        }
        else {
            if (onBeforeDelete != undefined) {
                onBeforeDelete(selectedItem,model,dynamicElements);
            }
            else if (dynamicElements.clientEvents.onBeforeDelete != undefined) {
                dynamicElements.clientEvents.onBeforeDelete(selectedItem,model,dynamicElements);
            }

            sendRequest(Enum.requestType.POST, dynamicElements.deleteUrl,"",{selectedItem},function (res) {
                if (onAfterDelete != undefined) {
                    onAfterDelete(selectedItem,model,dynamicElements,res);
                }
                else if (dynamicElements.clientEvents.onAfterDelete != undefined) {
                    dynamicElements.clientEvents.onAfterDelete(selectedItem,model,dynamicElements,res);
                }
                openTablePage(tableName);
                openSnackbar("success", "Veriler Başarılı Bir Şekilde Silindi", 2500);
            },function (error) {
                if (errorFunction != undefined) {
                    errorFunction(error,selectedItem,model,dynamicElements);
                } else if(dynamicElements.clientEvents.onDeleteError != undefined){
                    dynamicElements.clientEvents.onDeleteError(error,selectedItem,model,dynamicElements);
                }
                
            });
        }
        
    }else {
        openSnackbar("alert", "Veriniz mevcut değildir");
        //debugger GECİKME
    }
}
//KALDIRALACAK YERİ YOK BURADA 
export function passivePull(tableName,selectedItem,options,model) {
    if (!Array.isArray(selectedItem)) {
        selectedItem = [selectedItem];
    }

    let dynamicElements = getTable(tableName);
    if (options != undefined && options.dialogText != undefined) {
        dynamicElements.deleteDialogText = options.dialogText;
    }

    let title = "";
    if (selectedItem.length > 0) {
        title = "Seçilen Kayıtları Pasife Çekmek İstediğinize Emin Misiniz?";
    }
    else {
        title = "Seçilen Kayıtları Pasife Çekmek İstediğinize Emin Misiniz?";
    }

    if (options == undefined || options.showDialog == true) {
        dynamicElements.forceRender();
        
        model.close = "Hayır",
        model.deleteItem = "Evet";
        dynamicElements.dialogVisible = true;
        dynamicElements.dialogInfo = 
        {
            name:"deleteDialog",
            maxWidth : "1000",
            dialogVisible : "dialogVisible",
            title : title,
            actions : [
              {
                id : "close",
                type : "v-btn",
                variant : "btn",
                name : "close",
                inputText : "Hayır",
                divStyle:"",
                btnTypeText: true,
                color:"green darken-1",
                classTag : "",
                onClick : function(thisData,model,dialogInfo) {
                    var tableObject = getTable(tableName);
                    tableObject.dialogVisible = false;
                    tableObject.forceRender();
                }
              },
              {
                id : "deleteItem",
                type : "v-btn",
                variant : "btn",
                name : "deleteItem",
                inputText : "Evet",
                divStyle:"",
                btnTypeText: true,
                color:"green darken-1",
                classTag : "",
                onClick : function(thisData,model,dialogInfo) {
                    var tableObject = getTable(tableName);
                    tableObject.dialogVisible = false;
                    tableObject.forceRender();
                    sendRequest(Enum.requestType.POST, dynamicElements.passivePullUrl,"",{selectedItem},function (res) {
                        openTablePage(tableName);
                        openSnackbar("success", "Veriler Başarılı Bir Şekilde Pasife Çekildi");
                    }, function (error) {openSnackbar("error", "Veriler Pasife Çekilemedi!");});
                }
              },
            ]
        };
    }
    else {
        sendRequest(Enum.requestType.POST, dynamicElements.passivePullUrl,"",{selectedItem},function (res) {
            openTablePage(tableName);
            openSnackbar("success", "Veriler Başarılı Bir Şekilde Pasife Çekildi");
        }, function (error) {openSnackbar("error", "Veriler Pasife Çekilemedi!");});
    }
}

export function manualReceiptAssign(tableName,selectedItem,options,model) {
    if (!Array.isArray(selectedItem)) {
        selectedItem = [selectedItem];
    }

    let dynamicElements = getTable(tableName);
    if (options != undefined && options.dialogText != undefined) {
        dynamicElements.deleteDialogText = options.dialogText;
    }
    let inputElements = [
            {
                type: "v-select",
                variant: "number",
                editable: true,
                creatable: true,
                inlineEditable: true,
                //value: ['Aktif'],
                label: "Fiş Türü",
                style: "width: 200px",
                dropDownItems: ["Açılış", "Mahsup", "Kur Farkı", "Yansıtma", "Kar Zarar Virman", "Kapanış", "Adat"]
            }
        ]
    let title = "";
    if (selectedItem.length > 0) {
        title = "Seçilen Kayıtları İçin Fiş Türü Seçiniz";
    }
    else {
        title = "Seçilen Kayıtları İçin Fiş Türü Seçiniz";
    }
    if (options == undefined || options.showDialog == true) {
        
        model.close = "Hayır",
        model.deleteItem = "Evet";
        dynamicElements.dialogVisible = true;
        dynamicElements.dialogInfo = 
        {
            name:"deleteDialog",
            maxWidth : "500",
            dialogVisible : "dialogVisible",
            title : title,
            layout : [
                {
                  type : Enum.layoutType.div,
                  name : "loginForm",
        
                  inputElements : inputElements,
                }
            ],
            actions : [
              {
                id : "close",
                type : "v-btn",
                variant : "btn",
                name : "close",
                inputText : "Hayır",
                divStyle:"",
                btnTypeText: true,
                color:"green darken-1",
                classTag : "",
                onClick : function(thisData,model,dialogInfo) {
                    var tableObject = getTable(tableName);
                    tableObject.dialogVisible = false;
                    tableObject.forceRender();
                }
              },
              {
                id : "deleteItem",
                type : "v-btn",
                variant : "btn",
                name : "deleteItem",
                inputText : "Evet",
                divStyle:"",
                btnTypeText: true,
                color:"green darken-1",
                classTag : "",
                onClick : function(thisData,model,dialogInfo) {
                    var tableObject = getTable(tableName);
                    tableObject.dialogVisible = false;
                    tableObject.forceRender();
                    sendRequest(Enum.requestType.POST, "manuelReceiptAssign","",{selectedItem, model, cookieData: window.$cookies.get("selectedItem")},function (res) {
                        openTablePage(tableName);
                        openSnackbar("success", "Veriler Başarılı Bir Şekilde Pasife Çekildi");
                    }, function (error) {openSnackbar("error", "Veriler Pasife Çekilemedi!");});
                }
              },
            ],
        };
        dynamicElements.forceRender();
    }
    else {
        sendRequest(Enum.requestType.POST, dynamicElements.passivePullUrl,"",{selectedItem},function (res) {
            debugger
            openTablePage(tableName);
            openSnackbar("success", "Veriler Başarılı Bir Şekilde Pasife Çekildi");
            
        },function (error) {openSnackbar("error", "Veriler Pasife Çekilemedi!");});
    }
}

export function excelDownload(tableName) {
    showLoader("Exceliniz Hazırlanıyor. Lütfen Bekleyiniz.");
    let dynamicElements = getTable(tableName);
    let model = getTableModel(tableName);
    let rowSelections = [] , excelHeaders = [];
    for (let index = 0; index < dynamicElements.headers.length; index++) {
        if(dynamicElements.headers[index].visible == true || dynamicElements.headers[index].visible == undefined){
            rowSelections.push(dynamicElements.headers[index].value);
            excelHeaders.push(dynamicElements.headers[index].text);
        }
    }
    let filter = {
        tableName : dynamicElements.name,
        currentPageNo : 1,
        pageSize : model.itemCount,
        filters : dynamicElements.filteredColumn,
        sortBy : dynamicElements.sortBy,
        sortDesc : dynamicElements.sortDesc
    };
    sendRequest(Enum.requestType.POST, dynamicElements.selectionUrl, "" , {filter,sendObjectToGetTable : {selectionRows : rowSelections}},function (res) {
        let excelData = res.data.data.data;
        let jsSheet = XLSX.utils.json_to_sheet(excelData, {
            origin: "A1",
            range: 1,
            raw: false,
        });
        XLSX.utils.sheet_add_aoa(jsSheet, [excelHeaders], {
            origin: "A1",
        });
        let wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, jsSheet, "Sheet1");
        XLSX.writeFile(wb, Enum.excelNames[tableName] + ".xlsx");
        hideLoader();
    }, function (error) {openSnackbar("error", "Exceliniz Hazırlanırken Hata Oluştu"); });
}

export function excelDownloadDefault(sheetNameArray, excelResult, excelName) {
    XLSX.writeFile({
        SheetNames:sheetNameArray,
        Sheets: excelResult 
      }, excelName);
}

export function excelDownloaded(sheetName, excelHeaders, excelResult, excelName) {
    let jsSheet = XLSX.utils.json_to_sheet(excelResult, {
        origin: "A1",
        range: 1,
        raw: false,
    });
    XLSX.utils.sheet_add_aoa(jsSheet, [excelHeaders], {
        origin: "A1",
    });
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, jsSheet, sheetName);
    XLSX.writeFile(wb, excelName + ".xlsx");
}


export function includeTable(tableName, tableObject, tableModel){
    if (store.state.tableList == undefined) {
        store.state.tableList = {};
        store.state.tableModel = {};
    }
    store.state.tableList[tableName] = tableObject;
    store.state.tableModel[tableName] = tableModel;
}
export function showDialog(content) {
    let states = store.state;
    if (content != undefined) {
        if (store.state.globalDialog == undefined) {
            store.state.globalDialog = {}
            store.state.globalDialog.globalDialogs = []
        }
        store.state.globalDialog.globalDialogs.push({});
        store.state.globalDialog.globalDialogs[store.state.globalDialog.globalDialogs.length - 1].dialogInfo = content;
    }
    store.state.globalDialog.globalDialogs[store.state.globalDialog.globalDialogs.length - 1].globalDialogVisible = true;
    store.state.globalDialog.globalDialogs[store.state.globalDialog.globalDialogs.length - 1].dialogVisible = true;
    store.state.globalDialog.globalDialogs[store.state.globalDialog.globalDialogs.length - 1].renderKey = hashCode();
    store.state.globalDialog.renderKey2++;
    //accountPlan (dimro) da iki kez render atıp toolbar iki kez çiziliyor o nedenle aşağıdaki render yorum satırına alındı
    store.state.globalDialog.renderKey++;
}
export function closeDialog() {
    store.state.globalDialog.globalDialogs.pop();
    store.state.globalDialog.renderKey = 101;
    store.state.globalDialog.renderKey++;
}
export function showDialogTable(dynamicElements,dialogInfo) {
    dynamicElements.dialogVisible = true;
    dynamicElements.dialogInfo = dialogInfo
    dynamicElements.renderKey++
}
export function closeDialogTable(dynamicElements) {
    dynamicElements.dialogVisible = false;
    dynamicElements.dialogInfo = undefined;
    dynamicElements.renderKey++
}
export function showLoading(){

}

export function hideLoading() {
    
}

export function hashCode(){
    let uuidv4 = function () {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == "x" ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    };
    return uuidv4().split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a;},0);              
}

export function fillInputElements (dynamicElements,inputElements,columnsCount,type){
    if (columnsCount == undefined) {
        columnsCount = 6;
    }
    for (let i = 0; i < dynamicElements.headers.length; i++) {
        if (!Object.keys(dynamicElements.columns).includes(dynamicElements.headers[i].value)) {
            dynamicElements.columns[dynamicElements.headers[i].value] = {
                label : dynamicElements.headers[i].text,
                type : "v-text-field",
                editable: true,
                creatable: true
            };
        }
    }
    
    for (let i = 0; i < columnsCount; i++) {
        inputElements.push([]);
    }
    let i = 0;
    Object.keys(dynamicElements.columns).forEach(key => {
        if (dynamicElements.columns[key][type]) {
            let object = {
                id : key,
                type : dynamicElements.columns[key].type,
                variant : dynamicElements.columns[key].variant,
                name : key,
                readonly : dynamicElements.columns[key].readonly,
                label : dynamicElements.columns[key].label || key,
                value : dynamicElements.columns[key].value,
                prependIcon : dynamicElements.columns[key].prependIcon,
                placeHolder : dynamicElements.columns[key].placeHolder,
                style : dynamicElements.columns[key].style,
                classTag : dynamicElements.columns[key].classTag,
                rules : dynamicElements.columns[key].rules,
                inputText : dynamicElements.columns[key].inputText,
                dropDownItems : (dynamicElements.columns[key].type == "v-select") ?  dynamicElements.columns[key].dropDownItems : undefined,
                multiple : dynamicElements.columns[key].multiple,
                beforeOnChange : dynamicElements.columns[key].beforeOnChange,
                onChange : dynamicElements.columns[key].onChange,
                afterOnChange : dynamicElements.columns[key].afterOnChange,
                beforeOnClick : dynamicElements.columns[key].beforeOnClick,
                onClick : dynamicElements.columns[key].onClick,
                afterOnClick : dynamicElements.columns[key].afterOnClick,
                beforeOnInput : dynamicElements.columns[key].beforeOnInput,
                onInput : dynamicElements.columns[key].onInput,
                afterOnInput : dynamicElements.columns[key].afterOnInput,
                disabled : (dynamicElements.columns[key].disabled == undefined) ?  false : dynamicElements.columns[key].disabled,
                url : dynamicElements.columns[key].url,
                searchEventControl : dynamicElements.columns[key].searchEventControl,
                crudRequestObj : dynamicElements.columns[key].crudRequestObj,
                counter : dynamicElements.columns[key].counter,
                hideDetails : dynamicElements.columns[key].hideDetails,
                maxLength : dynamicElements.columns[key].maxLength
            };
            inputElements[i % columnsCount].push(object);
            i++;
        }
    });
    return {dynamicElementsColumns : dynamicElements.columns,inputElements};
}

//renderOrganizer fonksiyonu gereksiz render işlemlerini engellemek için kullanılır.
//birden fazla forceRender çağırıldığında sadece sonuncu forceRender çalışır.
//renderOrganizer fonksiyonu içindeki fonksiyonlar çalıştırılmadan önce 50ms beklenir.
//renderOrganizer fonksiyonu içindeki fonksiyonlar çalıştırılmadan önce 50ms beklenmesinin
//sebebi birden fazla forceRender çağırıldığında sadece sonuncu forceRender çalışsın diye.
export function renderOrganizerFunction (dyn,dynamicElement) {
           dynamicElement.renderOrganizer = {}
           dynamicElement.forceRender = function () {     
                let processId = hashCode();
                dyn.renderOrganizer = {};
                dyn.renderOrganizer[processId] = function () {
                    dyn.renderKey++; 
                    dyn.renderKey2++;
                }
                setTimeout(function(){
                    if (dyn.renderOrganizer[processId] != undefined){
                        dyn.renderOrganizer[processId]();
                    }  
                }, 100);    
            } 
            return dynamicElement.forceRender;
    
}

export function  currencyFormat(num, decimalLength, thousandLength, decimalSeparator, thousandSeparator) {
    let number = typeof num
    if ("number" == number) {
      if(decimalSeparator == thousandSeparator || isNaN(num))
      throw num
      let left = num.toFixed(thousandLength).replace('.', decimalSeparator).replace(new RegExp('(\\d)(?=(\\d{'+thousandLength+'})+(?!\\d))', 'g'), '$1' + thousandSeparator).split(decimalSeparator)[0];
      let right = num.toFixed(decimalLength).replace('.', decimalSeparator).replace(new RegExp('(\\d)(?=(\\d{'+decimalLength+'})+(?!\\d))', 'g'), '$1' + thousandSeparator).split(decimalSeparator)[1];
      const total = left + decimalSeparator + right;
      return total
    }else{
      return num
    }
  }
  
export function currencyInputFormat(input, decimalLength, decimalSeparator, thousandSeparator) {
    // currenctInputFormatter NOT: input STRING gelmeli
    const cleanedInput = input.toString().replace(/[\.,]/g, '');
    const integerPartLength = cleanedInput.length - decimalLength; 

    let decimalPart = cleanedInput.slice(integerPartLength);
    const integerPart = cleanedInput.slice(0,integerPartLength);
    let formattedIntegerPart=""

    function addThousandSeparator(input, separator) {
        const reversedInput = input.split('').reverse().join('');
        const separatedInput = reversedInput.replace(/(\d{3}(?=\d))/g, '$1' + separator);
        return separatedInput.split('').reverse().join('');
    }

    formattedIntegerPart = addThousandSeparator(integerPart, thousandSeparator);
    const formattedNumber = formattedIntegerPart + decimalSeparator + decimalPart;
    return formattedNumber;
}

 

  
