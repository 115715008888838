<template>
    <div>
        <chart :chart-obj="chartObj"/>
        <chart :chart-obj="chartObj2"/>
        <chart :chart-obj="chartObj3"/>
    </div>
</template>

<script>
import Enum from '../../../frontend/components/Enum.json'
import chart from '../../../frontend/components/atoms/container/chartComponent.vue'
export default {
    components: {
        chart,
    },
    data () {
      return {
        chartObj:{
            chartType : Enum.chart.lineChart,
            chartName : "LineChart",
            labels : ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                  label: 'Data One',
                  backgroundColor: '#f87979',
                  data: [40, 39, 10, 40, 39, 80, 40]
                },
                {
                  label: 'Data Two',
                  backgroundColor: '#800000',
                  data: [100, 20, 90, 40, 39, 80, 20]
                }
            ]
        },
        chartObj2:{
            chartType : Enum.chart.barChart,
            chartName : "BarChart",
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            datasets: [
              {
                label: 'Data One',
                backgroundColor: '#f87979',
                data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 100]
              },
              {
                label: 'Data One',
                backgroundColor: '#800000',
                data: [44, 11, 120, 39, 1000, 40, 39, 80, 40, 20, 12, 100]
              }
            ]
        },
        chartObj3:{
            chartType : Enum.chart.pieChart,
            chartName : "PieChart",
            labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs','ERTAN ABİ'],
            datasets: [
              {
                backgroundColor: [
                  '#41B883',
                  '#E46651',
                  '#00D8FF',
                  '#DD1B16',
                  '#800000'
                ],
                data: [40, 20, 80, 10,170]
              }
            ]
        },
      }
    },
    methods:{
      
    }
}
</script>