// https://vuetifyjs.com/en/features/treeshaking/#dynamic-components
<template>
<div>
  <login-component :dynamic-elements="dynamicElements" :model="model" />
</div>

</template>

<script>
import Enum from "../../../frontend/components/Enum.json"
import {sendRequest} from "../../../frontend/components/atoms/helper/requestHelper"
import loginComponent from "../molecules/loginComponent.vue";
//import {setUserLoginData} from '../../../frontend/components/atoms/helper/elementHelper.js'
import {openSnackbar , setLoggedUser} from "../../../frontend/components/atoms/helper/elementHelper.js"
export default {
    components: { 'login-component' : loginComponent  },
    data: () => ({
      dynamicElements:[],
      model:{},
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model1: []
    }),
    created() {
      this.loadData ();
    },
    methods: {
      loadData () {
        this.model = {
          email : "",
          password: "",
          dateFormatted : ""
        }
        this.dynamicElements = [
          {
            type : Enum.layoutType.div,
            name : "loginForm",
            style : "margin-top: 200px",
            inputElements : [
              [ 
                {
                  id : "email",
                  type : "v-text-field",
                  variant : "text",
                  name : "email",
                  label : "Email",
                  value : "",
                  rules : [value => !!value || "Gerekli.", value => {
                      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                      return pattern.test(value) || "Geçersiz Email";
                    }
                  ],
                  prependIcon : "fas fa-at",
                  placeHolder : "",
                  style : "margin-bottom:15px;",
                  classTag : ""
                },
                {
                  id : "password",
                  type : "v-text-field",
                  variant : "password",
                  name : "password",
                  label : "Şifre",
                  style:"margin-bottom:10px",
                  value : "",
                  prependIcon : "lock",
                  placeHolder : "",
                  classTag : ""
                },
                {
                  id : "forgottenPass",
                  type : "v-btn",
                  variant : "btn",
                  name : "forgottenPass",
                  inputText : 'ŞİFREMİ UNUTTUM',
                  style:"margin-top:20px; margin-right: 10px",
                  divStyle:"float:left; ",
                  btnTypeText: true,
                  prependIcon : "lock",
                  classTag : "",
                  to : "/forgottenPageCreatEt"
                },
                {
                  id : "sendLogin",
                  type : "v-btn",
                  variant : "btn",
                  name : "sendLogin",
                  inputText : "Giriş",
                  divStyle:"float:left; ",
                  style:"font-size: 12px; padding: 3px 3px; heigth:3px; margin-top: 20px",
                  btnTypeText: false,
                  color : "primary",
                  prependIcon : "lock",
                  classTag : "",
                  onClick : function(thisData,model,dynamicElements) {
                      console.log(thisData,model,dynamicElements)
                    try {
                        sendRequest("POST","login","",{email : model.email, password : model.password},function (response) {
                          if (response.data.success == true) {
                              const userToken = response.data.data.token
                              let newInfo = {}
                              newInfo.isloggedin = true
                              newInfo.token = userToken
                              newInfo.userId = response.data.data.userId
                              newInfo.userName = response.data.data.userName
                              setLoggedUser("loggedUser", newInfo)
                              // if (error.response) {
                              //   openSnackbar('error', error.response.data.error[0].detail.message)
                              // }
                          }
                        })
                    } catch (error) {
                        openSnackbar('error', error)
                    }
                  }
                }
              ],
            ]
          }
        ]
        return this.dynamicElements;
      },
      remove (item) {
        const index = this.friends.indexOf(item.name)
        if (index >= 0) this.friends.splice(index, 1)
      },
    },
}
</script>