<template>
  <v-app> 
    <snackbar-component />
    <table-component :dynamic-element="tableElements" :model="model" />
    <upload-component :dynamic-element="uploadElements" :model="model" />
    <Loader></Loader>
    <snackbar-component />
  </v-app>
</template>

<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import {
  openSnackbar,
  showLoader,
  openTablePage,
  getTable,
  hideLoader
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import { sendRequest, uploadHelperFormDataQueue,axiosRequestTypeArrayBuffer } from "../../../frontend/components/atoms/helper/requestHelper";
import {axiosUploadTypeArrayBuffer} from "../../helper/uploadHelperForData"

export default {
  components: {
    "table-component": tableComponent,
    "upload-component": uploadComponent,
    Loader: Loader,
    "snackbar-component": snackbarComponent,
  },

  data() {
    return {
      uploadElements: {},
      tableElements: {},
      model: {},
      renderKey: 0
    };
  },

  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      let that = this;
      this.uploadElements = [
        {
          renderKey: 0,
          name: "uploadCard",
          interfaceName: "Tek Düzen Plan Yükle",
          width: 400,
          layout: [
            {
              type: Enum.layoutType.div,
              name: "loginForms",
              inputElements: [
                [
                  {
                    id: "mizan",
                    type: "v-file-input",
                    label: "Dosyanızı Seçiniz",
                    inputHeader: "Hesap Planı Yükle",
                    name: "mizanFile",
                    multiple: false,
                    onChange: async function(thisData, model, dynamicElements) {
                      let files = model.mizanFile;
                      if (model.mizanFile.size > 0) {
                        showLoader("Dosyanız Yükleniyor. Lütfen Bekleyiniz..."); 
                        let choiceData = {
                          choice : "accountPlanMizanExcel", 
                          headerAddFlag : true, 
                          projectId : window.$cookies.get("selectedItem").id,
                          insertType : "MizanValidation",
                          nextInsertType : "MizanDBInsert",
                          fileName : model.mizanFile.name,
                          auditDate: window.$cookies.get("selectedItem").auiditDate,
                          preriorDate: window.$cookies.get("selectedItem").preriorDate,
                          explain : window.$cookies.get("selectedItem").explain
                        }
                        uploadHelperFormDataQueue([files], "excelUpload", Enum.requestType.POST, choiceData, 
                        function (response) {
                          if (response.data.data.errorExcel != undefined) {
                            axiosRequestTypeArrayBuffer(response)
                            openSnackbar("error", "Taslak Hatası");
                          }else if (response.data.data.draftError != undefined ) {
                            openSnackbar("error", response.data.data.draftError);
                          } else {
                            //openSnackbar("success", "Dosya Yüklendi");
                            //openTablePage("accountPlanMain")
                            that.$router.push("folderNotification")
                          }
                          hideLoader();
                        })
                      }
                    }
                  }
                ],
              ]
            }
          ]
        },
      ],
      
      this.tableElements = {
        sortBy: [],
        sortDesc: false,
        renderKey: 0,
        type: "table",
        tableHeight : 320,
        name: "accountPlanMain",
        class: "",
        infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
        searchBtnText: "Ara",
        excelDownloadBtnText: "İndir",
        deleteDialogVisible : false,
        hidePagination: true,
        selectedRows: [],
        filteredColumn: {},
        filteredColumns: true,
        selectionUrl: "gettabledata",
        crudRequestObj : {
          placeOfAction : "mizanCrud"
        },
        initialFilter:{
          [Enum.initialFilter.AND] :
          [
            {
              company_id: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").companyId
              },project_id: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id
              },is_active: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").isActive
              }
            }
          ]
        },
        showDefaultDeleteButon : true,
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
              [
                {
                  id: "excelDownload",
                  type: "v-btn",
                  variant: "btn",
                  name: "excelDownload",
                  inputText: "excelDownloadBtnText",
                  style:"height: 26px !important;",
                  divStyle: "",
                  icon: "mdi-file-excel",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    sendRequest(Enum.requestType.POST,"accountPlan",null,
                          {
                              filter : {
                                choice : "accountPlan", orderBy : "orderBy"
                              },
                              excelDownload : true
                          },
                          function (response) {
                            //let periodName =  this.vatDirectSelection.replace("/", "_")
                            let zipName = "Mizan Tablo Listesi" + " " /*+ periodName*/
                            axiosUploadTypeArrayBuffer("allExcelZipDownload","accountPlan", zipName, "Excel İndirildi")
                          },
                          function (error) {
                            openSnackbar(
                              "error",
                              error.response.data.error[0].detail.message
                            );
                          }
                        )
                  },
                  
                }
              ],
            ],
          }
        ],
        headers: [
          { text: "Raporlanacak Hesap Kodu", value: "accountCodeReport",search: true , sortable: true },
          { text: "Hesap Kodu", value: "accountCode",search: true , sortable: true},
          { text: "Hesap Adı", value: "accountName",search: true , sortable: true},
          { text: "Hesap Tipi", value: "monetaryTypeString",search: true , sortable: true },
        ],
        columns: {
          accountCode: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: false,
            // onSave: function (thisData, model, dynamicElements) {
            //   let editableObjects = {}
            //   editableObjects[thisData.id] = model[thisData.id]
            //   sendRequest("POST","updateForSpesificTable","",{selectedItem : [model] ,editableObjects ,tableName : "accountPlan", tableKey : "account_code", forKey : "accountPlanId", sequl : "id"},
            //   function(response){
            //     openSnackbar("success", response.data.data.value)
            //   }, 
            //   function(error){
            //     if (error.response != undefined){
            //       openSnackbar("error", error.response.data.error[0].detail.message)
            //     } else (
            //       openSnackbar("error", "Sunucu Hatası")
            //     )
            //   })
            // }
          },
          accountName: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: false,
            // onSave: function (thisData, model, dynamicElements) {
            //   let editableObjects = {}
            //   editableObjects[thisData.id] = model[thisData.id]
            //   sendRequest("POST","updateAccountPlan","",{selectedItem : [model] ,editableObjects ,tableName : "t_account_plan", tableKey : "account_name"},function(response){
            //     openSnackbar("success", response.data.data.value)
            //   }, 
            //   function(error){
            //     if (error.response != undefined){
            //       openSnackbar("error", error.response.data.error[0].detail.message)
            //     } else (
            //       openSnackbar("error", "Sunucu Hatası")
            //     )
            //   })
            // }
          },
          accountTypeString: {
            type: "v-select",
            editable: true,
            creatable: true,
            inlineEditable: false,
            // dropDownItems: ["PARASAL", "PARASAL OLMAYAN"],
            // onSave: function (thisData, model, dynamicElements) {
            //   let editableObjects = {}
            //   editableObjects[thisData.id] = model[thisData.id]
            //   sendRequest("POST","updateAccountPlan","",{selectedItem : [model] ,editableObjects ,tableName : "t_account_plan", tableKey : "account_type"},function(response){
            //     openSnackbar("success", response.data.data.value)
            //   }, 
            //   function(error){
            //     if (error.response != undefined){
            //       openSnackbar("error", error.response.data.error[0].detail.message)
            //     } else (
            //       openSnackbar("error", "Sunucu Hatası")
            //     )
            //   })
            // }
          },
        },
        data: "data",
        showSelecetVisible: false,
        singleSelect: false,
        itemKey: "id",
        footer: [
          {
            type: Enum.layoutType.div,
            name: "footerForm",
            inputElements: [
              [
                {
                  id: "pageSize",
                  type: "v-text-field",
                  variant: "number",
                  name: "pageSize",
                  label: "Görünecek Satır Sayısı",
                  value: "",
                  placeHolder: "",
                  style: "width: 45%; margin-left: 5%",
                  classTag: "",
                  onChange: function(thisData, model, dynamicElements) {
                    openTablePage("accountPlanMain")
                  },
                },
              ],
              [
                {
                  id: "currentPageNo",
                  type: "v-pagination",
                  variant: "pagination",
                  name: "currentPageNo",
                  divStyle: "",
                  style: "width:850px;",
                  classTag: "my-1",
                  length: "pagesCount",
                  onInput: function(thisData, model, dynamicElements) {
                    openTablePage("accountPlanMain")
                  },
                },
              ],
              [
                {
                  id: "infoTotalRowCountHeaderAssistantTable",
                  type: "p",
                  variant: "p",
                  name: "infoTotalRowCountHeaderAssistantTable",
                  inputText: "infoTotalRowCountHeaderAssistantTable",
                  divStyle: "",
                  style: "font-size: 12px;font-weight:500;margin:3px 0;color:#666A6F;margin-right: 2%;margin-left: 60%;",
                  classTag: ""
                },
                {
                  id: "infoTotalRowCountAssistantTable",
                  type: "p",
                  variant: "p",
                  name: "infoTotalRowCountAssistantTable",
                  inputText: "itemCount",
                  divStyle: "",
                  style: "font-size: 15px;font-weight:500;margin:3px 0;color:#666A6F;margin-left: 82%;",
                  classTag: ""
                },
              ],
            ],
          },
        ]
      };
    }
  },
};
</script>

<style scoped>
::v-deep .threedot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 300px;
}
::v-deep .threedot:hover {
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}
</style>
