<template>
    <div>
        <div :key="topTableRenderKey" class="topTdMainBox">
            <div class="topTdBox">
                <div>{{componentElements.props.topTdHeaders.header1}}{{ componentElements.props.topTdHeaders.header1Text == undefined ? (model.kind == 0) ? "Satış Faturası" : "Alış Faturası" : componentElements.props.topTdHeaders.header1Text }}</div>
                <div v-if="!model.editShowModel" >{{componentElements.props.topTdHeaders.header2}}{{ model.billNo }}</div>
                <div v-if="model.editShowModel" class="tdInputBox"><div class="tdInputTitle">{{componentElements.props.topTdHeaders.header2}}</div><input v-model="model.desserts[0].billNo" class="tdInput"/></div>
                <div v-if="!model.editShowModel">{{componentElements.props.topTdHeaders.header3}}{{ new Date(model.billDate).toLocaleString('tr-TR').split(" ")[0] }}</div>
                <div v-if="model.editShowModel" class="tdInputBox"><div class="tdInputTitle">{{componentElements.props.topTdHeaders.header3}}</div><input  :value="formatDate(model.desserts[0].billDate)" class="tdInput" @change="handleBillDateChange($event,'billDate')" @input="dateMaskFunc($event)"/></div>            
            </div>
            <div class="topTdBox">
                <div v-if="!model.editShowModel">{{componentElements.props.topTdHeaders.header4}}{{String(model.name).substring(0,42)}}</div>
                <div v-if="model.editShowModel">{{componentElements.props.topTdHeaders.header4}}{{String(model.desserts[0].name).substring(0,42)}}</div>
                <div v-if="!model.editShowModel">{{componentElements.props.topTdHeaders.header5}}{{model.tinNumber}}</div>
                <div v-if="model.editShowModel" class="tdInputBox"><div>{{componentElements.props.topTdHeaders.header5}}</div><input  v-model="model.desserts[0].tinNumber" class="tdInput"/></div>
                <div v-if="model.eInvoiceType == 11">{{componentElements.props.topTdHeaders.header6}}{{ model.closingDate == undefined || model.closingDate == null ? new Date(model.billDate).toLocaleString('tr-TR').split(" ")[0] : new Date(model.closingDate).toLocaleString('tr-TR').split(" ")[0] }}</div>
            </div>
        </div>
        <div class="tableStyle" :key="model.tdTableRenderForAddRow">
            <div  class="tableHeader">
                <div v-for="header in componentElements.props.tableHeaders" :style="header.style" class="headerTitle">{{ header.title }}</div>
            </div>
            <div  class="tableBody">
              <div v-for="(dessert, index) in (model.paginationBoo && !model.editShowModel ? model.paginationDesserts : model.desserts)" class="tableRow">
                    <div v-for="headerData in componentElements.props.tableHeaders " :style="headerData.style" class="tableRowCell" :class="{ 'justify-end': headerData.dataType === 'money' }">
                        <div v-if="!model.editShowModel || !headerData.editable">{{headerData.value == "index" ? computedIndex(index) :  headerData.dataType == "money" ? `${currencyFormatProcessing(dessert[headerData.value],2,3,',','.')} ${model.currencyType}` : String(dessert[headerData.value]).substring(0,30) }}</div>
                        <input :ref="`textInput${index}`" v-if="model.editShowModel && headerData.editable && headerData.dataType == 'money' " :value="currencyFormatProcessing(dessert[headerData.value],2,3,',','.')"  class="tdInput1" @input="handleInput($event,index,headerData)" />
                        <input :ref="`textInput${index}`" v-if="model.editShowModel && headerData.editable && headerData.dataType != 'money'" v-model="dessert[headerData.value]"  class="tdInput1" @input="handleInput($event,index,headerData)"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottombottomTableAndInputs">
          <div class="tableBody" v-if="model.eInvoiceType == 'Z Raporu'">
              <div class="tableRow">
                <div class="bottomTableHeaderRowCell">Kümülatif Tutar</div>
                <div class="bottomTableRowCell">{{model.leftLittleTableHeaderCumulatesAmount}}</div> 
              </div>
              <div class="tableRow">
                <div class="bottomTableHeaderRowCell">Kümülatif Vergi Tutar</div>
                <div class="bottomTableRowCell">{{model.leftLittleTableHeaderCumulatesTaxAmount}}</div> 
              </div>
          </div>
          <div class="bottomInputs">
            <v-checkbox
              v-if="model.sameAccountTransfer && !model.eInvoiceType == 'Z Raporu'"
              v-model="sameAccountTransfer[model.tinNumber]"
              label="Mal/Hizmet Kodu * olan faturalara da bu hesap kodları aktarılsın."
              @change="sameAccountMethod(model.tinNumber, sameAccountTransfer[model.tinNumber])"
              hide-details="true"
            ></v-checkbox>
            <div v-if="model.eInvoiceType == 11" class="GCBinputs">
              <v-col cols="4" style="margin-top:20px; padding-right:0px" >
                <v-select
                  v-model="gcbPeriodObj.model"
                  :items="gcbPeriodObj.items"
                  @change="gcbPeriodChangeFunc()"
                  label="Dönem Seçiniz"
                ></v-select>
              </v-col>
                <div>GÇB NO:</div>
                <v-combobox
                  item-value="declarationNo"
                  item-text="declarationNo"
                  :search-input.sync="declarationNoInput"
                  v-model="model.declarationNo"
                  :items="model.declarationNoItems"
                  @change="gcbChangeFunction()"
                  :menu-props="{ maxWidth: '426', minHeight: '600' }"
                  outlined
                  hide-details="true"
                  :key="gcbRenderKey"
                >
                  <template v-slot:no-data   >
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          "<strong>{{ declarationNoInput }}</strong>"Eklemek için <kbd>Enter</kbd> tuşuna basınız.
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
                <v-btn  small class="mx-2" @click="bringButtonClick()" color="#00a8ff">Getir</v-btn>
            </div>
            <div class="currencyInputs" v-if="model.currencyType != 'TL' && model.currencyType != 'TRY'">
                <layout :key="rateKey" :dynamic-elements="componentElements.props.vMoneyElements" :layout-elements="componentElements.props.vMoneyElements" :model="model"/>         
                <v-btn small class="mx-2" @click="changeCurrency()" color="#00a8ff">Çevir</v-btn>
            </div>
          </div>
          <div class="bottomInputs">
            <div class="tableBody">
              <div class="tableRow">
                  <!-- nakit z raporunda buraya denk geliyor -->
                <div  class="bottomTableHeaderRowCell" >{{ componentElements.props.bottomTableHeaders.header1 }}</div>
                <div  class="bottomTableRowCell" >{{ model.matrahTotal }}</div> 
              </div>
              <div class="tableRow"  v-if="model.withholdingTaxTotal != null">
                <div class="bottomTableHeaderRowCell">{{ componentElements.props.bottomTableHeaders.header2 }}</div>
                <div class="bottomTableRowCell">{{ model.withholdingTaxTotal }}</div>
              </div>
              <div class="tableRow">
                  <!-- yemek kartı z raporunda buraya denk geliyor -->
                <div class="bottomTableHeaderRowCell">{{ componentElements.props.bottomTableHeaders.header3 }}</div>
                <div class="bottomTableRowCell">{{ model.vatTotal }}</div>
              </div>
              <div class="tableRow" v-if="model.specialCommunicationTaxAmount != null">
                <div class="bottomTableHeaderRowCell">{{ componentElements.props.bottomTableHeaders.header6 }}</div>
                <div class="bottomTableRowCell">{{ model.specialCommunicationTaxAmount }}</div>
              </div>
              <div class="tableRow">
                  <!-- diğer z raporunda buraya denk geliyor -->
                <div class="bottomTableHeaderRowCell">{{ componentElements.props.bottomTableHeaders.header4 }}</div>
                <div class="bottomTableRowCell">{{ model.vatIncludingTotal }}</div>
              </div>
              <div class="tableRow">
                  <!-- kredi kartı z raporunda buraya denk geliyor -->
                <div class="bottomTableHeaderRowCell">{{ componentElements.props.bottomTableHeaders.header5 }}</div>
                <div class="bottomTableRowCell">{{ model.total }}</div>
              </div>
            </div>
            <div class="tableBody" :key="bottomTableRenderKey"  v-if="model.currencyType != 'TL' && model.currencyType != 'TRY'" >
              <div class="tableRow">
                <div  class="bottomTableHeaderRowCell">{{ componentElements.props.bottomTableHeaders.header1 }}</div>
                <div class="bottomTableRowCell">{{ model.matrahTotalWithRate }}</div> 
              </div>
              <div class="tableRow"  v-if="model.withholdingTaxTotal != null">
                <div  class="bottomTableHeaderRowCell">{{ componentElements.props.bottomTableHeaders.header2 }}</div>
                <div class="bottomTableRowCell">{{ model.withholdingTaxTotal }}</div>
              </div>
              <div class="tableRow">
                <div  class="bottomTableHeaderRowCell">{{ componentElements.props.bottomTableHeaders.header3 }}</div>
                <div class="bottomTableRowCell">{{ model.vatTotalWithRate }}</div>
              </div>
              <div class="tableRow">
                <div  class="bottomTableHeaderRowCell">{{ componentElements.props.bottomTableHeaders.header4 }}</div>
                <div class="bottomTableRowCell">{{ model.vatIncludingTotalWithRate }}</div>
              </div>
              <div class="tableRow">
                <div  class="bottomTableHeaderRowCell">{{ componentElements.props.bottomTableHeaders.header5 }}</div>
                <div class="bottomTableRowCell">{{ model.totalWithRate }}</div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import layout from "../../../frontend/components/atoms/container/layout.vue";
import { openSnackbar, showLoader, hideLoader, currencyFormat,currencyInputFormat} from "../../../frontend/components/atoms/helper/elementHelper.js";
import Loader from "../../../frontend/components/atoms/loader";
import { sendRequest } from "../../../frontend/components/atoms/helper/requestHelper.js";
import Enum from "../../../frontend/components/Enum.json";
import store from "../store/index.js";

export default {  
  name: "newLeft",
  components: {
    Loader: Loader,
    "layout": layout,
  },
  props: {
    componentElements: {
      type: Object,
      default: () => { },
    },
    model: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      topTableRenderKey : 0,
      bottomTableRenderKey : 0,
      rateKey : 0,
      declarationNoInput: "",
      tableDatas:{},
      gcbRenderKey : 0,
      gcbPeriodObj : {
        model : null,
        items : []
      }
    }
  },
  computed: {
    ...mapState(['sameAccountTransfer']),
  },
  created () {
    this.gcbPeriodObj = this.model.gcbPeriodObj
  },
  methods: {
    gcbPeriodChangeFunc () {
      if (this.gcbPeriodObj.model == "Tümü") {
        this.model.declarationNoItems = this.model.declarationNoItemsOriginal
      }else {
        let gcbItemsArray = []
        for (let i = 0; i < this.model.declarationNoItemsOriginal.length; i++) {
          if (this.model.declarationNoItemsOriginal[i].gcbPeriod == this.model.gcbPeriodObj.model) {
            gcbItemsArray.push(this.model.declarationNoItemsOriginal[i]);
          }
        }
        this.model.declarationNoItems = gcbItemsArray
      } 
    },
    sameAccountMethod(tinNumber, value) {
      store.dispatch("setSameAccountCodes", { tinNumber, value });
    },
    gcbChangeFunction() {
      let that = this
      showLoader("Verileriniz getiriliyor. Lütfen bekleyiniz...")
      let indexDeclarationNo = that.model.declarationNoItems.indexOf(that.model.declarationNo)
      if (indexDeclarationNo == - 1) {
        hideLoader()
        // gçb botu çalıştırılacak, sürükle bırak.
      } else {
        that.model.closingDate = that.model.declarationNo["closingDate"]
        sendRequest(Enum.requestType.POST, "getCurrencyRateCustomDeclaration", null, { currencyType: this.model.currencyType, closingDate: this.model.closingDate },
          function (response) {
            hideLoader()
            that.model.rate = response.data.data.value
            that.model.rate = currencyFormat(that.model.rate, 4, 3, ",", ".")
            that.rateKey++
          },
          function (error) {
            hideLoader()
          }
        );
      }
    },
    changeCurrency() {
      let that = this
      if (typeof that.model.rate == 'string') {
        that.model.rate = that.model.rate.replaceAll(".", "")
        that.model.rate = parseFloat(that.model.rate.replaceAll(",", "."))
      }
      let matrahTotalWithRate = 0 // -> matrahTotal += billArray[i].totalPrice
      let vatTotalWithRate = 0 // -> vatTotal += billArray[i].vat
      for (let i = 0; i < that.model.desserts.length; i++) {
        matrahTotalWithRate += that.model.desserts[i].totalPrice
        vatTotalWithRate += that.model.desserts[i].vat
      }
      that.model.vatIncludingTotalWithRate = currencyFormat(that.model.rate * (matrahTotalWithRate + vatTotalWithRate), 2, 3, ",", ".") + " " + "TL"
      that.model.matrahTotalWithRate = currencyFormat(that.model.rate * matrahTotalWithRate, 2, 3, ",", ".") + " " + "TL"
      that.model.vatTotalWithRate = (that.model.rate * vatTotalWithRate).toLocaleString('tr-TR', { currency: 'TRY', minimumFractionDigits: 2,maximumFractionDigits: 2 }) + " " + "TL";
      that.model.totalWithRate = currencyFormat(that.model.rate * (matrahTotalWithRate + vatTotalWithRate), 2, 3, ",", ".") + " " + "TL"
      that.bottomTableRenderKey++
    },
    bringButtonClick() {
      let that = this
      that.model.declarationNo = that.declarationNoInput

      showLoader("Kapanış Dönemine Ait Kur Getiriliyor...")
      if (that.model.declarationNo != null) {
        let indexDeclarationNo = that.model.declarationNoItems.indexOf(that.model.declarationNo)
        if (indexDeclarationNo == -1 && (that.model.declarationNo.includes("IM") || that.model.declarationNo.includes("EX"))) {
          sendRequest(
            Enum.requestType.POST,
            "declarationInquiry",
            "",
            {
              declarationNo: that.model.declarationNo,
              currencyType: that.model.currencyType
            },
            function (response) {
              if (response.data.data.value != undefined) {
                hideLoader();
                openSnackbar("error", response.data.data.value);
              } else {
                hideLoader();
                that.model.declarationNoItems.push(that.model.declarationNo)
                that.gcbRenderKey++
                that.model.rate = response.data.data.rate
                that.model.rate = currencyFormat(that.model.rate, 4, 3, ",", ".")
                that.model.closingDate = response.data.data.kapanisTarihi
                that.topTableRenderKey++
                that.rateKey++
                that.changeCurrency();
                openSnackbar("success", "Kapanış Tarihi Kuru Başarılı Bir Şekilde Çekilmiştir.");
              }
            },
            function (error) {

              hideLoader()
              openSnackbar("error", "Kapanış dönemine ilişkin kur getirilirken hata oluştu.", 3000);
            }
          );
        } else {
          hideLoader()
          if (indexDeclarationNo != -1) {
            openSnackbar("error", "GÇB Daha Önce kaydedilmiş.")
          } else {
            openSnackbar("error", "Lütfen kullanılabilir bir GÇB No giriniz.")
          }
        }
      } else {
        hideLoader()
        openSnackbar("error", "Lütfen GÇB No giriniz.")
      }
    },
    currencyFormatProcessing(num, decimalLength, thousandLength, decimalSeparator, thousandSeparator) {
      return currencyFormat(num, decimalLength, thousandLength, decimalSeparator, thousandSeparator);
    },
    handleInput(event, index, headerData) {

      //bu fonksiyon inputların başka öğelere etkisi varsa veya currency format gibi işlemler uygulanması gerekiyorsa onlar yapılır

      if (headerData?.dataType == 'money') {
        //money elementlerine anlık currency format uygulanır aslında bir v-money dir
        let formattedNumber = currencyInputFormat(event.target.value, 2, ',', '.')
        event.target.value = formattedNumber
        this.model.desserts[index][headerData.value] = formattedNumber

        //price datamız değiştikçe totalPrice'ı değiştirir ve price datamızı currencyFormattan çıkarır
        if (headerData.value == 'price') {
          let withoutDots = formattedNumber.replace(/\./g, '');
          let convertedNumber = withoutDots.replace(',', '.');
          this.model.desserts[index]['totalPrice'] = this.model.desserts[index]['productAmount'] * convertedNumber
          this.model.desserts[index]['vat'] = (this.model.desserts[index]['totalPrice'] * this.model.desserts[index]['vatRate']) / 100
        }

          //totalPrice datamız değiştikçe price'ı değiştirir ve totalPrice datamızı currencyFormattan çıkarır
          if (headerData.value == 'totalPrice') {
          let withoutDots = formattedNumber.replace(/\./g, '');
          let convertedNumber = withoutDots.replace(',', '.');
          this.model.desserts[index]['price'] = convertedNumber / this.model.desserts[index]['productAmount']
          this.model.desserts[index]['vat'] = (convertedNumber * this.model.desserts[index]['vatRate']) / 100
          this.model.tdTableRenderForAddRow++
          this.$nextTick(() => {
            this.$refs[`textInput${index}`][2].focus();
          });
        }
      }

      //productAmount değiştikçe totalPrice'ımızı ve KDV oranımızı değiştirir - price datamızı currencyFormattan çıkarır
      if (headerData?.value == 'productAmount') {
        let withoutDots = this.model.desserts[index]['price'].toString().replace(/\./g, '');
        let convertedNumber = withoutDots.replace(',', '.');
        this.model.desserts[index]['totalPrice'] = event.target.value * convertedNumber
        this.model.desserts[index]['vat'] = (this.model.desserts[index]['totalPrice'] * this.model.desserts[index]['vatRate']) / 100
        this.model.tdTableRenderForAddRow++
        this.$nextTick(() => {
          this.$refs[`textInput${index}`][0].focus();
        });
      }
      //vatRate değiştikçe KDV'yi tekrar hesaplar anlık olarak
      if (headerData?.value == 'vatRate') {
        this.model.desserts[index]['vat'] = (this.model.desserts[index]['totalPrice'] * event.target.value) / 100 
        this.model.tdTableRenderForAddRow++
        this.$nextTick(() => {
          this.$refs[`textInput${index}`][1].focus();
        });
      }
    },
    handleBillDateChange(event, value) {
      this.model.invalidDate = false
      //bu fonksiyon billdate değiştikçe (change anında) tetiklenir ve modele istediğimiz formatta setlenir
      if (value == 'billDate') {
        let dateParts = event.target.value.split("."); // Tarihi parçalara böler
        let day = parseInt(dateParts[0], 10);
        let month = parseInt(dateParts[1], 10) - 1; // Ayı 0'dan başlatır
        let year = parseInt(dateParts[2], 10);

        //invalid date control
        if (month > 11 || day > 31) {
          this.model.invalidDate = true
          return openSnackbar("error", "Lütfen Geçerli Bir Tarih Giriniz")
        }
        else if ((month === 3 || month === 5 || month === 8 || month === 10) && day > 30) {
          this.model.invalidDate = true;
          return openSnackbar("error", "Bu ay " + (month + 1) + ". ay maksimum 30 gün içerir.");
        } else if (month === 1) { // Şubat ayı
          if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) { // Artık yıl kontrolü
            if (day > 29) {
              this.model.invalidDate = true;
              return openSnackbar("error", "Bu yıl Şubat ayı 29 gün içerir.");
            }
          } else if (day > 28) {
            this.model.invalidDate = true;
            return openSnackbar("error", "Bu yıl Şubat ayı 28 gün içerir.");
          }
        }

        let convertedDate = new Date(year, month, day, 12, 0, 0);
        this.model.desserts[0].billDate = convertedDate

      }
    },
    dateMaskFunc(event) {
      let value = event.target.value;
      const onlyNumbers = value.replace(/\D/g, '');
      // Gün, ay ve yılı alacak şekilde formatı düzenle
      let formattedDate = '';
      if (onlyNumbers.length > 0) {
        if (onlyNumbers.length === 1) {
          formattedDate = onlyNumbers;
        } else if (onlyNumbers.length === 2) {
          formattedDate = onlyNumbers.substring(0, 2);
        } else if (onlyNumbers.length === 3) {
          formattedDate = onlyNumbers.substring(0, 2) + '.' + onlyNumbers.substring(2);
        } else if (onlyNumbers.length === 4) {
          formattedDate = onlyNumbers.substring(0, 2) + '.' + onlyNumbers.substring(2, 4);
        } else if (onlyNumbers.length >= 5 && onlyNumbers.length <= 8) {
          formattedDate = onlyNumbers.substring(0, 2) + '.' + onlyNumbers.substring(2, 4) + '.' + onlyNumbers.substring(4, 8);
        }
      }
      // Eğer formatlanmış değer girilen değerden farklıysa, güncellenmiş değeri set et
      if (formattedDate !== value) {
        event.target.value = formattedDate;
      }
    },
    formatDate(date) {
      //Bu fonksiyon modelimizdeki billDate'i input alanın gün.ay.yıl formatında göstermek için kullanılır
      return new Date(date).toLocaleString('tr-TR').split(" ")[0];
    },
    computedIndex(index){
      if(this.model.paginationBoo == true){
        return index+1+((this.model.pageNow -1 )*this.model.itemsPerPageDetail) 
      } else {
        return index+1 
      }
    }
  },



}
</script>

<style scoped>
/*TD INPUTS STYLE */
.topTdMainBox{
    display:flex;
    flex-direction: row;
    gap:5px;
    font-size: 11px;
    font-weight: 600;
    height:88px
}
.topTdBox{
    border-top:2px solid black;
    border-bottom:2px solid black;
    height:88px;
    display:flex; 
    flex-direction: column; 
    justify-content: center;
    width:100%;
    padding-left: 15px;
}
.tdInput{
    color:#29405f;
    font-weight:500;
    border: 1px solid black;
    background-color:rgb(160,157,161,0.3);
    padding:3px;
    margin-left:12px;
    margin-right:3px;
    margin-top:3px;
    font-size: 13px;
}
.tdInput1{
    color:#29405f;
    font-weight:500;
    border: 1px solid black;
    background-color:rgb(160,157,161,0.3);
    font-size: 13px;
    width:100%;
    height:100%
}
.tdInputBox{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.tdInputTitle{
  min-width:65px
}
.tableStyle{
    display: flex;
    flex-direction: column;
    height: fit-content;
    padding:2px;
    margin-bottom: 20px;
}
.tableHeader{
    display: flex;
    flex-direction: row;
    font-size: 12px;
    font-weight: 600;
    width: 100%;
    justify-content: space-between;
    height: 34px;
}
.headerTitle{
display:flex;
justify-content: center;
align-items: center;
text-align: center;
}
.tableBody{
    display: flex;
    flex-direction: column;
    font-size: 12px;
    gap:1px

}
.tableRow{
    display: flex;
    flex-direction: row;
    width:100%;
    justify-content: space-between;
    gap:1px;
    height:39px
}
.tableRowCell{
    display: flex;
    justify-content: center;
    align-items: center;
    border:1px solid black;
    padding:1px;
}
.bottombottomTableAndInputs{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5px;
  gap:5px
}
.bottomInputs{
  display: flex; 
  flex-direction: column; 
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
}
.bottomTableRowCell{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border:1px solid black;
    padding:2px;
    min-width:105px
}
.bottomTableHeaderRowCell{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border:1px solid black;
    padding:2px;
    width:100%;
    white-space: nowrap;
}
.GCBinputs{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap:5px;
}
.currencyInputs{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(103, 117, 224, 0.416);
  background-color: #d7e7ff;
  border-radius: 15px;
  height: fit-content;
  width: fit-content;
}

</style>

//renderların da kontrol edilip temizlenmesi gerekiyor