<template>
  <div id="container" class="mainContainer">
      <div class="leftBox" :style="`${projectDesign.color}`"  :key="renderKey">
        <div class="leftInnerBox" :style="`${projectDesign.logoBackground}`" :key="renderKey">
          <img  class="leftImg" :src= projectDesign.leftBoxLogo alt="Resim Yükleniyor">
        </div>
      </div>
      <div class="rightBox">
          <div class="rightInnerBox">
            <img class="rightImg" :src= projectDesign.rightBoxLogo alt="Resim Yükleniyor"/>
            <h4 class="rightTitle" >{{projectDesign.rightTitle != undefined ? projectDesign.rightTitle : ""}}</h4>
            <v-form class="rightInputArea" ref="form" lazy-validation>
              <v-text-field 
              class="rightInput" 
              v-model="userName" 
              label="Kullanıcı Adını Giriniz" 
              counter maxlength="100" 
              :rules="[rules.required, rules.email]"
              name="email"
              @keyup.enter="projectDesign.loginFunction(userName,password)">
              </v-text-field>
              <v-text-field 
              class="rightInput" 
              v-model="password" 
              type= 'password' 
              :rules="[rules.required, rules.counter]"
              label="Şifrenizi Giriniz"  
              @keyup.enter="projectDesign.loginFunction(userName,password)"
            >
              </v-text-field>
            </v-form>
            <!-- <v-btn text class="mx-auto text-center mt-3"   style="color :black; display: flex; justify-align:center" @click="projectDesign.forgetPassword()" small>
              Şifre mi unuttum?
            </v-btn> -->
            <a class="rightLink" :href='"forgotten"' style="width: fit-content;cursor: pointer;">Şifrenizi mi unuttunuz?</a>
            <div class="login-button-container">
              <div>
                <button class="left-button" :style= projectDesign.btnStyle>
                  <a href="https://eportal.notitek.com.tr/adminlogin" target="_blank" :style= projectDesign.btnStyle  class="a-left-button">Admin Paneli</a></button>
              </div>
              <div>
                <button class="rightButton" :style= projectDesign.btnStyle @click="projectDesign.loginFunction(userName,password)" >Giriş</button>
              </div>
            </div>            
            <div class="socialLinkArea">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn class="linkButton" target="_blank" href="https://www.notitek.com.tr/" fab color="black" outlined  v-on="on">
                    <img src="../assets/www.svg" class="icon"/>
                  </v-btn>
                </template>
                <span>Web Sitemizi Ziyaret Edin</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn class="linkButton" target="_blank" href="https://www.instagram.com/notitek.com.tr/ " fab color="black" outlined v-on="on">
                    <img src="../assets/instagram.svg" class="icon"/>
                  </v-btn>
                </template>
                <span>İnstagram Adresimizi Ziyaret Edin</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn class="linkButton" target="_blank" href="https://tr.linkedin.com/company/notitek-software" fab color="black" outlined v-on="on">
                    <img src="../assets/linkedin.svg" class="icon"/>
                  </v-btn>
                </template>
                <span>Linkedin Adresimizi Ziyaret Edin</span>
              </v-tooltip>
            </div>
          </div>
        </div>
    <snackbar-component />
  </div>
</template>



<script>
import layout from "./layout.vue";
import autocompleteComponent from "./autocompleteComponent.vue";
import snackbarComponent from "./snackbarComponent.vue";
import {routerItem} from "../helper/helper.js"


export default {
  
    components: { 'layout' : layout, 'autocomplete-component' : autocompleteComponent ,  "snackbar-component": snackbarComponent, },
    props: {
        projectDesign : {},
        colors : "",
        inputElement : {
          type: Object,
          default: () => ({
            logoBackground : "",
            leftBoxLogo : "",
            rightBoxLogo : "",
            btnStyle : "",
            // loginFunction : function (userName,password) {
            //    return "function"
            // }
          })
        },
        dynamicElements : {
          type: Array,
          default: () => [{}]
        },
        tableHeader : {},
        model : {}
    },
    created(){
      this.renderKey++
      let loginPageEnums ={
        //color : "background-color: #b71111!important;",
        color : "background-color:black!important;",
        logoBackground : "background-color: #b71111!important;",
        leftBoxLogo : 'https://www.notitek.com.tr/wp-content/uploads/elementor/thumbs/logo-beyaz-pyfyeteuugdk4prv1lrcgbvo4p53ksuriqu3jt5hc0.png',
        rightBoxLogo : 'https://www.notitek.com.tr/wp-content/uploads/elementor/thumbs/logo-beyaz-pyfyeteuugdk4prv1lrcgbvo4p53ksuriqu3jt5hc0.png',
        loginFunction : "",
      }
     
    },
    data () {
      return {
        userName: '',
        password : '',
        email: '',
        renderKey : 0,
        route : "forgotten",
        color : "background-color: black!important;",
        rules: {
          required: value => !!value || 'Required.',
          counter: value => value.length <= 20 || 'Max 20 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Geçersiz e-mail.'
          },
        },
      }
    },
    mounted()  {
      // window.addEventListener("keydown",(a) => {
      //   a
      // })
    }
    
}
</script>
<style src='../../../../frontend/components/atoms/css/loginPage.css' scoped>

</style>



