<template>
    <v-app>
      <div class="header-div">
        <h3 class="header-h3">Önceki Dönem Mizan</h3>
      </div>
      <v-toolbar-items style="margin-left: 25px; height: 30px;">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn text @click="downloadDrafts" v-on="on" style="background-color: #4BB543; color: white; border-radius: 5px;">
                Taslak İndir
              </v-btn>
            </template>
          </v-tooltip>
      </v-toolbar-items>
      <table-component :dynamic-element="tableElements" :model="model" />
      <Loader></Loader>
      <snackbar-component />
    </v-app>
  </template>
  <script>
  import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
  import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
  import Enum from "../../../frontend/components/Enum.json";
  import Loader from "../../../frontend/components/atoms/loader";
  import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";
  
  import {
  getTable,
  updateItem,
  saveItem,
  deleteItem,
  openSnackbar,
  openTablePage,
  showLoader, 
  hideLoader 
  } from "../../../frontend/components/atoms/helper/elementHelper.js";
  import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
  import {axiosUploadTypeArrayBuffer, checkProject, downloadZipFile} from "../../helper/uploadHelperForData"
  export default {
    components: {
        "table-component": tableComponent,
        "upload-component": uploadComponent,
        Loader: Loader,
        "snackbar-component": snackbarComponent,
    },
  
    data() {
        return {
          tableElements: {},
          model: {},
          renderKey: 0,
        };
    },
    created() {
      let that = this
      this.loadData();
      checkProject();
    },
    watch: {},
    methods: {
      downloadDrafts() {
          downloadZipFile();
      },
      renderMizanTable(thisData, model, dynamicElements) {
        showLoader('Hesaplamalar Yapılıyor')
        let choiceData = {
          cookieData: window.$cookies.get("selectedItem")
        }
        sendRequest(Enum.requestType.POST, "queryPreviousMizanTable", "", choiceData, 
        function (response) {
          dynamicElements.data = response.data.data.data;
          model.itemCount = dynamicElements.data.length;
          model.pagesCount = dynamicElements.data.length / 50;
          dynamicElements.forceRender();
          hideLoader();
        },
        function (error) {
         errFunc(error);
        })
      },
    loadData() {
      let that = this;
      this.tableElements = {
        sortBy: [],
        sortDesc: false,
        renderKey: 0,
        type: "table",
        tableHeight : 530,
        name: "mizanTable",
        class: "",
        infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
        excelDownloadBtnText: "İndir",
        deleteDialogVisible : true,
        deleteBtnText: "Sil",
        hidePagination: true,
        selectionUrl: "queryPreviousMizanTable",
        showDefaultSaveButon : false,
        initialFilter:{
          [Enum.initialFilter.AND] :
          [
            {
              projectId: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id
              }
            }
          ]
        },
        clientEvents : {
            onBeforeSave :function (selectedItem,creatableObjects,model,tableObject) {
              creatableObjects.initialFilter = tableObject.initialFilter
            },
         },
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
                [
                    {
                       id : "startDate",
                       type : "v-date-picker",
                       variant : "text",
                       name : "startDate",
                       label : "Başlangıç Tarihi",
                       value : "",
                       prependIcon : "fas fa-at",
                       placeHolder : "",
                       style : "width: 260px;",
                       classTag : "",
                       readonly: true,
                       onClick : function(thisData,model,dynamicElements) {
                       }
                     },
                ],
                [
                    {    
                      id : "endDate",
                      type : "v-date-picker",
                      variant : "text",
                      name : "endDate",
                      label : "Bitiş Tarihi",
                      value : "",
                      prependIcon : "fas fa-at",
                      placeHolder : "",
                      style : "width: 260px;",
                      classTag : "",
                      readonly: true,
                      onClick : function(thisData,model,dynamicElements) {
                      }
                    },
                ],
                [
                    {
                       id: "mizan",
                       type: "v-file-input",
                       style:"height: 25px!important; width: 250px;",
                       label: "Dosyanızı Seçiniz",
                       inputHeader: "Mizan",
                       name: "mizan",
                       multiple: true,
                       onChange: async function(thisData, model, dynamicElements) {
                       }
                    }
                ],
                [
                    {
                     id: "saveMizan",
                     type: "v-btn",
                     variant : "btn",
                     style:"height: 25px!important; width: 200px;",
                     label: "Kaydet",
                     inputHeader: "Mizan",
                     name: "saveMizan",
                     inputText : "Kaydet",
                     divStyle:"",
                     style:"height: 25px !important; margin-top: 20px; margin-bottom: 20px;",
                     btnTypeText: false,
                     color : "primary",
                     prependIcon : "",
                     classTag : "",
                     onClick: async function(thisData, model, dynamicElements) {
                        if (model.startDate === "" && model.endDate === "") {
                          openSnackbar("error","Girilen Tarih Değerleri Boş Olamaz");
                          return false;
                        }
                        if (((model.startDate != undefined) && (model.endDate != undefined))) {
                            let startDateNew = model.startDate.split("/")
                            startDateNew = new Date(startDateNew[2] + "-" + startDateNew[1] + "-" +startDateNew[0])
                            let endDateNew = model.endDate.split("/")
                            endDateNew = new Date(endDateNew[2] + "-" + endDateNew[1] + "-" +endDateNew[0])
                            const diffTime = Math.abs(endDateNew - startDateNew);
                            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

                            if (diffDays > 370) {
                              openSnackbar("error","Seçilen Tarihler Arası Bir Yıldan Büyük Olamaz", 2000);
                              return false;
                            }
                            if (startDateNew > endDateNew) {
                              openSnackbar("error","Başlangıç Tarihi, Bitiş Tarihinden Büyük Olamaz",2000);
                              return false;
                            }
                        }
                        let files = model.mizan[0];
                        let choiceData = {
                          choice : "mizan", 
                          headerAddFlag : true, 
                          fileName : files.name,
                          fileType : 'mizan',
                          cookieData: window.$cookies.get("selectedItem"),
                          startDate: model.startDate,
                          endDate: model.endDate
                        }
                      showLoader('Dosyanız Hazırlanıyor')
                      uploadHelperFormDataQueue([files], "insertToDatabaseFromExcel", Enum.requestType.POST, choiceData, 
                      function (response) {
                        if (response.data.data.errorExcel != null) {
                          openSnackbar("error", "Hatalı Excel Taslağı Yüklediniz", "2000")
                        }
                        hideLoader();
                        that.renderMizanTable(thisData, that.model.mizanTable, dynamicElements)
                        },
                      function (error) {
                          openSnackbar(
                            "error",
                            error.response.data.error[0].detail.message,
                            "2300"
                          );
                          hideLoader();
                        }
                      )
                     }
                    }
                ],
                [
                  {
                    id : "del",
                    type : "v-btn",
                    variant : "btn",
                    name : "del",
                    inputText : "Sil",
                    divStyle:"",
                    style:"height: 25px !important; margin-top: 20px;",
                    btnTypeText: false,
                    color : "error",
                    prependIcon : "",
                    classTag : "",
                    onClick : function(thisData, model, dynamicElements) {
                      showLoader('Verileriniz Siliniyor') 
                      sendRequest(Enum.requestType.POST, "deleteMizan", "", 
                        {cookieData: window.$cookies.get("selectedItem")}, 
                        function (response) {
                          hideLoader();
                          openSnackbar("green", "Verileriniz Başarıyla Silindi", "3000")
                          that.renderMizanTable(thisData, that.model.mizanTable, dynamicElements)
                        },
                        function (error) 
                        {
                          
                          errFunc(error);
                        }
                      )
                    }
                  }
                ],
            ],
          }
        ],
        headers: [
          { text: "Hesap Kodu", value: "accountCode", width: "100" , divider: true,},
          { text: "Hesap Adı", value: "accountName", width:"100" , divider: true,},
          { text: "Başlangıç Tarihi", value: "startDate", width: "100", dataType: "date" , divider: true,},
          { text: "Bitiş Tarihi", value: "endDate", width: "100", dataType: "date" , divider: true,},
          { text: "Borç", value: "debt", width: "100", dataType: "currency" , divider: true,},
          { text: "Alacak", value: "credit", width: "100", dataType: "currency" , divider: true,},
        ],
      };
    },
    copyItem(tableName,selectedItem,options,model,onBeforeSave,onAfterSave,errorFunction) {
      if (!Array.isArray(selectedItem)) {
          selectedItem = [selectedItem];
      }
      
      let dynamicElements = getTable(tableName);
      if (options != undefined && options.dialogText != undefined) {
          dynamicElements.deleteDialogText = options.dialogText;
      }
      
      let title = "Yeni Kayıt Eklemek İstediğinize Emin Misiniz?";
      
      dynamicElements.forceRender();
      model.close = "Hayır",
      model.insertItem = "Evet";
      dynamicElements.dialogVisible = true;
      dynamicElements.dialogInfo = {
          name:"insertDialog",
          maxWidth : "1100",
          dialogVisible : "dialogVisible",
          title : title,
          layout : [
              {
                type : Enum.layoutType.div,
                name : "loginForm",
      
                inputElements : [
                ]
              }
          ],
          actions : [
            {
              id : "close",
              type : "v-btn",
              variant : "btn",
              name : "close",
              inputText : "Kapat",
              divStyle:"",
              btnTypeText: true,
              color:"green darken-1",
              classTag : "",
              onClick : function(thisData,model,dialogInfo) {
                var tableObject = getTable(tableName);
                tableObject.dialogVisible = false;
                tableObject.forceRender();
                console.log(thisData,model,dialogInfo);
              }
            },
            {
              id : "insertItem",
              type : "v-btn",
              variant : "btn",
              name : "insertItem",
              inputText : "Ekle",
              divStyle:"",
              btnTypeText: true,
              color:"green darken-1",
              classTag : "",
              onClick : function(thisData,model,dialogInfo) {
                var tableObject = getTable(tableName);
                console.log(thisData,model,dialogInfo);
                tableObject.dialogVisible = false;
                tableObject.forceRender();
                let creatableObjects = {}; 
                sendRequest(Enum.requestType.POST, dynamicElements.saveUrl,"",{selectedItem,creatableObjects},function (res) {
                    openTablePage(tableName);
                    openSnackbar("success", "Eklemeniz Başarılı Bir Şekilde Gerçekleşti");
                },function (error) {
                    if (errorFunction != undefined) {
                        errorFunction(error,selectedItem,creatableObjects,model,tableObject);
                    } else if(tableObject.clientEvents.onSaveError != undefined){
                        tableObject.clientEvents.onSaveError(error,selectedItem,creatableObjects,model,tableObject);
                    }
                });
              }
            },
          ]
      };
      },
  },
  }
  
    
  
  </script>
  
  <style>
  .header-div {
  text-align: center;
  }
  </style>