<template>
  <v-app>
    <table-component :dynamic-element="inflationAccountElements" :model="model" />
    <Loader></Loader>
    <snackbar-component />
  </v-app>
</template>

<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import {
  openSnackbar,
  openTablePage,
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import { sendRequest } from "../../../frontend/components/atoms/helper/requestHelper";

export default {
  components: {
    "table-component": tableComponent,
    "upload-component": uploadComponent,
    Loader: Loader,
    "snackbar-component": snackbarComponent,
  },

  data() {
    return {
      inflationAccountElements: {},
      model: {},
      renderKey: 0,
      required : (value) => !!value || "Gerekli."
    };
  },

  created() {
    this.loadData();
  },
  watch: {},
  methods: {
    loadData() {
      let that = this;
      this.inflationAccountElements = {
        clientEvents : {
          onBeforeSave :function (selectedItem,creatableObjects,model,tableObject) {
            if(creatableObjects.accountCode != undefined  && creatableObjects.accountName != undefined && creatableObjects.monetaryTypeString != undefined){
              creatableObjects.initialFilter = tableObject.initialFilter
              creatableObjects["monetaryType"] =  creatableObjects["monetaryTypeString"]
              let splitDate = "", keys = []
              keys = Object.keys(tableObject.data[0])
              for (let j = 0; j < keys.length; j++) {
                if(keys[j].includes("__")){
                  let keysDate = keys[j].split("__")
                  creatableObjects[keysDate[0]] = 0
                }
                
              }
              delete creatableObjects["monetaryTypeString"]

            }else{
              openSnackbar("error", "Tüm Alanları Doldurunuz")
            }
          },
          onafterUpload : function (dynamicElements) {
            dynamicElements.headers = []
            if (dynamicElements.data.length > 0) {
              dynamicElements.headers.push(
                { text: "Hesap Kodu", value: "accountCode", width: "100" , search: true , sortable: true},
                { text: "Hesap Adı", value: "accountName", width: "100" , search: true , sortable: true,class: "threedot"},
                { text: "Hesap Tipi", value: "monetaryTypeString", width: "100" , search: true , sortable: true },
                // { text: "Tarih", value: "monetaryTypeString", width: "100" , search: true , sortable: true },
                // { text: "Bakiye", value: "balance", width: "100" , search: true , sortable: true },
              )         
              let dateObj = {}
              for (let i = 0; i < dynamicElements.data.length; i++) 
              {
                 
                for (let j = 0; j < dynamicElements.data[i].item.length; j++) {
                  if (dateObj[dynamicElements.data[i].item[j].date] == undefined) {
                    dateObj[dynamicElements.data[i].item[j].date] = {}
                  }
                  dynamicElements.data[i][dynamicElements.data[i].item[j].date] = dynamicElements.data[i].item[j].balance
                  dynamicElements.data[i][dynamicElements.data[i].item[j].date + "__" + "mainAccountDetailId"] = dynamicElements.data[i].item[j].mainAccountDetailId
                }
                  
                  delete dynamicElements.data[i].item
              }
              /// date headers pushlanıyor.
              for (let i = 0; i < Object.keys(dateObj).length; i++) {
                dynamicElements.columns[Object.keys(dateObj)[i]] = {}
                  dynamicElements.columns[Object.keys(dateObj)[i]] = {
                    type: "v-money",
                    editable: true,
                    creatable: false,
                    inlineEditable: true,
                    label: "Tutar",
                    value : "",
                    money : {
                      decimal: ',',
                      thousands: '.',
                      prefix: '',
                      suffix: '',
                      precision: 2,
                      masked: false
                    },                  
                    onSave: function (thisData, model, dynamicElements) {
                      let editableObjects = {};
                      model[thisData.id] = model[thisData.id].replaceAll(".","").replaceAll(",",".")
                      if(model[thisData.id].toString().startsWith("-")){
                        model[thisData.id] = model[thisData.id].split("-")
                        editableObjects["credit"] = model[thisData.id][1];
                        editableObjects["debt"] = 0
                      }else{
                        editableObjects["debt"] = model[thisData.id];
                        editableObjects["credit"] = 0
                      }
                      model["mainAccountDetailId"] = model[thisData.id + "__mainAccountDetailId"]
                      sendRequest("POST","updateForAllTable","",{selectedItem: [model],editableObjects,tableName: "accountDetail",tableKey : thisData.id, forKey : "mainAccountDetailId", sequl : "id",},
                        function(response){
                          openSnackbar("success", response.data.data.value)
                          openTablePage("mizanTable")
                        }, 
                        function(error){
                          if (error.response != undefined){
                            openSnackbar("error", error.response.data.error[0].detail.message)
                          } else {
                            openSnackbar("error", "Sunucu Hatası")
                          }
                      })
                    }
                  }
                  dynamicElements.headers.push({ text: Object.keys(dateObj)[i], value: Object.keys(dateObj)[i], width: "100" , search: true , sortable: true, dataType: "currency" })
                }
              }            
            },
          onBeforeDelete :function (selectedItem,model,tableObject,res) {
            if (selectedItem[0] == undefined) {
              
            } else {
              let keys = Object.keys(selectedItem[0])
              let keysArray = {}, j=0
              for (let i = 0; i < keys.length; i++) {
                if(keys[i].length == 31 ){
                  keysArray["mainAccountDetailId"+j] = selectedItem[0][keys[i]]
                  j++
                }
              }
              selectedItem[0]["mainAccountGroup"] = keysArray
              }
          } 
        },
        sortBy: ["accountCode"],
        sortDesc: false,
        renderKey: 0,
        type: "table",
        tableHeight : 420,
        name: "mizanTable",
        class: "",
        infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
        //deleteBtnText: "Sil",
        excelDownloadBtnText: "İndir",
        saveBtnText : 'Yeni Kayıt',
        saveBtnSettings : {
          color: "blue",
          style:"height: 25px !important; ",
        },
        deleteBtnSettings : {
          color: "error",
          style:"height: 25px !important; ",
        },
        deleteDialogVisible : false,
        hidePagination: true,
        selectedRows: [],
        filteredColumn: {},
        filteredColumns: true,
        selectionUrl: "gettabledata",
        //deleteUrl: "deleteTable",
        saveUrl : "updateTable",
        crudRequestObj : {
          placeOfAction : "inflationAccountingTrialBalance",
          projectId : window.$cookies.get("selectedItem").id,
          company_id: window.$cookies.get("selectedItem").companyId,
          auditDate: window.$cookies.get("selectedItem").auiditDate,
          preriorDate: window.$cookies.get("selectedItem").preriorDate,
        },
        initialFilter:{
          [Enum.initialFilter.AND] :
          [
            {
              company_id: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").companyId
              },project_id: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id
              },is_active: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").isActive
              }
            }
          ]
        },
        showDefaultUpdateButon : true,
        showDefaultDeleteButon : true,
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [],
          }
        ],
        headers: [],
        columns: {
          accountCode: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Hesap Kodu",
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              editableObjects["account_code"] = model[thisData.id]
              sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "accountPlan", tableKey : "account_code", forKey : "accountPlanId", sequl : "id"},
              function(response){
                openSnackbar("success", response.data.data.value)
                openTablePage("mizanTable")
              }, 
              function(error){
                if (error.response != undefined){
                  openSnackbar("error", error.response.data.error[0].detail.message)
                } else (
                  openSnackbar("error", "Sunucu Hatası")
                )
              })
            }
          },
          accountName: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Hesap Adı",
            rules : [this.required],
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              editableObjects["account_name"] = model[thisData.id]
              sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "accountPlan", tableKey : "account_name", forKey : "accountPlanId", sequl : "id"},function(response){
                openSnackbar("success", response.data.data.value)
                openTablePage("mizanTable")
              }, 
              function(error){
                if (error.response != undefined){
                  openSnackbar("error", error.response.data.error[0].detail.message)
                } else (
                  openSnackbar("error", "Sunucu Hatası")
                )
              })
            }
          },
          monetaryTypeString: {
            type: "v-select",
            variant: "number",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label: "Hesap Tipi",
            dropDownItems: ["PARASAL", "PARASAL OLMAYAN"],
            rules: [this.required],
            onSave: function(thisData, model, dynamicElements) {
              let editableObjects = {};
              let modelmonetaryTypeString = ""
              model[thisData.id] == "PARASAL" ? modelmonetaryTypeString = 0 :   modelmonetaryTypeString = 1
              editableObjects["monetary_type"] = modelmonetaryTypeString;              
              sendRequest("POST","updateForAllTable","",{selectedItem : [model], editableObjects ,tableName : "accountPlan", tableKey : "monetary_type", forKey : "accountPlanId", sequl : "id"},
                function(response) {
                  openSnackbar("success", response.data.data.value);
                  openTablePage("mizanTable")
                },
                function(error) {
                  if (error.response != undefined) {
                    openSnackbar(
                      "error",
                      error.response.data.error[0].detail.message
                    );
                  } else openSnackbar("error", "Sunucu Hatası");
                }
              );
            },
          },
          date: {
            type: "v-text-field",
            editable: true,
            creatable: false,
            inlineEditable: true,
            label : "Tarih",
          },
          balance: {
            type: "v-text-field",
            editable: true,
            creatable: false,
            inlineEditable: true,
            label : "Bakiye",
          },
        },
        data: "data",
        showSelecetVisible: true,
        singleSelect: false,
        itemKey: "id",
        footer: [
          {
            type: Enum.layoutType.div,
            name: "footerForm",
            inputElements: [
              [
                {
                  id: "pageSize",
                  type: "v-text-field",
                  variant: "number",
                  name: "pageSize",
                  label: "Görünecek Satır Sayısı",
                  value: "",
                  placeHolder: "",
                  style: "width: 45%; margin-left: 5%",
                  classTag: "",
                  onChange: function(thisData, model, dynamicElements) {
                    openTablePage("mizanTable")
                  },
                },
              ],
              [
                {
                  id: "currentPageNo",
                  type: "v-pagination",
                  variant: "pagination",
                  name: "currentPageNo",
                  divStyle: "",
                  style: "width:850px;",
                  classTag: "my-1",
                  length: "pagesCount",
                  onInput: function(thisData, model, dynamicElements) {
                    openTablePage("mizanTable")                  
                    },
                },
              ],
              [
                {
                  id: "infoTotalRowCountHeaderAssistantTable",
                  type: "p",
                  variant: "p",
                  name: "infoTotalRowCountHeaderAssistantTable",
                  inputText: "infoTotalRowCountHeaderAssistantTable",
                  divStyle: "",
                  style: "font-size: 12px;font-weight:500;margin:3px 0;color:#666A6F;margin-right: 2%;margin-left: 60%;",
                  classTag: ""
                },
                {
                  id: "infoTotalRowCountAssistantTable",
                  type: "p",
                  variant: "p",
                  name: "infoTotalRowCountAssistantTable",
                  inputText: "itemCount",
                  divStyle: "",
                  style: "font-size: 15px;font-weight:500;margin:3px 0;color:#666A6F;margin-left: 82%;",
                  classTag: ""
                },
              ],
            ],
          },
        ]
      };
     
    }
  },
};
</script>

<style scoped>
::v-deep .threedot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 300px;
}
::v-deep .threedot:hover {
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}
</style>
