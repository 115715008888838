<template>
    <div :id="componentElements.componentName" :style="componentElements.componentStyle">

        <div :style="componentElements.headerStyle">
            <div :style="componentElements.titleStyle">{{ componentElements.title }}</div>

            <div :style="componentElements.inputsAreaStyle">
                <div :style="componentElements.inputsStyle" v-if="componentElements.inputs">
                    <div v-for="input in componentElements.inputs">
                        <inputComponent v-if="input.type != 'justText'" :style="input.inputStyle" :input-element="input" :model="model"
                            :dynamic-elements="componentElements"></inputComponent>
                            <div v-if="input.type == 'justText' && (input.showInput != undefined ? input.showInput : true)" :style="input.inputStyle"> {{ input.text }}</div>
                    </div>
                </div>
                <div :style="componentElements.buttonsStyle" v-if="componentElements.buttons">
                    <div v-for="input in componentElements.buttons">
                        <inputComponent :style="input.inputStyle" :input-element="input" :model="model"
                            :dynamic-elements="componentElements"></inputComponent>
                    </div>
                </div>
                <div :style="componentElements.specialInputsStyle" v-if="componentElements.specialInputs">
                    <div v-for="input in componentElements.specialInputs">
                        <component v-if="input.showInput != undefined ? input.showInput : true" :is="input.componentName" :style="input.inputStyle" :dynamicElements="componentElements"
                            :model="model" :elements="input.elements">
                        </component>
                    </div>
                </div>
            </div>
        </div>

        <component :is="componentElements.componentName" :style="componentElements.componentBodyStyle" :model="model"
            :componentElements="componentElements"></component>

        <div :style="componentElements.componentFooterStyle">
            <div v-for="input in componentElements.footerInputs">
                <inputComponent :style="input.inputStyle" :input-element="input" :model="model"
                    :dynamic-elements="componentElements"></inputComponent>
            </div>
        </div>
    </div>
</template>

<script>
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue"
import inputComponent from "../../../frontend/components/atoms/input/inputComponent.vue"
import xmlOutputViewer from "../molecules/newXmlOutputViewer.vue";

export default {

    components: {
        tableComponent,
        inputComponent,
        xmlOutputViewer,
    },
    props: {
        componentElements: {
            type: Object,
            default: () => ({
            })
        },
        model: {},
    },
    data() {
        // Dışarıdan gelen verileri alabilirsiniz
        const externalData = this.componentElements.dataElements;

        // Elle eklemek istediğiniz verileri tanımlayabilirsiniz
        const additionalData = {

        };

        // Dışarıdan gelen verileri ve elle eklenen verileri birleştirerek döndürebilirsiniz
        return {
            ...externalData,
            ...additionalData
        };
    },
    created() {

        //Dışarıdan import etmek istediğimiz componentleri require eder
        if (Array.isArray(this.componentElements.importComponentNames)) {
            this.componentElements.importComponentNames.forEach((component) => {
                this.importComponent(component.componentName, component.componentPath);
            });
        }
        //Dışarıdan gelen createdFunc'ı çalıştırır. Create sırasında çalışmasını istediğimiz fonksiyon gönderilir
        if( this.componentElements.createdFunc != undefined){
            this.componentElements.createdFunc(this.model, this.componentElements)
        }
        
    },

    updated() {
        //Dışarıdan import etmek istediğimiz componentleri require eder
        if (Array.isArray(this.componentElements.importComponentNames)) {
            this.componentElements.importComponentNames.forEach((component) => {
                this.importComponent(component.componentName, component.componentPath);
            });
        }
        //Dışarıdan gelen updatedFunc'ı çalıştırır. Update sırasında çalışmasını istediğimiz fonksiyon gönderilir
        if (this.componentElements.updatedFunc != undefined) {
            this.componentElements.updatedFunc(this.model, this.componentElements)
        }
    },
    methods: {
        //Dışarıdan import etmek istediğimiz componentleri require eder
        importComponent(componentName, componentPath) {
            const componentImport = componentPath;
            this.$options.components[componentName] = componentImport.default;
        }
    },
    watch: {
        // componentIsChanged(val) {
        //     debugger
        //     if (val == true) {
        //         if (Array.isArray(this.componentElements.importComponentNames)) {
        //             this.componentElements.importComponentNames.forEach((component) => {
        //                 this.importComponent(component.componentName, component.componentPath);
        //             });
        //         }
        //     }
        // }
    }
}
</script>