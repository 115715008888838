<template>
  <v-app>
    <v-row>
      <v-col col="4">
        <table-component :dynamic-element="tableElements" :model="model" />
      </v-col>
      <v-col col="4">
      <table-component :dynamic-element="tableElements2" :model="model" style="margin-top: 21.5%;"/>
      </v-col>
      <v-col col="4">
      <table-component :dynamic-element="tableElements3" :model="model" style="margin-top: 19%;"/>   
      </v-col> 
      <Loader></Loader>
      <snackbar-component />
    </v-row>
  </v-app>
  
</template>

<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import XLSX from "xlsx";
import {
  openSnackbar,
  showLoader,
  openTablePage,
  getTable,
  deleteItem,
  closeDialog,
  excelDownloadDefault,
  //getTableModel,
  //showDialog,
  hideLoader,
    updateItem,
  saveItem
  //hashCode
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";
import {axiosUploadTypeArrayBuffer, checkProject} from "../../helper/uploadHelperForData"

export default {
  components: {
    "table-component": tableComponent,
    "upload-component": uploadComponent,
    Loader: Loader,
    "snackbar-component": snackbarComponent,
  },

  data() {
    return {
      tableElements: {},
      tableElements2: {},
      tableElements3: {},
      model: {},
      renderKey: 0,
      //selectedRows: [],
      required : (value) => !!value || "Gerekli.",

    };
  },

  created() {
    checkProject();
    this.loadData();
    this.calculateDepreciation()
  },
  watch: {},
  methods: {
    calculateDepreciation() {
      let that = this;
      showLoader('Hesaplamalar Yapılıyor')
      sendRequest(Enum.requestType.POST, "convertToDepreciation", "", {
        cookieData: window.$cookies.get("selectedItem")
      }, 
      function (response) {
        that.tableElements.data = response.data.data.data[0]
        that.tableElements2.data = response.data.data.data[1]
        that.tableElements3.data = response.data.data.data[2]
        that.tableElements.renderKey++
        that.tableElements2.renderKey++
        that.tableElements3.renderKey++
        hideLoader();
      },
      function (error) {
       console.log(error);
      })
    },
    loadData() {
      let that = this;
      this.tableElements = {
        sortBy: [],
        sortDesc: false,
        renderKey: 0,
        type: "table",
        tableHeight : 420,
        name: "amortismanTable",
        class: "",
        style: "",
        hidePagination: true,
        selectedRows: [],
        filteredColumn: {},
        //selectionUrl: "convertToDepreciation",
        initialFilter:{
          [Enum.initialFilter.AND] :
          [
            {
              projectId: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id
              },auditDate: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").auiditDate
              },preriorDate: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").preriorDate
              }
            }
          ]
        },
        showDefaultUpdateButon : true,
        showDefaultDeleteButon : true,
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
              [
                {
                  id: "excelDownload",
                  type: "v-btn",
                  variant: "btn",
                  name: "excelDownload",
                  inputText: "excelDownloadBtnText",
                  style:"height: 25px !important; margin-top: 60px; margin-bottom: 20px;",
                  divStyle: "",
                  icon: "mdi-file-excel",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    showLoader('Dosyanız Hazırlanıyor')
                    sendRequest(Enum.requestType.POST,"convertToDepreciation","",
                      {
                        excelDownload : true,
                        choice : "depreciation2",
                        cookieData: window.$cookies.get("selectedItem")
                      },
                      function (response) {
                        if (response.data.data.data != true) {
                          openSnackbar('error', "İndirilecek Veri Bulunamadı", 3000)
                          hideLoader();
                          return false
                        }
                        let zipName = "Amortisman Rapor"
                        axiosUploadTypeArrayBuffer("allExcelZipDownload", "depreciation2", zipName, "Excel İndirildi")
                      },
                      function (error) {
                        openSnackbar("error", error.response.data.error[0].detail.message);
                        hideLoader();
                      }
                    )
                  },
                },
                {
                  id : "amortismanDiv",
                  type : "v-text",
                  variant : "text",
                  name : "amortismanDiv",
                  style:" margin-left: 100px;",
                  inputText : "Birikmiş Amortismanlar",
                  divStyle:"",
                },
              ],
            ],
          }
        ],
        headers: [
          { text: "Kod", value: "accountCode", width: "100", divider: true },
          { text: "Tablo", value: "table", width: "100", dataType: "currency", divider: true, align: "right"},
          { text: "Mizan", value: "mizan", width: "100", dataType: "currency", divider: true, align: "right"},
          { text: "Değer", value: "value", width: "100", dataType: "currency", divider: true, align: "right" },
        ],
      };
      this.tableElements2 = {
        sortBy: [],
        sortDesc: false,
        renderKey: 0,
        type: "table",
        tableHeight : 420,
        name: "amortismanTable2",
        class: "",
        hidePagination: true,
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
              [
                {
                  id : "amortismanDiv",
                  type : "v-text",
                  variant : "text",
                  name : "amortismanDiv",
                  style:"margin-left: 150px; margin-top: 20px",
                  inputText : "Sabit Kıymet Listesi",
                  divStyle:"",
                },
              ]
            ]
          }
        ],
        headers: [
          { text: "Kod", value: "accountCode", width: "100", divider: true },
          { text: "Tablo", value: "table", width: "100", dataType: "currency", divider: true,align: "right"},
          { text: "Mizan", value: "mizan", width: "100", dataType: "currency", divider: true,align: "right"},
          { text: "Fark", value: "value", width: "100", dataType: "currency", divider: true ,align: "right"},
        ],
      }
      this.tableElements3 = {
        sortBy: [],
        sortDesc: false,
        renderKey: 0,
        type: "table",
        tableHeight : 420,
        name: "amortismanTable3",
        class: "",
        hidePagination: true,
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
              [
                {
                  id : "amortismanDiv",
                  type : "v-text",
                  variant : "text",
                  name : "amortismanDiv",
                  style:"margin-left: 150px;",
                  inputText : "Dönem Amortisman Giderleri",
                  divStyle:"",
                },
              ]
            ]
          }
        ],
        headers: [
          { text: "Kod", value: "accountCode", width: "100", divider: true },
          { text: "Gider Hesapları", value: "settingsAccs", width: "100", divider: true},
          { text: "Tablo", value: "table", width: "100", dataType: "currency", divider: true,align: "right"},
          { text: "Mizan", value: "mizan", width: "100", dataType: "currency", divider: true,align: "right"},
          { text: "Fark", value: "result", width: "100", dataType: "currency", divider: true,align: "right" },
        ],
      }
    }
  },
};
</script>

<style scoped>
::v-deep .threedot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 300px;
}
::v-deep .threedot:hover {
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}
v-row {
  margin-top: 50px;
}
</style>