<template>
  <v-app>
    <table-component :dynamic-element="tableElements" :model="model" />    
    <Loader></Loader>
    <snackbar-component />
  </v-app>
</template>

<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import {
  openSnackbar,
  openTablePage,
  getTable,
  showLoader,
  hideLoader
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import { sendRequest } from "../../../frontend/components/atoms/helper/requestHelper";
import {dateCalculation} from "../../helper/uploadHelperForData"
import {axiosUploadTypeArrayBuffer} from "../../helper/uploadHelperForData"


export default {
  components: {
    "table-component": tableComponent,
    "upload-component": uploadComponent,
    Loader: Loader,
    "snackbar-component": snackbarComponent,
  },

  data() {
    return {
      tableElements: {},
      model: {},
      renderKey: 0,
      //selectedRows: [],
      required : (value) => !!value || "Gerekli.",

    };
  },

  created() {
    this.loadData();
  },
  watch: {},
  methods: {
    loadData() {
      this.tableElements = {
        sortBy: ['date'],
        sortDesc: false,
        renderKey: 0,
        type: "table",
        tableHeight : 420,
        name: "amortismanTable",
        class: "",
        infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
        deleteBtnText: "Sil",
        excelDownloadBtnText: "İndir",
        saveBtnText : 'Yeni Kayıt',
        saveBtnSettings : {
          color: "blue",
          style:"height: 25px !important; ",
        },
        deleteBtnSettings : {
          color: "error",
          style:"height: 25px !important; ",
        },
        deleteDialogVisible : false,
        hidePagination: true,
        selectedRows: [],
        filteredColumn: {},
        filteredColumns: true,
        selectionUrl: "gettabledata",
        initialFilter: {
          [Enum.initialFilter.AND]: [
            {
              company_id: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem")
                  .companyId,
              },
              project_id: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id,
              },
              is_active: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem")
                  .isActive,
              },
            },
          ],
        },
        deleteUrl: "deleteTable",
        saveUrl : "insertSelectedItem",
        crudRequestObj :{
          placeOfAction : "inflationDeprication",
          company_id: window.$cookies.get("selectedItem").companyId,
          projectId: window.$cookies.get("selectedItem").id,
          auditDate: window.$cookies.get("selectedItem").auiditDate,
          preriorDate: window.$cookies.get("selectedItem").preriorDate
        },
        showDefaultUpdateButon : true,
        showDefaultDeleteButon : true,
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
              {
                  id: "excelDownload",
                  type: "v-btn",
                  variant: "btn",
                  name: "excelDownload",
                  inputText: "excelDownloadBtnText",
                  style:"height: 35px !important;",
                  divStyle: "",
                  icon: "mdi-file-excel",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    showLoader('Dosyanız Hazırlanıyor')
                    sendRequest(Enum.requestType.POST,"accountPlan",null,
                          {
                              filter : {
                                choice : "amortismanTable", orderBy : "orderBy",
                                projectId : window.$cookies.get("selectedItem").id
                              },
                              excelDownload : true
                          },
                          function (response) {
                            //let periodName =  this.vatDirectSelection.replace("/", "_")
                            let zipName = "Rapor Excel" + " " /*+ periodName*/
                            axiosUploadTypeArrayBuffer("allExcelZipDownload","amortismanTable", zipName, "Excel İndirildi")
                          },
                          function (error) {
                            openSnackbar(
                              "error",
                              error.response.data.error[0].detail.message
                            );
                            hideLoader();
                          }
                        )
                  },
                  
              }
            ],
          }
        ],
        headers: [
          { text: "Rapor Hesap Kodu", value: "accountCodeReport", width: "100" , search: true , sortable: true},
          { text: "Hesap Kodu", value: "accountCode", width: "100" , search: true , sortable: true},
          { text: "Hesap Adı", value: "accountName", width: "100" , search: true , sortable: true,class: "threedot"},
          { text: "Transfer(Aktifleştirme) Tarihi", value: "activateDate", width: "100" , search: true , sortable: true, dataType: "date" },
          { text: "Alış Tarihi", value: "date", width: "100" , search: true , sortable: true , dataType: "date"},
          { text: "Alış Değeri", value: "debt", width: "100" , search: true , sortable: true , dataType: "currency"},
          { text: "Kur Farkı", value: "exchangeRateDifference", width: "100" , search: true , sortable: true , dataType: "currency"},
          { text: "Faiz", value: "interest", width: "100" , search: true , sortable: true , dataType: "currency"},
          { text: "Diğer Maaliyetler", value: "otherCosts", width: "100", search: true , sortable: true , dataType: "currency"},
          { text: "Kalıntı Değeri", value: "residualValue", width: "100" , search: true , sortable: true ,dataType: "currency"},
          { text: "Yeniden Değerleme Tarihi", value: "revaluationDate", width: "100" , search: true , sortable: true, dataType: "date" }, 
          { text: "Yeniden Değerleme Maaliyeti", value: "revaluationCost", width: "100" , search: true , sortable: true,dataType: "currency" }, 
          { text: "Satış Tarihi", value: "sellDate", width: "100" , search: true , sortable: true , dataType: "date"}, 
          { text: "Satış Maaliyeti", value: "sellCost", width: "100" , search: true , sortable: true ,dataType: "currency"}, 
          { text: "MDV Kodu", value: "mdvCode", width: "100" , search: true , sortable: true }, 
          { text: "Gider Yeri (Gider Hesap Kodu)", value: "expenseAccountCode", width: "100" , search: true , sortable: true }, 
          { text: "Yöntem", value: "method", width: "100" , search: true , sortable: true }, 
          { text: "Süre(Yıl)", value: "duration", width: "100" , search: true , sortable: true },
          { text: "Amortismana Tabi Tarih", value: "depreciationCalculateDate", width: "100" , search: true , sortable: true ,dataType: "date"},
          { text: "Düzeltilmiş Maaliyet Bedeli", value: "depreciationCalculateDebt", width: "100" , search: true , sortable: true ,dataType: "currency"},
          { text: "Dönem Amortismanı", value: "depreciationPeriodValue", width: "100" , search: true , sortable: true ,dataType: "currency" },
          { text: "Toplam Birikmiş Amortisman", value: "depreciationTotalValue", width: "100" , search: true , sortable: true  ,dataType: "currency"},
          { text: "Endekslenmiş Maaliyet", value: "netValue", width: "100" , search: true , sortable: true ,dataType: "currency" ,dataType: "currency" },
          { text: "Amortismana Tabi Maaliyet", value: "residualValueCalculateDebt", width: "100" , search: true , sortable: true ,dataType: "currency" },
          { text: "Amortismana Tabi Dönem Amortismanı", value: "residualPeriodValue", width: "100" , search: true , sortable: true ,dataType: "currency" },
          { text: "Amortismana Tabi Toplam Birikmiş Amortisman", value: "residualTotalValue", width: "100" , search: true , sortable: true ,dataType: "currency" },
          { text: "Endekslenmiş Birikmiş Amortisman", value: "endexedBalance", width: "100" , search: true , sortable: true ,dataType: "currency" }
        ],
        columns: {

          accountCode: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: false,
            label : "Hesap Kodu",
            rules : [this.required],
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              editableObjects["account_code"] = model[thisData.id]
              sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "accountPlan", tableKey : "account_code", forKey : "subAccountDetailId", sequl : "id", spesificUpdateItem : true, fliterTableName :"t_account_detail"},
              function(response){
                openTablePage("amortismanTable")
                openSnackbar("success", response.data.data.value)
              }, 
              function(error){
                if (error.response != undefined){
                  openSnackbar("error", error.response.data.error[0].detail.message)
                } else (
                  openSnackbar("error", "Sunucu Hatası")
                )
              })
            }

          },
          accountCodeReport: {
            type: "v-text-field",
            editable: true,
            creatable: false,
            inlineEditable: true,
            label : "Rapor Hesap Kodu",
            rules : [this.required],
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              editableObjects["main_account_detail_id"] = model[thisData.id]
              sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "accountGroup", tableKey : "account_code_report", forKey : "mainAccountDetailId", sequl : "main_account_detail_id",spesificUpdateItem : true, fliterTableName :"v_inflation_report"},
              function(response){
                openTablePage("amortismanTable")
                openSnackbar("success", response.data.data.value)
              }, 
              function(error){
                if (error.response != undefined){
                  openSnackbar("error", error.response.data.error[0].detail.message)
                } else (
                  openSnackbar("error", "Sunucu Hatası")
                )
              })
            }

          },
          accountName: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Hesap Adı",
            rules : [this.required],
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              editableObjects["account_name"] = model[thisData.id]
              sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "accountPlan", tableKey : "account_name", forKey : "accountPlanId", sequl : "id",  fliterTableName :"t_account_detail"},function(response){
                openTablePage("amortismanTable")
                openSnackbar("success", response.data.data.value)
              }, 
              function(error){
                if (error.response != undefined){
                  openSnackbar("error", error.response.data.error[0].detail.message)
                } else (
                  openSnackbar("error", "Sunucu Hatası")
                )
              })
            }
          },
          activateDate: {
            type: "v-date-picker",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Sabit Kıymet Tarihi",
            rules : [this.required],
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              // let modelactivateDateSplit = model.activateDate.split("/")
              // modelactivateDateSplit = modelactivateDateSplit[2] + "-" + modelactivateDateSplit[1] + "-" + modelactivateDateSplit[0] 
              let auditDateIf = window.$cookies.get("selectedItem").auiditDate 
              let modelActivateDate = dateCalculation(model.activateDate, auditDateIf)
              if(modelActivateDate != false){
                editableObjects["activate_date"] = new Date(modelActivateDate)
                sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "depreciation", tableKey : "activate_date", forKey : "subAccountDetailId", sequl : "account_detail_id"},function(response){
                  openSnackbar("success", response.data.data.value)
                  openTablePage("amortismanTable")
                }, 
                function(error){
                  if (error.response != undefined){
                    openSnackbar("error", error.response.data.error[0].detail.message)
                  } else (
                    openSnackbar("error", "Sunucu Hatası")
                  )
                })
              }else{
                openSnackbar("error", "Tutarlı Tarih Giriniz")

              }
              
            }

          },
          date: {
            type: "v-date-picker",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Alış Tarihi",
            rules : [this.required],
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              // let modeldateSplit = model.date.split("/")
              // modeldateSplit = modeldateSplit[2] + "-" + modeldateSplit[1] + "-" + modeldateSplit[0] 
              let auditDateIf = window.$cookies.get("selectedItem").auiditDate 
              let modelDate = dateCalculation(model.date, auditDateIf)
              if(modelDate != false){
                editableObjects["date"] = new Date(modelDate)
                sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "accountDetail", tableKey : "date", forKey : "mainAccountDetailId", sequl : "id"},function(response){
                  openTablePage("amortismanTable")
                  openSnackbar("success", response.data.data.value)
                }, 
                function(error){
                  if (error.response != undefined){
                    openSnackbar("error", error.response.data.error[0].detail.message)
                  } else (
                    openSnackbar("error", "Sunucu Hatası")
                  )
                })
              }
              else{
                openSnackbar("error", "Tutarlı Tarih Giriniz")

              }
              
            }
          },
          debt: {
            type: "v-money",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Alış Değeri",
            value : "",
            money : {
              decimal: ',',
              thousands: '.',
              prefix: '',
              suffix: '',
              precision: 2,
              masked: false
            },                  
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              model[thisData.id] = model[thisData.id].replaceAll(".","").replaceAll(",",".")
              if(model[thisData.id].toString().startsWith("-")){
                model[thisData.id] = model[thisData.id].split("-")
                editableObjects["credit"] = model[thisData.id][1];
                editableObjects["debt"] = 0;
              }else{
                editableObjects["debt"] = model[thisData.id];
                editableObjects["credit"] = 0;
              }
              sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "accountDetail", tableKey : "debt", forKey : "subAccountDetailId", sequl : "id"},function(response){
                openTablePage("amortismanTable") 
                openSnackbar("success", response.data.data.value)
              }, 
              function(error){
                if (error.response != undefined){
                  openSnackbar("error", error.response.data.error[0].detail.message)
                } else (
                  openSnackbar("error", "Sunucu Hatası")
                )
              })
            }


          },
          exchangeRateDifference: {
            type: "v-money",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Kur Farkı",
            value : "",
            money : {
              decimal: ',',
              thousands: '.',
              prefix: '',
              suffix: '',
              precision: 2,
              masked: false
            },
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              model[thisData.id] = model[thisData.id].replaceAll(".","").replaceAll(",",".")
              editableObjects["exchange_rate_difference"] = model[thisData.id]
              sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "depreciation", tableKey : "exchange_rate_difference", forKey : "subAccountDetailId", sequl : "account_detail_id"},function(response){
                openTablePage("amortismanTable")
                openSnackbar("success", response.data.data.value)
              }, 
              function(error){
                if (error.response != undefined){
                  openSnackbar("error", error.response.data.error[0].detail.message)
                } else (
                  openSnackbar("error", "Sunucu Hatası")
                )
              })
            }

          },
          interest: {
            type: "v-money",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Faiz",
            money : {
              decimal: ',',
              thousands: '.',
              prefix: '',
              suffix: '',
              precision: 2,
              masked: false
            },          
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              model[thisData.id] = model[thisData.id].replaceAll(".","").replaceAll(",",".")
              editableObjects[thisData.id] = model[thisData.id]
              sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "depreciation", tableKey : "interest", forKey : "subAccountDetailId", sequl : "account_detail_id"},function(response){
                openTablePage("amortismanTable")
                openSnackbar("success", response.data.data.value)
              }, 
              function(error){
                if (error.response != undefined){
                  openSnackbar("error", error.response.data.error[0].detail.message)
                } else (
                  openSnackbar("error", "Sunucu Hatası")
                )
              })
            }

          },
          otherCosts: {
            type: "v-money",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Diğer Maaliyetler",
            money : {
              decimal: ',',
              thousands: '.',
              prefix: '',
              suffix: '',
              precision: 2,
              masked: false
            },          
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              model[thisData.id] = model[thisData.id].replaceAll(".","").replaceAll(",",".")
              editableObjects["other_costs"] = model[thisData.id]
              sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "depreciation", tableKey : "other_costs", forKey : "subAccountDetailId", sequl : "account_detail_id"},function(response){
                openTablePage("amortismanTable")
                openSnackbar("success", response.data.data.value)
              }, 
              function(error){
                if (error.response != undefined){
                  openSnackbar("error", error.response.data.error[0].detail.message)
                } else (
                  openSnackbar("error", "Sunucu Hatası")
                )
              })
            }

          },
          residualValue: {
            type: "v-money",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Kalıntı Değeri",
            money : {
              decimal: ',',
              thousands: '.',
              prefix: '',
              suffix: '',
              precision: 2,
              masked: false
            },          
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              model[thisData.id] = model[thisData.id].replaceAll(".","").replaceAll(",",".")
              editableObjects["residual_value"] = model[thisData.id]
              sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "depreciation", tableKey : "residual_value", forKey : "subAccountDetailId", sequl : "account_detail_id"},function(response){
                openTablePage("amortismanTable")
                openSnackbar("success", response.data.data.value)
              }, 
              function(error){
                if (error.response != undefined){
                  openSnackbar("error", error.response.data.error[0].detail.message)
                } else (
                  openSnackbar("error", "Sunucu Hatası")
                )
              })
            }

          },
          revaluationDate: {
            type: "v-date-picker",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Yeniden Değerleme Tarihi",
            rules : [this.required],
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              let auditDateIf = window.$cookies.get("selectedItem").auiditDate 
              let modelDate = dateCalculation(model.revaluationDate, auditDateIf)
              if(modelDate != false){
                editableObjects["revaluation_date"] =  new Date(model[thisData.id])
                sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "depreciation", tableKey : "revaluation_date", forKey : "subAccountDetailId", sequl : "account_detail_id"},function(response){
                  openTablePage("amortismanTable")
                  openSnackbar("success", response.data.data.value)
                }, 
                function(error){
                  if (error.response != undefined){
                    openSnackbar("error", error.response.data.error[0].detail.message)
                  } else (
                    openSnackbar("error", "Sunucu Hatası")
                  )
                })
              }
              else{
                openSnackbar("error", "Tutarlı Tarih Giriniz")

              }
              
            }

          },
          revaluationCost: {
            type: "v-money",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Yeniden Değerleme Maaliyeti",
            money : {
              decimal: ',',
              thousands: '.',
              prefix: '',
              suffix: '',
              precision: 2,
              masked: false
            },          
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              model[thisData.id] = model[thisData.id].replaceAll(".","").replaceAll(",",".")
              editableObjects["revaluation_cost"] = model[thisData.id]
              sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "depreciation", tableKey : "revaluation_cost", forKey : "subAccountDetailId", sequl : "account_detail_id"},function(response){
                openTablePage("amortismanTable")
                openSnackbar("success", response.data.data.value)
              }, 
              function(error){
                if (error.response != undefined){
                  openSnackbar("error", error.response.data.error[0].detail.message)
                } else (
                  openSnackbar("error", "Sunucu Hatası")
                )
              })
            }

          },
          sellDate: {
            type: "v-date-picker",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Satış Tarihi",
            rules : [this.required],
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              let auditDateIf = window.$cookies.get("selectedItem").auiditDate 
              let modelDate = dateCalculation(model.sellDate, auditDateIf)
              if(modelDate != false){
                editableObjects["sell_date"] = new Date(model[thisData.id])
                sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "depreciation", tableKey : "sell_date", forKey : "subAccountDetailId", sequl : "account_detail_id"},function(response){
                  openTablePage("amortismanTable")
                  openSnackbar("success", response.data.data.value)
                }, 
                function(error){
                  if (error.response != undefined){
                    openSnackbar("error", error.response.data.error[0].detail.message)
                  } else (
                    openSnackbar("error", "Sunucu Hatası")
                  )
                })
              }
            }
          },
          sellCost: {
            type: "v-money",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Satış Maaliyeti",
            money : {
              decimal: ',',
              thousands: '.',
              prefix: '',
              suffix: '',
              precision: 2,
              masked: false
            },          
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              model[thisData.id] = model[thisData.id].replaceAll(".","").replaceAll(",",".")
              editableObjects["sell_cost"] = model[thisData.id]
              sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "depreciation", tableKey : "sell_cost", forKey : "subAccountDetailId", sequl : "account_detail_id"},function(response){
                openTablePage("amortismanTable")
                openSnackbar("success", response.data.data.value)
              }, 
              function(error){
                if (error.response != undefined){
                  openSnackbar("error", error.response.data.error[0].detail.message)
                } else (
                  openSnackbar("error", "Sunucu Hatası")
                )
              })
            }

          },
          mdvCode: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "MDV Kodu",
            rules : [this.required],
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              editableObjects["mdv_code"] = model[thisData.id]
              sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "depreciation", tableKey : "mdv_code", forKey : "subAccountDetailId", sequl : "account_detail_id"},function(response){
                openTablePage("amortismanTable")
                openSnackbar("success", response.data.data.value)
              }, 
              function(error){
                if (error.response != undefined){
                  openSnackbar("error", error.response.data.error[0].detail.message)
                } else (
                  openSnackbar("error", "Sunucu Hatası")
                )
              })
            }

          },
          expenseAccountCode: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Gider Yeri (Gider Hesap Kodu)",
            rules : [this.required],
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              editableObjects["expense_account_code"] = model[thisData.id]
              sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "depreciation", tableKey : "expense_account_code", forKey : "subAccountDetailId", sequl : "account_detail_id"},function(response){
                openTablePage("amortismanTable")
                openSnackbar("success", response.data.data.value)
              }, 
              function(error){
                if (error.response != undefined){
                  openSnackbar("error", error.response.data.error[0].detail.message)
                } else (
                  openSnackbar("error", "Sunucu Hatası")
                )
              })
            }

          },
          method: {
            type: "v-text-field",
            editable: false,
            creatable: true,
            inlineEditable: false,
            label : "Yöntem",
            rules : [this.required],
          },
          duration: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Süre(Yıl)",
            rules : [this.required],
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              editableObjects[thisData.id] = model[thisData.id]
              sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "depreciation", tableKey : "duration", forKey : "subAccountDetailId", sequl : "account_detail_id"},function(response){
                openTablePage("amortismanTable")
                openSnackbar("success", response.data.data.value)
              }, 
              function(error){
                if (error.response != undefined){
                  openSnackbar("error", error.response.data.error[0].detail.message)
                } else (
                  openSnackbar("error", "Sunucu Hatası")
                )
              })
            }

          },
          depreciationCalculateDate: {
            type: "v-text-field",
            editable: true,
            creatable: false,
            inlineEditable: false,
            label : "Süre(Yıl)",
            rules : [this.required],
           
          },
          depreciationCalculateDebt: {
            type: "v-text-field",
            editable: true,
            creatable: false,
            inlineEditable: false,
            label : "Süre(Yıl)",
            rules : [this.required],
           
          },
          depreciationPeriodValue: {
            type: "v-text-field",
            editable: true,
            creatable: false,
            inlineEditable: false,
            label : "Süre(Yıl)",
            rules : [this.required],
           
          },
          depreciationTotalValue: {
            type: "v-text-field",
            editable: true,
            creatable: false,
            inlineEditable: false,
            label : "Süre(Yıl)",
            rules : [this.required],
           
          },
          netValue: {
            type: "v-text-field",
            editable: true,
            creatable: false,
            inlineEditable: false,
            label : "Süre(Yıl)",
            rules : [this.required],
           
          },
          residualValueCalculateDebt: {
            type: "v-text-field",
            editable: true,
            creatable: false,
            inlineEditable: false,
            label : "Süre(Yıl)",
            rules : [this.required],
           
          },
          residualPeriodValue: {
            type: "v-text-field",
            editable: true,
            creatable: false,
            inlineEditable: false,
            label : "Süre(Yıl)",
            rules : [this.required],
           
          },
          residualTotalValue: {
            type: "v-text-field",
            editable: true,
            creatable: false,
            inlineEditable: false,
            label : "Süre(Yıl)",
            rules : [this.required],
           
          },
          residualNetValue: {
            type: "v-text-field",
            editable: true,
            creatable: false,
            inlineEditable: false,
            label : "Süre(Yıl)",
            rules : [this.required],
           
          },
          
        },
        data: "data",
        showSelecetVisible: true,
        singleSelect: false,
        itemKey: "id",
        clientEvents : {
          onBeforeSave :function (selectedItem,creatableObjects,model,tableObject) {
            if((model.accountCode != undefined) && (model.accountName != undefined) && (model.accountCode != undefined) &&  (model.activateDate != undefined) && 
            (model.date != undefined) && (model.debt != undefined) && (model.duration != undefined) && (model.exchangeRateDifference != undefined) && (model.expenseAccountCode != undefined) && 
            (model.interest != undefined)  && (model.method != undefined) && (model.otherCosts  != undefined) && (model.residualValue != undefined)){
              let auditDateIf = window.$cookies.get("selectedItem").auiditDate 
              let resultActivateDate = dateCalculation(model.activateDate, auditDateIf)
              if(typeof resultActivateDate == "object"){
                creatableObjects.activateDate = resultActivateDate
                model.emincan = ""
              }
              else{
                openSnackbar("error", "Dönem Tarihinden Büyük Tarih Giremezsiniz");
              }
              let auditDate = window.$cookies.get("selectedItem").auiditDate 
              let resultDate = dateCalculation(model.date, auditDate)
              if(typeof resultDate == "object"){
                creatableObjects.date = resultDate
              }
              else{
                openSnackbar("error", "Dönem Tarihinden Büyük Tarih Giremezsiniz");
              }
              let auditRevaluationDate = window.$cookies.get("selectedItem").auiditDate 
              let resultRevaluationDate = dateCalculation(model.revaluationDate, auditRevaluationDate)
              if(typeof resultRevaluationDate == "object"){
                creatableObjects.date = resultRevaluationDate
              }
              else{
                openSnackbar("error", "Dönem Tarihinden Büyük Tarih Giremezsiniz");
              }
              let auditSellDate = window.$cookies.get("selectedItem").auiditDate 
              let resultSellDate = dateCalculation(model.sellDate, auditSellDate)
              if(typeof resultSellDate == "object"){
                creatableObjects.sellDate = resultSellDate
              }
              else{
                openSnackbar("error", "Dönem Tarihinden Büyük Tarih Giremezsiniz");
              }
            }else{
              openSnackbar("error", "Lütfen Girilmesi Zorunlu Satırları Boş Bırakmayınız");

            }
          }
        },
        footer: [
          {
            type: Enum.layoutType.div,
            name: "footerForm",
            inputElements: [
              [
                {
                  id: "pageSize",
                  type: "v-text-field",
                  variant: "number",
                  name: "pageSize",
                  label: "Görünecek Satır Sayısı",
                  value: "",
                  placeHolder: "",
                  style: "width: 45%; margin-left: 5%",
                  classTag: "",
                  onChange: function(thisData, model, dynamicElements) {
                    openTablePage("amortismanTable")
                  },
                },
              ],
              [
                {
                  id: "currentPageNo",
                  type: "v-pagination",
                  variant: "pagination",
                  name: "currentPageNo",
                  divStyle: "",
                  style: "width:850px;",
                  classTag: "my-1",
                  length: "pagesCount",
                  onInput: function(thisData, model, dynamicElements) {
                    openTablePage("amortismanTable")
                  },
                },
              ],
              [
                {
                  id: "infoTotalRowCountHeaderAssistantTable",
                  type: "p",
                  variant: "p",
                  name: "infoTotalRowCountHeaderAssistantTable",
                  inputText: "infoTotalRowCountHeaderAssistantTable",
                  divStyle: "",
                  style: "font-size: 12px;font-weight:500;margin:3px 0;color:#666A6F;margin-right: 2%;margin-left: 60%;",
                  classTag: ""
                },
                {
                  id: "infoTotalRowCountAssistantTable",
                  type: "p",
                  variant: "p",
                  name: "infoTotalRowCountAssistantTable",
                  inputText: "itemCount",
                  divStyle: "",
                  style: "font-size: 15px;font-weight:500;margin:3px 0;color:#666A6F;margin-left: 82%;",
                  classTag: ""
                },
              ],
            ],
          },
        ]
      };
     
    }
  },
};
</script>

<style scoped>
::v-deep .threedot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 300px;
}
::v-deep .threedot:hover {
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}
</style>
