import { render, staticRenderFns } from "./inputComponent.vue?vue&type=template&id=2afc9feb&"
import script from "./inputComponent.vue?vue&type=script&lang=js&"
export * from "./inputComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../vue/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports