<!-- eslint-disable -->
<template>
  <div>
    <v-card elevation="24" outlined class="mx-auto my-12 " max-width="500">
      <v-card-title class="justify-center">Otomatik Veri Girişi
        <v-tooltip bottom>
        <template v-slot:activator="{ on , attrs}">
          <v-icon
          color="error"
          dark
          v-bind="attrs"
          v-on="on"
        >
          info
        </v-icon>
        </template>
        <span>Seçilen Entegratör : {{selectColIntegrator}}</span>
      </v-tooltip>
      </v-card-title>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container>
          <v-row>
            <v-col cols="12" lg="6">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDateFormatted"
                    label="Başlangıç Tarihini Seçiniz"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  @input="menu1 = false"
                  locale="tr"

                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" lg="6">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedDateFormatted2"
                    label="Bitiş Tarihini Seçiniz"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date2"
                  no-title
                  locale="tr"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-container>
        

        <v-container fluid>
          <v-row>
            <v-col cols="4" md="6">
              <v-combobox
                v-model="selectchoice"
                :items="itemchoice"
                label="İşlem Türünü Seçiniz"
                outlined
                multiple
                dense
                class="combobox"
              ></v-combobox>
            </v-col>
          </v-row>
        </v-container>
        <v-card-title class="justify-center">
          <v-btn color="blue" outlined @click="validate" style="margin-right: 2%"> Başlat </v-btn>
          <v-btn color="error" outlined @click="reset" style="margin-left: 2%"> Formu Sıfırla </v-btn>
        </v-card-title>
        <div>
          <v-dialog v-model="alertModel">
            <v-alert prominent type="error" v-model="alertModel">
              <v-row align="center">
                <v-col class="grow">
                  {{ infoMessage }}
                </v-col>
              </v-row>
            </v-alert>
          </v-dialog>
          <snackbar-component />
          <Loader></Loader>
        </div>
      </v-form>
    </v-card>
    <v-dialog v-model="defaultBotFlag" width="600px" persistent>
      <v-list color="blue-grey">
        <v-list-item>
          <v-list-item-title style="color:white; text-align: center"
            >Lütfen Bir Entegratör Seçimi Yapınız.
          </v-list-item-title>
        </v-list-item>
        <v-list-item-subtitle>
          <div class="text-center">
            <v-btn
              text
              class="my2"
              color="white"
              router-link
              to="/settings"
              >Tamam</v-btn
            >
          </div>
        </v-list-item-subtitle>
      </v-list>
    </v-dialog>

  </div>
</template>
<!-- eslint-disable -->
<script>
import { sendRequest } from "../../../frontend/components/atoms/helper/requestHelper.js";
import Enum from "../helper/Enum.json";
import { router } from "../router/router.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import Loader from "../../../frontend/components/atoms/loader";
import {
    openSnackbar,
    showLoader,
    hideLoader
} from "../../../frontend/components/atoms/helper/elementHelper.js";
export default {
    components: {
        "snackbar-component": snackbarComponent,
        Loader : Loader
    },
    data: (vm) => ({
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        dateFormatted: vm.formatDate(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)),
        date2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        dateFormatte2: vm.formatDate(new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)),
        menu1: false,
        menu2: false,
        valid: true,
        alertModel: false,
        infoMessage: "",
        selectchoice: null,
        itemchoice: [
          "E-Arşiv (Satış)",
          "E-Fatura (Satış)",
          "E-Fatura (Alış)",
          "Gib E-Arşiv (Alış)",
          // "Gib E-Arşiv (Satış)",
          "GÇB"
        ],
        defaultBotFlag : false,
        selectColIntegrator : ''
    }),

  created(){
    this.getCompanySettings();
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
    computedDateFormatted2() {
      return this.formatDate(this.date2);
    },
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
    date2(val) {
      this.dateFormatted2 = this.formatDate(this.date2);
    },
  },

  methods: {
    async validate() {
      let startDate = this.computedDateFormatted;
      let endDate = this.computedDateFormatted2;
      startDate = new Date(startDate.split("/")[2] + "-" + startDate.split("/")[1] + "-" + startDate.split("/")[0]);
      endDate = new Date(endDate.split("/")[2] + "-" + endDate.split("/")[1] + "-" + endDate.split("/")[0]);
      if(this.selectchoice != null){
        if (startDate.getFullYear() == endDate.getFullYear() && (new Date(endDate).getMonth() - new Date(startDate).getMonth()) == 0) {
          if ( endDate >= startDate ) {
            try {
              showLoader("Verileriniz Hazırlanıyor. Lütfen Bekleyiniz...")
              await sendRequest(
                Enum.requestType.POST,"botController","",
                {
                  computedDateFormatted: this.computedDateFormatted,
                  computedDateFormatted2: this.computedDateFormatted2,
                  selectchoice: this.selectchoice,
                  itemschoice: this.itemschoice,
                },
                function (response) {
                  if(response.status == 200){
                    hideLoader()
                    //openSnackbar("success",response.data.data.value);
                    router.push('folderNotification')

                  }else{
                    hideLoader()
                    openSnackbar("error",response.data.data.value);
                  }
                },
                function (error) {
                  hideLoader()
                  openSnackbar("error","Hata oluştu.");
                }
              );
            } catch (error) {
                console.log(error);
                hideLoader()
                openSnackbar("error","Hata oluştu.");
            }
          }
          else {
            openSnackbar("error","Tarih Aralığını Yanlış Girdiniz", 2500)
          }
        }else{
          openSnackbar("error","Tarih Aralığını Yanlış Girdiniz Tarih Farkı 1 Aydan Büyük Olamaz", 2500)
        }
      }
      else{
        openSnackbar("error","Bir İşlem Türü Seçiniz", 2500)
      }
    },
    getCompanySettings(){
      let that = this;
      sendRequest("POST","getCompanySettings","",{},function (res) {
        let userSelectedEntegrator = {
          accountingDigitalPlanet : "Dijital Planet",
          accountingEfinans : "QNB Finans Bank",
          accountingIsnet : "İşnet",
          accountingLogo : "E-Logo",
          accountingMikro : "Mikro",
          accountingEdmBilisim : "EDM Bilişim",
          accountingIziBiz : "İzi Bizi",
          accountingVega : "Vega",
          accountingVisionPlus : "Vision Plus",
          accountingForiba : "Fit Bulut"

        }
        if(res.data.data.settings.didero.integratorUserAccount != undefined){
          that.selectColIntegrator = userSelectedEntegrator[res.data.data.settings.didero.integratorUserAccount.selectedIntegrator]
        }else{
          that.defaultBotFlag = true;
        }
        if (res.data.data.settings.didero.defaultAccountCode == undefined) {
          //openSnackbar('error', 'Lütfen Varsayılan Hesapları Atayınız')
        }
        if (res.data.data.settings.didero.defaultAccountCode.sales[0].oneAccount.accountCode == '') {
          //openSnackbar('error', 'Lütfen Varsayılan Hesapları Atayınız')
        }
        if (that.selectColIntegrator == undefined) {
          that.defaultBotFlag = true;
        }
      },
      function(error) {
        openSnackbar("error", 'Hata Oluştu');
      })
    },
    reset() {
      this.$refs.form.reset();
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
};
</script>
<style scoped>
.combobox{
  margin-left: 120px !important ;
  width: 250px;
}
.buttons{
  margin-left: 80px;
}
</style>