<template>
  <div class="filter">
    <div style=""><h1>Filtrele</h1></div>
    <div style="display: flex; flex-direction: row; flex-wrap: wrap; gap : 20px">
      <div
        v-for="(element, index) in filterArray"
        :key="element.id"
        style="display: flex; flex-direction: row; flex-wrap: no-wrap; gap : 20px; min-width : 400px"
      >
        <div v-if="element.input == 'dateTime'">
          <v-menu
            ref="menu1"
            v-model="element['menu']"
            :name="element.name"
            :id="element.name"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
            :key="element.renderKey"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :key="element.renderKey"
                v-model="element.ValueFormatted"
                :label="element.label"
                hint="MM/DD/YYYY format"
                persistent-hint
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                @blur="element.value = parseDate(element.ValueFormatted, index)"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="element.value"
              :name="element.name"
              :id="element.name"
              no-title
              @input="element['menu'] = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div v-if="element.input == 'dateTime' && element.operator == 'İki Filtre Arası'">
          <v-menu
            :key="element.renderKey"
            ref="menu2"
            name="deneme"
            id="deneme"
            v-model="element['menu2']"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :key="element.renderKey"
                v-model="element.ValueFormatted2"
                label="Bitiş Tarihi"
                hint="MM/DD/YYYY format"
                persistent-hint
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                @blur="
                  element.value2 = parseDate(element.ValueFormatted2, index)
                "
                v-on="on"
              >
              </v-text-field>
            </template>

            <v-date-picker
              name="deneme"
              id="deneme"
              v-model="element.value2"
              no-title
              @input="element['menu2'] = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div v-if="element.input == 'v-select'">
          <v-select
            v-model="element.ValueFormatted"
            :items="element.items"
            chips
            :label="element.label"
            :multiple="element.multiple"
          ></v-select>
        </div>
        <div v-if="element.input == 'text'">
          <v-text-field
            v-model="element.ValueFormatted"
            :key="element.renderKey"
            :label="element.label"
            :placeholder="element.placeholder"
            :disabled="element.disabled == undefined ? false : ''"
          >
          </v-text-field>
        </div>
        <div v-if="element.input == 'number'">
          <v-text-field
            v-model="element.ValueFormatted"
            :key="element.renderKey"
            :label="element.label"
            :placeholder="element.placeholder"
            :disabled="element.disabled == undefined ? false : ''"
          >
          </v-text-field>
        </div>
        <v-menu  offset-y v-if="element.input != 'button'">
          <template v-slot:activator="{ on, attrs }">
            <v-btn style="margin-top : 1rem" v-bind="attrs" v-on="on">
              <v-icon left>mdi-menu</v-icon> {{element.operator}}
            </v-btn>
          </template>
          <v-card>
            <v-card-text class="pa-6">
              <v-row style="width: fit-content; flex : auto" v-for="filter in getFiterEnums(element)" :key="filter.id">
                <v-btn
                  style="width : auto"
                  large
                  text
                  color="primary"
                  @click="element.formatValue(element, filter)"
                  ><v-icon left> mdi-target</v-icon
                  >{{ filter.filterName }}</v-btn
                >
              </v-row>
            </v-card-text>
          </v-card>
        </v-menu>
      </div>
    </div>
    <v-btn style="color:red; display:block"  @click="getFilter()">Filtrele</v-btn>
  </div>
</template>
<script>
//filtreleye bastığında json olarak fonksiyon olacak adı ilk value operatörü ne
//filtreleen değeri array olarak çıkartan şeyi yaz
import { sendRequest } from "../../../../components/atoms/helper/requestHelper";
import Enum from "../../../../components/Enum.json";

export default {
  props: {
        filterArray : {
          type: Array,
          default: []
        },
      },
  data(vm) {
    return {
      filterProps: [],
      vm: vm,
      // obj: [
      //   {  input: "dateTime",  label: "Başlangıç Tarihi",  function: "",  class: "",  operator: "Equal",  id: 0,  name: "billDate",  value: null,  value2: null,},
      //   {  input: "dateTime",  label: "Başlangıç Tarihi",  function: "",  class: "",  operator: null,  id: 1,  name: "transactionDate",  value: null,  value2: null,},
      //   {  input: "v-select",  label: "vselect",  function: "",  class: "",  value: "",  items: ["foo", "bar", "fizz", "buzz"],  multiple: false,},
      //   {  input: "text",  label: "text",  function: "",  class: "",  value: "",  multiple: false,  mask: [],  ValueFormatted: null,}, //rules ismi input.mask olarak gelecek mask fonksiyonu yazılacak thousent operatör decimalspereatör
      //   {  input: "dateTime",  label: "Başlangıç Tarihi",  function: "",  class: "",  operator: "Equal",  id: 0,  name: "billDate",  value: null,  value2: null,},
      //   {  input: "number",  label: "number",  function: "",  class: "",  value: "",  multiple: false,  mask: [],  ValueFormatted: null,}, //rules ismi input.mask olarak gelecek mask fonksiyonu yazılacak thousent operatör decimalspereatör
      //   // {input :  "button" , label :"Filtrele", function : "", class : ""}
      // ],
      filterDateEnums: [
        { filterName: "Eşit Olanlar", vIcon: "", id: 0 },
        { filterName: "Eşit Olmayanlar", vIcon: "", id: 1 },
        { filterName: "İçerik Sorgusu(like)", vIcon: "", id: 2 },
        { filterName: "Daha büyük", vIcon: "", id: 3 },
        { filterName: "Büyüktür veya Eşittir", vIcon: "", id: 4 },
        { filterName: "Daha az", vIcon: "", id: 5 },
        { filterName: "Az veya eşit", vIcon: "", id: 6 },
        { filterName: "Çoklu Sorgu", vIcon: "", id: 7 },
        { filterName: "Eşit Olmaması", vIcon: "", id: 8 },
        { filterName: "İki Filtre Arası", vIcon: "", id: 9 },
      ],
      filtertextEnums: [
        { filterName: "Eşit Olanlar", vIcon: "", id: 0 },
        { filterName: "İçerik Sorgusu(like)", vIcon: "", id: 1 },
      ],
      filterVSelectEnums: [
        { filterName: "Eşit Olanlar", vIcon: "", id: 0 },
        { filterName: "Eşit Olmayanlar", vIcon: "", id: 1 },
        { filterName: "İçerik Sorgusu(like)", vIcon: "", id: 2 },
        { filterName: "Daha büyük", vIcon: "", id: 3 },
        { filterName: "Büyüktür veya Eşittir", vIcon: "", id: 4 },
        { filterName: "Daha az", vIcon: "", id: 5 },
        { filterName: "Az veya eşit", vIcon: "", id: 6 },
        { filterName: "Çoklu Sorgu", vIcon: "", id: 7 },
        { filterName: "Eşit Olmaması", vIcon: "", id: 8 },
      ],
      filterNumberEnums: [
        { filterName: "Eşit Olanlar", vIcon: "", id: 0 },
        { filterName: "İçerik Sorgusu(like)", vIcon: "", id: 1 },
      ],
    };
  },
  created() {
    this.setItemProps();
  },
  methods: {
    getFiterEnums(element) {
      switch (element.input) {
        case "dateTime":
          return this.filterDateEnums;
        case "v-select":
          return this.filterVSelectEnums;
        case "text":
          return this.filtertextEnums;
        case "number":
          return this.filterNumberEnums;
        default:
          break;
      }
    },
    filterConverter() {
      for (let i = 0; i < this.filterArray.length; i++) {
        this.filterProps.push({
          operator: this.filterArray[i].operator,
          name: this.filterArray[i].name,
          value: this.filterArray[i].value,
          value2: this.filterArray[i].value2,
        });
      }
    },
    getFilter() {
      let subFilter = this.filterConverter();

      //execute function olacak kullanıcı tarafından verilecek eğer ki bu filter componenti bir filter componentin içinde tanımlamadıysa kafasına göre yapçak eğer table içinde yapıldıysa connectionstring tanımlayıp onu gönderecek
      // sendRequest(Enum.requestType.POST,"getViewData",null,{filter : {initialFilter:this.filterArray} },
      //   function (response) {
      //   },
      //   function (error) {
      //   }
      //)
    },

    setItemProps() {
      for (let i = 0; i < this.filterArray.length; i++) {
        this.setInitialProps(this.filterArray[i]);
      }
    },

    setInitialProps(objItem) {
      objItem.renderKey = 0;
      switch (objItem.input) {
        case "dateTime":
          objItem.formatValue = this.newBetweenDate;
          objItem.ValueFormatted = objItem.formatValue(objItem, {
            filterName: "Eşit Olanlar",});
          break;
        case "text":
          objItem.formatValue = this.formattext;
          objItem.ValueFormatted = objItem.formatValue(objItem,{filterName : "Eşit Olanlar"})
          break;
        case "number":
          objItem.formatValue = this.formatDouble;
          objItem.ValueFormatted =  objItem.formatValue(objItem,{filterName : "Eşit Olanlar"});
          break;
        case "v-select":
          objItem.formatValue = this.formatDouble;
          objItem.ValueFormatted =  objItem.formatValue(objItem,{filterName : "Eşit Olanlar"});
          break;
          
      }
    },
    formatDouble(objItem, filter) {
      if (filter != null && filter != undefined) {
        objItem.operator = filter.filterName;
        objItem.renderKey++;
      }
      if (objItem.ValueFormatted != undefined) {
        const inputValue = objItem.ValueFormatted;
        if (inputValue != "" && inputValue != null) {
          let numericValue = inputValue.replace(/\D/g, "hata");
          if (numericValue.includes("hata")) {
            alert("Lütfen Sadece Numara Giriniz");
            objItem.ValueFormatted = objItem.ValueFormatted.slice(0,objItem.ValueFormatted.length-1)
            objItem.renderKey++;
            // objItem.ValueFormatted = "";
            // this.filterArray[i].ValueFormatted = "";
            // this.filterArray[i].renderKey++;
          } 
          else {
          }
        }
      }
      return objItem.ValueFormatted;
    },
    vselect(objItem, filter) {
      objItem.operator = filter.filterName;
      objItem.renderKey++;
      return "";
    },
    formattext(objItem, filter) {
      if (filter != null && filter != undefined) {
        objItem.operator = filter.filterName;
        objItem.renderKey++;
      }
      if (objItem.ValueFormatted != undefined) {
        const inputTextValue = objItem.ValueFormatted;
        if (inputTextValue != "" && inputTextValue != null) {
          let turkishAlphanumericValue = inputTextValue.replace(/[^a-zA-ZğüşıöçĞÜŞİÖÇ]/g, "hata");
          if (turkishAlphanumericValue.includes("hata")) {
            alert("Lütfen Sadece Harf Giriniz");

            objItem.ValueFormatted = objItem.ValueFormatted.slice(0,objItem.ValueFormatted.length-1)
            objItem.renderKey++;
            // objItem.ValueFormatted = "";
          //   this.filterArray[i].ValueFormatted = "";
            // this.filterArray[i].renderKey++;
          }
        }
      }
      
      return objItem.ValueFormatted;
    },
    newBetweenDate(objItem, filter) {
      let valueKeys = "";
      if (filter.filterName == "İki Filtre Arası") {
        valueKeys = "2";
      }
      objItem.operator = filter.filterName;
      objItem["value" + valueKeys] = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      objItem["ValueFormatted" + valueKeys] = this.formatDate(
        objItem["value" + valueKeys]
      );
      objItem["menu" + valueKeys] = false;
      objItem.renderKey++;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date, index) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      // this.filterArray[index].Date = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      this.filterArray[index].ValueFormatted = this.formatDate(this.filterArray[index].value);
      this.filterArray[index].ValueFormatted2 = this.formatDate(this.filterArray[index].value2);

      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
  },
  watch: {
    filterArray: {
      handler: function (val, oldVal) {
        for (let i = 0; i < this.filterArray.length; i++) {
          switch (this.filterArray[i].input) {
            case "dateTime":
              this.filterArray[i].ValueFormatted = this.formatDate(val[i].value);
              if (this.filterArray[i].value2 != null) {
                this.filterArray[i].ValueFormatted2 = this.formatDate(val[i].value2);
              }
              this.filterArray[i].renderKey++;
              break;
            case "text":
              val[i].ValueFormatted = this.formattext(val[i]);
              break;
            case "number":
              val[i].ValueFormatted = this.formatDouble(val[i]);
              break;
            case "v-select":
              // val[i].ValueFormatted = this.formatDouble(val[i]);

          }
        }
      },
      deep: true,
    },
  },
  computed: {
    ComputedDateFormatted(date) {
      return this.formatDate(date);
    },
  },
};
</script>
