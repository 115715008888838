
<template>
  <div>
    <Loader></Loader>
    <!-- max-width="890" -->
      <v-dialog v-model="dialogKkegAccounts" max-width="1390">
        <v-card>
          <h3 style="text-align: center;">KKEG Hesapları</h3>
          <layout style="display: flex; justify-content: center; text-align: center" :dynamic-elements="dynamicElements[5]" :layout-elements="dynamicElements[5]" :model="model"/>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="white" text @click="dialogKkegAccounts = false" style="background-color: red; height: 25px; width: 93px; margin-right: 628px;">Kapat</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDepreciationAccounts" max-width="990">
        <v-card>
          <h3 style="text-align: center;">Amortisman Gider Hesapları</h3>
          <layout style="display: flex; justify-content: center; text-align: center" :dynamic-elements="dynamicElements[2]" :layout-elements="dynamicElements[2]" :model="model"/>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="white" text @click="dialogDepreciationAccounts = false" style="background-color: red; height: 25px; width: 93px; margin-right: 428px;">Kapat</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogRentAccounts" max-width="600">
        <v-card>
          <h3 style="text-align: center;">Kira Hesapları</h3>
          <layout style="display: flex; justify-content: center; text-align: center" :dynamic-elements="dynamicElements[6]" :layout-elements="dynamicElements[6]" :model="model"/>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="white" text @click="dialogRentAccounts = false" style="background-color: red; height: 25px; width: 93px; margin-right: 42%;">Kapat</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogEBillsAccounts">
        <v-card>
          <v-data-table
            :headers="generalAssignmentHeaders"
            :items="generalAssignmentDessertsSales"
            hide-default-footer
          >
          <template v-for="header in generalAssignmentHeaders" v-slot:[`item.${header.value}`]="{item}">
            <div :key="header.value">
              <v-row>
                <v-col cols="9" style="padding-right: 0px">
                  <v-tooltip bottom>
                    <template v-slot:activator="{on}" >
                      <v-autocomplete
                        v-if="header.dataType =='select' && item[header.value] != ''"
                        style="margin-bottom:-20px;max-height:60px;" 
                        :items="accountCodes"
                        :filter="customFilterForAccount"
                        v-model="item[header.value]"
                        item-text="accountCode"
                        return-object
                        outlined
                        dense
                        v-on="on"
                      >
                        <template v-slot:item="{item}">
                          {{item.accountCode + " " + item.accountName}}
                        </template>
                      </v-autocomplete>
                      <slot v-else>{{item[header.value]}}</slot>
                    </template>
                    <span>{{setTooltipSpanFunc(item[header.value])}}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </div>
          </template>
          </v-data-table>
          <v-data-table
            :headers="generalAssignmentHeaders"
            :items="generalAssignmentDessertsPurchase"
            hide-default-footer
          >
          <template v-for="header in generalAssignmentHeaders" v-slot:[`item.${header.value}`]="{item}">
            <div :key="header.value">
              <v-row>
                <v-col cols="9" style="padding-right: 0px">
                  <v-tooltip bottom>
                    <template v-slot:activator="{on}" >
                    <v-autocomplete
                      style="margin-bottom:-20px;max-height:60px;"
                      v-if="header.dataType =='select' && item[header.value] != ''"
                      :items="accountCodes"
                      :filter="customFilterForAccount"
                      v-model="item[header.value]"
                      item-text="accountCode"
                      return-object
                      outlined
                      dense
                      v-on="on"
                    >
                      <template v-slot:item="{item}">
                        {{item.accountCode + " " + item.accountName}}
                      </template>
                    </v-autocomplete>
                    <slot v-else>{{item[header.value]}}</slot>
                  </template>
                  <span>{{setTooltipSpanFunc(item[header.value])}}</span>
                </v-tooltip>
                </v-col>
              </v-row>
            </div>
          </template>
          </v-data-table>
          <br>
          <v-row no-gutters>
          <v-col cols="4">
            <h4 style="margin-top:10px;margin-left: 2%">Araç Kısıtlaması(KKEG)</h4>
          </v-col>
          <v-col cols="4">
            <h4 style="margin-top:13px;margin-left: 42%">Trafik Cezası</h4>
          </v-col>
        </v-row>
        <v-row style="margin-top: -5px" no-gutters>
          <v-col cols="2" style="padding-right: 0px;margin-top:12px">
            <v-tooltip bottom>
              <template v-slot:activator="{on}">   
                <v-autocomplete
                  label="Matrah Hesap Kodu"
                  style="margin-left: 10px"
                  item-text="accountCode"
                  v-model="defaultAccountCode['accountPlanVehicleBaseCode']"
                  :items="accountCodes"
                  return-object
                  :filter="customFilterForAccount"
                  outlined
                  dense
                  v-on="on"
                >
                  <template v-slot:item="{item}">
                    {{item.accountCode + " " + item.accountName}}
                  </template>
                </v-autocomplete>
              </template>
              <span>{{setTooltipSpanFunc(defaultAccountCode['accountPlanVehicleBaseCode'])}}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2" style="padding-left: 0px;margin-top:12px; margin-left: 10%;">
            <v-tooltip bottom>
              <template v-slot:activator="{on}"> 
                <v-autocomplete
                  style="margin-left: -25%"
                  label="KDV Hesap Kodu"
                  v-model="defaultAccountCode['accountPlanVehicleVatCode']"
                  :items="accountCodes"
                  return-object
                  :filter="customFilterForAccount"
                  item-text="accountCode"
                  outlined
                  dense
                  v-on="on"
                >
                  <template v-slot:item="{item}">
                    {{item.accountCode + " " + item.accountName}}
                  </template>
                </v-autocomplete>
              </template>
              <span>{{setTooltipSpanFunc(defaultAccountCode['accountPlanVehicleVatCode'])}}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2" style="padding-right: 0px;margin-top:12px;">
            <v-tooltip bottom>
              <template v-slot:activator="{on}">
                <v-autocomplete
                  style="margin-left: 10px"
                  label="Ceza Hesap Kodu"
                  v-model="defaultAccountCode['accountPlanPunishmentAccountCode']"
                  :items="accountCodes"
                  return-object
                  :filter="customFilterForAccount"
                  :item-text="'accountCode'"
                  outlined
                  dense
                  v-on="on"
                >
                  <template v-slot:item="{item}">
                    {{item.accountCode + " " + item.accountName}}
                  </template>
                </v-autocomplete>
              </template>
              <span>{{setTooltipSpanFunc(defaultAccountCode['accountPlanPunishmentAccountCode'])}}</span>
            </v-tooltip>
          </v-col>
          <!-- <v-col cols="3" style="padding-left: 0px;margin-top:-7px">
            <v-checkbox
              v-model="defaultAccountCode['notFoundAccountCheckbox']"
              label="Bulunmayan Cari Hesaba Varsayılan Hesap Atansın"
            ></v-checkbox>
          </v-col> -->
        </v-row>
        <v-row no-gutters>
          <v-col cols="4">
            <h4 style="margin-top:-10px;margin-left: 2%">KDV Tevkifat Tutarı</h4>
          </v-col>
          <v-col cols="4">
            <h4 style="margin-top: -10px;margin-left: 42%">İletişim Vergisi</h4>
          </v-col>
        </v-row>
        <v-row style="margin-top: -5px" no-gutters>
          <v-col cols="2" style="padding-right: 0px;margin-top:12px">
            <v-tooltip bottom>
              <template v-slot:activator="{on}">
                <v-autocomplete
                  style="margin-left: 10px"
                  label="Sorumlu Sıfatı Ödenecek KDV"
                  :items="accountCodes"
                  v-model="defaultAccountCode['accountPlanPayableCode']"
                  :filter="customFilterForAccount"
                  return-object
                  :item-text="'accountCode'"
                  outlined
                  dense
                  v-on="on"
                >
                  <template v-slot:item="{item}">
                    {{item.accountCode + " " + item.accountName}}
                  </template>
                </v-autocomplete>
              </template>
              <span>{{setTooltipSpanFunc(defaultAccountCode['accountPlanPayableCode'])}}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2" style="padding-right: 0px;margin-top:12px;margin-left: 10%;">
            <v-tooltip bottom>
              <template v-slot:activator="{on}">
                <v-autocomplete
                  style="margin-left: -25%"
                  label="Sorumlu Sıfatı İnd. KDV"
                  v-model="defaultAccountCode['accountPlanDeductedCode']"
                  return-object
                  :item-text="'accountCode'"
                  :items="accountCodes"
                  :filter="customFilterForAccount"
                  outlined
                  dense
                  v-on="on"
                >
                  <template v-slot:item="{item}">
                    {{item.accountCode + " " + item.accountName}}
                  </template>
                </v-autocomplete>
              </template>
              <span>{{setTooltipSpanFunc(defaultAccountCode['accountPlanDeductedCode'])}}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="2" style="padding-right: 0px;margin-top:12px;">
            <v-tooltip bottom>
              <template v-slot:activator="{on}">
                <v-autocomplete
                  style="margin-left: 10px"
                  label="İletişim Vergisi"
                  :items="accountCodes"
                  v-model="defaultAccountCode['accountPlanCommunicationVatCode']"
                  :filter="customFilterForAccount"
                  return-object
                  :item-text="'accountCode'"
                  outlined
                  dense
                  v-on="on"
                >
                  <template v-slot:item="{item}">
                    {{item.accountCode + " " + item.accountName}}
                  </template>
                </v-autocomplete>
              </template>
              <span>{{setTooltipSpanFunc(defaultAccountCode['accountPlanCommunicationVatCode'])}}</span>
            </v-tooltip>
          </v-col>
        </v-row>
          <v-card-actions style="display: flex; justify-content: right; text-align: right">
           <v-btn color="green" text @click="saveDefaultAccountCode">Kaydet</v-btn>
           <v-btn color="red" text @click="dialogEBillsAccounts = false">Kapat</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row class="pa-10">
        <v-col class="justify-center" md="4">
          <v-card elevation="24" outlined style="width: 95%; height : 100%" >
            <v-toolbar style="background-color: rgb(41, 64, 95); border-color: rgb(41, 64, 95);" dark flat>
              <v-toolbar-title>Şirket Oranları</v-toolbar-title>
            </v-toolbar>
            <v-card-text style="">              
              <layout style="margin-left: 5%; margin-top: 3%;" :dynamic-elements="dynamicElements[4]" :layout-elements="dynamicElements[4]" :model="model"/>
              <!--<layout style="margin-left: 15px;" :dynamic-elements="dynamicElements[0]" :layout-elements="dynamicElements[0]" :model="model"/> -->
              <layout style="margin-left: 5%;" :dynamic-elements="dynamicElements[3]" :layout-elements="dynamicElements[3]" :model="model"/>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="justify-center" md="4">
          <v-card elevation="24" outlined style="width: 95%; height : 100%" >
            <v-toolbar style="background-color: rgb(41, 64, 95); border-color: rgb(41, 64, 95);" dark flat>
              <v-toolbar-title>Şirket Hesapları</v-toolbar-title>
            </v-toolbar>
              <v-btn dark style="height: 25px !important;background-color:#00a8ff; margin-top: 10%; margin-left: 30%;" @click="kkegAccountCodes()">KKEG Hesapları</v-btn>
              <v-btn dark style="height: 25px !important;background-color:#00a8ff; margin-top: 5%; margin-left: 30%;" @click="rentAccountCodes()">Kira Hesapları</v-btn>
              <v-btn dark style="height: 25px !important;background-color:#00a8ff;  margin-top: 5%; margin-left: 18%;" @click="depreciationAccountCodes()">Amortisman Gider Hesapları</v-btn>
              <v-btn dark style="height: 25px !important;background-color:#00a8ff;  margin-top: 5%; margin-left: 20%;" @click="eBillsAccountCodes()">Varsayılan Hesap Kodları</v-btn>
          </v-card>
        </v-col>
        <v-col class="justify-center" md="4">
          <v-card elevation="24" outlined style="width: 95%; height : 100%" >
            <v-toolbar style="background-color: rgb(41, 64, 95); border-color: rgb(41, 64, 95);" dark flat>
              <v-toolbar-title>Şifre Güncelle</v-toolbar-title>
            </v-toolbar>
            <v-card-text style="">
              <layout style="margin-left: 15px;" :dynamic-elements="dynamicElements[0]" :layout-elements="dynamicElements[0]" :model="model"/>
            </v-card-text>
            <v-card-text style="">
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="justify-center" md="4">
          <v-card elevation="24" outlined style="width: 95%; height : 100%" >
            <v-toolbar style="background-color: rgb(41, 64, 95); border-color: rgb(41, 64, 95);" dark flat>
              <v-toolbar-title>Firma Ekle</v-toolbar-title>
            </v-toolbar>
            <v-card-text style="">
              <layout style="margin-left: 15px;" :dynamic-elements="dynamicElements[7]" :layout-elements="dynamicElements[7]" :model="model"/>
            </v-card-text>
            <v-card-text style="">
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="justify-center" md="4">
          <v-card elevation="24" outlined style="width: 95%; height : 100%">
            <v-toolbar style="background-color: rgb(41, 64, 95); border-color: rgb(41, 64, 95);" dark flat :key="entegratorRenderKey">
              <v-toolbar-title>Entegratör Şifre Girişi</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-title>{{entegratorObj[propertyName].lastPasswordUpdate}}</v-toolbar-title>
            </v-toolbar>
            <v-card-text style="overflow-y: auto; height:250px">
              <v-row>
                <v-col cols="4" style="padding-top : 0px; padding-bottom : 0px">
                  <h5>Özel Entegratör</h5>
                  <v-autocomplete
                    style="width: 150px; margin-left: 2px"
                    label="Özel Entegratörler"
                    :items="specialIntegrator"
                    v-model="propertyName"
                    item-disabled="customDisabled"
                    v-on:change="changeSpecialRoute()"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    solo
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="4" style="margin-top : -10px">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on , attrs}">
                      <v-icon
                      color="error"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      style="position: absolute; right; 0px;width: 500px; margin-top: 3%" 
                    >
                      info
                    </v-icon>
                    </template>
                    <span>Her Ayın Belirlenen Gününde Bir Önceki Ayın Tüm Verilerini Otomatik Almanızı Sağlar</span>
                  </v-tooltip>                  
                </v-col>
              </v-row>

              <!-- <layout :dynamic-elements="dynamicElements[3]" :layout-elements="dynamicElements[3]" :model="model"/> -->
              <v-row>
                <v-col :cols="propertyName == 'accountingDigitalPlanet' ? '4' : '5'" v-if="propertyName == 'accountingDigitalPlanet'|| propertyName =='accountingTeb'">
                  <v-text-field
                    style="padding: 0;0;0;0"
                    prepend-icon="man"
                    v-model="entegratorObj[propertyName].accountNo"
                    label="Üye Numarası"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col :cols="propertyName == 'accountingDigitalPlanet' ? '4' : '6'">
                  <v-text-field
                    style="padding: 0;0;0;0"
                    prepend-icon="mail"
                    v-model="entegratorObj[propertyName].mail"
                    label="Kullanıcı Adı"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col :cols="propertyName == 'accountingDigitalPlanet' ? '4' : '6'">
                  <v-text-field
                    @focus="focusMethod"
                    readonly
                    style="padding: 0;0;0;0"
                    prepend-icon="lock"
                    type="password"
                    v-model=" entegratorObj[propertyName].password"
                    label="Parola"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" v-if="propertyName == 'accountingForiba'">
                <v-text-field
                  @focus="focusMethod"
                  readonly
                  style="padding: 0;0;0;0"
                  prepend-icon="key"
                  v-model=" entegratorObj[propertyName].identifierpk"
                  label="Pk Değeri"
                  required
                  maxLength=100
                ></v-text-field>
              </v-col>
              <v-col cols="6" v-if="propertyName == 'accountingForiba'">
                <v-text-field
                  @focus="focusMethod"
                  readonly
                  style="padding: 0;0;0;0"
                  prepend-icon="key"
                  v-model=" entegratorObj[propertyName].identifiergb"
                  label="GB Değeri"
                  required
                  maxLength=100
                ></v-text-field>
              </v-col>
              </v-row>
              <v-btn color="green" style= "height: 25px !important; margin: 10px; color: white;"  @click="saveSettings('Entegrator')">Kaydet</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="justify-center" md="4" style="width: 95%; height : 100%">
        <v-card elevation="24" outlined :style="dynamicElements[3][0].style">
          <v-toolbar style="background-color: rgb(41, 64, 95); border-color: rgb(41, 64, 95);" dark flat :key="gibRenderKey">
            <v-toolbar-title>Gib Şifre Girişi</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-title>{{gibInfo.lastPasswordUpdate}}</v-toolbar-title>
          </v-toolbar>
          <v-card-text style="overflow-y: auto; height:270px">
            <v-row>
              <v-col cols="4"  style="padding-top : 0px; padding-bottom : 0px;">
                <v-row style="flex-wrap: nowrap;">
                  <v-col cols="4" style="width: fit-content; height: fit-content;">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on , attrs}">
                        <v-icon
                        color="error"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        info
                      </v-icon>
                      </template>
                      <span>Her Ayın Belirlenen Gününde Bir Önceki Ayın Tüm Verilerini Otomatik Almanızı Sağlar</span>
                    </v-tooltip>
                  </v-col>
                </v-row>

              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  style="padding: 0;0;0;0"
                  prepend-icon="mail"
                  v-model="entegratorObj['GIB'].mail"
                  label="Kullanıcı Adı"
                  required
                >
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  @focus="focusMethod"
                  readonly
                  style="padding: 0;0;0;0"
                  prepend-icon="lock"
                  type="password"
                  v-model="entegratorObj['GIB'].password"
                  label="Parola"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn color="green" style= "height: 25px !important; margin-top: 69px; color: white;"  @click="saveSettings('GIB')">Kaydet</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      </v-row>
    </div>
</template>

<script>
import layout from "../../../frontend/components/atoms/container/layout.vue";
import { sendRequest } from "../../../frontend/components/atoms/helper/requestHelper.js";
import * as config from "../../config.json";
import {
  openSnackbar,
  getDivModel,
  getDiv,
  getTable,
} from "../../../frontend/components/atoms/helper/elementHelper";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import {
  showLoader,
  hideLoader,
} from "../../../frontend/components/atoms/helper/elementHelper.js";

export default {
  components: { 'layout' : layout, 'Loader': Loader },
  props: {
    dynamicElements : {
      type: Array,
      default: () => [{}]
    },
    model : {}
  },
  data: () => ({
    settings : null,
    entegratorRenderKey : 0,
    gibRenderKey: 0,
    dialogKkegAccounts: false,
    dialogDepreciationAccounts: false,
    dialogEBillsAccounts: false,
    dialogRentAccounts: false,
    generalAssignmentHeaders : [],
    generalAssignmentDesserts : [],
    generalAssignmentDessertsSales : [],
    generalAssignmentDessertsPurchase : [],
    accountCodes : [],
    addCodeType : null,
    //accountCodes : config.accountCodes,
    // generalAssignmentHeaders : config.generalAssignmentHeaders,
    // generalAssignmentDessertsSales : config.generalAssignmentDessertsForSales,
    // generalAssignmentDessertsPurchase : config.generalAssignmentDessertsForPurchase,
    gibInfo : {
      mail : "",
      password : "",
      controlDay : '',
      lastPasswordUpdate : "",
    },
    defaultAccountCode: {
      "accountPlanVehicleBaseCode" : null,
      "accountPlanVehicleVatCode" : null,
      "accountPlanPayableCode" : null,
      "accountPlanDeductedCode" : null,
      "accountPlanCommunicationVatCode" : null,
      "accountPlanPunishmentAccountCode" : null,
      "accountPlanExportVatCode" : null
    },
    propertyName : "accountingLogo",
    specialIntegrator : [
      { name : "LOGO YAZILIM SANAYİ VE TİCARET ANONİM ŞİRKETİ", id : "accountingLogo"},
      { name : "FIT BİLGİ İŞLEM SİSTEMLERİ SERV. SAN. VE TİC. A.Ş.", id : "accountingForiba"},
      { name : "D.T.P. Bilgi İşlem İletişim Ve Ticaret Anonim  Şirketi", id : "accountingDigitalPlanet"},
      { name : "EDM Bilişim Sistemleri ve Danışmanlık Hizmetleri A.Ş.	", id : "accountingEdmBilisim"},
      { name : "MİKRO Yazılımevi Yazılım Hizmetleri Bilgisayar ve Sanayi Ticaret A.Ş.", id : "accountingMikro"},
      { name : "İZİBİZ YAZILIM", id : "accountingIziBiz"},
      { name : "Vega", id : "accountingVega"},
      { name : "EFİNANS Elektronik Ticaret ve Bilişim Hizmetleri Anonim Şirketi", id : "accountingEfinans"},
      { name : "UYUMSOFT Bilgi Sistemleri ve Teknolojileri Ticaret Anonim Şirketi", id : "accountingUyumsoft", customDisabled: true},
      { name : "Kolay Soft", id : "accountingKolaySoft"},
      { name : "Soft Net", id : "accountingSoftNet"},
      { name : "Teb Bankası", id : "accountingTeb"},
      { name : "Pavo", id : "accountingPavo"},
      { name : "Paraşüt Mikro Group", id : "accountingParasut", customDisabled: true},
      { name : "Vision Plus(İdea Teknoloji Çözümleri)", id : "accountingVisionPlus", customDisabled: true},
      { name : "İŞNET Elektronik Bilgi Üretim Dağıtım Ticaret ve İletişim Hizmetleri A.Ş.", id : "accountingIsnet", customDisabled: true},//sadece logini sorunlu
      { name : "Zirve Bilgi Teknolojileri Medikal Turizm Sanayi Ticaret Limited Şirketi", id : "accountingZirve", customDisabled: true},
      { name : "DİA YAZILIM SANAYİ VE TİCARET A.Ş.", id : "accountingDia", customDisabled: true},
      { name : "TÜRMOB-TESMER EĞİTİM YAYIN VE YAZILIM HİZMETLERİ İKTİSADİ İŞLETMESİ", id : "accountingTurmob", customDisabled: true},
      { name : "UBL TEKNOLOJİ ANONİM ŞİRKETİ", id : "accountingUbl", customDisabled: true},
      { name : "ISIS E DÖNÜŞÜM TEKNOLOJİLERİ SANAYİ TİCARET ANONİM ŞİRKETİ", id : "accountingIsis", customDisabled: true},
    ],
    entegratorObj : {
      GIB : {mail : "", password : "", controlDay : "", lastPasswordUpdate : ""},
      accountingLogo : {mail : "", password : "", controlDay : "", lastPasswordUpdate : ""},
      accountingForiba : {mail : "", password : "", controlDay : "", lastPasswordUpdate : "",identifiergb : "", identifierpk : ""},
      accountingDigitalPlanet : {mail : "", password : "", controlDay : "", accountCode : "", lastPasswordUpdate : ""},
      accountingEdmBilisim : {mail : "", password : "", controlDay : "", lastPasswordUpdate : ""},
      accountingMikro : {mail : "", password : "", controlDay : "", lastPasswordUpdate : ""},
      accountingVega : {mail : "", password : "", controlDay : "", lastPasswordUpdate : ""},
      accountingVisionPlus : {mail : "", password : "", controlDay : "", lastPasswordUpdate : ""},
      accountingIziBiz : {mail : "", password : "", controlDay : "", lastPasswordUpdate : ""},
      accountingIsnet : {mail : "", password : "", controlDay : "", lastPasswordUpdate : ""},
      accountingEfinans : {mail : "", password : "", controlDay : "", lastPasswordUpdate : ""},
      accountingZirve : {mail : "", password : "", controlDay : "", lastPasswordUpdate : ""},
      accountingDia : {mail : "", password : "", controlDay : "", lastPasswordUpdate : ""},
      accountingTurmob : {mail : "", password : "", controlDay : "", lastPasswordUpdate : ""},
      accountingUbl : {mail : "", password : "", controlDay : "", lastPasswordUpdate : ""},
      accountingIsis : {mail : "", password : "", controlDay : "", lastPasswordUpdate : ""},
      accountingParasut : {mail : "", password : "", controlDay : "", lastPasswordUpdate : ""},
      accountingEArsiv : {mail : "", password : "", controlDay : "", lastPasswordUpdate : ""},
      accountingLuca : {mail : "", password : "", controlDay : "", lastPasswordUpdate : ""},
      accountingKolaySoft : {mail : "", password : "", controlDay : "", lastPasswordUpdate : ""},
      accountingSoftNet : {mail : "", password : "", controlDay : "", lastPasswordUpdate : ""},
      accountingTeb : {mail : "", password : "", controlDay : "", accountCode : "", lastPasswordUpdate : ""},
      // accountingUyumsoft : {mail : "", password : "", controlDay : "", accountCode : "", lastPasswordUpdate : ""},
      accountingPavo : {mail : "", password : "", controlDay : "", accountCode : "", lastPasswordUpdate : ""},
    },
  }),
  created() {
    this.getCompanySettings();
    this.getDepreciationSettings();
    this.getKkegSettings();
    this.getRentSettings();
    this.autocompleteAccountsFunction();
    this.getDefaultAccountCodesSettings();
  },
  computed: {
    computedIsDisabled() {
        return (type) => {
          return this.isDisabled[type];
      };
    },
  },
  methods: {
    saveSettings(type){
      debugger
      let that = this
      let saveFlag = false
      showLoader("Lütfen Bekleyiniz. Verileriniz kayıt ediliyor...")
      let settingsUpdateObject = {}, errorText = "Kullanıcı Adı Ve Şifre Boş Bırakılamaz."
      if(type == "Entegrator"){
        if (that.propertyName != '' && that.propertyName != undefined) {
          if(that.entegratorObj[that.propertyName].mail != "" && that.entegratorObj[that.propertyName].password != ""){
            that.entegratorObj[that.propertyName].lastPasswordUpdate = new Date().toLocaleString("tr-TR")
            settingsUpdateObject = that.entegratorObj[that.propertyName]
            saveFlag = true
          }
        }else{
          errorText = 'Entegratör Seçimi Yapınız'
        }
      } else if (type == 'GIB') {
        if(that.entegratorObj["GIB"].mail != "" && that.entegratorObj["GIB"].password != ""){
          debugger
          if(that.entegratorObj["GIB"].password.length <= 6){
            that.entegratorObj["GIB"].lastPasswordUpdate = new Date().toLocaleString("tr-TR")
            settingsUpdateObject = that.entegratorObj["GIB"]
            saveFlag = true
          }else{
            hideLoader();
            errorText = "GİB şifresi en fazla 6 karakter olabilir."
          }
        }
      }
      if(saveFlag == true){
        sendRequest(
        Enum.requestType.POST,
        "updateCompanySettings",
        "",
        {
          choice : "integratorUserAccount",
          updatedArray: settingsUpdateObject,
          type : type,
          propertyName : that.propertyName,
          tinNumber : this.settings.tinNumber

        },
        function (response) {
          that.entegratorRenderKey++
          openSnackbar("success", "Verileriniz başarılı bir şekilde güncellendi.", 2000);
          hideLoader();
        },
        function (error) {
          console.log(error)
          if (error?.response?.data?.error[0]?.detail?.message) {
            openSnackbar("error",error?.response?.data?.error[0]?.detail?.message, 2000);
          }else{
            openSnackbar("error","Şifreniz Yanlış.", 2000);
          }
          if(type == "Entegrator"){
            that.entegratorObj[that.propertyName].controlDay = ""
          }
          hideLoader();
        }
      );
      }
      else{
        hideLoader()
        openSnackbar("error",errorText, 2000); 
      }
    },
    changeSpecialRoute(){
        let that = this
        if (that.entegratorObj[that.propertyName] == undefined) {
          that.entegratorObj[that.propertyName] = that.entegratorObj[that.propertyName]
        }
      },
    focusMethod : function (a) {
        a.srcElement.removeAttribute('readonly')
    },
    setTooltipSpanFunc (item, value) {
      if (item == undefined) {
        return ''
      }else{
        return item['accountName']
      }
    },
    getCompanySettings(){
        showLoader("Lütfen Bekleyiniz...")
        let that = this
        sendRequest(Enum.requestType.POST,"getCompanySettings",null,{type: "Settings"},
          function(response) {
            debugger
            that.settings = response.data.data
            let integratorUserAccount = response.data.data.settings.didero.integratorUserAccount
            if (integratorUserAccount != undefined) {
              debugger
              let keyEntegrator = Object.keys(integratorUserAccount.Entegrator) 
              if (integratorUserAccount.selectedIntegrator != "" && integratorUserAccount.selectedIntegrator != undefined) {
                that.propertyName = integratorUserAccount.selectedIntegrator
                for (let i = 0; i < keyEntegrator.length; i++) {
                  that.entegratorObj[keyEntegrator[i]] = integratorUserAccount.Entegrator[keyEntegrator[i]]
                }
              }
              that.entegratorObj["GIB"]= integratorUserAccount.GIB.accountingEArsiv
            }
            hideLoader();
          },
          function(error) {
            console.log(error)
            openSnackbar("error", error.response.data.error[0].detail.message, 2000);
            hideLoader();
          }
        );
    },
    getDepreciationSettings() {
      let that = this;
      sendRequest(Enum.requestType.POST,"querySettings","",{},
        function(response){
          if (response.data.data.data[0].settings.didero.depreciationAccountCodes != undefined) {
            let objKeys = Object.keys(response.data.data.data[0].settings.didero.depreciationAccountCodes)
            let array = []
            array.push(response.data.data.data[0].settings.didero.depreciationAccountCodes)
            for (let i = 0; i < array.length; i++) {
              for (let j = 0; j < objKeys.length; j++) {
                                  if (!array[i][objKeys[j]].includes('-')) {
                    let newArray = []
                    for (let z = 0; z < array[i][objKeys[j]].length; z++) {
                      newArray.push({name: array[i][objKeys[j]][z].accountCode + "_" + array[i][objKeys[j]][z].accountName})
                    }
                    that.model["items" + objKeys[j]] = newArray
                  } else {
                    if (array[i][objKeys[j]].length == 3 || array[i][objKeys[j]].includes('-')) {
                      that.model["items" + objKeys[j]] = ""
                    } else {
                      that.model["items" + objKeys[j]] = response.data.data.data[0].settings.didero.depreciationAccountCodes[objKeys[j]].accountCode + "_" + response.data.data.data[0].settings.didero.depreciationAccountCodes[objKeys[j]].accountName
                    }
                  }
                }
                            }
          }
        },
        function (error) {
          openSnackbar(
            "error",
            error.response.data.error[0].detail.message
          );
          hideLoader();
        }       
      )
    },
    getRentSettings() {
      let that = this;
      sendRequest(Enum.requestType.POST,"querySettings","",{},
        function(response){
          if (response.data.data.data[0].settings.didero.rentAccountCodes != undefined) {
            let objKeys = Object.keys(response.data.data.data[0].settings.didero.rentAccountCodes)
            let array = response.data.data.data[0].settings.didero.rentAccountCodes[objKeys]
            let newArray = []
            for (let i = 0; i < array.length; i++) {
              newArray.push({name: array[i].accountCode + "_" + array[i].accountName})
            }
            that.model['rentCode'] = newArray
          }
        },
        function (error) {
          openSnackbar(
            "error",
            error.response.data.error[0].detail.message
          );
          hideLoader();
        }       
      )
    },
    getDefaultAccountCodesSettings() {
      let that = this;
      sendRequest(Enum.requestType.POST,"querySettings","",{},
        function(response){
          if (response.data.data.data[0].settings.didero.defaultAccountCode != undefined) {
            that.generalAssignmentHeaders = config.generalAssignmentHeaders
            that.generalAssignmentDessertsSales = response.data.data.data[0].settings.didero.defaultAccountCode.sales;
            that.generalAssignmentDessertsPurchase = response.data.data.data[0].settings.didero.defaultAccountCode.purchase;
            that.defaultAccountCode = response.data.data.data[0].settings.didero.defaultAccountCode.otherDefaultAccountCode;
          } else {
            that.generalAssignmentHeaders = config.generalAssignmentHeaders
            that.generalAssignmentDessertsSales = config.generalAssignmentDessertsForSales
            that.generalAssignmentDessertsPurchase = config.generalAssignmentDessertsForPurchase
          }
        },
        function (error) {
          openSnackbar(
            "error",
            error.response.data.error[0].detail.message
          );
          hideLoader();
        }       
      )
    },
    getKkegSettings() {
      let that = this;
      sendRequest(Enum.requestType.POST,"querySettings","",{},
        function(response){
          let kkegArray = [];
          if(response.data.data.data[0].settings.didero.kkegAccountCodes != undefined){
            let objKeys = Object.keys(response.data.data.data[0].settings.didero.kkegAccountCodes)
            for (let i = 0; i < objKeys.length; i++) {
              kkegArray.push({
                description: response.data.data.data[0].settings.didero.kkegAccountCodes[objKeys[i]].description,
                accountCode: response.data.data.data[0].settings.didero.kkegAccountCodes[objKeys[i]].accountCode,
                accountName: response.data.data.data[0].settings.didero.kkegAccountCodes[objKeys[i]].accountName
              })
            }
            for (let i = 0; i < kkegArray.length; i++) {
              that.model[kkegArray[i].description + "1"] = kkegArray[i].accountCode + '_' + kkegArray[i].accountName
            }
          }
          
        },
        function (error) {
          openSnackbar(
            "error",
            error.response.data.error[0].detail.message
          );
          hideLoader();
        }       
      )
    },
    kkegAccountCodes() {
      this.dialogKkegAccounts = true
      this.getKkegSettings()
    },
    rentAccountCodes() {
      this.dialogRentAccounts = true
      this.getRentSettings()
    },
    depreciationAccountCodes() {
      this.dialogDepreciationAccounts = true
      this.getDepreciationSettings()
    },
    eBillsAccountCodes() {
      this.dialogEBillsAccounts = true
      this.getDefaultAccountCodesSettings()
    },
    customFilterForAccount (item, queryText, itemText) {
      const textOne = item.accountCode.toLowerCase()
      const textTwo = item.accountName.toLowerCase()
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1
    },
    autocompleteAccountsFunction() {
      let that = this;
      sendRequest(Enum.requestType.POST,"defaultAccountCodes","",{cookieData: window.$cookies.get("selectedItem")},
        function(response){
          if (response.data.data.data.length < 1) {
            that.accountCodes = config.accountCodes
          }
          that.accountCodes = response.data.data.data
        },
        function (error) {
          openSnackbar(
            "error",
            error.response.data.error[0].detail.message
          );
        }       
      )
    },
    saveDefaultAccountCode () {
        let that = this
        showLoader("Lütfen Bekleyiniz. Verileriniz kayıt ediliyor...")
        that.defaultAccountCode.notFoundAccountCheckbox
        let settingsDefaultObject = {
          "sales" : that.generalAssignmentDessertsSales,
          "purchase" : that.generalAssignmentDessertsPurchase,
          "otherDefaultAccountCode" : that.defaultAccountCode
        }
        sendRequest(
          Enum.requestType.POST,
          "addSettings",
          "",
          {
            choice : "defaultAccountCode",
            updatedArray: settingsDefaultObject,
            type : "defaultAccountCode",
          },
          function (response) {
            openSnackbar("success","Verileriniz başarılı bir şekilde güncellendi.", 2000);
            that.dialogEBillsAccounts = false
            hideLoader();
          },
          function (error) {
            openSnackbar("error","Yeni kod eklenirken hata oluştu.", 2000);
            hideLoader();
            console.log(error)
          }
        );
      },
  }
}
</script>

<style scoped>
  .dialog-div {

    margin-left: 20px;
  }
</style>