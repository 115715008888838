<template>
  <v-app>
    <div class="header-div">
      <h3 class="header-h3">SİGORTA POLİÇESİ</h3>
    </div>
      <table-component :dynamic-element="tableElements" :model="model" />
      <Loader></Loader>
      <snackbar-component />
  </v-app>
</template>
<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";

import {
getTable,
updateItem,
saveItem,
deleteItem,
openSnackbar,
openTablePage,
showLoader, 
hideLoader 
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import {axiosUploadTypeArrayBuffer} from "../../helper/uploadHelperForData"
export default {
  components: {
      "table-component": tableComponent,
      "upload-component": uploadComponent,
      Loader: Loader,
      "snackbar-component": snackbarComponent,
  },

  data() {
      return {
        tableElements: {},
        model: {},
        renderKey: 0,
      };
  },
  created() {
      this.loadData();
  },
  watch: {},
  methods: {
  loadData() {
    this.tableElements = {
      sortBy: [],
      sortDesc: false,
      renderKey: 0,
      type: "table",
      tableHeight : 530,
      name: "insuranceTable",
      class: "",
      infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
      excelDownloadBtnText: "İndir",
      deleteDialogVisible : true,
      deleteBtnText: "Sil",
      hidePagination: true,
      saveBtnText : 'Yeni Kayıt',
      saveUrl : "insuranceTableInsert",
      //selectedRows: [],
      //filteredColumn: {},
      //filteredColumns: true,
      showDefaultDeleteButon : false,
      selectionUrl: "queryinsurancetable",
      showDefaultDeleteButon : true,
      deleteUrl: "deleteTable",
      deleteBtnSettings : {
          color: "error",
          style:"height: 25px !important; ",
        },
      toolbars: [
        {
          type: Enum.layoutType.div,
          name: "toolbarsForm",
          inputElements: [
            // [
            //   {
            //     id: "delete",
            //     type: "v-btn",
            //     variant: "btn",
            //     name: "delete",
            //     inputText: "Sil",
            //     divStyle: "",
            //     style: "height: 36px !important;",
            //     btnTypeText: false,
            //     color: "error",
            //     prependIcon: "",
            //     classTag: "",
            //     onClick : function(thisData,model,dynamicElements) {
            //       let tableObject = getTable("insuranceTable")
            //       console.log(thisData,model,dynamicElements)
            //       if (tableObject.selectedRows.length > 0) {
            //         let options = {
            //           showDialog : true
            //         }
            //         deleteItem("insuranceTable",tableObject.selectedRows,options,model)
            //       }else{
            //         openSnackbar('error', 'Lütfen silmek istediğiniz satırı seçiniz')
            //       }
            //     }
            //   },
            // ],
          ],
        }
      ],
      headers: [
        { text: "Poliçe No", value: "insuranceNo", width: "100", divider: true,},
        { text: "Tutar", value: "amount", width:"100", dataType: "currency" , divider: true,},
        { text: "Başlangıç Tarihi", value: "startDate", width: "100", dataType: "date", divider: true,},
        { text: "Bitiş Tarihi", value: "endDate", width: "100",  dataType: "date", divider: true,},
        { text: "Kur", value: "rate", width: "100",  dataType: "currency" , divider: true,},
        { text: "Araç Kısıtlaması", value: "vehicleRestriction" , width: "100", switchValue: {"true" : "Var", "false" : "Yok"}, divider: true,},
      ],
      columns: {
        insuranceNo: {
          type: "v-text-field",
          label: "Poliçe No",
          editable: false,
          creatable: true,
          inlineEditable:false,
        },
        amount: {
          type: "v-text-field",
          label: "Tutar",
          editable: false,
          creatable: true,
          inlineEditable:false,
        },
        startDate: {
          label: "Başlangıç Tarihi",
          type : "v-date-picker",
          readonly: true,
          editable: false,
          creatable: true,
          inlineEditable:false,
        },
        endDate: {
          label: "Bitiş Tarihi",
          type : "v-date-picker",
          readonly: true,
          editable: false,
          creatable: true,
          inlineEditable:false,
        },
        rate: {
          type: "v-text-field",
          label: "Kur",
          editable: false,
          creatable: true,
          inlineEditable:false,
        },
        vehicleRestriction: {
          type: "v-checkbox",
          label: "Araç Kısıtlaması",
          editable: false,
          creatable: true,
          inlineEditable:false,
        },
      }
    };
  },
  copyItem(tableName,selectedItem,options,model,onBeforeSave,onAfterSave,errorFunction) {
    if (!Array.isArray(selectedItem)) {
        selectedItem = [selectedItem];
    }
    
    let dynamicElements = getTable(tableName);
    if (options != undefined && options.dialogText != undefined) {
        dynamicElements.deleteDialogText = options.dialogText;
    }
    
    let title = "Yeni Kayıt Eklemek İstediğinize Emin Misiniz?";
    
    dynamicElements.forceRender();
    model.close = "Hayır",
    model.insertItem = "Evet";
    dynamicElements.dialogVisible = true;
    dynamicElements.dialogInfo = {
        name:"insertDialog",
        maxWidth : "1100",
        dialogVisible : "dialogVisible",
        title : title,
        layout : [
            {
              type : Enum.layoutType.div,
              name : "loginForm",
    
              inputElements : [
              ]
            }
        ],
        actions : [
          {
            id : "close",
            type : "v-btn",
            variant : "btn",
            name : "close",
            inputText : "Kapat",
            divStyle:"",
            btnTypeText: true,
            color:"green darken-1",
            classTag : "",
            onClick : function(thisData,model,dialogInfo) {
              var tableObject = getTable(tableName);
              tableObject.dialogVisible = false;
              tableObject.forceRender();
              console.log(thisData,model,dialogInfo);
            }
          },
          {
            id : "insertItem",
            type : "v-btn",
            variant : "btn",
            name : "insertItem",
            inputText : "Ekle",
            divStyle:"",
            btnTypeText: true,
            color:"green darken-1",
            classTag : "",
            onClick : function(thisData,model,dialogInfo) {
              var tableObject = getTable(tableName);
              console.log(thisData,model,dialogInfo);
              tableObject.dialogVisible = false;
              tableObject.forceRender();
              let creatableObjects = {}; 
              sendRequest(Enum.requestType.POST, dynamicElements.saveUrl,"",{selectedItem,creatableObjects},function (res) {
                  openTablePage(tableName);
                  openSnackbar("success", "Eklemeniz Başarılı Bir Şekilde Gerçekleşti");
              },function (error) {
                  if (errorFunction != undefined) {
                      errorFunction(error,selectedItem,creatableObjects,model,tableObject);
                  } else if(tableObject.clientEvents.onSaveError != undefined){
                      tableObject.clientEvents.onSaveError(error,selectedItem,creatableObjects,model,tableObject);
                  }
              });
            }
          },
        ]
    };
    },
},
}

  

</script>

<style>
.header-div {
text-align: center;
}
</style>