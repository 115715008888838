<template>
  <v-app>
    <nav-bar v-if="$route.fullPath != '/landing' && $route.fullPath != '/loginPage' && $route.fullPath != '/forgotten' && !$route.fullPath.includes('createpass')"/>
    <router-view :key="haber" />
  </v-app>
</template>

<script>
import NavBar from '../NavBar.vue'
import * as userRequest from "./request/user";
export default {
  components: {
    'nav-bar': NavBar
  },
  name: "App",
  data: () => ({
    componentIndex :{},
    componentClass : "",
    componentClasses : [
      {reset : true, className : ".menuable__content__active button, .menuable__content__active input, .menuable__content__active .v-select__selections"} ,
      {reset : false, className : "td.text-start .v-small-dialog__activator__content"}
    ],
    haber : ''
  }),
  methods: {
    checkOnline(){
      if (this.$router.currentRoute.fullPath != "/landing" && this.$router.currentRoute.fullPath != "/loginPage" && this.$router.currentRoute.fullPath != "/forgotten" && !this.$router.currentRoute.fullPath.includes("createpass")) {
        userRequest.checkOnline().catch((err) => console.log(err));
      }
    },
    checkCloseBrowser: function () {
      //Tarayıcı kapatıp çıktıysa logout yapmak için
      userRequest.checkCompanyFlag()
        .then((response) => {
          if (response.data.data.value == false) {
            //this.logOut();
            this.$store.dispatch("logOut");
            location.reload();
            //// sadece kullanıcı bilgileri silinecek.
          }
        });
    },
  },
  //#region TODO
    //table objeleri router olaylarında sıfırlanmıyor
  //#endregion
  created(){
  },
  watch: { 
    '$route': { deep: true, handler: function (refreshPage) 
      { 
        this.$store.state.globalDialog = undefined;
        this.$store.state.globalDialogInitialized = false;
        
        this.$store.state.snackbarInitialized = false;
      },
    },
    '$store.state.selectedItem' : { deep: true, handler: function (refreshPage) 
      { 
        this.haber = this.$store.state.selectedItem
      }
    } 
  },
  mounted()  {
    setInterval(() => {
      this.checkOnline();
    },15000)
    let that = this
    for (let i = 0; i < that.componentClasses.length; i++) {
      that.componentIndex[that.componentClasses[i].className] = -1
    }
    window.addEventListener('keyup', () => {
      let currentComponentClass = that.componentClasses[0].className
        let components = document.querySelectorAll(currentComponentClass)
        if(components.length == 0 ){
          currentComponentClass = that.componentClasses[1].className
          components = document.querySelectorAll(currentComponentClass)
        }
        if(that.componentClass != currentComponentClass){
          that.componentClass = currentComponentClass
          for (let j = 0; j < that.componentClasses.length; j++) {
            if (that.componentClasses[j].className != currentComponentClass && that.componentClasses[j].reset) {
              that.componentIndex[that.componentClasses[j].className] = -1
            }            
          }
        }
        for (let i = 0; i < that.componentClasses.length; i++) {
          let clearComponents = document.querySelectorAll(that.componentClasses[i].className)
          for (let j = 0; j < clearComponents.length; j++) {
            if (that.componentIndex[that.componentClasses[i].className] != j) {
              clearComponents[j].style.backgroundColor = ""

            }
            else{
              clearComponents[j].style.backgroundColor = "yellow"
            }
          }
        }
    })
    window.addEventListener('keydown',
      (a,b,c) => {
        //let preventComponents = document.querySelectorAll(".menuable__content__active , .ekranda classa sahip")
        // let preventComponents = document.querySelectorAll(".menuable__content__active")
        // if(preventComponents.length > 0 ){
        //   return 
        // }
      
        //let components = document.querySelectorAll("td.text-start .v-small-dialog__activator__content , .sıralamada seçilmesini istediğimiz")
        let currentComponentClass = that.componentClasses[0].className
        let components = document.querySelectorAll(currentComponentClass)
        if(components.length == 0 ){
          currentComponentClass = that.componentClasses[1].className
          components = document.querySelectorAll(currentComponentClass)
        }
        if(that.componentClass != currentComponentClass){
          that.componentClass = currentComponentClass
          for (let j = 0; j < that.componentClasses.length; j++) {
            if (that.componentClasses[j].className != currentComponentClass && that.componentClasses[j].reset) {
              that.componentIndex[that.componentClasses[j].className] = -1
            }            
          }
        }
        
        // if (classChange && that.componentIndex[currentComponentClass] >= 0 && that.componentIndex[currentComponentClass] < components.length) {
        //   components[that.componentIndex[currentComponentClass]].style.backgroundColor = "yellow"
        // }
        switch (a.key) {
          case  "Tab" :
            if (a.shiftKey == true ) {
              if (that.componentIndex[currentComponentClass] > 0) {
                that.componentIndex[currentComponentClass]--
                //components[that.componentIndex[currentComponentClass]].focus()
                
              }
            }
            else{
              if (that.componentIndex[currentComponentClass] < components.length -1) {
                that.componentIndex[currentComponentClass]++
                //components[that.componentIndex[currentComponentClass]].focus()
                
              }
            }
            a.preventDefault()    
            break;
          case "Enter" :
            if (components.length > 0) {
              components[that.componentIndex[currentComponentClass]].click()
              //components[that.componentIndex].click()
              setTimeout(() => {
                document.querySelector(".v-list.v-select-list").focus()
              }, 1000);
            }
            a.preventDefault()    
            break;
        
          default:
            break;
        }
	},true
    )
  }
  
};
</script>

<style>
@media (max-width: 600px) and (min-width: 0px){
  html{
    zoom:50% !important
  }
}
@media (max-width: 1366px) and (min-width: 601px){
  html{
    zoom:92% !important
  }
}
html{
    zoom:100%
}
::-webkit-scrollbar{
  background: white;
  width: 10px;
  height: 10px
  
}
::-webkit-scrollbar-thumb {
  background:gray ;
  border-radius: 20px;
}
</style>