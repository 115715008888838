<template>
    <v-autocomplete
        :solo="autocompleteObj.solo"
        :dense="autocompleteObj.dense"
        :key="renderKey"
        :name="autocompleteObj.name"
        :label="autocompleteObj.label"
        v-model="model[autocompleteObj.name]"
        :search-input.sync="search"
        :items="items"
        filled
        chips
        :clearable="autocompleteObj.clearable"
        :color="autocompleteObj.color"
        item-text="name"
        item-value="name"
        :multiple="autocompleteObj.multiple"
        :rules="(autocompleteObj.rules != undefined) ?  autocompleteObj.rules : undefined"
    >
      <!-- v-chip close attribute deleted because the remove(data.item) was not working properly -->
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          :color="autocompleteObj.color"
          :text-color="autocompleteObj.textColor"
          @click="data.select"
          @click:close="remove(data.item)"
        >
          {{ data.item.name }}
        </v-chip>
      </template>
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
</template>
<script>
import {sendRequest} from "../helper/requestHelper.js"

export default {
    data: () => ({
        search: null,
        items: [],
        isUpdating : true,
        renderKey : 0,
        searchEventControl : false,
    }),
    props: {
        autocompleteObj : {
          type: Object,
          default: () => {}
        },
        model : {}
    },
    watch: {
      search (val) {
        if (val && this.searchEventControl) {
          if (val.length >= this.autocompleteObj.minValue) {
            let that = this;
            let searchObj = {
              value : val
            }
            sendRequest("POST",this.autocompleteObj.url,"",{searchObj,crudRequestObj : this.autocompleteObj.crudRequestObj, cookieData: window.$cookies.get("selectedItem")},function (res) {
              that.items = res.data.data.value
            }, function (err) {
              openSnackbar("error","Hesap Kodları Getirilirken Hata Oluştu")
            })
          }
        }
      },
    },

    created() {
      this.modelControl ();
    },

    methods: {
        modelControl() {
          if (!Object.keys(this.autocompleteObj).includes('minValue')) {
            this.autocompleteObj.minValue = 3
          }
          if (this.model[this.autocompleteObj.name] == undefined) {
            this.model[this.autocompleteObj.name] = []
          }
          if (Object.keys(this.autocompleteObj).includes('items')) {
              this.searchEventControl = false;
              if (Array.isArray(this.autocompleteObj.items)) {
                  this.items = this.autocompleteObj.items;
              }
              else {
                this.searchEventControl = false;
              }
          }
          else if (Object.keys(this.autocompleteObj).includes('url')) {
            this.searchEventControl = true;
            if (this.model[this.autocompleteObj.name] != undefined) {
              if (Array.isArray(this.model[this.autocompleteObj.name])) {
                this.items = this.model[this.autocompleteObj.name]
              } else {
                this.items.push({name:this.model[this.autocompleteObj.name]});
              }
            }
          }
          else {
              this.searchEventControl = false;
          }
        },
        remove (item) {
          const index = this.model[this.autocompleteObj.name].indexOf(item.name)
          if (index >= 0) this.model[this.autocompleteObj.name].splice(index, 1)
        },
    },
}
</script>