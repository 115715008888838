<template>
  <div class="mainContainer">
    <div class="toolBarClass">
      <div @click="showHTML(model,componentElements)" class="buttonClass" v-if="!model.editShowModel">
        <div class="iconBoxClass">
          <v-icon color="#203959" dense class="iconClass">mdi-xml</v-icon>
        </div>
        <div class="iconTextClass">HTML'i Göster</div>
      </div>
      <!-- <div @click="model.ticketModel = true" class="buttonClass">
        <div class="iconBoxClass">
          <v-icon color="#203959" dense class="iconClass">mdi-comment-alert</v-icon>
        </div>
        <div class="iconTextClass">Destek</div>
      </div> -->
      <div class="buttonClass" @click="downloadOutput">
        <div class="iconBoxClass">
          <v-icon color="#203959" dense class="iconClass">mdi-download</v-icon>
        </div>
        <div class="iconTextClass">Faturayı İndir</div>
      </div>
      <div @click="updateScaleFactor" class="buttonClass">
        <div class="iconBoxClass">
          <v-icon color="#203959" dense class="iconClass">mdi-arrow-expand-all</v-icon>
        </div>
        <div class="iconTextClass">Oranla</div>
      </div>
      <v-slider v-model="scaleFactor" style="max-width: 100%;" min="0.4" max="1.5" step="0.001" dense
        color="rgb(33,150,243)" hide-details="true" track-fill-color="rgb(33,150,243)" track-color="rgb(41,64,95)"
        thumb-color="rgb(33,150,243)"></v-slider>
    </div>
    <div class="outerOutputContainer" ref="outerOutputContainer" @mousedown="startDrag" @mousemove="drag"
      @mouseup="endDrag" @mouseleave="endDrag" :style="componentElements.props.viewerElements.style">
      <div id="output" :class="userSelectOption ? 'outputContainerSelectOption' : 'outputContainer'" :style="{
        transform: 'scale(' + scaleFactor + ')',
      }" ref="output"></div>
    </div>
    <div class="originalValuesCont" v-if="showMiniTable && componentElements.showInfo" id="originalValuesCont"  :style="{ top: topPosition }">
      <div class="tableBody">
              <div class="miniTableTitle"> Dimroda Hesaplanan Değerler <div class="miniTableCloseIcon" @click="infoIconClickFunc">x</div></div>
              <div class="tableRow">
                <div  class="bottomTableHeaderRowCell" >{{ componentElements.props.bottomTableHeaders.header1 }}</div>
                <div  class="bottomTableRowCell" >{{ model.matrahTotal }}</div> 
              </div>
              <div class="tableRow"  v-if="model.withholdingTaxTotal != null">
                <div class="bottomTableHeaderRowCell">{{ componentElements.props.bottomTableHeaders.header2 }}</div>
                <div class="bottomTableRowCell">{{ model.withholdingTaxTotal }}</div>
              </div>
              <div class="tableRow">
                  <!-- yemek kartı z raporunda buraya denk geliyor -->
                <div class="bottomTableHeaderRowCell">{{ componentElements.props.bottomTableHeaders.header3 }}</div>
                <div class="bottomTableRowCell">{{ model.vatTotal }}</div>
              </div>
              <div class="tableRow" v-if="model.specialCommunicationTaxAmount != null">
                <div class="bottomTableHeaderRowCell">{{ componentElements.props.bottomTableHeaders.header6 }}</div>
                <div class="bottomTableRowCell">{{ model.specialCommunicationTaxAmount }}</div>
              </div>
              <div class="tableRow">
                  <!-- diğer z raporunda buraya denk geliyor -->
                <div class="bottomTableHeaderRowCell">{{ componentElements.props.bottomTableHeaders.header4 }}</div>
                <div class="bottomTableRowCell">{{ model.vatIncludingTotal }}</div>
              </div>
              <div class="tableRow">
                  <!-- kredi kartı z raporunda buraya denk geliyor -->
                <div class="bottomTableHeaderRowCell">{{ componentElements.props.bottomTableHeaders.header5 }}</div>
                <div class="bottomTableRowCell">{{ model.total }}</div>
              </div>
            </div>
            <div class="tableBody" :key="bottomTableRenderKey"  v-if="model.currencyType != 'TL' && model.currencyType != 'TRY'" >
              <div class="miniTableTitle"> Dimroda Hesaplanan Değerler <div class="miniTableCloseIcon" @click="infoIconClickFunc">x</div></div>
              <div class="tableRow">
                <div  class="bottomTableHeaderRowCell">{{ componentElements.props.bottomTableHeaders.header1 }}</div>
                <div class="bottomTableRowCell">{{ model.matrahTotalWithRate }}</div> 
              </div>
              <div class="tableRow"  v-if="model.withholdingTaxTotal != null">
                <div  class="bottomTableHeaderRowCell">{{ componentElements.props.bottomTableHeaders.header2 }}</div>
                <div class="bottomTableRowCell">{{ model.withholdingTaxTotal }}</div>
              </div>
              <div class="tableRow">
                <div  class="bottomTableHeaderRowCell">{{ componentElements.props.bottomTableHeaders.header3 }}</div>
                <div class="bottomTableRowCell">{{ model.vatTotalWithRate }}</div>
              </div>
              <div class="tableRow">
                <div  class="bottomTableHeaderRowCell">{{ componentElements.props.bottomTableHeaders.header4 }}</div>
                <div class="bottomTableRowCell">{{ model.vatIncludingTotalWithRate }}</div>
              </div>
              <div class="tableRow">
                <div  class="bottomTableHeaderRowCell">{{ componentElements.props.bottomTableHeaders.header5 }}</div>
                <div class="bottomTableRowCell">{{ model.totalWithRate }}</div>
              </div>
            </div>
    </div>
    <button class="infoIconClass" @click="infoIconClickFunc" v-if="componentElements.showInfo"><v-icon color="white" >mdi-information-variant</v-icon></button>
  </div>
</template>

<script>
import { openSnackbar, showLoader, hideLoader } from "../../../frontend/components/atoms/helper/elementHelper.js";
import { sendRequest } from "../../../frontend/components/atoms/helper/requestHelper";
import Enum from "../../../frontend/components/Enum.json";
export default {
  name: "newXmlOutputViewer",
  data() {
    return {
      scaleFactor: 0,
      isDragging: false,
      lastX: 0,
      lastY: 0,
      userSelectOption: true,
      initialScaleFactor: 0,
      showMiniTable:true,
      topPosition:""
    };
  },
  props: {
    componentElements: {
      type: Object,
      default: () => { },
    },
    model: {
      type: Object,
      default: () => { },
    },
  },
  created() {
  },

  watch: {
    scaleFactor(val) {
      if (val <= this.initialScaleFactor + 0.001) {
        this.userSelectOption = true
      } else {
        this.userSelectOption = false
      }
    }
  },
  mounted() {
    // XML ve XSLT nesnelerini oluşturma
    var parser = new DOMParser();
    var xmlDoc = parser.parseFromString(this.model.xmlString, "text/xml");
    var xsltDoc = parser.parseFromString(this.model.xsltString, "text/xml");

    // XSLTProcessor nesnesini oluşturma
    var xsltProcessor = new XSLTProcessor();
    xsltProcessor.importStylesheet(xsltDoc);

    // Dönüşümü gerçekleştirme
    var resultDocument = xsltProcessor.transformToDocument(xmlDoc);

    // Dönüşüm sonucunu HTML içine yerleştirme
    var resultString = new XMLSerializer().serializeToString(resultDocument);
    document.getElementById("output").innerHTML = resultString;

    // var parser = new DOMParser();
    // var xmlDoc = parser.parseFromString(this.xmlString, "text/xml");
    // var xsltDoc = parser.parseFromString(this.xsltString, "text/xml");

    // // XSLTProcessor nesnesini oluşturma
    // var xsltProcessor = new XSLTProcessor();
    // xsltProcessor.importStylesheet(xsltDoc);

    // // Dönüşümü gerçekleştirme
    // var resultFragment = xsltProcessor.transformToFragment(xmlDoc, document);

    // // Dönüşüm sonucunu bir belirli bir DOM öğesine ekleyin
    // var outputElement = document.getElementById("output");
    // outputElement.innerHTML = ''; // Önce içeriği temizle
    // outputElement.appendChild(resultFragment); // Dönüşüm sonucunu ekleyin

    this.$nextTick(() => {
      this.updateScaleFactor();
      this.bottomTableTopPositionFix()
    });
  },

  methods: {
    // handleWheel(event) {
    //   // Mouse tekerleği olayını al
    //   const wheelDelta = event.deltaY;

    //   // Büyütme faktörünü ayarla
    //   if (wheelDelta < 0) {
    //     // Tekerlek yukarı doğru dönerse büyütme faktörünü artır
    //     this.scaleFactor += wheelDelta * -0.0006;
    //   } else {
    //     // Tekerlek aşağı doğru dönerse küçültme faktörünü artır
    //     this.scaleFactor -= wheelDelta * 0.0006;
    //   }

    //   // Büyütme faktörünü sınırla (örneğin, en fazla 2 kat büyütebilirsiniz)
    //   this.scaleFactor = Math.min(Math.max(this.scaleFactor, 0.4), 1.5);
    // },
    updateScaleFactor() {
      // outerOutputContainer boyutlarına göre scaleFactor'ü hesaplayın
      const outerContainer = this.$refs.outerOutputContainer;
      const containerWidth = outerContainer.clientWidth;
      const containerHeight = outerContainer.clientHeight;

      //outputRef output size'a göre

      let findMaxWidthTableIndex = () => {
        let maxTableClientWidth = -1; // <table> içeren öğeler için max clientWidth
        let maxTableIndex = -1; // <table> içeren öğelerin indeksi
        /// ilk divi bul element.offsetWidth yap bul. bu componentin width // ofsett width
        for (let i = 0; i < this.$refs.output.children.length; i++) {
          const element = this.$refs.output.children[i];

          if (
            element.tagName.toLowerCase() == "table" ||
            element.tagName.toLowerCase() == "div"
          ) {
            // Öğe bir <table> ise
            const tableWidth = element.clientWidth;

            if (tableWidth > maxTableClientWidth) {
              maxTableClientWidth = tableWidth;
            }

            if (element.children[0]?.tagName != undefined) {
              if (
                element.children[0].tagName?.toLowerCase() == "table" ||
                element.children[0].tagName?.toLowerCase() == "div"
              ) {
                const tableWidth2 = element.children[0].clientWidth;

                if (tableWidth2 > maxTableClientWidth) {
                  maxTableClientWidth = tableWidth2;
                }
              }
            }
          }
        }

        return maxTableClientWidth;
      };
      let tableWidth = findMaxWidthTableIndex();
      // Özelleştirilebilir bir formül kullanabilirsiniz
      this.scaleFactor = Math.max(
        containerWidth / (tableWidth + 135) //1070
      );
      this.initialScaleFactor = this.scaleFactor
      //scrollu başa sarmak için
      outerContainer.scrollLeft = 0;
      outerContainer.scrollTop = 0;
    },
    startDrag(event) {
      this.isDragging = true;
      this.lastX = event.clientX;
      this.lastY = event.clientY;
    },
    drag(event) {
      if (this.isDragging && !this.userSelectOption) {
        const deltaX = event.clientX - this.lastX;
        const deltaY = event.clientY - this.lastY;
        const outerContainer = this.$refs.outerOutputContainer;

        // Scroll pozisyonunu değiştir
        outerContainer.scrollLeft -= deltaX;
        outerContainer.scrollTop -= deltaY;

        this.lastX = event.clientX;
        this.lastY = event.clientY;
      }
    },
    endDrag() {
      this.isDragging = false;
    },
    showHTML(model,componentElements) {

      this.componentElements.specialFunctions.showHTML(model,componentElements)


      // this.componentElements.props.viewerElements.viewerShowModel = false
      // this.$emit('get-bill-detail')
    },
    downloadOutput() {
      // 'output' elementini seçin
      const outputElement = document.getElementById("output");
      // output elementinin içeriğini alın
      const outputContent = outputElement.outerHTML; // HTML içeriği ve stil yapısı
      // İndirme işlemini başlatmak için bir bağlantı oluşturun
      const blob = new Blob([outputContent], { type: "text/html" });
      const url = URL.createObjectURL(blob);
      // Bir <a> etiketi oluşturun ve indirme bağlantısını ayarlayın
      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = "FaturaNo_" + this.model.billNo + ".html"; // İndirilen dosyanın adı
      // Düğmeye tıklama olayını tetikleyin
      downloadLink.click();
      // URL'yi temizle
      URL.revokeObjectURL(url);

    },
    infoIconClickFunc(){
         this.showMiniTable = !this.showMiniTable
    },
    bottomTableTopPositionFix(){
         let originalValuesCont = document.getElementById('originalValuesCont')
         let bottomTableHeight = originalValuesCont.clientHeight
         this.topPosition = `calc(100vh - ${bottomTableHeight+8}px )`;
    }
  },
};
</script>
<style scoped>
.mainContainer {
  width: 100%;
  height: fit-content;
  position: relative;
}

.toolBarClass {
  background-color: #b5bbca;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  gap: 10px;
  /* border-right:2px solid white; */
}

.buttonClass {
  background-color: #29405f;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  color: white;
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
  font-size: 13px;
}

.buttonClass:hover {
  background-color: #203959;
}

.buttonClass:hover .iconClass {
  transform: scale(1.1);
  transition: ease-in-out;
}

.iconBoxClass {
  background-color: white;
  border-radius: 10px 0px 0px 10px;
  padding: 5px;
}

.iconTextClass {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 10px;
  color: white;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
}

.outerOutputContainer {
  width: 100%;
  height: calc(100vh - 40px);
  border: 8px solid #b5bbca;
  border-top: none;
  overflow: auto;
  padding: 10px;
  padding-left: 7%;
}

.outputContainer {
  transform-origin: top left;
  height: 0px;
  cursor: grab;
  user-select: none;
}

.outputContainerSelectOption {
  transform-origin: top left;
  /*height: 0px; */ 
  cursor: unset;
  user-select: text;
}

.outputContainer:active {
  cursor: grabbing;
}
.originalValuesCont{
  background-color: rgba(162, 195, 241, 0.7); ;
  position: absolute; 
  right: 23px;
}
.infoIconClass{
  position: absolute; 
  top: 55px; 
  right: 28px;
  background-color: rgb(33,150,243);
  border-radius: 100%;
}
.miniTableTitle{
  background-color: rgba(87, 133, 225, 0.5); ;
  font-weight: 600;
  text-align: start;
  position: relative;
  padding: 2px;
}
.miniTableCloseIcon{
  color: white;
  position: absolute;
  right: 3px;
  top: -3px;
  font-weight:600;
  font-size: 16px;
  cursor: pointer;
}


.tableBody{
    display: flex;
    flex-direction: column;
    font-size: 12px;
    gap:1px

}
.tableRow{
    display: flex;
    flex-direction: row;
    width:100%;
    justify-content: space-between;
    gap:1px;
    height:30px
}
.bottomTableRowCell{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border:1px solid black;
    padding:2px;
    min-width:105px
}
.bottomTableHeaderRowCell{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border:1px solid black;
    padding:2px;
    width:100%;
    white-space: nowrap;
}
</style>
