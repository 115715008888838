<template>
  <companyselect-component :landingDefaultObject="landingDefaultObject" />
</template>

<script>
import  companySelectComponent from "../../../frontend/components/atoms/container/companySelectComponent.vue";
import companyScreenLogo from"../assets/Didero-siyah.png"
import * as userRequest from "../request/user";// sendrequeste çevir
import {setLoggedUser} from "../../../frontend/components/atoms/helper/elementHelper.js";
export default {
    components: { 'companyselect-component' : companySelectComponent  },
    data: () => {
    return {
      landingDefaultObject : {}
    }
    },
    created() {
      this.landingPageFunction()
    },
    methods: {
      landingPageFunction() {
        let that = this
        this.landingDefaultObject= {
          companies: [],
          selectedCompany : "",
          projectName : "Dijital Denetim Robotu",
          companyScreenLogo : companyScreenLogo,
          setCompany : function (selectedCompany,companiesArray){
            let that2 = that
            let companies = companiesArray.filter(c => c.name == selectedCompany)
            let loggedUser = window.$cookies.get("loggedUser")  
            userRequest.checkOnline(companies[0].id).then(function () {
              userRequest.startCompanySession(loggedUser.userId, companies[0].id).then( res => {
                if (res.data.success) {
                  let companyObj = {}
                  companyObj.companyId = companies[0].id
                  companyObj.companyName = companies[0].name
                  that2.$store.dispatch("updateLoggedUserCompany", companyObj);
                  let newInfo = {}
                  newInfo.isloggedin = true
                  newInfo.token = loggedUser.token
                  newInfo.userId = loggedUser.userId
                  newInfo.companyId = companies[0].id
                  newInfo.companyName = companies[0].name
                  newInfo.userName = loggedUser.userName
                  setLoggedUser("loggedUser", newInfo)
                  that2.$router.push('/');
                }
              })
            }).catch((err) => console.log(err));
      
          },
          remove (item) {
            const index = this.friends.indexOf(item.name)
            if (index >= 0) this.friends.splice(index, 1)
          },
          logOut(){
            let loggedUserDefault = {
              userId: null,
              isloggedin: false,
              companyId: null,
              companyName: null,
              token: null,
              userName: null
            }
            window.$cookies.remove('loggedUser')
            that.$store.dispatch("updateLoggedUserInfo", loggedUserDefault);
            localStorage.clear();
            that.$router.push("/loginPage")        
          },
          async getCompany (){
            let user = await userRequest.getUser();
            let companies = []
            user = user.data.data;
            if (user != undefined) {
              user.tUserCompanies.forEach((companyObj) => {
                if (companyObj.tCompany.isActive === 0) {
                  return;
                }
                let tempCompany = {};
                tempCompany.name = companyObj.tCompany.name;
                tempCompany.id = companyObj.tCompany.id;
                tempCompany.deadLineLoggedin = companyObj.tCompany.deadlineLoggedin
                tempCompany.settings = companyObj.tCompany.settings
                companies.push(tempCompany);
              });
            }
            that.landingDefaultObject.companies = companies
            return companies
          },
        }
      }
    },
}
</script>

<style scoped>


</style>