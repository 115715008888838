<template>
  <forgotten-component :projectDesign="projectDesign"/>
</template>

<script>
import  forgottenComponent from "../../../frontend/components/atoms/container/forgottenPageComponent.vue";
import {sendRequest} from "../../../frontend/components/atoms/helper/requestHelper.js"
import {openSnackbar , setLoggedUser} from "../../../frontend/components/atoms/helper/elementHelper.js"
import logoLeftBox from"../assets/Notitek-Logo-White.png"
import rightLeftBox from"../assets/Didero-siyah.png"
import { router } from '../router/router'
export default {
  components: { 'forgotten-component' : forgottenComponent  },
  data: () => {
    return{
      dynamicElements:[],
      model:{},
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model1: [],
      projectDesign : {}
    }
    },
  created(){
    this.LoginPageProp()
  },
    
  methods : {
    LoginPageProp(){
      this.projectDesign = { 
        // src="@/assets/emproBlack.png"
        color : "background-color: #4a922a!important;",
        logoBackground : "background-color: #4a922a!important;",
        leftBoxLogo : logoLeftBox,
        rightBoxLogo : rightLeftBox,
        btnStyle : "background-color : #4a922a;  color : white; -webkit-border-radius: 16px",
        sendMail : function (email) {
          if (email != '') {
            sendRequest("POST","forgotYourPassword","",{email: email, project : "DİDERO"},function (response) {
                if (response.data.success == true) {
                  router.push('/loginPage')
                  openSnackbar("success", response.data.data.value, 3000)
                }else{
                  openSnackbar("error", "Kullanıcı Adı Yanlış", 2000)
                }
                },function (error) {
                  openSnackbar("error", "Hata", 2000)
                }
            ) 
          }else{
            openSnackbar("error", "Lütfen Mail Adresinizi Giriniz", 2000)
          }
          
        }
      }      
      }
    }
  }
</script>

