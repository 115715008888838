<template>
    <v-app>
      <div class="header-div">
        <h3 class="header-h3">KİRA TAHAKKUK KAYDI</h3>
      </div>
      <v-toolbar-items style="margin-left: 25px; height: 30px;">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text @click="downloadDrafts" v-on="on" style="background-color: #4BB543; color: white; border-radius: 5px;">
              Taslak İndir
            </v-btn>
          </template>
        </v-tooltip>
      </v-toolbar-items>
        <table-component :dynamic-element="tableElements" :model="model" class="small-page-layout"/>
        <Loader></Loader>
        <!-- <snackbar-component /> -->
    </v-app>
  </template>
  <script>
  import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
  import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
  import Enum from "../../../frontend/components/Enum.json";
  import Loader from "../../../frontend/components/atoms/loader";
  import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";
  
  import {
  getTable,
  updateItem,
  saveItem,
  deleteItem,
  openSnackbar,
  openTablePage,
  showLoader, 
  hideLoader 
  } from "../../../frontend/components/atoms/helper/elementHelper.js";
  import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
  import {downloadZipFile, checkProject} from "../../helper/uploadHelperForData"
  export default {
    components: {
        "table-component": tableComponent,
        "upload-component": uploadComponent,
        Loader: Loader,
        "snackbar-component": snackbarComponent,
    },
  
    data() {
        return {
          tableElements: {},
          model: {},
          renderKey: 0,
        };
    },
    created() {
      checkProject();
        this.loadData();
    },
    watch: {},
    methods: {
      downloadDrafts() {
        downloadZipFile();
      },
      renderRentTable(thisData, model, dynamicElements) {
        showLoader('Hesaplamalar Yapılıyor')
        let choiceData = {
          cookieData: window.$cookies.get("selectedItem")
        }
        sendRequest(Enum.requestType.POST, "queryRentTable", "", choiceData, 
        function (response) {
          dynamicElements.data = response.data.data.data;
          model.itemCount = dynamicElements.data.length;
          model.pagesCount = dynamicElements.data.length / 50;
          dynamicElements.forceRender();
          hideLoader();
        },
        function (error) {
         errFunc(error);
        })
    },
    loadData() {
      let that = this;
      this.tableElements = {
        sortBy: [],
        sortDesc: false,
        renderKey: 0,
        type: "table",
        tableHeight : 530,
        name: "rentTable",
        class: "",
        infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
        excelDownloadBtnText: "İndir",
        deleteDialogVisible : true,
        deleteBtnText: "Sil",
        hidePagination: true,
        saveBtnText : 'Yeni Kayıt',
        saveUrl : "rentTableInsert",
        //selectedRows: [],
        //filteredColumn: {},
        //filteredColumns: true,
        showDefaultDeleteButon : false,
        selectionUrl: "queryRentTable",
        initialFilter:{
            [Enum.initialFilter.AND] :
              [
                {
                  projectId: {
                    [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id
                  }
                }
              ]
         },
        showDefaultDeleteButon : true,
        deleteUrl: "deleteTable",
        deleteBtnSettings : {
            color: "error",
            style:"height: 25px !important; margin-top: 27%",
          },
        saveBtnSettings: {
          style:"height: 25px !important; margin-top: 15%",
        },
        clientEvents : {
          onBeforeSave :function (selectedItem,creatableObjects,model,tableObject) {
            creatableObjects.initialFilter = tableObject.initialFilter
          },
        },
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
              [
                {
                  id: "rent",
                  type: "v-file-input",
                  style:"height: 25px!important; width: 250px;",
                  label: "Dosyanızı Seçiniz",
                  inputHeader: "Kira",
                  name: "rent",
                  multiple: true,
                  onChange: async function(thisData, model, dynamicElements) {
                    let files = model.rent[0];
                    let choiceData = {
                        choice : "rent", 
                        headerAddFlag : true, 
                        fileName : files.name,
                        fileType : 'rent',
                        cookieData: window.$cookies.get("selectedItem")
                    }
                    showLoader('Dosyanız Hazırlanıyor')
                    uploadHelperFormDataQueue([files], "insertToDatabaseFromExcel", Enum.requestType.POST, choiceData, 
                    function (response) {
                      if (response.data.data.errorExcel != null) {
                      openSnackbar("error", "Hatalı Excel Taslağı Yüklediniz", "2000")
                      }
                      hideLoader();
                      that.renderRentTable(thisData, that.model.rentTable, dynamicElements)
                      openSnackbar("success", "Verileriniz Başarılı Bir Şekilde Kaydedildi", "2000")
                      },
                    function (error) {
                        openSnackbar(
                          "error",
                          error.response.data.error[0].detail.message
                        );
                        hideLoader();
                      }
                    )
                  }
                }
              ],
              // [
              //   {
              //     id: "delete",
              //     type: "v-btn",
              //     variant: "btn",
              //     name: "delete",
              //     inputText: "Sil",
              //     divStyle: "",
              //     style: "height: 36px !important;",
              //     btnTypeText: false,
              //     color: "error",
              //     prependIcon: "",
              //     classTag: "",
              //     onClick : function(thisData,model,dynamicElements) {
              //       let tableObject = getTable("insuranceTable")
              //       console.log(thisData,model,dynamicElements)
              //       if (tableObject.selectedRows.length > 0) {
              //         let options = {
              //           showDialog : true
              //         }
              //         deleteItem("insuranceTable",tableObject.selectedRows,options,model)
              //       }else{
              //         openSnackbar('error', 'Lütfen silmek istediğiniz satırı seçiniz')
              //       }
              //     }
              //   },
              // ],
            ],
          }
        ],
        headers: [
          { text: "Kira Yeri", value: "rentLocation", width: "100", divider: true,},
          { text: "Bitiş Tarihi", value: "endDate", width:"100", dataType: "date", divider: true, },
          { text: "Net Tutar", value: "netAmount", width: "100", dataType: "currency", divider: true, align: "right"},
          { text: "Kira Stopajı", value: "rentStoppage", width: "100", dataType: "currency", divider: true, align: "right"},
        ],
        columns: {
          rentLocation: {
            type: "v-text-field",
            label: "Kira Yeri",
            editable: false,
            creatable: true,
            inlineEditable:false,
          },
          endDate: {
            label: "Bitiş Tarihi",
            type : "v-date-picker",
            readonly: true,
            editable: false,
            creatable: true,
            inlineEditable:false,
          },
          netAmount: {
            type: "v-text-field",
            label: "Tutar",
            editable: false,
            creatable: true,
            inlineEditable:false,
          },
          rentStoppage: {
            type: "v-text-field",
            label: "Kira Stopajı",
            editable: false,
            creatable: true,
            inlineEditable:false,
          },
        }
      };
    },
    copyItem(tableName,selectedItem,options,model,onBeforeSave,onAfterSave,errorFunction) {
      if (!Array.isArray(selectedItem)) {
          selectedItem = [selectedItem];
      }
      
      let dynamicElements = getTable(tableName);
      if (options != undefined && options.dialogText != undefined) {
          dynamicElements.deleteDialogText = options.dialogText;
      }
      
      let title = "Yeni Kayıt Eklemek İstediğinize Emin Misiniz?";
      
      dynamicElements.forceRender();
      model.close = "Hayır",
      model.insertItem = "Evet";
      dynamicElements.dialogVisible = true;
      dynamicElements.dialogInfo = {
          name:"insertDialog",
          maxWidth : "1100",
          dialogVisible : "dialogVisible",
          title : title,
          layout : [
              {
                type : Enum.layoutType.div,
                name : "loginForm",
      
                inputElements : [
                ]
              }
          ],
          actions : [
            {
              id : "close",
              type : "v-btn",
              variant : "btn",
              name : "close",
              inputText : "Kapat",
              divStyle:"",
              btnTypeText: true,
              color:"green darken-1",
              classTag : "",
              onClick : function(thisData,model,dialogInfo) {
                var tableObject = getTable(tableName);
                tableObject.dialogVisible = false;
                tableObject.forceRender();
                console.log(thisData,model,dialogInfo);
              }
            },
            {
              id : "insertItem",
              type : "v-btn",
              variant : "btn",
              name : "insertItem",
              inputText : "Ekle",
              divStyle:"",
              btnTypeText: true,
              color:"green darken-1",
              classTag : "",
              onClick : function(thisData,model,dialogInfo) {
                var tableObject = getTable(tableName);
                console.log(thisData,model,dialogInfo);
                tableObject.dialogVisible = false;
                tableObject.forceRender();
                let creatableObjects = {}; 
                sendRequest(Enum.requestType.POST, dynamicElements.saveUrl,"",{selectedItem,creatableObjects},function (res) {
                    openTablePage(tableName);
                    openSnackbar("success", "Eklemeniz Başarılı Bir Şekilde Gerçekleşti");
                },function (error) {
                    if (errorFunction != undefined) {
                        errorFunction(error,selectedItem,creatableObjects,model,tableObject);
                    } else if(tableObject.clientEvents.onSaveError != undefined){
                        tableObject.clientEvents.onSaveError(error,selectedItem,creatableObjects,model,tableObject);
                    }
                });
              }
            },
          ]
      };
    },
  },
  }
  
    
  
  </script>
  
  <style>
  .header-div {
  text-align: center;
  }
  </style>