<template>
    <div style="display: flex;flex-direction: column;">
        <v-data-table :key="dataTableRenderKey" style="width: 100%;min-width: 100%" dense outlined filled
            :headers="model.headers" :items="model.desserts" :hide-default-footer="hideFooter"
            :disable-pagination="hideFooter" :footer-props="{ 'items-per-page-options': [model.itemsPerPageDetail] }"
            @pagination="handlePagination" class="dataTableWrapperOverflowAccountComp" @click:row="onRowClick">
            <template v-for="(header, index) in model.headers" v-slot:[`item.${header.value}`]="{ item }">
                <div :key="'d' + index" :style="header.style" :class="header.class">
                    <autocomplete-alternative v-if="header.dataType == 'select' && item[header.value] != ''"
                        @all-set-account-plan="allSetAccountPlanCode"
                        :allSetAccountPlanParam="{ headerValue: header.value, itemAllObj: item }"
                        :inputElement="header.uniqInput != undefined ? inputElementAutoProduct : inputElementAuto"
                        :inputValue1.sync="item[header.value]"
                        :accountNameParam="item[header.paramAccountName]" :accountType="header.paramAccountName" />
                    <autocomplete-alternative v-if="header.dataType == 'selectProduct' && item[header.value] != ''"
                        :key="productRenderKey" :inputElement="inputElementAutoProduct[index]"
                        :inputValue1.sync="item[header.value]" :accountNameParam="item[header.paramAccountName]"
                        :setAccountPlanVatAndBaseParam="{ headerValue: header.value, itemAllObj: item }"
                        @set-account-plan-vat-and-base="setAccountPlanVatAndBase" />

                    <v-select v-if="header.dataType == 'v-select'" :items="componentElements.props.productInvoiceItems"
                        @change="productInvoiceChangeFunc(item[header.value], item, undefined, index)"
                        style="width: 100%;font-size: 12px;height: 34px;" :item-value="'id'" :item-text="'name'"
                        v-model="item[header.value]" outlined dense></v-select>
                    <v-text-field v-if="header.dataType == 'input'" :label="header.text" :item-value="item[header.value]"
                        style="height: 27px " hide-details v-model="item[header.value]" outlined class="labelClass"
                        dense></v-text-field>
                    <vuetify-money v-if="header.dataType == 'money'" :label="header.text" :item-value="item[header.value]"
                        style="font-size: 12px;height: 34px;padding-left: 5px;padding-right: 5px;" hide-details
                        v-model="item[header.value]" outlined class="labelClass" dense></vuetify-money>
                    <div v-if="header.dataType == 'button'"
                        style="display:flex; flex-direction:row; flex-wrap: nowrap; place-content:center;gap:3px;">
                        <v-btn style="height: 25px !important;min-width: 5px;width: fit-content; padding: 2px;color: white; "
                            color="#294060" @click="addDetailCodeForInvoice(item, 'PK')" x-small>
                            PK
                        </v-btn>
                        <v-btn style="height: 25px !important;min-width: 5px;width: fit-content; padding: 0px;color: white;"
                            color="#294060" @click="addDetailCodeForInvoice(item, 'MM')" x-small>
                            MM
                        </v-btn>
                        <!-- <v-btn style="height: 25px !important;min-width: 5px;width: fit-content; padding: 1px;color: white;"
                            color="#294060" x-small>
                            {{ getRate(item,model)}}
                        </v-btn> -->
                        <div style="font-weight: 600;color:#294060;align-items: center;display: flex;">{{ getRate(item,model)}}</div>
                    </div>
                    <div v-if="header.dataType == 'onlyText'">
                        {{ item[header.value] }}
                    </div>
                </div>
            </template>
        </v-data-table>
        <div v-if="model.eInvoiceType == 'Z Raporu'" class="zReportsBox">
                <!-- <h4>Nakit</h4> -->
                <autocomplete-alternative  :inputElement="inputElementAuto" :inputValue1.sync="model.desserts[0]['accountPlanZreportCashAccountCode']" :accountNameParam="model.desserts[0]['accountPlanZreportCashAccountName']"
                    :onClickElements="{item:model.desserts,type:'accountPlanZreportCashAccountCode'}"
                />
                <!-- <h4>Yemek</h4> -->
                <autocomplete-alternative  :inputElement="inputElementAuto" :inputValue1.sync="model.desserts[0]['accountPlanZreportFoodAccountCode']" :accountNameParam="model.desserts[0]['accountPlanZreportFoodAccountName']" 
                    :onClickElements="{item:model.desserts,type:'accountPlanZreportFoodAccountCode'}"
                />
                <!-- <h4>Diğer</h4> -->
                <autocomplete-alternative  :inputElement="inputElementAuto" :inputValue1.sync="model.desserts[0]['accountPlanZreportOtherAccountCode']" :accountNameParam="model.desserts[0]['accountPlanZreportOtherAccountName']" dropdownPosition="top"
                    :onClickElements="{item:model.desserts,type:'accountPlanZreportOtherAccountCode'}"
                />
                <!-- <h4>Kredi Kartı</h4> -->
                <autocomplete-alternative  :inputElement="inputElementAuto" :inputValue1.sync="model.desserts[0]['accountPlanZreportCreditAccountCode']" :accountNameParam="model.desserts[0]['accountPlanZreportCreditAccountName']" dropdownPosition="top"
                    :onClickElements="{item:model.desserts,type:'accountPlanZreportCreditAccountCode'}"
                />
        </div>
        <div class="autoCompletesMainBox" id="autoMainCompletesBox">
            <div v-if="model.vehicleRestriction" class="autoCompletesBox">
                <div class="autoCompletesInnerBox">
                    <autocomplete-alternative :inputElement="inputElementAuto"
                        :inputValue1.sync="model.desserts[0]['accountPlanVehicleBaseCode']"
                        :accountNameParam="model.desserts[0]['accountPlanVehicleBaseName']" :inputLabel="'Araç Kısıtlaması (Matrah)'"
                        :onClickElements="{item:model.desserts,type:'accountPlanVehicleBaseName'}"
                        class="autoCompleteClass" />
                    <autocomplete-alternative :inputElement="inputElementAuto"
                        :inputValue1.sync="model.desserts[0]['accountPlanVehicleVatCode']"
                        :accountNameParam="model.desserts[0]['accountPlanVehicleVatName']" :inputLabel="'Araç Kısıtlaması (KDV)'"
                        :onClickElements="{item:model.desserts,type:'accountPlanVehicleVatName'}"
                        class="autoCompleteClass" />
                </div>
                <div class="autoCompletesInnerBox" v-if="model.vehiclePunishmentBoxBoo">
                    <autocomplete-alternative :inputElement="inputElementAuto"
                        :inputValue1.sync="model.desserts[0]['accountPlanPunishmentAccountCode']"
                        :accountNameParam="model.desserts[0]['accountPlanPunishmentAccountName']"
                        :onClickElements="{item:model.desserts,type:'accountPlanPunishmentAccountName'}"
                        :inputLabel="'Trafik Cezası'" class="autoCompleteClassSpecial" />
                </div>
            </div>
             <div v-if="model.withholding" class="withholdingsBox">
                <div :key="item" v-for="item,index in model.desserts" v-if="withholdingShowFunc(item,index)"  class="withholdingBox" >
                    
                        <div v-if="item.kind == 1" class="withholdingAccounts">
                            <autocomplete-alternative class="withholdingAccount" :inputElement="inputElementAuto" :inputValue1.sync="item['accountPlanWithholPurchaseDeductedVatCode']" :accountNameParam="item['accountPlanWithholPurchaseDeductedVatName']" :inputLabel="'Sorumlu Sıfatı İnd.KDV'"  :onClickElements="{item:item,type:'accountPlanWithholPurchaseDeductedVat'}"/>
                            <autocomplete-alternative class="withholdingAccount" :inputElement="inputElementAuto" :inputValue1.sync="item['accountPlanWithholdingPurchasePayableVatCode']" :accountNameParam="item['accountPlanWithholdingPurchasePayableVatName']"  :inputLabel="'Sorumlu Sıfat Ödenecek KDV'"  :onClickElements="{item:item,type:'accountPlanWithholdingPurchasePayableVat'}" />
                        </div>
                        <div class="withholdingKey" @mouseover="withHoldingCodeEnumSpanBoo = true" @mousemove="withHoldingCodeEnumSpanBoo = true" @mouseleave="withHoldingCodeEnumSpanBoo = false">{{ item.withholdingCode }}</div>
                        <div v-if="withHoldingCodeEnumSpanBoo"  class="withholdingKeySpan">{{ withHoldingCodeEnum[item.withholdingCode] }}</div>
                        <!-- <div v-if="item.kind == 0" class="withholdingAccounts">
                            <autocomplete-alternative class="withholdingAccount" :inputElement="inputElementAuto" :inputValue1.sync="item['accountPlanWithholdingSalesDeductedVatCode']" :accountNameParam="item['accountPlanWithholdingSalesDeductedVatName']" :inputLabel="'Sorumlu Sıfat Ödenecek KDV'"  :onClickElements="{item:item,type:'accountPlanWithholdingSalesDeductedVat'}" /> 
                            <autocomplete-alternative class="withholdingAccount" :inputElement="inputElementAuto" :inputValue1.sync="item['accountPlanWithholdingSalesPayableVatCode']" :accountNameParam="item['accountPlanWithholdingSalesPayableVatName']" :inputLabel="'Sorumlu Sıfatı İnd.KDV'"  :onClickElements="{item:item,type:'accountPlanWithholdingSalesPayableVat'}"/>
                        </div> -->
                
                </div>
            </div>
            <div v-if="model.exportVat" class="autoCompletesBox">
                <autocomplete-alternative :inputElement="inputElementAuto"
                    :inputValue1.sync="model.desserts[0]['accountPlanExportVatCode']"
                    :accountNameParam="model.desserts[0]['accountPlanExportVatName']"
                    :inputLabel="'İhraç Kayıtlı KDV Tutarı'" style="width: 50%;"/>
                
            </div>
        </div>
        <div v-if="model.contact" style="padding-top: 50px;">
            <autocomplete-alternative :inputElement="inputElementAuto"
                :inputValue1.sync="model.desserts[0]['accountPlanCommunicationVatCode']"
                :accountNameParam="model.desserts[0]['accountPlanCommunicationVatName']" :inputLabel="'İletişim Vergisi'"
                style="width: 33%;" />
        </div>
    </div>
</template>

<script>
import autocompleteAlternative from "../../../frontend/components/atoms/container/autocompleteAlternative.vue";
import Enum from "../helper/Enum.json";
import { sendRequest } from "../../../frontend/components/atoms/helper/requestHelper.js";
import { openSnackbar, showLoader, hideLoader } from '../../../frontend/components/atoms/helper/elementHelper';
import store from "../store";

export default {
    components: {
        "autocomplete-alternative": autocompleteAlternative,
    },
    props: {
        componentElements: {
            type: Object,
            default: () => { },
        },
        model: {
            type: Object,
            default: () => { },
        },
    },
    data() {
        return {
            withHoldingCodeEnum:Enum.withHoldingCodeEnum,
            withHoldingCodeEnumSpanBoo:false,
            dataTableRenderKey: 0,
            productRenderKey: 0,
            inputElementAuto: {},
            inputElementAutoProduct: {},
            withHoldingCodeEnum:Enum.withHoldingCodeEnum,
            withHoldingCodeEnumSpanBoo:false,
            hideFooter: true,
        };
    },
    created() {
        this.inputElementAuto = this.componentElements.props.inputElementAuto

        if (this.model.desserts.length > this.model.itemsPerPageDetail) { //pagination yapılıp yapılamayacağına göre footer'ı saklar
            this.hideFooter = false
        }

        let that = this;
        for (let i = 0; i <= this.model.desserts.length; i++) {
            if (this.inputElementAutoProduct[i + 1] == undefined) {
                this.inputElementAutoProduct[i + 1] = {
                    type: "v-select",
                    name: "invoice",
                    label: "Hesap Seçiniz",
                    dropDownItems: [],
                    key: "id",
                    visibleItemKeys: ["accountCode","accountName"],
                    hoverItemKeys: ["accountName"],
                    inputBoxStyle: "height: 27px;",
                    //kullanıcı Fatura tipi seçmiş olduğu ürün kodu dolması için yazıldı
                    getselectionurlFunc: function (childThis) {
                        let childThat = childThis;
                        that.productInvoiceChangeFunc(childThis.setAccountPlanVatAndBaseParam.itemAllObj[childThis.inputElement.bodyObj.key], childThis.setAccountPlanVatAndBaseParam.itemAllObj, function (data) {
                            childThat.mainData = data;
                        })
                    },
                    bodyObj: { key: 'productInvoiceType' }
                }
            }
        }
    },
    methods: {
        withholdingShowFunc(item, index) { 
            if (item.withholdingCode != null) {
                const firstSegment = this.model.desserts.slice(0, index); 
                const found = firstSegment.some((currentItem) => {
                    return currentItem.withholdingCode == item.withholdingCode;
                });

                return !found; 
            } else {
                return false; 
            }
        },
        productInvoiceChangeFunc(productInvoiceVModel, item, thenFunc, index) {
            showLoader("Lütfen Bekleyiniz")
            let that = this
            sendRequest(Enum.requestType.POST, "getProductPlan", "", { productType: productInvoiceVModel },
                function (response) {
                    if (response.status == 200) {
                        //Fat tipi ilki seçilince gerisi aynı fat tipi olsun diye yazılan fonksiyon
                        function allSetProductInvoiceType() {
                            if (that.model.rowIndex == 0) {
                                for (let i = 0; i < that.model.desserts.length; i++) {
                                    if(thenFunc == undefined){
                                        that.model.desserts[i]['productBaseCode'] = null
                                        that.model.desserts[i]['productBaseName'] = null
                                    }
                                    that.model.desserts[i]['productInvoiceType'] = item['productInvoiceType']
                                }
                            }
                            if(thenFunc == undefined){
                                    //kullanıcı Fatura tipi değiştirdiğinde ürün kodu sıfırlanması için yazıldı
                                    item['productBaseCode'] = null;
                                    item['productBaseName'] = null;
                            }
                        }
                        if (thenFunc != undefined) {
                            thenFunc(response.data.data.value);
                            allSetProductInvoiceType()
                        }
                        else {
                            allSetProductInvoiceType()
                            that.inputElementAutoProduct[index].dropDownItems = response.data.data.value
                            that.productRenderKey++
                        }

                        hideLoader();
                    } else {
                        openSnackbar("error", "Ürün Kodu Getirelemedi. Lütfen Tekrar Deneyiniz.");
                        hideLoader()
                    }
                },
                function (error) {
                    openSnackbar("error", "Ürün Kodu Getirelemedi. Lütfen Tekrar Deneyiniz.");
                    hideLoader()
                }
            );
        },
        setAccountPlanVatAndBase(selectedAccountCodeHeaderName, selectedObj, item) {
            // accountPlanBaseCode, accountPlanVatCode bu key isimlerine obje setlemek gerek.
            // db designera bakarak yaptım. yine de kontrol edin lütfen.
            let enumsForCode = {
                1: "accountPlanBaseCode",
                2: "accountPlanVatCode"
            }
            let setObj = {
                accountCode: "",
                accountName: "",
                companyId: "",
                id: "",
                synchronous: true,
                isActive: 1,
            }
            setObj.accountCode = selectedObj.accountPlanCode;
            setObj.accountName = selectedObj.accountPlanName;
            setObj.companyId = selectedObj.companyId;
            setObj.id = selectedObj.accountPlanId;
            item[enumsForCode[selectedObj.appColumnType]] = JSON.parse(JSON.stringify(setObj))
            if (selectedObj.connectCodes.length > 0) {
                for (let i = 0; i < selectedObj.connectCodes.length; i++) {
                    if (setObj.accountCode = selectedObj.connectCodes[i].accountPlanCode != null) {
                        setObj.accountCode = selectedObj.connectCodes[i].accountPlanCode;
                        setObj.accountName = selectedObj.connectCodes[i].accountPlanName;
                        setObj.companyId = selectedObj.connectCodes[i].companyId;
                        setObj.id = selectedObj.connectCodes[i].accountPlanId;
                        item[enumsForCode[selectedObj.connectCodes[i].appColumnType]] = setObj
                    }
                }
            }
            this.allSetAccountPlanCode(selectedAccountCodeHeaderName, selectedObj, item);
        },
        allSetAccountPlanCode(selectedAccountCodeHeaderName, selectedObj, item) {
            // 1.satırdaki matrah kodunu seçtiğinde diğer kalemlere de setlenmesi için yazıldı. '2.satırdan seçersen bu fonk çalışmaz. sadece 1. kalem için geçerli.'
            if (item.einId == this.model.desserts[0].einId) {
                if (selectedAccountCodeHeaderName === 'productBaseCode') {
                    const firstDessert = this.model.desserts[0];
                    for (let i = 0; i < this.model.desserts.length; i++) {
                        this.model.desserts[i]['productBaseCode'] = selectedObj;
                        this.model.desserts[i]['accountPlanBaseCode'] = firstDessert['accountPlanBaseCode'];
                        this.model.desserts[i]['accountPlanVatCode'] = firstDessert['accountPlanVatCode'];
                    }
                } else {
                    for (let i = 0; i < this.model.desserts.length; i++) {
                        this.model.desserts[i][selectedAccountCodeHeaderName] = selectedObj;
                    }
                }
                this.dataTableRenderKey++
            }
        },
        onRowClick(item, index) {
            this.model.rowIndex = index.index
        },
        handlePagination({ page, itemsPerPage }) {
            this.componentElements.specialFunctions?.handlePagination(page, itemsPerPage,this.model,this.componentElements)
        },
        setStyles(boxes, mainBoxes, widthValue, paddingRightValue,paddingLeftValue) {
            for (var i = 0; i < boxes.length; i++) {
                var box = boxes[i];
                box.style.width = widthValue;
            }
            for (var i = 0; i < mainBoxes.length; i++) {
                var mainBox = mainBoxes[i];
                mainBox.style.paddingRight = paddingRightValue;
                mainBox.style.paddingLeft = paddingLeftValue



            }
        },
        addDetailCodeForInvoice() {
            openSnackbar("", "Aktif Değil")
        },
        withholdingShowFunc(item, index) {
            if (item.withholdingCode != null) {
                const firstSegment = this.model.desserts.slice(0, index); 
                const found = firstSegment.some((currentItem) => {
                    return currentItem.withholdingCode == item.withholdingCode;
                });

                return !found; 
            } else {
                return false; 
            }
        },
        getRate(item,model){
                if(model.summaryInfo != null &&  model.summaryInfo.statu == true){
                    return `${item.vatRate}%`
                }
        }
    },
    mounted() {
        var boxes = document.getElementsByClassName('autoCompletesBox');
        var mainBoxes = document.getElementsByClassName('autoCompletesMainBox');

        if (store.state.logoErpAndReciptKeyState) {
            this.setStyles(boxes, mainBoxes, '43.6%', '29.4%', "");
        } else {
            this.setStyles(boxes, mainBoxes, '100%', '33.5%', "");
        }
    }
};
</script>

<style scoped>

::v-deep td.text-center.v-data-table__divider {
    padding-left: 2px;
    padding-right: 2px;
}
::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)>.v-input__control>.v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
    padding-right: 0px;
    padding-left: 8px;
    min-height: 10px;
}

/* aditional autocomplete box style */
.autoCompletesMainBox{
    width:100%;
    display: flex;
    justify-content: flex-end;
}
.autoCompletesBox {
    display: flex;
    gap: 30px;
    flex-direction: column;
    padding: 40px 0px 30px 0px;
    align-items: flex-end;
    justify-content: flex-start;
    align-items: flex-start;
}
.autoCompletesInnerBox {
    display: flex;
    flex-direction: row;
    gap: 6px;
    font-size: 14px;
    width:100%
}
.autoCompleteClass {
  width:100%
}
.autoCompleteClassSpecial {
  width:50%
}


/* v-data table için*/
.dataTableWrapperOverflowAccountComp{ 
  height: fit-content; 
  box-shadow: none;
}
::v-deep .dataTableWrapperOverflowAccountComp .v-data-table__wrapper{
  overflow:visible;
  width:100%
} 
::v-deep  .dataTableWrapperOverflowAccountComp .v-data-table__wrapper table {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24), 0 2px 3px rgba(0, 0, 0, 0.12);
  padding: 1px;
  border-spacing:0px
}
::v-deep  .dataTableWrapperOverflowAccountComp .v-data-table__wrapper table thead{
  height:33px;
} 
::v-deep  .dataTableWrapperOverflowAccountComp .v-data-table__wrapper table tbody tr{
  height:40px;
} 
::v-deep  .dataTableWrapperOverflowAccountComp .v-data-table__wrapper table tbody tr td:nth-child(2) .v-select__selections input{ display: none;}
::v-deep  .dataTableWrapperOverflowAccountComp .v-data-table__wrapper table tbody tr td:nth-child(2) .v-input__control{height:32px;margin-top:2px;}
::v-deep  .dataTableWrapperOverflowAccountComp .v-data-table__wrapper table tbody tr td:nth-child(2) .v-icon{margin-bottom: 10px;}
::v-deep .dataTableWrapperOverflowAccountComp .v-data-table__wrapper table tbody tr td:nth-child(2) {width: 90px; min-width: 90px; max-width: 90px;}
/* withholdingdatas styles  */  
.withholdingsBox{
    display: flex;
    flex-direction: column;
    gap:8px;
    margin-top: 28px;
    margin-left:15px;
    width:100%
}
.withholdingBox{
    display: flex;
    flex-direction: row;
    gap:15px;
    align-items: center;    
    position:relative;
}
.withholdingKey{
    width:fit-content;
    background-color: #00a8ff;
    color: white;
    border-radius: 5px;
    padding: 5px;
}
.withholdingKeySpan{
    position:absolute;
    top:0px;
    left:0px;
    width:fit-content;
    background-color: gray ;
    color: white;
    border-radius: 5px;
    padding: 5px;
}
.withholdingAccounts{
    display: flex;
    flex-direction: row;
    gap:5px
}
.withholdingAccount{
    width:200px

}

.zReportsBox{
    display: flex;
    flex-direction: column;
    gap:15px;
    margin-top: 28px;
    margin-left:15px;
    width:200px
}
</style>

