<template>
  <div>
    <v-toolbar height="50%" flat dark style="background-color: #313131;">
      <v-toolbar-title style="margin-right:1%;"><img src="./public/power-yesil.png" alt="Didero" srcset=""></v-toolbar-title>
      <v-toolbar-items>
        <!-- <v-menu
          bottom
          origin="center center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn small style="height:30px!important" text v-on="on">
              E-Tutanak
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              dense
              v-for="(item, index) in protocol"
              :key="index"
              @click="test(item.value)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->
        <!-- <v-menu
          bottom
          origin="center center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn small style="height:30px!important" text v-on="on">
              Atama
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="test('declarationdefine')">
              <v-list-item-content>
                <v-list-item-title>Beyanname Tanımlama</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="test('assigningReceipt')">
              <v-list-item-content>
                <v-list-item-title>Döviz Hesapları</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              style="margin-left: -6%"
              v-for="item in declarationAssing"
              :key="item.title"
              v-model="item.active"
              no-action
            >
              <template v-slot:activator>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <v-list-item
                dense
                v-for="subItem in item.items"
                :key="subItem.title"
                @click="test(subItem.title)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon>{{ subItem.action }}</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list-group>
            <v-list-item @click="test('assigningReceipt')">
              <v-list-item-content>
                <v-list-item-title>KKEG Hesapları</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu> -->
        <v-btn small text @click="test('folderNotification')">
          Dosya Yönetimi
        </v-btn>
        <v-menu 
          bottom
          origin="center center"
          transition="scale-transition"
        >
        
          <template v-slot:activator="{ on }">
            <v-btn small text v-on="on" :disabled="muavinUpload">
              Veri Girişi
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="test('uploadTest')">
              <v-list-item-content>
                <v-list-item-title>Muavin/E-Defter Girişi</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- :disabled="isFatura" -->
            <v-list-item @click="test('ebillUpload')" :disabled="isFatura">
              <v-list-item-content>
                <v-list-item-title>E-Fatura Girişi</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="test('manuelBotTable')" >
              <v-list-item-content>
                <v-list-item-title>Otomatik Veri Girişi</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            
              <template v-slot:activator>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-list>
                <v-list-item
                  :disabled="isDisabled"
                  dense
                  v-for="(item, index) in dataInput"
                  :key="index"
                  @click="test(item.value)"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
              <!-- <v-list-item
                dense
                v-for="subItem in item.items"
                :key="subItem.title"
                @click="test(subItem.title)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon>{{ subItem.action }}</v-icon>
                </v-list-item-action>
              </v-list-item> -->
              <!-- <v-list-group
              style="margin-left: -6%"
              v-for="item in dataInput"
              :key="item.title"
              v-model="item.active"
              no-action
            >
            </v-list-group> -->
            <!-- <v-list-item @click="test('assigningReceipt')">
              <v-list-item-content>
                <v-list-item-title>Gelecek Ay/Yıl Giderleri</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="test('assigningReceipt')">
              <v-list-item-content>
                <v-list-item-title>Gelecek Ay/Yıl Gelirleri</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="test('assigningReceipt')">
              <v-list-item-content>
                <v-list-item-title>Mali Zarar</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="test('assigningReceipt')">
              <v-list-item-content>
                <v-list-item-title>Gümrük Beyannamesi</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="test('assigningReceipt')">
              <v-list-item-content>
                <v-list-item-title>Stok</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
          </v-list>
        </v-menu>
        <v-menu 
          bottom
          origin="center center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn small text v-on="on" :disabled="muavinUpload">
              Sorgu
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item @click="test('testTable')">
              <v-list-item-content>
                <v-list-item-title
                  >Muavin İşlemleri</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="test('eBillTable')">
              <v-list-item-content>
                <v-list-item-title
                  >E-Faturalar</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <!-- :disabled="isFatura" -->
            <v-list-item @click="test('eBillsUltraTest')">
              <v-list-item-content>
                <v-list-item-title
                  >E-Fatura İşlemleri(Yüklenen)</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="test('reviewedEBills')">
              <v-list-item-content>
                <v-list-item-title
                  >E-Fatura İşlemleri(Onaylanan)</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="test('eBillsArchive')">
            <v-list-item-content>
                <v-list-item-title
                  >E-Fatura İşlemleri(Arşivim)</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item @click="test('assigningReceipt')">
              <v-list-item-content>
                <v-list-item-title style="margin-left:9%;"
                  >Fiş Atama</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item @click="test('findReceipt')">
              <v-list-item-content>
                <v-list-item-title
                  >Fiş Arama</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-group
              v-for="item in query"
              :key="item.title"
              v-model="item.active"
              no-action
            >
              <template v-slot:activator>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <v-list-item
                dense
                v-for="subItem in item.items"
                :key="subItem.title"
                @click="test(subItem.title)"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-icon>{{ subItem.action }}</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-list-group> -->
          </v-list>
        </v-menu>
        <v-menu 
          bottom
          origin="center center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn small text v-on="on" :disabled="isDisabled">
              Kontrol
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              dense
              v-for="(item, index) in control"
              :key="index"
              @click="test(item.value)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu 
          bottom
          origin="center center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn small  text v-on="on" :disabled="isBeyanname">
              Beyanname
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              dense
              v-for="(item, index) in declaration"
              :key="index"
              @click="test(item.value)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu 
          bottom
          origin="center center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn :disabled='isDisabled'  small text v-on="on">
              Hesaplama
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              dense
              
              v-for="(item, index) in calculation"
              :key="index"
              @click="test(item.value)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- <v-menu 
          bottom
          origin="center center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn small style="height:30px!important" text v-on="on">
              Yıl Sonu İşlemler
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(item, index) in yearEndTransaction"
              :key="index"
              @click="test(item.value)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->
        <v-menu 
          bottom
          origin="center center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn small text v-on="on" :disabled="isDisabled">
              Mali Tablolar
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(item, index) in financialStatements"
              :key="index"
              @click="test(item.value)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- <v-menu 
          bottom
          origin="center center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn small style="height:30px!important" text v-on="on">
              Analiz
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(item, index) in analysis"
              :key="index"
              @click="test(item.value)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->

        <v-menu 
          bottom
          origin="center center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn @click="test('createProject')" small text v-on="on">
              Projeler
            </v-btn>
          </template>
        </v-menu>

        <!-- <v-menu 
          bottom
          origin="center center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn small text v-on="on">
              Bütçe
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(item, index) in budget"
              :key="index"
              @click="test(item.value)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->

        <!-- <v-menu 
          bottom
          origin="center center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn small text v-on="on">
              Yurtdışı Rapor
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(item, index) in specialReport"
              :key="index"
              @click="test(item.value)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->
        <!-- <v-menu 
          bottom
          origin="center center"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-btn small text v-on="on">
              Enflasyon
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(item, index) in fixedAsset"
              :key="index"
              @click="test(item.value)"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
            
          </v-list>
        </v-menu> -->
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-select
            style="box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px; max-width: 250px;"
            text  
            v-on="on"
            :items="items"
            item-text="visibleProp"
            label="Proje Seçiniz"
            v-model="projectElements"
            return-object
            height="30px"
            solo
            @click:clear="clearProjectSelection()"
            clearable
            >
            </v-select>
          </template>
        </v-tooltip>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn @click="selectCompany()" large style="height:30px!important; margin-top: 9.5px; max-width: 50px; color: whitesmoke; background-color: rgb(74, 146, 42); border-radius: 3px; " text v-on="on">
              {{compName.length > 5 ? compName[0] + compName[1] + compName[2] + compName[3] + compName[4] + "..." : compName}}
            </v-btn>
          </template>
          <span>{{compName}}</span>
        </v-tooltip>
      </v-toolbar-items>
      <v-toolbar-items>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-if="isSettings" text @click="test('settings')" v-on="on">
              <v-icon >settings</v-icon>
            </v-btn>
          </template>
          <span>Ayarlar</span>
        </v-tooltip>
      </v-toolbar-items>
      <v-toolbar-items>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text @click="downloadZipFile" v-on="on">
              <v-icon >file_download</v-icon>
            </v-btn>
          </template>
          <span>Taslak İndir</span>
        </v-tooltip>
      </v-toolbar-items>
      <v-toolbar-items>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn class="mx-2" text @click="logOut" v-on="on">
              <v-icon >exit_to_app</v-icon>
            </v-btn>
          </template>
          <span>Çıkış</span>
        </v-tooltip>
      </v-toolbar-items>
      <v-toolbar-items >
        <v-menu>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn  small v-bind="attrs"  v-on="{ ...tooltip, ...menu }">
                  <v-icon >help</v-icon>
                </v-btn>
              </template>
              <span>Yardımcı Programlar</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item
                  dense
                  v-for="(item, index) in helperPrograms"
                  :key="index"
                  @click="test(item.value)"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-toolbar>
  </div>
</template>

<script>
import * as userRequest from "./src/request/user";
import { sendRequest } from "../frontend/components/atoms/helper/requestHelper";
import Enum from "../frontend/components/Enum.json";
import axios from "axios"
import { logOut } from "./src/store/index";
import { openSnackbar } from "../frontend/components/atoms/helper/elementHelper";
//import { openSnackbar } from '../frontend/components/atoms/helper/elementHelper';


export default {
  data: () => ({
    selectedItem : {},
    selectedCompany: {},
    items : [],
    compName : "",
    isDisabled: false,
    muavinUpload: false,
    isBeyanname: false,
    isFatura: false,
    isSettings: false,
    declarationAssing: [
      {
        action: "local_activity",
        title: "Beyanname Hesapları",
        items: [
          { title: "KDV", value: "assignmentVat" },
          { title: "Muhtasar", value: "concise" },
          { title: "Geçici Vergi", value: "temporaryTax" },
          { title: "Damga", value: "damga" },
          { title: "Kurumlar Vergisi", value: "corporationTax" },
        ],
      },
    ],
    // query: [
    //   {
    //     action: "local_activity",
    //     title: "Pasife Çekme",
    //     items: [
    //       { title: "Kapanış Fişi", value: "temporaryTax" },
    //       { title: "Yansıtma", value: "projection" },
    //       { title: "Kar / Zarar", value: "profitAndLoss" },
    //     ],
    //   },
    // ],
    dataInput: [
      // {
      //   action: "local_activity",
      //   title: "Banka",
      //   items: [
      //     { title: "Kredi", value: "credit" },
      //     { title: "Kiralama", value: "leasing" },
      //     { title: "Spotaj", value: "stoppage" },
      //   ],
      // },
      // {
      //   action: "restaurant",
      //   title: "Müfredat Kartı",
      //   items: [
      //     { title: "Vergi Dairesi", value: "taxAdministration" },
      //     { title: "SGK", value: "sgk" },
      //   ],
      // },
      // {
      //   action: "local_activity",
      //   title: "İstisna İndirimler",
      //   items: [
      //     { title: "İştirak Kazançlar", value: "1" },
      //     { title: "Emisyon Pirimi", value: "2" },
      //     { title: "Portföy Kazançları", value: "3" },
      //     { title: "Taşınmaz ve İştirak Hissesi Satış Kazancı", value: "4" },
      //     { title: "Serbest Bölge Karı", value: "5" },
      //     { title: "Konusu Kalmayan Karşılık İptalleri", value: "6" },
      //     { title: "Çek Reeskontu İptal Gelirleri", value: "7" },
      //     { title: "Diğer İndirimler", value: "8" },
      //     { title: "Geçmiş Dönem Giderleri", value: "9" },
      //     {
      //       title: "Teknoloji Geliştirme Bölgelerinde Elde Edilen Kazançlar",
      //       value: "10",
      //     },
      //     { title: "AR-GE", value: "11" },
      //     { title: "Sponsorluk Harcamaları", value: "12" },
      //     { title: "Bağışlar", value: "13" },
      //     { title: "Yatırım İndirimi Teşvikli", value: "14" },
      //     { title: "Nakdi Sermaye Artışı", value: "15" },
      //   ],
      // },
      // {
      //   action: "restaurant",
      //   title: "Mahsup Edilecek Vergiler",
      //   items: [
      //     { title: "Kesinti Suretiyle Ödenen Vergiler", value: "16" },
      //     { title: "Önceki Dönemlerde Hesaplanan Geçici Vergi", value: "17" },
      //     { title: "Yurt Dışında Ödenen Vergiler", value: "18" },
      //   ],
      // },
      { title: "Kira", value: "rent" },
      { title: "Nakdi Sermaye Artışı", value: "capitalCashIncrease" },
      { title: "Gelecek Dönemlere Ait Giderler", value: "insurance2" },
      { title: "Geçmiş Yıl Zararları", value: "financialLossUpdate" },
      { title: "Sabit Kıymet Listesi", value: "depreciationInsert" },
      { title: "Ortak ve İlişkili Kişi Bilgileri", value: "hiddenCapitalUpdate" },
      { title: "Finansman Kısıtlaması", value: "fundingConstraintsUpdate" },
      //{ title: "180 - 280 Diğer Bilgiler", value: "insuranceUpdate" },
      { title: "Bordro", value: "34" },
      { title: "Kredi", value: "credit" },
      //{ title: "Önceki Dönem Mizan", value: "previousPeriodMizan" },
      //{ title: "Kur Farkı Fişleri", value: "37" },
    ],
    control: [
      { title: "Hareketsiz Hesaplar", value: "staticAccounts" },
      //{ title: "Gün Analizi", value: "21" },
      //{ title: "Sorumlu Yevmiye Fiş", value: "22" },
      //{ title: "Mükerrer Kayıt", value: "23" },
      { title: "Ters Bakiye Kontrolü", value: "deficitBalance" },
      { title: "Ters Kayıt Kontrolü", value: "reverseEntry" },
      //{ title: "Jet Test", value: "jetTest" },
      { title: "Satış Fatura Numara Kontrolü", value: "25" },
      { title: "E-Fatura Deneyiş", value: "eBillsTest" },
      { title: "E-Fatura Ultra Deneme", value: "eBillsUltraTest" },
      //{ title: "Muavin Karşılaştırma", value: "muavinComparison"},
    ],
    protocol: [
      { title: "Kasa Sayım Tutanağı", value: "19" },
      { title: "Stok Sayım Tutanağı", value: "20" },
      { title: "Son Fatura", value: "21" }
    ],
    declaration: [
      { title: "Atama", value: "declarationdefine" },
      { title: "Bordro", value: "26" },
      { title: "SGK", value: "uploadSgkAccrualDeclaration" },
      { title: "KDV 1", value: "uploadVatOneDeclaration" },
      { title: "KDV 2", value: "uploadVatTwoDeclaration" },
      { title: "Muhtasar", value: "uploadConciseDeclaration" },
      { title: "Damga Vergisi", value: "uploadStampDutyDeclaration" },
      { title: "Gecici Vergi", value: "123123" },
    ],
    calculation: [
      { title: "Kur Değerleme", value: "34" },
      { title: "Örtülü Sermaye", value: "hiddenCapital" },
      { title: "Nakdi Sermaye Artışı", value: "capitalCashIncreaseCalculation" },
      { title: "180-280 Hesaplama", value: "insuranceCalculation"},
      { title: "Finansman Kısıtlaması" , value: "fundingConstraints"},
      { title: "Kira", value: "rentCalculation"},
      { title: "Ücret Tahakkuk", value: "accruedFee"},
      { title: "Kur Etkisi", value: "currencyImpact"},
      { title: "Geçmiş Yıl Zararları Hesaplama", value: "financialLossCalculation" },
      { title: "KKEG", value: "kkeg"},
      { title: "Amortisman", value: "depreciationDidero"},
      { title: "Krediler", value: "creditCalculation"},
      //{ title: "Reeskont", value: "33" },
      //{ title: "Faiz Gelir Tahakkuk", value: "35" },
      //{ title: "Faiz Gider Tahakkuk", value: "36" },
      //{ title: "Harcirah", value: "37" },
      //{ title: "İhbar ve Kıdem Tazminatı", value: "38" },
      //{ title: "Vergi Hesaplama", value: "taxCalculation" },
      // { title: "Stok", value: "42" },
      // { title: "Çekler", value: "43" },
      // { title: "Şüpheli Alacaklar", value: "44" },
    ],
    yearEndTransaction: [
      { title: "Yıllara Sari Listesi", value: "45" },
      { title: "Yapılmakta Olan Yatırımlar", value: "46" },
      { title: "ATİK Satışı", value: "47" },
      { title: "Yenileme Fonu", value: "48" },
      { title: "İştirak Temettü Geliri", value: "49" },
      { title: "Bağlı Ortaklık Geliri", value: "50" },
      { title: "Özvarlık Hesaplama", value: "51" },
      { title: "Transfer Fiyatlandırması", value: "52" },
      { title: "Kar Dağıtım Tablosu", value: "53" },
      { title: "İndirimli Kurumlar Vergisi Hesaplaması", value: "54" },
      { title: "Dipnot", value: "55" },
      { title: "Yabancı Para Pozisyonu", value: "56" },
      { title: "Kurum Ortakları Listesi", value: "57" }
    ],
    financialStatements: [
      { title: "Bilanço", value: "financialStatement" },
      { title: "Gelir Tablosu", value: "incomeStatement" },
    ],
    analysis: [
      { title: "Dinamik", value: "61" },
      { title: "Bilanço Analizleri", value: "62" },
      { title: "Gelir Tablosu Analizleri", value: "63" },
      { title: "Rasyo", value: "64" },
      { title: "Konsolidasyon", value: "65" }
    ],
    budget: [
      { title: "Gelir Bütçesi", value: "66" },
      { title: "Gider Bütçesi", value: "67" }
    ],
    specialReport: [{ title: "Mapping", value: "68" }],
    fixedAsset: [
      { title: "Dosya Yönetimi", value: "folderNotification" },
      { title: "Proje Ekle/Güncelle", value: "createProject" },
      { title: "Mizan Veri Girişi", value: "mizan" },
      { title: "Amortisman Veri Girişi", value: "amortismanUpload" },
      { title: "Tüfe Görüntüle", value: "tufe" },
      { title: " Mizan Görüntüle", value: "inflationAccountingTrialBalance" },
      { title: "Amortisman Görüntüle", value: "inflationDepreciation" },
      { title: "Ertelenmiş Vergi", value: "deferredTax" },
      { title: "Mizan Rapor Görüntüle", value: "inflationAccountingReporting" }
    ],
    helperPrograms: [
      { title: "Mizan", value: "mizanView" },
      { title: "Adat", value: "interestNumber" },
      { title: "Amortisman", value: "depreciation" },
      { title: "Yaşlandırma", value: "aging" },
      { title: "Muavin ve E-Fatura Kontrolü", value: "muavinAndEbillComparison"},
    ]
  }),
  created() {
    let that = this;
    if (window.$cookies.get("loggedUser") == null) {
      let loggedUserDefault = {
        userId: null,
        isloggedin: false,
        companyId: null,
        companyName: null,
        token: null,
        userName: null
      }
      window.$cookies.remove('selectedItem')
      window.$cookies.remove('loggedUser')
      this.$store.dispatch("updateLoggedUserInfo", loggedUserDefault);
      return this.$router.push("/loginPage")
    }
    if (window.$cookies.get("selectedItem") == null || window.$cookies.get("selectedItem") == 'null') {
      openSnackbar("error", "Lütfen Proje Seçiniz", 2000)
    } else {
      this.isSettings = true
    }
    let companyName = window.$cookies.get("loggedUser").companyName
    that.compName = companyName
    this.loadData();
  },
  watch: {
    
  },
  methods: {
    loadData(){
      let that = this;
      sendRequest(Enum.requestType.POST,"selectedProject",null,{tableName : "t_project"},                         
        function (response) {
          if (response.data.data.data.length > 0) {
            for (let i = 0; i < response.data.data.data.length; i++) {
              if (response.data.data.data[i].explain.toLowerCase() != 'beyanname' && response.data.data.data[i].explain.toLowerCase() != 'fatura') {
                response.data.data.data[i].visibleProp = response.data.data.data[i].fakeDate + "-" + response.data.data.data[i].explain
              } else {
                response.data.data.data[i].visibleProp = response.data.data.data[i].explain
              }
            }
            that.$store.dispatch("setProjectInformation", {items : response.data.data.data });
            that.items = response.data.data.data 
            // if(window.$cookies.get("selectedItem") == null){
            //   alert("Veriniz mevcut değildir");
            //   openSnackbar("error","Lütfen Proje Kodu Tanımlayınız")
            //   that.$router.push("createProject")
            // }
          } 
          // else {
          //   alert("Veriniz mevcut değildir");
          //   openSnackbar("error","Lütfen Proje Kodu Tanımlayınız")
          //   that.$router.push("createProject")
          // }
        }
      )
    },
    downloadZipFile() {
      axios({
        method: "get",
        url: "zipToDownload",
        responseType: "arraybuffer",
      })
        .then((response) => {
          this.forceFileDownload(response);
        })
        .catch(() => console.log("error occured"));
    },
    forceFileDownload(response) {
      console.log(response)
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "taslak Didero.zip"); //or any other extension
      document.body.appendChild(link);
      link.click();
    },
    test(route) {
      if ("/" + route != this.$route.fullPath) {
        this.$router.push("/" + route)
      }
    },
    selectCompany() {
      
      let selectedUser = window.$cookies.get("loggedUser")
      let selectedProject = window.$cookies.get("selectedItem")
      let loggedUserDefault = {
        userId: selectedUser.userId,
        isloggedin: true,
        companyId: null,
        companyName: null,
        token: null,
        userName: selectedUser.userName
      }
      window.$cookies.remove('selectedItem')
      this.$store.dispatch("updateLoggedUserInfo", loggedUserDefault);
      this.$router.push("/landing")
    },
    logOut(){
      
      let loggedUserDefault = {
        userId: null,
        isloggedin: false,
        companyId: null,
        companyName: null,
        token: null,
        userName: null
      }
      let loggedUser = window.$cookies.get("loggedUser")
      window.$cookies.remove('selectedItem')
      this.$store.dispatch("updateLoggedUserInfo", loggedUserDefault);
      userRequest.logOut(loggedUser.userId, loggedUser.companyId)
        .then((res) => {
          if (res.data.success) {
            this.$router.push("/loginPage")
          }
        });
    },
    clearProjectSelection() {
      
      let selectedProject = window.$cookies.get("selectedItem")
      let value = this.$store.dispatch("setProjectInformation", selectedProjectDefault);
      this.projectElements.set(value)
    },
    restrictIfProjectNotSelectedFunction() {
      
      if (this.selectedItem == null || this.selectedItem == 'null') {
        return false
      } else {
        return true
      }
    },
  },
  computed: {
    projectElements: {
      get () {
        if ((window.$cookies.get("selectedItem") == 'null' || window.$cookies.get("selectedItem") == null)) {
          this.muavinUpload = true
          this.isDisabled = true
          this.isBeyanname = true
          this.isFatura = true
        }
        if (window.$cookies.get("selectedItem") != 'null' && window.$cookies.get("selectedItem") != null) {
          if (window.$cookies.get("selectedItem").explain.toLowerCase() == "beyanname" || window.$cookies.get("selectedItem").explain.toLowerCase() == "fatura" || window.$cookies.get("selectedItem").explain.toLowerCase() == "jet test") {
            this.isDisabled = true
          }
          if (window.$cookies.get("selectedItem").explain.toLowerCase() != "beyanname") {
            this.isBeyanname = true
          }
          if (window.$cookies.get("selectedItem").explain.toLowerCase() != "fatura" && window.$cookies.get("selectedItem").explain.toLowerCase() != "jet test") {
            this.isFatura = true
          }
        }
        return window.$cookies.get("selectedItem");
      },
      set (value) {
        this.$store.state.selectedItem = value;
        window.$cookies.set("selectedItem", value);
        window.location.reload(true);
      }
    },
  }
};
</script>
