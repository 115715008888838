<template>
  <v-flex justify="center">
    <v-row justify="center" >  
      <v-col class="shrink" >
        <v-card :width="dynamicElement[0].width" :disabled="dynamicElement[0].disabled" :key="renderKey">
          <v-list-item  :style="dynamicElement[0].itemStyle">
            <v-list-item-content  :style="dynamicElement[0].contentStyle" >
              <v-list-item-title class="headline" :style="dynamicElement[0].titleStyle"
                >{{dynamicElement[0].interfaceName}}
                <v-icon v-if="dynamicElement[0].iconNone != true"> {{dynamicElement[0].iconName ? dynamicElement[0].iconName : 'mdi-upload'}} </v-icon>
                <video-component v-if="dynamicElement[0].videoDocumentationPath" :videoSrc="dynamicElement[0].videoDocumentationPath" style="position: absolute; right: 10px;top:10px;" styleTypeIcon="height:30px" :iconColorWhite="dynamicElement[0].playerColorWhite ? true : false"></video-component>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <layout
            :dynamic-elements="dynamicElement"
            :layout-elements="dynamicElement[0].layout"
            :model="model"
          ></layout>
          <v-card-text class="text--primary">
            <div>
              {{ dynamicElement[0].inputInformationText }}
              <br />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-flex>

</template>

<script>
import layout from "../container/layout.vue";
import { hashCode } from "../helper/elementHelper.js";
import videoPlayerComponent from "../container/videoPlayerComponent.vue";
export default {
  components: { layout: layout, "video-component":videoPlayerComponent },
  props: {
    dynamicElement : {
      default: () => {}
    },
    model : {}
  },
  data() {
    return {
      renderKey: hashCode(),
      //declarationDate: içerisinde dynamic element boş gönderildiği için dynamicElement[0] undefined oluyor .width .layout .disabled okunamıyor hata veriyor konsolda (konsol hatası)
      //defaultDynamicElement: [{ width: 0, disabled: false, interfaceName: "", layout: [], inputInformationText: "" }],
    };
  },
  computed: {
    responsive() {
      if (this.$vuetify.breakpoint.name == "xs") {
        return { width: 790, height: 200 };
      } else if (this.$vuetify.breakpoint.name == "sm") {
        return { width: 790, height: 200 };
      } else if (this.$vuetify.breakpoint.name == "md") {
        return { width: 790, height: 200 };
      } else if (this.$vuetify.breakpoint.name == "lg") {
        return { width: 400, height: 150 };
      } else if (this.$vuetify.breakpoint.name == "xl") {
        return { width: 500, height: 200 };
      } else {
        return 0;
      }
    },
  },
  watch: {
    dynamicElement: {
      handler: function () {},
      deep: true,
    }
  },

  methods: {
  },
};
</script>

<style scoped>
.row{margin: 0;}</style>