<template>
    <v-app>
      <div class="header-div">
        <h3 class="header-h3">Ters Bakiye Kontrolü</h3>
      </div>
      <table-component :dynamic-element="tableElements" :model="model" class="small-page-layout"/>    
      <Loader></Loader>
      <!-- <snackbar-component /> -->
    </v-app>
  </template>
  
  <script>
  import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
  import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
  import Enum from "../../../frontend/components/Enum.json";
  import Loader from "../../../frontend/components/atoms/loader";
  import XLSX from "xlsx";
  import {
  openSnackbar,
  showLoader,
  openTablePage,
  getTable,
  deleteItem,
  closeDialog,
  excelDownloadDefault,
  //getTableModel,
  //showDialog,
  hideLoader,
    updateItem,
  saveItem
  //hashCode
  } from "../../../frontend/components/atoms/helper/elementHelper.js";
  import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
  import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";
  import {axiosUploadTypeArrayBuffer, checkProject} from "../../helper/uploadHelperForData"
  
  export default {
    components: {
      "table-component": tableComponent,
      "upload-component": uploadComponent,
      Loader: Loader,
      "snackbar-component": snackbarComponent,
      },
      data() {
          return {
            tableElements: {},
            model: {},
            renderKey: 0,
            accountCodes: []
          };
      },
      created() {
        checkProject();
        this.loadData()
      },
      watch: {},
      methods: {
          loadData() {
            let that = this
            this.tableElements = {
            sortBy: [],
            sortDesc: false,
            renderKey: 0,
            type: "table",
            tableHeight : 430,
            name: "mizanTable",
            class: "",
            infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
            excelDownloadBtnText: "İndir",
            deleteDialogVisible : true,
            hidePagination: true,
            filteredColumn: {},
            filteredColumns: true,
            selectionUrl: "deficitBalance",
            crudRequestObj : {
            placeOfAction : "deficitBalance",
            projectId : window.$cookies.get("selectedItem").id,
            company_id: window.$cookies.get("selectedItem").companyId,
            auditDate: window.$cookies.get("selectedItem").auiditDate,
            preriorDate: window.$cookies.get("selectedItem").preriorDate,
            },
            initialFilter:{
              [Enum.initialFilter.AND] :
              [
                {
                  projectId: {
                    [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id
                  },auditDate: {
                    [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").auiditDate
                  },preriorDate: {
                    [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").preriorDate
                  }
                }
              ]
            },
                toolbars: [
                  {
                      type: Enum.layoutType.div,
                      name: "toolbarsForm",
                      inputElements: [
                      [
                        {
                          id: "excelDownload",
                          type: "v-btn",
                          variant: "btn",
                          name: "excelDownload",
                          inputText: "excelDownloadBtnText",
                          style:"height: 25px !important;",
                          divStyle: "",
                          icon: "mdi-file-excel",
                          btnTypeText: false,
                          color: "success",
                          prependIcon: "",
                          classTag: "",
                          onClick: function(thisData, model, dynamicElements) {
                            showLoader('Dosyanız Hazırlanıyor')
                            sendRequest(Enum.requestType.POST,"deficitBalance","",
                              {
                                excelDownload : true,
                                choice : "deficitBalanceTable",
                                cookieData: window.$cookies.get("selectedItem")
                              },
                              function (response) {
                                if (response.data.data.data != true) {
                                  openSnackbar('error', "İndirilecek Veri Bulunamadı", 3000)
                                  hideLoader();
                                  return false
                                }
                                let zipName = "Ters Bakiye Excel Rapor"
                                axiosUploadTypeArrayBuffer("allExcelZipDownload", "deficitBalanceTable", zipName, "Excel İndirildi")
                              },
                              function (error) {
                                openSnackbar(
                                  "error",
                                  error.response.data.error[0].detail.message
                                );
                                hideLoader();
                              }
                            )
                          },
                        }
                      ],
                    ],
                  }
                ],
                headers: [
                  { text: "Hesap Kodu", value: "accountCode", width: "100" , divider: true,},
                  { text: "Hesap Adı", value: "accountName", width: "100" , divider: true,},
                  { text: "Bakiye", value: "balance", width: "100", dataType: "currency" , divider: true,},
                  { text: "Hesabın Çalışma Şekli", value: "accountDebtOrCredit", width: "100" , divider: true,},
                ],
            }
          }
      }
  }
  
  </script>