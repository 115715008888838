<template>
  <v-app>
    <table-component :dynamic-element="tableElements" :model="model" />    
    <Loader></Loader>
    <snackbar-component />
  </v-app>
</template>

<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import XLSX from "xlsx";
import {
  openSnackbar,
  showLoader,
  openTablePage,
  getTable,
  deleteItem,
  closeDialog,
  excelDownloadDefault,
  //getTableModel,
  //showDialog,
  hideLoader,
    updateItem,
  saveItem
  //hashCode
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";
import {axiosUploadTypeArrayBuffer} from "../../helper/uploadHelperForData"

export default {
  components: {
    "table-component": tableComponent,
    "upload-component": uploadComponent,
    Loader: Loader,
    "snackbar-component": snackbarComponent,
  },

  data() {
    return {
      tableElements: {},
      model: {},
      renderKey: 0,
      //selectedRows: [],
      required : (value) => !!value || "Gerekli.",

    };
  },

  created() {
    this.loadData();
  },
  watch: {},
  methods: {
    loadData() {
      let that = this;
      this.tableElements = {
        sortBy: [],
        sortDesc: false,
        renderKey: 0,
        type: "table",
        tableHeight : 420,
        name: "amortismanTable",
        class: "",
        infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
        
        hidePagination: true,
        selectedRows: [],
        filteredColumn: {},
        selectionUrl: undefined,
        showDefaultUpdateButon : true,
        showDefaultDeleteButon : true,
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
              [
               {
                  id: "amorti",
                  type: "v-file-input",
                  style:"height: 25px!important; width: 200px;",
                  label: "Dosyanızı Seçiniz",
                  inputHeader: "Amorti",
                  name: "amorti",
                  multiple: true,
                  onChange: async function(thisData, model, dynamicElements) {
                    
                    // let files = model.conciseFile;
                    // if (model.conciseFile.length > 0) {
                    //   showLoader("Dosyanız Yükleniyor. Lütfen Bekleyiniz...");
                    //   uploadHelperFormDataQueue(files, "declarationupload", Enum.requestType.POST, {tableName : "conciseDeclarationTable"}, function (response) {
                    //     if (response.data.data.value == "Hata") {
                    //       openSnackbar( "error", "Taslak ile form uyumlu değildir. Lütfen yeniden yükleyiniz." );
                    //     } else {
                    //       openSnackbar("success", "Dosya Yüklendi");
                    //       openTablePage("conciseDeclarationTable");
                    //     }
                    //     hideLoader();
                    //   })
                    // }
                  }
                }
              ],
              [
              ],
              [
                {
                  id : "calculateDateAmortisman",
                  type : "v-date-picker",
                  variant : "btn",
                  name : "calculateDateAmortisman",
                  inputText : "Amortisman",
                  label : "Hesaplama Tarihi",
                  divStyle:"",
                  style:"height: 25px!important; width: 200px;",
                  btnTypeText: false,
                  color : "primary",
                  prependIcon : "",
                  classTag : "",
                  onClick : function(thisData,model,dynamicElements) {
                    console.log(thisData,model,dynamicElements);
                  }
                }
              ],
              [
                  {
                    id : "convertToAmortisman",
                    type : "v-btn",
                    variant : "btn",
                    name : "convertToAmortisman",
                    inputText : "Hesapla",
                    divStyle:"",
                    style:"height: 25px !important; margin-top: 25%",
                    btnTypeText: false,
                    color : "primary",
                    prependIcon : "",
                    classTag : "",
                    onClick : function(thisData,model,dynamicElements) {
                      //showLoader("Amortisman Hesaplanıyor.Lütfen Bekleyiniz.");
                      if (model.calculateDateAmortisman === undefined) {
                        openSnackbar("error","Girilen Tarih Değeri Boş Olamaz");
                        return false;
                      }
                      let files = model.amorti[0];
                      let choiceData = {
                          choice : "depreciation", 
                          headerAddFlag : true, 
                          amortismanDate :model.calculateDateAmortisman,
                          fileName : files.name,
                          fileType : 'depreciation',
                          excelDownload: true
                        }
                      showLoader('Dosyanız Hazırlanıyor')
                      uploadHelperFormDataQueue([files], "convertToAmortisman", Enum.requestType.POST, choiceData, 
                      function (response) {
                        let cohice = ''
                        if (response.data.data.errorExcel != undefined) {
                          cohice = 'errorExcelDepreciation'
                        }else{
                          cohice = 'depreciation'
                        }
                        let zipDate = model.calculateDateAmortisman.replaceAll("/", "_")
                        let zipName = that.$store.state.loggedUser.companyName + " " + zipDate
                        axiosUploadTypeArrayBuffer("amortiExcelZipDownload", cohice, zipName, "Excel İndirildi")
                        },
                      function (error) {
                          openSnackbar(
                            "error",
                            error.response.data.error[0].detail.message
                          );
                        }
                      )
                    }
                  },
                ],
            ],
          }
        ],
        headers: [
          // { text: "Muhasebe Kodu", value: "accountingCode", width: "100" , search: true , sortable: true},
          // { text: "Sabit Kıymet Kodu", value: "fixedAssetCode", width: "100" , search: true , sortable: true},
          // { text: "Sabit Kıymet Adı", value: "fixedAssetName", width: "100" , search: true , sortable: true },
          // { text: "Aktifleştirilme Tarihi", value: "activate_date", width: "100" , search: true , sortable: true , dataType: "date"},
          // { text: "Alış Tarihi", value: "date", width: "100" , search: true , sortable: true , dataType: "date"},
          // { text: "Alış Değeri", value: "value", width: "100" , search: true , sortable: true , },
          // { text: "Kur Farkı", value: "exchangeRate", width: "100" , search: true , sortable: true , },
          // { text: "Finansman Maliyeti", value: "financingCost", width: "100" , search: true , sortable: true , },
          // { text: "Yeniden Değerleme", value: "revaluation", width: "100", search: true , sortable: true , },
          // { text: "Yöntem", value: "method", width: "100" , search: true , sortable: true },
          // { text: "Süre", value: "time", width: "100" , search: true , sortable: true }, 
        ],
        columns: {

          accountingCode: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Muhasebe Kodu",
            rules : [this.required]

          },
          fixedAssetCode: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Sabit Kıymet Kodu",
            rules : [this.required]

          },
          fixedAssetName: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Sabit Kıymet Adı",
            rules : [this.required]

          },
          activate_date: {
            type: "v-date-picker",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Aktifleştirilme Tarihi",
            rules : [this.required],
          },
          date: {
            type: "v-date-picker",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Alış Tarihi",
            rules : [this.required]
          },
          value: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Alış Değeri",
            rules : [this.required],
            variant : "number",


          },
          exchangeRate: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Kur Farkı",
            rules : [this.required]

          },
          financingCost: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Finansman Maliyeti",
            rules : [this.required]

          },
          revaluation: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Yeniden Değerleme",
            rules : [this.required]

          },
          method: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Yöntem",
            rules : [this.required]

          },
          time: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Süre",
            rules : [this.required]

          },
        },
        data: [],
        itemKey: "id",
        // footer: [
        //   {
        //     type: Enum.layoutType.div,
        //     name: "footerForm",
        //     inputElements: [
        //       [
        //         {
        //           id: "pageSize",
        //           type: "v-text-field",
        //           variant: "number",
        //           name: "pageSize",
        //           label: "Görünecek Satır Sayısı",
        //           value: "",
        //           placeHolder: "",
        //           style: "width: 45%; margin-left: 5%",
        //           classTag: "",
        //           onChange: function(thisData, model, dynamicElements) {
        //             openTablePage("amortismanTable")
        //             var tableObject = getTable("amortismanTable");
        //             for (let i = 0; i < tableObject.data.length; i++) {
        //               tableObject.data[i].kebir = tableObject.data[i].accountCode.substring(0, 3);
        //             }
        //             console.log(thisData);
        //             console.log(dynamicElements);
        //             console.log(model);
        //           },
        //         },
        //       ],
        //       [
        //         {
        //           id: "currentPageNo",
        //           type: "v-pagination",
        //           variant: "pagination",
        //           name: "currentPageNo",
        //           divStyle: "",
        //           style: "width:850px;",
        //           classTag: "my-1",
        //           length: "pagesCount",
        //           onInput: function(thisData, model, dynamicElements) {
        //             openTablePage("amortismanTable")
        //             console.log(thisData);
        //             console.log(dynamicElements);
        //             console.log(model);
        //           },
        //         },
        //       ],
        //       [
        //         {
        //           id: "infoTotalRowCountHeaderAssistantTable",
        //           type: "p",
        //           variant: "p",
        //           name: "infoTotalRowCountHeaderAssistantTable",
        //           inputText: "infoTotalRowCountHeaderAssistantTable",
        //           divStyle: "",
        //           style: "font-size: 12px;font-weight:500;margin:3px 0;color:#666A6F;margin-right: 2%;margin-left: 60%;",
        //           classTag: ""
        //         },
        //         {
        //           id: "infoTotalRowCountAssistantTable",
        //           type: "p",
        //           variant: "p",
        //           name: "infoTotalRowCountAssistantTable",
        //           inputText: "itemCount",
        //           divStyle: "",
        //           style: "font-size: 15px;font-weight:500;margin:3px 0;color:#666A6F;margin-left: 82%;",
        //           classTag: ""
        //         },
        //       ],
        //     ],
        //   },
        // ]
      };
     
    }
  },
};
</script>

<style scoped>
::v-deep .threedot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 300px;
}
::v-deep .threedot:hover {
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}
</style>
