<template>
    <v-app>
        <div class="header-div">
          <h3 class="header-h3">Fiş Arama</h3>
        </div>
        <table-component :dynamic-element="tableElements" :model="model" />
        <Loader></Loader>
        <snackbar-component />
    </v-app>
</template>
<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";

import {
  openSnackbar,
  showDialog,
  closeDialog,
  openTablePage,
  showLoader, 
  hideLoader 
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import {axiosUploadTypeArrayBuffer} from "../../helper/uploadHelperForData"
export default {
    components: {
        "table-component": tableComponent,
        "upload-component": uploadComponent,
        Loader: Loader,
        "snackbar-component": snackbarComponent,
    },

    data() {
        return {
          tableElements: {},
          model: {},
          renderKey: 0,
        };
    },
    created() {
        this.loadData();
    },
    watch: {},
    methods: {
    loadData() {
      this.tableElements = {
        sortBy: [],
        sortDesc: false,
        renderKey: 0,
        type: "table",
        tableHeight : 430,
        name: "receiptNumber",
        class: "",
        infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
        excelDownloadBtnText: "İndir",
        deleteDialogVisible : true,
        hidePagination: true,
        //selectedRows: [],
        filteredColumn: {},
        filteredColumns: true,
        //selectionUrl: "getMizanView",
        // showDefaultDeleteButon : true,
        // deleteUrl: "deleteTable",
        // saveUrl : "updateTable",
        // updateUrl : "updateTable",
        // deleteBtnText: "Sil",
        // saveBtnText : 'Yeni Kayıt',
        // saveBtnSettings : {
        //   color: "blue",
        //   style:"height: 25px !important; ",
        // },
        // deleteBtnSettings : {
        //   color: "error",
        //   style:"height: 25px !important; ",
        // },
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
              [
               {
                  id : "dateOfReceipt",
                  type : "v-date-picker",
                  variant : "text",
                  name : "dateOfReceipt",
                  label : "Fiş Tarihi",
                  value : "",
                  prependIcon : "fas fa-at",
                  placeHolder : "",
                  style : "width: 260px;",
                  classTag : "",
                  readonly: true,
                  onClick : function(thisData,model,dynamicElements) {
                  }
                },
              ],
              [
                {
                  id : "receiptNumber",
                  type : "v-text-field",
                  variant : "text",
                  name : "receiptNumber",
                  label : "Fiş Numarası",
                  value : "",
                  placeHolder : "",
                  style : "width: 240px;",
                  classTag : "",
                },
              ],
              [
                {
                  id : "get",
                  type : "v-btn",
                  variant : "btn",
                  name : "get",
                  inputText : "Getir",
                  divStyle:"",
                  style:"height: 25px !important; margin-top: 28%",
                  btnTypeText: false,
                  color : "primary",
                  prependIcon : "",
                  classTag : "",
                  onClick : function(thisData, model, dynamicElements) {
                    showLoader('Hesaplamalar Yapılıyor')
                    sendRequest(Enum.requestType.POST, "findReceipts", "", 
                    {
                      dateOfReceipt: model.dateOfReceipt, 
                      receiptNumber: model.receiptNumber, 
                      cookieData: window.$cookies.get("selectedItem")
                    }, 
                    function (response) {
                      dynamicElements.data = response.data.data.data;
                      model.itemCount = dynamicElements.data.length;
                      model.pagesCount = dynamicElements.data.length / 50;
                      dynamicElements.forceRender();
                      hideLoader();
                    },
                      function (error) {errFunc(error);}
                    )
                  }
                }
              ],
            ],
          }
        ],
        headers: [
          { text: "Kebir", value: "kebir", width: "50", divider: true },
          { text: "Hesap Kodu", value: "accountCode", width: "100", divider: true, },
          { text: "Hesap Adı", value: "accountName", width: "100", divider: true, },
          { text: "Tarih", value: "billDate", dataType: "date", width: "100", divider: true, },
          { text: "Fiş No", value: "receiptNumber", width: "100", divider: true },
          { text: "Borç", value: "debt", width: "100", dataType: "currency", divider: true, },
          { text: "Alacak", value: "credit", width: "100", dataType: "currency", divider: true, },
          { text: "Bakiye", value: "balance", width: "100", dataType: "currency", divider: true, },
          { text: "Açıklama", value: "explanation", width: "200", class: "threedot", divider: true },
        ],
      };
    }
  },
}

    

</script>