<template>
    <div>
        <v-snackbar
          v-for="(snackbar, index) in snackbars.filter(s => s.showing)"
          :key="snackbar.text + Math.random()"
          v-model="snackbar.showing"
          :timeout="snackbar.timeout"
          :color="snackbar.color"
          top
          right
          :style="`top: ${(index * 60) + 8}px`"
        >
          <div style="display: flex;align-items: center;justify-content: space-between;"> 
            {{snackbar.text}}
            <v-btn text @click="snackbar.showing = false" >
            Kapat
            </v-btn>
          </div>
          
        </v-snackbar>
    </div>
</template>

<script>
import { mapState } from '../../../../vue/node_modules/vuex'
export default {
    data () {
        return {

        }
    },
    computed : {
        ...mapState(['snackbars'])
    }
}
</script>