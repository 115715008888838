<template>
    <div :key="dynamicElement.renderKey" :v-model="dynamicElement">
        <table-header :dynamicElement="dynamicElement" :model="model[dynamicElement.name]"/>
        <table-body :dynamicElement="dynamicElement" :model="model[dynamicElement.name]"/>
        <table-footer :dynamicElement="dynamicElement" :model="model[dynamicElement.name]"/>
        
        <div>
            <dialog-component v-if="dynamicElement.dialogVisible" :dynamic-element="dynamicElement" :model="model" />
        </div>
<!--         
        <div :key="dynamicElement.renderKey2" v-if="dynamicElement.globalDialogInitialized">
            <dialog-component v-if="dynamicElement.globalDialogVisible" :dynamic-element="dynamicElement" :model="model" />
        </div> -->

        <div v-if="dynamicElement.globalDialogInitialized" :key="dynamicElement.renderKey2"> 
            <!-- <div v-for="(globalDialog,i) in dynamicElement.globalDialogs" :key="i" > -->
                <dialog-component v-if="dynamicElement.globalDialogs.length > 0" :dynamic-element="dynamicElement.globalDialogs[dynamicElement.globalDialogs.length - 1]" :model="model" />
            <!-- </div> -->
        </div> 

        <div v-if="dynamicElement.snackbarInitialized">
            <snackbar-component/>
        </div>
    </div>
</template>

<script>
import tableHeader from "./table/tableHeader";
import tableBody from "./table/tableBody";
import tableFooter from "./table/tableFooter";
import Enum from "../../Enum.json"
import dialogComponent from "./dialogComponent.vue"
import {includeTable,openTablePage,hashCode,updateItem, saveItem,deleteItem, renderOrganizerFunction} from "../../atoms/helper/elementHelper.js"
import snackbarComponent from "./snackbarComponent.vue"

export default {
    components: { 'table-header' : tableHeader , 'table-body' : tableBody, 'table-footer' : tableFooter, 'dialog-component':dialogComponent, 'snackbar-component':snackbarComponent},
    props: {
        dynamicElement : {
          type: Object,
          default: () => {}
        },
        model : {}
    },

    data() {
        return {
            renderKey : hashCode(),
            sortableBy: " "
        };
    },
    created(){
        if (!Object.keys(this.dynamicElement).includes('clientEvents')) {
            this.dynamicElement.clientEvents = {}
        }
        if (this.dynamicElement.clientEvents.onBeforeunload != undefined) {
            this.dynamicElement.clientEvents.onBeforeunload(this.dynamicElement);
        }

        if (this.dynamicElement.data == undefined) {
            this.dynamicElement.data = []
        }
        this.modelControl();
        this.setCrudBtnControl();
        this.getTableData();

        this.dynamicElement.renderKey2 = hashCode();

        this.dynamicElement.globalDialogInitialized = false;
        
        this.dynamicElement.globalDialogs = [];
        if (this.$store.state.globalDialogInitialized == false) {
            this.dynamicElement.globalDialogInitialized = true;
            this.$store.state.globalDialog = this.dynamicElement
        }
        this.$store.state.globalDialogInitialized = true;

        this.dynamicElement.snackbarInitialized = false;

        if (this.$store.state.snackbarInitialized == false) {
            this.dynamicElement.snackbarInitialized = true;
        }
        this.$store.state.snackbarInitialized = true;

        // if (this.dynamicElement.clientEvents.onafterUpload != undefined) {
        //     this.dynamicElement.clientEvents.onafterUpload();
        // }
    },
    computed:{
        
    },
    watch:{
        "dynamicElement.data":{
            handler: function() {
                if (!Object.keys(this.dynamicElement).includes('clientEvents')) {
                    this.dynamicElement.clientEvents = {}
                }
                if (this.dynamicElement.clientEvents.dataChanged != undefined) {
                    this.dynamicElement.clientEvents.dataChanged();
                }
            },
            deep : true,
        },
        //"dynamicElement.sortBy":{
        //    handler: function() {
        //        if (this.dynamicElement.sortBy != null) {
        //            this.sortableBy = this.dynamicElement.sortBy[0]
        //            openTablePage(this.dynamicElement.name)
        //        }
        //    },
        //    deep : true,
        //},
        //"dynamicElement.sortDesc":{
        //    handler: function() {
        //        if (this.dynamicElement.sortDesc != null) {
        //            if(this.sortableBy == this.dynamicElement.sortBy[0]){
        //                openTablePage(this.dynamicElement.name)
        //            }else{
        //              this.sortableBy = 0
        //            }
        //        }
        //    },
        //    deep : true,
        //},
        dynamicElement: {
            handler: function() {
                if (this.dynamicElement.globalDialogVisible == true) {
                }
                this.model[this.dynamicElement.name].dialogVisible = this.dynamicElement.dialogVisible
                if (this.dynamicElement.dialogVisible == false) {
                    this.dynamicElement.dialogInfo = undefined;
                }
                
                this.model[this.dynamicElement.name].infoTotalRowCountHeaderAssistantTable= this.dynamicElement.infoTotalRowCountHeaderAssistantTable
                this.model[this.dynamicElement.name].saveBtnText = this.dynamicElement.saveBtnText
                this.model[this.dynamicElement.name].updateBtnText = this.dynamicElement.updateBtnText
                this.model[this.dynamicElement.name].deleteBtnText = this.dynamicElement.deleteBtnText
                this.model[this.dynamicElement.name].searchBtnText = this.dynamicElement.searchBtnText
            },
            deep : true,
        },
    },
    
    methods:{
        getTableData (){
            //default value assignment
            let x = this.model
            let y = this.dynamicElement
            if (this.model[this.dynamicElement.name] == undefined) {
                this.model[this.dynamicElement.name] = {}
            }

            this.model.dialogVisible = false
            this.model[this.dynamicElement.name].infoTotalRowCountHeaderAssistantTable= this.dynamicElement.infoTotalRowCountHeaderAssistantTable
            
            this.model[this.dynamicElement.name].saveBtnText =  this.dynamicElement.saveBtnText == undefined ? "Yeni Kayıt" : this.dynamicElement.saveBtnText
            this.model[this.dynamicElement.name].updateBtnText = this.dynamicElement.updateBtnText == undefined ? "Güncelle" : this.dynamicElement.updateBtnText
            this.model[this.dynamicElement.name].deleteBtnText = this.dynamicElement.deleteBtnText == undefined ? "Sil" : this.dynamicElement.deleteBtnText
            this.model[this.dynamicElement.name].searchBtnText = this.dynamicElement.searchBtnText == undefined ? "Ara" : this.dynamicElement.searchBtnText
            this.model[this.dynamicElement.name].clearSearchBtnText = this.dynamicElement.clearSearchBtnText == undefined ? "Temizle" : this.dynamicElement.clearSearchBtnText
            this.model[this.dynamicElement.name].filterBtnText = this.dynamicElement.filterBtnText == undefined ? "Filtre" : this.dynamicElement.filterBtnText
            this.model[this.dynamicElement.name].excelDownloadBtnText = this.dynamicElement.excelDownloadBtnText == undefined ? "İndir" : this.dynamicElement.excelDownloadBtnText
            this.model[this.dynamicElement.name].emailBtnText = this.dynamicElement.emailBtnText == undefined ? "Email" : this.dynamicElement.emailBtnText
            
            
            this.model[this.dynamicElement.name].pageSize = this.dynamicElement.pageSize == undefined ? 50 : this.dynamicElement.pageSize
            this.model[this.dynamicElement.name].currentPageNo = this.dynamicElement.currentPageNo == undefined ? 1 : this.dynamicElement.currentPageNo
            this.model[this.dynamicElement.name].pagesCount = this.dynamicElement.pagesCount == undefined ? 1 : this.dynamicElement.pagesCount
            this.model[this.dynamicElement.name].itemCount = this.dynamicElement.itemCount == undefined ? 1 : this.dynamicElement.itemCount

            this.dynamicElement.loadType = Enum.loadType.static

            //gereksiz render atmasın diye render organizer'ı çalıştırıyoruz
            let dyn = this.dynamicElement;
            this.dynamicElement.forceRender = renderOrganizerFunction(dyn,this.dynamicElement);
          
            //render//1 // force renderın tanımlandığı yer

            includeTable(this.dynamicElement.name, this.dynamicElement,this.model)

            if (this.dynamicElement.selectionUrl != undefined) {
                this.dynamicElement.loadType = Enum.loadType.ajax
                this.dynamicElement.data = []
                openTablePage(this.dynamicElement.name) //render//3 
            }
        },
        modelControl(){
            if (Object.keys(this.dynamicElement).includes("updateUrl") || Object.keys(this.dynamicElement).includes("saveUrl")) {
                this.dynamicElement.showSelecetVisible = true;
            }
            if (!Object.keys(this.dynamicElement).includes("footer")) {
                this.dynamicElement.footer =  [{
                    name :'x',
                    type : Enum.layoutType.div,
                    inputElements : []
                }]
            }
        },
        setCrudBtnControl(){
            if (Object.keys(this.dynamicElement).includes("saveUrl")) {
                if (this.dynamicElement.showDefaultSaveButon == undefined || this.dynamicElement.showDefaultSaveButon == true) {
                    this.setBtn('save',saveItem, "success")
                }
            }
            if (Object.keys(this.dynamicElement).includes("updateUrl")) {
                if (this.dynamicElement.showDefaultUpdateButon == undefined || this.dynamicElement.showDefaultUpdateButon == true) {
                    this.setBtn('update',updateItem, "success")
                }
            }
            if (Object.keys(this.dynamicElement).includes("deleteUrl")) {
                if (this.dynamicElement.showDefaultDeleteButon == undefined || this.dynamicElement.showDefaultDeleteButon == true) {
                    this.setBtn('delete',deleteItem, "error")
                }
            }
        },
        setBtn(typeName,typeFunction,buttonColor){
            if (!Object.keys(this.dynamicElement).includes("toolbars")) {
                this.dynamicElement.toolbars =  []
                this.dynamicElement.toolbars[0].inputElements = []
            }
            if (!Object.keys(this.dynamicElement.toolbars[0]).includes("inputElements")) {
                this.dynamicElement.toolbars[0].inputElements = []
            }
            if (this.dynamicElement[typeName + "BtnSettings"] == undefined) {
                this.dynamicElement[typeName + "BtnSettings"] = {};
            }
            this.dynamicElement.toolbars[0].inputElements.push(
                [
                    {
                      id : typeName,
                      type : "v-btn",
                      variant : "btn",
                      name : typeName,
                      inputText : typeName + "BtnText",
                      small: this.dynamicElement[typeName + "BtnSettings"].small ? this.dynamicElement[typeName + "BtnSettings"].small : false,
                      divStyle: this.dynamicElement[typeName + "BtnSettings"].divStyle == undefined ?  "" : this.dynamicElement[typeName + "BtnSettings"].divStyle,
                      style: this.dynamicElement[typeName + "BtnSettings"].style == undefined ?  "" : this.dynamicElement[typeName + "BtnSettings"].style,
                      btnTypeText: false,
                      color : this.dynamicElement[typeName + "BtnSettings"].color == undefined ?  "primary" : this.dynamicElement[typeName + "BtnSettings"].color,
                      prependIcon : this.dynamicElement[typeName + "BtnSettings"].prependIcon == undefined ?  "" : this.dynamicElement[typeName + "BtnSettings"].prependIcon,
                      classTag : this.dynamicElement[typeName + "BtnSettings"].classTag == undefined ?  "" : this.dynamicElement[typeName + "BtnSettings"].classTag,
                      onClick : function(thisData,model,dynamicElements) {
                        let options = {
                          showDialog : true
                        }
                        typeFunction(dynamicElements.name,dynamicElements.selectedRows,options,model)
                      }
                    },
                ],
            )
        }
    }
}
</script>