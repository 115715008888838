<template>
  <div id="container" class="mainContainer">
    <div class="leftBox" :style = projectDesign.color :key="renderKey">
      <div class="leftInnerBox" :style = projectDesign.logoBackground :key="renderKey">
        <img class="leftImg" :src= projectDesign.leftBoxLogo alt="Resim Yükleniyor">
      </div>
    </div>
    <div class="rightBox">
      <div class="rightInnerBox">
          <img class="rightImg" :src= projectDesign.rightBoxLogo alt="Resim Yükleniyor" />
          <h4 class="rightTitle">Lütfen Hesabınızı Giriniz</h4>
          <v-form class="rightInputArea" ref="form" v-model="valid" lazy-validation>
            <v-text-field  
              class="rightInput" 
              label="E-mailinizi giriniz"  
              name="email"  
              prepend-icon="email"  
              type="text"  
              v-model="userName"  
              :rules="[rules.required, rules.email]">
            </v-text-field>
          </v-form >
          <vue-recaptcha
            ref="recaptcha"
            class="captchaFix"
            @verify="onCaptchaVerified"
            size="invisible"
            :sitekey="recaptchaSiteKey"
          >
          </vue-recaptcha>
          <a class="rightLink" :href='route'>Geri Dön</a>
          <v-btn class="rightButton" v-if="hideButton"  :style= projectDesign.btnStyle :disabled="!valid" @click="projectDesign.sendMail(userName)" >Şifre Yenile</v-btn>
          <div class="socialLinkArea">
            <v-btn class="linkButton" fab color="black" outlined target="_blank" href="https://www.facebook.com/sharer.php?u=https://egeteknopark.com.tr/portfolio/notitek-yazilim/">
              <img src="../assets/facebook.svg" class="iconFB" />
            </v-btn>
            <v-btn class="linkButton" fab color="black" outlined target="_blank" href="https://www.notitek.com.tr/">
              <img src="../assets/www.svg" class="icon"/>
            </v-btn>
            <v-btn class="linkButton" fab color="black" outlined target="_blank" href="https://tr.linkedin.com/company/notitek-software">
              <img src="../assets/linkedin.svg" class="icon"/>
            </v-btn>
          </div>
      </div>
    </div>
    <snackbar-component />
  </div>
</template>


<script src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit" async defer>
</script>
<script>
import layout from "./layout.vue";
import autocompleteComponent from "./autocompleteComponent.vue";
import snackbarComponent from "./snackbarComponent.vue";
import {VueRecaptcha} from "../../../../vue/node_modules/vue-recaptcha";


export default {
    components: { 'layout' : layout, 'autocomplete-component' : autocompleteComponent ,  "snackbar-component": snackbarComponent,  VueRecaptcha },
    props: {
        projectDesign : {},
        colors : "",
        inputElement : {
          type: Object,
          default: () => ({
            logoBackground : "",
            leftBoxLogo : "",
            rightBoxLogo : "",
            btnStyle : "",
            sendMail : function (userName,password) {
               return "function"
            }
          })
        },
        dynamicElements : {
          type: Array,
          default: () => [{}]
        },
        tableHeader : {},
        model : {}
    },
    created(){
      this.renderKey++
      let loginPageEnums ={
        //color : "background-color: #b71111!important;",
        color : "background-color:black!important;",
        logoBackground : "background-color: #b71111!important;",
        leftBoxLogo : 'https://www.notitek.com.tr/wp-content/uploads/elementor/thumbs/logo-beyaz-pyfyeteuugdk4prv1lrcgbvo4p53ksuriqu3jt5hc0.png',
        rightBoxLogo : 'https://www.notitek.com.tr/wp-content/uploads/elementor/thumbs/logo-beyaz-pyfyeteuugdk4prv1lrcgbvo4p53ksuriqu3jt5hc0.png',
        loginFunction : "",
      }
      // let projectDesignKeys = Object.keys(this.projectDesign)
      // for (let i = 0; i < projectDesignKeys.length; i++) {
      //   if (loginPageEnums[projectDesignKeys[i]] == undefined){
      //     //projectDesignKeys.
      //   }
        
      // }


      if(localStorage.getItem("_grecaptcha") !== "" || localStorage.getItem("_grecaptcha") !== undefined){
        this.hideButton=true
      }
    
    },
    data () {
      return {
        recaptchaSiteKey: '6LdwEMwUAAAAADFmGyZZUPGiSRuZ0ii6dovuToNG',
        userName: '',
        valid : false,
        password : '',
        hideButton: false,
        route : 'login',
        email: '',
        renderKey : 0,
        color : "background-color: black!important;",
        rules: {
          required: value => !!value || 'Required.',
          counter: value => value.length <= 20 || 'Max 20 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
        },
      }
    },
    mounted()  {
      window.addEventListener("keydown",(a) => {
      })
    },
    methods:{
      
    async onCaptchaVerified(res) {
      if (res !== "") {
        this.hideButton = true;
      }
      //await localStorage.setItem("veritfy-token", res);
    }
    }
    
}
</script>
<style src='../../../../frontend/components/atoms/css/forgotten.css' scoped>

</style>



