<template>
  <div>
    <div v-if="dynamicElement.filteredComponentVisible">
      <filter-component :filterArray="filterArray"/>
    </div>
    <div  style=" width: 100%;display: flex; padding: 10px; padding-bottom: 20px;" :class="dynamicElement.tableHeaderContainerClass">
        <layout :dynamic-elements="dynamicElement" :layout-elements="dynamicElement.toolbars" :model="model" :style="dynamicElement.tableHeaderLayoutStyle"/>
    </div>
  </div>   
</template>

<script>
import layout from "../layout.vue";
import filterComponent from './filterComponents.vue'

export default {
    components: { 'layout' : layout ,filterComponent : filterComponent },

    props: {
        dynamicElement : {},
        model : {}
    },

    data() {
      return {
        renderKey: 0,
        filterArray : [],
      };
    },

    methods: {
      FilterHeaders(){
        let dynamicFilterObject = {}
        for (let i = 0; i < this.dynamicElement.headers.length; i++) {
            dynamicFilterObject = {}
            dynamicFilterObject= {
             "input": this.dynamicElement.headers[i].filterType,
             "label": this.dynamicElement.headers[i].label == undefined ? this.dynamicElement.headers[i].text : this.dynamicElement.headers[i].label,
             "function": "",
             "class": "",
             "operator": "",
             "name": this.dynamicElement.headers[i].value,
             "value": null,
             "value2": null ,
             "ValueFormatted": null,
             "ValueFormatted2": null,
            }
          
          if (this.dynamicElement.headers[i].filterType == "v-select") {
            dynamicFilterObject["items"] = this.dynamicElement.headers[i].items 
          }
          if (this.dynamicElement.headers[i].filterType == "text") {
           dynamicFilterObject["multiple"] = this.dynamicElement.headers[i].multiple 

          }
          this.filterArray.push(dynamicFilterObject)
        }
      },
    },
    computed: {

    },

    watch: {

    },

    created(){
      this.FilterHeaders()
    }
};
</script>