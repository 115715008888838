<template>
    <v-data-table
      :height="height"
      :style="dynamicElement.style"
      :name="dynamicElement.name"
      :class="dynamicElement.class"
      :headers="computedHeaders"
      :items="dynamicElement.data"
      :items-per-page="dynamicElement.itemsPerPage == undefined ? dynamicElement.data.length : dynamicElement.itemsPerPage"
      :hide-default-footer="dynamicElement.hidePagination"
      v-model="dynamicElement.selectedRows"
      :show-select ="dynamicElement.showSelecetVisible"
      :single-select="dynamicElement.singleSelect"
      :item-key="dynamicElement.itemKey"
      :sort-desc.sync="dynamicElement.sortDesc"
      :sort-by.sync="dynamicElement.sortBy"
      :item-class="dynamicElement.itemClass"
      fixed-header
      no-data-text="KAYDINIZ BULUNMAMAKTADIR.."
      dense
      :footer-props="dynamicElement.footerProps != undefined ? dynamicElement.footerProps : undefined"
    >
      <!-- <template v-for="header in computedHeaders" v-slot:[`item.${header.value}`]="{item}">
          
      </template> -->
      <template v-for="header in computedHeaders" v-slot:[`item.${header.value}`]="{item}" >
        <div v-if="dynamicElement.columns[header.value].inlineEditable" :key="header.value" :style="header.style" :class="header.class" >
          <edit-dialog-component :input-element="dynamicElement.columns[header.value].columnElement" :model="item" :dynamic-elements="[dynamicElement]" :table-header="header"/>
        </div>
        <div v-else-if="Object.keys(dynamicElement.columns[header.value]).includes('cellElement')" :key="header.value" :style="header.style " :class="header.class" >
          <input-component :input-element="dynamicElement.columns[header.value].cellElement" :model="item" :dynamic-elements="dynamicElement"/>
        </div>
        <div v-else :key="header.value"  :class="header.class" :style="{ 'text-align': getTextAlign(header.textAlign,header.dataType) }" >
          <!-- Buradaki tarih ve para birimi formatı settings'in içerisnden çekilerek yazılacak -->
          <slot v-if="header.dataType =='date'" >{{ (item[header.value] == null) ? null : new Date(item[header.value]).toLocaleString("tr-TR").split(" ")[0] }}</slot>
           <slot v-else-if="header.dataType =='currency'">{{ currencyFormat(item[header.value] ,2,3,",",".") }}</slot>
          <slot v-else-if="header.dataType =='currencyLocaleString'">{{ item[header.value] != null ?  item[header.value].toLocaleString('tr-TR', { currency: 'TRY', minimumFractionDigits: 2,maximumFractionDigits: 2 }) : 0}}</slot>
          <slot v-else-if="header.dataType =='currencyFourDigit'">{{ currencyFormat(item[header.value] ,4,3,",",".") }}</slot>
          <slot v-else-if="header.dataType =='currencyFiveDigit'">{{ currencyFormat(item[header.value] ,5,3,",",".") }}</slot>
          <slot v-else-if="header.dataType =='array'">{{ (item[header.value] == undefined || item[header.value] == null) ?  null : item[header.value].join(", ") }}</slot>
          <slot v-else-if="header.switchValue">
            <v-chip
            v-if="header.colorRow"
            :color="getColor(item[header.value],header.specialColorRow)"
            dark
            >
              {{ typeof  header.switchValue == 'function' ? header.switchValue(item[header.value]) :  header.switchValue[item[header.value]] }}
            </v-chip>
            <v-chip 
              v-else
              color="white"
            >
              {{ typeof  header.switchValue == 'function' ? header.switchValue(item[header.value]) :  header.switchValue[item[header.value]] }}
            </v-chip>
          </slot>
          <slot v-else>{{item[header.value]}}</slot>
        </div>
      </template>
      <template slot="header" v-if="dynamicElement.filteredColumns">
        <th v-if="dynamicElement.showSelecetVisible" class="selectionSpaceStock"></th>
        <th v-for="header in computedHeaders" style="padding-right:3px" :key="header.text">
          <div v-if="header.search">
            <div style="display: flex; flex-direction: row; justify-content: center;align-items: center;flex-wrap: nowrap;">
              <v-text-field
                :style="`width:10%;margin-left:18px; margin-bottom:0px; margin-top:12px;`"
                v-model="dynamicElement.filteredColumn[header.value]"
                dense
                placeholder="Ara"
                @keyup.enter="onClick(dynamicElement.clientEvents.searchOnClick,dynamicElement)"
              ></v-text-field>
              <button style="" x-small @click="searchOnClick(dynamicElement,dynamicElement.clientEvents.searchOnClick)">
                <div>
                <v-icon   
                    style="color: black;"
                >
                  search
                </v-icon></div>
              </button>
            </div>
          </div>
          <div v-else-if="header.items">
            <v-select
              class="checkbox"
              v-model="dynamicElement.filteredColumn[header.value]"
              dense
              placeholder="Ara"
              @change="searchOnClick(dynamicElement,dynamicElement.clientEvents.searchOnClick)"
              :items="header.items"
              :item-value="'value'"
              :item-text="'name'"
              :clearable="header.clearableSelect"
              :multiple="header.multipleSelect"
            >
              <template v-slot:selection="{ item, index }" v-if="header.hideMultipleSelections">
                <span  v-if="index === 0">{{ item }}</span>
                <span v-if="index === 1" class="grey--text text-caption ml-1">
                  (+{{ dynamicElement.filteredColumn[header.value].length - 1 }} others)
                </span>
              </template>
            </v-select>
          </div>
        </th>
      </template>
      <snackbar-component/>
    </v-data-table>
</template>

<script>
import InputComponent from '../../input/inputComponent.vue'
import editDialogComponent from '../editDialogComponent.vue'
import snackbarComponent from "../snackbarComponent.vue"
import {
  openSnackbar,
  openTablePage
} from "../../helper/elementHelper.js";
export default {
  components: { 'edit-dialog-component' : editDialogComponent , 'input-component' : InputComponent},'snackbar-component':snackbarComponent,
    props: {
        dynamicElement : {
          type: Object,
          default: () => {}
        },
        model : {},
    },

    data() {
      return {
        renderKey: 0,
        sortBy: "",
        sortDesc: "",
        balance : 0,
      };
    },

    methods: {
      //searchOnClick
      searchOnClick: function (prop,onClickFunc) {
        let errorFlag = false;
        let filterPropKey = Object.keys(prop.filteredColumn)
        for (let index = 0; index < filterPropKey.length; index++) {
          if (/[",'\!$%&]/.test(prop.filteredColumn[filterPropKey[index]])) {
            errorFlag = true;
            openSnackbar("error", `[",'\!$%&] Özek Karakter Girilemez`)
          }
        }
        if (errorFlag == false) {
          this.onClick(onClickFunc,prop);
        }
      },
      getTextAlign(value,type) {
        if (value === undefined) {
          if ( type === 'number' || type === 'currency' || type === 'currencyFourDigit' || type ==='money' || type === 'date') {
            return 'right';
          } else {
            return 'center';
          }
        } else if (value !== undefined){    
            return value;
        }
      },
      //elementhelpera ekle bu fonksiyonu
      getColor (value,specialColorRow) {
        if (specialColorRow != undefined){
          for (let index = 0; index < specialColorRow.length; index++) {
            if (value == specialColorRow[index].value) return specialColorRow[index].color
          }
        } 
        else{
          if (value == 0) return 'green'
        else if (value == 1) return 'red'
        else if (value == 2) return 'green'
        else if (value == 3) return 'blue'
        else if (value == 4) return 'pink'
        else return 'green'
        }
       
      },
      dateFormat(value){
        return new Date(value).toLocaleString("tr-TR").split(" ")[0]
      },
      currencyFormat(num, decimalLength, thousandLength, decimalSeparator, thousandSeparator) {
        let number = typeof num
        if ("number" == number) {
          if(decimalSeparator == thousandSeparator || isNaN(num))
          throw num
          let left = num.toFixed(thousandLength).replace('.', decimalSeparator).replace(new RegExp('(\\d)(?=(\\d{'+thousandLength+'})+(?!\\d))', 'g'), '$1' + thousandSeparator).split(decimalSeparator)[0];
          let right = num.toFixed(decimalLength).replace('.', decimalSeparator).replace(new RegExp('(\\d)(?=(\\d{'+decimalLength+'})+(?!\\d))', 'g'), '$1' + thousandSeparator).split(decimalSeparator)[1];
          const total = left + decimalSeparator + right;
          return total
        }else{
          return num
        }
      },
      onClick (onClick,prop){
        if (this.model[this.dynamicElement.name] == undefined) {
          this.model[this.dynamicElement.name] = {}
        }
        if (Object.keys(prop.filteredColumn).length != 0) {
          this.model.currentPageNo = 1
        }
        if (onClick != undefined) {
          onClick(prop,this.model,this.dynamicElement)
        }
        openTablePage(this.dynamicElement.name)
      },
      control(){
        let that = this;
        if (!Object.keys(this.dynamicElement).includes('columns')) {
          this.dynamicElement.columns = {}
        }
        if (Object.keys(this.dynamicElement).includes('heightType')) {
          if (this.dynamicElement.heightType == "height") {
            this.balance = 1
            this.dynamicElement.height = this.height * this.balance
          }
          else if (this.dynamicElement.heightType == "heightAlternative") {
            this.balance = 0.6346
            this.dynamicElement.height = this.height * this.balance
          }
        }
        else if (!Object.keys(this.dynamicElement).includes('heightType')) {
          this.balance = 1
          this.dynamicElement.height = this.height * this.balance
        }
        for (let i = 0; i < this.dynamicElement.headers.length; i++) {
          if (!Object.keys(this.dynamicElement.columns).includes(this.dynamicElement.headers[i].value)) {
            this.dynamicElement.columns[this.dynamicElement.headers[i].value]= {
              label : this.dynamicElement.headers[i].text,
              type : "v-text-field",
              editable: true,
              creatable: true,
              inlineEditable : false,
            }
          }
          else if(!Object.keys(this.dynamicElement.columns[this.dynamicElement.headers[i].value]).includes('inlineEditable')){
            this.dynamicElement.columns[this.dynamicElement.headers[i].value].inlineEditable = false
          }
        }

        Object.keys(this.dynamicElement.columns).forEach(key => {
          if (this.dynamicElement.columns[key].inlineEditable) {
            this.dynamicElement.columns[key].columnElement = {
              id : key,
              readonly : this.dynamicElement.columns[key].readonly,
              type : this.dynamicElement.columns[key].type,
              variant : this.dynamicElement.columns[key].variant,
              name : key,
              label : this.dynamicElement.columns[key].label || key,
              value : this.dynamicElement.columns[key].name,
              prependIcon : this.dynamicElement.columns[key].prependIcon,
              placeHolder : this.dynamicElement.columns[key].placeHolder,
              style : this.dynamicElement.columns[key].style,
              classTag : this.dynamicElement.columns[key].classTag,
              rules : this.dynamicElement.columns[key].rules,
              inputText : this.dynamicElement.columns[key].inputText,
              dropDownItems : (this.dynamicElement.columns[key].type == 'v-select') ?  this.dynamicElement.columns[key].dropDownItems : undefined,
              onSave : this.dynamicElement.columns[key].onSave,
              onChange : this.dynamicElement.columns[key].onChange,
              money : (this.dynamicElement.columns[key].type == 'v-money') ? this.dynamicElement.columns[key].money : undefined,
            }
            if (that.dynamicElement.columns[key].url != undefined) {
              that.dynamicElement.columns[key].columnElement.url = that.dynamicElement.columns[key].url
            }
            if (that.dynamicElement.columns[key].searchEventControl != undefined) {
              that.dynamicElement.columns[key].columnElement.searchEventControl = that.dynamicElement.columns[key].searchEventControl
            }
            if (that.dynamicElement.columns[key].crudRequestObj != undefined) {
              that.dynamicElement.columns[key].columnElement.crudRequestObj = that.dynamicElement.columns[key].crudRequestObj
            }
          }
        });
      }
    },
    computed: {
        computedHeaders () {
          return this.dynamicElement.headers.filter(function (header){
            return header.visible == true || header.visible == undefined
        })
      },
      height() {
        if (this.dynamicElement.tableHeight != undefined) {
          return this.dynamicElement.tableHeight;
        }
        else if (this.$vuetify.breakpoint.name == "xs") {
          return "calc(100vh - 274px)";
        } else if (this.$vuetify.breakpoint.name == "sm") {
          return "calc(100vh - 274px)";
        } else if (this.$vuetify.breakpoint.name == "md") {
          return "calc(100vh - 274px)";
        } else if (this.$vuetify.breakpoint.name == "lg") {
          if (this.dynamicElement.dialogTable) {
            return "100%";
          }else{
            return "calc(100vh - 243px)";
          }
        } else if (this.$vuetify.breakpoint.name == "xl") {
          if (this.dynamicElement.dialogTable) {
            return "100%";
          }else{
            return "calc(100vh - 243px)";
          }
        } else {
          return 0;
        }
      },
    },

    watch: {
      'dynamicElement.selectedRows':{
        handler: function(newValue,oldValue) {
            if (!Object.keys(this.dynamicElement).includes('clientEvents')) {
                this.dynamicElement.clientEvents = {}
            }
            if (oldValue.length < newValue.length && this.dynamicElement.clientEvents.onSelect != undefined) {
              // this.dynamicElement.clientEvents.onBeforeSelect(this.dynamicElement.selectedRows,this.model, this.dynamicElement)
              this.dynamicElement.clientEvents.onSelect(this.dynamicElement.selectedRows,this.model, this.dynamicElement);
              // this.dynamicElement.clientEvents.onAfterSelect(this.dynamicElement.selectedRows,this.model, this.dynamicElement)
            }
        },
        deep : true,
      }
    },
    created(){
      this.control();
    }
};

</script>
<style scoped>
.v-list-item__action{
  display: none !important;
}
.v-text-field__details{
  display: none !important;
}
.v-pagination__item{
  height: 25px;
  font-size: 15px;
}
.v-pagination__navigation{
  height: 25px;
  font-size: 12px;
}
.v-data-table--fixed-header thead th{
  padding-right: 0px
}
::v-deep .threedot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* width: 200px; */
}
::v-deep .threedot:hover {
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}
</style>