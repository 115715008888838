<template>
  <v-app>
    <v-row justify="center">
      <v-col md="3">
        <upload-component :dynamic-element="ebillElements" :model="model" style="margin-top: 50%;"/>
      </v-col>
    </v-row>
    <Loader></Loader>
    <snackbar-component />
  </v-app>
</template>

<script>
import tableComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
// import { showLoader, hideLoader } from "../../../frontend/components/atoms/helper/elementHelper";
import { showLoader, hideLoader } from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
//import { uploadFileAssistant } from "../tools/uploadFile.js";
import { sendRequest, uploadHelperFormDataQueue, recursivePromiseAllInsert, ticketIdFunction } from "../../../frontend/components/atoms/helper/requestHelper";
import {openSnackbar} from "../../../frontend/components/atoms/helper/elementHelper.js";

export default {
  components: {
    "upload-component": tableComponent,
    Loader: Loader,
    "snackbar-component": snackbarComponent,
  },

  data() {
    return {
      ebillElements: [],
      model: {},
      renderKey: 0,
    };
  },

  created() {
    this.loadData();
    this.getCompanySettings()
  },
  watch: {},
  methods: {
    loadData() {
      let that = this;
      this.ebillElements = [
        {
          renderKey: 0,
          name: "sss",
          interfaceName: "E-Fatura",
          width: 400,
          layout: [
            {
              type: Enum.layoutType.div,
              name: "loginForms",
              inputElements: [
                [
                  {
                    id: "ebill",
                    type: "v-file-input",
                    label: "Dosyanızı Seçiniz",
                    inputHeader: "E-Fatura",
                    accept: ".xml",
                    multiple : true,
                    name: "ebill",
                    onChange: function (thisData, model, dynamicElements) {
                      that.uploadFileOnChange(model,"ebill")
                    },
                  },
                ],
              ],
            },
          ],
        },
      ];
    },
    uploadFileOnChange(model,fileTypeName){
      let that = this
      let ticketId = ticketIdFunction()
      let fileArray = model[fileTypeName];
      if (fileArray.length > 0) {
        
        showLoader("Lütfen Bekleyiniz...","uploadLoader",fileArray.length,"this.$store.state.promiseLength")
        // kuyruk için
        // kuyruksuz endpoint ebillUpload ----- kuyruklu endpoint allFileUploadForFolderNotification
        let choiceData = {
          fileType: fileTypeName,
          nextInsertType: "EInvoiceInsert",
          insertType: "EInvoiceValidation",
          projectType: "didero",
          choice: fileTypeName,
          fileName: model[fileTypeName].name,
          projectInfo: window.$cookies.get("selectedItem"),
          ticketId: ticketId
        }
        // let thenFunc = function (xmlDbInsertResponse) {
        //     if (xmlDbInsertResponse.status == 200) {
        //         console.log("Başarılı");
        //     }else{
        //         console.log("Dosya Formatına Uygun Değil");
        //     }
        //     openSnackbar("success", "Yüklendi");
        //     hideLoader();
        //     // if (window.$cookies.get("selectedItem").explain.toLowerCase() != 'fatura') {
        //     //   that.$router.push("eBillTable")
        //     // } else {
        //     //   that.$router.push("eBillsUltraTest")
        //     // }
        //     //that.$router.push("folderNotification")
        // }
        //uploadHelperFormDataQueue(fileArray, "allFileUploadForFolderNotification", "POST", choiceData, thenFunc);
        recursivePromiseAllInsert(fileArray, 0, fileArray.length, 30, ticketId, choiceData, 30)
        fileArray = []
        // else {
        //    openSnackbar("blue", "Bir kerede en fazla 100 dosya atabilirsiniz.");
        // }
      } else {
        openSnackbar("pink", "Yüklenecek Dosya Seçiniz", 2000)
      }
    },
    getCompanySettings(){
      let that = this;
      sendRequest("POST","getCompanySettings","",{},function (res) {
        if (res.data.data.settings.didero.defaultAccountCode == undefined && window.$cookies.get('selectedItem').explain.toLowerCase() == 'Fatura') {
          //openSnackbar('error', 'Lütfen Varsayılan Hesapları Atayınız')
        }
        if (res.data.data.settings.didero.defaultAccountCode.sales[0].oneAccount.accountCode == '' && window.$cookies.get('selectedItem').explain.toLowerCase() == 'Fatura') {
          //openSnackbar('error', 'Lütfen Varsayılan Hesapları Atayınız')
        }
      },
      function(error) {
        openSnackbar("error", 'Hata Oluştu');
      })
    },
  },
};
</script>
