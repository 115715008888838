<template>
  <div :class="'inputbox'" :style="inputElement.inputBoxStyle">
    <div @mouseover="showHover" @mouseleave="hideHover"  class="inputboxarea" ref="inputarea">
      <div v-if="inputLabel != ''" class="inputLabel" :style="inputElement.inputLabelStyle">{{inputLabel}}</div>
      <input
        :class="!isDisabled ? inputareaClass : 'inputareaDisabled'"
        v-model="internalInputValue"
        @input="filterDropdown"
        :placeholder="inputElement.label"
        :style="inputElement.inputStyle"
        @click="openDropdown"
        :disabled="isDisabled"
      />
      <div @click="toggleDropdown" :disabled="isDisabled">
        <button v-if="inputElement.clearable" class="clearButtonClass" @click="clearButtonFunc"><v-icon x-small color="white">mdi-close</v-icon></button>
        <img
          v-if="!isDropdownOpen"
          class="arrow"
          src="../assets/down-arrow.svg"
        />
        <img
          v-if="isDropdownOpen"
          class="arrowRotate"
          src="../assets/down-arrow.svg"
        />
      </div>
    </div>
    <div v-if="isDropdownOpen" :class="{
        'dropdownTop': isDropdownOpen && dropdownPosition === 'top',
        'dropdown': isDropdownOpen && dropdownPosition === 'bottom',
        'dropdownclose': !isDropdownOpen
     }"  ref="dropdown">
      <button
        v-for="item in filterItems"
        :key="item[inputElement.key]"
        :class="[
          'item',
          { selecteditem: selectedItemId === item[inputElement.key] },
        ]"
        @click="selectItem(item)"
        :disabled="inputElement.selectDataDisabled"
      >
      {{ visibleItem(item, "visibleItemKeys") }}
      </button>
      <button
            v-if="addingItem"
            class="addButton"
            @click="addClick(inputElement.addButtonFunc,inputElement)"
          >
          <v-icon class="addButtonIcon" color="blue"  size="25px" width="5px">mdi-plus</v-icon>
          <div>Hesap Ekle</div>
      </button>
      <!-- <button
        v-for="item in includeItems"
        :key="item[inputElement.key]"
        :class="[
          'item',
          { selecteditem: selectedItemId === item[inputElement.key] },
        ]"
        @click="selectItem(item)"
      >
        {{ visibleItem(item, "visibleItemKeys") }}
      </button> -->
    </div>
    <div :class="{'hover': dropdownPosition === 'bottom', 'hoverTop': dropdownPosition === 'top'  }" v-if="isHoverVisible && inputElement.hoverDisabled != true">
      <div v-if="selectedData" style="white-space: pre-wrap;">
        {{ visibleItem(selectedData, "hoverItemKeys") }}
      </div>
      <div v-if="!selectedData" style="white-space: pre-wrap;">{{ accountNameToolTip }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "autoComplete",
  props: {
    onClickElements : {
      type: Object,
      default: () => ({}),
    },
    inputLabelStyle : {
      type: Object,
      default: () => ({}),
    },
    inputElement: {
      type: Object,
      default: () => ({}),
    },
    inputValue1: { 
      default: () => ({}),
    },
    accountNameParam: { 
      default: () => ({}),
    },
    allSetAccountPlanParam : {
      default: () => ({}),
    },
    setAccountPlanVatAndBaseParam : {
      default: () => ({}),
    },
    dropdownPosition : {
      type: String,
      default: "bottom",
    },
    inputLabel: {
      type: String,
      default: "",
    },
    hoverLabel: {
      type: String,
      default: "Hesap Seçiniz",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    accountType:{
      type: String,
      default: "",
    }
  },
  data() {
    return {
      inputareaClass : "inputarea",
      accountNameToolTip : "",
      isDropdownOpen: false,
      selectedData: null,
      selectedItemId: null, // selectedItemId veri parçasını ekleyin
      isHoverVisible: false,
      internalInputValue : this.inputValue1,
      addingItem:false,
      isSelectedBoo:false,
      clearButtonClick:false
    }
  },
  created() {
    // this.inputValue1 null olması durumunda, buradan kontrol edip, clası ona göre setleyeceğim.
    if (typeof this.inputValue1 === 'object' && this.inputValue1 != null) {
      this.internalInputValue= this.inputValue1.accountCode
      this.accountNameToolTip = this.inputValue1.accountName
    }else{
      this.accountNameToolTip = this.accountNameParam
    }
    if (this.inputValue1 == null) {
      this.inputareaClass = "inputareaBorderRed"   
    }
    this.modelControl();
    
    if (this.inputElement.getselectionurlFunc != undefined) {
      if (this.mainData.length == 0) {
        this.inputElement.getselectionurlFunc(this);
      }
    }
    //tooltip boş geldiği için hesap seçiniz yazdırmak için kullanılıyor.
    if (this.accountNameToolTip == "" || this.accountNameToolTip == null || this.accountNameToolTip == undefined) {
    this.accountNameToolTip = this.hoverLabel}

    //seperatorOperator tanımlanmadıysa default olarak - işareti atanıyor.

    if (this.inputElement.seperatorOperator == undefined) {
      this.inputElement.seperatorOperator = "-"
    }
  },
  updated(){
    if (this.inputElement.updateFunc != undefined) {
        this.inputElement.updateFunc(this.isDropdownOpen,this.internalInputValue);
      }
  },
  methods: {
    visibleItem(item,arrayKey) {
      let visibleText = "";
      for (let i = 0; i < this.inputElement[arrayKey].length; i++) {
        if (i == 0) {
          visibleText += item[this.inputElement[arrayKey][i]];
        } else {
          visibleText += ` ${this.inputElement.seperatorOperator} ` + item[this.inputElement[arrayKey][i]];
        }
      }
      return visibleText;
    },   
    modelControl() {
      if (Array.isArray(this.inputElement.dropDownItems)) {
        this.mainData = this.inputElement.dropDownItems;
      }
      // 
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    openDropdown() {
      if (!this.isDropdownOpen) {
        this.isDropdownOpen = true;
        this.isHoverVisible = false;
      } 
    },
    filterDropdown() {
      this.isDropdownOpen = true;
    },
    selectItem(item) {
      this.clearButtonClick = false
      this.isSelectedBoo=true
      if (this.inputElement.onclick != undefined) {
        this.inputElement.onclick(item, this.onClickElements);
      }
      this.internalInputValue = this.visibleItem(item, "visibleItemKeys").split(this.inputElement.seperatorOperator)[0].trimEnd();
      this.selectedData = item;
      this.inputareaClass = "inputarea"
      this.isDropdownOpen = false;
      this.$emit("update:inputValue1",item);
      // eBills için ilk satırdaki hesap kodu değişince alttaki satırlara da aktarılması için yazıldı.
      if (Object.keys(this.allSetAccountPlanParam).length > 0) {
        this.$emit("all-set-account-plan", this.allSetAccountPlanParam.headerValue, item, this.allSetAccountPlanParam.itemAllObj )  
      }
      if (Object.keys(this.setAccountPlanVatAndBaseParam).length > 0) {
        this.$emit("set-account-plan-vat-and-base", this.setAccountPlanVatAndBaseParam.headerValue, item, this.setAccountPlanVatAndBaseParam.itemAllObj )  
      }
    },
    showHover() {
      this.isHoverVisible = true;
      if (this.isDropdownOpen) {
        this.isHoverVisible = false;
      }
    },
    hideHover() {
      this.isHoverVisible = false;
    },
    handleClickOutside(event) {
      const dropdownElement = this.$refs.dropdown;
      const inputElement = this.$refs.inputarea;
      if (dropdownElement != undefined && inputElement != undefined) {
        if (!dropdownElement.contains(event.target) && !inputElement.contains(event.target)) {
          this.isDropdownOpen = false;
        }
      }
    },
    addClick(onClick,prop) {
      if( this.inputElement.addButtonFunc != undefined) {
        onClick(prop,this.internalInputValue, this.accountType)
      }
    },
    clearButtonFunc(){
      let clearObj = {
        accountCode: "",
        accountName: "",
        companyId: null,
        id: null,
        isActive: null,
        synchronous: null,
      }
      this.internalInputValue = ""
      this.inputValue1= ""
      this.accountNameToolTip= "Hesap Seçiniz"
      // this.selectedData = null
      this.isSelectedBoo=false
      this.clearButtonClick = true
      this.$emit("update:inputValue1",clearObj);
    }
  },
  computed: {
    filterItems(){
      let startAccountCodeArray = []
      let includeAccountCodeArray = []
      let startAccountNameArray = []
      let includeAccountNameArray = []
      //formatter for filter - value turns to string and lowercase
      const filterFormatter = (value) => { return String(value).toLocaleLowerCase("tr")}

      const inputValue =  filterFormatter(this.internalInputValue); 
      //const inputValue =  filterFormatter(String(this.internalInputValue).trimStart()); 

      startAccountCodeArray.push(this.mainData.filter((item) => {
        const accountCode = filterFormatter(item.accountCode);
        return accountCode.startsWith(inputValue)
      }))

      includeAccountCodeArray.push(this.mainData.filter((item) => {
        const accountCode = filterFormatter(item.accountCode);
        return accountCode.includes(inputValue)
      }))


      startAccountNameArray.push(this.mainData.filter((item) => {
        const accountName = filterFormatter(item.accountName);
        return accountName.startsWith(inputValue)
      }))

      includeAccountNameArray.push(this.mainData.filter((item) => {
        const accountName = filterFormatter(item.accountName);
        return accountName.includes(inputValue)
      }))      
      
      const combinedArray = [...new Set([...startAccountCodeArray.flat(), ...includeAccountCodeArray.flat(), ...startAccountNameArray.flat(), ...includeAccountNameArray.flat()])];
      if (inputValue == 'null') {
        return this.mainData;
      }
      if (combinedArray.length <= 0 && this.inputElement.addItem == true && inputValue != 'null') {
        this.addingItem = true
      } else { this.addingItem = false }
      return combinedArray;
    },

    // startItems() {
    //   return this.mainData.filter((item) => {
    //     const inputValue = String(this.internalInputValue); // internalInputValue'yi stringe dönüştür
    //     const accountCode = String(item.accountCode); // accountCode'u stringe dönüştür
    //     const accountName = String(item.accountName); // accountName'i stringe dönüştür

    //     if (!isNaN(inputValue)) {
    //       // Eğer inputValue bir sayıysa
    //       return accountCode.startsWith(inputValue.toLocaleLowerCase("tr"));
    //     } else {
    //       // Eğer inputValue bir sayı değilse
    //       return (accountName.toLocaleLowerCase("tr").startsWith(inputValue.toLocaleLowerCase("tr")));
    //     }
    //   }).sort(function (a, b) {
    //     if (a.accountName != null) {
    //       a.accountName.localeCompare(b.accountName, "tr", {sensitivity: "base",})
    //     }
    //   })
    // },
    // includeItems() {
    //   if (this.internalInputValue == null) {
    //     return this.mainData
    //   }else{
    //     return this.mainData.filter((item) => {
    //       // -> item.accountName? -> undefined veya null değil ise bu koşula girer. 
    //       item.accountCode.toLocaleLowerCase("tr").includes(this.internalInputValue.toLocaleLowerCase("tr")) || item.accountName?.toLocaleLowerCase("tr").includes(this.internalInputValue.toLocaleLowerCase("tr"))
          
    //     }).filter((item) =>   !this.startItems.includes(item))
    //     .sort((a, b) => {
    //       if (a.accountName != null) {
    //         a.accountName.localeCompare(b.accountName, "tr", {sensitivity: "base"})
    //       }
    //     } 
    //     );
    //   }
    // },
  },
  watch: {
    isDropdownOpen(val) {
      if (this.inputElement.handleClickOutsideDisabled != true) {
        if (val == true) {
          window.addEventListener("click", this.handleClickOutside);
        }
        else {
          if (this.inputValue1?.accountCode == '') {
            this.internalInputValue = ``;
          }else{
            if(!this.clearButtonClick){
              this.internalInputValue = `${this.inputValue1?.accountCode || this.inputValue1}`;
            }
          }
          window.removeEventListener("click", this.handleClickOutside);
          if(this.isSelectedBoo != true && !this.clearButtonClick){
            if(this.inputValue1?.accountCode == ""){
              this.internalInputValue=`${this.inputValue1.accountCode}`
            } else if (this.inputValue1 == ""){
              this.internalInputValue=`${this.inputValue1}`
            } else if (this.inputValue1 == null){
              this.internalInputValue = ""
            }
            // this.internalInputValue=`${this.inputValue1.accountCode || this.inputValue1}`
          }
          this.isSelectedBoo=false
        }
      }
    },
  },
};
</script>

<style>
.inputbox {
  display: flex;
  position: relative;
  height: 35px;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  border: 1px solid #707070;
  border-radius: 0.375rem;
}

.inputboxarea {
  width: 100%;
}

.inputarea {
  height: 100%;
  width: 100%;
  padding: 0.5rem 0.75rem;
  border-radius: 0.375rem;
  outline: 2px solid #294060;
  color: black;
}
.inputareaDisabled{
  height: 100%;
  width: 100%;
  padding: 0.5rem 0.75rem;
  border-radius: 0.375rem;
  outline: 2px solid #73767a;
  color: black;
  opacity: 0.6;
}
.inputareaBorderRed {
  height: 100%;
  width: 100%;
  padding: 0.5rem 0.75rem;
  border-radius: 0.375rem;
  outline: 2px solid #ff0000;
  color: black;
}

.arrow {
  width: 0.75rem;
  height: 0.75rem;
  position: absolute;
  right: 3%;
  top: 35%;
}
.arrowRotate {
  width: 0.75rem;
  height: 0.75rem;
  position: absolute;
  right: 3%;
  top: 35%;
  transform: rotate(180deg);
}

.dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 999;
  top: 35px;
  left: 0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.6),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  width: 100%;
  height: 300px;
  overflow-y: auto;
  padding: 5px;
  background: rgb(245, 253, 255);
  border: #294060 solid 3px;
  border-radius: 10px;
}
@media screen and  (max-width: 1350px){
.dropdown {
  left: -50%;
  width: 190%;
}}
.dropdownTop {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 999;
  bottom: 35px;
  left: 0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.6),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  width: 100%;
  height: 300px;
  overflow-y: auto;
  padding: 0.5rem;
  background: rgb(245, 253, 255);
  border: #294060 solid 3px;
  border-radius: 10px;
}
.dropdownclose {
  display: none;
}
.item {
  color: black;
  text-align: start;
  width: 100%;
  padding: 0.75rem;
  white-space: wrap;
  overflow-x: clip;
}

.item:hover {
  background-color: #bee3f8;
  border-radius: 5px;
  color: #1e4bb2;
}


.selecteditem {
  background-color: #bee3f8;
  color: #1e4bb2;
}

.hover {
  position: absolute;
  top: 2.5rem;
  white-space: nowrap;
  background-color: #294060;
  color: white;
  padding: 0.5rem;
  border-radius: 0.375rem;
  z-index: 999;
}

.hoverTop {
  position: absolute;
  bottom: 2.3rem;
  white-space: nowrap;
  background-color: #294060;
  color: white;
  padding: 0.5rem;
  border-radius: 0.375rem;
  z-index: 999;
}

.inputLabel{ 
  position: absolute;
  color:#3182ce;
  background-color: white; 
  bottom:23px; 
  height: fit-content;
  left:5px;
  font-size:12px;
  margin-right: 5px;}

  .addButton{
    color: black;
    text-align: start;
    width: 100%;
    padding: 10px;
    white-space: wrap;
    overflow-x: clip;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    gap:5px;
    text-align: center;
    background-color: #bee3f8;
    border-radius: 5px;
  }
  .addButton:hover{
    background-color: #9edafd;
    border-radius: 5px;
    color: #1e4bb2;
  }
  .addButtonIcon{
    text-align: center;
    width:20px;
   margin-bottom:0  !important;
  }

  .clearButtonClass{
    position: absolute;
    top:20%;
    right:23px;
    background-color: #949494;
    border-radius: 100%;
    width:20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
