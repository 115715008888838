<template>
    <div class="Chart" >
      <div style="text-align:center;" :style="chartObj.titleStyle">{{chartObj.chartName}}</div>
      <component :is="chartObj.chartType" :chart-obj="chartObj" :style="chartObj.style"/>
    </div>
</template>
<script>
import LineExample from '../container/charts/lineChart.js'
import BarExample from '../container/charts/barChart.js'
import PieExample from '../container/charts/pieChart.js'
export default {
    components: {
    //https://github.com/apertureless/vue-chartjs
      BarExample,
      LineExample,
    //   DoughnutExample,
    //   CustomLine,
       PieExample,
    //   RadarExample,
    //   PolarAreaExample,
    //   BubbleExample,
    //   ReactiveExample,
    //   ReactivePropExample,
    //   ScatterExample,
    //   HorizontalBarExample
    },
    props: {
        chartObj : {
            chartName : String,
            chartType : String
        }
    },
    data () {
      return {
          name : "line-example"
      }
    },
}
</script>