<template>
    <v-app>
      <div class="header-div">
        <h3 class="header-h3">Hareketsiz Hesaplar</h3>
      </div>
      <table-component :dynamic-element="tableElements" :model="model"/>    
      <Loader></Loader>
      <snackbar-component />
    </v-app>
</template>

<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import XLSX from "xlsx";
import {
  openSnackbar,
  showLoader,
  hideLoader,
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";
import {axiosUploadTypeArrayBuffer} from "../../helper/uploadHelperForData"

export default {
    components: {
    "table-component": tableComponent,
    "upload-component": uploadComponent,
    Loader: Loader,
    "snackbar-component": snackbarComponent,
    },
    data() {
        return {
          tableElements: {},
          model: {},
          renderKey: 0,
        };
    },
    created() {
        this.loadData();
    },
    watch: {},
    methods: {
        loadData() {
          let that = this
            this.tableElements = {
            sortBy: [],
            sortDesc: false,
            renderKey: 0,
            type: "table",
            tableHeight : 500,
            name: "staticAccounts",
            class: "",
            infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
            excelDownloadBtnText: "İndir",
            deleteDialogVisible : true,
            hidePagination: true,
            filteredColumn: {},
            filteredColumns: true,
            selectionUrl: "staticAccounts",
            initialFilter:{
              [Enum.initialFilter.AND] :
              [
                {
                  projectId: {
                    [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id
                  },auditDate: {
                    [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").auiditDate
                  },preriorDate: {
                    [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").preriorDate
                  }
                }
              ]
            },
                toolbars: [
                  {
                    type: Enum.layoutType.div,
                    name: "toolbarsForm",
                    inputElements: [
                    [
                      {
                        id: "excelDownload",
                        type: "v-btn",
                        variant: "btn",
                        name: "excelDownload",
                        inputText: "excelDownloadBtnText",
                        style:"height: 25px !important; margin-top: 25%",
                        divStyle: "",
                        icon: "mdi-file-excel",
                        btnTypeText: false,
                        color: "success",
                        prependIcon: "",
                        classTag: "",
                        onClick: function(thisData, model, dynamicElements) {
                          showLoader('Dosyanız Hazırlanıyor')
                          sendRequest(Enum.requestType.POST,"staticAccounts","",
                            { 
                              startDate: model.startDate, 
                              excelDownload : true,
                              choice : "staticAccountsTable",
                              cookieData: window.$cookies.get("selectedItem")
                            },
                            function (response) {
                              if (response.data.data.data != true) {
                                openSnackbar('error', "İndirilecek Veri Bulunamadı", 3000)
                                hideLoader();
                                return false
                              }
                              
                              let zipName = "Hareketsiz Hesaplar"
                              axiosUploadTypeArrayBuffer("allExcelZipDownload", "staticAccountsTable", zipName, "Excel İndirildi")
                            },
                            function (error) {
                              openSnackbar(
                                "error",
                                error.response.data.error[0].detail.message
                              );
                              hideLoader();
                            }
                          )
                        },
                      }
                    ],
                  ],
                }
              ],
              headers: [
                { text: "Hesap Kodu", value: "accountCode", width: "100" , divider: true, align: "left"},
                { text: "Hesap Adı", value: "accountName", width: "100" , divider: true, class : 'threedot', align: "left"},
                { text: "Tarih", value: "billDate", width: "100" , dataType: "date" , divider: true, align: "center"},
                { text: "Fiş Türü", value: "receiptType", width: "100" , divider: true, align: "left"},
                { text: "Fiş Atama", value: "receiptTypeAssign", width: "100" , divider: true, align: "left", switchValue: {1 : "Açılış", 2 : "Mahsup", 3: "Kur Farkı", 4: "Yansıtma", 5: "Kar Zarar Virman", 6: "Kapanış", 7: "Adat"}},
                { text: "Fiş Numarası", value: "receiptNumber", width: "100" , divider: true, align: "right"},
                { text: "Bakiye", value: "balance", width: "100", dataType: "currency" , divider: true, align: "right"},
                { text: "Açıklama", value: "explanation", width: "100" , divider: true, class : 'threedot', align: "left"},
              ],
            }
        }
    }
}

</script>