<template>
  <v-app>
    <table-component :dynamic-element="tableElements" :model="model" />    
    <Loader></Loader>
    <snackbar-component />
  </v-app>
</template>

<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import XLSX from "xlsx";
import {
openSnackbar,
showLoader,
openTablePage,
getTable,
deleteItem,
closeDialog,
excelDownloadDefault,
//getTableModel,
//showDialog,
hideLoader,
  updateItem,
saveItem
//hashCode
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";
import {axiosUploadTypeArrayBuffer} from "../../helper/uploadHelperForData"

export default {
  components: {
    "table-component": tableComponent,
    "upload-component": uploadComponent,
    Loader: Loader,
    "snackbar-component": snackbarComponent,
    },
    data() {
        return {
          tableElements: {},
          model: {},
          renderKey: 0,
          accountCodes: [],
          autoCompleteUrl: ""
        };
    },
    created() {
      if (window.$cookies.get("selectedItem") == null || window.$cookies.get("selectedItem") == 'null') {
        this.autoCompleteUrl = "getAccountCodes"
      } else {
        this.autoCompleteUrl = "getAccountCodesWithProject"
      }
      console.log(this.autoCompleteUrl);
      this.loadData()
    },
    watch: {},
    methods: {
        loadData() {
          let that = this
          this.tableElements = {
          sortBy: [],
          sortDesc: false,
          renderKey: 0,
          type: "table",
          tableHeight : 230,
          name: "mizanTable",
          class: "",
          infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
          excelDownloadBtnText: "İndir",
          deleteDialogVisible : true,
          hidePagination: true,
          filteredColumn: {},
          filteredColumns: true,
          //selectionUrl: "incomeStatementTable",
              toolbars: [
                {
                    type: Enum.layoutType.div,
                    name: "toolbarsForm",
                    inputElements: [
                    [
                      {
                        id : "items",
                        name : "items",
                        type : 'autocomplete',
                        label : "Hesap Kodu",
                        value: '',
                        style : "width: 260px; margin-top: 10px;",
                        multiple : false,
                        classTag : "",
                        url : this.autoCompleteUrl,
                        color: 'blue-grey lighten-2',
                        solo: true,
                        clearable: true,
                        dense: true
                      },
                    ],
                    [
                      {
                        id : "withoutInterest",
                        type : "v-text-field",
                        variant : "text",
                        name : "withoutInterest",
                        label : "Adat Harici Tutar",
                        value : "",
                        placeHolder : "",
                        style : "width: 140px;",
                        classTag : "",
                      },
                    ],
                    [
                      {
                        id : "startDate",
                        type : "v-date-picker",
                        readonly: true,
                        variant : "text",
                        name : "startDate",
                        label : "Başlangıç Tarihi",
                        value : "",
                        prependIcon : "fas fa-at",
                        placeHolder : "",
                        style : "width: 260px;",
                        classTag : "",
                        onClick : function(thisData,model,dynamicElements) {
                        }
                      },
                    ],
                    [
                     {
                        id : "interestNumberDate",
                        type : "v-date-picker",
                        readonly: true,
                        variant : "text",
                        name : "interestNumberDate",
                        label : "Adat Tarihi",
                        value : "",
                        prependIcon : "fas fa-at",
                        placeHolder : "",
                        style : "width: 260px;",
                        classTag : "",
                        onClick : function(thisData,model,dynamicElements) {
                        }
                      },
                    ],
                    [
                      {
                        id : "interestRate",
                        type : "v-text-field",
                        variant : "currency",
                        name : "interestRate",
                        label : "Faiz Oranı (%)",
                        value : 10,
                        placeHolder : "",
                        style : "width: 130px;",
                        classTag : "",
                      },
                    ],
                    [
                    {
                        id : "calculate",
                        type : "v-btn",
                        variant : "btn",
                        name : "calculate",
                        inputText : "Hesapla",
                        divStyle:"",
                        style:"height: 25px !important;",
                        btnTypeText: false,
                        color : "primary",
                        prependIcon : "",
                        classTag : "",
                        onClick : function(thisData, model, dynamicElements) {
                          let startingDates = [];
                          let interestNumberDates = [];
                          let interestRates = [];
                          let objModelKeys = Object.keys(model)
                          for (let i = 0; i < objModelKeys.length; i++) {
                            if (objModelKeys[i].includes('startDate') === true) {
                              startingDates.push({startDate: model[objModelKeys[i]]});
                            }
                          }
                          for (let i = 0; i < objModelKeys.length; i++) {
                            if (objModelKeys[i].includes('interestNumberDate') === true) {
                              interestNumberDates.push({interestNumberDate: model[objModelKeys[i]]});
                            }
                          }
                          if (startingDates.length === 1) {
                            console.log(startingDates.length);
                          } else {
                            for (let k = 0; k < startingDates.length; k++) {
                              for (let h = 0; h < interestNumberDates.length; h++) {
                                  if (k !== startingDates.length - 1) {
                                  k++;
                                  let startDateNew = startingDates[k].startDate.split("/")
                                  startDateNew = new Date(startDateNew[2] + "-" + startDateNew[1] + "-" +startDateNew[0])
                                  let interestNumberNew = interestNumberDates[h].interestNumberDate.split("/")
                                  interestNumberNew = new Date(interestNumberNew[2] + "-" + interestNumberNew[1] + "-" + interestNumberNew[0])
                                  if (startDateNew < interestNumberNew) {
                                    openSnackbar("error","Adat Tarihlerini Kontrol Ediniz",2000);
                                    return false;
                                  }
                                } else {
                                  break;
                                }
                              }
                            }
                          }
                          for (let i = 0; i < objModelKeys.length; i++) {
                            if (objModelKeys[i].includes('interestRate') === true) {
                              interestRates.push({interestRate: model[objModelKeys[i]]});
                            }
                          }
                          if(model.startDate === "" && model.interestNumberDate === "" || model.items === "" || model.interestRate === "" || model.withoutInterest === "") {
                            openSnackbar("error","Lütfen Değerleri Eksiksiz Doldurunuz.");
                            return false;
                          }
                          if(((model.startDate != undefined) && (model.interestNumberDate != undefined))) {
                            let startDateNew = model.startDate.split("/")
                            startDateNew = new Date(startDateNew[2] + "-" + startDateNew[1] + "-" +startDateNew[0])
                            let interestNumberDateNew = model.interestNumberDate.split("/")
                            interestNumberDateNew = new Date(interestNumberDateNew[2] + "-" + interestNumberDateNew[1] + "-" +interestNumberDateNew[0])
                            const diffTime = Math.abs(interestNumberDateNew - startDateNew);
                            //const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                            if (startDateNew > interestNumberDateNew) {
                              openSnackbar("error","Başlangıç Tarihi, Bitiş Tarihinden Büyük Olamaz",2000);
                              return false;
                            }
                          }
                          showLoader('Hesaplamalar Yapılıyor') 
                          sendRequest(Enum.requestType.POST, "calculateInterestNumber", "", {
                            startDate: startingDates, 
                            interestNumberDate: interestNumberDates, 
                            accountCode: model.items,
                            withoutInterest: model.withoutInterest, 
                            interestRate: interestRates,
                            cookieData: window.$cookies.get("selectedItem")
                            }, 
                            function (response) {
                            dynamicElements.data = response.data.data.data;
                            model.itemCount = dynamicElements.data.length;
                            model.pagesCount = dynamicElements.data.length / 50;
                            dynamicElements.forceRender();
                            hideLoader();
                            },
                            function (error) {
                              openSnackbar(
                              "error",
                              error.response.data.error[0].detail.message
                            );
                            hideLoader();
                              })
                        }
                      },
                      {
                        id : "addDate",
                        type : "v-btn",
                        variant : "btn",
                        name : "addDate",
                        inputText : "Tarih Ekle",
                        divStyle:"",
                        style:"height: 25px !important; margin-top: 5%",
                        btnTypeText: false,
                        color : "primary",
                        prependIcon : "",
                        classTag : "",
                        onClick : function(thisData, model, dynamicElements) {
                          if (dynamicElements.toolbars[0].inputElements[2].length > 4) {
                            openSnackbar("error", "En fazla 4 yeni tarih ekleyebilirsiniz.", 2000)
                            return false
                          }
                          let elementStartDate = JSON.parse(JSON.stringify(dynamicElements.toolbars[0].inputElements[2][0]));
                          elementStartDate.id = elementStartDate.id + String(dynamicElements.toolbars[0].inputElements[2].length - 1);
                          elementStartDate.name = elementStartDate.name + String(dynamicElements.toolbars[0].inputElements[2].length - 1);
                          dynamicElements.toolbars[0].inputElements[2].push(elementStartDate)
                          let elementEndDate = JSON.parse(JSON.stringify(dynamicElements.toolbars[0].inputElements[3][0]));
                          elementEndDate.id = elementEndDate.id + String(dynamicElements.toolbars[0].inputElements[3].length - 1);
                          elementEndDate.name = elementEndDate.name + String(dynamicElements.toolbars[0].inputElements[3].length - 1);
                          dynamicElements.toolbars[0].inputElements[3].push(elementEndDate)
                          let elementInterestRate = JSON.parse(JSON.stringify(dynamicElements.toolbars[0].inputElements[4][0]));
                          elementInterestRate.id = elementInterestRate.id + String(dynamicElements.toolbars[0].inputElements[4].length - 1);
                          elementInterestRate.name = elementInterestRate.name + String(dynamicElements.toolbars[0].inputElements[4].length - 1);
                          elementInterestRate.style = "margin-top: 26px;"
                          dynamicElements.toolbars[0].inputElements[4].push(elementInterestRate);
                        }
                      },
                      {
                        id : "refreshPage",
                        type : "v-btn",
                        variant : "btn",
                        name : "refreshPage",
                        inputText : "Sayfayı Temizle",
                        divStyle:"",
                        style:"height: 25px !important; margin-top: 5%",
                        btnTypeText: false,
                        color : "primary",
                        prependIcon : "",
                        classTag : "",
                        onClick : function(thisData, model, dynamicElements) {
                          showLoader('Sayfanız Yenileniyor')
                          window.location.reload(true);
                          openSnackbar("success", "Sayfa Temizlendi", 2000)
                        }
                      },
                      {
                        id: "excelDownload",
                        type: "v-btn",
                        variant: "btn",
                        name: "excelDownload",
                        inputText: "excelDownloadBtnText",
                        style:"height: 25px !important; margin-top: 5%",
                        divStyle: "",
                        icon: "mdi-file-excel",
                        btnTypeText: false,
                        color: "success",
                        prependIcon: "",
                        classTag: "",
                        onClick: function(thisData, model, dynamicElements) {
                          let startingDates = [];
                          let interestNumberDates = [];
                          let interestRates = [];
                          let objModelKeys = Object.keys(model)
                          for (let i = 0; i < objModelKeys.length; i++) {
                            if (objModelKeys[i].includes('startDate') === true) {
                              startingDates.push({startDate: model[objModelKeys[i]]});
                            }
                          }
                          for (let i = 0; i < objModelKeys.length; i++) {
                            if (objModelKeys[i].includes('interestNumberDate') === true) {
                              interestNumberDates.push({interestNumberDate: model[objModelKeys[i]]});
                            }
                          }
                          for (let i = 0; i < objModelKeys.length; i++) {
                            if (objModelKeys[i].includes('interestRate') === true) {
                              interestRates.push({interestRate: model[objModelKeys[i]]});
                            }
                          }
                          if (model.startDate === "" && model.interestNumberDate === "") {
                              openSnackbar("error","Girilen Tarih Değerleri Boş Olamaz");
                              return false;
                          }
                          if (((model.startDate != undefined) && (model.interestNumberDate != undefined))) {
                            let startDateNew = model.startDate.split("/")
                            startDateNew = new Date(startDateNew[2] + "-" + startDateNew[1] + "-" +startDateNew[0])
                            let endDateNew = model.interestNumberDate.split("/")
                            endDateNew = new Date(endDateNew[2] + "-" + endDateNew[1] + "-" +endDateNew[0])
                            const diffTime = Math.abs(endDateNew - startDateNew);
                            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

                            if (diffDays > 370) {
                              openSnackbar("error","Seçilen Tarihler Arası Bir Yıldan Büyük Olamaz", 2000);
                              return false;
                            }
                            if (startDateNew > endDateNew) {
                              openSnackbar("error","Başlangıç Tarihi, Bitiş Tarihinden Büyük Olamaz",2000);
                              return false;
                            }
                          }
                          showLoader('Dosyanız Hazırlanıyor')
                          sendRequest(Enum.requestType.POST,"calculateInterestNumber","",
                            {
                              startDate: startingDates, interestNumberDate: interestNumberDates, accountCode: model.items,
                              withoutInterest: model.withoutInterest, interestRate: interestRates,
                              excelDownload : true,
                              choice : "interestNumber"
                            },
                            function (response) {
                              if (response.data.data.data != true) {
                                openSnackbar('error', "İndirilecek Veri Bulunamadı", 3000)
                                hideLoader();
                                return false
                              }
                              let zipNameStartDate = startingDates[0]['startDate'].replaceAll("/", "_")
                              let zipNameEndDate = interestNumberDates[interestNumberDates.length - 1].interestNumberDate.replaceAll("/", "_")
                              let zipName = "Rapor Excel" + zipNameStartDate + "ve" + zipNameEndDate
                              axiosUploadTypeArrayBuffer("allExcelZipDownload", "interestNumber", zipName, "Excel İndirildi")
                            },
                            function (error) {
                              console.log(error);
                              openSnackbar(
                                "error",
                                error.response.data.error[0].detail.message
                              );
                              hideLoader();
                            }
                          )
                        },
                      }
                    ]
                  ],
                }
              ],
              headers: [
                { text: "Hesap Kodu", value: "accountCode", width: "100", divider: true,},
                { text: "Tarih", value: "date", width: "100", dataType: "date", divider: true,},
                { text: "Borç", value: "debt", width: "100", dataType: "currency", divider: true, align: "right"},
                { text: "Alacak", value: "credit", width: "100", dataType: "currency", divider: true, align: "right"},
                { text: "Borç Bakiye", value: "balance", width: "100", dataType: "currency", divider: true, align: "right"},
                { text: "Alacak Bakiye", value: "creditBalance", width: "100", divider: true, align: "right"},
                { text: "Adat Harici Tutar", value: "withoutInterest", width: "100", dataType: "currency", divider: true, align: "right"},
                { text: "İşlem Görecek Tutar", value: "theBalanceThatWillBeUsed", width: "120", dataType: "currency", divider: true, align: "right"},
                { text: "Adat Gün", value: "interestDay", width: "100", divider: true, align: "right"},
                { text: "Borç Faiz", value: "debtInterest", width: "100", dataType: "currency", divider: true, align: "right"},
                { text: "Alacak Faiz", value: "creditInterest", width: "100", divider: true, align: "right"},
              ],
          }
        }
    }
}

</script>