<template>
    <v-app>
      <div class="header-div">
        <h3 class="header-h3">FİNANSMAN GİDER KISITLAMASI HESAPLAMASI</h3>
      </div>
        <table-component :dynamic-element="tableElements" :model="model" class="small-page-layout"/>
        <Loader></Loader>
        <!-- <snackbar-component /> -->
    </v-app>
</template>
<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";

import {
  openSnackbar,
  openTablePage,
  showLoader, 
  hideLoader 
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import {axiosUploadTypeArrayBuffer, checkProject} from "../../helper/uploadHelperForData"
export default {
  components: {
    "table-component": tableComponent,
    "upload-component": uploadComponent,
    Loader: Loader,
    "snackbar-component": snackbarComponent,
  },
  data() {
    return {
      tableElements: {},
      model: {},
      renderKey: 0,
    };
  },
  created() {
    checkProject();
    this.loadData();
  },
  watch: {},
  methods: {
    loadData() {
      this.tableElements = {
        sortBy: [],
        sortDesc: false,
        renderKey: 0,
        type: "table",
        tableHeight : 530,
        name: "fundingConstraintsTable",
        class: "",
        infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
        excelDownloadBtnText: "İndir",
        deleteDialogVisible : true,
        hidePagination: true,
        //selectedRows: [],
        filteredColumn: {},
        filteredColumns: true,
        selectionUrl: "fundingConstraints",
        crudRequestObj : {
          placeOfAction : "fundingConstraints",
          projectId : window.$cookies.get("selectedItem").id,
          company_id: window.$cookies.get("selectedItem").companyId,
          auditDate: window.$cookies.get("selectedItem").auiditDate,
          preriorDate: window.$cookies.get("selectedItem").preriorDate,
        },
        initialFilter:{
          [Enum.initialFilter.AND] :
          [
            {
              projectId: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id
              },auditDate: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").auiditDate
              },preriorDate: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").preriorDate
              }
            }
          ]
        },
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
              [
                {
                  id: "excelDownload",
                  type: "v-btn",
                  variant: "btn",
                  name: "excelDownload",
                  inputText: "excelDownloadBtnText",
                  style:"height: 25px !important;",
                  divStyle: "",
                  icon: "mdi-file-excel",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    showLoader('Dosyanız Hazırlanıyor')
                    sendRequest(Enum.requestType.POST,"fundingConstraints","",
                      {
                        excelDownload : true,
                        choice : "fundingConstraintsTable",
                        cookieData: window.$cookies.get("selectedItem")
                      },
                      function (response) {
                        if (response.data.data.data != true) {
                          openSnackbar('error', "İndirilecek Veri Bulunamadı", 3000)
                          hideLoader();
                          return false
                        }
                        let zipName = "Finans Kısıtlaması Rapor"
                        axiosUploadTypeArrayBuffer("allExcelZipDownload", "fundingConstraintsTable", zipName, "Excel İndirildi")
                      },
                      function (error) {
                        openSnackbar(
                          "error",
                          error.response.data.error[0].detail.message
                        );
                        hideLoader();
                      }
                    )
                  },
                }
              ],
            ],
          }
        ],
        headers: [
          { text: "Açıklamalar", value: "description", width: "100" , divider: true,},
          { text: "Değerler", value: "value", width: "20%", dataType: "currency" , divider: true, align: "right"},
        ],
      };
    }
  },
}
</script>

<style>
.header-div {
  text-align: center;
}
</style>