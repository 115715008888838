import { sendRequest } from "../../../frontend/components/atoms/helper/requestHelper.js";
import {
    openSnackbar,
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import Enum from "../helper/Enum.json";

export function getPlans(model, thenFunc) {
    // Hesap Kodlarını, Masraf ve Proje Kodlarını serverdan çeker ve döndürür. (çoğunlukla sayfa yüklenince çalışır)
    sendRequest(Enum.requestType.POST, "getAccountPlans", null, null,
        function (response) {
            // that.projectCodes = response.data.data.projectPlans
            // that.costCenters = response.data.data.costPlans
            model.accountCodes = response.data.data.value
            if (thenFunc != undefined) {
                return thenFunc(model)
            }
            return response.data.data.value
        },
        function (error) {
            openSnackbar("error", "Hesap kodları serverdan getirilirken hata oluştu.")
        }
    );
}