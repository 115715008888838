// https://vuetifyjs.com/en/features/treeshaking/#dynamic-components
<template>
<div>
  <login-component :dynamic-elements="dynamicElements" :model="model" />
</div>

</template>

<script>
import Enum from "../../../frontend/components/Enum.json"
import loginComponent from "../molecules/loginComponent.vue";
import {getElement} from '../../../frontend/components/atoms/helper/elementHelper.js'
import {openSnackbar} from "../../../frontend/components/atoms/helper/elementHelper.js"
export default {
    components: { 'login-component' : loginComponent  },
    data: () => ({
      dynamicElements:[],
      model:{},
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model1: []
    }),
    created() {
      this.loadData ();
    },
    methods: {
      loadData () {
        this.model = {
          email : "a@a.com",
          dateFormatted : ""
        }
        this.dynamicElements = [
          {
            type : Enum.layoutType.div,
            name : "loginForm",
            autocompleteObj: {
              id : "items",
              name : "items",
              label : "Seç",
              value : "",
              style : "width: 260px;",
              multiple : true,
              classTag : "",
              url : "autocomplete",
              items: [
                { name: 'Sandra Adams'},
                { name: 'Ali Connors'},
                { name: 'Trevor Hansen'},
                { name: 'Tucker Smith'},
                { name: 'Britta Holt'},
                { name: 'Jane Smith'},
                { name: 'John Smith'},
                { name: 'Sandra Williams'},
              ],
              color: 'blue-grey lighten-2'
            },
            inputElements : [
              [
                {
                  id : "dateFormatted",
                  type : "v-date-picker",
                  variant : "text",
                  name : "dateFormatted",
                  label : "Tarih",
                  value : "",
                  prependIcon : "fas fa-at",
                  placeHolder : "",
                  style : "width: 260px;",
                  classTag : "",
                },
                {
                  id : "select",
                  type: "v-select",
                  //variant : "number",
                  name : "select",
                  
                  label: "Muhtasar İse Tür Kodu",
                  dropDownItems: ['a','s','d'],
                  value: ['a'],
                  multiple : true
                },
                {
                  id : "radioButton",
                  type : "v-radio-group",
                  name : "radioButton",
                  label : ["Tarih","Tarih2","Tarih3"],
                  value : ["1","2","3"],
                  //user girmesin TODO !!!
                  key : ["11","22","33"],
                  classTag : "",
                },
                {
                  id : "checkbox",
                  type : "v-checkbox",
                  name : "checkbox",
                  label : "checkbox",
                  value : "tt2",
                },
                {
                  id : "checkbox1",
                  type : "v-checkbox",
                  name : "checkbox1",
                  label : "checkbox1",
                  value : "tt21",
                },
                {
                  id : "email",
                  type : "v-text-field",
                  variant : "text",
                  name : "email",
                  label : "Email",
                  
                  value : "",
                  //prependIcon : "fas fa-at",
                  placeHolder : "",
                  style : "width: 260px;",
                  classTag : "",
                  rules : [value => !!value || "Gerekli.", value => {
                      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                      return pattern.test(value) || "Geçersiz Email";
                    }
                  ]
                },
                {
                  id : "email2",
                  type : "v-text-field",
                  variant : "text",
                  name : "email2",
                  label : "Mask Test",
                  mask : '###.###.###,##',
                  value : "",
                  //prependIcon : "fas fa-at",
                  placeHolder : "",
                  style : "width: 260px;",
                  classTag : "",
                },
                
                {
                  id : "mm",
                  type : "v-money",
                  name : "mm",
                  label : "Money",
                  value : -12222221.3323,
                  money : {
                    decimal: ',',
                    thousands: '.',
                    prefix: '',
                    suffix: '',
                    precision: 2,
                  }
                },
  
                {
                  id : "forgottenPass",
                  type : "v-btn",
                  variant : "btn",
                  name : "forgottenPass",
                  inputText : 'ŞİFREMİ UNUTTUM',
                  divStyle:"float:left; ",
                  btnTypeText: true,
                  prependIcon : "lock",
                  classTag : "",
                  to : "/forgottenPageCreatEt"
                },
                {
                  id : "sendLogin",
                  type : "v-btn",
                  variant : "btn",
                  name : "sendLogin",
                  inputText : "Giriş",
                  divStyle:"float:left; ",
                  style:"font-size: 10px; padding: 3px 3px; heigth:3px; ",
                  btnTypeText: false,
                  color : "primary",
                  prependIcon : "lock",
                  classTag : "",
                  onClick : function(thisData,model,dynamicElements) {
                    model.password = "123111"
                    console.log("send login" + thisData)
                    console.log(model)
                    let element = getElement("forgottenPass",dynamicElements)
                    element.color = "success"
                    openSnackbar('success', 'Merhaba abi')
                  }
                }
              ],
              [
                {
                  id : "email2",
                  type : "v-text-field",
                  variant : "text",
                  name : "email2",
                  label : "Email",
                  value : "",
                  prependIcon : "fas fa-at",
                  placeHolder : "",
                  style : "",
                  classTag : ""
                },
                {
                  id : "password2",
                  type : "v-text-field",
                  variant : "text",
                  name : "password2",
                  label : "Şifre",
                  value : "",
                  prependIcon : "lock",
                  placeHolder : "",
                  style : "",
                  classTag : ""
                },
                {
                  id : "area",
                  type : "v-textarea",
                  variant : "text",
                  name : "area",
                  label : "Şifre",
                  value : "",
                  prependIcon : "lock",
                  placeHolder : "",
                  style : "",
                  classTag : "mx-2",
                  rows:"2",
                  clearable:true,
                  clearIcon:"mdi-close-circle"
                },
                {
                  id : "file",
                  type : "v-file-input",
                  name : "file",
                  label : "Dosya Seçiniz",
                  placeHolder : "",
                  style : "",
                  classTag : "",
                  accept : ".xlsx, .xls, .ods",
                  onChange : function(thisData,model,dynamicElements) {
                    console.log(thisData,dynamicElements)
                    console.log(model)
                  }
                },
                // {
                //   id : "auto",
                //   type : "v-autocomplete",
                  
                //   name : "auto",
                //   label : "auto",
                  
                //   style : "width: 260px;",
                //   classTag : "",
                // },
              ],
            ]
          }
        ]
        return this.dynamicElements;
      },
      remove (item) {
        const index = this.friends.indexOf(item.name)
        if (index >= 0) this.friends.splice(index, 1)
      },
    },
}
</script>