<template>
  <v-app>
    <table-component :dynamic-element="tableElements" :model="model" />

    <upload-component :dynamic-element="uploadElements" :model="model" />
    
    <Loader></Loader>
    <snackbar-component />
  </v-app>
</template>

<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import {
  openSnackbar,
  showLoader,
  openTablePage,
  getTable,
  deleteItem,
  closeDialog,
  excelDownloadDefault,
  //getTableModel,
  showDialog,
  hideLoader,
  //hashCode
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";
import config from "../../config.json";
//import {axiosUploadTypeArrayBuffer} from "../../helper/uploadHelperForData"
import {pdfZipDownload} from "../helper/pdfZipDownload"
export default {
  components: {
    "table-component": tableComponent,
    "upload-component": uploadComponent,
    Loader: Loader,
    "snackbar-component": snackbarComponent,
  },

  data() {
    return {
      uploadElements: {},
      tableElements: {},
      model: {},
      renderKey: 0,
    };
  },

  created() {
    this.loadData();
  },
  watch: {},
  methods: {
    loadData() {
      this.uploadElements = [
        {
          renderKey: 0,
          name: "sss",
          interfaceName: "KDV 1 Beyannamesi",
          width: 400,
          layout: [
            {
              type: Enum.layoutType.div,
              name: "loginForms",
              inputElements: [
                [
                  {
                    id: "logo",
                    type: "v-file-input",
                    label: "Dosyanızı Seçiniz",
                    inputHeader: "KDV 1 Beyannamesi",
                    name: "vatFile",
                    multiple: true,
                    onChange: async function(thisData, model, dynamicElements) {
                      console.log("satır sayısı");
                      console.log(thisData);
                      console.log(dynamicElements);
                      let files = model.vatFile;
                      if (model.vatFile.length > 0) {
                        showLoader("Dosyanız Yükleniyor. Lütfen Bekleyiniz..."); 
                        // , cookieData: window.$cookies.get("selectedItem") ekle
                        uploadHelperFormDataQueue(files, "declarationupload", Enum.requestType.POST, {tableName : "vatOneDeclarationTable" , cookieData: window.$cookies.get("selectedItem")}, 
                        function (response) {
                          // if (response.data.data.value == "Hata") {
                          //   openSnackbar(
                          //     "error",
                          //     "Taslak ile form uyumlu değildir. Lütfen yeniden yükleyiniz."
                          //   );
                          // } else {
                            openSnackbar("success", "Dosya Yüklendi");
                            openTablePage("vatOneDeclarationTable")
                          //}
                          hideLoader();
                        }, function (error) {
                        openSnackbar("error", error.response.data.error[0].detail.message, 2000)
                        hideLoader();
                      })
                      }
                    }
                  }
                ],
              ]
            }
          ]
        },
      ],
      
      this.tableElements = {
        clientEvents : {
          onAfterDelete :function (selectedItem,model,tableObject,res) {
            console.log(selectedItem,model,tableObject,res)
          }
        },
        sortBy: ['orderByPeriod'],
        sortDesc: false,
        renderKey: 0,
        type: "table",
        tableHeight : 430,
        name: "vatOneDeclarationTable",
        class: "",
        infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
        deleteBtnText: "Sil",
        searchBtnText: "Ara",
        excelDownloadBtnText: "İndir",
        emailBtnText: "Email",
        deleteDialogVisible : false,
        hidePagination: true,
        selectedRows: [],
        filteredColumn: {},
        filteredColumns: true,
        selectionUrl: "getdeclaration",
        deleteUrl: "declarationdeletion",
        comparisonUrl: "assistantdeclarationcomparison",
        showDefaultDeleteButon : false,
        initialFilter:{
          [Enum.initialFilter.AND] :
          [
            {
              projectId: { [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id },
            }
          ]
        },
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
              [
                {
                  id : "mizan",
                  type : "v-btn",
                  variant : "btn",
                  name : "mizan",
                  inputText : "Mizan",
                  divStyle:"",
                  style:"height: 25px !important;",
                  btnTypeText: false,
                  color : "primary",
                  prependIcon : "",
                  classTag : "",
                  onClick : function(thisData,model,dynamicElements) {
                    console.log(thisData,model,dynamicElements)
                    var vatTableObject = getTable("vatOneDeclarationTable");
                    let dialogInfo = {
                      name:"infoTableDialog",
                      title : "Seçilen Dönemler Arasındaki Mizan",
                      layout : [
                        {
                          type : Enum.layoutType.div,
                          name : "tableDialog",
                          tableObject : {
                            dialogTable: true,
                            renderKey : 15,
                            type : "table",
                            name : "infoTable",
                            class : "",
                            infoTotalRowCountHeaderAssistantTable: "Toplam satır sayısı",
                            initialFilter: {
                              [Enum.initialFilter.AND] :
                                [
                                  {
                                    projectId: { [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id },
                                  }
                                ]
                            },
                            selectionUrl : "getmizan",
                            hidePagination : false,
                            filteredColumn : vatTableObject.filteredColumn,
                            toolbars : [],
                            headers : [
                              { text: "Kebir", value: "kebir", width: "75" , search: true , sortable: true },
                              { text: "Hesap Kodu", value: "accountCode", width: "100" , search: true , sortable: true},
                              { text: "Hesap Adı", value: "accountName", search: true, class: 'threedot'},
                              { text: "Borç", value: "debt", width: "50", align: "right" , search: true, dataType: "currency"},
                              { text: "Alacak", value: "credit", width: "50", align: "right" , search: true, dataType: "currency"},
                              { text: "Bakiye Borç", value: "balanceDebt", width: "50" , align: "right" , search: true, dataType: "currency"},
                              { text: "Bakiye Alacak", value: "balanceCredit", width: "50" , align: "right" ,search: true, dataType: "currency"},
	                            { text: "Mizan Bakiye", value: "balanceMizan", width: "50", align: "right" , search: true, dataType: "currency"}
                            ],
                            data : "data2",
                            singleSelect:true,
                            itemKey: "id",
                            columns:{
                              accountCode:{
                                type : "v-text-field",
                                editable: false,
                                creatable: true,
                                inlineEditable:false,
                                cellElement : {
                                  id : "accountCode",
                                  type : "v-btn",
                                  variant : "btn",
                                  name : "accountCode",
                                  inputText : "accountCode",
                                  divStyle:"",
                                  style: "height:0px;text-decoration: underline;color: blue !important;",
                                  btnTypeText: true,
                                  color : "black",
                                  prependIcon : "",
                                  classTag : "",
                                  onClick : function(thisData,model,dynamicElements) {
                                    console.log(thisData,model,dynamicElements)
                                    let dialogInfo = {
                                      name:"infoTableDialog",
                                      title : model.accountCode + " " + "Hesap Kodu",
                                      layout : [
                                        {
                                          type : Enum.layoutType.div,
                                          name : "tableDialog",
                                          tableObject : {
                                            renderKey : 0,
                                            type : "table",
                                            name : "infoTable",
                                            class : "",
                                            infoTotalRowCountHeaderAssistantTable: "Toplam satır sayısı",
                                            selectionUrl : "getmuavin",
                                            initialFilter: {
                                              [Enum.initialFilter.AND] :
                                                [
                                                  {
                                                    projectId: { [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id },
                                                  }
                                                ]
                                            },
                                            filteredColumn: {
                                              accountCode : model.accountCode
                                            },
                                            hidePagination : false,
                                            toolbars : [],
                                            headers : [
                                              { text: "Hesap Kodu", value: "accountCode", width: "150" , search: true , sortable: true},
                                              { text: "Hesap Adı", value: "accountName", width: "250" , search: true},
                                              { text: "Tarih", value: "billDate", width: "70" , search: true, dataType: "date"},
                                              { text: "Fiş Türü", value: "receiptType", width: "160" , search: true},
                                              { text: "Fiş No", value: "receiptNumber", width: "160" , search: true},
                                              { text: "Açıklama", value: "explanation", class: 'threedot' ,search: true},
                                              { text: "Borç", value: "balance", width: "150", align: "right" , search: true, dataType: "currency"},
                                              { text: "Alacak", value: "credit", width: "150", align: "right" , search: true, dataType: "currency"},
                                              { text: "Bakiye", value: "debt", width: "150", align: "right" , search: true, dataType: "currency"},
                                              { text: "Tutar", value: "debt", width: "160", align: "right" , search: true, dataType: "currency"},
                                            ],
                                            data : "data2",
                                            singleSelect:true,
                                            itemKey: "id",
                                            columns:{
                                              receiptNumber:{
                                                type : "v-text-field",
                                                editable: false,
                                                creatable: true,
                                                inlineEditable:false,
                                                //cell için gerekli inputElement hücre özelliştirmesi için
                                                cellElement : {
                                                  id : "receiptNumber",
                                                  type : "v-btn",
                                                  variant : "btn",
                                                  name : "receiptNumber",
                                                  inputText : "receiptNumber",
                                                  divStyle:"",
                                                  style: "height:0px;text-decoration: underline;color: blue !important;",
                                                  btnTypeText: true,
                                                  color : "black",
                                                  prependIcon : "",
                                                  classTag : "",
                                                  onClick : function(thisData,model,dynamicElements) {
                                                    console.log(thisData,model,dynamicElements)
                                                    let dialogInfo = {
                                                      name:"infoTableDialog",
                                                      //maxWidth : "auto",
                                                      //dialogVisible : "dialogVisible",
                                                      title : model.receiptNumber + " " + "Yevmiye Fişi",
                                                      layout : [
                                                          {
                                                            type : Enum.layoutType.div,
                                                            name : "tableDialog",
                                                            tableObject : {
                                                              renderKey : 0,
                                                              type : "table",
                                                              name : "infoTable",
                                                              //style : "max-width: 1450px;",
                                                              class : "",
                                                              infoTotalRowCountHeaderAssistantTable: "Toplam satır sayısı",
                                                              selectionUrl : "getassistantuploadaccountcodes",
                                                              initialFilter: {
                                                                [Enum.initialFilter.AND] :
                                                                  [
                                                                    {
                                                                      projectId: { [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id },
                                                                    }
                                                                  ]
                                                              },
                                                              hidePagination : false,
                                                              filteredColumn: {
                                                                receiptNumber : model.receiptNumber,
                                                                assistantDate : model.billDate
                                                              },
                                                              toolbars : [],
                                                              headers : [
                                                                { text: "Hesap Kodu", value: "accountCode", width: "150" , search: true , sortable: true},
                                                                { text: "Hesap Adı", value: "accountName", width: "250" , search: true},
                                                                { text: "Tarih", value: "billDate", width: "70" , search: true, dataType: "date"},
                                                                { text: "Fiş Türü", value: "receiptType", width: "160" , search: true},
	                                                              { text: "Fiş No", value: "receiptNumber", width: "160" , search: true},
                                                                { text: "Açıklama", value: "explanation", class: 'threedot', search: true},
                                                                { text: "Borç", value: "balance", width: "150", align: "right" , search: true, dataType: "currency"},
                                                                { text: "Alacak", value: "credit", width: "150", align: "right" , search: true, dataType: "currency"},
                                                                { text: "Bakiye", value: "debt", width: "150", align: "right" , search: true, dataType: "currency"},
                                                                { text: "Tutar", value: "debt", width: "160", align: "right" , search: true, dataType: "currency"},
                                                              ],
                                                              data : "data2",
                                                              singleSelect:true,
                                                              itemKey: "id",
                                                              columns:{}
                                                            }
                                                          }
                                                      ],
                                                      actions : [
                                                        {
                                                          id : "close",
                                                          type : "v-btn",
                                                          variant : "btn",
                                                          name : "close",
                                                          inputText : "Kapat",
                                                          divStyle:"",
                                                          btnTypeText: true,
                                                          color:"green darken-1",
                                                          classTag : "",
                                                          onClick : function(thisData,model,dialogInfo) {
                                                            console.log(thisData,model,dialogInfo)
                                                            closeDialog()
                                                          }
                                                        }
                                                      ]
                                                    }
                                                    showDialog(dialogInfo)
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      ],
                                      actions : [
                                        {
                                          id : "close",
                                          type : "v-btn",
                                          variant : "btn",
                                          name : "close",
                                          inputText : "Kapat",
                                          divStyle:"",
                                          btnTypeText: true,
                                          color:"green darken-1",
                                          classTag : "",
                                          onClick : function(thisData,model,dialogInfo) {
                                              console.log(thisData,model,dialogInfo)
                                              closeDialog()
                                          }
                                        }
                                      ]
                                    }
                                    showDialog(dialogInfo)
                                  }
                                } 
                              } 
                            } 
                          } 
                        }
                      ],
                      actions : [
                        {
                          id: "save",
                          type: "v-btn",
                          variant: "btn",
                          name: "save",
                          inputText: "Kapat",
                          divStyle: "",
                          btnTypeText: true,
                          color: "green darken-1",
                          classTag: "",
                          onClick: function() {
                            closeDialog();
                          },
                        },
                      ]
                    }
                    
                    //var dialogObject = getTableModel("vatDeclarationTable");
                    var tableObject = getTable("vatOneDeclarationTable");
                    let companyId = tableObject.selectedRows[0].companyId , allPeriod = [] , date
                    for (let index = 0; index < tableObject.selectedRows.length; index++) {
                      let splitDate = tableObject.selectedRows[index].period.split("/")
                      if(splitDate[0].length == 1){
                        splitDate[0] = "0" + splitDate[0]
                      }
                      date = splitDate[1] + splitDate[0]
                      allPeriod.push(date)
                    }
                    allPeriod.sort(function(a, b){return b - a})
                    let maxDate = {year : allPeriod[0].substring(0 , 4) , month : allPeriod[0].substring(4 , 6) , day :  0}
                    let minDate = {year : allPeriod[allPeriod.length - 1].substring(0 , 4) , month : allPeriod[allPeriod.length - 1].substring(4 , 6) , day : 1}
                    tableObject.filteredColumn.maxDateMizan = maxDate
                    tableObject.filteredColumn.minDateMizan = minDate
                    tableObject.filteredColumn.companyId = companyId
                    showDialog(dialogInfo)
                  }
                }
              ],
              [
                {
                  id: "comparisonBtn",
                  type: "v-btn",
                  variant: "btn",
                  name: "comparisonBtn",
                  inputText: "Muavin İle Karşılaştır",
                  style:"height: 25px !important;",
                  divStyle: "",
                  btnTypeText: false,
                  color: "primary",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    showLoader( "Karşılaştırmanız Yapılıyor. Lütfen Bekleyiniz...");
                    console.log(thisData, model, dynamicElements)
                    if (dynamicElements.selectedRows.length > 0) {
                      let selectedItem = dynamicElements.selectedRows
                      // , cookieData: window.$cookies.get("selectedItem") ekle
                      sendRequest(Enum.requestType.POST, dynamicElements.comparisonUrl,"",{selectedItem, comparisonType : "vatOneDeclarationTable" , cookieData: window.$cookies.get("selectedItem")},function (res) {
                        //let excelName = window.$cookies.get("loggedUser").companyName.substring(0,10) + "_KDV1_Muavin Karşılaştırması.xlsx"
                        //let response = res.data.data.value , configHeaderArray = config.assistantAndVatDeclarationComparison , excelResult = {}, sheetNameArray = []
                        let pdfZipPath = '', zipName = ''
                        pdfZipPath = res.data.data.pdfZipPath
                        zipName = res.data.data.zipName
                        if (pdfZipPath == '' || zipName == '') {
                          hideLoader()
                          openSnackbar("error", "Zip oluşturulamadı", 2000)
                          return false
                        }
                        pdfZipDownload("pdfZipDownload", pdfZipPath, zipName, "Karşılaştırmanız başarılı bir şekilde gerçekleşti.")
                        // for (let i = 0; i < response.length; i++) {
                        //   let underAlphabetNumber = 5 , lineIndex = 4 //headerNumber = 1 ,
                        //   let header = Object.keys(response[i][0])
                        //   let sheetName = response[i][0].sheetName.split("/")[0] + "." + response[i][0].sheetName.split("/")[1]
                        //   sheetNameArray.push(sheetName)
                        //   excelResult[sheetName] = {}
                        //   var wscols = []
                        //   excelResult[sheetName]['!cols'] = wscols;
                        //   excelResult[sheetName]["!ref"] = "A1:Z540"
                        //   excelResult[sheetName]["!merges"] = []
                        //   for (let j = 0; j < configHeaderArray.length; j++) {
                        //     if(header.includes(configHeaderArray[j])){
                        //       //excelResult[sheetName]["A" + headerNumber] = {t:'s', v: configHeaderArray[j]}
                        //       if(excelResult[sheetName]["!merges"].length == 0){
                        //         excelResult[sheetName]["!merges"].push({s:{r:4,c:0},e:{r:4,c:8}})
                        //         //lineIndex = lineIndex + response[i][0][configHeaderArray[j]].length + 2
                        //       }else{
                        //         excelResult[sheetName]["!merges"].push({s:{r:lineIndex, c:0},e:{r:lineIndex, c:8}})
                        //         if (response[i][0][configHeaderArray[j]].length != 0) {
                        //           lineIndex = lineIndex + response[i][0][configHeaderArray[j]].length + 2
                        //         }
                        //       }
                        //       for (let k = 0; k < response[i][0][configHeaderArray[j]].length; k++) {
                        //         let objectKeys = Object.keys(response[i][0][configHeaderArray[j]][k])
                        //         if (configHeaderArray[j] == 'companyData') {
                        //           let rowIndexHeaders = 1
                        //           let rowIndexValue = 1
                        //           for (let comIndex = 0; comIndex < response[i][0][configHeaderArray[j]].length; comIndex++) {
                        //             if (0 == comIndex) {
                        //               excelResult[sheetName]["A1"] = {t: "s", v: response[i][0][configHeaderArray[j]][comIndex].row1Headers, s: { fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" }}, font: { bold: true, italic: false}, border: {top: {style: "thin", color: {auto: 1}},	right: {style: "thin", color: {auto: 1}},	bottom: {style: "thin", color: {auto: 1}},left: {style: "thin", color: {auto: 1}}}}}
                        //               rowIndexHeaders++
                        //             }else{
                        //               excelResult[sheetName][config.alphabet[0] + rowIndexHeaders] = {t: "s", v: response[i][0][configHeaderArray[j]][comIndex].row1Headers, s: { fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" }}, font: { bold: true, italic: false}, border: {top: {style: "thin", color: {auto: 1}},	right: {style: "thin", color: {auto: 1}},	bottom: {style: "thin", color: {auto: 1}},left: {style: "thin", color: {auto: 1}}}}}
                        //               rowIndexHeaders++
                        //             }
                        //           }
                        //           for (let comIndex = 0; comIndex < response[i][0][configHeaderArray[j]].length; comIndex++) {
                        //             if (comIndex == 0) {
                        //               excelResult[sheetName]["B1"] = {t: "s", v: response[i][0][configHeaderArray[j]][comIndex].companyName, s: { fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" }}, font: { bold: true, italic: false}, border: {top: {style: "thin", color: {auto: 1}},	right: {style: "thin", color: {auto: 1}},	bottom: {style: "thin", color: {auto: 1}},left: {style: "thin", color: {auto: 1}}}}}
                        //               rowIndexValue++
                        //             }else{
                        //               excelResult[sheetName][config.alphabet[1] + rowIndexValue] = {t: "s", v: response[i][0][configHeaderArray[j]][comIndex].companyName, s: { fill: { patternType: "solid", fgColor: { rgb: "FFFFFF" }}, font: { bold: true, italic: false}, border: {top: {style: "thin", color: {auto: 1}},	right: {style: "thin", color: {auto: 1}},	bottom: {style: "thin", color: {auto: 1}},left: {style: "thin", color: {auto: 1}}}}}
                        //               rowIndexValue++
                        //             }
                        //           }
                        //           break;
                        //         }
                        //         if (k == 0) {
                        //           for (let z = 0; z < objectKeys.length; z++) {
                        //             let rowColForDifference = "4ca2e4"
                        //             if (objectKeys[z] == "differenceVat" || objectKeys[z] == "differenceAmount" || objectKeys[z] == "checkVat" || objectKeys[z] == "checkAmount") {
                        //               rowColForDifference = "bcbcbc"
                        //             }
                        //             excelResult[sheetName][config.alphabet[z] + underAlphabetNumber] = {t:"s", v: config.defineHeaders[objectKeys[z]].name, s: { fill: { patternType: "solid", fgColor: { rgb: rowColForDifference }},font: { bold: true, italic: false}, border: {top: {style: "thin", color: {auto: 1}},	right: {style: "thin", color: {auto: 1}},	bottom: {style: "thin", color: {auto: 1}}, left: {style: "thin", color: {auto: 1}}}}}
                        //           }
                        //           underAlphabetNumber++
                        //           //headerNumber++
                        //         }
                        //         for (let z = 0; z < objectKeys.length; z++) {
                        //           wscols.push({wch:19})
                        //           if (objectKeys[z] == "differenceVat") {
                        //             if (Math.abs(response[i][0][configHeaderArray[j]][k][objectKeys[z]]) < 0.9 && Math.abs(response[i][0][configHeaderArray[j]][k][objectKeys[z]]) > 0) {
                        //               //response[i][0][configHeaderArray[j]][k][objectKeys[z]] = 0
                        //               response[i][0][configHeaderArray[j]][k]["checkVat"] = "Tutarlı"
                        //             }
                        //           }
                        //           if (objectKeys[z] == "differenceAmount") {
                        //             if (Math.abs(response[i][0][configHeaderArray[j]][k][objectKeys[z]]) < 0.9 && Math.abs(response[i][0][configHeaderArray[j]][k][objectKeys[z]]) > 0) {
                        //               //response[i][0][configHeaderArray[j]][k][objectKeys[z]] = 0
                        //               response[i][0][configHeaderArray[j]][k]["checkAmount"] = "Tutarlı"
                        //             }
                        //           }
                        //           let rowCol = "FFFFFF"
                        //           if (response[i][0][configHeaderArray[j]][k][objectKeys[z]] == "Tutarsız") {
                        //             rowCol = "dd180a"
                        //           }else if(response[i][0][configHeaderArray[j]][k][objectKeys[z]] == "Tutarlı"){
                        //             rowCol = "52c404"
                        //           }
                        //           excelResult[sheetName][config.alphabet[z] + underAlphabetNumber] = {t:config.defineHeaders[objectKeys[z]].type, v: response[i][0][configHeaderArray[j]][k][objectKeys[z]], s: { fill: { patternType: "solid", fgColor: { rgb: rowCol }}, font: { bold: true, italic: false}, border: {top: {style: "thin", color: {auto: 1}},	right: {style: "thin", color: {auto: 1}},	bottom: {style: "thin", color: {auto: 1}},left: {style: "thin", color: {auto: 1}}}}}
                        //         }
                        //         underAlphabetNumber++
                        //         //headerNumber++
                        //       }
                        //       //headerNumber++
                        //       underAlphabetNumber++
                        //     }
                        //   }
                        //   excelResult[sheetName]['!cols'] = wscols;                   
                        // }
                        // excelDownloadDefault(sheetNameArray, excelResult, excelName)
                        
                        // burda excel dosyası hazır. pdfkit yada pdfmake çağırsak olur mu
                        //hideLoader()
                        //openSnackbar('success', 'Karşılaştırmanız başarılı bir şekilde gerçekleşti.')
                      })
                    }else{
                      hideLoader()
                      openSnackbar('error', 'Lütfen karşılaştırmak istediğiniz aylara göre satır seçimlerinizi yapınız')
                    }
                  },
                },
              ],
              [
                {
                id: "search",
                type: "v-btn",
                variant: "btn",
                name: "search",
                inputText: "searchBtnText",
                style:"height: 25px !important;",
                divStyle: "",
                btnTypeText: false,
                color: "primary",
                prependIcon: "",
                classTag: "",
                onClick: async function(thisData, model, dynamicElements) {
                  var tableObject = getTable("vatOneDeclarationTable");
                  if (tableObject.data.length > 0) {
                    console.log(thisData);
                    console.log(dynamicElements);
                    console.log(model);
                    openTablePage("vatOneDeclarationTable")
                  } else {
                    alert("Veriniz mevcut değildir");
                  }
                }
                }
              ],
              [
                {
                  id : "delete",
                  type : "v-btn",
                  variant : "btn",
                  name : "delete",
                  inputText : "deleteBtnText",
                  divStyle:"",
                  style:"height: 25px !important;",
                  btnTypeText: false,
                  color : "primary",
                  prependIcon : "",
                  classTag : "",
                  onClick : function(thisData,model,dynamicElements) {
                    console.log("send delete" + thisData + dynamicElements)
                    let options = {
                      showDialog : true
                    }
                    deleteItem("vatOneDeclarationTable",dynamicElements.selectedRows,options,model)
                  }
                }
              ],
              [
                {
                  id: "email",
                  type: "v-btn",
                  variant: "btn",
                  name: "email",
                  inputText: "emailBtnText",
                  style:"height: 25px !important;",
                  divStyle: "",
                  btnTypeText: false,
                  icon: "mark_email_read",
                  color: "primary",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    console.log(thisData, model, dynamicElements)
                  },
                }
              ],
              // [
              //   {
              //     id: "excelDownload",
              //     type: "v-btn",
              //     variant: "btn",
              //     name: "excelDownload",
              //     inputText: "excelDownloadBtnText",
              //     style:"height: 25px !important;",
              //     divStyle: "",
              //     icon: "mdi-file-excel",
              //     btnTypeText: false,
              //     color: "success",
              //     prependIcon: "",
              //     classTag: "",
              //     onClick: function(thisData, model, dynamicElements) {
              //       if (dynamicElements.selectedRows.length != 1) {
              //         openSnackbar('error', "Bir Tane Beyanname Seçmelisiniz.", 3000)
              //         return false
              //       }
              //       showLoader('Dosyanız Hazırlanıyor')
              //       sendRequest(Enum.requestType.POST,"vatOneExcelDownload","",
              //         {
              //           excelDownload : true,
              //           choice : "vatOneTable",
              //           cookieData: window.$cookies.get("selectedItem"),
              //           selectedItem: dynamicElements.selectedRows
              //         },
              //         function (response) {

              //           if (response.data.data.data != true) {
              //             openSnackbar('error', "İndirilecek Veri Bulunamadı", 3000)
              //             hideLoader();
              //             return false
              //           }
              //           let zipName = "Sigorta Rapor"
              //           axiosUploadTypeArrayBuffer("allExcelZipDownload", "vatOneTable", zipName, "Excel İndirildi")
              //         },
              //         function (error) {
              //           openSnackbar(
              //             "error",
              //             error.response.data.error[0].detail.message
              //           );
              //           hideLoader();
              //         }
              //       )
              //     },
              //   }
              // ],
            ],
          }
        ],
        headers: [
          { text: "Vergi Kimlik No", value: "taxNumber", width: "100" , search: true , sortable: true},
          { text: "Dönem", value: "fakePeriod", width: "100" , search: true , sortable: true},
          { text: "Dosya Yükleme Tarihi", value: "uploadDate", width: "100" , search: true , sortable: true , dataType: "date"}
        ],
        columns: {

        },
        data: "data",
        showSelecetVisible: true,
        singleSelect: false,
        itemKey: "id",
        footer: [
          {
            type: Enum.layoutType.div,
            name: "footerForm",
            inputElements: [
              [
                {
                  id: "pageSize",
                  type: "v-text-field",
                  variant: "number",
                  name: "pageSize",
                  label: "Görünecek Satır Sayısı",
                  value: "",
                  placeHolder: "",
                  style: "width: 45%; margin-left: 5%",
                  classTag: "",
                  onChange: function(thisData, model, dynamicElements) {
                    openTablePage("vatOneDeclarationTable")
                    var tableObject = getTable("vatOneDeclarationTable");
                    for (let i = 0; i < tableObject.data.length; i++) {
                      tableObject.data[i].kebir = tableObject.data[i].accountCode.substring(0, 3);
                    }
                    console.log(thisData);
                    console.log(dynamicElements);
                    console.log(model);
                  },
                },
              ],
              [
                {
                  id: "currentPageNo",
                  type: "v-pagination",
                  variant: "pagination",
                  name: "currentPageNo",
                  divStyle: "",
                  style: "width:850px;",
                  classTag: "my-1",
                  length: "pagesCount",
                  onInput: function(thisData, model, dynamicElements) {
                    openTablePage("vatOneDeclarationTable")
                    console.log(thisData);
                    console.log(dynamicElements);
                    console.log(model);
                  },
                },
              ],
              [
                {
                  id: "infoTotalRowCountHeaderAssistantTable",
                  type: "p",
                  variant: "p",
                  name: "infoTotalRowCountHeaderAssistantTable",
                  inputText: "infoTotalRowCountHeaderAssistantTable",
                  divStyle: "",
                  style: "font-size: 12px;font-weight:500;margin:3px 0;color:#666A6F;margin-right: 2%;margin-left: 60%;",
                  classTag: ""
                },
                {
                  id: "infoTotalRowCountAssistantTable",
                  type: "p",
                  variant: "p",
                  name: "infoTotalRowCountAssistantTable",
                  inputText: "itemCount",
                  divStyle: "",
                  style: "font-size: 15px;font-weight:500;margin:3px 0;color:#666A6F;margin-left: 82%;",
                  classTag: ""
                },
              ],
            ],
          },
        ]
      };
    }
  },
};
</script>

<style scoped>
::v-deep .threedot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 300px;
}
::v-deep .threedot:hover {
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}
</style>
