<template>
  <v-app>
    <table-component :dynamic-element="inflationAccountElements" :model="model" />
    <v-row>
        <v-col cols="2" style="margin-top: 0px; margin-left: 82%;">
            <v-text-field 
                outline
                hide-details
                disabled
                label="Ertlenmiş Vergi Etkisi Genel Toplam"
                type="text"
                v-model="sumtable"
                >
            </v-text-field>
        </v-col>
    </v-row>
    <Loader></Loader>
    <snackbar-component />
  </v-app>
</template>

<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import {
  openSnackbar,
  openTablePage,
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import { sendRequest } from "../../../frontend/components/atoms/helper/requestHelper";

export default {
  components: {
    "table-component": tableComponent,
    "upload-component": uploadComponent,
    Loader: Loader,
    "snackbar-component": snackbarComponent,
  },

  data() {
    return {
      inflationAccountElements: {},
      model: {},
      renderKey: 0,
      required : (value) => !!value  || "Gerekli.",
      sumtable : ""
    };
  },

  created() {
    this.loadData();
    let that = this;
    sendRequest(Enum.requestType.POST,"createddeferredTaxQuery",null,{projectId : window.$cookies.get("selectedItem").id},
      function(response){
        that.sumtable = currencyFormat(response.data.data.value[0].sumtable ,2,3,",",".");
      },             
    )    
  },
  watch: {},
  methods: {
    loadData() {
      let that = this;
      this.inflationAccountElements = {
        clientEvents : {
            onBeforeSave :function (selectedItem,creatableObjects,model,tableObject) {
                
            },
            onafterUpload : function (dynamicElements) {
              // let that = this;
              // sendRequest(Enum.requestType.POST,"createddeferredTaxQuery",null,null,
              //   function(response){
              //   console.log("hüso")
              //   that.account = response.data.data.value[0].sumtable
              //   },             
              // )
            },
            onBeforeDelete :function (selectedItem,model,tableObject,res) {
            
            } 
        },
        sortBy: ["accountCode"],
        sortDesc: false,
        renderKey: 0,
        type: "table",
        tableHeight : 420,
        name: "deferredTax",
        class: "",
        infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
        //deleteBtnText: "Sil",
        excelDownloadBtnText: "İndir",
        //saveBtnText : 'Yeni Kayıt',
        saveBtnSettings : {
          color: "blue",
          style:"height: 25px !important; ",
        },
        deleteBtnSettings : {
          color: "error",
          style:"height: 25px !important; ",
        },
        deleteDialogVisible : false,
        hidePagination: true,
        selectedRows: [],
        filteredColumn: {},
        filteredColumns: true,
        selectionUrl: "gettabledata",
        //deleteUrl: "deleteTable",
        //saveUrl : "updateTable",
        crudRequestObj : {
          placeOfAction : "inflationAccountingTrialBalance",
          projectId : window.$cookies.get("selectedItem").id,
          company_id: window.$cookies.get("selectedItem").companyId,
          auditDate: window.$cookies.get("selectedItem").auiditDate,
          preriorDate: window.$cookies.get("selectedItem").preriorDate,
        },
        initialFilter:{
          [Enum.initialFilter.AND] :
          [
            {
              company_id: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").companyId
              },project_id: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id
              },is_active: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").isActive
              }
            }
          ]
        },
        showDefaultUpdateButon : true,
        showDefaultDeleteButon : true,
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
                [//{
                //     id : "openingBalance",
                //     type : "v-text-field",
                //     variant : "text",
                //     name : "openingBalance",
                //     label : "Açılış Dönem Vergi Oranı",
                //     value : "",
                //     placeHolder : "",
                //     style : "width: 260px;",
                //     classTag : "",
                //     rules: [this.required]
                // }
                {
                    id : "openingBalance",
                    type: "v-money",
                    variant : "text",
                    name : "openingBalance",
                    label : "Açılış Endexleme Tutarı",
                    value : "",
                    placeHolder : "",
                    style : "width: 260px;",
                    classTag : "",
                    rules: [this.required],
                    money : {
                      decimal: ',',
                      thousands: '.',
                      prefix: '',
                      suffix: '',
                      precision: 2,
                      masked: false
                    },    
                }

                ],
                [{
                    id : "currentPeriodTaxRate",
                    type: "v-money",
                    variant : "text",
                    name : "currentPeriodTaxRate",
                    label : "Cari Dönem Vergi Oranı",
                    value : "",
                    placeHolder : "",
                    style : "width: 260px;",
                    classTag : "",
                    rules: [this.required],
                    money : {
                      decimal: ',',
                      thousands: '.',
                      prefix: '',
                      suffix: '',
                      precision: 2,
                      masked: false
                    },    
                }],
                [{
                    id : "calculateBalance",
                    type : "v-btn",
                    variant : "btn",
                    name : "calculateBalance",
                    inputText : 'Güncelle',
                    color: "primary",
                    divStyle:"margin-top : 10px!important ",
                    btnTypeText: false,
                    prependIcon : "",
                    classTag : "",
                    disabled :false,
                    onClick: function(thisData, model, dynamicElements) {
                      model.currentPeriodTaxRate = parseFloat(model.currentPeriodTaxRate.replaceAll('.','').replace(',','.'));
                      model.openingBalance = parseFloat(model.openingBalance.replaceAll('.','').replace(',','.'));
                      if(model.currentPeriodTaxRate >= 0 && model.currentPeriodTaxRate <= 1 && model.openingBalance >= 0 && model.openingBalance <= 1){
                        let editableObjects = {}
                        let current_tax_rate = model.currentPeriodTaxRate
                        let past_tax_rate = model.openingBalance
                        editableObjects = {current_tax_rate,past_tax_rate}
                        sendRequest("POST","deferredTax","",{projectId : window.$cookies.get("selectedItem").id,editableObjects ,auditDate: window.$cookies.get("selectedItem").auiditDate, preriorDate: window.$cookies.get("selectedItem").preriorDate,tableName : "accountGroup", tableKey : "endex", sequl : "id", deferredTax : true, fliterTableName :"v_deferred_tax"},
                          function(response){
                              openTablePage("deferredTax")
                              openSnackbar("success", response.data.data.value)
                          },             
                        )
                      }else{
                        openSnackbar("error", "Lütfen 0-1 arasında bir değer giriniz")
                      }
                      
                    },
                }],
            ],
          }
        ],
        headers: [
            { text: "Hesap Kodu", value: "accountCode",  search: true , sortable: true},
            { text: "Hesap Adı", value: "accountName",  search: true , sortable: true, class: "threedot"},
            { text: "Hesap Tipi", value: "monetaryTypeString", search: true , sortable: true},
            { text: "Açılış Endexleme Tutarı", value: "openingDifferenceEndexBalance",  search: true , sortable: true, dataType: "currency"},
            { text: "Cari Endexleme Tutarı", value: "currentPeriodBalance",  search: true , sortable: true, dataType: "currency"},
            { text: "Geçmiş Dönem Vergi Oranları", value: "pastTaxRate",  search: true , sortable: true, dataType: "currency"},
            { text: "Cari Dönem Vergi Oranları", value: "currentTaxRate",  search: true , sortable: true, dataType: "currency"},
            { text: "Açılış Endeksleme Farkı Ertelenmiş Vergi Etkisi", value: "x1",  search: true , sortable: true, dataType: "currency"},
            { text: "Cari Dönem Endeksleme Farkı Ertelenmiş Vergi Etkisi", value: "x2",  search: true , sortable: true, dataType: "currency"},
            { text: "Toplam Ertelenmiş Vergi Etkisi", value: "taxResult",  search: true , sortable: true, dataType: "currency"},
            //{ text: "Cari dönem endexleme(699) ", value: "currentPeriodIndexationDifference",  search: true , sortable: true, dataType: "currency"},
        ],
        columns: {
          accountCode: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: false,
            label : "Hesap Kodu",
            rules : [this.required],
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              editableObjects["account_code"] = model[thisData.id]
              sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "accountPlan", tableKey : "account_code", forKey : "subAccountDetailId", sequl : "id"},
              function(response){
                openTablePage("deferredTax")
                openSnackbar("success", response.data.data.value)
              }, 
              function(error){
                if (error.response != undefined){
                  openSnackbar("error", error.response.data.error[0].detail.message)
                } else (
                  openSnackbar("error", "Sunucu Hatası")
                )
              })
            }

          },
          accountName: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: false,
            label : "Hesap Adı",
            rules : [this.required],
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              editableObjects["account_name"] = model[thisData.id]
              sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "accountPlan", tableKey : "account_name", forKey : "accountPlanId", sequl : "id",  fliterTableName :"t_account_detail"},function(response){
                openTablePage("deferredTax")
                openSnackbar("success", response.data.data.value)
              }, 
              function(error){
                if (error.response != undefined){
                  openSnackbar("error", error.response.data.error[0].detail.message)
                } else (
                  openSnackbar("error", "Sunucu Hatası")
                )
              })
            }
          },
          monetaryTypeString: {
             type: "v-select",
            variant: "number",
            editable: true,
            creatable: true,
            inlineEditable: false,
            label: "Hesap Tipi",
            dropDownItems: ["PARASAL", "PARASAL OLMAYAN"],
            rules: [this.required],
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {};
              let modelmonetaryTypeString = ""
              model[thisData.id] == "PARASAL" ? modelmonetaryTypeString = 0 :   modelmonetaryTypeString = 1
              editableObjects["monetary_type"] = modelmonetaryTypeString;              
              sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "accountPlan", tableKey : "account_name", forKey : "accountPlanId", sequl : "id",  fliterTableName :"t_account_detail"},
                function(response) {
                  openSnackbar("success", response.data.data.value);
                  openTablePage("deferredTax")
                },
                function(error) {
                  if (error.response != undefined) {
                    openSnackbar(
                      "error",
                      error.response.data.error[0].detail.message
                    );
                  } else openSnackbar("error", "Sunucu Hatası");
                }
              );
            }
          },
          openingEndexBalance: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: false,
            label : "Açılış Endexleme Tutarı",
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              editableObjects["opening_endex_balance"] = model[thisData.id]
              sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "accountGroup", tableKey : "opening_endex_balance", forKey : "id", sequl : "id"},function(response){
                openTablePage("deferredTax")
                openSnackbar("success", response.data.data.value)
              }, 
              function(error){
                if (error.response != undefined){
                  openSnackbar("error", error.response.data.error[0].detail.message)
                } else (
                  openSnackbar("error", "Sunucu Hatası")
                )
              })
            }
          },
          currentPeriodBalance: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: false,
            label : "Cari Endexleme Tutarı",
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              editableObjects["current_period_balance"] = model[thisData.id]
              sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "accountGroup", tableKey : "current_period_balance", forKey : "id", sequl : "id"},function(response){
                openTablePage("deferredTax")
                openSnackbar("success", response.data.data.value)
              }, 
              function(error){
                if (error.response != undefined){
                  openSnackbar("error", error.response.data.error[0].detail.message)
                } else (
                  openSnackbar("error", "Sunucu Hatası")
                )
              })
            }
          },
          pastTaxRate: {
            type: "v-money",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Geçmiş Dönem Vergi Oranları",
            value : "",
            money : {
              decimal: ',',
              thousands: '.',
              prefix: '',
              suffix: '',
              precision: 2,
            },  
            onSave: function (thisData, model, dynamicElements) {
              model[thisData.id] = parseFloat(model[thisData.id].replaceAll('.','').replace(',','.'));
              if(model[thisData.id] >= 0 && model[thisData.id] <= 1){
                let that = this;
                let editableObjects = {}
                editableObjects["past_tax_rate"] = model[thisData.id]
                sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "accountGroup", tableKey : "past_tax_rate", forKey : "id", sequl : "id"},function(response){
                  openTablePage("deferredTax")
                  openSnackbar("success", response.data.data.value)
                }, 
                function(error){
                  if (error.response != undefined){
                    openSnackbar("error", error.response.data.error[0].detail.message)
                  } else (
                    openSnackbar("error", "Sunucu Hatası")
                  )
                })
              }
              else{
                openSnackbar("error", "Lütfen 0-1 arasında bir değer giriniz");
              }
            }
          },
          currentTaxRate: {
            type: "v-money",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Cari Dönem Vergi Oranı",
            value : "",
            money : {
              decimal: ',',
              thousands: '.',
              prefix: '',
              suffix: '',
              precision: 2,
              masked: false
            }, 
            onSave: function (thisData, model, dynamicElements) {
              model[thisData.id] = parseFloat(model[thisData.id].replaceAll('.','').replace(',','.'));
              if(model[thisData.id] >= 0 && model[thisData.id] <= 1){
                let that = this;
                let editableObjects = {}
                editableObjects["current_tax_rate"] = model[thisData.id]
                sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "accountGroup", tableKey : "current_tax_rate", forKey : "id", sequl : "id"},function(response){
                  openTablePage("deferredTax")
                  openSnackbar("success", response.data.data.value)
                }, 
                function(error){
                  if (error.response != undefined){
                    openSnackbar("error", error.response.data.error[0].detail.message)
                  } else (
                    openSnackbar("error", "Sunucu Hatası")
                  )
                })
              }
              else{
                openSnackbar("error", "Lütfen 0-1 arasında bir değer giriniz");
              }
            }
          },
        },
        data: "data",
        showSelecetVisible: false,
        singleSelect: false,
        itemKey: "id",
        footer: [
          {
            type: Enum.layoutType.div,
            name: "footerForm",
            inputElements: [
              [
                {
                  id: "pageSize",
                  type: "v-text-field",
                  variant: "number",
                  name: "pageSize",
                  label: "Görünecek Satır Sayısı",
                  value: "",
                  placeHolder: "",
                  style: "width: 45%; margin-left: 5%",
                  classTag: "",
                  onChange: function(thisData, model, dynamicElements) {
                    openTablePage("deferredTax")
                  },
                },
              ],
              [
                {
                  id: "currentPageNo",
                  type: "v-pagination",
                  variant: "pagination",
                  name: "currentPageNo",
                  divStyle: "",
                  style: "width:850px;",
                  classTag: "my-1",
                  length: "pagesCount",
                  onInput: function(thisData, model, dynamicElements) {
                    openTablePage("deferredTax")                  
                    },
                },
              ],
              [
                {
                  id: "infoTotalRowCountHeaderAssistantTable",
                  type: "p",
                  variant: "p",
                  name: "infoTotalRowCountHeaderAssistantTable",
                  inputText: "infoTotalRowCountHeaderAssistantTable",
                  divStyle: "",
                  style: "font-size: 12px;font-weight:500;margin:3px 0;color:#666A6F;margin-right: 2%;margin-left: 60%;",
                  classTag: ""
                },
                {
                  id: "infoTotalRowCountAssistantTable",
                  type: "p",
                  variant: "p",
                  name: "infoTotalRowCountAssistantTable",
                  inputText: "itemCount",
                  divStyle: "",
                  style: "font-size: 15px;font-weight:500;margin:3px 0;color:#666A6F;margin-left: 82%;",
                  classTag: ""
                },
              ]
            ],
          },
        ]
      };
     
    }
  },
};
function currencyFormat(num, decimalLength, thousandLength, decimalSeparator, thousandSeparator) {
        let number = typeof num
        if ("number" == number) {
          if(decimalSeparator == thousandSeparator || isNaN(num))
          throw num
          let left = num.toFixed(thousandLength).replace('.', decimalSeparator).replace(new RegExp('(\\d)(?=(\\d{'+thousandLength+'})+(?!\\d))', 'g'), '$1' + thousandSeparator).split(decimalSeparator)[0];
          let right = num.toFixed(decimalLength).replace('.', decimalSeparator).replace(new RegExp('(\\d)(?=(\\d{'+decimalLength+'})+(?!\\d))', 'g'), '$1' + thousandSeparator).split(decimalSeparator)[1];
          const total = left + decimalSeparator + right;
          return total
        }else{
          return num
        }
}
</script>

<style scoped>
::v-deep .threedot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 300px;
}
::v-deep .threedot:hover {
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}
</style>
