import { sendRequest } from "../../../frontend/components/atoms/helper/requestHelper.js";
import Enum from "../helper/Enum.json";
import { router } from "../router/router.js";
//import informationDialog from "../../../frontend/components/atoms/container/informationDialog.vue";
import {
  openSnackbar,
  showLoader,
  hideLoader,
  closeDialogTable,
  showDialogTable,
  openTablePage
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import store from "../store";



export function updateImportErpButton(erpName,tableElements,invoiceType,routeType) {
    if (erpName == 'Logo') {
        tableElements.toolbars[0].inputElements[9] =[
          {
            id: "addLOGO",
            type: "v-btn",
            variant: "btn",
            name: "addLOGO",
            inputText: "LOGO Aktar",
            showInput: routeType == 'reviewed' ,
            divStyle: "",
            small:true,
            color:"#294060",
            style: "height: 25px !important;margin-top: 8px;color:white",
            btnTypeText: false,
            prependIcon: "",
            classTag: "",
            onClick: function (thisData, model, dynamicElements) {
              if (store.state.logoErpAndReciptKeyState == false && dynamicElements.name == "receiptTable")
              {
                let dialogInfo = {
                  name: "infoTableDialog",
                  // title: dynamicElements.selectedRows.length == 0 ? "Aktarılacak Kayıt Sayısı: " +  model.itemCount : "Aktarılacak Kayıt Sayısı: " +  dynamicElements.selectedRows.length ,
                  title: "Yevmiye Fiş Tarihi",
                  maxWidth : "600px",
                  layout: [
                    {
                      type: Enum.layoutType.div,
                      name: "infoDialog",
                      colStyle : "width: 100%;",
                      inputElements : [
                        [
                          {
                            id : "dateFormattedLogo",
                            type : "v-date-picker",
                            variant : "text",
                            name : "dateFormattedLogo",
                            hint : "",
                            label : "Aktarılacak Tarih",
                            value : "",
                            prependIcon : "fas fa-at",
                            placeHolder : "",
                            style : "width: 50%;",
                            classTag : ""
                          },
                          {
                            id: "selectedDateWhenImportingToLogo",
                            type: "v-checkbox",
                            name: "selectedDateWhenImportingToLogo",
                            color: "blue",
                            label: "Yevmiye Kaydı tek tarihe atansın mı? Farklı Tarihlere Atamak İstiyorsanız Sadece Logo Aktar Butonuna Basınız.",
                            style: "width:350px",
                            classTag: "mb-5",
                            hideDetails: false,
                            require:true,
                            // showInput: that.$store.state.logoErpAndReciptKeyState ? true : false
                          },
                          {
                            id: "currentStatementSelection",
                            type: "v-checkbox",
                            name: "currentStatementSelection",
                            color: "blue",
                            label: "Cari Açıklamasını özelleştirmek istermisiniz?",
                            style: "width:350px",
                            classTag: "mb-5",
                            hideDetails: false,
                            require:true,
                            onChange : function (thisData, model, dynamicElements) {
                              dynamicElements.dialogInfo.layout[0].inputElements[0][3].showInput = model.currentStatementSelection
                            }
                          },
                          {
                            id: "currentStatement",
                            type: "v-text-field",
                            name: "currentStatement",
                            variant: "text",
                            label: "Açıklamayı giriniz",
                            placeHolder: "Açıklamayı giriniz",
                            color: "blue",
                            style: "width:350px",
                            hint: "Açıklamayı giriniz",
                            hideDetails: false,
                            clearable: true,
                            singleLine: true,
                            require:true,
                            showInput: false
                          },
                        ]
                      ]
                    },
                  ],
                  actions: [
                    {
                      id: "save",
                      type: "v-btn",
                      variant: "btn",
                      name: "save",
                      inputText: "Logo Aktar",
                      divStyle: "",
                      btnTypeText: true,
                      color: "green darken-1",
                      classTag: "",
                      onClick: function (thisData,model,dialogInfo) {
                        if (model.dateFormattedLogo != "" &&  model.dateFormattedLogo != null && model.selectedDateWhenImportingToLogo == true) {
                          if (dynamicElements.selectedRows.length > 0) {
                            let updatedArray = [], controlFlag = false
                            let dateFormattedLogo = model.dateFormattedLogo
                            for (let i = 0; i < dynamicElements.selectedRows.length; i++) {
                              if (dynamicElements.selectedRows[i].control == 1) {
                                controlFlag = true
                              }
                              updatedArray.push(dynamicElements.selectedRows[i].uuId)
                            }
                            let calculateThenFunc = function () {
                              router.push('folderNotification');
                              hideLoader();
                            }
                            accountingCalculateLOGO(model.currentStatement, invoiceType, updatedArray, 'Logo', dateFormattedLogo, model.selectedDateWhenImportingToLogo, calculateThenFunc)
                          }else{
                            openSnackbar("error", "Lütfen Muhasebeleştirmek İstediğiniz Satırların Seçimi Yapınız.",2000);
                            hideLoader();
                          }
                        }else if ((model.dateFormattedLogo == "" ||  model.dateFormattedLogo == null) && model.selectedDateWhenImportingToLogo == undefined){
                          if (dynamicElements.selectedRows.length > 0) {
                            let updatedArray = [], controlFlag = false
                            let dateFormattedLogo = model.dateFormattedLogo
                            for (let i = 0; i < dynamicElements.selectedRows.length; i++) {
                              if (dynamicElements.selectedRows[i].control == 1) {
                                controlFlag = true
                              }
                              updatedArray.push(dynamicElements.selectedRows[i].uuId)
                            }
                            let calculateThenFunc = function () {
                              router.push('folderNotification');
                              hideLoader();
                            }
                            accountingCalculateLOGO(model.currentStatement,invoiceType, updatedArray, 'Logo', dateFormattedLogo, model.selectedDateWhenImportingToLogo, calculateThenFunc)
                          }else{
                            openSnackbar("error", "Lütfen Muhasebeleştirmek İstediğiniz Satırların Seçimi Yapınız.",2000);
                            hideLoader();
                          }
                        }else{
                          openSnackbar("error", "Lütfen Tarih Seçiniz.",2000);
                        }
                      },
                    },
                    {
                      id: "close",
                      type: "v-btn",
                      variant: "btn",
                      name: "close",
                      inputText: "Kapat",
                      divStyle: "",
                      btnTypeText: true,
                      color: "green darken-1",
                      classTag: "",
                      onClick: function (thisData,model,dialogInfo) {
                        closeDialogTable(dynamicElements);
                        //closeDialog();
                        model.dateFormattedLogo = ""
                      }
                    }
                  ],
                };
                showDialogTable(dynamicElements,dialogInfo);
              }
              else{
                showLoader("Lütfen Bekleyiniz...")
                if (dynamicElements.selectedRows.length > 0) {
                  let updatedArray = [], controlFlag = false
                  for (let i = 0; i < dynamicElements.selectedRows.length; i++) {
                    if (dynamicElements.selectedRows[i].control == 1) {
                      controlFlag = true
                    }
                    updatedArray.push(dynamicElements.selectedRows[i].uuId)
                  }
                  let calculateThenFunc = function () {
                    router.push('folderNotification');
                    hideLoader();
                  }
                    accountingCalculateLOGO(model.currentStatement,invoiceType, updatedArray, 'Logo', undefined,undefined, calculateThenFunc)
                }else{
                  openSnackbar("error", "Lütfen Muhasebeleştirmek İstediğiniz Satırların Seçimi Yapınız.",2000);
                  hideLoader();
                }

              }
              //showDialog(dialogInfo);

              // showLoader("Lütfen Bekleyiniz...")
              // if (dynamicElements.selectedRows.length > 0) {
              //   let updatedArray = [], controlFlag = false
              //   for (let i = 0; i < dynamicElements.selectedRows.length; i++) {
              //     if (dynamicElements.selectedRows[i].control == 1) {
              //       controlFlag = true
              //     }
              //     updatedArray.push(dynamicElements.selectedRows[i].uuId)
              //   }
              //   let calculateThenFunc = function () {
              //     router.push('folderNotification');
              //     hideLoader();
              //   }
              //     accountingCalculateLOGO(invoiceType, updatedArray, 'Logo', calculateThenFunc)
              // }else{
              //   openSnackbar("error", "Lütfen Muhasebeleştirmek İstediğiniz Satırların Seçimi Yapınız.",2000);
              //   hideLoader();
              // }
            },
          },
        ]
      }
      else if(erpName == 'Luca'){
        tableElements.toolbars[0].inputElements[9] =[
          {
            id: "addLuca",
            type: "v-btn",
            variant: "btn",
            name: "addLuca",
            inputText: "Luca Aktar",
            showInput: routeType == 'reviewed' ,
            small:true,
            divStyle: "",
            style: "height: 25px !important;margin-top: 8px;color:white",
            btnTypeText: false,
            color:"#294060",
            prependIcon: "",
            classTag: "",
            onClick: function (thisData, model, dynamicElements) {
              if (model.itemCount > 0) {
                let dialogInfo = {
                  name: "infoTableDialog",
                  title: dynamicElements.selectedRows.length == 0 ? "Aktarılacak Kayıt Sayısı: " +  model.itemCount : "Aktarılacak Kayıt Sayısı: " +  dynamicElements.selectedRows.length ,
                  maxWidth : "600px",
                  layout: [
                    {
                      type: Enum.layoutType.div,
                      name: "infoDialog",
                      colStyle : "width: 100%;",
                      inputElements : [
                        [
                          {
                            id : "dateFormattedLuca",
                            type : "v-date-picker",
                            variant : "text",
                            name : "dateFormattedLuca",
                            hint : "",
                            label : "Aktarılacak Tarih",
                            value : "",
                            prependIcon : "fas fa-at",
                            placeHolder : "",
                            style : "width: 50%;",
                            classTag : "",
                            calendarType : "month",
                          },
                        ]
                      ]
                    },
                  ],
                  actions: [
                    {
                      id: "save",
                      type: "v-btn",
                      variant: "btn",
                      name: "save",
                      inputText: "Luca Aktar",
                      divStyle: "",
                      btnTypeText: true,
                      color: "green darken-1",
                      classTag: "",
                      onClick: function (thisData,model,dialogInfo) {
                        let that = this
                        if (model.dateFormattedLuca != "" &&  model.dateFormattedLuca != null) {
                          showLoader("Lütfen Bekleyiniz...");
                          let uuIds = []
                          for (let i = 0; i < dynamicElements.selectedRows.length; i++) {
                            uuIds.push(dynamicElements.selectedRows[i].uuId)
                          }
                          let filteredData = {};
                          let filterObjKeys = Object.keys(dynamicElements.filteredColumn)
                          if (filterObjKeys.length > 0) {
                            filteredData = dynamicElements.filteredColumn
                          }
                          /// filter için de yazılması gerekiyor.
                          let betweenDateData = {
                            startDate : "",
                            endDate : ""
                          }
                          if (uuIds.length == 0) {
                            let tableModel = model[dialogInfo.name]
                            if (tableModel.getDataWithDateStarted != "" &&  tableModel.getDataWithDateEnd != "") {
                              betweenDateData.startDate  = tableModel.getDataWithDateStarted
                              betweenDateData.endDate  = tableModel.getDataWithDateEnd
                            }
                          }
                          let processType = { extractData : 1, importData : 2 ,normalImport : 3} 
                          let pageJson = { token: '', processType : processType.importData, items : [],inputValues : {
                            transferLucaDate: model.dateFormattedLuca,
                            requestDefault :  {
                              type : invoiceType,
                              updateData : [],
                              selectchoice : "LUCAEXTANSIONS",
                              transferLucaDate : model.dateFormattedLuca,
                              uuIds : uuIds,
                              betweenDateData,
                              filteredData,
                              receiptCount  :  model[dialogInfo.name].itemCount},
                              uuIds : uuIds
                            }
                          };
                          // hideLoader();
                          // return tableElements.LucaTransfer(pageJson)
                          sendRequest(
                            Enum.requestType.POST,"botController","",
                            {
                              type : invoiceType,
                              updateData : [],
                              selectchoice : "LUCA",
                              transferLucaDate : model.dateFormattedLuca,
                              uuIds : uuIds,
                              betweenDateData,
                              filteredData,
                              receiptCount  :  model[dialogInfo.name].itemCount
                            },
                            function (response) {
                              hideLoader();
                              closeDialogTable(dynamicElements);
                              //closeDialog();
                              //openSnackbar( "success", "Verileriniz Lucaya başarılı bir şekilde kayıt edildi.", 3000);
                              router.push('folderNotification')
                            },
                            function (error) {
                              hideLoader()
                              openSnackbar("error", error.response.data.error[0].detail.message);
                            }
                          );
                        }
                        else{
                          openSnackbar( "error", "Lütfen Bir Tarih Seçiniz.", 3000);
                        }
                      },
                    },
                    {
                      id: "close",
                      type: "v-btn",
                      variant: "btn",
                      name: "close",
                      inputText: "Kapat",
                      divStyle: "",
                      btnTypeText: true,
                      color: "green darken-1",
                      classTag: "",
                      onClick: function (thisData,model,dialogInfo) {
                        closeDialogTable(dynamicElements);
                        //closeDialog();
                        model.dateFormattedLuca = ""
                      }
                    }
                  ],
                };
                showDialogTable(dynamicElements,dialogInfo);
                //showDialog(dialogInfo);
              }
              else{
                openSnackbar("error","Aktarılacak Kayıt Bulunamadı.")
              }
            }
          },
        ]
      }else if (erpName == 'Zirve') {
        tableElements.toolbars[0].inputElements[9] =[
          {
            id: "addZirve",
            type: "v-btn",
            variant: "btn",
            name: "addZirve",
            inputText: "Zirve Aktar",
            showInput: routeType == 'reviewed' ,
            small:true,
            divStyle: "",
            color:"#294060",
            style: "height: 25px !important;margin-top: 8px;color:white",
            btnTypeText: false,
            prependIcon: "",
            classTag: "",
            onClick: function (thisData, model, dynamicElements) {
              if (dynamicElements.selectedRows.length > 0) {
                let dialogInfo = {
                  name: "infoTableDialog",
                  title: dynamicElements.selectedRows.length == 0 ? "Aktarılacak Kayıt Sayısı: " +  model.itemCount : "Aktarılacak Kayıt Sayısı: " +  dynamicElements.selectedRows.length ,
                  maxWidth : "600px",
                  layout: [
                    {
                      type: Enum.layoutType.div,
                      name: "infoDialog",
                      colStyle : "width: 100%;",
                      inputElements : [
                        [
                          {
                            id : "dateFormattedZirve",
                            type : "v-date-picker",
                            variant : "text",
                            name : "dateFormattedZirve",
                            hint : "",
                            label : "Aktarılacak Tarih",
                            value : "",
                            prependIcon : "fas fa-at",
                            placeHolder : "",
                            style : "width: 50%;",
                            classTag : "",
                          },
                        ]
                      ]
                    },
                  ],
                  actions: [
                    {
                      id: "save",
                      type: "v-btn",
                      variant: "btn",
                      name: "save",
                      inputText: "Zirve Aktar",
                      divStyle: "",
                      btnTypeText: true,
                      color: "green darken-1",
                      classTag: "",
                      onClick: function (thisData,model,dialogInfo) {
                        let that = this
                        if (model.dateFormattedZirve != "" &&  model.dateFormattedZirve != null) {
                          showLoader("Lütfen Bekleyiniz...");
                          let uuIds = []
                          for (let i = 0; i < dynamicElements.selectedRows.length; i++) {
                            uuIds.push(dynamicElements.selectedRows[i].uuId)
                          }
                          let filteredData = {};
                          let filterObjKeys = Object.keys(dynamicElements.filteredColumn)
                          if (filterObjKeys.length > 0) {
                            filteredData = dynamicElements.filteredColumn
                          }
                          /// filter için de yazılması gerekiyor.
                          let betweenDateData = {
                            startDate : "",
                            endDate : ""
                          }
                          if (uuIds.length == 0) {
                            let tableModel = model[dialogInfo.name]
                            if (tableModel.getDataWithDateStarted != "" &&  tableModel.getDataWithDateEnd != "") {
                              betweenDateData.startDate  = tableModel.getDataWithDateStarted
                              betweenDateData.endDate  = tableModel.getDataWithDateEnd
                            }
                          }
                          let calculateThenFunc = function () {
                            router.push('folderNotification');
                            hideLoader();
                          }
                          accountingCalculateLOGO(undefined,invoiceType, uuIds, 'Zirve', model.dateFormattedZirve, undefined, calculateThenFunc)
                        }
                        else{
                          openSnackbar( "error", "Lütfen Bir Tarih Seçiniz.", 3000);
                        }
                      },
                    },
                    {
                      id: "close",
                      type: "v-btn",
                      variant: "btn",
                      name: "close",
                      inputText: "Kapat",
                      divStyle: "",
                      btnTypeText: true,
                      color: "green darken-1",
                      classTag: "",
                      onClick: function (thisData,model,dialogInfo) {
                        closeDialogTable(dynamicElements);
                        //closeDialog();
                        model.dateFormattedLuca = ""
                      }
                    }
                  ],
                };
                showDialogTable(dynamicElements,dialogInfo);
                // let selectedRowsUuId = [];
                // for (let i = 0; i < dynamicElements.selectedRows.length; i++) {
                //   selectedRowsUuId.push(dynamicElements.selectedRows[i].uuId)
                // }
                // let calculateThenFunc = function () {
                //   router.push('folderNotification');
                //   hideLoader();
                // }
                //   accountingCalculateLOGO(invoiceType, selectedRowsUuId, 'Zirve', undefined,undefined, calculateThenFunc)
              }else{
                openSnackbar("error", "Lütfen Muhasebeleştirmek İstediğiniz Satırların Seçimi Yapınız.",2000);
                hideLoader();
              }
            },
          },
        ]
      }
      else {
            sendRequest(Enum.requestType.POST,"getCompanySettings",null,{},
              function(response) {
                store.dispatch("setERPInformation", response.data.data.settings.reporting );
               //openSnackbar("success", "TEKRAR İSTEK ATILDI", 10000);
              
                if (store.state.erpInformation.choiceERP == "Logo" || store.state.erpInformation.choiceERP == "Luca" || store.state.erpInformation.choiceERP == "Zirve") { 
                  console.log("")
                }  
                else  { 
                  openSnackbar("error", "Lütfen Ayarlar Bölümünden, Muhasebe Programı Kartından Muhasebe Programınızı Seçiniz.", 10000);
                  }
              },
              function(error) {
                openSnackbar("error", error.response.data.error[0].detail.message, 2000);
              }
            );    
      } 
}

export function infoTableDialogFunc(dynamicElements ,explanation , successExplanation,errorExplanation,approvedDocumentsEntryAndExitSelect){
  let updatedId = [], updatedObject = [];
  let dialogInfo = {
    name: "infoTableDialog",
    title: dynamicElements.selectedRows.length == 0 ? explanation + model.itemCount : explanation + dynamicElements.selectedRows.length,
    maxWidth: "600px",
    actions: [
      {
        id: "save",
        type: "v-btn",
        variant: "btn",
        name: "save",
        inputText: "Onayla",
        divStyle: "",
        btnTypeText: true,
        color: "green darken-1",
        classTag: "",
        onClick: function (thisData, model, dialogInfo) {
          showLoader("Lütfen Bekleyiniz...");
          if (dynamicElements.selectedRows.length == 0) {
            updatedObject = {
              filterData: dynamicElements.filteredColumn
            }
          } else {
            for (let i = 0; i < dynamicElements.selectedRows.length; i++) {
              updatedId.push({ invoiceId: dynamicElements.selectedRows[i].invoiceId })
            }
          }

          sendRequest(
            Enum.requestType.POST,
            "approvalProcedures",
            "",
            {
              updatedId: updatedId,
              updatedObject: updatedObject,
              approvedDocumentsEntryAndExit : approvedDocumentsEntryAndExitSelect
            },
            function (response) {
              dynamicElements.selectedRows = []
              hideLoader()
              openSnackbar("success", successExplanation, 3000);
              openTablePage(dynamicElements.name);
              closeDialogTable(dynamicElements);
            },
            function (error) {
              hideLoader()
              openSnackbar("error", errorExplanation);
            }
          );
        },
      },
      {
        id: "close",
        type: "v-btn",
        variant: "btn",
        name: "close",
        inputText: "Kapat",
        divStyle: "",
        btnTypeText: true,
        color: "green darken-1",
        classTag: "",
        onClick: function (thisData, model, dialogInfo) {
          closeDialogTable(dynamicElements);
        }
      }
    ],
  };
  showDialogTable(dynamicElements,dialogInfo);

}


