<template>
  <v-app>
    <table-component
      :dynamic-element="inflationAccountElements"
      :model="model"
    />
    <Loader></Loader>
    <snackbar-component />
  </v-app>
</template>

<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import store from "../../src/store/index";
import {
  openSnackbar,
  openTablePage,
  showDialog,
  closeDialog,
  hideLoader,
  showLoader
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import {
  sendRequest,uploadHelperFormDataQueue, axiosRequestTypeArrayBuffer 
} from "../../../frontend/components/atoms/helper/requestHelper";
import {axiosUploadTypeArrayBuffer} from "../../helper/uploadHelperForData"

export default {
  components: {
    "table-component": tableComponent,
    "upload-component": uploadComponent,
    Loader: Loader,
    "snackbar-component": snackbarComponent,
  },

  data() {
    return {
      mainDataItems: [],
      inflationAccountElements: {},
      model: {},
      renderKey: 0,
      required: (value) => !!value || "Gerekli.",
      subGroupDialogStatus: false,
      subGroupAddDialogStatus: false,
      subGroupItems: [],
      subGroupHeaders: [
        {
          text: "Hesap Kodu",
          value: "accountCode",
          width: "100",
          search: true,
          sortable: true,
        },
        {
          text: "Hesap Adı",
          value: "accountName",
          width: "100",
          search: true,
          sortable: true,
        },
        {
          text: "Hesap Tipi",
          value: "monetaryTypeString",
          width: "100",
          search: true,
          sortable: true,
        },
        {
          text: "Tarih",
          value: "date",
          width: "100",
          search: true,
          sortable: true,
        },
        {
          text: "Tutar",
          value: "debt",
          width: "100",
          search: true,
          sortable: true,
        },
        ,
      ],
    };
  },
  created() {
    this.loadData();
  },
  watch: {},
  methods: {
    onClickDialog() {
      this.subGroupAddDialogStatus = true;
    },
    loadData() {
      let that = this;
      this.inflationAccountElements = {
        clientEvents: {
        },
        sortBy: ['accountCodeReport'],
        sortDesc: false,
        renderKey: 0,
        type: "table",
        tableHeight: 420,
        name: "inflationAccountTableReport",
        class: "",
        infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
        deleteBtnText: "Sil",
        deleteUrl: "deleteTable",
        deleteDialogVisible: false,
        hidePagination: true,
        selectedRows: [],
        filteredColumn: {},
        filteredColumns: true,
        deleteBtnSettings : {
          color: "error",
          style:"height: 36px !important; ",
        },
        selectionUrl: "gettabledata",
        crudRequestObj : {
          placeOfAction : "inflationAccountTableReportReceiptDialog",
          tableName : "v_main_account_group",
          companyId: window.$cookies.get("selectedItem").companyId,
          projectId: window.$cookies.get("selectedItem").id,
          auditDate: window.$cookies.get("selectedItem").auiditDate,
          preriorDate: window.$cookies.get("selectedItem").preriorDate,
          isActive: window.$cookies.get("selectedItem").isActive
        },
        initialFilter: {
          [Enum.initialFilter.AND]: [
            {
              company_id: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem")
                  .companyId,
              },
              project_id: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id,
              },
              is_active: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem")
                  .isActive,
              },
            },
          ],
        },
        showDefaultUpdateButon: true,
        showDefaultDeleteButon: true,
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
              [
                {
                  id: "excelDownload",
                  type: "v-btn",
                  variant: "btn",
                  name: "excelDownload",
                  inputText: "excelDownloadBtnText",
                  style:"height: 35px !important;",
                  divStyle: "",
                  icon: "mdi-file-excel",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    showLoader('Dosyanız Hazırlanıyor')
                    sendRequest(Enum.requestType.POST,"accountPlan",null,
                          {
                              filter : {
                                choice : "inflationAccountTableReport", orderBy : "orderBy",
                                projectId : window.$cookies.get("selectedItem").id
                              },
                              excelDownload : true
                          },
                          function (response) {
                            //let periodName =  this.vatDirectSelection.replace("/", "_")
                            let zipName = "Rapor Excel" + " " /*+ periodName*/
                            axiosUploadTypeArrayBuffer("allExcelZipDownload","inflationAccountTableReport", zipName, "Excel İndirildi")
                          },
                          function (error) {
                            openSnackbar(
                              "error",
                              error.response.data.error[0].detail.message
                            );
                            hideLoader();
                          }
                        )
                  },
                  
                }
              ],
              [
                {
                  id: "tableDialogBtn",
                  type: "v-btn",
                  variant: "btn",
                  name: "tableDialogBtn",
                  inputText: "Düzeltme Fişi Ekle",
                  divStyle: "",
                  btnTypeText: false,
                  color: "primary",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    let dialogInfo = {
                      name: "infoTableDialog",
                      maxWidth: "1100",
                      dialogVisible: "dialogVisible",
                      title: "Fiş Ekle",
                      layout: [
                        {
                          type: Enum.layoutType.div,
                          name: "tableDialog",
                          tableObject: {
                            sortBy: ['receiptNo'],
                            sortDesc: false,
                            renderKey: 0,
                            type: "table",
                            name: "inflationAccountTableReportDialog",
                            class: "",
                            infoTotalRowCountHeaderAssistantTable:
                              "Toplam satır sayısı",
                            selectionUrl: "getAllItemsFilter",
                            deleteUrl: "deleteTable",
                            //saveUrl: "insertSelectedItem",
                            saveUrl: "updateTable",
                            deleteBtnText: "Sil",
                            excelDownloadBtnText: "İndir",
                            filteredColumn: {},
                            filteredColumns: true,
                            saveBtnText: "Yeni Kayıt",
                            footerProps: {"items-per-page-options": [15],"items-per-page-text": "Sayfa Başına Veri Sayısı",},
                            crudRequestObj : {
                              placeOfAction : "inflationAccountTableReportReceiptDialog",
                              tableName : "v_main_account_group",
                              companyId: window.$cookies.get("selectedItem").companyId,
                              projectId: window.$cookies.get("selectedItem").id,
                              auditDate: window.$cookies.get("selectedItem").auiditDate,
                              preriorDate: window.$cookies.get("selectedItem").preriorDate,
                              isActive: window.$cookies.get("selectedItem").isActive
                            },
                            saveBtnSettings: {
                              color: "blue",
                              style: "height: 25px !important; ",
                            },
                            deleteBtnSettings: {
                              color: "error",
                              style: "height: 25px !important; ",
                            },
                            initialFilter: {
                              [Enum.initialFilter.AND]: [
                                {
                                  company_id: {
                                    [Enum.initialFilter
                                      .EQ]: window.$cookies.get("selectedItem")
                                      .companyId,
                                  },
                                  project_id: {
                                    [Enum.initialFilter
                                      .EQ]: window.$cookies.get("selectedItem")
                                      .id,
                                  },
                                  is_active: {
                                    [Enum.initialFilter
                                      .EQ]: window.$cookies.get("selectedItem")
                                      .isActive,
                                  },
                                },
                              ],
                            },
                            clientEvents : {
                            onBeforeSave :function (selectedItem,creatableObjects,model,tableObject) {
                              if(creatableObjects.accountCode != undefined  && creatableObjects.accountName != undefined && creatableObjects.classificationString != undefined && 
                              creatableObjects.explain != undefined && creatableObjects.receiptNo != undefined && creatableObjects.tranCredit != undefined && 
                              creatableObjects.tranDebt != undefined){
                                creatableObjects.debt = creatableObjects.tranDebt
                                delete creatableObjects.tranDebt
                                creatableObjects.credit = creatableObjects.tranCredit
                                delete creatableObjects.tranCredit


                              }else{
                                openSnackbar("error", "Tüm Alanları Doldurunuz")
                                return false;
                              }
                            },
                            },
                            hidePagination: false,
                            toolbars: [
                              {
                                type: Enum.layoutType.div,
                                name: "toolbarsForm",
                                inputElements: [],
                              },
                            ],
                            headers: [
                              { text: "Fiş No", value: "receiptNo" },
                              { text: "Hesap Kodu", value: "accountCode" },
                              { text: "Hesap Adı", value: "accountName",class: "threedot" },
                              { text: "Borç", value: "tranDebt", dataType: "currency"  },
                              { text: "Alacak", value: "tranCredit", dataType: "currency"  },
                              { text: "Açıklama", value: "explain" },
                              { text: "Sınıflandırma", value: "classificationString"  },
                            ],
                            data: "data2",
                            showSelecetVisible: false,
                            singleSelect: false,
                            itemKey: "id",
                            columns: {
                              receiptNo: {
                                type: "v-text-field",
                                editable: true,
                                creatable: true,
                                inlineEditable: true,
                                label: "Fiş No",
                                onSave: function(thisData, model, dynamicElements) {
                                  showLoader("Dosyanız Yükleniyor. Lütfen Bekleyiniz.");
                                  let editableObjects = {};
                                  editableObjects[thisData.id] = model[thisData.id]; 
                                    sendRequest("POST","updateForAllTable","",{selectedItem : [model], editableObjects ,tableName : "accountTransaction", tableKey : "receipt_no", forKey : "id", sequl : "id"},
                                    function(response) {
                                      openTablePage("inflationAccountTableReportDialog")
                                      hideLoader()
                                      openSnackbar("success", response.data.data.value);
                                    },
                                    function(error) {
                                      if (error.response != undefined) {
                                        openSnackbar(
                                          "error",error.response.data.error[0].detail.message);
                                      } else
                                        openSnackbar("error", "Sunucu Hatası");
                                    }
                                  );
                                },
                              },
                              accountCode: {
                                type: "autocomplete",
                                editable: true,
                                creatable: true,
                                inlineEditable: true,
                                multiple:false,
                                label: "Hesap Kodu",
                                url : "getAutoCompleteSelectBox",
                                crudRequestObj : {
                                  tableName : "v_main_account_group",
                                  companyId: window.$cookies.get("selectedItem").companyId,
                                  projectId: window.$cookies.get("selectedItem").id,
                                  isActive: window.$cookies.get("selectedItem").isActive,
                                },
                                searchEventControl : true,
                                onSave: function (thisData, model, dynamicElements) {
                                  let editableObjects = {}
                                  editableObjects["account_code"] = model[thisData.id]
                                  sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "accountPlan", tableKey : "account_code", forKey : "accountPlanId", sequl : "id"},
                                  function(response){
                                    openTablePage("inflationAccountTableReportDialog")
                                    openSnackbar("success", response.data.data.value)
                                  }, 
                                  function(error){
                                    if (error.response != undefined){
                                      openSnackbar("error", error.response.data.error[0].detail.message)
                                    } else (
                                      openSnackbar("error", "Sunucu Hatası")
                                    )
                                  })
                                }
                              },
                              accountName: {
                                type: "v-text-field",
                                editable: false,
                                creatable: true,
                                inlineEditable: false,
                                label: "Hesap Adı",
                              },
                              tranDebt: {
                                type: "v-text-field",
                                editable: true,
                                creatable: true,
                                inlineEditable: true,
                                label: "Borç",
                                onSave: function (thisData, model, dynamicElements) {
                                  let editableObjects = {}
                                  editableObjects["debt"] = model[thisData.id]
                                  sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "accountTransaction", tableKey : "credit", forKey : "id", sequl : "id"},
                                  function(response){
                                    openTablePage("inflationAccountTableReportDialog")
                                    openSnackbar("success", response.data.data.value)
                                  }, 
                                  function(error){
                                    if (error.response != undefined){
                                      openSnackbar("error", error.response.data.error[0].detail.message)
                                    } else (
                                      openSnackbar("error", "Sunucu Hatası")
                                    )
                                  })
                                }
                              },
                              tranCredit: {
                                type: "v-text-field",
                                editable: true,
                                creatable: true,
                                inlineEditable: true,
                                label: "Alacak",
                                onSave: function (thisData, model, dynamicElements) {
                                  let editableObjects = {}
                                  editableObjects["credit"] = model[thisData.id]
                                  sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "accountTransaction", tableKey : "credit", forKey : "id", sequl : "id"},
                                  function(response){
                                    openTablePage("inflationAccountTableReportDialog")
                                    openSnackbar("success", response.data.data.value)
                                  }, 
                                  function(error){
                                    if (error.response != undefined){
                                      openSnackbar("error", error.response.data.error[0].detail.message)
                                    } else (
                                      openSnackbar("error", "Sunucu Hatası")
                                    )
                                  })
                                }
                              },
                              explain: {
                                type: "v-text-field",
                                editable: true,
                                creatable: true,
                                inlineEditable: true,
                                label: "Açıklama",
                                onSave: function (thisData, model, dynamicElements) {
                                  let editableObjects = {}
                                  editableObjects["explain"] = model[thisData.id]
                                  sendRequest("POST","updateForAllTable","",{selectedItem : [model], editableObjects ,tableName : "accountTransaction", tableKey : "explain", forKey : "id", sequl : "id"},
                                  function(response){
                                    openTablePage("inflationAccountTableReportDialog")
                                    openSnackbar("success", response.data.data.value)
                                  }, 
                                  function(error){
                                    if (error.response != undefined){
                                      openSnackbar("error", error.response.data.error[0].detail.message)
                                    } else (
                                      openSnackbar("error", "Sunucu Hatası")
                                    )
                                  })
                                }
                              },
                              classificationString: {
                                type: "v-select",
                                variant: "number",
                                editable: true,
                                creatable: true,
                                inlineEditable: true,
                                label: "Hesap Tipi",
                                dropDownItems: ["Sınıflandırma","Düzeltme","İptal","Açılış","Taşıma"],
                                onSave: function (thisData, model, dynamicElements) {
                                  let classification = {
                                      "Sınıflandırma" : 0,
                                      "Düzeltme" : 1,
                                      "İptal" : 2,
                                      "Açılış": 3,
                                      "Taşıma": 4

                                  }
                                  let editableObjects = {}
                                  editableObjects["classification"] = classification[model[thisData.id]]
                                  sendRequest("POST","updateForAllTable","",{selectedItem : [model], editableObjects ,tableName : "accountTransaction", tableKey : "classification", forKey : "id", sequl : "id"},
                                  function(response){
                                    openTablePage("inflationAccountTableReportDialog")
                                    openSnackbar("success", response.data.data.value)
                                  }, 
                                  function(error){
                                    if (error.response != undefined){
                                      openSnackbar("error", error.response.data.error[0].detail.message)
                                    } else (
                                      openSnackbar("error", "Sunucu Hatası")
                                    )
                                  })
                                }
                              },
                            },
                            footer: [],
                          },
                        },
                      ],
                      actions: [],
                      titleActions: [
                        {
                          id: "close",
                          type: "v-btn",
                          variant: "btn",
                          name: "close",
                          divStyle: "",
                          inputText: "Kapat",
                          btnTypeText: true,
                          color: "red",
                          classTag: "",
                          icon: "mdi-close",
                          closeBtn: true,
                          onClick: function(thisData, model, dialogInfo) {
                            closeDialog();
                          },
                        },
                      ],
                    };
                    showDialog(dialogInfo);
                  },
                },
              ],
              [
                {
                  id: "tableReceiptBtn",
                  type: "v-btn",
                  variant: "btn",
                  name: "tableReceiptBtn",
                  inputText: "Fiş Yükle",
                  divStyle: "",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    let dialogInfo = {
                      name: "receiptTableDialog",
                      maxWidth: "1100",
                      dialogVisible: "dialogVisible",
                      title: "Fiş Yükle",
                      layout: [
                        {
                          type: Enum.layoutType.div,
                          name: "loginForms",
                          inputElements: [
                            [
                              {
                                id: "receiptUpload",
                                type: "v-file-input",
                                label: "Dosyanızı Seçiniz",
                                inputHeader: "Hesap Planı Yükle",
                                name: "receiptUploadExcel",
                                multiple: false,
                                onChange: async function(thisData, model, dynamicElements) {
                                  let files = model.receiptUploadExcel;
                                  if (model.receiptUploadExcel.size > 0) {
                                    showLoader("Dosyanız Yükleniyor. Lütfen Bekleyiniz...");
                                    sendRequest("POST","tableStatus","",{tableName: 'inflation_report',projectId: window.$cookies.get("selectedItem").id},
                                    function(response) {
                                      if (!response.data.data.value) {
                                        openSnackbar("error", "İlk Önce Mizan Yükleyeniz");
                                        hideLoader();
                                      }
                                      else{ 
                                        let choiceData = {
                                          choice : "receiptUploadExcel", 
                                          headerAddFlag : true, 
                                          projectId : window.$cookies.get("selectedItem").id,
                                          insertType : "ReceiptValidation",
                                          nextInsertType : "ReceiptDBInsert",
                                          fileName : model.receiptUploadExcel.name,
                                          auditDate: window.$cookies.get("selectedItem").auiditDate,
                                          preriorDate: window.$cookies.get("selectedItem").preriorDate,
                                          explain : window.$cookies.get("selectedItem").explain
                                        }
                                        uploadHelperFormDataQueue([files], "excelUpload", Enum.requestType.POST, choiceData, 
                                        function (response) {
                                          closeDialog();
                                          if (response.data.data.errorExcel != undefined) {
                                            axiosRequestTypeArrayBuffer(response)
                                            openSnackbar("error", "Taslak Hatası");
                                            hideLoader();
                                          }else if (response.data.data.draftError != undefined ) {
                                            openSnackbar("error", response.data.data.draftError);
                                            hideLoader();
                                          } else {
                                            //openSnackbar("success", "Dosya Yüklendi");
                                            closeDialog();
                                            that.$router.push("folderNotification")
                                            //openTablePage("inflationAccountTableReport")
                                          }
                                        })
                                      }
                                    })
                                  }
                                }
                              }
                            ],
                          ],
                        },
                      ],
                      actions: [],
                      titleActions: [
                        {
                          id: "close",
                          type: "v-btn",
                          variant: "btn",
                          name: "close",
                          divStyle: "",
                          inputText: "Kapat",
                          btnTypeText: true,
                          color: "red",
                          classTag: "",
                          icon: "mdi-close",
                          closeBtn: true,
                          onClick: function(thisData, model, dialogInfo) {
                            closeDialog();
                          },
                        },
                      ],
                    };
                    showDialog(dialogInfo);
                  },
                },
              ],
              [
                {
                  id: "subGroupBtn",
                  type: "v-btn",
                  variant: "btn",
                  name: "subGroupBtn",
                  inputText: "Detay Grup Yükle",
                  divStyle: "",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    let dialogInfo = {
                      name: "subGroupTableDialog",
                      maxWidth: "1100",
                      dialogVisible: "dialogVisible",
                      title: "Detay Grup Yükle",
                      layout: [
                        {
                          type: Enum.layoutType.div,
                          name: "loginForms",
                          inputElements: [
                            [
                              {
                                id: "mizan",
                                type: "v-file-input",
                                label: "Dosyanızı Seçiniz",
                                inputHeader: "Hesap Planı Yükle",
                                name: "subGroupExcel",
                                multiple: false,
                                onChange: async function(thisData, model, dynamicElements) {
                                  let files = model.subGroupExcel;
                                  if (model.subGroupExcel.size > 0) {
                                    showLoader("Dosyanız Yükleniyor. Lütfen Bekleyiniz..."); 
                                    sendRequest("POST","tableStatus","",{tableName: 'inflation_report',projectId: window.$cookies.get("selectedItem").id},
                                    function(response) {
                                      if (!response.data.data.value) {
                                        openSnackbar("error", "İlk Önce Mizan Yükleyeniz");
                                        hideLoader();
                                      }
                                      else{ 
                                        let choiceData = {
                                          choice : "subGroupExcel", 
                                          headerAddFlag : true, 
                                          projectId : window.$cookies.get("selectedItem").id,
                                          insertType : "SubgroupValidation",
                                          nextInsertType : "SubgroupDBInsert",
                                          fileName : model.subGroupExcel.name,
                                          auditDate: window.$cookies.get("selectedItem").auiditDate,
                                          preriorDate: window.$cookies.get("selectedItem").preriorDate,
                                          explain : window.$cookies.get("selectedItem").explain
                                        }
                                        uploadHelperFormDataQueue([files], "excelUpload", Enum.requestType.POST, choiceData, 
                                        function (response) {
                                          if (response.data.data.errorExcel != undefined) {
                                            axiosRequestTypeArrayBuffer(response)
                                            openSnackbar("error", "Taslak Hatası");
                                            hideLoader();
                                          }else if (response.data.data.draftError != undefined ) {
                                            openSnackbar("error", response.data.data.draftError);
                                            hideLoader();
                                          } else {
                                            //openSnackbar("success", "Dosya Yüklendi");
                                            closeDialog();
                                            that.$router.push("folderNotification")
                                            //openTablePage("inflationAccountTableReport")
                                          }
                                        })
                                      }
                                    })
                                  }
                                }
                              }
                            ],
                          ],
                        },
                      ],
                      actions: [],
                      titleActions: [
                        {
                          id: "close",
                          type: "v-btn",
                          variant: "btn",
                          name: "close",
                          divStyle: "",
                          inputText: "Kapat",
                          btnTypeText: true,
                          color: "red",
                          classTag: "",
                          icon: "mdi-close",
                          closeBtn: true,
                          onClick: function(thisData, model, dialogInfo) {
                            closeDialog();
                          },
                        },
                      ],
                    };
                    showDialog(dialogInfo);
                  },
                },
              ],
              [
                {
                  id: "account698Update",
                  type: "v-btn",
                  variant: "btn",
                  name: "account698Update",
                  inputText: "698 Hesabı Güncelle",
                  divStyle: "",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    sendRequest("POST","account698Update","",{companyId: window.$cookies.get("selectedItem").companyId, projectId: window.$cookies.get("selectedItem").id,auditDate : window.$cookies.get("selectedItem").auiditDate,preriorDate: window.$cookies.get("selectedItem").preriorDate},
                          function(response) {
                            openSnackbar("success", "Hesap Güncellendi");
                          },
                          function(error) {
                            if (error.response != undefined) {

                            } else openSnackbar("error", "Sunucu Hatası");
                          }
                    )
                  },
                },
              ],
            ],
          },
        ],
        headers: [
                {text: "Rapor Hesap Kodu",value: "accountCodeReport",width: "100",search: true,sortable: true,},
                {text: "Hesap Kodu",value: "accountCode", width: "100",search: true,sortable: true,},
                {text: "Hesap Adı",value: "accountName",width: "100",search: true,sortable: true,class: "threedot"},
                {text: "Hesap Tipi",value: "monetaryTypeString",width: "100",search: true,sortable: true,class: "threedot"},
                {text: "Tutar",value: "balance",width: "100",search: true,sortable: true,dataType: "currency"},
                {text: "Düzenlenmiş Borç",value: "tranDebt",width: "100",search: true,sortable: true,dataType: "currency"},
                {text: "Düzenlenmiş Alacak",value: "tranCredit",width: "100",search: true,sortable: true,dataType: "currency"},
                {text: "Düzenlenmiş Tutar",value: "updatedBalance",width: "100",search: true,sortable: true,dataType: "currency"},
                {text: "Açılış Endeksleme Farkı Tutarı",value: "openingDifferenceEndexBalance",width: "100",search: true,sortable: true,dataType: "currency"},
                {text: "Cari Dönem Endeksleme Tutarı",value: "currentPeriodBalance",width: "100",search: true,sortable: true,dataType: "currency"},
                {text: "Endekslenmiş Tutar",value: "endexedBalance",width: "100",search: true,sortable: true,dataType: "currency"}
          ],
        columns: {
          accountCode: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: false,
            label: "Hesap Kodu",
            cellElement: {
              id: "accountCode",
              type: "v-btn",
              variant: "btn",
              name: "accountCode",
              inputText: "accountCode",
              divStyle: "",
              style:"height:0px;text-decoration: underline;color: blue !important;",
              btnTypeText: true,
              color: "black",
              prependIcon: "",
              classTag: "",
              onClick: function(thisData, model, dynamicElements) {
                that.mainDataItems = JSON.parse(JSON.stringify(store.state.globalDialog.data))
                store.state.globalDialog.data = [];
                let stockAccountCodes = ["150", "151", "152", "153"];
                if (stockAccountCodes.includes(model.accountCode)) {
                  detailDialogInfoStock(model, that.mainDataItems);
                } else {
                  detailDialogInfo(model, that.mainDataItems);
                }
              },
            },
          },
          monetaryTypeString: {
            type: "v-select",
            variant: "number",
            editable: true,
            creatable: true,
            inlineEditable: false,
            label: "Hesap Tipi",
            dropDownItems: ["PARASAL", "PARASAL OLMAYAN"],
            rules: [this.required],
            onSave: function(thisData, model, dynamicElements) {
              let editableObjects = {};
              let modelmonetaryTypeString = ""
              model[thisData.id] == "PARASAL" ? modelmonetaryTypeString = 0 :   modelmonetaryTypeString = 1
              editableObjects["monetary_type"] = modelmonetaryTypeString;              
              sendRequest("POST","updateForAllTable","",{selectedItem : [model], editableObjects ,tableName : "accountPlan", tableKey : "monetary_type", forKey : "accountPlanId", sequl : "id"},
                function(response) {
                  openSnackbar("success", response.data.data.value);
                },
                function(error) {
                  if (error.response != undefined) {
                    openSnackbar(
                      "error",
                      error.response.data.error[0].detail.message
                    );
                  } else openSnackbar("error", "Sunucu Hatası");
                }
              );
            },
          },
          balance : {
            type: "v-money",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label: "Tutar",
            value : "",
            money : {
                    decimal: ',',
                    thousands: '.',
                    prefix: '',
                    suffix: '',
                    precision: 2,
                  },                  
            onSave: function(thisData, model, dynamicElements) {
              let editableObjects = {};
                model[thisData.id] = model[thisData.id].replaceAll(".","").replaceAll(",",".")
              if(model.balance.toString().startsWith("-")){
                model[thisData.id] = model[thisData.id].split("-")
                editableObjects["credit"] = model[thisData.id][1];
                editableObjects["debt"] = 0
              }else{
                editableObjects["debt"] = model[thisData.id];
                editableObjects["credit"] = 0;
              }
              
              sendRequest("POST","updateForAllTable","",{selectedItem: [model],editableObjects,tableName: "accountDetail",tableKey : "balance", forKey : "subAccountDetailId", sequl : "id"},
                function(response) {
                  openSnackbar("success", response.data.data.value);
                  openTablePage("inflationAccountTableReport")
                },
                function(error) {
                  if (error.response != undefined) {
                    openSnackbar(
                      "error",
                      error.response.data.error[0].detail.message
                    );
                  } else openSnackbar("error", "Sunucu Hatası");
                }
              );
            },
          }
        },
        data: "data",
        showSelecetVisible: true,
        singleSelect: false,
        itemKey: "id",
        footer: [
          {
            type: Enum.layoutType.div,
            name: "footerForm",
            inputElements: [
              [
                {
                  id: "pageSize",
                  type: "v-text-field",
                  variant: "number",
                  name: "pageSize",
                  label: "Görünecek Satır Sayısı",
                  value: "",
                  placeHolder: "",
                  style: "width: 45%; margin-left: 5%",
                  classTag: "",
                  onChange: function(thisData, model, dynamicElements) {
                    openTablePage("inflationAccountTableReport");
                  },
                },
              ],
              [
                {
                  id: "currentPageNo",
                  type: "v-pagination",
                  variant: "pagination",
                  name: "currentPageNo",
                  divStyle: "",
                  style: "width:850px;",
                  classTag: "my-1",
                  length: "pagesCount",
                  onInput: function(thisData, model, dynamicElements) {
                    openTablePage("inflationAccountTableReport");
                  },
                },
              ],
              [
                {
                  id: "infoTotalRowCountHeaderAssistantTable",
                  type: "p",
                  variant: "p",
                  name: "infoTotalRowCountHeaderAssistantTable",
                  inputText: "infoTotalRowCountHeaderAssistantTable",
                  divStyle: "",
                  style:
                    "font-size: 12px;font-weight:500;margin:3px 0;color:#666A6F;margin-right: 2%;margin-left: 60%;",
                  classTag: "",
                },
                {
                  id: "infoTotalRowCountAssistantTable",
                  type: "p",
                  variant: "p",
                  name: "infoTotalRowCountAssistantTable",
                  inputText: "itemCount",
                  divStyle: "",
                  style:
                    "font-size: 15px;font-weight:500;margin:3px 0;color:#666A6F;margin-left: 82%;",
                  classTag: "",
                },
              ],
            ],
          },
        ],
      };
    },
  },
};

function detailDialogInfo(model,mainDataItems) {
    let totalBalance = 0;
    let amountAccountPositiveOrNegative = "Tutarsız"
    sendRequest("POST","getSubGrupItems","",{filter:{initialFilter: { companyId: window.$cookies.get("selectedItem").companyId, projectId: window.$cookies.get("selectedItem").id,
    isActive: window.$cookies.get("selectedItem").isActive, mainId: model.mainAccountDetailId},}},
    function(response) {
    //if (response.data.data.data.length > 0)  {
      for (let i = 0; i < response.data.data.data.length; i++) {
        totalBalance += response.data.data.data[i].balance;
      }
      let totalInfo = model.updatedBalance - totalBalance;
      if (totalInfo == 0) {
        amountAccountPositiveOrNegative = "Tutarlı"
      }
      let dialogInfo = {
      name: "inflationAccountTableReport",
      title: model.accountCode + " " + "Kodlu Hesabın Değeri: " + currencyFormat(model.updatedBalance ,2,3,",",".") + " Fark: " + currencyFormat(totalInfo ,2,3,",",".") + " Sonuç: " + amountAccountPositiveOrNegative,
      layout: [
        {
          type: Enum.layoutType.div,
          name: "subGroupDialog",
          tableObject: {
            renderKey: 50,
            type: "table",
            name: "subGroupDialogTable",
            dialogVisible: "dialogVisible",
            class: "",
            infoTotalRowCountHeaderAssistantTable: "Toplam satır sayısı",
            selectionUrl: "getSubGrupItems",
            saveBtnSettings: {
              color: "blue",
            },
            deleteBtnSettings: {
              color: "error",
            },
            //saveUrl: "insertSelectedItem",
            saveUrl: "updateTable",
            deleteBtnText: "Sil",
            deleteUrl: "deleteTable",
            saveBtnText: "Yeni Kayıt",
            hidePagination: false,
            footerProps: {"items-per-page-options": [15],"items-per-page-text": "Sayfa Başına Veri Sayısı",},
            toolbars: [
              {
                type: Enum.layoutType.div,
                name: "toolbarsForm",
                inputElements: [
                  [
                    {
                  id: "excelDownloadDetail",
                  type: "v-btn",
                  variant: "btn",
                  name: "excelDownload",
                  inputText: "excelDownloadBtnText",
                  style:"height: 35px !important;",
                  divStyle: "",
                  icon: "mdi-file-excel",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    showLoader('Dosyanız Hazırlanıyor')
                    sendRequest(Enum.requestType.POST,"accountPlan",null,
                          {
                              filter : {
                                choice : "accountSubGroup", orderBy : "orderBy",
                                query : dynamicElements.data[0].accountCode,
                                projectId : window.$cookies.get("selectedItem").id
                              },
                              excelDownload : true
                          },
                          function (response) {
                            //let periodName =  this.vatDirectSelection.replace("/", "_")
                            let zipName = "Rapor Excel" + " " /*+ periodName*/
                            axiosUploadTypeArrayBuffer("allExcelZipDownload","accountSubGroup", zipName, "Excel İndirildi")
                          },
                          function (error) {
                            openSnackbar(
                              "error",
                              error.response.data.error[0].detail.message
                            );
                            hideLoader();
                          }
                        )
                  },
                  
                  }
                  ]
                ],
              },
            ],
            crudRequestObj : {
              placeOfAction : "detailDialogInfo",
              tableName : "subGroupDialogTable",
              companyId: window.$cookies.get("selectedItem").companyId,
              projectId: window.$cookies.get("selectedItem").id,
              auditDate: window.$cookies.get("selectedItem").auiditDate,
              preriorDate: window.$cookies.get("selectedItem").preriorDate,
              isActive: window.$cookies.get("selectedItem").isActive,
              mainAccountDetailId : model.mainAccountDetailId
            },
            initialFilter: {
              companyId: window.$cookies.get("selectedItem").companyId,
              projectId: window.$cookies.get("selectedItem").id,
              isActive: window.$cookies.get("selectedItem").isActive,
              mainId: model.mainAccountDetailId,
            },
            headers: [
              {text: "Rapor Hesap Kodu",value: "accountCodeReport",search: true,sortable: true,},
              {text: "Hesap Kodu",value: "accountCode",search: true,sortable: true,},
              {text: "Hesap Adı",value: "accountName",search: true,class: "threedot",},
              {text: "Hesap tipi",value: "monetaryTypeString",search: true,class: "threedot",},
              {text: "Tarih", value: "date",search: true,class: "threedot",dataType: "date"},
              {text: "Tutar",value: "balance",search: true, class: "threedot",dataType: "currency"},
              {text: "Cari Dönem Endeksleme Tutarı",value: "endexedBalance",search: true,class: "threedot",dataType: "currency"},
            ],
            footer: [],
            data: "dataSubGroup",
            singleSelect: false,
            itemKey: "id",
            clientEvents : {
            onBeforeSave :function (selectedItem,creatableObjects,model,tableObject) {
              creatableObjects.initialFilter = tableObject.initialFilter
            },
            },
            columns: {
              accountCode: {
                type: "v-text-field",
                editable: false,
                creatable: true,
                inlineEditable: false,
                label: "Hesap Kodu",
              },
              accountName: {
                type: "v-text-field",
                editable: false,
                creatable: true,
                inlineEditable: false,
                label: "Hesap Adı",
              },
              monetaryTypeString: {
              type: "v-select",
              variant: "number",
              editable: true,
              creatable: true,
              inlineEditable: false,
              label: "Hesap Tipi",
              dropDownItems: ["PARASAL", "PARASAL OLMAYAN"],
              onSave: function(thisData, model, dynamicElements) {
                let editableObjects = {};
                let modelmonetaryTypeString = ""
                model[thisData.id] == "PARASAL" ? modelmonetaryTypeString = 0 :   modelmonetaryTypeString = 1
                editableObjects["monetary_type"] = modelmonetaryTypeString;
                sendRequest("POST","updateReferanceFilter","",{selectedItem : [model], editableObjects ,tableName : "accountPlan", tableKey : "monetary_type", forKey : "accountPlanId", sequl : "id"},
                  function(response) {
                    openTablePage("getSubGrupItems")
                    openSnackbar("success", response.data.data.value);
                  },
                  function(error) {
                    if (error.response != undefined) {
                      openSnackbar(
                        "error",
                        error.response.data.error[0].detail.message
                      );
                    } else openSnackbar("error", "Sunucu Hatası");
                  }
                );
              },
              },
              date: {
                type: "v-date-picker",
                editable: true,
                creatable: true,
                inlineEditable: true,
                label: "Tarih",
                onSave: function(thisData,model,dynamicElements) {
                  let editableObjects = {};
                  editableObjects[thisData.id] = model[thisData.id];
                  let dateQuery = model.date.split("/")
                  let auditDateIf = window.$cookies.get("selectedItem").auiditDate 
                  let result = dateCalculation(model.date,auditDateIf)
                  if(result){
                  if(model.date <= window.$cookies.get("selectedItem").auiditDate){
                    
                  sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "accountDetail", tableKey : "date", forKey : "subAccountDetailId", sequl : "id"},
                    function(response) {
                      openTablePage("getSubGrupItems")
                      openSnackbar("success", response.data.data.value);
                    },
                    function(error) {
                      if (error.response != undefined) {
                        openSnackbar("error",error.response.data.error[0].detail.message);
                      } else
                        openSnackbar("error", "Sunucu Hatası");
                    }
                  );
                  }else{
                    openSnackbar("error", "Dönem Tarihinden Büyük Tarih Giremezsiniz");
                  }
                  }
                },
              },
              
              balance: {
                type: "v-money",
                editable: true,
                creatable: true,
                inlineEditable: true,
                label: "Tutar",
                value : "",
                money : {
                  decimal: ',',
                  thousands: '.',
                  prefix: '',
                  suffix: '',
                  precision: 2,
                },                
                onSave: function(thisData, model, dynamicElements
                ) {//burdayım
                  let  accountCodeResult  =""
                  let editableObjects = {};
                  model[thisData.id] = model[thisData.id].replaceAll(".","").replaceAll(",",".")
                  if(model[thisData.id].toString().startsWith("-")){
                    editableObjects["credit"] = model[thisData.id];
                    accountCodeResult = "credit"
                    editableObjects["debt"] = 0
                  }else{
                    editableObjects["debt"] = model[thisData.id];
                    accountCodeResult = "debt"
                    editableObjects["credit"] = 0;
                  }
                    sendRequest("POST","updateForAllTable","",{selectedItem : [model] ,editableObjects ,tableName : "accountDetail", tableKey : accountCodeResult, forKey : "subAccountDetailId", sequl : "id"},
                    function(response) {
                      openSnackbar("success",response.data.data.value);
                    },
                    function(error) {
                      if (error.response != undefined) {
                        openSnackbar("error",error.response.data.error[0].detail.message);
                      } else
                        openSnackbar("error", "Sunucu Hatası");
                    }
                  );
                },
              },
              endexedBalance: {
                type: "v-text-field",
                editable: false,
                creatable: false,
                inlineEditable: false,
                label: "Hesap Kodu",
              },
            },
          },
        },
      ],
      actions: [],
      titleActions: [
        {
          id: "close",
          type: "v-btn",
          variant: "btn",
          name: "close",
          divStyle: "",
          inputText: "Kapat",
          btnTypeText: true,
          color: "red",
          classTag: "",
          icon: "mdi-close",
          closeBtn: true,
          onClick: function (thisData, model, dialogInfo) {
            closeDialog();
            store.state.globalDialog.data = mainDataItems
          },
        },
      ],
  };
  showDialog(dialogInfo);
  //  } else {
  //   openSnackbar("error", error.response.data.error[0].detail.message);
  //  }
  },
  function(error) {
    if (error.response != undefined) {
    } else openSnackbar("error", "Sunucu Hatası");
  }
  
)
}

function detailDialogInfoStock(model,mainDataItems) {
  sendRequest(
    "POST","getSubGrupItemsForStock","",{ companyId: window.$cookies.get("selectedItem").companyId, projectId: window.$cookies.get("selectedItem").id, 
    auditDate: window.$cookies.get("selectedItem").auiditDate,isActive: window.$cookies.get("selectedItem").isActive, 
    mainId: model.mainAccountDetailId, mainAccountCode: model.accountCode},
    function (response) {
      let averageMaturityDay = response.data.data.averageMaturityDay;
      let dialogInfo = {
        name: "inflationAccountTableReport",
        title: "Mizan Detay Bilgileri",
        layout: [
          {
            type: Enum.layoutType.div,
            name: "subGroupDialog",
            tableObject: {
              renderKey: 50,
              type: "table",
              name: "subGroupDialogTable",
              dialogVisible: "dialogVisible",
              class: "",
              infoTotalRowCountHeaderAssistantTable: "Toplam satır sayısı",
              //selectionUrl: "getSubGrupItems",
              
              hidePagination: false,
              footerProps: {
                "items-per-page-options": [15],
                "items-per-page-text": "Sayfa Başına Veri Sayısı",
              },
              toolbars: [
                {
                  type: Enum.layoutType.div,
                  name: "toolbarsForm",
                  inputElements: [
                    [
                      {
                        id: "averageMaturityDay",
                        type: "v-text-field",
                        variant: "text",
                        name: "averageMaturityDay",
                        label: "Ortalama Stokta Kalma Süresi(Aylık)",
                        value: currencyFormat(averageMaturityDay ,4,3,",","."),
                        placeHolder: "",
                        style: "width: 300px;",
                        classTag: "",
                        disabled : true
                      },
                    ]
                  ],
                },
              ],
              initialFilter: {
                companyId: window.$cookies.get("selectedItem").companyId,
                projectId: window.$cookies.get("selectedItem").id,
                isActive: window.$cookies.get("selectedItem").isActive,
                mainId: model.mainAccountDetailId,
              },
              headers: [
                {
                  text: "Dönemi",
                  value: "type",
                  width: "120",
                  search: true,
                  sortable: true,
                },
                {
                  text: "Hesap Kodu",
                  value: "accountCode",
                  width: "120",
                  search: true,
                  sortable: true,
                },
                {
                  text: "Hesap Adı",
                  value: "accountName",
                  width: "120",
                  search: true,
                  class: "threedot",
                },
                {
                  text: "Tarih",
                  value: "date",
                  width: "120",
                  search: true,
                  class: "threedot",
                  dataType : 'date'
                },
                {
                  text: "Ortalama Enventarde Kalma Süresi(Aylık)",
                  value: "averageOfMonthsInventoryValue",
                  width: "120",
                  search: true,
                  class: "threedot",
                },
                {
                  text: "Düzenlenmiş Tutar",
                  value: "updatedBalance",
                  width: "120",
                  search: true,
                  class: "threedot",
                  dataType : 'currency'
                },
                {
                  text: "Endekslenmiş Tutar",
                  value: "endexedBalance",
                  width: "120",
                  search: true,
                  class: "threedot",
                  dataType : 'currency'
                },
                {
                  text: "Endekse Etki Oranı",
                  value: "averageSpendRate",
                  width: "120",
                  search: true,
                  class: "threedot",
                  dataType : 'currencyFourDigit'
                }
              ],
              footer: [],
              data: response.data.data.value,
              singleSelect: false,
              itemKey: "id",
              columns: {},
            },
          },
        ],
        actions: [],
        titleActions: [
          {
            id: "close",
            type: "v-btn",
            variant: "btn",
            name: "close",
            divStyle: "",
            inputText: "Kapat",
            btnTypeText: true,
            color: "red",
            classTag: "",
            icon: "mdi-close",
            closeBtn: true,
            onClick: function (thisData, model, dialogInfo) {
              closeDialog();
              store.state.globalDialog.data = mainDataItems
            },
          },
        ],
      };
      showDialog(dialogInfo);
    },
    function (error) {
      if (error.response != undefined) {
        openSnackbar("error", error.response.data.error[0].detail.message);
      } else openSnackbar("error", "Sunucu Hatası");
    }
  );
}
function currencyFormat(num, decimalLength, thousandLength, decimalSeparator, thousandSeparator) {
        let number = typeof num
        if ("number" == number) {
          if(decimalSeparator == thousandSeparator || isNaN(num))
          throw num
          let left = num.toFixed(thousandLength).replace('.', decimalSeparator).replace(new RegExp('(\\d)(?=(\\d{'+thousandLength+'})+(?!\\d))', 'g'), '$1' + thousandSeparator).split(decimalSeparator)[0];
          let right = num.toFixed(decimalLength).replace('.', decimalSeparator).replace(new RegExp('(\\d)(?=(\\d{'+decimalLength+'})+(?!\\d))', 'g'), '$1' + thousandSeparator).split(decimalSeparator)[1];
          const total = left + decimalSeparator + right;
          return total
        }else{
          return num
        }
}
function dateCalculation(date, auiditDate) {
  let splitDate = date.split("/")
  date = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0] 

  let newDateQuery = new Date(date)
  if (date.length == 10  && newDateQuery != "Invalid Date" && date < auiditDate) {
    return true
  }
  else{
    openSnackbar("error","Yanlış Değer Girdiniz");
  }
  
}

</script>

<style scoped>
::v-deep .threedot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 90px;
}
::v-deep .threedot:hover {
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}
</style>
