import {hideLoader,openSnackbar} from "../../../frontend/components/atoms/helper/elementHelper.js";
import axios from "axios";

export  function pdfZipDownload(nodeURL, choice, zipName, snackbarText) {
    debugger
    axios({ method: "post", url: nodeURL,  data : {choice : choice} , responseType: "arraybuffer"}).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", zipName + ".zip"); //or any other extension
      document.body.appendChild(link);
      link.click();
      //hideLoader();
      openSnackbar("success", snackbarText);
      hideLoader();
    }).catch(() => console.log("error occured"));
}