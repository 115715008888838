<template>
    <v-app>
      <v-row justify="center">
        <v-col md="3">
          <upload-component :dynamic-element="eInvoiceElements" :model="model" />
        </v-col>
      </v-row>
      <Loader></Loader>
      <snackbar-component />
    </v-app>
  </template>
  
  <script>
  import tableComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
  import Enum from "../../../frontend/components/Enum.json";
  import Loader from "../../../frontend/components/atoms/loader";
  import { showLoader, hideLoader } from "../../../frontend/components/atoms/helper/elementHelper.js";
  import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
  //import { uploadFileAssistant } from "../tools/uploadFile.js";
  import { sendRequest, uploadHelperFormDataQueue, axiosRequestTypeArrayBuffer } from "../../../frontend/components/atoms/helper/requestHelper";
  import {openSnackbar} from "../../../frontend/components/atoms/helper/elementHelper.js";
  
  export default {
    components: {
      "upload-component": tableComponent,
      Loader: Loader,
      "snackbar-component": snackbarComponent,
    },
    data() {
      return {
        eInvoiceElements: [],
        model: {},
        renderKey: 0,
      };
    },
    created() {
      this.loadData();
    },
    watch: {},
    methods: {
      loadData() {
        let that = this;
        this.eInvoiceElements = [
          {
            renderKey: 0,
            name: "eInvoiceUpload",
            interfaceName: "E-FATURA",
            width: 400,
            layout: [
              {
                type: Enum.layoutType.div,
                name: "uploadLayout",
                inputElements: [
                  [
                    {
                      id: "eInvoice",
                      type: "v-file-input",
                      label: "Dosyanızı Seçiniz",
                      inputHeader: "Einvoice",
                      name: "muavinEinvoice",
                      onChange: async function (thisData,model,dynamicElements) {
                        that.uploadFileOnChange(model,"muavinEinvoice")
                      },
                    },
                  ],
                ],
              },
            ],
          },
        ];
      },
      uploadFileOnChange(model,fileTypeName){
        let that = this
        if (model[fileTypeName].size > 0) {
          showLoader("Dosyanız Yükleniyor. Lütfen Bekleyiniz.");
          let files = model[fileTypeName]
          let choiceData = {
            fileType : fileTypeName
          }
          uploadHelperFormDataQueue([files], "muavinExcelUpload", Enum.requestType.POST, choiceData, async function (response) {
            if (response.data.data.errorExcel != undefined) {
              // openSnackbar("error",
              //  "Taslak ile form uyumlu değildir. Lütfen yeniden yükleyiniz."
              // );
              await axiosRequestTypeArrayBuffer(response)
              hideLoader();
            } else {
              hideLoader();
              if (response.data.data.value == "Daha öncesinde yüklediğiniz aylara ait muavin yüklemesi yapamazsınız.") {
                openSnackbar("error", response.data.data.value);
              }else{
                openSnackbar("success", response.data.data.value);
                that.$router.push("testTable")
              }
            }
          })
        }
      }
    },
  };
  </script>
  