<template>
    <createpass-Component :projectDesign="projectDesign"/>

</template>
<script>
import { router } from '../router/router'
import {sendRequest} from "../../../frontend/components/atoms/helper/requestHelper.js"
import { openSnackbar , setLoggedUser} from "../../../frontend/components/atoms/helper/elementHelper.js";
import  createPass from "../../../frontend/components/atoms/container/createPasswordUser.vue";
import logoLeftBox from"../assets/Notitek-Logo-White.png"
import rightLeftBox from"../assets/Didero-siyah.png"

export default {
    components: { 'createpass-Component' :   createPass},
  data() {
    return {
        projectDesign : {}

      
    };
  },
  created() {
        this.LoginPageProp()

  },
  methods: {
    LoginPageProp(){
      this.projectDesign = { 
        // src="@/assets/emproBlack.png"
        color : "background-color: #4a922a!important;",
        logoBackground : "background-color: #4a922a!important;",
        leftBoxLogo : logoLeftBox,
        rightBoxLogo : rightLeftBox,
        btnStyle : "background-color : #4a922a;  color : white; -webkit-border-radius: 16px",
        async sendPass(Password,Hash) {
            if (Password != '') {
                sendRequest("POST","createpass","",{password: Password,hash: Hash},function (response) {
                if (response.data.success == true) {
                  openSnackbar("success", response.data.data.value);
                  router.push('/loginPage')
                }else{
                    openSnackbar("error", "Kullanıcı Adı Yanlış", 2000)
                }
                },function (error) {
                    openSnackbar("error", "Hata", 2000)
                }
            )
            }else{
                openSnackbar("error", "Lütfen Şifrenizi Boş Bırakmayınız", 2000)
            }
            
        },
      }      
      }
    
  }
};
</script>

<style scoped>
.forgot-password-page {
  overflow: hidden;
  top: 0;
  left: 0;
  height: 100vh;
  background-image: linear-gradient(
    to left top,
    #e4e4ec,
    #e3e4ef,
    #e1e4f3,
    #dee4f6,
    #dbe4fa,
    #dee6fc,
    #e0e9fd,
    #e3ebff,
    #ecf0ff,
    #f3f4ff,
    #fafaff,
    #ffffff
  );
}
</style>