import {hideLoader,openSnackbar} from "../../frontend/components/atoms/helper/elementHelper.js";
import axios from "axios";

export  function axiosUploadTypeArrayBuffer(nodeURL, choice, zipName, snackbarText) {
     axios({ method: "post", url: nodeURL,  data : {choice : choice} , responseType: "arraybuffer"}).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", zipName + ".zip"); //or any other extension
      document.body.appendChild(link);
      link.click();
      hideLoader();
      openSnackbar("success", snackbarText);
    }).catch(() => console.log("error occured"));
  }
export function dateCalculation(date, auiditDate) {
  if (date.includes("/")) {
    let splitDate = date.split("/")
    date = splitDate[2] + "-" + splitDate[1].padStart(2, '0') + "-" + splitDate[0] 
  } else if(date.includes("-")) {
    let splitDate = date.split("-")
    date = splitDate[2] + "-" + splitDate[1].padStart(2, '0') + "-" + splitDate[0] 
  }
  else{
    console.log("error")
  }
  

  let newDateQuery = new Date(date)
  if (date.length == 10  && newDateQuery != "Invalid Date" && date <= auiditDate) {
    return newDateQuery
  }
  else{
    return false
  }
  
}
export function checkProject() {
  if (window.$cookies.get("selectedItem") == null || window.$cookies.get("selectedItem") == 'null') {
    openSnackbar("error","Lütfen Proje Seçiniz.", 3500);
  }
}

export function downloadZipFile() {
  axios({
    method: "get",
    url: "draftZipDownloads",
    responseType: "arraybuffer",
  })
    .then((response) => {
      forceFileDownload(response);
    })
    .catch(() => console.log("error occured"));
}
function forceFileDownload(response) {
  
  console.log(response)
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  let zipName = response.config.headers.urlPath
  for (let i = 0; i < draftNames.length; i++) {
    if (draftNames[i].urlName == zipName) {
      zipName = draftNames[i].zipName
    }
  }
  link.href = url;
  link.setAttribute("download", `${zipName} Taslak Didero.zip`); //or any other extension
  document.body.appendChild(link);
  link.click();
}

const draftNames = [
  {zipName: 'Sigorta', urlName: "insurance2"},
  {zipName: 'Kira', urlName: 'rent'},
  {zipName: 'Nakdi Sermaye Artışı', urlName: 'capitalCashIncrease'},
  {zipName: 'Amortisman', urlName: 'depreciationInsert'},
  {zipName: 'Krediler', urlName:"credit"},
  {zipName: 'Sigorta Diğerleri', urlName: "insuranceUpdate"},
  {zipName: 'Örtülü Sermaye', urlName: "hiddenCapitalUpdate"},
  {zipName: "Önceki Dönem Mizan", urlName: "previousPeriodMizan"}
]