<template>
  <div :id="id" :key="renderKey">
    <div
      v-for="(layoutElement, x) in layoutElements"
      :key="x"
      :class="layoutElement.layoutClass"
      :style="layoutElement.layoutStyle"
    >
      <component :is="layoutElement.type" :name="layoutElement.name">
        <v-row v-if="layoutElement.tableObject">
          <table-component
            :dynamic-element="layoutElement.tableObject"
            :model="model"
            :style="layoutElement.tableStyle"
          />
        </v-row>
        <div v-else class="rowClass" :style="layoutElement.rowStyle">
          <div
            v-for="(inputElements, i) in layoutElement.inputElements"
            :key="i" class="colClass" :style="layoutElement.colStyle"
          >
            <div
              v-for="(inputElement, j) in inputElements"
              :key="j"
              :style="inputElement.divStyle"
              :class="inputElement.divClass"
            >
              <input-component
                :input-element="inputElement"
                :model="model"
                :dynamic-elements="dynamicElements"
              ></input-component>
            </div>
          </div>
        </div>
      </component>
    </div>

    <div v-if="globalComponent.globalDialogInitialized"  :key="globalComponent.renderKey2" >
      <div v-for="(globalDialog, i) in globalComponent.globalDialogs" :key="i">
        <dialog-component
          v-if="globalDialog.dialogVisible"
          :dynamic-element="globalDialog"
          :model="model"
        />
      </div>
    </div>
    <div v-if="globalComponent.snackbarInitialized">
      <snackbar-component />
    </div>
  </div>
</template>

<script>
import InputComponent from "../input/inputComponent.vue";
import { VForm } from "vuetify/lib";
import snackbarComponent from "./snackbarComponent.vue";
import { hashCode } from "../helper/elementHelper.js";

export default {
  components: {
    VForm,
    "input-component": InputComponent,
    "table-component": () => import("./tableComponent.vue"),
    "snackbar-component": snackbarComponent,
    "dialog-component": () => import("./dialogComponent.vue"),
  },
  props: {
    dynamicElements: {},
    layoutElements: {
      type: Array,
      default: () => [{}],
    },
    model: {},
  },
  data: () => ({
    renderKey: hashCode(),
    globalComponent: {},
    id: hashCode(),
  }),
  created() {
    this.modelControl();
    for (let i = 0; i < this.dynamicElements.length; i++) {
      this.$store.state.divList[ this.dynamicElements[i].name  ] = this.dynamicElements[i];
      this.$store.state.divModel[this.dynamicElements[i].name] = this.model;
      this.dynamicElements[i].forceRender = this.forceRender;
    }
    this.globalComponent.renderKey2 = hashCode();
    this.globalComponent.globalDialogInitialized = false;
    this.globalComponent.globalDialogs = [];
    
    if (this.$store.state.globalDialogInitialized == false) {
      this.globalComponent.globalDialogInitialized = true;
      this.$store.state.globalDialog = this.globalComponent;
    }
    // if (this.$store.state.addedLayouts == undefined) {
    //   this.$store.state.addedLayouts = []
    // }
    // this.$store.state.addedLayouts.push(this)
    this.$store.state.layouts[this.id] = this.layoutElements; //buradaki layouts state'i nerede kullanılıyor?(dimro-didero-userPortal-empro projelerinde kullanılmamış)
    this.$store.state.globalDialogInitialized = true;

    this.globalComponent.snackbarInitialized = false;
    if (this.$store.state.snackbarInitialized == false) {
      this.globalComponent.snackbarInitialized = true;
    }

    this.$store.state.snackbarInitialized = true;
  },
  methods: {
    modelControl() {
      if (!Array.isArray(this.layoutElements)) {
        this.layoutElements = [this.layoutElements];
      }
      for (let i = 0; i < this.layoutElements.length; i++) {
        if (
          this.layoutElements[i].inputElements != undefined &&
          Array.isArray(this.layoutElements[i].inputElements) &&
          this.layoutElements[i].inputElements.length > 0 &&
          !Array.isArray(this.layoutElements[i].inputElements[0])
        ) {
          this.layoutElements[i].inputElements = [
            this.layoutElements[i].inputElements,
          ];
        }
      }
    },
    forceRender() {
      this.renderKey++;
      // if (this.$store.state.addedLayouts != undefined ) {
      //   for (let i = 0; i < this.$store.state.addedLayouts.length; i++) {
      //     this.$store.state.addedLayouts[i].renderKey++
        
      //   }
      // }
    },
  },
};
</script>

<style>
.rowClass{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  padding: 10px;
  gap:5px;
 
  

}
.colClass{

  width: fit-content;
 


}</style>