//import * as dateParser from "../tools/dateparser";

export function isObjectContainTrue(myObj) {
  // Verilen objenin içinde true değerinin olup olmadığını kontrol eden method
  let result = Object.keys(myObj).some(function(k) {
    return myObj[k] === true;
  });
  return result;
}

export function yerliReplace(variable, target, changed) {
  let x = variable;
  let y = "";
  for (let i = 0; i < x.length; i++) {
    if (x[i] == target) {
      y += changed;
    } else {
      y += x[i];
    }
  }
  return y;
}

export function objectInputConverter(object) {
  let keys = Object.keys(object);
  for (let i = 0; i < keys.length; i++) {
    if (object[keys[i]] != null) {
      if (keys[i] != "activationDate" && object[keys[i]] != "Bulunamadı" && keys[i] != "stockAmount") {
        if (keys[i].includes("Amount") || keys[i].includes("amount") || keys[i].includes("Price") || keys[i].includes("Monthly") || keys[i].includes("Vat") || keys[i].includes("unitMultiplier") || keys[i].includes("vat") || keys[i].includes("price") || keys[i].includes("Money") || keys[i].includes("currencyRate")) {
          object[keys[i]] = yerliReplace(object[keys[i]], ".", "");
          object[keys[i]] = parseFloat(yerliReplace(object[keys[i]], ",", "."));
        }
      }
    }
  }
  return object;
}

export async function searchFilterArrangement(headers, filters){
  let filtersKey = Object.keys(filters)
  if (filtersKey.length == 0) {
    return filters
  }else{
    for (let i = 0; i < filtersKey.length; i++) {
      let controlCurrency = headers.filter((header) => {
        if (header.value == filtersKey[i]) {
          if (header.dataType == "currency") {
            return true
          }else{
            return false
          }
        }
      });
      if (controlCurrency) {
        if (filters[filtersKey[i]].includes(",")) {
          let splitCurrency = filters[filtersKey[i]].split(",")
          if (splitCurrency[1] == "00") {
            filters[filtersKey[i]] = yerliReplace(filters[filtersKey[i]], ".", "")
            filters[filtersKey[i]] = String(parseFloat(yerliReplace(filters[filtersKey[i]], ",", "."))) 
          }else{
            filters[filtersKey[i]] = yerliReplace(filters[filtersKey[i]], ".", "")
            filters[filtersKey[i]] = yerliReplace(filters[filtersKey[i]], ",", ".")
          }
        }        
      }
    }
    return filters
  }
}
export function objectInputConverterTable(object , updateValue) {
  let keys = Object.keys(object);
  //Tarihin tam adını bulmak için yazıldı period parçalamak için
  for (let i = 0; i < keys.length; i++) {
    for (let index = 0; index < updateValue.length; index++) {     
      if (keys[i].includes("Amount") && keys[i] == updateValue[index]) {
        if (object[keys[i]] != "Bulunamadı") {
          object[keys[i]] = yerliReplace(object[keys[i]], ".", "");
          object[keys[i]] = parseFloat(yerliReplace(object[keys[i]], ",", "."));
        }
      }
      if (keys[i].includes("rate") && keys[i] == updateValue[index]) {
        if (object[keys[i]] != "Bulunamadı") {
          object[keys[i]] = yerliReplace(object[keys[i]], ".", "");
          object[keys[i]] = parseFloat(yerliReplace(object[keys[i]], ",", "."));
        }
      }
    }
  }
  return object;
}

export function objectInputDefinier(rawArray) {
  if (Array.isArray(rawArray)) {
    if (rawArray.length > 0) {
      let numberSearch = ["miktar", "tutar", "bedel"];
      let dateSearch = ["tarih"];

      const multiSearchOr = (text, searchWords) =>
        searchWords.some((el) => {
          return text.match(new RegExp(el, "i"));
        });

      for (let index = 0; index < rawArray.length; index++) {
        if (multiSearchOr(rawArray[index].text, numberSearch)) {
          rawArray[index].inputType = "number";
        } else if (rawArray[index].value == "tin_number") {
          rawArray[index].inputType = "tin_number";
        } else if (multiSearchOr(rawArray[index].text, dateSearch)) {
          rawArray[index].inputType = "dateInput";
        } else if (rawArray[index].value == "yuklenim_type") {
          rawArray[index].inputType = "yuklenim_type";
        }
      }
    }
    return rawArray;
  } else {
    return [];
  }
}
