<template>
    <v-app>
      <div class="header-div">
        <h3 class="header-h3">Kur Etkisi</h3>
      </div>
      <table-component :dynamic-element="tableElements" :model="model" />    
      <Loader></Loader>
      <snackbar-component />
    </v-app>
</template>

<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import XLSX from "xlsx";
import {
  openSnackbar,
  showLoader,
  openTablePage,
  getTable,
  deleteItem,
  closeDialog,
  excelDownloadDefault,
  //getTableModel,
  //showDialog,
  hideLoader,
    updateItem,
  saveItem
  //hashCode
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";
import {axiosUploadTypeArrayBuffer} from "../../helper/uploadHelperForData"

export default {
    components: {
    "table-component": tableComponent,
    "upload-component": uploadComponent,
    Loader: Loader,
    "snackbar-component": snackbarComponent,
    },
    data() {
        return {
          tableElements: {},
          model: {},
          renderKey: 0,
        };
    },
    created() {
        this.loadData();
    },
    watch: {},
    methods: {
        loadData() {
          let that = this
            this.tableElements = {
            sortBy: [],
            sortDesc: false,
            renderKey: 0,
            type: "table",
            tableHeight : 500,
            name: "mizanTable",
            class: "",
            infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
            excelDownloadBtnText: "İndir",
            deleteDialogVisible : true,
            hidePagination: true,
            filteredColumn: {},
            filteredColumns: true,
            //selectionUrl: "incomeStatementTable",
                toolbars: [
                  {
                    type: Enum.layoutType.div,
                    name: "toolbarsForm",
                    inputElements: [
                    [
                     {
                        id : "startDate",
                        type : "v-date-picker",
                        readonly: true,
                        variant : "text",
                        name : "startDate",
                        label : "Başlangıç Tarihi",
                        value : "",
                        prependIcon : "fas fa-at",
                        placeHolder : "",
                        style : "width: 260px;",
                        classTag : "",
                        onClick : function(thisData,model,dynamicElements) {
                        }
                      },
                    ],
                    [
                     {
                        id : "endDate",
                        type : "v-date-picker",
                        readonly: true,
                        variant : "text",
                        name : "endDate",
                        label : "Bitiş Tarihi",
                        value : "",
                        prependIcon : "fas fa-at",
                        placeHolder : "",
                        style : "width: 260px;",
                        classTag : "",
                        onClick : function(thisData,model,dynamicElements) {
                        }
                      },
                    ],
                    [
                      {
                        id : "get",
                        type : "v-btn",
                        variant : "btn",
                        name : "get",
                        inputText : "Hesapla",
                        divStyle:"",
                        style:"height: 25px !important; margin-top: 25%",
                        btnTypeText: false,
                        color : "primary",
                        prependIcon : "",
                        classTag : "",
                        onClick : function(thisData, model, dynamicElements) {
                          if (model.startDate === "" || model.endDate === "") {
                                openSnackbar("error","Girilen Değerleri Doldurunuz");
                                return false;
                            }
                          if (((model.startDate != undefined) && (model.endDate != undefined))) {
                              let startDateNew = model.startDate.split("/")
                              startDateNew = new Date(startDateNew[2] + "-" + startDateNew[1] + "-" +startDateNew[0])
                              let endDateNew = model.endDate.split("/")
                              endDateNew = new Date(endDateNew[2] + "-" + endDateNew[1] + "-" +endDateNew[0])
                              const diffTime = Math.abs(endDateNew - startDateNew);
                              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                            
                              if (diffDays > 370) {
                                openSnackbar("error","Seçilen Tarihler Arası Bir Yıldan Büyük Olamaz", 2000);
                                return false;
                              }
                              if (startDateNew > endDateNew) {
                                openSnackbar("error","Başlangıç Tarihi, Bitiş Tarihinden Büyük Olamaz",2000);
                                return false;
                              }
                            }
                          showLoader('Hesaplamalar Yapılıyor') 
                          sendRequest(Enum.requestType.POST, "currencyImpact", "", 
                            {
                            startDate: model.startDate, 
                            endDate: model.endDate,
                            cookieData: window.$cookies.get("selectedItem")
                            }, 
                            function (response) {
                              dynamicElements.headers = [
                                { text: "Hesap Kodu", value: "accountCode", width: "110" , divider: true,},
                                { text: "Hesap Adı", value: "accountName", width: "110" , divider: true,},
                                { text: "Bakiye", value: "balance", width: "100", dataType: "currency" , divider: true,}
                              ];
                                let endDateMonth = parseInt(model.endDate.split("/")[1]);
                                let startDateMonth = parseInt(model.startDate.split("/")[1]);
                              let enumDates = {
                                1 : "Ocak",
                                2 : "Şubat",
                                3 : "Mart",
                                4 : "Nisan",
                                5 : "Mayıs",
                                6 : "Haziran",
                                7 : "Temmuz",
                                8 : "Ağustos",
                                9 : "Eylül",
                                10 : "Ekim",
                                11 : "Kasım",
                                12 : "Aralık"
                              }
                              for (let i = startDateMonth; i <= endDateMonth; i++) {
                                dynamicElements.headers.push({ text: `${enumDates[i]}`, value: i, width: "100", dataType: "currency" , divider: true, align: "right"})
                              }
                              dynamicElements.data = response.data.data.data;
                              model.itemCount = dynamicElements.data.length;
                              model.pagesCount = dynamicElements.data.length / 50;
                              dynamicElements.forceRender();
                              hideLoader();
                            },
                            function (error) {errFunc(error);}
                          )
                        }
                      }
                    ],
                    [
                      {
                        id: "excelDownload",
                        type: "v-btn",
                        variant: "btn",
                        name: "excelDownload",
                        inputText: "excelDownloadBtnText",
                        style:"height: 25px !important; margin-top: 25%",
                        divStyle: "",
                        icon: "mdi-file-excel",
                        btnTypeText: false,
                        color: "success",
                        prependIcon: "",
                        classTag: "",
                        onClick: function(thisData, model, dynamicElements) {
                          if (model.startDate === "" && model.endDate === "") {
                                openSnackbar("error","Girilen Tarih Değerleri Boş Olamaz");
                                return false;
                           }
                          if (((model.startDate != undefined) && (model.endDate != undefined))) {
                            let startDateNew = model.startDate.split("/")
                            startDateNew = new Date(startDateNew[2] + "-" + startDateNew[1] + "-" +startDateNew[0])
                            let endDateNew = model.endDate.split("/")
                            endDateNew = new Date(endDateNew[2] + "-" + endDateNew[1] + "-" +endDateNew[0])
                            const diffTime = Math.abs(endDateNew - startDateNew);
                            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
                            if (diffDays > 370) {
                              openSnackbar("error","Seçilen Tarihler Arası Bir Yıldan Büyük Olamaz", 2000);
                              return false;
                            }
                            if (startDateNew > endDateNew) {
                              openSnackbar("error","Başlangıç Tarihi, Bitiş Tarihinden Büyük Olamaz",2000);
                              return false;
                            }
                          }
                          showLoader('Dosyanız Hazırlanıyor')
                          sendRequest(Enum.requestType.POST,"currencyImpact","",
                            { 
                              startDate: model.startDate, 
                              endDate: model.endDate,
                              excelDownload : true,
                              choice : "currencyImpactTable",
                              cookieData: window.$cookies.get("selectedItem")
                            },
                            function (response) {
                              if (response.data.data.data != true) {
                                openSnackbar('error', "İndirilecek Veri Bulunamadı", 3000)
                                hideLoader();
                                return false
                              }
                              let zipNameStartDate = model.startDate.replaceAll("/", "_")
                              let zipNameEndDate = model.endDate.replaceAll("/", "_")
                              let zipName = "Kur Etkisi" + zipNameStartDate + "ve" + zipNameEndDate
                              axiosUploadTypeArrayBuffer("allExcelZipDownload", "currencyImpactTable", zipName, "Excel İndirildi")
                            },
                            function (error) {
                              openSnackbar(
                                "error",
                                error.response.data.error[0].detail.message
                              );
                              hideLoader();
                            }
                          )
                        },
                      }
                    ],
                  ],
                }
              ],
              headers: [
                { text: "Hesap Kodu", value: "accountCode", width: "110" , divider: true,},
                { text: "Hesap Adı", value: "accountName", width: "110" , divider: true,},
                { text: "Bakiye", value: "balance", width: "100", dataType: "currency" , divider: true,},
              ],
            }
        }
    }
}

</script>