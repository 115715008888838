<template>
    <div>
        <v-dialog v-model="visibleDialog" max-width="650px" persistent>
            <v-list color="blue-grey">
                <v-list-item>
                    <v-list-item-title style="color:white; text-align: center"> {{dialogText}} </v-list-item-title>
                </v-list-item>
                <v-list-item-subtitle>
                    <div class="text-center">
                        <v-row class="text-right" >
                            <v-btn text color="white" style="margin-left:35%" @click="deleteButtonClick(true)">Evet</v-btn>
                            <v-btn text color="white"  @click="deleteButtonClick(false)">Hayır</v-btn>
                        </v-row>
                    </div>
                </v-list-item-subtitle>
            </v-list>
        </v-dialog>
    </div>
</template>

<script>

export default {
    props: {
        // cardTitle : {
        //     type: String,
        //     default: () => ''
        // },
        visibleDialog : {
          type: Boolean,
          default: () => false
        },
        dialogText : {
          type: String,
          default: () => ""
        },
    },
    data() {
        return {

        }
    },
    created() {

    },
    methods: {
        contractInfoContinue : function () {
            this.$emit('continue-dialog',this.selectedContractInfoRow);
            this.selectedContractInfoRow = [];
        },
        deleteButtonClick(type) {
            this.$emit('continue-func',type);
        },
    }
}
</script>