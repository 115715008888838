import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@mdi/font/css/materialdesignicons.css'
import { router } from "./router/router.js";
import VueRouter from "vue-router";
import store from "./store";
import Vuecookies from 'vue-cookies'

Vue.use(VueRouter, Vuecookies);

router.beforeEach((to, from, next) => { 
  let loggedUser = window.$cookies.get("loggedUser")
  let companyId = store.state.loggedUser.companyId
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (loggedUser == null || loggedUser == undefined) {
      next({
        path: "/loginPage"
      });
    } else {
      if (companyId == undefined || companyId == null) {
        next({
          path: "/landing",
          query: {
            redirect: to.fullPath
          }
        });
      } else if (to.meta.id && to.path != "/") {
        next({ path: "/createProject" });
      }
      else {
        next();
      }
    }
  } 
  else if((to.path == '/loginPage' || to.path == '/landing' ) && (companyId != null || companyId != undefined)) {
    next({ path: "/createProject" });
  }
  else {
    next();
  }
});

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
