<template>
  <div id="container" class="mainContainer">
    <div class="leftBox" :style = projectDesign.color :key="renderKey">
      <div class="leftInnerBox" :style = projectDesign.logoBackground :key="renderKey">
        <img class="leftImg" :src= projectDesign.leftBoxLogo alt="Resim Yükleniyor" >
      </div>
    </div>
    <div class="rightBox">
      <div class="rightInnerBox">
          <img class="rightImg" :src= projectDesign.rightBoxLogo alt="Resim Yükleniyor"/>
          <h4 class="rightTitle">Lütfen Yeni Parolanızı Giriniz</h4>
          <v-form class="rightInputArea" ref="form" v-model="valid">
              <v-text-field
                class="rightInput"
                id="password"
                label="Parolanızı Giriniz"
                name="password"
                v-model="Password"
                type="password"
                :rules="[rules.required,rules.counter]"
              >
              <v-icon slot="prepend" color="black">
              lock
              </v-icon>
              </v-text-field>
              <v-text-field
                class="rightInput"
                v-model="confirmPassword"
                id="confirmPassword"
                label="Parolanızı Doğrulayınız"
                name="confirmPassword"
                type="password"
                required
                :error-messages="passwordMatchError()"
              >
              <v-icon slot="prepend" color="black">
              lock
              </v-icon>
              </v-text-field>             
            </v-form>      
          <v-btn :style= projectDesign.btnStyle   color="#FF5325FF" :disabled="!valid" @click="projectDesign.sendPass(Password, $route.params.hash)">Parola Oluştur</v-btn>
          <div class="socialLinkArea">
            <v-btn class="linkButton" fab color="black" outlined target="_blank" href="https://www.facebook.com/sharer.php?u=https://egeteknopark.com.tr/portfolio/notitek-yazilim/">
              <img src="../assets/facebook.svg" class="iconFB" />
            </v-btn>
            <v-btn class="linkButton" fab color="black" outlined target="_blank" href="https://www.notitek.com.tr/">
              <img src="../assets/www.svg" class="icon"/>
            </v-btn>
            <v-btn class="linkButton" fab color="black" outlined target="_blank" href="https://tr.linkedin.com/company/notitek-software">
              <img src="../assets/linkedin.svg" class="icon"/>
            </v-btn>
          </div>
      </div>
    </div>
    <snackbar-component />
  </div>
</template>

<script>
import layout from "./layout.vue";
import snackbarComponent from "./snackbarComponent.vue";


export default {
    components: { 'layout' : layout,  "snackbar-component": snackbarComponent },
    props: {
        projectDesign : {},
        colors : "",
        inputElement : {
          type: Object,
          default: () => ({
            logoBackground : "",
            leftBoxLogo : "",
            rightBoxLogo : "",
            btnStyle : "",
            sendMail : function (userName,password) {
               return "function"
            }
          })
        },
        dynamicElements : {
          type: Array,
          default: () => [{}]
        },
        tableHeader : {},
        model : {}
    },
    created(){
      this.renderKey++
      let loginPageEnums ={
        //color : "background-color: #b71111!important;",
        color : "background-color:black!important;",
        logoBackground : "background-color: #b71111!important;",
        leftBoxLogo : 'https://www.notitek.com.tr/wp-content/uploads/elementor/thumbs/logo-beyaz-pyfyeteuugdk4prv1lrcgbvo4p53ksuriqu3jt5hc0.png',
        rightBoxLogo : 'https://www.notitek.com.tr/wp-content/uploads/elementor/thumbs/logo-beyaz-pyfyeteuugdk4prv1lrcgbvo4p53ksuriqu3jt5hc0.png',
        loginFunction : {},
      }
    },
    data () {
      return {
        valid:false,
        email: "",
        Password: "",
        confirmPassword: "",
        rules: {
            required: value => !!value || "Gerekli.",
            counter: value => value.length >= 5 || "En az 5 karakter"
        },
        
        color : "background-color: black!important;",
      }
    },
    mounted()  {
      window.addEventListener("keydown",(a) => {
      })
    },
    methods:{
        passwordMatchError() {
          return this.Password === this.confirmPassword ? "" : "Şifreler Eşleşmeli";
        },
    }
    
}
</script>
<style src='../../../../frontend/components/atoms/css/createPassword.css' scoped>

</style>



