import { render, staticRenderFns } from "./dialogComponent.vue?vue&type=template&id=2a76d836&scoped=true&"
import script from "./dialogComponent.vue?vue&type=script&lang=js&"
export * from "./dialogComponent.vue?vue&type=script&lang=js&"
import style0 from "./dialogComponent.vue?vue&type=style&index=0&id=2a76d836&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../vue/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a76d836",
  null
  
)

export default component.exports