<template>
    <v-app>
      <div class="header-div">
        <h3 class="header-h3">ORTAK VE İLİŞKİLİ KİŞİLER ÖRTÜLÜ SERMAYE VERİ GİRİŞİ</h3>
      </div>
      <!-- <v-toolbar-items style="margin-left: 25px; height: 30px;">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text @click="downloadDrafts" v-on="on" style="background-color: #4BB543; color: white; border-radius: 5px;">
              Taslak İndir
            </v-btn>
          </template>
        </v-tooltip>
      </v-toolbar-items> -->
        <table-component :dynamic-element="tableElements" :model="model" class="small-page-layout"/>
        <Loader></Loader>
        <!-- <snackbar-component /> -->
    </v-app>
</template>
<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";

import {
  openSnackbar,
  openTablePage,
  showLoader, 
  hideLoader 
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import {axiosUploadTypeArrayBuffer, checkProject, downloadZipFile} from "../../helper/uploadHelperForData"
export default {
    components: {
        "table-component": tableComponent,
        "upload-component": uploadComponent,
        Loader: Loader,
        "snackbar-component": snackbarComponent,
    },

    data() {
        return {
          tableElements: {},
          model: {},
          renderKey: 0,
        };
    },
    created() {
      checkProject();
      this.loadData();
    },
    watch: {},
    methods: {
    downloadDrafts() {
      downloadZipFile();
    },
    loadData() {
      this.tableElements = {
        sortBy: [],
        sortDesc: false,
        renderKey: 0,
        type: "table",
        tableHeight : 530,
        name: "hiddenCapitalTable",
        class: "",
        infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
        excelDownloadBtnText: "İndir",
        deleteDialogVisible : true,
        hidePagination: true,
        //selectedRows: [],
        filteredColumn: {},
        filteredColumns: true,
        selectionUrl: "hiddenCapitalUpdate",
        initialFilter:{
          [Enum.initialFilter.AND] :
          [
            {
              projectId: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id
              },auditDate: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").auiditDate
              },preriorDate: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").preriorDate
              }
            }
          ]
        },
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
              [
                {
                  id: "hiddenCapitalAutoUpdate",
                  type: "v-btn",
                  variant: "btn",
                  name: "hiddenCapitalAutoUpdate",
                  inputText: "Mizan'dan Getir",
                  divStyle: "",
                  style: "height: 25px !important;",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    showLoader('Verileriniz Kayıt Ediliyor')
                    sendRequest(Enum.requestType.POST, "hiddenCapitalAutoUpdate", "", 
                    {
                      cookieData: window.$cookies.get("selectedItem"),
                      firstDefinitionId: dynamicElements.data[0].definitionId,
                      firstSectionId: dynamicElements.data[0].sectionId,
                      secondDefinitionId: dynamicElements.data[1].definitionId,
                      secondSectionId: dynamicElements.data[1].sectionId,
                      thirdDefinitionId: dynamicElements.data[2].definitionId,
                      thirdSectionId: dynamicElements.data[2].sectionId,
                      dynamicElementsData: dynamicElements.data
                    }, 
                    function (response) {
                      hideLoader();
                      openTablePage('hiddenCapitalTable');
                      openSnackbar("success", "Verileriniz Başarılı Bir Şekilde Getirildi", "2000")
                    },
                      function (error) {
                        openSnackbar("error", "Verileri Kaydederken Bir Hata Oluştu", "2000")
                        hideLoader();
                        openTablePage('hiddenCapitalTable');
                      }
                      
                    )
                  },
                },
              ],
            ],
          }
        ],
        headers: [
          { text: "Açıklamalar", value: "description", width: "100", divider: true,},
          { text: "Tutar", value: "value", width: "20%", dataType: "currency", divider: true, align: "right",},
        ],
        columns: {
          value: {
              type: "v-text-field",
              editable: true,
              creatable: true,
              width: "200",
              inlineEditable: true,
              label: "Değer",
              onSave: function(thisData, model, dynamicElements) {
                showLoader("Dosyanız Yükleniyor. Lütfen Bekleyiniz.");
                sendRequest(Enum.requestType.POST,"updateProjectAttachmentValue","",
                      {
                        updateObj: {
                          attachmentDefinitionCode: model.description,
                          value: model.value,
                          definitionId: model.definitionId,
                          sectionId: model.sectionId,
                          cookieData: window.$cookies.get("selectedItem")
                        }
                      },
                      function (response) {
                        openTablePage("hiddenCapitalTable")
                      },
                      function (error) {
                        openSnackbar(
                          "error",
                          error.response.data.error[0].detail.message
                          
                        );
                        hideLoader();
                      }
                    )
              },
           },
        }
      };
    }
  },
}
</script>

<style>
.header-div {
  text-align: center;
}
</style>