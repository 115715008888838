<template>
  <v-app>
    <table-component :dynamic-element="dynamicElements" :model="model" />
    <loader />
  </v-app>
</template>

<script>
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from '../../../frontend/components/atoms/loader'
import {
  getTable,
  openTablePage,
  showDialog,
  closeDialog,
  passivePull,
  excelDownload,
  getTableModel,
  showTableColoumn,
  hideTableColoumn,
  showLoader,
  hideLoader,
  deleteItem
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import { sendRequest } from '../../../frontend/components/atoms/helper/requestHelper';
import { openSnackbar } from '../../../frontend/components/atoms/helper/elementHelper';
export default {
  components: { "table-component": tableComponent , 'loader' : Loader},

  data() {
    return {
      dynamicElements: {},
      model: {},
      renderKey: 0,
    };
  },

  created() {
    this.loadData();
  },
  watch: {},
  methods: {
    loadData() {
      this.model = {};
      this.dynamicElements = {
        sortBy: [],
        sortDesc: false,
        clientEvents : {
          onCellClick : function (/*row ve Column Index,prop bilgileri*/) {
            //table ın body sinde
          },
          onBeforeUpdate : function () {
            console.log('onBeforeUpdate yapıldı')
          },
          onBeforeDelete : function () {
            console.log('onBeforeDelete yapıldı')
          },
          onBeforeSave : function () {
            console.log('onBeforeSave yapıldı')
          },
          onAfterUpdate : function () {
            console.log('onAfterUpdate yapıldı')
          },
          onAfterSave: function () {
            console.log('onAfterSave yapıldı')
          },
          onAfterDelete :function () {
            console.log('onAfterDelete yapıldı')
          },
          onBeforeSelect : function () {
            console.log('onBeforeSelect yapıldı')
          },
          onAfterSelect : function () {
            console.log('onAfterSelect yapıldı')
          },
          onSelect : function () {
            console.log('onSelect yapıldı')
          },
          onSaveError: function () {
            console.log('onSaveError yapıldı')
          },
          onUpdateError : function () {
            console.log('onUpdateError yapıldı')
          },
          onDeleteError: function () {
            console.log('onDeleteError yapıldı')
          },
          //table için
          onBeforeunload: function () {
            console.log('onBeforeunload yapıldı')
          },
          onafterUpload: function () {
            console.log('onafterUpload yapıldı')
          },
          dataChanged: function () {
            console.log('dataChanged yapıldı')
          },
          searchOnClick: function () {
            console.log('searchOnClick yapıldı')
          },
        },
        selectedRows: [],
        renderKey: 0,
        type: "table",
        name: "assistantTableWith",
        class: "",
        infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
        updateBtnText: "Güncelle",
        deleteBtnText: "Sil",
        searchBtnText: "Ara",
        clearSearchBtnText: "Temizle",
        filterBtnText: "Filtre",
        excelDownloadBtnText: "İndir",
        emailBtnText: "Email",
        hidePagination: true,
        filteredColumn: {},
        filteredColumns: true,
        selectionUrl: "gettabledata",
        deleteUrl: "deletefortable",
        passivePullUrl: "passivepullforassistant",
        showDefaultDeleteButon : false,
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
              [
                {
                  id: "passivePull",
                  type: "v-btn",
                  variant: "btn",
                  name: "passivePull",
                  inputText: "Pasife Çek",
                  divStyle: "",
                  style: "height: 25px !important;",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    console.log(thisData, model, dynamicElements);
                    var tableObject = getTable("assistantTable");
                    console.log(tableObject);
                    if (tableObject.showSelecetVisible == true) {
                      if (tableObject.selectedRows.length > 0) {
                        if (tableObject.data.length > 0) {
                          let options = {
                            showDialog: true,
                          };
                          passivePull(
                            tableObject.name,
                            tableObject.selectedRows,
                            options,
                            model
                          );
                        } else {
                          alert("Veriniz mevcut değildir");
                        }
                      } else {
                        tableObject.showSelecetVisible = false;
                      }
                    } else {
                      tableObject.singleSelect = false;
                      tableObject.showSelecetVisible = true;
                    }
                  },
                },
              ],
              [
                {
                  id: "assigningReceipt",
                  type: "v-btn",
                  variant: "btn",
                  name: "assigningReceipt",
                  inputText: "Fiş Atama",
                  divStyle: "",
                  style: "height: 25px !important;",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    console.log(thisData, model, dynamicElements);
                  },
                },
              ],
              [
                {
                  id: "tableDialogBtn",
                  type: "v-btn",
                  variant: "btn",
                  name: "tableDialogBtn",
                  style: "height: 25px !important;",
                  inputText: "Sütun Ekle",
                  divStyle: "",
                  btnTypeText: false,
                  color: "primary",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    console.log(
                      "send delete" + thisData + dynamicElements,
                      model
                    );
                    model = getTable("assistantTable");
                    let dialogInfo = {
                      name: "infoTableDialog",
                      maxWidth: "700",
                      dialogVisible: "dialogVisible",
                      title: "Ekstra Getirilmesi İstenilen Sütunlar",
                      layout: [
                        {
                          type: Enum.layoutType.div,
                          name: "tableDialog",
                          inputElements: [
                            [
                              {
                                id: "kebir",
                                type: "v-checkbox",
                                name: "kebir",
                                label: "Kebir",
                                value: false,
                              },
                              {
                                id: "complementaryAccountCode",
                                type: "v-checkbox",
                                name: "complementaryAccountCode",
                                label: "Tamamlayıcı Hesap Detay",
                                value: false,
                              },
                            ],
                            [
                              {
                                id: "month",
                                type: "v-checkbox",
                                name: "month",
                                label: "Ay Kolonu",
                                value: false,
                              },
                              {
                                id: "counterAccount",
                                type: "v-checkbox",
                                name: "counterAccount",
                                label: "Karşı Hesap Detay",
                                value: false,
                              },
                            ]
                          ],
                        },
                      ],
                      actions: [
                        {
                          id: "save",
                          type: "v-btn",
                          variant: "btn",
                          name: "save",
                          inputText: "Kaydet",
                          divStyle: "",
                          btnTypeText: true,
                          color: "green darken-1",
                          classTag: "",
                          onClick: function(thisData, model, dialogInfo) {
                            let showOrHideTableColoumn = ["complementaryAccountCode" , "counterAccount" , "month" , "kebir"]
                            model = getTableModel("assistantTable");
                            for (let index = 0; index < showOrHideTableColoumn.length; index++) {
                              if(model[showOrHideTableColoumn[index]]){
                                showTableColoumn("assistantTable" , showOrHideTableColoumn[index])
                              }else{
                                hideTableColoumn("assistantTable" , showOrHideTableColoumn[index])
                              }
                            }
                            console.log(thisData, model, dialogInfo);
                            
                            closeDialog();
                          },
                        },
                      ],
                    };
                    showDialog(dialogInfo);
                  },
                },
              ],
              [
                {
                  id: "search",
                  type: "v-btn",
                  variant: "btn",
                  name: "search",
                  inputText: "searchBtnText",
                  style: "height: 25px !important;",
                  divStyle: "",
                  btnTypeText: false,
                  color: "primary",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    let dynamicElement = getTable("assistantTable");
                    if (dynamicElement.data.length > 0) {
                      console.log(thisData);
                      console.log(dynamicElements);
                      console.log(model);
                      showLoader("Verileriniz Getiriliyor.Lütfen Bekleyiniz.")
                      openTablePage("assistantTable");
                      hideLoader()
                    } else {
                      alert("Veriniz mevcut değildir");
                    }
                  },
                },
              ],
              [
                {
                  id: "clear",
                  type: "v-btn",
                  variant: "btn",
                  name: "clear",
                  inputText: "clearSearchBtnText",
                  style: "height: 25px !important;",
                  divStyle: "",
                  btnTypeText: false,
                  color: "primary",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    let dynamicElement = getTable("assistantTable");
                    console.log(thisData,dynamicElements,model);
                    dynamicElement.filteredColumn = {};
                    openTablePage("assistantTable");
                  },
                },
              ],
              [
                {
                  id: "filter",
                  type: "v-btn",
                  variant: "btn",
                  name: "filter",
                  inputText: "filterBtnText",
                  style: "height: 25px !important;",
                  divStyle: "",
                  btnTypeText: false,
                  color: "primary",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    console.log(thisData);
                    console.log(dynamicElements);
                    console.log(model);

                    var tableObject = getTable("assistantTable");
                    if (thisData.color == "primary") {
                      thisData.color = "red";
                      for (
                        let index = 0;
                        index < tableObject.headers.length;
                        index++
                      ) {
                        tableObject.headers[index].search = false;
                        tableObject.headers[index].items = [];
                      }
                    } else {
                      thisData.color = "primary";
                      for (
                        let index = 0;
                        index < tableObject.headers.length;
                        index++
                      ) {
                        tableObject.headers[index].search = true;
                      }
                    }
                  },
                },
              ],
              [
                {
                  id : "delete",
                  type : "v-btn",
                  variant : "btn",
                  name : "delete",
                  inputText : "deleteBtnText",
                  divStyle:"",
                  style:"height: 25px !important;",
                  btnTypeText: false,
                  color : "primary",
                  prependIcon : "",
                  classTag : "",
                  onClick : function(thisData,model,dynamicElements) {
                    console.log("send delete" + thisData + dynamicElements)
                    let options = {
                      showDialog : true
                    }
                    deleteItem("assistantTable",1,options,model)
                  }
                }
              ],
              [
                {
                  id: "email",
                  type: "v-btn",
                  variant: "btn",
                  name: "email",
                  inputText: "emailBtnText",
                  style: "height: 25px !important;",
                  divStyle: "",
                  btnTypeText: false,
                  icon: "mark_email_read",
                  color: "primary",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    console.log(thisData, model, dynamicElements);
                  },
                },
              ],
              [
                {
                  id: "excelDownload",
                  type: "v-btn",
                  variant: "btn",
                  name: "excelDownload",
                  inputText: "excelDownloadBtnText",
                  style: "height: 25px !important;",
                  divStyle: "",
                  icon: "mdi-file-excel",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    console.log(thisData, model, dynamicElements);
                    excelDownload("assistantTable");
                  },
                },
              ],
            ],
          },
        ],
        headers: [
          { text: "Kebir", value: "kebir", width: "50", search: false , sortable: false, visible: false , divider: true},
          
          {
            text: "Örnekleme",
            value: "sampling",
            width: "100",
            search: true,
          },
          { text: "Aktif/Pasif", value: "isActive", width: "100", search: true, visible: false , sortable: true, divider: true, switchValue: {1 : "Aktif", 2 : "Pasif"}},
          {
            text: "Hesap Kodu",
            value: "accountCode",
            width: "100",
            search: true,
            sortable: true,
            divider: true
          },
          {
            text: "Hesap Adı",
            value: "accountName",
            width: "150",
            search: true,
            divider: true,
            class : 'threedot'
          },
          { text: "Ay", value: "month", width: "50", search: false , sortable: false, visible: false, divider: true},
          { text: "Tarih", value: "billDate", width: "90", search: true, divider: true, dataType: "date"},
          {
            text: "Fiş Türü",
            value: "receiptType",
            width: "100",
            search: true,
            divider: true
          },
          {
            text: "Fiş No",
            value: "receiptNumber",
            width: "100",
            search: true,
            divider: true
          },
          { text: "Karşı Hesap Detay", value: "counterAccount", width: "160", sortable: true, search: true, class : 'threedot', visible : false },
          { text: "Tamamlayıcı Hesap Detay", value: "complementaryAccountCode", width: "160", sortable: true, search: true, class : 'threedot', visible : false },
          {
            text: "Açıklama",
            value: "explanation",
            width: "200",
            search: true,
            class: "threedot",
            divider: true
          },
          {
            text: "Borç",
            value: "debt",
            width: "100",
            align: "right",
            search: true,
            divider: true, 
            dataType: "currency"
          },
          {
            text: "Alacak",
            value: "credit",
            width: "100",
            align: "right",
            search: true,
            divider: true, 
            dataType: "currency"
          },
          {
            text: "Bakiye",
            value: "balance",
            width: "100",
            align: "right",
            search: true,
            divider: true, 
            dataType: "currency"
          },

          {
            text: "RD Kuru",
            value: "reportExchangeRate",
            width: "100",
            align: "right",
            search: true,
            divider: true, 
            dataType: "currency"
          },
          {
            text: "R. Döv. Tutarı",
            value: "reportCurrencyAmount",
            width: "100",
            align: "right",
            search: true,
            divider: true, 
            dataType: "currency"
          },
          {
            text: "R. Döv. Bakiye",
            value: "reportCurrencyBalance",
            width: "100",
            align: "right",
            search: true,
            divider: true, 
            dataType: "currency"
          },
          { text: "Döviz Adı", value: "currency", width: "100", search: true, divider: true },
          {
            text: "İşlem Döviz Kuru",
            value: "exceptionExchangeRate",
            width: "100",
            align: "right",
            search: true,
            divider: true, 
            dataType: "currency"
          },
          {
            text: "İşlem Döviz Tutarı",
            value: "exceptionCurrencyAmount",
            width: "100",
            align: "right",
            search: true,
            divider: true, 
            dataType: "currency"
          },
          {
            text: "İşlem Döviz Bakiye",
            value: "exceptionCurrencyBalance",
            width: "100",
            align: "right",
            search: true,
            divider: true, 
            dataType: "currency"
          },
        ],
        columns: {
          receiptNumber: {
            type: "v-text-field",
            editable: false,
            creatable: true,
            inlineEditable: false,
            //cell için gerekli inputElement hücre özelliştirmesi için
            cellElement: {
              id: "receiptNumber",
              type: "v-btn",
              variant: "btn",
              name: "receiptNumber",
              inputText: "receiptNumber",
              divStyle: "",
              style:
                "height:0px;text-decoration: underline;color: blue !important;",
              btnTypeText: true,
              color: "black",
              prependIcon: "",
              classTag: "",
              onClick: function(thisData, model, dynamicElements) {
                console.log(thisData, model, dynamicElements);
                
                let x= model
                console.log(x)
                let dialogInfo = {
                  name: "infoTableDialog",
                  title: model.receiptNumber + " " + "Yevmiye Fişi",
                  layout: [
                    {
                      type: Enum.layoutType.div,
                      name: "tableDialog",
                      tableObject: {
                        renderKey: 50,
                        type: "table",
                        name: "infoTable",
                        class: "",
                        infoTotalRowCountHeaderAssistantTable: "Toplam satır sayısı",
                        selectionUrl: "getassistantuploadaccountcodes",
                        hidePagination: false,
                        filteredColumn: {
                          receiptNumber: model.receiptNumber,
                          assistantDate: model.billDate,
                        },
                        toolbars: [],
                        headers: [
                          {
                            text: "Hesap Kodu",
                            value: "accountCode",
                            width: "150",
                            search: true,
                            sortable: true,
                          },
                          {
                            text: "Hesap Adı",
                            value: "accountName",
                            width: "400",
                            search: true,
                            class: 'threedot'
                          },
                          {
                            text: "Tarih",
                            value: "billDate",
                            width: "300",
                            search: true,
                            dataType: "date"
                          },
                          {
                            text: "Fiş Türü",
                            value: "receiptType",
                            width: "160",
                            search: true,
                          },
                          {
                            text: "Fiş No",
                            value: "receiptNumber",
                            width: "160",
                            search: true,
                          },
                          {
                            text: "Açıklama",
                            value: "explanation",
                            width: "2000",
                            search: true,
                          },
                          {
                            text: "Borç",
                            value: "balance",
                            width: "150",
                            align: "right",
                            search: true,
                            dataType: "currency"
                          },
                          {
                            text: "Alacak",
                            value: "credit",
                            width: "150",
                            align: "right",
                            search: true,
                            dataType: "currency"
                          },
                          {
                            text: "Bakiye",
                            value: "debt",
                            width: "150",
                            align: "right",
                            search: true,
                            dataType: "currency"
                          },
                          {
                            text: "Tutar",
                            value: "debt",
                            width: "160",
                            align: "right",
                            search: true,
                            dataType: "currency"
                          },
                        ],
                        footer: [],
                        singleSelect: true,
                        itemKey: "id",
                        columns: {},
                      },
                    },
                  ],
                  actions: [
                    {
                      id: "close",
                      type: "v-btn",
                      variant: "btn",
                      name: "close",
                      inputText: "Kapat",
                      divStyle: "",
                      btnTypeText: true,
                      color: "green darken-1",
                      classTag: "",
                      onClick: function(thisData, model, dialogInfo) {
                        console.log(thisData, model, dialogInfo);
                        closeDialog();
                      },
                    },
                  ],
                };
                //var dialogObject = getTableModel("assistantTable");
                // var tableObject = getTable("assistantTable");

                // tableObject.filteredColumn.receiptNumber = model.receiptNumber
                // tableObject.filteredColumn.assistantDate = model.billDate
                showDialog(dialogInfo);
              },
            },
          },
          complementaryAccountCode: {
            type: "v-text-field",
            editable: false,
            creatable: true,
            inlineEditable: true,
          },
          counterAccount: {
            type: "v-text-field",
            variant: "number",
            editable: false,
            creatable: true,
          },
          month: {
            type: "v-text-field",
            variant: "number",
            editable: false,
            creatable: true,
          },
          kebir: {
            type: "v-text-field",
            variant: "number",
            editable: false,
            creatable: true,
          },
          sampling : {
            type: "v-select",
            variant: "number",
            editable: true,
            creatable: true,
            inlineEditable: true,
            dropDownItems:["Significant items", "Transfers","Item Tested Separetely","MUS","Negative MUS"],
            onSave: function (thisData, model, dynamicElements) {
              console.log("thisData")
              console.log(thisData)
              console.log("model")
              console.log(model)
              console.log("dynamicElements")
              console.log(dynamicElements)
              sendRequest("POST","updateSampling","",model,function(response){
                openSnackbar("success", response.data.data.value)
              }, 
              function(error){
                if (error.response != undefined){
                  openSnackbar("error", error.response.data.error[0].detail.message)
                } else (
                  openSnackbar("error", "Sunucu Hatası")
                )
              })
            }
          }
        },

        data: "data",
        showSelecetVisible: false,
        singleSelect: true,
        itemKey: "id",
        footer: [
            {
              type: Enum.layoutType.div,
              name: "footerForm",
              inputElements: [
                [
                  {
                    id: "pageSize",
                    type: "v-text-field",
                    variant: "number",
                    name: "pageSize",
                    label: "Görünecek Satır Sayısı",
                    placeHolder: "",
                    style: "width: 45%; margin-left: 5%",
                    classTag: "",
                    onChange: function(thisData, model, dynamicElements) {
                      openTablePage("assistantTable");
                      console.log(thisData);
                      console.log(dynamicElements);
                      console.log(model);
                    },
                  },
                ],
                [
                  {
                    id: "currentPageNo",
                    type: "v-pagination",
                    variant: "pagination",
                    name: "currentPageNo",
                    divStyle: "",
                    style: "width:850px;",
                    classTag: "my-1",
                    length: "pagesCount",
                    onInput: function(thisData, model, dynamicElements) {
                      openTablePage("assistantTable");
                      console.log(thisData);
                      console.log(dynamicElements);
                      console.log(model);
                    },
                  },
                ],
                [
                  {
                    id: "infoTotalRowCountHeaderAssistantTable",
                    type: "p",
                    variant: "p",
                    name: "infoTotalRowCountHeaderAssistantTable",
                    inputText: "infoTotalRowCountHeaderAssistantTable",
                    divStyle: "",
                    style:
                      "font-size: 12px;font-weight:500;margin:3px 0;color:#666A6F;margin-right: 2%;margin-left: 60%;",
                    classTag: "",
                  },
                  {
                    id: "infoTotalRowCountAssistantTable",
                    type: "p",
                    variant: "p",
                    name: "infoTotalRowCountAssistantTable",
                    inputText: "itemCount",
                    divStyle: "",
                    style:
                      "font-size: 15px;font-weight:500;margin:3px 0;color:#666A6F;margin-left: 82%;",
                    classTag: "",
                  },
                ],
              ],
            },
        ]
      };
    },
  },
};
</script>

<style scoped>
::v-deep .threedot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 200px;
}
::v-deep .threedot:hover {
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}
</style>
