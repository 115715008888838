<template>
    <div>
      <button :key="componentKey" :style="styleType"  @click="openInfoVideo()">
         <img v-if="!buttonText"  :style="styleTypeIcon"  :src="iconSrc" /> 
        <div style="letter-spacing: 1px" v-if="buttonText">{{buttonText}}</div>
      </button>
      <v-dialog v-model="dialogVisible" max-width="80%">
        <v-card style="padding: 20px 20px 0px 20px;">
              <iframe
                v-if="dialogVisible"
                :src="videoSrc"
                allowfullscreen
                frameborder="0"
                style="width: 100%; height: 80vh"
              ></iframe>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="closeDialog()">Kapat</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  import whitePlayerIcon from '../assets/white-player.svg';
  import playerIcon from '../assets/player.svg';

  export default {
    props: {
      videoSrc: String,
      styleType: String,
      styleTypeIcon: String,
      buttonText:String,
      iconColorWhite:Boolean
    },
    data() {
      return {
        dialogVisible: false,
        componentKey: 0,
        iconSrc:""
      };
    },
    methods: {
      openInfoVideo() {
        this.dialogVisible = true;
      },
      closeDialog() {
        this.dialogVisible = false;
      },
    },
    created() {
      this.iconSrc = this.iconColorWhite ? whitePlayerIcon : playerIcon;
    }
  };
  </script>


Not: videoSrc prop'u https://www.youtube.com/embed/{video_id} biçiminde bir YouTube embed URL'si olmalıdır.
Bu bileşen, dialog penceresinde videonun embed URL'sini kullanarak YouTube videosunu gösterecektir. 
Bu yöntem, YouTube'un embed politikalarına uygun olarak çalışmalıdır.
  