<template>
  <div @click="onClick(model, dynamicElements)" class="buttonClass" v-if="elements.showInput != undefined ? elements.showInput : true">
    <div class="iconBoxClass">
      <v-icon color="#203959" dense class="iconClass">{{ elements.icon }}</v-icon>
    </div>
    <div class="iconTextClass">{{ elements.text }}</div>
  </div>
</template>
<script>
export default {
  name: "newSpecialButton",
  props: {
    elements: {
      type: Object,
      default: () => { }
    },
    model: {},
    dynamicElements: {}
  },
  methods: {
    onClick(model, dynamicElements) {
      this.elements.onClick(model, dynamicElements)
    },
  },
};
</script>

<style>
.buttonClass {
  background-color: #29405f;
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  color: white;
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
  font-size: 13px;
  margin: 3px;
  font-family: Roboto, sans-serif;
}

.buttonClass:hover {
  background-color: #203959;
}

.buttonClass:hover .iconClass {
  transform: scale(1.1);
  transition: ease-in-out;
}

.iconBoxClass {
  background-color: white;
  border-radius: 10px 0px 0px 10px;
  padding: 5px;
}

.iconTextClass {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 10px;
  color: white;
  white-space: nowrap;
}</style>
