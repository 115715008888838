<template>
<div v-if="inputElement.type == 'v-date-picker'">
  <v-menu
      ref="menu1"
      v-model="menu1"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
        :style="inputElement.style"
          autocomplete = inputElement.autocomplete
          v-model="model[inputElement.name]"
          :label="inputElement.label"
          :hint="inputElement.hint == undefined ? 'GG/AA/YYYY format' : inputElement.hint "
          :hide-details="inputElement.hideDetails == undefined ? false : inputElement.hideDetails "
          persistent-hint
          :readonly="(inputElement.readonly == undefined) ? readonly = false : readonly = true"
          :disabled="inputElement.disabled"
          prepend-icon="mdi-calendar"
          v-bind="attrs"
          @blur="date = parseDate(model[inputElement.name],inputElement.calendarType)"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        no-title
        :color="inputElement.color"
        :type="inputElement.calendarType"
        @input="menu1 = false"
        locale="tr"
      ></v-date-picker>
  </v-menu>
</div>
<div v-else-if="inputElement.type == 'autocomplete'">
  <autocomplete-component 
  :style="inputElement.style"
  :autocomplete-obj="inputElement" 
  :model="model"
  />
</div>
<div v-else-if="inputElement.type == 'v-money'">
  <div>
    <div class="v-input v-input--hide-details v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted" style="width: 260px;">
      <div class="v-input__control">
        <div class="v-input__slot">
          <div class="v-text-field__slot">
            <label for="money" class="v-label v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">{{inputElement.label}}</label>
            <input v-model.lazy="model[inputElement.name]"  v-money="inputElement.money" /> 
            <!-- <input v-model.lazy="formattedNumber"  v-money="inputElement.money" />  -->
          </div>
        </div>
      </div>
    </div> 
  </div>
  <!-- <v-currency-field v-model="model[inputElement.name]" :options="{ currency: 'TRY' }" /> -->

  <!-- <VuetifyMoney
              v-model="value2"
              v-bind:label="inputElement.label"
              v-bind:readonly="inputElement.readonly"
              v-bind:disabled="inputElement.disabled"
              v-bind:outlined="inputElement.outlined"
              v-bind:clearable="inputElement.clearable"
              v-bind:options="inputElement.money"
  /> -->

</div>
<div v-else-if="inputElement.type == 'v-radio-group'">
  <v-radio-group v-model="model[inputElement.name]"
  @change="onChange(inputElement.onChange,inputElement)"
  :style="inputElement.style"
  :rules="(inputElement.rules != undefined) ?  inputElement.rules : undefined"
  >
    <v-radio
      v-for="n in inputElement.key.length"
      :key="inputElement.key[n - 1]"
      :label="inputElement.label[n - 1]"
      :value="inputElement.value[n - 1]"
    ></v-radio>
    
  </v-radio-group>
</div>
<div v-else-if="Object.keys(inputElement).includes('mask')">
  <component
    v-if="refresh"
    :id="inputElement.id"
    :key="renderKey"
    :is="inputElement.type"
    :type="inputElement.variant"
    :name="inputElement.name"
    :label="inputElement.label"
    v-model="model[inputElement.name]"
    :prepend-icon="inputElement.prependIcon"
    :placeholder="inputElement.placeHolder"
    :color="inputElement.color"
    :readonly="inputElement.readonly"
    :style="inputElement.style"
    :class="inputElement.classTag"
    :rules="(inputElement.type == 'v-text-field') ?  inputElement.rules : undefined"
    @click="onClick(inputElement.onClick,inputElement)"
    @change="onChange(inputElement.onChange,inputElement)"
    :text="(inputElement.type == 'v-btn') ?  inputElement.btnTypeText : undefined"
    :to="inputElement.to"
    :length="model[inputElement.length]"
    @input="onInput(inputElement.onInput,inputElement)"
    :hint="inputElement.hint"
    :single-line="inputElement.singleLine"
    :autofocus="inputElement.autofocus"
    :items="(inputElement.type == 'v-select') ?  inputElement.dropDownItems : undefined"
    :multiple="inputElement.multiple"
    :hide-details="inputElement.hideDetails"
    :value="inputElement.value"
    :rows="inputElement.rows"
    :accept="inputElement.accept"
    :clearable="inputElement.clearable"
    :clear-icon="inputElement.clearIcon"
    v-bind="(inputElement.type == 'Money') ? inputElement.bind : undefined"
    v-mask="inputElement.mask"
  >
    {{model[inputElement.inputText]}}
  </component>
</div>
<div v-else>
  <component 
     v-if="inputElement.showInput != undefined ? inputElement.showInput : true"
    :id="inputElement.id"
    :key="renderKey"
    :is="inputElement.type"
    :type="inputElement.variant"
    :name="inputElement.name"
    :label="inputElement.label"
    :disabled="inputElement.disabled"
    v-model="model[inputElement.name]"
    :prepend-icon="inputElement.prependIcon"
    :placeholder="inputElement.placeHolder"
    :color="inputElement.color"
    :style="(typeof inputElement.style == 'function') ? inputElement.style(model) : inputElement.style"
    :class="inputElement.classTag"
    :rules="(inputElement.rules != undefined) ?  inputElement.rules : undefined"
    @click="onClick(inputElement.onClick,inputElement)"
    @change="onChange(inputElement.onChange,inputElement)"
    :text="(inputElement.type == 'v-btn') ?  inputElement.btnTypeText : undefined"
    :to="inputElement.to"
    :length="model[inputElement.length]"
    @input="onInput(inputElement.onInput,inputElement)"
    :hint="inputElement.hint"
    :single-line="inputElement.singleLine"
    :autofocus="inputElement.autofocus"
    :items="(inputElement.type == 'v-select')  ?  inputElement.dropDownItems : (inputElement.type == 'v-treeview')  ? inputElement.items : undefined"
    :multiple="inputElement.multiple"
    :hide-details="inputElement.hideDetails"
    :value="inputElement.value"
    :rows="inputElement.rows"
    :accept="inputElement.accept"
    :clearable="inputElement.clearable"
    :clear-icon="inputElement.clearIcon"
    :maxlength="inputElement.maxLength"
    :counter="inputElement.counter"
    :max="(inputElement.variant == 'number') ?  inputElement.maxValue : undefined"
    :min="(inputElement.variant == 'number') ?  inputElement.minValue : undefined"
    :x-small="(inputElement.type == 'v-btn') ?  inputElement.xSmall : false"
    :small="(inputElement.type == 'v-btn') ?  inputElement.small : false"
    :outlined="inputElement.outlined"
    :dense="inputElement.dense"
    :solo="inputElement.solo"
    :item-value="(inputElement.type == 'v-select') ?  inputElement.itemValue : undefined"
    :item-text="(inputElement.type == 'v-select') ?  inputElement.itemText : undefined"
    :selectable = inputElement.selectable
    :item-disabled = "(inputElement.type == 'v-treeview') ? inputElement.itemDisabled : undefined"
    :open-all = "(inputElement.type == 'v-treeview') ? inputElement.openAll : undefined"
    :selected-color = "(inputElement.type == 'v-treeview') ? inputElement.selectedColor : undefined"
  >
    {{model[inputElement.inputText] != undefined ? model[inputElement.inputText] : inputElement.disabled == true ? inputElement.disabledText : typeof(inputElement.inputText) == 'function' ? inputElement.inputText(model) : inputElement.inputText}}
    <v-icon v-if="Object.keys(inputElement).includes('icon')" right style="font-size: 20px"> {{inputElement.icon}} </v-icon>
  </component>
</div>
</template>

<script>
import {getDiff} from '../helper/jsonHelper.js'
import {VMoney} from 'v-money' //npm i v-money
import { mask } from "vue-the-mask"; //npm i vue-the-mask
import {VTextField,VBtn,VPagination,VSelect,VRadio,VCheckbox,VTextarea,VFileInput,VAutocomplete,VTreeview} from 'vuetify/lib'
import {hashCode} from '../helper/elementHelper.js'
import autocompleteComponent from '../container/autocompleteComponent.vue';
//import VuetifyMoney from "./VuetifyMoney.vue"; VuetifyMoney
import { openSnackbar } from '../helper/elementHelper.js';

export default {
  components: { VTextField,VBtn,VPagination,VSelect,VRadio,VCheckbox,VTextarea,VFileInput,VAutocomplete,VTreeview,'autocomplete-component' : autocompleteComponent },
  directives: {
    mask,
    money: VMoney
  },
  props: {
    inputElement : {
      type: Object,
      default: () => ({
 
      })
    },
    dynamicElements : {},
    model : {}
  },
//   // computed: {
//   //   formattedNumber() {
//   //     return this.model[inputElement.name].toFixed(2);
//   //   },
//   // },

// //   computed: {
// //   formattedNumber: {
// //     get() {
// //       return this.model[this.inputElement.name].toFixed(2);
// //     },
// //     set(value) {
// //       this.model[this.inputElement.name] = parseFloat(value.replace(",", "."));
// //     },
// //   },
// // },

  data: () => ({
    value2: -13.1231,
    renderKey : hashCode(),
    refresh : true,
    date : new Date().toISOString().substr(0, 10),
    menu1: false,  
   // formattedNumber: 0.0000000000
    require:{
      name:"",
      statu:false,
    }
  }),
  created() {
    this.modelControl ();
    this.requirementControlMethod()    
  },
  destroyed() {
    this.$store.state.requirementArray = {
      requireInputs:[],
      disabledButtons:[]
    }
  },
//  // beforeCreate() {this.modelControl ();},
 

  watch: {
    date () {
      this.model[this.inputElement.name] = this.formatDate(this.date, this.inputElement.calendarType)
    },

    

  // //   formattedNumber () {
  // //     this.formattedNumber = this.model[this.inputElement.name]},
  
   },
   updated() {
    this.requirementStatusCheck()
  },


  methods : {
    formatDate (date,controlType) {
      if(controlType != undefined){
        if (!date) return null
        const [year, month] = date.split('-')
        return `${month}/${year}`
      }
      else{
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      }
      
    },
    parseDate (date,controlType) {
      if(controlType != undefined){
        if (!date) return null
        else if (date.includes('/')) {
          const [month, year] = date.split('/')
          return `${year}-${month.padStart(2, '0')}`
        }
        else{
          return date;
        }
      }else{
        if (!date) return null
        else if (date.includes('/')) {
          const [day, month, year] = date.split('/')
          return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        }
        else{
          return date;
        }
      }
      
    },
    modelControl() {
      if (this.model[this.inputElement.name] == undefined) {
        this.model[this.inputElement.name] = this.inputElement.value
      }
       if (this.inputElement.type == 'v-money') {
        if (this.model[this.inputElement.name] == '') {
          this.model[this.inputElement.name] = '1,0000'
        }
        if (typeof this.model[this.inputElement.name] != 'string') {
         this.model[this.inputElement.name] = parseFloat(this.model[this.inputElement.name].toFixed(this.inputElement.money.precision));
        // this.formattedNumber = this.model[this.inputElement.name].toFixed(this.inputElement.money.precision);
        //this.model[this.inputElement.name] =  this.model[this.inputElement.name].toFixed(this.inputElement.money.precision);
        }
      }
      //this.inputElement.renderKey++;
      this.inputElement.renderKey = 0;
    },

    onClick (onClick,prop){
      if (this.inputElement.beforeOnClick != undefined) {
        this.inputElement.beforeOnClick()
      }
      if (onClick != undefined) {
        //     GECİKME      //BİZ HERŞEYİN ONCLİCK'ınden sonra komple her şeyi cloneluyoruz
        let cloned = JSON.parse(JSON.stringify(this.model))
        let clonedDynamicElements = JSON.parse(JSON.stringify(this.dynamicElements))
        //     GECİKME

        onClick(prop,this.model,this.dynamicElements)

        let diff = getDiff(this.model,cloned)

        let diffDynamicElements = getDiff(this.dynamicElements,clonedDynamicElements)

        if (Object.keys(diff).length > 0 || Object.keys(diffDynamicElements).length > 0) {
          if (this.dynamicElements.forceRender != undefined) {
            //  GECİKME
            this.dynamicElements.forceRender();
          }
        }
      }

      if (this.inputElement.afterOnClick != undefined) {
        this.inputElement.afterOnClick()
      }
    },
    onChange (onChange,prop){
      if (this.inputElement.beforeOnChange != undefined) {
        this.inputElement.beforeOnChange(prop,this.model,this.dynamicElements);
      }
      //paginationPageSizeConrol
      if(this.inputElement.paginationPageSizeConrol == true && this.inputElement.variant == 'number') {
        let pageSize = Number(this.model.pageSize)
        if (pageSize > this.inputElement.maxValue && this.inputElement.maxValue != undefined) {
          this.model.pageSize = this.inputElement.maxValue; openSnackbar("warning",`${this.inputElement.maxValue} öğeden fazla gösterilemez.`,3000)}
        else if (pageSize < this.inputElement.minValue && this.inputElement.minValue != undefined) {
          this.model.pageSize = this.inputElement.minValue; openSnackbar("warning",`${this.inputElement.minValue} öğeden az gösterilemez.`,3000)}
      }
      //pageSize değiştiğinde currentPageNo 1 olarak tekrar setler ve birinci sayfadan başlar.
      if(this.inputElement.name === 'pageSize'){
        this.model.currentPageNo = 1;
      }
      if (onChange != undefined) {
        let cloned = JSON.parse(JSON.stringify(this.model))
        let clonedDynamicElements = JSON.parse(JSON.stringify(this.dynamicElements))
        
        onChange(prop,this.model,this.dynamicElements);
        
        //this.renderKey = hashCode();

        let diff = getDiff(this.model,cloned)

        let diffDynamicElements = getDiff(this.dynamicElements,clonedDynamicElements)
        if (Object.keys(diff).length > 0 || Object.keys(diffDynamicElements).length > 0) {
          //this.$parent.forceRender  
          if (this.dynamicElements.forceRender != undefined) {
            this.$parent.forceRender()  
            //this.dynamicElements.forceRender();
          }
        }
      }
      if (this.inputElement.afterOnChange != undefined) {
        this.inputElement.afterOnChange(prop,this.model,this.dynamicElements);
      }
    },
    onInput (onInput,prop){
      if (this.inputElement.beforeOnInput != undefined) {
        this.inputElement.beforeOnInput(prop,this.model,this.dynamicElements);
      }
      if (onInput != undefined) {
        let cloned = JSON.parse(JSON.stringify(this.model))
        let clonedDynamicElements = JSON.parse(JSON.stringify(this.dynamicElements))

        onInput(prop,this.model,this.dynamicElements)
        this.renderKey++;
        let diff = getDiff(this.model,cloned)

        let diffDynamicElements = getDiff(this.dynamicElements,clonedDynamicElements)

        if (Object.keys(diff).length > 0 || Object.keys(diffDynamicElements).length > 0) {
          //this.$parent.forceRender  
          if (this.dynamicElements.forceRender != undefined) {
            this.dynamicElements.forceRender();
          }
        }
      }
      if (this.inputElement.afterOnInput != undefined) {
        this.inputElement.afterOnInput(prop,this.model,this.dynamicElements);
      }
    },
    requirementControlMethod(){
      //Created da çalışır her input çizilirken bir kez çalışır!

      // requirementArray.requireInputs ARRAY
      // tüm inputElementleri require === true olarak verilenleri yeni bir array oluşturuyorum name ve statu olarak.
      if (this.inputElement.require === true) {
      // hepsine name'lerini atıyorum 
      this.require.name = this.inputElement.name;
      //buradaki if'de model boş değilse statulerini true yapıyorum boş değilse zaten default olarak false kalıyor
      if (
        this.model[this.inputElement.name] !== undefined &&
        this.model[this.inputElement.name] !== null &&
        this.model[this.inputElement.name] !== "" &&
        this.model[this.inputElement.name] !== [] &&
        this.model[this.inputElement.name] !== {} &&
        this.model[this.inputElement.name] !== false && //checkboxlar için ama kaldırılsın mı? 
        this.model[this.inputElement.name].length !== 0 // this.model[this.inputElement.name].length > 0 olmuyor
        
      ) {
        this.require.statu = true;
      }
      //bunları check ettikten name ve statulerini atayıp burada arraya 
      this.$store.state.requirementArray.requireInputs.push(this.require);
      }

      //burada requirementControl= true olan butonları belirliyorum ve onların disabled veya able olma durumlarına bakıyorum
      if (this.inputElement.type == 'v-btn' && this.inputElement.requirementControl == true) {
        //buradaki forda oluşturduğum arrayın statularıni geziyorum ve false olan varsa butonu disabled yapıyorum
        for (let i = 0; i < this.$store.state.requirementArray.requireInputs.length; i++) {
          const statu = this.$store.state.requirementArray.requireInputs[i].statu;
          if (statu == false) {
            this.inputElement.disabled = true;
            break;
            //bir adet bile statusu false olan varsa butonu disabled yapıp for'u kırıyorum
          } else { 
            //eğer hepsinin statusu true ise butonlar able kalıyor
            this.inputElement.disabled = false; }
        }
      }
    },
    
    requirementStatusCheck(){
      //Updated da çalışır her input onChange olduğunda tetiklenir!

      //requirementArray.requireInputs
      //require=true olanı bu fonksiyon işler
      if (this.inputElement.require === true) {
        //modeli boş olmayanları belirleriz (silme de olabilir onChange için)
        if (
          this.model[this.inputElement.name] !== undefined &&
          this.model[this.inputElement.name] !== null &&
          this.model[this.inputElement.name] !== "" &&
          this.model[this.inputElement.name] !== [] &&
          this.model[this.inputElement.name] !== {} &&
          this.model[this.inputElement.name] !== false && //checkboxlar için ama kaldırılsın mı?
          this.model[this.inputElement.name].length !== 0 // this.model[this.inputElement.name].length > 0 olmuyor

        ) {
          //burada requirementArray.requireInputs arrayimizi döneriz 
          for (let i = 0; i < this.$store.state.requirementArray.requireInputs.length; i++) {
            const element = this.$store.state.requirementArray.requireInputs[i];
            //array'imizdeki element ve input elementin name'ini karşılaştırırız eğer tutuyorsa statusunü true yaparız
            if (element.name == this.inputElement.name) {
              this.$store.state.requirementArray.requireInputs[i].statu = true;
            }
          }
        } else {
          //burada requirementArray.requireInputs arrayimizi döneriz 
          for (let i = 0; i < this.$store.state.requirementArray.requireInputs.length; i++) {
            const element = this.$store.state.requirementArray.requireInputs[i];
            //array'imizdeki element ve input elementin name'ini karşılaştırırız eğer tutuyorsa statusunü false yaparız
            if (element.name == this.inputElement.name) {
              this.$store.state.requirementArray.requireInputs[i].statu = false;
            }
          }
        }

        //buradaki forda statuleri check ediyorum true mu false mu diye
        for (let i = 0; i < this.$store.state.requirementArray.requireInputs.length; i++) {
          const statu = this.$store.state.requirementArray.requireInputs[i].statu;
          //false olan varsa
          if (statu == false) {
            //butonları for dönüyorum 
            for (let i = 0; i < this.dynamicElements.length; i++) {
              const element = this.dynamicElements[i];
              // butonlarda requirementControl içeren varsa disabled yapıyorum ve foru kırıyorum
              if (Object.keys(element).includes('requirementControl')) {
                this.dynamicElements[i].disabled = true;
              }
            }
            break;
          } else {
            //butonları statu=true için for dönüyorum
            for (let i = 0; i < this.dynamicElements.length; i++) {
              const element = this.dynamicElements[i];
              // butonlarda requirementControl içeren varsa disabled=false yapıyorum
              if (Object.keys(element).includes('requirementControl')) {
                this.dynamicElements[i].disabled = false;
              }
            }
          }
        }
       

      }
    }
  }
}
</script>
if (this.inputElement.type == 'v-money') {
        if (this.model[this.inputElement.name] == '') {
          this.model[this.inputElement.name] = 1.0000
        }
        if (typeof this.model[this.inputElement.name] == 'string' &&this.model[this.inputElement.name].includes(",")) {
          this.model[this.inputElement.name] = this.model[this.inputElement.name].replace(",", ".")
          this.model[this.inputElement.name] = parseFloat(this.model[this.inputElement.name])
        }
        this.model[this.inputElement.name] = parseFloat(this.model[this.inputElement.name].toFixed(4));
}