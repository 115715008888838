import axios from "../../../../vue/node_modules/axios";
// import Vue from "vue"
// import VueCookies from "vue-cookies"
import store from "../../../../vue/src/store/index";
import {openSnackbar, hideLoader} from "./elementHelper.js";
import { router } from "../../../../vue/src//router/router";
import Enum from "../../../../vue/src/helper/Enum.json";

// Vue.use(VueCookies)
//axios.defaults.baseURL = "http://ec2-3-120-26-84.eu-central-1.compute.amazonaws.com:3000/";
//axios.defaults.baseURL = "http://localhost:3000/";
axios.defaults.baseURL = "https://api.didero.com.tr/";

axios.interceptors.request.use(
    config => {
        let loggedUser = window.$cookies.get("loggedUser");
        if (loggedUser != null || loggedUser != undefined) {
            if (loggedUser.companyId !== null) {
                config.headers["companyId"] = parseInt(loggedUser.companyId);
                config.headers["userId"] = parseInt(loggedUser.userId);
                config.headers["urlPath"] = window.location.pathname.replace("/","");
            }
            if (loggedUser.token !== null) {
                config.headers["Auth"] = loggedUser.token;
            }
            if (loggedUser.token == null) {
              store.dispatch("logOut");
            }
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    let errorCode = error.response.status;
    store.state.snackbarErrorObj = {
        snackbar: true,
        color: "error",
        text: error.response.data.error[0].detail.message,
        timeout: 15000,
    };
    if (errorCode == 401 || errorCode == 403) {
        window.$cookies.remove("loggedUser");
        router.push("/loginPage");
        location.reload();
        return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
});

export function sendRequest(type, controller, action, obj, then, err, headers) {
    let url = controller;
    let head = "";
    switch (type) {
        case "GET":
            return axios.get(url, { params: obj }).then(function (response) {
                then(response);
            }).catch(function (error) {
                if (err != undefined) {
                    err(error);
                }
                errorFunc(error, "/loginPage");
            }).finally(() => (console.log("")));
        case "POST":
            if (headers != undefined) {
                head = headers
            }
            return axios.post(url, obj, head).then(function (response) {
                then(response);
            }).catch(function (error) {
                if (err != undefined) {
                    err(error);
                }
                errorFunc(error, "/loginPage");
            });
        case "PUT":
            return axios({
                method: "put",
                baseURL: url,
                data: obj
            }).then(function (response) {
                then(response);
            }).catch(function (error) {
                if (err != undefined) {
                    err(error);
                }
                errorFunc(error, "/loginPage");
            });
    }
}
export function uploadHelperWithPromiseAll(files, endPoint, requestType, uploadFlag, ticketId, choiceData, thenFunc, err) {
  let fileNames = [];
  let formData = new FormData();
  let head =  { headers: {"Content-Type": "multipart/form-data"}}
  let xlsFlag = false
  for (let i = 0; i < files.length; i++) {
    formData.append("file", files[i]);
    if (files[i].name.substring(files[i].name.length - 4, files[i].name.length) == '.xls') {
      xlsFlag = true
    }
    fileNames.push(files[i].name)
  }
  head.headers["xlsFlag"] = xlsFlag
  head.headers["uploadFlag"] = uploadFlag
  head.headers["ticketNumber"] = ticketId
  formData.append("filename", JSON.stringify(fileNames))
  formData.append("choiceData", JSON.stringify(choiceData)) 
  sendRequest(requestType, endPoint, "", formData, thenFunc, err, head)
}

export function uploadHelperFormDataQueue(files, endPoint, requestType, choiceData, thenFunc, errFunc) {
    let fileNames = [];
    let formData = new FormData();
    let head =  { headers: {"Content-Type": "multipart/form-data"}}
    let xlsFlag = false
    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
      if (files[i].name.substring(files[i].name.length - 4, files[i].name.length) == '.xls') {
        xlsFlag = true
      }
      fileNames.push(files[i].name)
    }
    //formData.append("choice", Enum[type]["insertType"])
    head.headers["xlsFlag"] = xlsFlag
    formData.append("filename", JSON.stringify(fileNames))
    formData.append("choiceData", JSON.stringify(choiceData)) 
    sendRequest(requestType, endPoint, "", formData, thenFunc, errFunc, head)
}

export async function axiosRequestTypeArrayBuffer(response) {
    await axios({ method: "post", data : {choice : response.data.data.errorExcel},  url: "errorExcelWithZip", responseType: "arraybuffer",}).then((response) => {
      forceFileDownload(response);
    })
    .catch(() => console.log("error occured"));
}

function errorFunc(error, url) {
  // if (error != undefined) openSnackbar('error', error);
  if (error.response != undefined && error.response.status == 403) {
      store.dispatch("logOut");
      if (window.location.href.indexOf(url) < 0)
          window.location = url;
  }
}
export function ticketIdFunction() {
  return rand8() + rand8();
}
export function rand8() {
  return Math.random().toString(16).substr(2,8);
}
export function recursivePromiseAllInsert(files, recursiveIndex, filesLength, promiseLength, ticketId, choiceData, fileRange) {
  if (filesLength < promiseLength) {
    promiseLength = filesLength
  }
  store.state.promiseLength = promiseLength
  if (files.length == recursiveIndex || files.length < recursiveIndex) {
    let lastThenFunc = function (xmlDbInsertResponse) {
      //hideLoader();
      //router.push('folderNotification')
      if (xmlDbInsertResponse.data.data.value.includes("Başarılı")) {
        
        //openSnackbar("success", "Faturanız başarılı bir şekilde kayıt edildi.");
      }else if(xmlDbInsertResponse.data.data.value.includes("Hatalı")){
        openSnackbar("error", "Faturanız kayıt edilirken bir hata oluştu.");
      };
    }
    let errFunc = function (result) {
      console.log(result)
    }
    uploadHelperWithPromiseAll(
    [files[files.length - 1]],
    "allFileUploadForFolderNotification",
    Enum.requestType.POST,
    true,
    ticketId,
    choiceData,
    lastThenFunc,
    errFunc
    );
    openSnackbar("success", "Dosyalarınız başarılı bir şekilde sisteme aktarıldı.", 2000)
    hideLoader();
    router.push('folderNotification');
    return 'Başarılı'
  }
  let receiptPromiseArray = [], newFileArray = [];
  for (let i = recursiveIndex; i < promiseLength; i++) {
    newFileArray.push(files[i])
    receiptPromiseArray.push(new Promise((resolve, reject) => {
      let thenFunc = function (xmlDbInsertResponse) {
        //hideLoader();
        //router.push('folderNotification')
        if (xmlDbInsertResponse.data.data.value.includes("Başarılı")) {
          //router.push('folderNotification');
          //openSnackbar("success", "Faturanız başarılı bir şekilde kayıt edildi.");
        }else if(xmlDbInsertResponse.data.data.value.includes("Hatalı")){
          //openSnackbar("error", "Faturanız kayıt edilirken bir hata oluştu.");
        };
        resolve(xmlDbInsertResponse)
        }
        let errFunc = function (result) {
          reject(result)
        }
        uploadHelperWithPromiseAll(
          [files[i]],
          "allFileUploadForFolderNotification",
          Enum.requestType.POST,
          false,
          ticketId,
          choiceData,
          thenFunc,
          errFunc
        );
    }));
  }
  
  Promise.all(receiptPromiseArray).then((reqResults) => {
    console.log(reqResults);
    recursiveIndex += fileRange;
    promiseLength += fileRange;
    return recursivePromiseAllInsert(files, recursiveIndex, filesLength, promiseLength, ticketId, choiceData, fileRange)
  })
}

export function  forceFileDownload(response) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Hatalı Excel.zip"); 
    document.body.appendChild(link);
    link.click();
}

export default axios;