<template>
    <v-app>
        <div class="header-div">
          <h3 class="header-h3">Muavin E-Fatura Karşılaştırma</h3>
        </div>
        <table-component :dynamic-element="tableElements" :model="model" />
        <Loader></Loader>
        <snackbar-component />
    </v-app>
</template>
<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";
import { router } from '../router/router';
import {
  openSnackbar,
  showDialog,
  closeDialog,
  openTablePage,
  showLoader, 
  hideLoader 
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import {axiosUploadTypeArrayBuffer} from "../../helper/uploadHelperForData"
export default {
    components: {
        "table-component": tableComponent,
        "upload-component": uploadComponent,
        Loader: Loader,
        "snackbar-component": snackbarComponent,
    },

    data() {
        return {
          tableElements: {},
          model: {},
          renderKey: 0,
        };
    },
    created() {
      this.loadData();
      openSnackbar("orange", "E-Defter ile çalışır", 3000)
    },
    watch: {},
    methods: {
    loadData() {
      this.tableElements = {
        sortBy: [],
        sortDesc: false,
        renderKey: 0,
        type: "table",
        tableHeight : 530,
        name: "mizanTable",
        class: "",
        infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
        excelDownloadBtnText: "İndir",
        deleteDialogVisible : true,
        hidePagination: true,
        //selectedRows: [],
        filteredColumn: {},
        filteredColumns: true,
        //selectionUrl: "getMizanView",
        // showDefaultDeleteButon : true,
        // deleteUrl: "deleteTable",
        // saveUrl : "updateTable",
        // updateUrl : "updateTable",
        // deleteBtnText: "Sil",
        // saveBtnText : 'Yeni Kayıt',
        // saveBtnSettings : {
        //   color: "blue",
        //   style:"height: 25px !important; ",
        // },
        // deleteBtnSettings : {
        //   color: "error",
        //   style:"height: 25px !important; ",
        // },
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
            
              [
               {
                  id : "startDate",
                  type : "v-date-picker",
                  variant : "text",
                  name : "startDate",
                  readonly: true,
                  label : "Başlangıç Tarihi",
                  value : "",
                  prependIcon : "fas fa-at",
                  placeHolder : "",
                  style : "width: 260px;",
                  classTag : "",
                  onClick : function(thisData,model,dynamicElements) {
                  }
                },
              ],
              [
               {
                  id : "endDate",
                  type : "v-date-picker",
                  readonly: true,
                  variant : "text",
                  name : "endDate",
                  label : "Bitiş Tarihi",
                  value : "",
                  prependIcon : "fas fa-at",
                  placeHolder : "",
                  style : "width: 260px;",
                  classTag : "",
                  onClick : function(thisData,model,dynamicElements) {
                  }
                },
              ],
              [
                {
                  id: "excelDownload",
                  type: "v-btn",
                  variant: "btn",
                  name: "excelDownload",
                  inputText: "excelDownloadBtnText",
                  style:"height: 25px !important; margin-top: 23%",
                  divStyle: "",
                  icon: "mdi-file-excel",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    
                    if (model.startDate === "" && model.endDate === "") {
                          openSnackbar("error","Girilen Tarih Değerleri Boş Olamaz");
                          return false;
                      }
                    if (((model.startDate != undefined) && (model.endDate != undefined))) {
                      let startDateNew = model.startDate.split("/")
                      startDateNew = new Date(startDateNew[2] + "-" + startDateNew[1] + "-" +startDateNew[0])
                      let endDateNew = model.endDate.split("/")
                      endDateNew = new Date(endDateNew[2] + "-" + endDateNew[1] + "-" +endDateNew[0])
                      const diffTime = Math.abs(endDateNew - startDateNew);
                      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
                      if (diffDays > 370) {
                        openSnackbar("error","Seçilen Tarihler Arası Bir Yıldan Büyük Olamaz", 2000);
                        return false;
                      }
                      if (startDateNew > endDateNew) {
                        openSnackbar("error","Başlangıç Tarihi, Bitiş Tarihinden Büyük Olamaz",2000);
                        return false;
                      }
                    }
                    showLoader('Dosyanız Hazırlanıyor')
                    let that = this;
                    sendRequest(Enum.requestType.POST,"muavinAndEbillComparisonQueueProcess","",
                      {
                        startDate: model.startDate, endDate: model.endDate,
                        excelDownload : true,
                        choice : "muavinAndEbillComparisonTable",
                        cookieData: window.$cookies.get("selectedItem")
                      },
                      function (response) {
                        hideLoader()
                        openSnackbar("success", response.data.data.value);
                        router.push("folderNotification")
                      },
                      function (error) {
                        openSnackbar(
                          "error",
                          error.response.data.error[0].detail.message
                        );
                        hideLoader();
                      }
                    )
                  },
                }
              ],
            ],
          }
        ],
      };
    }
  },
}

    

</script>