import Enum from "./Enum.json";
export function freeSpaceCheckFunc(lineItem,pageName){
    if (pageName == "abroadInvoice") {
        if(lineItem.accountPlanBaseCode == "" || lineItem.accountPlanBaseCode == undefined || lineItem.tinNumber == ""||lineItem.tinNumber == undefined || lineItem.billDate == ""||lineItem.billDate ==undefined ||lineItem.invoiceType == "" || lineItem.invoiceType == undefined || lineItem.totalPrice == "" || lineItem.totalPrice ==undefined || lineItem.billNo == "" || lineItem.billNo == undefined || lineItem.invoiceId == undefined ||lineItem.invoiceId == ""){
            return false;
        }else{
            return true;
        }
    }
    if (lineItem.accountPlanTotalCode == "" || lineItem.accountPlanTotalCode == undefined || (lineItem.vatRate != 0 && (pageName == 'eBills' && lineItem.eInvoiceType == Enum.einvoiceTypes.ISTISNA) && (lineItem.accountPlanVatCode == "" || lineItem.accountPlanVatCode == undefined))) {
        return false;
    }
    if (pageName == "receipts") {
        if(lineItem.billDate == "" ||lineItem.tinNumber == "" || lineItem.billNo==""||lineItem.rowTotal=="" ||lineItem.billDate  == undefined || lineItem.tinNumber ==undefined || lineItem.billNo==undefined||lineItem.rowTotal==undefined ){
            return false;
        }
    }
    if (pageName == "editableEbills") {
        if(lineItem.billDate  == "" || lineItem.tinNumber == "" || lineItem.billNo==""||lineItem.totalPrice=="" || lineItem.billDate  == undefined || lineItem.tinNumber ==undefined || lineItem.billNo==undefined||lineItem.totalPrice==undefined ){
            return false;
        }
    }
    return true;
}