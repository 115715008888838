<template>
    <v-app>
        <div class="header-div">
          <h3 class="header-h3">Mizan</h3>
        </div>
        <table-component :dynamic-element="tableElements" :model="model"/>
        <Loader></Loader>
        <snackbar-component />
    </v-app>
</template>
<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";

import {
  openSnackbar,
  showDialog,
  closeDialog,
  openTablePage,
  showLoader, 
  hideLoader 
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import {axiosUploadTypeArrayBuffer} from "../../helper/uploadHelperForData"
export default {
    components: {
        "table-component": tableComponent,
        "upload-component": uploadComponent,
        Loader: Loader,
        "snackbar-component": snackbarComponent,
    },

    data() {
        return {
          tableElements: {},
          model: {},
          renderKey: 0,
        };
    },
    created() {
        this.loadData();
    },
    watch: {},
    methods: {
    loadData() {
      this.tableElements = {
        sortBy: [],
        sortDesc: false,
        renderKey: 0,
        type: "table",
        tableHeight : 430,
        name: "mizanTable",
        class: "",
        infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
        excelDownloadBtnText: "İndir",
        deleteDialogVisible : true,
        hidePagination: true,
        //selectedRows: [],
        filteredColumn: {},
        filteredColumns: true,
        //selectionUrl: "getMizanView",
        // showDefaultDeleteButon : true,
        // deleteUrl: "deleteTable",
        // saveUrl : "updateTable",
        // updateUrl : "updateTable",
        // deleteBtnText: "Sil",
        // saveBtnText : 'Yeni Kayıt',
        // saveBtnSettings : {
        //   color: "blue",
        //   style:"height: 25px !important; ",
        // },
        // deleteBtnSettings : {
        //   color: "error",
        //   style:"height: 25px !important; ",
        // },
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
            
              [
               {
                  id : "startDate",
                  type : "v-date-picker",
                  variant : "text",
                  name : "startDate",
                  label : "Başlangıç Tarihi",
                  value : "",
                  prependIcon : "fas fa-at",
                  placeHolder : "",
                  style : "width: 260px;",
                  classTag : "",
                  readonly: true,
                  onClick : function(thisData,model,dynamicElements) {
                  }
                },
              ],
              [
               {
                  id : "endDate",
                  type : "v-date-picker",
                  variant : "text",
                  name : "endDate",
                  label : "Bitiş Tarihi",
                  value : "",
                  prependIcon : "fas fa-at",
                  placeHolder : "",
                  style : "width: 260px;",
                  classTag : "",
                  readonly: true,
                  onClick : function(thisData,model,dynamicElements) {
                  }
                },
              ],
              [
                {
                  id : "get",
                  type : "v-btn",
                  variant : "btn",
                  name : "get",
                  inputText : "Getir",
                  divStyle:"",
                  style:"height: 25px !important; margin-top: 25%",
                  btnTypeText: false,
                  color : "primary",
                  prependIcon : "",
                  classTag : "",
                  onClick : function(thisData, model, dynamicElements) {
                    if (model.startDate === "" && model.endDate === "") {
                          openSnackbar("error","Girilen Tarih Değerleri Boş Olamaz");
                          return false;
                        }
                    if (((model.startDate != undefined) && (model.endDate != undefined))) {
                        let startDateNew = model.startDate.split("/")
                        startDateNew = new Date(startDateNew[2] + "-" + startDateNew[1] + "-" +startDateNew[0])
                        let endDateNew = model.endDate.split("/")
                        endDateNew = new Date(endDateNew[2] + "-" + endDateNew[1] + "-" +endDateNew[0])
                        const diffTime = Math.abs(endDateNew - startDateNew);
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

                        if (diffDays > 370) {
                          openSnackbar("error","Seçilen Tarihler Arası Bir Yıldan Büyük Olamaz", 2000);
                          return false;
                        }
                        if (startDateNew > endDateNew) {
                          openSnackbar("error","Başlangıç Tarihi, Bitiş Tarihinden Büyük Olamaz",2000);
                          return false;
                        }
                      }
                    showLoader('Hesaplamalar Yapılıyor')
                    sendRequest(Enum.requestType.POST, "getMizanTable", "", {startDate: model.startDate, endDate: model.endDate, cookieData: window.$cookies.get("selectedItem")}, 
                    function (response) {
                      dynamicElements.data = response.data.data.data;
                      model.itemCount = dynamicElements.data.length;
                      model.pagesCount = dynamicElements.data.length / 50;
                      dynamicElements.forceRender();
                      hideLoader();
                    },
                      function (error) {errFunc(error);}
                    )
                  }
                }
              ],
              [
                {
                    id: "excelDownload",
                    type: "v-btn",
                    variant: "btn",
                    name: "excelDownload",
                    inputText: "excelDownloadBtnText",
                    style:"height: 25px !important; margin-top: 18%",
                    divStyle: "",
                    icon: "mdi-file-excel",
                    btnTypeText: false,
                    color: "success",
                    prependIcon: "",
                    classTag: "",
                    onClick: function(thisData, model, dynamicElements) {
                      if (model.startDate === "" && model.endDate === "") {
                            openSnackbar("error","Girilen Tarih Değerleri Boş Olamaz");
                            return false;
                        }
                      if (((model.startDate != undefined) && (model.endDate != undefined))) {
                        let startDateNew = model.startDate.split("/")
                        startDateNew = new Date(startDateNew[2] + "-" + startDateNew[1] + "-" +startDateNew[0])
                        let endDateNew = model.endDate.split("/")
                        endDateNew = new Date(endDateNew[2] + "-" + endDateNew[1] + "-" +endDateNew[0])
                        const diffTime = Math.abs(endDateNew - startDateNew);
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
                        if (diffDays > 370) {
                          openSnackbar("error","Seçilen Tarihler Arası Bir Yıldan Büyük Olamaz", 2000);
                          return false;
                        }
                        if (startDateNew > endDateNew) {
                          openSnackbar("error","Başlangıç Tarihi, Bitiş Tarihinden Büyük Olamaz",2000);
                          return false;
                        }
                      }
                      showLoader('Dosyanız Hazırlanıyor')
                      sendRequest(Enum.requestType.POST,"getMizanTable","",
                        {
                          startDate: model.startDate, endDate: model.endDate,
                          excelDownload : true,
                          cookieData: window.$cookies.get("selectedItem"),
                          choice : "mizanViewTable"
                        },
                        function (response) {
                          if (response.data.data.data != true) {
                            openSnackbar('error', "İndirilecek Veri Bulunamadı", 3000)
                            hideLoader();
                            return false
                          }
                          let zipNameStartDate = model.startDate.replaceAll("/", "_")
                          let zipNameEndDate = model.endDate.replaceAll("/", "_")
                          let zipName = "Mizan Rapor" + zipNameStartDate + "ve" + zipNameEndDate
                          axiosUploadTypeArrayBuffer("allExcelZipDownload", "mizanViewTable", zipName, "Excel İndirildi")
                        },
                        function (error) {
                          openSnackbar(
                            "error",
                            error.response.data.error[0].detail.message
                          );
                          hideLoader();
                        }
                      )
                  },
                }
              ],
              [
                {
                    id: "dateExcelDownload",
                    type: "v-btn",
                    variant: "btn",
                    name: "dateExcelDownload",
                    inputText: "Aylık Excel İndir",
                    style:"height: 25px !important; margin-top: 8%",
                    divStyle: "",
                    icon: "mdi-file-excel",
                    btnTypeText: false,
                    color: "success",
                    prependIcon: "",
                    classTag: "",
                    onClick: function(thisData, model, dynamicElements) {
                      if (model.startDate === "" && model.endDate === "") {
                            openSnackbar("error","Girilen Tarih Değerleri Boş Olamaz");
                            return false;
                        }
                      if (((model.startDate != undefined) && (model.endDate != undefined))) {
                        let startDateNew = model.startDate.split("/")
                        startDateNew = new Date(startDateNew[2] + "-" + startDateNew[1] + "-" +startDateNew[0])
                        let endDateNew = model.endDate.split("/")
                        endDateNew = new Date(endDateNew[2] + "-" + endDateNew[1] + "-" +endDateNew[0])
                        const diffTime = Math.abs(endDateNew - startDateNew);
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
                        if (diffDays > 370) {
                          openSnackbar("error","Seçilen Tarihler Arası Bir Yıldan Büyük Olamaz", 2000);
                          return false;
                        }
                        if (startDateNew > endDateNew) {
                          openSnackbar("error","Başlangıç Tarihi, Bitiş Tarihinden Büyük Olamaz",2000);
                          return false;
                        }
                      }
                      showLoader('Dosyanız Hazırlanıyor')
                      sendRequest(Enum.requestType.POST,"postMizanDates","",
                        {
                          startDate: model.startDate, endDate: model.endDate,
                          excelDownload : true,
                          cookieData: window.$cookies.get("selectedItem"),
                          choice : "mizanViewTable"
                        },
                        function (response) {
                          if (response.data.data.data != true) {
                            openSnackbar('error', "İndirilecek Veri Bulunamadı", 3000)
                            hideLoader();
                            return false
                          }
                          let zipNameStartDate = model.startDate.replaceAll("/", "_")
                          let zipNameEndDate = model.endDate.replaceAll("/", "_")
                          let zipName = "Mizan Aylık Rapor Excel" + zipNameStartDate + "ve" + zipNameEndDate
                          axiosUploadTypeArrayBuffer("allExcelZipDownload", "mizanViewTable", zipName, "Excel İndirildi")
                        },
                        function (error) {
                          openSnackbar(
                            "error",
                            error.response.data.error[0].detail.message
                          );
                          hideLoader();
                        }
                      )
                  },
                }
              ],
            ],
          }
        ],
        headers: [
          { text: "Hesap Kodu", value: "accountCode", width: "100", divider: true, },
          { text: "Hesap Adı", value: "accountName", width: "100", divider: true, },
          { text: "Borç", value: "debt", width: "100", dataType: "currency", divider: true, align: "right"},
          { text: "Alacak", value: "credit", width: "100", dataType: "currency", divider: true, align: "right"},
          { text: "Bakiye", value: "balance", width: "100", dataType: "currency", divider: true, align: "right"}

        ],
      };
    }
  },
}

    

</script>