<template>
  <v-app>
    <table-component :dynamic-element="inflationAccountElements" :model="model" />
    <Loader></Loader>
    <snackbar-component />
  </v-app>
</template>

<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import {
  openSnackbar,
  openTablePage,
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import { sendRequest} from "../../../frontend/components/atoms/helper/requestHelper";
import {axiosUploadTypeArrayBuffer, dateCalculation} from "../../helper/uploadHelperForData"

export default {
  components: {
    "table-component": tableComponent,
    "upload-component": uploadComponent,
    Loader: Loader,
    "snackbar-component": snackbarComponent,
  },

  data() {
    return {
      inflationAccountElements: {},
      model: {},
      renderKey: 0,
      required : (value) => !!value || "Gerekli."
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      let that = this;
      this.inflationAccountElements = {
        clientEvents : {
          onBeforeSave :function (selectedItem,creatableObjects,model,tableObject) {
            // if (model.explain.toLowerCase() == 'beyanname') {
            //   openSnackbar('error', 'Beyanname İsimli Proje Açılamaz', 3000)
            //   return false
            // }
            if (((creatableObjects.auiditDate != undefined) && (creatableObjects.preriorDate != undefined) && (creatableObjects.explain != undefined))) {
              let conditionAuiditDate = creatableObjects.auiditDate.split("/")
              conditionAuiditDate = new Date(conditionAuiditDate[2] + "-" + conditionAuiditDate[1] + "-" +conditionAuiditDate[0])
              let conditionPreriorDate = creatableObjects.preriorDate.split("/")
              conditionPreriorDate = new Date(conditionPreriorDate[2] + "-" + conditionPreriorDate[1] + "-" +conditionPreriorDate[0])
              const diffTime = Math.abs(conditionAuiditDate - conditionPreriorDate);
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
              // if (diffDays > 370) {
              //   openSnackbar("error","Seçilen Tarihler Arası Bir Yıldan Büyük Olamaz");
              //   return false;
              // }
              if (conditionPreriorDate > conditionAuiditDate) {
                openSnackbar("error","Önceki Dönem Tarihi, Denetim Tarihinden Büyük Olamaz");
                return false;
              }
              else{
                creatableObjects.auiditDate = conditionAuiditDate
                creatableObjects.preriorDate = conditionPreriorDate
                let splitConditionAuditDate = conditionAuiditDate.toLocaleString().split(" ")[0].split(".")
                creatableObjects.visibleProp = splitConditionAuditDate[2] + "-" + splitConditionAuditDate[1] + "-" +splitConditionAuditDate[0] + "-" + creatableObjects.explain
                that.$store.state.projectInformation.items.push(creatableObjects)
              }
            } else {
              openSnackbar("error","Proje Oluşturmak İçin Boş Alan Bırakılamaz");
              return false;
            }
          },
          onAfterSave: function (selectedItem,creatableObjects,model,tableObject,res) {
            that.$router.go()
          },
          onafterUpload(dynamicElements){
            
          },
          onSaveError: function (error,selectedItem,creatableObjects,model,tableObject) {
            console.log(error);
          },
          onBeforeDelete :function (selectedItem,model,tableObject,res) {
            if (selectedItem[0].explain.toLowerCase() == "beyanname") {
              openSnackbar("error", "Beyanname İsimli Proje Silinemez", 2000)
              return false
            }
            console.log(that.$store.state)
            let projectItem = that.$store.state.projectInformation.items
            if (selectedItem.length > 0) {
              for (let i = 0; i < projectItem.length; i++) {
                if(projectItem[i].visibleProp == selectedItem[0].auiditDate + "-" + selectedItem[0].explain){
                  that.$store.state.projectInformation.items.splice(i,1);
                } 
              }
            }else{
              that.$store.state.projectInformation.items = [];
            }
            
          } 
        },
        sortBy: [],
        sortDesc: false,
        renderKey: 0,
        type: "table",
        tableHeight : 550,
        name: "crudProject",
        class: "",
        infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
        deleteBtnText: "Sil",
        excelDownloadBtnText: "İndir",
        saveBtnText : 'Yeni Kayıt',
        saveBtnSettings : {
          color: "blue",
          style:"height: 25px !important; ",
        },
        deleteBtnSettings : {
          color: "error",
          style:"height: 25px !important; ",
        },
        crudRequestObj : {
          placeOfAction : "createProject",
          companyId: window.$cookies.get("loggedUser").companyId,
        },
        deleteDialogVisible : false,
        hidePagination: true,
        selectedRows: [],
        filteredColumn: {},
        filteredColumns: true,
        selectionUrl: "gettabledata",
        //deleteUrl: "deletefortable",
        deleteUrl: "deleteTable",
        //saveUrl : "insertSelectedItem",
        saveUrl : "updateTable",
        showDefaultUpdateButon : true,
        showDefaultDeleteButon : true,
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
              // [
              //   {
              //     id: "excelDownload",
              //     type: "v-btn",
              //     variant: "btn",
              //     name: "excelDownload",
              //     inputText: "excelDownloadBtnText",
              //     style: "height: 25px !important;",
              //     divStyle: "",
              //     icon: "mdi-file-excel",
              //     btnTypeText: false,
              //     color: "success",
              //     prependIcon: "",
              //     classTag: "",
              //     onClick: function(thisData, model, dynamicElements) {
              //       sendRequest(Enum.requestType.POST,"tufeTable",null,
              //             {
              //                 filter : {
              //                   tableName : "tufe", orderBy : "orderBy"
              //                 },
              //                 excelDownload : true
              //             },
              //             function (response) {
              //               //let periodName =  this.vatDirectSelection.replace("/", "_")
              //               let zipName = "Tüfe Güncel Tablo Listesi" + " " /*+ periodName*/
              //               axiosUploadTypeArrayBuffer("allExcelZipDownload","tufe", zipName, "Excel İndirildi")
              //             },
              //             function (error) {
              //               openSnackbar(
              //                 "error",
              //                 error.response.data.error[0].detail.message
              //               );
              //             }
              //           )
              //     },
              //   },
              // ],
              // [
              //   {
              //     id: "compare",
              //     type: "v-btn",
              //     variant: "btn",
              //     name: "compare",
              //     inputText: "Karşılaştır",
              //     style: "height: 25px !important;",
              //     divStyle: "",
              //     icon: "mdi-file-excel",
              //     btnTypeText: false,
              //     color: "primary",
              //     prependIcon: "",
              //     classTag: "",
              //     onClick: function(thisData, model, dynamicElements) {
              //       sendRequest(Enum.requestType.POST,"tufeTable",null,
              //             {
              //                 filter : {
              //                   tableName : "tufe", orderBy : "orderBy"
              //                 },
              //                 excelDownload : true
              //             },
              //             function (response) {
              //               //let periodName =  this.vatDirectSelection.replace("/", "_")
              //               let zipName = "Tüfe Güncel Tablo Listesi" + " " /*+ periodName*/
              //               axiosUploadTypeArrayBuffer("allExcelZipDownload","tufe", zipName, "Excel İndirildi")
              //             },
              //             function (error) {
              //               openSnackbar(
              //                 "error",
              //                 error.response.data.error[0].detail.message
              //               );
              //             }
              //           )
              //     },
              //   },
              // ],
            ],
          }
        ],
        headers: [
          { text: "Denetim Başlangıç Tarihi", value: "preriorDate",search: true , sortable: true, dataType: "date"},
          { text: "Denetim Tarihi", value: "auiditDate",  search: true , sortable: true, dataType: "date"},
          { text: "Açıklama", value: "explain", search: true , sortable: true},
          { text: "Proje Durumu", value: "status", search: true, sortable: true, divider: true, colorRow:true, switchValue: {0 : "Bitti", 1 : "Devam Ediyor"}}
        ],
        columns: {
          preriorDate: {
            type: "v-date-picker",
            editable: true,
            creatable: true,
            inlineEditable: false,
            style:"width: 250px;",
            label : "Denetim Başlangıç Tarihi",
            readonly : true,
            rules : [this.required],
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              let modelAuditDateSplit = model.auiditDate.split("-")
              modelAuditDateSplit = modelAuditDateSplit[0] + "-" + modelAuditDateSplit[1] + "-" + modelAuditDateSplit[1]
              let auditDateIf = window.$cookies.get("selectedItem").auiditDate 
              let dateCalculate = dateCalculation(model.preriorDate, auditDateIf)
              if(dateCalculate != false){ 
                editableObjects["prerior_date"] = new Date(dateCalculate)
                if(new Date(modelAuditDateSplit) > dateCalculate){
                  sendRequest("POST","updateForAllTable","",{selectedItem : [model], editableObjects ,tableName : "crudProject", tableKey : "prerior_Date", forKey : "id", sequl : "id"},
                  function(response){
                    openTablePage("crudProject")
                    openSnackbar("success", response.data.data.value)
                  }, 
                  function(error){
                    if (error.response != undefined){
                      openSnackbar("error", error.response.data.error[0].detail.message)
                    } else (
                      openSnackbar("error", "Sunucu Hatası")
                    )
                  })
                }else{
                  openSnackbar("error", "Tutarlı Bir Tarih Giriniz")
                }
              }
              else{
                openSnackbar("error","Yanlış Değer Girdiniz");
              }
            }
          },
          auiditDate: {
            type: "v-date-picker",
            creatable: true,
            inlineEditable: false,
            label : "Denetim Tarihi",
            readonly : true,
            style:"width: 250px;",
            rules : [this.required],
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              let auditDateIf = window.$cookies.get("selectedItem").auiditDate 
              let dateCalculate = dateCalculation(model.auiditDate, auditDateIf)
              if(dateCalculate != false){
                editableObjects["auidit_date"] = dateCalculate
                if(dateCalculate > new Date(model.preriorDate)){
                  sendRequest("POST","updateForAllTable","",{selectedItem : [model], editableObjects ,tableName : "crudProject", tableKey : "auidit_date", forKey : "id", sequl : "id"},
                  function(response){
                    openTablePage("crudProject")
                    openSnackbar("success", response.data.data.value)
                  }, 
                  function(error){
                    if (error.response != undefined){
                      openSnackbar("error", error.response.data.error[0].detail.message)
                    } else (
                      openSnackbar("error", "Sunucu Hatası")
                    )
                  })
                }
                else{
                  openSnackbar("error", "Önceki Dönem Tarihi, Denetim Tarihinden Büyük Olamaz")
                }
              }
              else{
                openSnackbar("error","Yanlış Değer Girdiniz");
              }
            }
          },
          explain: {
            type: "v-text-field",
            editable: true,
            creatable: true,
            inlineEditable: true,
            label : "Açıklama",
            rules : [this.required],
            onSave: function (thisData, model, dynamicElements) {
              let editableObjects = {}
              editableObjects[thisData.id] = model[thisData.id]
              sendRequest("POST","updateForAllTable","",{selectedItem : [model], editableObjects ,tableName : "crudProject", tableKey : "explain", forKey : "id", sequl : "id"},
              function(response){
                console.log(thisData, model, dynamicElements)
                for (let i = 0; i < that.$store.state.projectInformation.items.length; i++) {
                  if(that.$store.state.projectInformation.items[i].id == model.id){
                    that.$store.state.projectInformation.items[i].explain = model.explain
                    that.$store.state.projectInformation.items[i].visibleProp = that.$store.state.projectInformation.items[i].auiditDate + "-" + that.$store.state.projectInformation.items[i].explain
                    if( window.$cookies.set("selectedItem") != null){
                      window.$cookies.set("selectedItem", that.$store.state.projectInformation.items[i])
                    }
                  }
                }
                that.$store.dispatch("setProjectInformation", {items :that.$store.state.projectInformation.items });
                openTablePage("crudProject")
                //openSnackbar("success", response.data.data.value)
                if(response.data.success){
                  openSnackbar("success", 'Veriniz Güncellendi');
                }
              }, 
              function(error){
                if (error.response != undefined){
                  openSnackbar("error", error.response.data.error[0].detail.message)
                } else (
                  openSnackbar("error", "Sunucu Hatası")
                )
              })
            }
          },
          status: {
            type: "v-select",
            variant: "number",
            editable: true,
            creatable: true,
            inlineEditable: true,
            //value: ['Aktif'],
            label: "Proje Durumu",
            dropDownItems: ["Devam Ediyor", "Bitti"],
            rules: [this.required],
            onSave: function(thisData, model, dynamicElements) {
              let editableObjects = {};
              let modelStatus = {
                "Devam Ediyor" : 1,
                "Bitti" : 0
              }
              editableObjects["status"] = modelStatus[model[thisData.id]];
              sendRequest("POST","updateForAllTable","",{selectedItem : [model], editableObjects ,tableName : "crudProject", tableKey : "status", forKey : "id", sequl : "id"},
                function(response) {
                  openTablePage("crudProject")
                  if(response.data.success){
                    openSnackbar("success", 'Veriniz Güncellendi');
                  }
                },
                function(error) {
                  if (error.response != undefined) {
                    openSnackbar("error",error.response.data.error[0].detail.message);
                  } else openSnackbar("error", "Sunucu Hatası");
                }
              );
            },
          },
        },
        data: "data",
        showSelecetVisible: true,
        singleSelect: false,
        itemKey: "id",
        footer: [
          {
            type: Enum.layoutType.div,
            name: "footerForm",
            inputElements: [
              [
                {
                  id: "pageSize",
                  type: "v-text-field",
                  variant: "number",
                  name: "pageSize",
                  label: "Görünecek Satır Sayısı",
                  value: "",
                  placeHolder: "",
                  style: "width: 45%; margin-left: 5%",
                  classTag: "",
                  onChange: function(thisData, model, dynamicElements) {
                    openTablePage("crudProject")
                  },
                },
              ],
              [
                {
                  id: "currentPageNo",
                  type: "v-pagination",
                  variant: "pagination",
                  name: "currentPageNo",
                  divStyle: "",
                  style: "width:850px;",
                  classTag: "my-1",
                  length: "pagesCount",
                  onInput: function(thisData, model, dynamicElements) {
                    openTablePage("crudProject")
                  },
                },
              ],
              [
                {
                  id: "infoTotalRowCountHeaderAssistantTable",
                  type: "p",
                  variant: "p",
                  name: "infoTotalRowCountHeaderAssistantTable",
                  inputText: "infoTotalRowCountHeaderAssistantTable",
                  divStyle: "",
                  style: "font-size: 12px;font-weight:500;margin:3px 0;color:#666A6F;margin-right: 2%;margin-left: 60%;",
                  classTag: ""
                },
                {
                  id: "infoTotalRowCountAssistantTable",
                  type: "p",
                  variant: "p",
                  name: "infoTotalRowCountAssistantTable",
                  inputText: "itemCount",
                  divStyle: "",
                  style: "font-size: 15px;font-weight:500;margin:3px 0;color:#666A6F;margin-left: 82%;",
                  classTag: ""
                },
              ],
            ],
          },
        ]
      };
    }
  },
};
</script>

<style scoped>
::v-deep .threedot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 300px;
}
::v-deep .threedot:hover {
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}
</style>
