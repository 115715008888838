<template>
    <layout  :key="renderKey" :dynamic-elements="dynamicElement" :layout-elements="dynamicElement.footer" :model="model"/>
</template>
<script>
import layout from "../layout.vue";
import {hashCode} from "../../../atoms/helper/elementHelper.js"

export default {
    components: { 'layout' : layout  },

    props: {
        dynamicElement : {},
        model : {}
    },

    data() {
      return {
        renderKey: hashCode(),
      };
    },

    methods: {
      
    },
    
    computed: {

    },

    watch: {

    },

    created(){

    }
};
</script>
