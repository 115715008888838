<template>
  <div>
    <table-component :dynamic-element="tableElements" :model="model" :key="tableRenderKey" class="eBillsClassForThreeDot"/>
    <Loader></Loader>
    <snackbar-component />
    <v-dialog v-model="detailDialogModel" persistent fullscreen>
      <v-card class="detailDialogCard">
        <component-factory :componentElements="componentElementsLeft" :model="model1" :key="leftRenderKey" />
        <component-factory :componentElements="componentElementsRight" :model="model1" :key="rightRenderKey" />
      </v-card>
    </v-dialog>
    <v-dialog v-model="selectPeriodForCompareDialog" width="45%" persistent>
      <v-card height="200px">
        <v-card-title>
          <span class="headline">İşlem Yapmak İstediğiniz Dönemi Seçiniz</span>
        </v-card-title>
        <v-form v-model="selectedPeriodForCompareValid">
          <v-row no-gutters>
          <v-col cols="2">
            <v-select
              style="margin-left:4%"
              v-model="selectedPeriodCompare"
              :items="periodItemsForCompare"
              label="Dönem Seçiniz"
            >
            </v-select>
          </v-col>
          <v-col cols="4">
            <v-combobox
            style="margin-left:20%"
                v-model="selectInvoiceTypes"
                :items="itemsInvoiceType"
                label="Fatura Türü"
                multiple
              ></v-combobox>
          </v-col>  
          <v-col cols="4">
            <v-combobox
            style="margin-left:20%"
                v-model="selectInvoiceKind"
                :items="itemsInvoiceKinds"
                label="Fatura Tipi"
                multiple
              ></v-combobox>
          </v-col>
          </v-row>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="selectPeriodForCompareDialog = false">Kapat</v-btn>
            <v-btn color="blue darken-1" text @click="periodsCompareFunc()">Karşılaştır</v-btn>
          </v-card-actions>   
        </v-form>
          </v-card>
    </v-dialog>
     <v-dialog v-model="withholdingCodeSelectDialog" width="95%" persistent>
      <v-card height="600px">
        <v-card-title>
          <span class="headline">Eklemek İstediğiniz Tevkifat Kodlarını Seçiniz.</span>
        </v-card-title>
        <v-form v-model="withholdingCodeSelectValid">
          <v-row no-gutters>
          <v-col cols="9">
            <v-combobox
                style="margin-left:28%"
                v-model="selectWithholdingCodes"
                :items="withholdingCodesItems"
                label="Tevkifat Kodları"
                multiple
                solo
                @change="selectedWithholdingCodeFunc()"
            ></v-combobox>
          </v-col>
          <v-spacer></v-spacer>
          <div :key="withholdingInputRenderKey">
            <div
              :class="`dataTitle`"
              v-for="(data, index) in selectWithholdingCodes.length"
              :key="index"
              :style="`${data.headerStyle}`"
            >
              <div>
                <div class="v-input v-input--hide-details v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted" style="width: 260px;">
                  <div class="v-input__control">
                    <div class="v-input__slot">
                      <div class="v-text-field__slot">
                        <label  class="v-label v-label--active theme--light" style="left: 0px; right: auto; position: absolute;">{{selectWithholdingCodes[index].split("-")[0]}}</label>
                        <input v-model.lazy="withholdingInputModel[index]"  v-money="money2" /> 
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div>
            
          </v-row>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="withholdingCodeSelectDialog = false">Kapat</v-btn>
            <v-btn color="blue darken-1" text @click="addWithholdingCodes()">Ekle</v-btn>
          </v-card-actions>   
        
          </v-card>
    </v-dialog>
    <loader></loader>
    <snackbar-component />
    <ticket-dialog :model="model1" :ticketElements="ticketElements" />
  </div>
</template>
<script>
import { sendRequest } from "../../../frontend/components/atoms/helper/requestHelper.js";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../helper/Enum.json";
import DideroEnum from "../../../frontend/components/Enum.json"
import Loader from "../../../frontend/components/atoms/loader";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import config from "../../config.json";
import {
  closeDialog,
  showDialog,
  openSnackbar,
  openTablePage,
  showLoader,
  hideLoader,
  hashCode,
  currencyFormat
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import ticketDialog from "../molecules/newTicketDialog.vue";
import componentFactory from "./newComponentFactory.vue";
import eBillsHeaders from "./newEBillsStaticHeaders.json";
import { getPlans } from "../helper/newHelper.js";
import { freeSpaceCheckFunc } from "../helper/freeSpaceCheckFunc";
import { infoTableDialogFunc } from "../helper/updateHelpers.js"
import moment from 'moment';
import {VMoney} from 'v-money' //npm i v-money


export default {
  directives: {
    money: VMoney
  },
  components: {
    Loader: Loader,
    "table-component": tableComponent,
    "snackbar-component": snackbarComponent,
    "loader": Loader,
    "component-factory": componentFactory,
    "ticket-dialog": ticketDialog,
  },
  data() {
    return {
      orijinalSelectedWithholdingArray : [],
      withholdingInputModel : {},
      money2 : {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
      }, 
      withholdingInputRenderKey : 0,
      withholdingCodeSelectDialog : false,
      withholdingCodeSelectValid : false,
      selectWithholdingCodes : [],
      withHoldingCodeEnum : {
        "601":	0,
        "602":	0,
        "603":	0,
        "604":	0,
        "605":	0,
        "606":	0,
        "607":	0,
        "608":	0,
        "609":	0,
        "610":	0,
        "611":	0,
        "612":	0,
        "613":	0,
        "614":	0,
        "615":	0,
        "616":	0,
        "617":	0,
        "618":	0,
        "619":	0,
        "620":	0,
        "621":	0,
        "622":	0,
        "623":	0,
        "624":	0,
        "625":	0,
        "626":	0,
        "627":	0,
        "801":	0,
        "802":	0,
        "803":	0,
        "804":	0,
        "805":	0,
        "806":	0,
        "807":	0,
        "808":	0,
        "809":	0,
        "810":	0,
        "811":	0,
        "812":	0,
        "813":	0,
        "814":	0,
        "815":	0,
        "816":	0,
        "817":	0,
        "818":	0,
        "819":	0,
        "820":	0,
        "821":	0,
        "822":	0,
        "823":	0,
        "824":	0,
        "825":	0
      },
      withholdingCodesItems :  [
        "601-Yapım işleri ile bu işlerle birlikte ifa edilen mühendislik-mimarlık ve etüt-proje hizmetleri 4/10",
        "602-Etüt, plan-proje, danışmanlık, denetim ve benzeri hizmetler	9/10",
        "603-Makine, teçhizat, demirbaş ve taşıtlara ait tadil, bakım ve onarım hizmetleri	7/10",
        "604-Yemek servis hizmeti	5/10",
        "605-Organizasyon hizmeti	5/10",
        "606-İşgücü temin hizmetleri 9/10",
        "607-Özel güvenlik hizmeti	9/10",
        "608-Yapı denetim hizmetleri	9/10",
        "609-Fason olarak yaptırılan tekstil ve konfeksiyon işleri, çanta ve ayakkabı dikim işleri ve bu işlere aracılık hizmetleri	7/10",
        "610-Turistik mağazalara verilen müşteri bulma / götürme hizmetleri	9/10",
        "611-Spor kulüplerinin yayın, reklâm ve isim hakkı gelirlerine konu işlemleri	9/10",
        "612-Temizlik hizmeti	9/10",
        "613-Çevre ve bahçe bakım hizmetleri	9/10",
        "614-Servis taşımacılığı hizmeti	5/10",
        "615-Her türlü baskı ve basım hizmetleri	7/10",
        "616-5018 sayılı kanuna ekli cetvellerdeki idare, kurum ve kuruşlara yapılan diğer hizmetler	5/10",
        "617-Hurda metalden elde edilen külçe teslimleri	7/10",
        "618-Hurda metalden elde edilenler dışındaki bakır, çinko ve alüminyum külçe teslimleri	7/10",
        "619-Bakır, çinko ve alüminyum ürünlerinin teslimi	7/10",
        "620-İstisnadan vazgeçenlerin hurda ve atık teslimi	7/10",
        "621-Metal, plastik, lastik, kauçuk, kâğıt ve cam hurda ve atıklardan elde edilen hammadde teslimi	9/10",
        "622-Pamuk, tiftik, yün ve yapağı ile ham post ve deri teslimleri	9/10",
        "623-Ağaç ve orman ürünleri teslimi	5/10",
        "624-Yük taşımacılığı hizmeti	2/10",
        "625-Ticari reklam hizmetleri	3/10",
        "626-Diğer teslimler	2/10",
        "627-Demir-Çelik ürünlerinin teslimi	5/10"	,
        "801-Yapım İşleri ile Bu İşlerle Birlikte İfa Edilen Mühendislik-Mimarlık ve Etüt-Proje Hizmetleri	10/10	",
        "802-Etüt, Plan-Proje, Danışmanlık, Denetim ve Benzeri Hizmetler	10/10	",
        "803-Makine, Teçhizat, Demirbaş ve Taşıtlara Ait Tadil, Bakım ve Onarım Hizmetleri	10/10	",
        "804-Yemek Servis Hizmeti	10/10	",
        "805-Organizasyon Hizmeti	10/10	",
        "806-İşgücü Temin Hizmetleri	10/10	",
        "807-Özel Güvenlik Hizmeti	10/10"	,
        "808-Yapı Denetim Hizmetleri	10/10	",
        "809-Fason Olarak Yaptırılan Tekstil ve Konfeksiyon İşleri, Çanta ve Ayakkabı Dikim İşleri ve Bu İşlere Aracılık Hizmetleri	10/10	",
        "810-Turistik Mağazalara Verilen Müşteri Bulma/ Götürme Hizmetleri	10/10	",
        "811-Spor Kulüplerinin Yayın, Reklâm ve İsim Hakkı Gelirlerine Konu İşlemleri	10/10	",
        "812-Temizlik Hizmeti	10/10	",
        "813-Çevre ve Bahçe Bakım Hizmetleri	10/10	",
        "814-Servis Taşımacılığı Hizmeti	10/10	",
        "815-Her Türlü Baskı ve Basım Hizmetleri	10/10",
        "816-Hurda Metalden Elde Edilen Külçe Teslimleri	10/10	",
        "817-Hurda Metalden Elde Edilenler Dışındaki Bakır, Çinko, Demir Çelik, Alüminyum ve Kurşun Külçe Teslimi	10/10	",
        "818-Bakır, Çinko, Alüminyum ve Kurşun Ürünlerinin Teslimi	10/10	",
        "819-İstisnadan Vazgeçenlerin Hurda ve Atık Teslimi	10/10",
        "820-Metal, Plastik, Lastik, Kauçuk, Kâğıt ve Cam Hurda ve Atıklardan Elde Edilen Hammadde Teslimi	10/10",
        "821-Pamuk, Tiftik, Yün ve Yapağı İle Ham Post ve Deri Teslimleri	10/10	",
        "822-Ağaç ve Orman Ürünleri Teslimi	10/10",
        "823-Yük Taşımacılığı Hizmeti	10/10",
        "824-Ticari Reklam Hizmetleri	10/10",
        "825-Demir-Çelik Ürünlerinin Teslimi	10/1"
      ],
      orijinalWithholdingInputModel : {},
      selectInvoiceKind: [],
      itemsInvoiceKinds : [
        "Alış",
        "Satış"
      ],
      selectInvoiceTypes: [],
      itemsInvoiceType : ["E-Arşiv", "E-Fatura"],
      periodItemsForCompare : [],
      selectedPeriodCompare : [],
      selectedPeriodForCompareValid : false, 
      selectPeriodForCompareDialog : false,

      model: {},
      model1: {},
      initialModel1: {},

      tableElements: {},
      detailDialogModel: false,
      selectedRowsDataBillNo: {},
      rightRenderKey: 0,
      leftRenderKey: 0,

      //ticketDialogs datas
      ticketDialog: false,
      ticketElements: {},

      componentElementsLeft: {},
      componentElementsRight: {},
      HTMLComponent: {},
      HTMLMainInputs: [],
      HTMLEditInputs: [],
      XMLComponent: {},
      accountComponent: {},
      tableRenderKey:0,
      openTableName: "eBillUniqTable",
    };
  },
  created() {
    this.openTableName = "eBillUniqTable"
    if (this.$route.meta.menu == 'archived') {
      this.openTableName = "eBillUniqArchiveTable"
    }
    this.loadData();
    getPlans(this.initialModel1);
  },
  watch: {
    '$route.meta.menu':function (newVal) {
      this.openTableName = "eBillUniqTable"
      if (this.$route.meta.menu == 'archived') {
        this.openTableName = "eBillUniqArchiveTable"
      }
      this.loadData()
      this.tableRenderKey++
    },
  },
  methods: {
    loadData() {
      let that = this;
      this.tableElements = {
        itemClass : function (model) {
          if (model.expanded == true) {
            return 'rowSelected'
          }
          else if (that.selectedRowsDataBillNo != "") {
            if (that.selectedRowsDataBillNo == model.billNo ) {
              return 'rowSelected'
            }
          }
          return ''
        },
        sortBy: [""],
        initialFilter: that.$route.meta.menu == 'archived' ? {[Enum.initialFilter.AND]: []} : {
          [Enum.initialFilter.AND]:
            [
              {
                review_status: {
                  [Enum.initialFilter.IN]: [that.$route.meta.menu == 'reviewed' ? 1 : that.$route.meta.menu == 'deleted' ?  2 : 0]   
                }
              }
            ]
        },
        sortDesc: false,
        tableHeight: "calc(100vh - 180px)",
        renderKey: 0,
        deleteBtnText: "Sil",
        deleteUrl: "deleteEBills",
        type: "table",
        name: that.openTableName,
        class: "",
        deleteBtnSettings : {
          color: "error",
          style:"height: 25px !important; margin-top : 8px",
        },
        heightType: "heightAlternative",
        infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
        hidePagination: true,
        selectedRows: [],
        filteredColumn: {},
        filteredColumns: true,
        selectionUrl: "getTableDimro", /// created anında çekilecek end point eBillTestEndPoint
        showDefaultDeleteButon: that.$route.meta.menu != 'deleted' && that.$route.meta.menu != 'archived' ? true : false,
        showDefaultDeleteButon: true,
        deleteBtnSettings: {
          color: "error",
          style: that.$route.meta.menu == 'update' ? "height: 25px !important; margin-top : 8px;margin-left:-10px" : "height: 25px !important; margin-top : 8px",
          small:true
        },
        //showDefaultUpdateButon: false,
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            rowStyle: "display:flex; flex-direction: row; justify-content: space-between; align-items: center; width: 100%; padding: 0px 10px 10px 10px;gap:10px",
            inputElements: [
            [
                {
                  id: "getDataWithDateStarted",
                  type: "v-date-picker",
                  variant: "text",
                  name: "getDataWithDateStarted",
                  hideDetails : true,
                  label: "Başlangıç Tarihi",
                  value : "",
                  prependIcon: "fas fa-at",
                  placeHolder : "",
                  style: "width: 160px; ",
                  classTag : "",
                  readonly: true,
                  rules: [
                    (value) => {
                      const pattern = /^(0[1-9]|1[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;
                      return pattern.test(value) || "Geçersiz Tarih"; 
                    },
                  ],
                },
              ],
              [
                {
                  id : "getDataWithDateEnd",
                  type : "v-date-picker",
                  variant : "text",
                  name : "getDataWithDateEnd",
                  label : "Bitiş Tarihi",
                  hideDetails : true,
                  value : "",
                  prependIcon : "fas fa-at",
                  placeHolder : "",
                  style : "width: 160px; margin-right:20px;",
                  classTag : "",
                  readonly : true,
                  rules: [
                    (value) => {
                      const pattern = /^(0[1-9]|1[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;
                      return pattern.test(value) || "Geçersiz Tarih"; 
                    },
                  ],
                },
              ],
              [
                {
                  id: "fetchDataBetweenTwoDates",
                  type: "v-btn",
                  variant: "btn",
                  name: "fetchDataBetweenTwoDatesBtn",
                  inputText: "VERİ GETİR",
                  divStyle: "",
                  color : "#313131",
                  style:"height: 25px !important; margin-top: 8px;color:white",
                  btnTypeText: false,
                  prependIcon: "",
                  classTag: "",
                  onClick: function (thisData, model, dynamicElements) {
                    let resultFetchData = that.getDataBeetwenDate(model, openSnackbar, openTablePage, dynamicElements )
                    if (resultFetchData != false) {
                      dynamicElements = resultFetchData 
                      openTablePage(that.openTableName);
                    }
                  }
                },
              ],
              [
                {
                  id: "receiptKindModel",
                  type: "v-select",
                  name: "receiptKindModel",
                  variant: "text",
                  editable: false,
                  style: "padding-top:0px;width:200px;height: 25px",
                  creatable: false,
                  inlineEditable: false,
                  value: "receiptType",
                  label: "Fatura Tipi",
                  dropDownItems: ["E-Arşiv", "E-Fatura"],
                  multiple: false,
                  onChange: function(thisData, model, dynamicElements) {
                    showLoader("Lütfen Bekleyiniz...");
                    let type = "10";
                    if (model.receiptKindModel == "E-Arşiv") {
                      type = "11";
                    }else if (model.receiptKindModel == "E-Fatura") {
                      type = "10";
                    }
                    let pushFlag = true;
                    for (let i = 0; i < dynamicElements.initialFilter.and.length; i++) {
                      if (dynamicElements.initialFilter.and[i].type != undefined) {
                        pushFlag = false
                        dynamicElements.initialFilter.and[i].type = {
                          [DideroEnum.initialFilter.EQ]: type
                        }
                      }
                    }
                    if (pushFlag) {
                      dynamicElements.initialFilter.and.push({ /// createdtaki initialFilter çalışmadığı için bu şekilde.
                        type: {
                          [DideroEnum.initialFilter.EQ]: type
                        }
                      })
                    }
                    openTablePage(that.openTableName);
                  }
                },
              ],
              [
                // {
                //   id: "receiptTypeModel",
                //   type: "v-select",
                //   name: "receiptTypeModel",
                //   variant: "text",
                //   editable: false,
                //   style: "padding-top:0px;width:200px;height: 25px",
                //   creatable: false,
                //   inlineEditable: false,
                //   value: "receiptKind",
                //   label: "Fatura Türü",
                //   dropDownItems: ["Tümü", "Normal" , "Araç" , "Tevkifat", "İade", "İstisna", "İhraç Kayıtlı", "İletişim Faturası", "Özel Matrah", "İhracat"],
                //   multiple: false,
                //   onChange: function(thisData, model, dynamicElements) {
                //     showLoader("Lütfen Bekleyiniz...");
                //     let einvoiceType = model.receiptTypeModel
                //     if (model.receiptKindModel == 'receiptType') {
                //       dynamicElements.initialFilter = {
                //       [DideroEnum.initialFilter.AND] :
                //         [
                //           {
                //             e_invoice_type: {
                //               [DideroEnum.initialFilter.EQ]: einvoiceType
                //             },
                //             project_id: {
                //               [DideroEnum.initialFilter.EQ]: undefined
                //             }
                //           }
                //         ]
                //       }
                //     }else{
                //       dynamicElements.initialFilter = {
                //       [DideroEnum.initialFilter.AND] :
                //         [
                //           {
                //             e_invoice_type: {
                //               [DideroEnum.initialFilter.EQ]: einvoiceType
                //             },
                //             project_id: {
                //               [DideroEnum.initialFilter.EQ]: undefined
                //             },
                //             kind: {
                //               [DideroEnum.initialFilter.EQ]: model.receiptKindModel
                //             },
                //           }
                //         ]
                //       }
                //     }
                //     openTablePage(that.openTableName);
                //   }
                // },
              ],
              [
                {
                  id : "compareBillsAndMuavin",
                  type : "v-btn",
                  variant : "btn",
                  name : "compareBillsAndMuavin",
                  inputText : "Karşılaştır",
                  divStyle:"",
                  style:"height: 25px !important; margin-top: 8px;color:white",
                  btnTypeText: false,
                  color : "#313131",
                  prependIcon : "",
                  classTag : "",
                  onClick : function(thisData,model,dynamicElements) {                    
                    sendRequest(Enum.requestType.POST, "getPeriodsForEBillComparison","",{},function (res) {
                      let responsePeriod = res.data.data.value
                      if (responsePeriod.length > 0) {
                        that.periodItemsForCompare = responsePeriod
                        that.selectPeriodForCompareDialog = true
                      } else {
                        openSnackbar("grey", "Faturanız Bulunmamaktadır", 2000)
                      }
                    })
                  }
                }
              ],
              [// Arşivden Çıkar
                {
                  id: "singleAssignment",
                  type: "v-btn",
                  variant: "btn",
                  name: "singleAssignment",
                  inputText: "Arşİvden Çıkar",
                  divStyle: "",
                  style:"height: 25px !important; margin-top: 8px;color:white",
                  btnTypeText: false,
                  color : "#313131",
                  small:true,
                  prependIcon: "",
                  classTag: "",
                  showInput:that.$route.meta.menu == 'archived',
                  onClick: function (thisData, model, dynamicElements) {
                    showLoader("Lütfen Bekleyiniz...");
                    let updatedArray = []
                    if (dynamicElements.selectedRows.length > 0) {
                      for (let i = 0; i < dynamicElements.selectedRows.length; i++) {
                       updatedArray.push(dynamicElements.selectedRows[i].uuId)
                      }
                      sendRequest(Enum.requestType.POST,"unArchive","",{updateData : updatedArray},function (response) {
                          openSnackbar("success","Seçtiğiniz veriler başarılı bir şekilde arşivden çıkarılırdı.",1000);
                          hideLoader();
                          openTablePage(that.openTableName);
                        },
                        function (error) {
                          hideLoader();
                          console.log(error);
                          openSnackbar("error","Seçtiğiniz veriler arşivden çıkarılırken bir hata oluştu.");
                        }
                      );
                    }else{
                      hideLoader();
                      openSnackbar("error","Lütfen Satır Seçiniz", 2000);
                    }
                  },
                },
              ],
              [// Arşive Ekle
               {
                  id: "addArchive",
                  type: "v-btn",
                  variant: "btn",
                  name: "exelDownload",
                  inputText: "ARŞİVE EKLE",
                  showInput: that.$route.meta.menu != 'deleted' && that.$route.meta.menu != 'archived' ? true : false,
                  color : "#313131",
                  style: "height: 25px ;margin-top: 8px;color:white",
                  small:true,
                  btnTypeText: false,
                  // showInput:that.$route.meta.menu != 'archived',
                  onClick: function (thisData, model, dynamicElements) {
                    let updatedId = [], updatedObject = [], updatedTable = [];
                    model.dateFormattedLuca = "";
                    if (dynamicElements.selectedRows.length > 0) {
                      let dialogInfo = {
                        name: "infoTableDialog",
                        title: dynamicElements.selectedRows.length == 0 ? "Aktarılacak Kayıt Sayısı: " + model.itemCount : "Aktarılacak Kayıt Sayısı: " + dynamicElements.selectedRows.length,
                        maxWidth: "600px",
                        layout: [
                          {
                            type: Enum.layoutType.div,
                            name: "infoDialog",
                            colStyle: "width: 100%;",
                            inputElements: [
                              [
                                {
                                  id: "dateFormattedArchive",
                                  type: "v-date-picker",
                                  variant: "text",
                                  name: "dateFormattedArchive",
                                  label: "Aktarılacak Tarih",
                                  value: "",
                                  prependIcon: "fas fa-at",
                                  placeHolder: "",
                                  style: "width: 50%;",
                                  classTag: "",
                                  calendarType: "month",
                                  readonly: true,
                                  rules: [
                                    (value) => {
                                      const pattern = /^(0[1-9]|1[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;
                                      return pattern.test(value) || "Geçersiz Tarih";
                                    },
                                  ],
                                },
                              ]
                            ]
                          },
                        ],
                        actions: [
                          {
                            id: "save",
                            type: "v-btn",
                            variant: "btn",
                            name: "save",
                            inputText: "ARŞİVE EKLE",
                            divStyle: "",
                            btnTypeText: true,
                            color: "green darken-1",
                            classTag: "",
                            onClick: function (thisData, model, dialogInfo) {
                              if (model.dateFormattedArchive != "" && model.dateFormattedArchive != null) {
                                showLoader("Lütfen Bekleyiniz...");
                                let datePart = model.dateFormattedArchive.split('/');
                                let period = parseInt(datePart[1] + datePart[0]);
                                if (dynamicElements.selectedRows.length == 0) {
                                  updatedObject = {
                                    startDate: model[that.openTableName].getDataWithDateStarted, endDate: model[that.openTableName].getDataWithDateEnd,
                                    filterData: dynamicElements.filteredColumn
                                  }
                                } else {
                                  for (let i = 0; i < dynamicElements.selectedRows.length; i++) {
                                    updatedId.push({ invoiceId: dynamicElements.selectedRows[i].invoiceId })
                                  }
                                }

                                sendRequest(
                                  Enum.requestType.POST,
                                  "addArchive",
                                  "",
                                  {
                                    updatedId: updatedId,
                                    updatedObject: updatedObject,
                                    updatedTable: updatedTable,
                                    tableName: that.tableElements.name,
                                    period: period
                                  },
                                  function (response) {
                                    dynamicElements.selectedRows = []
                                    hideLoader()
                                    closeDialog();
                                    openSnackbar("success", "Seçtiğiniz veriler başarılı bir şekilde arşivlendi.", 3000);
                                    openTablePage(that.openTableName);
                                  },
                                  function (error) {
                                    console.log(error);
                                    hideLoader()
                                    openSnackbar("error", "Seçtiğiniz veriler arşivlenirken bir hata oluştu.");
                                  }
                                );
                              }
                              else {
                                openSnackbar("error", "Lütfen Bir Tarih Seçiniz.", 3000);
                              }
                            },
                          },
                          {
                            id: "close",
                            type: "v-btn",
                            variant: "btn",
                            name: "close",
                            inputText: "Kapat",
                            divStyle: "",
                            btnTypeText: true,
                            color: "green darken-1",
                            classTag: "",
                            onClick: function (thisData, model, dialogInfo) {
                              closeDialog();
                              model.dateFormattedArchive = ""
                            }
                          }
                        ],
                      };
                      showDialog(dialogInfo);
                    } else {
                      openSnackbar("error", "Lütfen Arşivlemek İstediğiniz Satırların Seçimi Yapınız.");
                    }
                  },
                },
              ],
              [// Onayla
               {
                  id: "addReviewed",
                  type: "v-btn",
                  variant: "btn",
                  name: "addReviewed",
                  inputText: "Onayla",
                  color : "#313131",
                  style: "height: 25px ;margin-top: 8px;color:white",
                  small:true,
                  btnTypeText: false,
                  showInput: that.$route.meta.menu == 'update',
                  onClick: function (thisData, model, dynamicElements) {
                    if (dynamicElements.selectedRows.length > 0) {
                      let explanation = "Onaylanan Belgeler Sayfasına Aktarılacak Kayıt Sayısı: "
                      let successExplanation = "Seçtiğiniz veriler Onaylanan Belgeler sayfasına aktarıldı."
                      let errorExplanation = "Seçtiğiniz veriler Onaylanan Belgeler sayfasına aktarılırken hata oluştu.Tekrar deneyiniz."
                      let approvedDocumentsEntryAndExitSelect = true
                      let infoTableDialogs = infoTableDialogFunc(dynamicElements ,explanation , successExplanation,errorExplanation,approvedDocumentsEntryAndExitSelect)
                    } else {
                    openSnackbar("error", "Lütfen Onaylanan Belgeler Sayfasına Aktarmak İstediğiniz Satırların Seçimi Yapınız.");
                    }
                  },
                },
              ],
              [ // Onayı Geri al
                {
                  id: "addUploaded",
                  type: "v-btn",
                  variant: "btn",
                  name: "addUploaded",
                  inputText: "ONAYI GERİ AL",
                  color : "#313131",
                  style: "height: 25px ;margin-top: 8px;color:white",
                  small:true,
                  btnTypeText: false,
                  showInput:that.$route.meta.menu != 'archived',
                  onClick: function (thisData, model, dynamicElements) {
                    if (dynamicElements.selectedRows.length > 0) {
                      let explanation = "Yüklenen Belgeler Sayfasına Aktarılacak Kayıt Sayısı: "
                      let successExplanation = "Seçtiğiniz veriler Yüklenen Belgeler sayfasına aktarıldı."
                      let errorExplanation = "Seçtiğiniz veriler Yüklenen Belgeler sayfasına aktarılırken hata oluştu.Tekrar deneyiniz."
                      let approvedDocumentsEntryAndExitSelect = false
                      let infoTableDialogs = infoTableDialogFunc(dynamicElements ,explanation , successExplanation,errorExplanation,approvedDocumentsEntryAndExitSelect)
                    } else {
                      openSnackbar("error", "Lütfen Arşivlemek İstediğiniz Satırların Seçimi Yapınız.");
                    }
                  },
                  showInput: that.$route.meta.menu == 'reviewed'
                },
              ]
              ,[]
            ],
          },
        ],
        headers: [
          {
            text: "Tarih",
            align: "center",
            value: "billDate",
            width: "70",
            divider: true,
            excelName: "Satış Faturası / VEDOP Tarihi",
            dataType: "date",
            search: true,
            sortable: true
          },
          {
            text: "Durum",
            align: "center",
            value: "control",
            width: "65",
            switchValue: { 0: "+", 1: "--" },
            divider: true,
            search: false,
            sortable: false,
            items: [{ name: "Tümü", value: "" }, { name: "Tutarlı", value: 0 }, { name: "Tutarsız", value: 1 }],
            colorRow: true
          },
          {
            text: "Fatura Tipi",
            align: "center",
            value: "kind",
            width: "110",
            divider: true,
            switchValue: {0 : "Satış", 1 : "Alış"},
            search: false,
            sortable: true,
            items: [{name : "Tümü", value : ""}, {name : "Alış", value : 1}, {name : "Satış", value : 0}]
          },
          {
            text: "Fatura Türü",
            align: "center",
            value: "eInvoiceType",
            width: "120",
            switchValue: {0 : "Normal", 1 : "Araç", 2 : "Tevkifat", 3 : "İade", 4 : "İstisna", 5 : "İhraç Kayıtlı", 6: "İletişim Faturası", 7 : "Özel Matrah", 11 : "İhracat"},
            divider: true,
            search: false,
            sortable: true,
            items: [{name : "Tümü", value : ""}, {name : "Normal", value : 0}, {name : "Araç", value : 1}, {name : "Tevkifat", value : 2}, {name : "İade", value : 3}, {name : "İstisna", value : 4}, {name : "İhraç Kayıtlı", value : 5}, {name : "İletişim Faturası", value : 6}, {name : "Özel Matrah", value : 7}, {name : "İhracat", value : 11}]
          },
          {
            text: "Fatura No",
            value: "billNo",
            align: "center",
            divider: true,
            width: "160",
            excelName: "Satış Faturası No",
            search: true,
            sortable: true
          },
          {
            text: "Vergi-TC Kimlik Numarası",
            value: "tinNumber",
            align: "center",
            width: "195",
            divider: true,
            excelName: "Vergi Kimlik Numarası / TC Kimlik Numarası",
            search: true,
            sortable: true
          },
          {
            text: "Unvan",
            value: "name",
            align: "center",
            divider: true,
            class: "threedot",
            excelName: "Adı-Soyadı / Unvanı",
            search: true,
            sortable: true
          },
          {
            text: "Genel Toplam",
            value: "totalPrice",
            align: "left",
            dataType: "currency",
            divider: true,
            width: "125",
            search: true,
            sortable: true
          },
          {
            text: "E-Fatura Detay",
            value: "detailInvoice",
            width: "135",
            divider: true,
            search: true,
            sortable: true
          }
        ],
        columns: {
          "detailInvoice": {
            //// cell'in özelleştirmek istiyorsak, cloumnsda value ile aynı değişken adı altında burada tanımlayabilirsin. 
            //cell için gerekli inputElement hücre özelliştirmesi için
            cellElement: {
              id: "detailInvoice",
              type: "v-btn",
              variant: "btn",
              name: "detailInvoice",
              inputText: "Detay",
              divStyle: "",
              style: "height:0px;text-decoration: underline;color: blue !important;",
              btnTypeText: true,
              color: "black",
              classTag: "",
              onClick: function(thisData, model, dynamicElements) {
                model.tdTableRenderForAddRow = 0
                model.editShowModel = false

                model.addCodeDialogOpen = false

                showLoader('Lütfen Bekleyiniz')


                for (let i = 0; i < dynamicElements.data.length; i++) {
                  dynamicElements.data[i].expanded = false
                }

                model.expanded = true
                that.selectedRowsDataBillNo = model.billNo

                let getUblThenFunc = function (params) {
                  let thenFunc = function () {
                    return that.getBillDetail(model.uuId, that.getBillArrayThenFunc, errFunc, model);
                  }
                  return that.getUbl(model, thenFunc, errFunc)
                }
                let errFunc = function (err) {
                };
                return getUblThenFunc();
              }
            }
          }
        },
        //heightType: "heightAlternative",
        clientEvents: {
          onSaveError: function (error) {
            if(error.response != undefined)
            openSnackbar("error", error.response.data.error[0].detail.message);
            else
            openSnackbar("error","Hesap Kodu eklenirken server iletişimi kesildi.")
          },
          onErrorUpdate: function (error) {
            if(error.response != undefined)
            openSnackbar("error", error.response.data.error[0].detail.message);
            else
            openSnackbar("error","Hesap Kodu eklenirken server iletişimi kesildi.")
          },
        },
        data: "data",
        showSelecetVisible: true,
        singleSelect: false,
        itemKey: "uuId",
        footer: [
          {
            type: Enum.layoutType.div,
            name: "footerForm",
            rowStyle:"display:flex; flex-direction: row; justify-content: space-between; align-items: center; width: 100%; padding: 0px 20px 0px 20px;",
            colStyle: "flex-wrap: nowrap; display: flex; white-space: nowrap; gap:10px;  justify-content: center; align-items: center; ",
            tableStyle: "height: 100%",
            inputElements: [
              [
                {
                  id: "pageSize",
                  type: "v-text-field",
                  variant: "number",
                  name: "pageSize",
                  label: "Görünecek Satır Sayısı",
                  value: "",
                  placeHolder: "",
                  style: "width: 130px",
                  classTag: "",
                  onChange: function (thisData, model, dynamicElements) {
                    openTablePage(that.openTableName);
                  },
                },
              ],
              [
                {
                  id: "currentPageNo",
                  type: "v-pagination",
                  variant: "pagination",
                  name: "currentPageNo",
                  divStyle: "",
                  style: "width:500px;",
                  classTag: "my-1",
                  length: "pagesCount",
                  onInput: function (thisData, model, dynamicElements) {
                    openTablePage(that.openTableName);
                  },
                },
              ],
              [
                {
                  id: "infoTotalRowCountHeaderAssistantTable",
                  type: "p",
                  variant: "p",
                  name: "infoTotalRowCountHeaderAssistantTable",
                  inputText: "infoTotalRowCountHeaderAssistantTable",
                  divStyle: "",
                  style: "font-size: 12px;font-weight:500;margin:3px 0;color:#666A6F;",
                  classTag: "",
                },
                {
                  id: "infoTotalRowCountAssistantTable",
                  type: "p",
                  variant: "p",
                  name: "infoTotalRowCountAssistantTable",
                  inputText: "itemCount",
                  divStyle: "",
                  style: "font-size: 15px;font-weight:500;margin:3px 0;color:#666A6F;",
                  classTag: "",
                },
              ],
            ],
          },
        ],
      };
        
    },
    selectedWithholdingCodeFunc (){
      // dialog açıldığında orijinal arrayi kaydet, daha sonra kaydet dediğinde, bu orijinal arrayi diğeri ile karşılaştır,  çıkan idleri al ve null olarak update et. bu sefer silinsin. ! ! ! ! ! oldu işte oldu ! ! !     
      this.withholdingInputRenderKey++
    },
    addWithholdingCodes () {
      showLoader("Lütfen Bekleyiniz")
      let that = this;
      const differencesWithholdingCodes = [];
      let sameElementArray = []
      for (let i = 0; i < that.orijinalSelectedWithholdingArray.length; i++) {
        if (!that.selectWithholdingCodes.includes(that.orijinalSelectedWithholdingArray[i])) {
          differencesWithholdingCodes.push(that.orijinalSelectedWithholdingArray[i]);
        }else{
          sameElementArray.push(that.orijinalSelectedWithholdingArray[i]);
        }
      }
      for (let i = 0; i < that.selectWithholdingCodes.length; i++) {
        that.withholdingInputModel[that.selectWithholdingCodes[i].split("-")[0]] = JSON.parse(JSON.stringify(that.withholdingInputModel[i]))
        delete that.withholdingInputModel[i]
        // if (that.orijinalWithholdingInputModel[that.selectWithholdingCodes[i].split("-")[0]] !== that.withholdingInputModel[that.selectWithholdingCodes[i].split("-")[0]]) {
          
        // }
        if (differencesWithholdingCodes.includes(that.selectWithholdingCodes[i])) {
          that.selectWithholdingCodes.splice(i, 1)
        }
      }
      sendRequest(Enum.requestType.POST, "addedWithholding", "", {sameElementArray, differencesWithholdingCodes,withholdingInputModel : that.withholdingInputModel, selectedWithholdingCodes: that.selectWithholdingCodes, invoiceTransactionArray : that.model1.desserts },
        function (response) {
          hideLoader()
          openSnackbar("green","Verileriniz Başarıyla Kaydedildi.",2000)
          that.withholdingCodeSelectDialog = false
          return that.getBillDetail(that.model1.uuId, that.getBillArrayThenFunc, function (params) {
            errFunc
          }, that.model1);
        },
        function (error) {
          hideLoader()
          errFunc(error);
        }
      )
    },
    getDeclarationNo(model) {  // MERCAN BEY DİDERO
      let that = this;
      sendRequest(Enum.requestType.POST, "getCustomDeclarationNo", null, null,
        function (response) {
          model.gcbPeriodObj = {
            items : [],
            model : null
          }
          if (response.data.data?.value?.length > 0) {
            let gcbItemsRes = response.data.data?.value
            gcbItemsRes.forEach(element => {
              const convertDate = moment(element.closingDate).format('MM/YYYY');
              element.gcbPeriod = convertDate
              if (!(model.gcbPeriodObj.items.includes(convertDate))) {
                model.gcbPeriodObj.items.push(convertDate);
              }
            });
          }
          model.gcbPeriodObj.items.unshift("Tümü")
          model.declarationNoItemsOriginal = response.data.data.value
          model.declarationNoItems = response.data.data.value;
          that.leftRenderKey++
        },
        function (error) {
          openSnackbar("error", "GÇB NO getirilemedi.")
        }
      );
    }, 

    getBillDetail(uuId, thenFunc, errFunc, model){

      model.ticketModel = false
      let that = this
      let postmodel = {uuId : uuId, tableName: that.openTableName, tableNames : this.openTableName};
      sendRequest(Enum.requestType.POST,"eBillTestBillNoEndPoint","",postmodel,
        function (response) {
          let billArray = response.data.data.value

          for (let i = 0; i < billArray.length; i++) {
            billArray[i].indexExpanded = hashCode();
          }

          //başlangıçta headerların ayarlandığı yer
          that.setInitialHeaders(model)
          that.detailDialogModel = true; // detay dialoğunu açar

          that.model1 = { ...model }
          that.model1.accountCodes = that.initialModel1.accountCodes
          //merve araç faturası 
          that.model1.vehicleRentBoxBoo = response.data.data.value[0]?.invoiceInfo?.vehicle?.rent
          that.model1.vehiclePunishmentBoxBoo = response.data.data.value[0]?.invoiceInfo?.vehicle?.punishment
          that.ticketElements = {
            billNo: that.model1.billNo,
            fileName: that.model1.fileName,
          }
          that.HTMLMainInputs = [{
            componentName: "newSpecialButton",
            inputStyle: "",
            elements: {
              text: "Orijinali Göster",
              icon: "mdi-file",
              onClick: function (model, componentElements) {
                that.componentElementsLeft = that.XMLComponent
              },
            },
          },
          {
            componentName: "newSpecialButton",
            inputStyle: "",
            elements: {
              text: "Düzenle",
              icon: "mdi-pencil",
              onClick: function (model, componentElements) {
                model.editShowModel = true
                that.HTMLComponent.specialInputs = that.HTMLEditInputs
                that.componentElementsRight = that.XMLComponent
              }
            }
          },
          // {
          //   componentName: "newSpecialButton",
          //   inputStyle: "",
          //   elements: {
          //     text: "Destek",
          //     icon: "mdi-comment-alert",
          //     onClick: function (model, componentElements) {
          //       model.ticketModel = true
          //     }
          //   },
          // },
          ]
          that.HTMLEditInputs = [
            {
              componentName: "newSpecialButton",
              inputStyle: "",
              elements: {
                text: "Geri Dön",
                icon: "mdi-backburger",
                onClick: function (model, componentElements) {
                  that.HTMLComponent.specialInputs = that.HTMLMainInputs
                  that.componentElementsRight = that.accountComponent
                  that.getBillDetail(model.uuId, that.getBillArrayThenFunc, errFunc, model);
                  model.editShowModel = false
                },
              },
            },
            {
              componentName: "newSpecialButton",
              inputStyle: "",
              elements: {
                text: "Kaydet",
                icon: "mdi-content-save",
                onClick: function (model, componentElements) {
                  if (!model.invalidDate) {
                    that.tdSaveFunc(model)
                  }
                },
              },
            },
            {
              componentName: "newSpecialButton",
              inputStyle: "",
              elements: {
                text: "Satır ekle",
                icon: "mdi-plus-box",
                onClick: function (model, componentElements) {
                  let element = JSON.parse(JSON.stringify( model.desserts[0]));
                  element.invoiceTransactionId = null
                  element.totalPrice = 0
                  element.price = 0
                  model.desserts.push(element)
                  that.leftRenderKey++
                },
              },
            },
            {
              componentName: "newSpecialButton",
              inputStyle: "",
              elements: {
                text: "Satır Sil",
                icon: "mdi-minus-box",
                onClick: function (model, componentElements) {
                  model.desserts.pop();
                  that.leftRenderKey++
                },
              },
            },
          ]
          that.XMLComponent = {
            componentStyle: "display:flex;flex-direction:column;width:50%;min-height:100vh",
            componentName: "xmlOutputViewer",
            componentBodyStyle: "display:flex;height:100%;flex-direction:column",
            showInfo:true,
            importComponentNames: [
              // {
              //   componentName: 'newXmlOutputViewer',
              //   componentPath: require("../molecules/newXmlOutputViewer.vue")
              // },
              {
                componentName: 'newSpecialButton',
                componentPath: require("../molecules/newSpecialButton.vue")
              }],
            props: {
              viewerElements: { style: "height:calc(100vh - 40px);width:100%", viewerShowModel: false },
              info: {},
              bottomTableHeaders: {
                header1: "Hizmet Toplam Tutarı",
                header2: "Tevkifat Toplam Tutar",
                header3: "Hesaplanan KDV",
                header4: "Vergiler Dahil Toplam Tutarı",
                header5: "Ödenecek Tutar",
                header6: "Özel İletişim Vergisi"
              }
            },
            specialFunctions: {
              showHTML: function (model, componentElements) {
                // this.componentElements.props.viewerElements.viewerShowModel = false
                // this.$emit('get-bill-detail')
                that.componentElementsLeft = that.HTMLComponent
                that.leftRenderKey++
                // that.getBillDetail(model.uuId, that.getBillArrayThenFunc, errFunc, model);
              }
            },
          }
          that.accountComponent = {
            componentStyle: "display:flex;flex-direction:column;width:50%;min-height:100vh;",
            componentName: "newRight",
            componentBodyStyle: "display:flex;height:100%;width:100%",
            importComponentNames: [{
              componentName: 'newRight',
              componentPath: require("./newRight.vue")
            },
            {
              componentName: 'newSpecialButton',
              componentPath: require("../molecules/newSpecialButton.vue")
            }
            ],
            props: {
              productInvoiceItems: that.model1.logoInvoiceType == 4 ? eBillsHeaders.productInvoiceLogoTypes : eBillsHeaders.productInvoiceTypes,
              inputElementAuto: {
                type: "v-select",
                name: "invoice",
                label: "Hesap Seçiniz",
                dropDownItems: that.model1.accountCodes,
                key: "id",
                visibleItemKeys: ["accountCode", "accountName"],
                hoverItemKeys: ["accountName"],
                inputBoxStyle: "height: 27px;",
                onclick: function (selectedItem, onClickElements) {
                  const updatedDesserts = that.model1.desserts.map((currentItem) => {
                    if (onClickElements?.item?.withholdingCode !== null && currentItem.withholdingCode === onClickElements?.item?.withholdingCode) {
                      if (onClickElements.type === 'accountPlanWithholPurchaseDeductedVat') {
                        currentItem['accountPlanWithholPurchaseDeductedVatCode'] = selectedItem;
                      }
                      else if (onClickElements.type === 'accountPlanWithholdingPurchasePayableVat') {
                        currentItem['accountPlanWithholdingPurchasePayableVatCode'] = selectedItem;
                      }
                      else if (onClickElements.type === 'accountPlanWithholdingSalesDeductedVat') {
                        currentItem['accountPlanWithholdingSalesDeductedVatCode'] = selectedItem;
                      }
                      else if (onClickElements.type === 'accountPlanWithholdingSalesPayableVat') {
                        currentItem['accountPlanWithholdingSalesPayableVatCode'] = selectedItem;
                      }
                    }else if (onClickElements?.type === 'accountPlanVehicleBaseName') { // tüm kalemlere araç hesap kodlarını aktarmak için.
                      currentItem['accountPlanVehicleBaseCode'] = selectedItem;
                    }else if (onClickElements?.type === 'accountPlanVehicleVatName') {
                      currentItem['accountPlanVehicleVatCode'] = selectedItem;
                    }else if (onClickElements?.type === 'accountPlanPunishmentAccountName') {
                      currentItem['accountPlanPunishmentAccountCode'] = selectedItem;
                    }
                    return currentItem;
                  });
                  that.model1.desserts = updatedDesserts;
                  that.rightRenderKey++;
                },
              }
            },

            specialFunctions: {
              handlePagination: function (page, itemsPerPage, model, componentElements) {
                model.pageNow = page
                if (model.paginationBoo == true) {
                  model.paginationDesserts = model.desserts.slice(
                    (page - 1) * itemsPerPage,
                    page * itemsPerPage
                  );
                  that.leftRenderKey++
                }
              }
            },


            headerStyle: "display:flex;flex-direction:column",
            titleStyle: "font-size:20px;font-weight:600;width:100%;background-color:#b5bbca;height:40px;display:flex;align-items:center",
            title: "E-Fatura Detay",

            inputsAreaStyle: "display:flex;flex-direction:row;justify-content:space-between;padding:5px;height:90px",
            inputsStyle: "display:flex;flex-direction:row;gap:5px;align-items:center",
            inputs: [{
              inputStyle: "width:180px",
              type: "v-select",
              label: "Tür:",
              outlined: true,
              dense: true,
              itemValue: 'id',
              itemText: 'name',
              dropDownItems: eBillsHeaders.invoiceTypes,
              name: "eInvoiceType",
              onChange: function (thisData, model, dynamicElements) {
                that.changeInvoiceType(model)
              }
            },
            {
              inputStyle: "width:230px",
              type: "v-select",
              label: "Logo F. Tipi:",
              outlined: true,
              dense: true,
              itemValue: 'id',
              itemText: 'name',
              dropDownItems: that.model1.kind == 1 ? eBillsHeaders.logoInvoiceTransactionsPurchaseInvoice : eBillsHeaders.logoInvoiceTransactionsSalesInvoices,
              name: "logoInvoiceType",
              onChange: function (thisData, model, dynamicElements) {
                dynamicElements.props.productInvoiceItems = model.logoInvoiceType != 4 ? eBillsHeaders.productInvoiceTypes : eBillsHeaders.productInvoiceLogoTypes
              },
              showInput: that.$store.state.logoErpAndReciptKeyState ? true : false
            },
            {
              inputStyle: "position:absolute;top:-12px;right:4%",
              type: "v-checkbox",
              label: "KDV Hesap Kodu Eklensin",
              name: "vatBoxBoo",
              showInput: (that.model1.kind == 1 && that.model1.eInvoiceType == 4) ? true : false,
              onChange: function (thisData, model, dynamicElements) {
                if (model.vatBoxBoo == true) {
                  that.setInitialHeaders(model)
                } else {
                  that.setWithoutVatHeaders(model)
                }
                that.rightRenderKey = hashCode();
              },
            },
            {
              inputStyle: "position:absolute;top:6px;right:30px;width:50px",
              type: "v-text-field",
              dense: true,
              outlined:true,
              solo:true,
              name:"vehicleBillDay",
              showInput:  that.model1.eInvoiceType == 1 ,
            },
            {
              inputStyle: "position:absolute;top:-19px;right:280px;width:100px;font-size:10px",
              type: "v-checkbox",
              label: "Ceza Faturası",
              name: "vehiclePunishmentBoxBoo",
              showInput:  that.model1.eInvoiceType == 1 ,
              onChange: function (thisData, model, dynamicElements) {
                if (model.vehiclePunishmentBoxBoo == true) {
                  if (model.vehicleRentBoxBoo == true) {
                    model.vehicleRentBoxBoo = false
                  }
                }
                that.rightRenderKey++
              },
            },
            {
              inputStyle: "position:absolute;top:-19px;right:400px;width:100px;font-size:10px",
              type: "v-checkbox",
              label: "Kiralama Faturası",
              name: "vehicleRentBoxBoo",
              showInput:  that.model1.eInvoiceType == 1 ,
              onChange: function (thisData, model, dynamicElements) {
                if (model.vehicleRentBoxBoo == true) {
                  if (model.vehiclePunishmentBoxBoo == true) {
                    model.vehiclePunishmentBoxBoo = false
                    that.rightRenderKey++
                  }
                }
              },
            },
            {
              inputStyle: "position:absolute;top:2px;right:100px;width:100px;font-size:10px",
              type: "v-btn",
              inputText: "Tevkifat Kodu Ekle",
              label: "Tevkifat Kodu Ekle",
              name: "withholdingBtn",
              showInput:  that.model1.eInvoiceType == 2,
              onClick: function (thisData, model, dynamicElements) {
                // desserts'in içindeki withholdingleri topla, bu arrayin içine pushla. bu şekilde güncel kalacak sonra çıkarılanları bul ve veritabanında update et.
                debugger
                that.selectWithholdingCodes = []
                function toTurkishCurrencyFormat(amount) {
                  const formatter = new Intl.NumberFormat('tr-TR', {
                      style: 'currency',
                      currency: 'TRY',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                  });
                  return formatter.format(amount).replaceAll("₺", "");
                }
                debugger
                for (let i = 0; i < that.model1.desserts.length; i++) {
                  let invoiceElement = that.model1.desserts[i]
                  if (invoiceElement.withholdingCode != null && invoiceElement.withholdingCodeRate != null ) {
                    for (let j = 0; j < that.withholdingCodesItems.length; j++) {
                      let codeElements = that.withholdingCodesItems[j]
                      if (codeElements.split("-")[0] == invoiceElement.withholdingCode) {
                        that.selectWithholdingCodes.push(codeElements)
                        that.withholdingInputModel[that.selectWithholdingCodes.length - 1] = toTurkishCurrencyFormat(invoiceElement.withholdingTaxTotal);
                        that.withholdingInputModel[codeElements.split("-")[0]] = toTurkishCurrencyFormat(invoiceElement.withholdingTaxTotal);
                        
                      }
                    }
                  }
                }
                that.orijinalSelectedWithholdingArray = JSON.parse(JSON.stringify(that.selectWithholdingCodes))
                that.orijinalWithholdingInputModel= JSON.parse(JSON.stringify(that.withholdingInputModel))
                debugger
                that.withholdingCodeSelectDialog = true;
                that.rightRenderKey++;
                that.leftRenderKey++;
              },
            }, 
            ],
            specialInputsStyle: "position:absolute;top:2px;right:90px;font-weight:500",
            specialInputs: [{
              componentName: "newSpecialButton",
              inputStyle: "cursor:unset",
              elements: {
                text: "Kiralanan Gün Sayısı :",
                icon: "mdi-car",
                showInput:that.model1.eInvoiceType == 1
              },
            },],

            componentFooterStyle: "display:flex;width:100%;justify-content:end",
            footerInputs: [{
              inputStyle: "",
              id: "close",
              type: "v-btn",
              variant: "btn",
              name: "close",
              btnTypeText: true,
              color: "black",
              icon: "mdi-arrow-left",
              onClick: function (thisData, model, dynamicElements) {
                that.switchData('left', undefined, model)
              },
            }, {
              inputStyle: "width:fit-content",
              id: "close",
              type: "v-btn",
              variant: "btn",
              name: "close",
              inputText: "(F2)",
              btnTypeText: true,
              color: "black",
              icon: "mdi-arrow-right",
              onClick: function (thisData, model, dynamicElements) {
                that.switchData('right', undefined, model)
              },
            }, 
            // {
            //   inputStyle: "",
            //   id: "delete",
            //   type: "v-btn",
            //   variant: "btn",
            //   name: "delete",
            //   inputText: "SİL",
            //   btnTypeText: true,
            //   color: "red",
            //   icon: "mdi-delete",
            //   onClick: function (thisData, model, dynamicElements) {},
            // }, 
            {
              inputStyle: "width:fit-content",
              id: "close",
              type: "v-btn",
              variant: "btn",
              name: "close",
              inputText: "Kaydet ve İlerle (F4)",
              btnTypeText: true,
              showInput:that.$route.meta.menu != 'archived',
              color: "blue",
              onClick: function (thisData, model, dynamicElements) {
                that.saveInvoiceAssignment('next', model)
              },
            }, {
              inputStyle: "width:fit-content",
              id: "close",
              type: "v-btn",
              variant: "btn",
              name: "close",
              inputText: "Kaydet",
              showInput:that.$route.meta.menu != 'archived',
              btnTypeText: true,
              color: "green",
              onClick: function (thisData, model, dynamicElements) {

                that.saveInvoiceAssignment('stop', model)
              },
            }, {
              inputStyle: "width:fit-content",
              id: "close",
              type: "v-btn",
              variant: "btn",
              name: "close",
              inputText: "Kapat",
              btnTypeText: true,
              color: "red",
              icon: "mdi-close",
              onClick: function (thisData, model, dynamicElements) {
                that.dialogClose()
              },
            },],
          }
          that.HTMLComponent = {
            componentStyle: "display:flex;flex-direction:column;width:50%;min-height:100vh;",
            componentName: "newLeft",
            componentBodyStyle: "display:flex;height:100%;flex-direction:column;",
            importComponentNames: [{
              componentName: 'newLeft',
              componentPath:require("./newLeft.vue")
            },
            {
              componentName: 'newSpecialButton',
              componentPath:require("../molecules/newSpecialButton.vue")
            }],

            props: {
              tableHeaders: [
                { title: "No", style: "width:3%", value: "index" },
                { title: "Mal/Hizmet Kodu", style: "width:11.5%;line-height:12px;font-size:11px", value: "productCode" },
                { title: "Mal/Hizmet Açıklaması", style: "width:30.5%;white-space:nowrap;overflow:hidden;", value: "productName" },
                { title: "Miktar", style: "width:5%", value: "productAmount", editable: true },
                { title: "Birim", style: "width:5%", value: "unit"},
                { title: "Birim Fiyat", style: "width:14.5%", value: "price", dataType: "money"}, //currencyType
                { title: "Oran", style: "width:3%", value: "vatRate", editable: true },
                { title: "KDV Tutarı", style: "width:13%", value: "vat", dataType: "money" }, //currencyType
                { title: "Hizmet Tutarı", style: "width:14.5%", value: "totalPrice", dataType: "money", editable: true  } //currencyType
              ],
              vMoneyElements: [
                {
                  type: Enum.layoutType.div,
                  name: "loginForm",
                  inputElements: [
                    [
                      {
                        id: "rate",
                        type: "v-money",
                        variant: "text",
                        name: "rate",
                        label: "Kur",
                        divStyle: "width:180px",
                        hideDetails: true,
                        money: {
                          decimal: ',',
                          thousands: '.',
                          prefix: '',
                          suffix: '',
                          precision: 4,
                          masked: false
                        },
                      }
                    ]
                  ]
                }
              ],
              topTdHeaders: {
                header1: "Fatura Tipi: ",
                header2: "Fatura No: ",
                header3: "Fatura Tarihi: ",
                header4: "Unvan: ",
                header5: "VKN: ",
                header6: "Beyanname Kapanış Tarihi: "
              },
              bottomTableHeaders: {
                header1: "Hizmet Toplam Tutarı",
                header2: "Tevkifat Toplam Tutar",
                header3: "Hesaplanan KDV",
                header4: "Vergiler Dahil Toplam Tutarı",
                header5: "Ödenecek Tutar",
                header6: "Özel İletişim Vergisi"
              }
            },

            headerStyle: "background-color:#b5bbca;min-height:40px;display:flex;align-items:center;padding-left:8px",
            inputsAreaStyle: "display:flex;flex-direction:row",
            specialInputsStyle: "display:flex;flex-direction:row;gap:5px;background-color:#b5bbca;",
            specialInputs: that.HTMLMainInputs,

          }
          
          if(that.$store.state.showXMLChoice == 'showOriginal'){
            that.componentElementsLeft = that.XMLComponent;
          } else {
            that.componentElementsLeft = that.HTMLComponent;
          }
          
          that.componentElementsRight = that.accountComponent;

          thenFunc(billArray, that.model1);
        },
        function (error) {
          console.log(error);
          errFunc(error);
        }
      );
    },
    getBillArrayThenFunc(billArray, model) {
      let that = this
      model.desserts = billArray

      model.itemsPerPageDetail = 50

      let vatTotal = 0, matrahTotal = 0, currencyType = "", withholdingTaxTotal = 0

      if (billArray[0].rate != null) {
        model.rate = billArray[0].rate.toFixed(4)
      } else {
        model.rate = 20 //bu else silinecek MERCAN BEY MERCAN BEY DİDERO
      }
      model.closingDate = billArray[0].closingDate
      model.declarationNo = billArray[0].declarationNo
      model.currencyType = model.desserts[0].currencyType
      if(model.desserts[0].vehicleBillDay != null ){
        model.vehicleBillDay = model.desserts[0].vehicleBillDay
      } else {
        model.vehicleBillDay = 30
      }
      let matrahTotalWithRate = 0 // -> matrahTotal += billArray[i].totalPrice
      let vatTotalWithRate = 0 // -> vatTotal += billArray[i].vat
      let specialCommunicationTaxAmount = 0
      let sameAccountTransfer = false, tinNumber = billArray[0].tinNumber


      model.paginationBoo = false

      if (!(billArray.length <= model.itemsPerPageDetail || model.editShowModel)) {
        model.paginationBoo = true
        model.paginationDesserts = billArray.slice(0, model.itemsPerPageDetail);
      }

      for (var i = 0; i < billArray.length; i++) {
        if (billArray[i].eInvoiceType == 1 && model.desserts[i].notes != null) {
          billArray[i].numberOfDaysRented = billArray[i].notes.numberOfDaysRented
        }

        tinNumber = billArray[i].tinNumber
        if (billArray[i].productCode == '*') {
          sameAccountTransfer = true
        }
        function toFixed(value) {
          return parseFloat(value.toFixed(2))
        }
        matrahTotalWithRate += toFixed((billArray[i].totalPrice))
        vatTotalWithRate += billArray[i].vat
        matrahTotal += toFixed((billArray[i].totalPrice))
        vatTotal += billArray[i].vat
        if (billArray[i].specialCommunicationTaxAmount != null) {
          specialCommunicationTaxAmount += billArray[i].specialCommunicationTaxAmount
        }
        withholdingTaxTotal += billArray[i].withholdingTaxTotal
        currencyType = billArray[i].currencyType
      }

      model.matrahTotal = currencyFormat(matrahTotal, 2, 3, ",", ".") + " " + currencyType
      if (withholdingTaxTotal == 0) {
        model.withholdingTaxTotal = null
      } else {
        model.withholdingTaxTotal = currencyFormat(withholdingTaxTotal, 2, 3, ",", ".") + " " + currencyType
      }
      if (specialCommunicationTaxAmount == 0) {
        specialCommunicationTaxAmount = null
      }
      model.currencyType = currencyType
      model.specialCommunicationTaxAmount = specialCommunicationTaxAmount
      model.vatTotal = currencyFormat(vatTotal, 2, 3, ",", ".") + " " + currencyType
      model.vatIncludingTotal = currencyFormat(matrahTotal + vatTotal, 2, 3, ",", ".") + " " + currencyType
      model.total = currencyFormat(matrahTotal + vatTotal - withholdingTaxTotal + specialCommunicationTaxAmount, 2, 3, ",", ".") + " " + currencyType
      model.vatIncludingTotalWithRate = currencyFormat(model.rate * (matrahTotalWithRate + matrahTotalWithRate), 2, 3, ",", ".") + " " + "TL"
      model.matrahTotalWithRate = currencyFormat(model.rate * matrahTotalWithRate, 2, 3, ",", ".") + " " + "TL"
      model.vatTotalWithRate = currencyFormat(model.rate * vatTotalWithRate, 2, 3, ",", ".") + " " + "TL"
      model.totalWithRate = currencyFormat(model.rate * (matrahTotalWithRate + vatTotalWithRate - withholdingTaxTotal), 2, 3, ",", ".") + " " + "TL"
      model.rate = currencyFormat(model.rate, 4, 3, ",", ".")
      if (sameAccountTransfer && that.$store.state.sameAccountTransfer[tinNumber] == undefined) {
        that.$store.dispatch("setSameAccountCodes", { tinNumber, value: false })
      }
      model.sameAccountTransfer = sameAccountTransfer
      model.tinNumber = tinNumber
      model.name = billArray[0].name

      that.changeInvoiceType(model);
      hideLoader();
    },

    saveInvoiceAssignment(val, model) {
      showLoader("Lütfen Bekleyiniz...")
      let that = this
      let accountInvoicesInsertArray = [], insertTableName = "t_account_invoice_detail";
      let codesProperty = Object.keys(config.codesPropertyId)
      let updatedId = [], tableName = [], updateObject = {};
      let declarationInsert = {
        declarationNo: "",
        closingDate: ""
      }

      let freeSpaceCheck = true;
      let setObj = {
        "t_invoice_transaction": {
          updateObj: {},
          updateArray: [],
          updateId: [],
          tableName: [],
        },
        "t_invoice": {
          updateObj: {},
          updateId: [],
          updateArray: [],
          tableName: [],
        },
        "t_account_invoice_detail": {
          updateObj: {},
          updateId: [],
          updateArray: [],
          tableName: []
        },
        "t_einvoice_transaction_detail": {
          updateObj: {},
          updateId: [],
          updateArray: [],
          tableName: []
        },
        "t_vehicle_bill_day": {
          updateObj: {},
          updateId: [],
          updateArray: [],
          tableName: []
        }
      }
      for (let i = 0; i < model.desserts.length; i++) {
        freeSpaceCheck = freeSpaceCheckFunc(model.desserts[i], "eBills");
        if (freeSpaceCheck == false) {
          break
        }

        if (model.declarationNo != null) {
          if (model.declarationNo.id == undefined) {
            declarationInsert.declarationNo = model.declarationNo
            declarationInsert.closingDate = model.closingDate
          } else {
            setObj["t_einvoice_transaction_detail"].updateId.push(model.desserts[i].einvoiceTransactionDetailId)
            setObj["t_einvoice_transaction_detail"].updateObj["customDeclarationId"] = model.declarationNo.id /// özel
            setObj["t_einvoice_transaction_detail"].updateArray.push(setObj["t_einvoice_transaction_detail"].updateObj)
            setObj["t_einvoice_transaction_detail"].updateObj = {};
          }
        }
        if (i == 0) {
          if (typeof model.rate == 'string') {
            model.rate = model.rate.replaceAll(".", "")
            model.rate = model.rate.replace(",", ".")
            model.rate = parseFloat(model.rate)
          }
          if (model.currencyType != 'TL' && model.currencyType != 'TRY') {
            setObj["t_invoice"].updateObj["rate"] = model.rate /// özel
          }
          setObj["t_invoice"].updateObj["classification"] = model.eInvoiceType  // BU FATURA TİPİ
          if (model.eInvoiceType == '1') { /// enuma alınması gerekiyor.
            let vehicleObj = {
              rent: model.vehicleRentBoxBoo == undefined ? false : model.vehicleRentBoxBoo,
              punishment: model.vehiclePunishmentBoxBoo == undefined ? false : model.vehiclePunishmentBoxBoo
            }
            if (model.desserts[i].invoiceInfo?.vehicle != null ) {
              model.desserts[i].invoiceInfo.vehicle = vehicleObj
              setObj["t_invoice"].updateObj["info"] = model.desserts[i].invoiceInfo
            }
            else{
              if (model.desserts[i].invoiceInfo == undefined) {
                model.desserts[i].invoiceInfo = {}
              }
              model.desserts[i].invoiceInfo.vehicle = vehicleObj;
              setObj["t_invoice"].updateObj["info"] = model.desserts[i].invoiceInfo
            }
          }
          setObj["t_invoice"].updateObj["reviewStatus"] =  Enum.reviewTypeStatus[that.$route.meta.menu] 
          setObj["t_invoice"].updateId.push(model.desserts[i].invoiceId)
          setObj["t_invoice"].updateArray.push(setObj["t_invoice"].updateObj)
          setObj["t_invoice"].tableName.push("t_invoice")
          setObj["t_invoice"].updateObj = {};

          if (model.desserts[i].vehicleBillDayId != null && model.eInvoiceType == 1) { //fatura türü sadece araç ise bu çalışacak.
            setObj["t_vehicle_bill_day"].updateId.push(model.desserts[i].vehicleBillDayId)
            setObj["t_vehicle_bill_day"].updateObj["vehicleBillDay"] = model.vehicleBillDay
            setObj["t_vehicle_bill_day"].updateArray.push(setObj["t_vehicle_bill_day"].updateObj)
            setObj["t_vehicle_bill_day"].tableName.push("t_vehicle_bill_day")
            setObj["t_vehicle_bill_day"].updateObj = {};
          }
        }

        if (typeof model.rate == 'string') {
          model.rate = model.rate.replaceAll(".", "")
          model.rate = parseFloat(model.rate.replaceAll(",", "."))
        }
        if (model.eInvoiceType == 1 && model.desserts[i].notes != null ) {
          model.desserts[i].notes.numberOfDaysRented = model.desserts[i].numberOfDaysRented  //for vehicle numberOfRentDay
        }
        
        for (let j = 0; j < codesProperty.length; j++) {
          if (typeof model.desserts[i][codesProperty[j]] == "object" && model.desserts[i][codesProperty[j]] != null) {
            let str = config.accountInvoiceDetail[config.accountPlanTypesVue[codesProperty[j]]] + "InvoiceDetailId"
            if (str.includes("Withhol")) {
              if (model.desserts[i].kind == 1 && str.includes("Sales")) {
                continue;
              }else if (model.desserts[i].kind == 0 && str.includes("Purchase")) {
                continue;
              }
            }
            if (model.desserts[i][str] == null) {
              if (model.desserts[i].vatRate == 0 && codesProperty[j] == 'accountPlanVatCode' && model.desserts[i][codesProperty[j]] == null) {
                continue
              }
              accountInvoicesInsertArray.push({
                id: null,
                accountPlanId: model.desserts[i][codesProperty[j]]["id"],
                einvoiceId: model.desserts[i].einId,
                einvoiceIndex: i,
                type: config.accountPlanTypesVue[codesProperty[j]],
                uniqCode: parseFloat(model.desserts[i]["totalPrice"]) + "_" + model.desserts[i].vatRate + "_" + model.desserts[i].vat // özel
              })
            } else {
              let id = model.desserts[i][str]
              setObj["t_account_invoice_detail"].updateObj["accountPlanId"] = model.desserts[i][codesProperty[j]]["id"]
              setObj["t_account_invoice_detail"].updateObj["type"] = config.accountPlanTypesVue[codesProperty[j]]
              setObj["t_account_invoice_detail"].updateId.push(id)
              setObj["t_account_invoice_detail"].updateArray.push(setObj["t_account_invoice_detail"].updateObj)
              setObj["t_account_invoice_detail"].tableName.push("t_account_invoice_detail")
              setObj["t_account_invoice_detail"].updateObj = {};
            }
          } else if (codesProperty[j] == "budget") { /// özel
            if (model.desserts[i][codesProperty[j]] != null) {
              model.desserts[i][codesProperty[j]] = String(model.desserts[i][codesProperty[j]]);
              if (model.desserts[i][codesProperty[j]].includes(',')) {
                setObj["t_einvoice_transaction_detail"].updateObj[config.codesPropertyId[codesProperty[j]]] = parseFloat(model.desserts[i][codesProperty[j]].split(".").join("").replace(",", "."));
              } else {
                setObj["t_einvoice_transaction_detail"].updateObj[config.codesPropertyId[codesProperty[j]]] = parseFloat(model.desserts[i][codesProperty[j]]);
              }
              setObj["t_einvoice_transaction_detail"].updateId.push(model.desserts[i].einvoiceTransactionDetailId)
              setObj["t_einvoice_transaction_detail"].updateArray.push(setObj["t_einvoice_transaction_detail"].updateObj)
              setObj["t_einvoice_transaction_detail"].tableName.push("t_einvoice_transaction_detail")
              setObj["t_einvoice_transaction_detail"].updateObj = {};
            }
          }
        }
        setObj["t_invoice_transaction"].updateId.push(model.desserts[i].id)
        setObj["t_invoice_transaction"].updateArray.push(setObj["t_invoice_transaction"].updateObj)
        setObj["t_invoice_transaction"].tableName.push("t_invoice_transaction")
        setObj["t_invoice_transaction"].updateObj = {};
        setObj["t_einvoice_transaction_detail"].updateId.push(model.desserts[i].einvoiceTransactionDetailId)
        setObj["t_einvoice_transaction_detail"].updateArray.push(setObj["t_einvoice_transaction_detail"].updateObj)
        setObj["t_einvoice_transaction_detail"].tableName.push("t_einvoice_transaction_detail")
        setObj["t_einvoice_transaction_detail"].updateObj = {};
        
      }
      // model.desserts[0].vehicleBillDay = '99'
      if (freeSpaceCheck == true) {
        sendRequest(
          Enum.requestType.POST,
          "addSingleAccountPlanForEInvoice",
          "",
          {
            sameAccountTransferFlag: that.$store.state.sameAccountTransfer[model.tinNumber],
            tableNames: tableName,
            data: model.desserts,
            updatedId: updatedId,
            updatedArray: setObj,
            insertObj: {
              accountInvoicesInsertArray, insertTableName
            },
            declarationInsert,
            selectedRowDetail: model,
            //accountingProgram: this.$store.state.erpInformation.choiceERP
            accountingProgram: "false"
          },
          function (response) {
            openSnackbar("success", "Verileriniz başarılı bir şekilde güncellendi.", 2500);
            if (val == "next") {
              that.switchData("right", 'saved', model)
              openTablePage(that.openTableName)
            } else {
              let afterGetPlanThenFunc = function (model) {
                that.accountComponent.props.inputElementAuto.dropDownItems = model.accountCodes
                that.initialModel1.accountCodes = model.accountCodes
                that.dialogClose();
                let thenAfterSaveThenFunc = function (dynamicElements, model) {
                  dynamicElements.selectedRows = []
                  hideLoader()
                }
                openTablePage(that.openTableName, undefined, thenAfterSaveThenFunc);
              }
              getPlans(model, afterGetPlanThenFunc);
            }
          },
          function (error) {
            openSnackbar("error", "Hata Oluştu", 5000);
            hideLoader()
          }
        );
      }
      else {
        openSnackbar("error", "Tüm alanların doldurulması zorunludur.", 2500);
        hideLoader()
      }
    },

    dialogClose() {
      this.detailDialogModel = false; //detail dialogu kapatır
      this.rightRenderKey++
    },

    switchData(val, type, model) {
      showLoader("Lütfen Bekleyiniz")
      let that = this
      let index = that.tableElements.data.findIndex(object => {
        return object.uuId === model.uuId;
      });
      let notRefreshFlag = true
      that.detailDialogModel = false;

      if (val == "right") {
        if (index == that.tableElements.data.length - 1) { // son elemandasın demek.
          notRefreshFlag = false
          if (that.model[that.openTableName].currentPageNo != that.model[that.openTableName].pagesCount) {
            that.model[that.openTableName].currentPageNo = that.model[that.openTableName].currentPageNo + 1
            let onbeforeSaveFunc = function (dynamicElements, model3) {
              let model = dynamicElements.data[0]
              model.expanded = true
              that.selectedRowsDataBillNo = model.billNo
              that.detailDialogModel = true;
              model = JSON.parse(JSON.stringify(model));

              //    return that.getBillDetail(model.uuId, that.getBillArrayThenFunc, errFunc, model);
              let getUblThenFunc = function (params) {
                let thenFunc = function () {
                  return that.getBillDetail(model.uuId, that.getBillArrayThenFunc, errFunc, model);
                }
                return that.getUbl(model, thenFunc, errFunc)
              }
              let errFunc = function (err) {
              };
              return getUblThenFunc();
            }
            openTablePage(that.openTableName, undefined, onbeforeSaveFunc)
            openSnackbar("orange darken-1", "Diğer Sayfaya Geçtiniz.", 2000)
          } else {
            if (type == 'saved') {
              openSnackbar("orange darken-1", "Belgeniz Kaydedildi ve Son Belgeyi Kontrol Ettiniz.", 2000)
            } else {
              openSnackbar("orange darken-1", "Son Belgeyi Kontrol Ettiniz.", 2000)
            }
            hideLoader()
          }
        } else {
          index = index + 1
        }
      } else if (val == "left") {
        if (index == 0) { // ilk elemandasın demek.
          notRefreshFlag = false
          openSnackbar("orange darken-1", "Birinci Belgedesiniz.", 2000)
          hideLoader()
        } else {
          index = index - 1
        }
      }
      // sayfa sonu için son belgeye geldiniz uyarısı için.
      if (notRefreshFlag) {
        that.dialogClose()
        for (let i = 0; i < that.tableElements.data.length; i++) {
          that.tableElements.data[i].expanded = false
        }
        that.selectedRowsDataBillNo = that.tableElements.data[index].billNo
        that.tableElements.forceRender() //that.tableElements.renderKey++
        let tableIndexDate = JSON.parse(JSON.stringify(that.tableElements.data[index]));
        model = tableIndexDate
        model.desserts = [tableIndexDate]
        that.detailDialogModel = true;

        let getUblThenFunc = function (params) {
          let thenFunc = function () {
            return that.getBillDetail(model.uuId, that.getBillArrayThenFunc, errFunc, model);
          }
          return that.getUbl(model, thenFunc, errFunc)
        }
        let errFunc = function (err) {
        };
        return getUblThenFunc();
      }
    },

    changeInvoiceType(model) {
      this.accountComponent.inputs[2].showInput = model.kind === 1 && model.eInvoiceType === 4;
      this.accountComponent.specialInputs[0].elements.showInput = model.eInvoiceType == 1;
      this.accountComponent.inputs[3].showInput = model.eInvoiceType == 1;
      this.accountComponent.inputs[4].showInput = model.eInvoiceType == 1;
      this.accountComponent.inputs[5].showInput = model.eInvoiceType == 1;
      this.accountComponent.inputs[6].showInput = model.eInvoiceType == 2;

      this.setInitialHeaders(model);
      model.exportVat = false;
      model.withholding = false;
      model.contact = false;
      model.vehicleRestriction = false;

      if (model.eInvoiceType === 2) {
        model.withholding = true;
      } else if (model.eInvoiceType === 1) {
        model.vehicleRestriction = true;
      } else if (model.eInvoiceType === 6) {
        model.contact = true;
      } else if ([7, 4, 11].includes(model.eInvoiceType)) {
        this.setWithoutVatHeaders(model);
        if (model.eInvoiceType == 11) {
          this.getDeclarationNo(model)
        }
      } else if (model.eInvoiceType === 5) {
        if (model.kind == 0) { model.exportVat = true; }
      }
      this.rightRenderKey++;
      this.leftRenderKey++;
    },

    setInitialHeaders(model) {
      let numberOfDaysRented = "", index = "";
      //invoice type 7-4-11 e geçtikten sonra diğer invoice typelara geçerken specificModel.headersı specialHeadersa tekrar setlemek için kullanılır.
      if (this.$store.state.logoErpAndReciptKeyState) {
      //kiralanan gün sayısı sutununu eklemek için yazıldı. 
        let numberOfDaysRentedFlag = false;
        for (let i = 0; i < eBillsHeaders.specialLogoHeaders.length; i++) {
          if (eBillsHeaders.specialLogoHeaders[i].text == "Kiralanan Gün Sayısı") {
            numberOfDaysRentedFlag = true;
            index = i
          }
        }
        if(model.eInvoiceType == 1 && numberOfDaysRentedFlag == false){
          numberOfDaysRented = {
            "text": "Kiralanan Gün Sayısı",
            "align": "center",
            "value": "numberOfDaysRented",
            "style": "padding-left:0px;padding-right:0px;",
            "width": "15",
            "sortable": false,
            "divider": true,
            "dataType": "input"
          }
          eBillsHeaders.specialLogoHeaders.push(numberOfDaysRented)
        }
        else if(model.eInvoiceType != 1){
          if (numberOfDaysRentedFlag == true) {
            eBillsHeaders.specialLogoHeaders.splice(index, 1); // eğerki araç faturasını değiştirdiyse header içinden silmek için yazıldı.
          }
        }
        model.headers = eBillsHeaders.specialLogoHeaders 
      } 
      else { model.headers = eBillsHeaders.specialHeadersOLDVIEW }  //ORTAK

      // let vatRateHeaderObj = {
      //     text: "KDV Oranı %",
      //     align: "center",
      //     value: "vatRate",
      //     style: "padding-left:0px;padding-right:0px;font-weight:800;color:#294060",
      //     width: "40",
      //     sortable: false,
      //     divider: true,
      //     dataType: "onlyText",
      //   }

      //   let strHeaders = JSON.stringify(model.headers)
      //   let strHeader = JSON.stringify(vatRateHeaderObj)

    },
    setWithoutVatHeaders(model) {
      if (this.$store.state.logoErpAndReciptKeyState) { model.headers = eBillsHeaders.headerWithoutVatLogo; }
      else { model.headers = eBillsHeaders.headerWithoutVat; }
    },

    getUbl(model, thenFunc, errFunc) {
      let that = this
      sendRequest(Enum.requestType.POST, "getUbl", "", { fileName: model.fileName },
        function (response) {
          model.xsltString = response.data.data.xsltString
          model.xmlString = response.data.data.ublString
          thenFunc([])
        },
        function (error) {
          errFunc(error);
        }
      )
    },
    tdSaveFunc(model) {
      showLoader("Lütfen Bekleyiniz...")
      let that = this
      let setObj = {
        "t_invoice_transaction": {
          updateObj: {},
          updateArray: [],
          updateId: [],
          tableName: [],
        },
        "t_invoice": {
          updateObj: {},
          updateArray: [],
          updateId: [],
          tableName: [],
        },
        "t_product": {
          updateObj: {},
          updateArray: [],
          updateId: [],
          tableName: [],
        },
      }
      let einvoiceInsertArray = []
      for (let i = 0; i < model.desserts.length; i++) {
        if(!['0', '1', '8', '10', '18', '20'].includes(model.desserts[i]["vatRate"].toString())){
          hideLoader()
          return openSnackbar("error", "Geçerli Bir Oran Giriniz")
        }
        if (model.desserts[i].invoiceTransactionId == null) {
          // invoiceId ve companyId ve isActive eklenecek.
          let withoutDots = model.desserts[i].totalPrice.replace(/\./g, '');
          let convertedNumber = withoutDots.replace(',', '.');
          einvoiceInsertArray.push({
            price : model.desserts[i].price,
            amount : model.desserts[i]["productAmount"],
            vatRate : model.desserts[i].vatRate,
            vat : model.desserts[i].vat,
            crudDetail : model.desserts[i].crudDetail,
            productId: model.desserts[i].productId,
            totalPrice: convertedNumber,
          })
          continue;
        }
        setObj["t_invoice_transaction"].updateObj["vatRate"] = model.desserts[i]["vatRate"]
        setObj["t_invoice_transaction"].updateObj["vat"] = model.desserts[i]["vat"]
        setObj["t_invoice_transaction"].updateObj["amount"] = model.desserts[i]["productAmount"]

        setObj["t_invoice_transaction"].updateObj["price"] = model.desserts[i]["price"]

        let totalPrice = model.desserts[i]["totalPrice"].toString();
        // Eğer sadece nokta varsa işlem yapma //yani currency format uygulanmadıysa
        if ((totalPrice.indexOf('.') === -1 && totalPrice.indexOf(',') !== -1) || (totalPrice.indexOf('.') !== -1 && totalPrice.indexOf(',') !== -1)) {
          let withoutDots = totalPrice.replace(/\./g, '');
          let convertedNumber = withoutDots.replace(',', '.');
          setObj["t_invoice_transaction"].updateObj["totalPrice"] = convertedNumber
        } else {
          setObj["t_invoice_transaction"].updateObj["totalPrice"] = model.desserts[i]["totalPrice"]}
        setObj["t_invoice_transaction"].updateId.push(model.desserts[i].id)
        setObj["t_invoice_transaction"].tableName.push("t_invoice_transaction")
        setObj["t_invoice_transaction"].updateArray.push(setObj["t_invoice_transaction"].updateObj)
        setObj["t_invoice_transaction"].updateObj = {};


        setObj["t_product"].updateObj["unit"] = model.desserts[i]["unit"]
        setObj["t_product"].updateId.push(model.desserts[i].original2ProductId)
        setObj["t_product"].tableName.push("t_product")
        setObj["t_product"].updateArray.push(setObj["t_product"].updateObj)
        setObj["t_product"].updateObj = {};


        if (i == 0) {
          setObj["t_invoice"].updateObj["billNo"] = model.desserts[i]["billNo"]
          setObj["t_invoice"].updateObj["billDate"] = model.desserts[i]["billDate"]
          setObj["t_invoice"].updateId.push(model.desserts[i].invoiceId)
          setObj["t_invoice"].tableName.push("t_invoice")
          setObj["t_invoice"].updateArray.push(setObj["t_invoice"].updateObj)
          setObj["t_invoice"].updateObj = {};
        }
      }

      let insertTableName = ""
      let accountInvoicesInsertArray = []

      sendRequest(
        Enum.requestType.POST,
        "addSingleAccountPlanForReceipt",
        "",
        {
          setObj,
          insertObj: {
            accountInvoicesInsertArray, insertTableName,
            addNewRowInsertObj: {
              einvoiceInsertArray, addNewRowInsertTableName: "t_invoice_transaction"
            }
          },
          selectedRowDetail: model
        },
        function (response) {
          openSnackbar("success", "Verileriniz başarılı bir şekilde güncellendi.", 1250);
          model.billNo = model.desserts[0].billNo
          model.billDate = model.desserts[0].billDate
          openTablePage(that.openTableName);
          hideLoader()
        },
        function (error) {
          openSnackbar("error", "Kayıt İşlemi Sırasında Hata Oluştu.", 2500);
          hideLoader()
        }
      );
    },
    nextPageShortCut(event) {
      if (event.key === 'F2') {
        this.switchData('right', undefined, this.model1)
      } else if (event.key === 'F4') {
        this.saveInvoiceAssignment('next', this.model1)
      }
    },

    periodsCompareFunc () {
      let that = this
      if (this.selectedPeriodCompare.length == 0) {
        alert("Lütfen Dönem Seçiniz")
      }else{
        showLoader("Dosyanız Hazırlanıyor.Lütfen Bekleyiniz.")
        sendRequest(Enum.requestType.POST,"compareEbillsAndMuavinQueueProcess","",
          {
            selectedPeriod: this.selectedPeriodCompare,
            choice : "ebillAndMuavinTable",
            cookieData: window.$cookies.get("selectedItem"),
            selectedKindItems : this.selectInvoiceKind,
            selectedInvoiceTypeItems : this.selectInvoiceTypes,
          },
          function (response) {
            hideLoader()
            openSnackbar("success", response.data.data.value);
            that.$router.push("folderNotification")
            that.selectPeriodForCompareDialog = false
          },
          function (error) {
            openSnackbar(
              "error",
              error.response.data.error[0].detail.message
            );
            hideLoader();
          }
        )
      }
    },
    getDataBeetwenDate(model, openSnackbar, openTablePage, dynamicElements) {
      debugger
      if (model.getDataWithDateStarted != "" && (model.getDataWithDateEnd != "" && model.getDataWithDateEnd != null)) {
        // 2022-02-26 00:00:00+03
        // 2022-02-26 23:59:00+03
        let resultBetweenDynamicElements = this.betweenDateFunc(model.getDataWithDateStarted, model.getDataWithDateEnd, dynamicElements)
        return resultBetweenDynamicElements
      } else {
        openSnackbar("error", "İki Tarih Arası Giriniz", 3000);
        return false;
      }
    },
    betweenDateFunc(startDate, endDate, dynamicElements) {
      let startDateSplit = startDate.split("/")
      startDateSplit = startDateSplit[2] + "-" + startDateSplit[1] + "-" + startDateSplit[0] + " 00:00:00+03"
      let endDateSplit = endDate.split("/")
      endDateSplit = endDateSplit[2] + "-" + endDateSplit[1] + "-" + endDateSplit[0] + " 23:59:00+03"
      let billDateFlag = true
      for (let i = 0; i < dynamicElements?.initialFilter?.and?.length; i++) {
        let filterElements = dynamicElements.initialFilter.and[i];
        if (filterElements.bill_date != undefined) {
          billDateFlag = false;
          dynamicElements.initialFilter.and[i] = { /// createdtaki initialFilter çalışmadığı için bu şekilde.
            bill_date: {
              [Enum.initialFilter.BETWEEN]: [startDateSplit, endDateSplit]
            }
          }
        }
      }
      if (billDateFlag) {
        dynamicElements.initialFilter.and.push({ /// createdtaki initialFilter çalışmadığı için bu şekilde.
          bill_date: {
            [Enum.initialFilter.BETWEEN]: [startDateSplit, endDateSplit]
          }
        })
      }
      return dynamicElements
    }
  },
  mounted() {
    window.addEventListener('keydown', this.nextPageShortCut);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.nextPageShortCut);
  },
};
</script>

<style scoped>
.detailDialogCard {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}
::v-deep .v-text-field--outlined.v-input--dense.v-text-field--single-line > .v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot{
  min-height: 0px;
  height: 25px;
}
::v-deep .v-chip {
  overflow: unset;
  white-space: unset;
  height: unset;
}
</style>