<template>
    <table-component :dynamic-element="dynamicElements" :model="model"/>
</template>
<script>
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json"
import {hashCode} from '../../../frontend/components/atoms/helper/elementHelper.js'
export default {
    components: { 'table-component' : tableComponent  },
    data() {
        return {
            dynamicElements:{},
            model:{},
            renderKey : hashCode(),
            filters : {}
        };
    },

    created(){
      this.loadData();
    },

    methods :{
      loadData(){
        this.model = {
            filters: {
              fat:'',
              carbs:'',
              protein:'',
              iron:''
            },
            data : [ 
          {
            id:0,
            fat: 11111.0,
            description:"dertt",
            carbs: 24,
            protein: 4.0,
            iron: '5%',
          },
          {
            id:1,
            fat: 9.0,
            description:"ders",
            carbs: 37,
            protein: 4.3,
            iron: '1%',
          },
          {
            id:2,
            fat: 6.0,
            description:"denk",
            carbs: 24,
            protein: 4.0,
            iron: '1%',
          },
          {
            id:3,
            fat: 9.0,
            carbs: 37,
            description:"def",
            protein: 4.3,
            iron: '1%',
          },
          {
            id:4,
            fat: 6.0,
            description:"zz",
            carbs: 24,
            protein: 4.0,
            iron: '1%',
          },
          {
            id:5,
            fat: 9.0,
            carbs: 37,
            description:"aa",
            protein: 4.3,
            iron: '1%',
          },
          {
            id:6,
            fat: 6.0,
            carbs: 24,
            protein: 4.0,
            description:"bb",
            iron: '1%',
          },
          {
            id:7,
            fat: 9.0,
            carbs: 37,
            description:"cc",
            protein: 4.3,
            iron: '1%',
          },
          {
            id:8,
            fat: 6.0,
            carbs: 24,
            description:"def",
            protein: 4.0,
            iron: '1%',
          },
          {
            id:9,
            fat: 229.0,
            carbs: 37,
            description:"def",
            protein: 4.3,
            iron: '1%',
          },
          {
            id:10,
            fat: 6.0,
            carbs: 24,
            description:"def",
            protein: 4.0,
            iron: '1%',
          },
          {
            id:11,
            fat: 912321.0,
            carbs: 37,
            description:"def",
            protein: 4.3,
            iron: '1%',
          }
            ],
        }
        this.dynamicElements = {
          selectedRows : [],
          sortBy: [],
            sortDesc: [false],
            renderKey:0,
            type : "table",
            name : "assistantTable",
            class : "",
            hidePagination : false,
            toolbars : [
              {
                type : Enum.layoutType.div,
                name : "toolbarsForm"
              }
            ],
            headers : [
              { text: 'Fat (g)', value: 'fat'},
              { text: 'Carbs (g)', value: 'carbs'},
              { text: 'Protein (g)', value: 'protein'},
              { text: 'Iron (%)', value: 'iron' },
            ],
            showDialogColumnsCount : 6,
            columns : {},
            data :"data",
            //showSelecetVisible : true,
            singleSelect:false,
            itemKey: "id",
        }
      }
    }
}
</script>