<template>
    <v-row justify="center" :key="dynamicElement.renderKey">
        <v-dialog
          v-model="dynamicElement.dialogVisible"
          persistent
          :max-width="dynamicElement.dialogInfo.maxWidth"
          :width="dynamicElement.dialogInfo.width"
          :height="dynamicElement.dialogInfo.height"
          :name="dynamicElement.dialogInfo.name"
        >
          <v-card>
            <v-card-title class="scrollthis">
              {{dynamicElement.dialogInfo.title}}
              <v-spacer></v-spacer>
              <div  v-for="(action,j) in dynamicElement.dialogInfo.titleActions" :key="j" :style="action.divStyle" >
                <input-component :input-element="action" :model="model" :dynamic-elements="dynamicElement"></input-component>
              </div>
            </v-card-title>
            <v-card-text class="scrollthis">
                <layout :dynamic-elements="dynamicElement" :layout-elements="dynamicElement.dialogInfo.layout" :model="model"/>
            </v-card-text>
            <v-card-actions v-if="dynamicElement.dialogInfo.actions.length > 0"> 
              <v-spacer></v-spacer>
                <div  v-for="(action,j) in dynamicElement.dialogInfo.actions" :key="j" :style="action.divStyle">
                  <input-component :input-element="action" :model="model" :dynamic-elements="dynamicElement"></input-component>
                </div>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import inputComponent from "../input/inputComponent.vue";
import {hashCode} from "../../atoms/helper/elementHelper.js"
export default {
    components: { 
      "layout": () => import("./layout.vue"),
      'input-component' : inputComponent 
    },
    props: {
      dynamicElement:{},
      model:{}
    },
    data: () => ({
      renderKey : hashCode()
    }),
    created() {
      this.modalControl()
      //TODO NEDENİ BİLİNMİYOR DİALOG RENDERLANMASI İÇİN
      this.$store.state.currentDialog = this.dynamicElement;
    },
    mounted() {
      this.$nextTick(() => {
        let dialogs = document.getElementsByClassName('v-dialog');
        for (let i = 0; i < dialogs.length; i++) {
          if (dialogs[i].subTreeAssigned == undefined) {
            dialogs[i].addEventListener('DOMSubtreeModified',
            function() {
              let tableContent = this.getElementsByClassName('v-data-table__wrapper')
              for (let j = 0; j < tableContent.length; j++) {
                tableContent[j].style.width = (this.clientWidth - parseInt(this.clientWidth/90)) + 'px'
              }  //BESTE BURASI DİMRODA ARŞİVE EKLE VEYA SİL BUTONLARIN YANİ ALT YAPIDAN DİALOG AÇTIĞIMIZ BUTONLARDA BAŞKA DİALOGLARIN STYLENI ETKLİYOR
            })
          }
          dialogs[i].subTreeAssigned = true;
        }
      })
    },
    methods:{
      modalControl(){
        if (!Object.keys(this.dynamicElement).includes('dialogInfo')) {
          this.dynamicElement.dialogVisible = false
          this.dynamicElement.dialogInfo = {
            layout : [],
            actions : []
           }
        }
      },
    }
}
</script>
<style scoped>
.scrollthis{
  overflow-x: auto;
}
</style>