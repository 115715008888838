import { Line } from './BaseCharts.js'

export default {
    extends: Line,
    props: {
        chartObj : { }
    },
    mounted () {
        let vm = this;
        this.renderChart({
          labels: vm.chartObj.labels,
          datasets: vm.chartObj.datasets
        }, {responsive: true, maintainAspectRatio: false})
    }
}