import VueRouter from 'vue-router';
import Vue from 'vue';
import login from '../organisms/login.vue';
import forgotten from '../organisms/forgotten.vue';
import loginPage from '../organisms/loginPage.vue';
import landing from '../organisms/landing.vue';
import dialogComponent from '../../../frontend/components/atoms/container/dialogComponent.vue';
import assistantTable from '../organisms/assistantTable.vue';
import testTable from '../organisms/testTable.vue';
import testTable2 from '../organisms/testTable2.vue';
import test2 from '../organisms/testUpload.vue';
import chartExample from '../organisms/chartExample.vue';
import gridTest from '../organisms/gridTest.vue';
import uploadVatOneDeclaration from '../organisms/uploadVatOneDeclaration'
import stampDutyDeclaration from '../organisms/stampDutyDeclaration'
import depreciation from '../organisms/depreciation.vue'
import mizan from '../organisms/mizan.vue'
import mizanView from "../organisms/mizanView.vue"
import createProject from '../organisms/createProject.vue'
import tufe from '../organisms/tufe.vue'
import uploadVatTwoDeclaration from '../organisms/uploadVatTwoDeclaration'
import uploadDeclarationConcise from '../organisms/uploadDeclarationConcise'
import uploadSgkAccrualDeclaration from '../organisms/uploadSgkAccrualDeclaration'
import settings from '../organisms/settings'
import declarationDefine from '../organisms/declarationDefine'
import amortismanUpload from '../organisms/amortismanUpload'
import inflationAccountingTrialBalance from '../organisms/inflationAccountingTrialBalance'
import inflationAccountingReporting from '../organisms/inflationAccountingReporting'
import deferredTax from '../organisms/deferredTax'
import inflationDepreciation from '../organisms/inflationDepreciation'
import folderNotification from '../organisms/folderNotification.vue'
import incomeStatement from "../organisms/incomeStatement.vue"
import financialStatement from "../organisms/financialStatement.vue"
import interestNumber from "../organisms/interestNumber"
import aging from "../organisms/aging"
import fundingConstraints from "../organisms/fundingConstraints"
import deficitBalance from "../organisms/deficitBalance"
import reverseEntry from "../organisms/reverseEntry"
import uploadEInvoice from "../organisms/uploadEInvoice"
import ebillUpload from "../organisms/ebillUpload"
import muavinComparison from "../organisms/muavinComparison"
import muavinAndEbillComparison from "../organisms/muavinAndEbillComparison"
import taxCalculation from "../organisms/taxCalculation"
import financialLossCalculation from "../organisms/financialLossCalculation"
import capitalCashIncreaseCalculation from "../organisms/capitalCashIncreaseCalculation"
import hiddenCapital from "../organisms/hiddenCapital"
import rentCalculation from "../organisms/rentCalculation"
import accruedFee from "../organisms/accruedFee"
import currencyImpact from "../organisms/currencyImpact"
import insurance from "../organisms/insurance"
import kkeg from "../organisms/kkeg"
import credit from "../organisms/credit"
import depreciationDidero from "../organisms/depreciationDidero"
import insuranceCalculation from "../organisms/insuranceCalculation"
import rent from "../organisms/rent"
import capitalCashIncrease from "../organisms/capitalCashIncrease"
import insurance2 from "../organisms/insurance2"
import depreciationInsert from "../organisms/depreciationInsert"
import hiddenCapitalUpdate from "../organisms/hiddenCapitalUpdate"
import fundingConstraintsUpdate from "../organisms/fundingConstraintsUpdate"
import insuranceUpdate from "../organisms/insuranceUpdate"
import financialLossUpdate from "../organisms/financialLossUpdate"
import CreatePassword from '@/organisms/createPass';
import creditCalculation from '../organisms/creditCalculation'
import jetTest from '../organisms/jetTest'
import manuelBotTable from '../organisms/manuelBotTable.vue'
import findReceipt from "../organisms/findReceipt"
import previousPeriodMizan from "../organisms/previousPeriodMizan"
import eBillTable from "../organisms/eBillTable"
import staticAccounts from "../organisms/staticAccounts"
import eBillsTest from "../organisms/eBillsTest"
import eBillsUltraTest from "../organisms/eBillsUltraTest"

Vue.use(VueRouter)
const routes = [
  { path: '/login', component: login, meta: { requiresAuth: true }},
  { path: '/forgotten', component: forgotten },
  { path: '/loginPage', component: loginPage},
  {path: '/createpass/:hash',name: 'createpass',component: CreatePassword,meta: { }},
  { path: '/landing', component: landing, meta: { requiresAuth: false } },
  { path: '/assistanttable', component: assistantTable, meta: { requiresAuth: true } },
  { path: '/declarationdefine', component: declarationDefine, meta: { requiresAuth: true } },
  { path: '/dialogComponent', component: dialogComponent, meta: { requiresAuth: true } },
  { path: '/testTable', component: testTable, meta: { requiresAuth: false } },
  { path: '/testTable2', component: testTable2, meta: { requiresAuth: false } },
  { path: '/uploadVatOneDeclaration', component: uploadVatOneDeclaration, meta: { requiresAuth: true } },
  { path: '/ebillUpload', component: ebillUpload, meta: { requiresAuth: true } },
  { path: '/uploadVatTwoDeclaration', component: uploadVatTwoDeclaration, meta: { requiresAuth: true } },
  { path: '/uploadStampDutyDeclaration', component: stampDutyDeclaration, meta: { requiresAuth: true } },
  { path: '/amortismanUpload', component: amortismanUpload, meta: { requiresAuth: true } },
  { path: '/depreciation', component: depreciation, meta: { requiresAuth: true } },
  { path: '/mizan', component: mizan, meta: { requiresAuth: true } },
  { path: '/mizanView', component: mizanView, meta: { requiresAuth: true } },
  { path: '/createProject', component: createProject, meta: { requiresAuth: true } },
  { path: '/tufe', component: tufe, meta: { requiresAuth: true } },
  { path: '/uploadConciseDeclaration', component: uploadDeclarationConcise, meta: { requiresAuth: true } },
  { path: '/uploadSgkAccrualDeclaration', component: uploadSgkAccrualDeclaration, meta: { requiresAuth: true } },
  { path: '/settings', component: settings, meta: { requiresAuth: true } },
  { path: '/uploadTest', component: test2, meta: { requiresAuth: true } }, // uploadTest
  { path: '/chartExample', component: chartExample, meta: { requiresAuth: true } },
  { path: '/gridTest', component: gridTest, meta: { requiresAuth: true } },
  { path: '/inflationAccountingTrialBalance', component: inflationAccountingTrialBalance, meta: { requiresAuth: true } },
  { path: '/inflationAccountingReporting', component: inflationAccountingReporting, meta: { requiresAuth: true } },
  { path: '/inflationDepreciation', component: inflationDepreciation, meta: { requiresAuth: true } },
  { path: '/deferredTax', component: deferredTax, meta: { requiresAuth: true } },
  { path: '/folderNotification', component: folderNotification, meta: { requiresAuth: true } },
  { path: "/incomeStatement", component: incomeStatement, meta: { requiresAuth: true } },
  { path: "/financialStatement", component: financialStatement, meta: { requiresAuth: true } },
  { path: "/interestNumber", component: interestNumber, meta: {requiresAuth: true} },
  { path: "/aging", component: aging, meta: {requiresAuth: true} },
  { path: "/fundingConstraints", component: fundingConstraints, meta: {requiresAuth: true} },
  { path: "/deficitBalance", component: deficitBalance, meta: {requiresAuth: true} },
  { path: "/reverseEntry", component: reverseEntry, meta: {requiresAuth: true} },
  { path: "/uploadEInvoice", component: uploadEInvoice, meta: {requiresAuth: true} },
  { path: "/muavinComparison", component: muavinComparison, meta: {requiresAuth: true} },
  { path: "/muavinAndEbillComparison", component: muavinAndEbillComparison, meta: {requiresAuth: true}},
  { path: "/financialLossCalculation", component: financialLossCalculation, meta: {requiresAuth: true} },
  { path: "/taxCalculation", component: taxCalculation, meta: {requiresAuth: true}},
  { path: "/capitalCashIncreaseCalculation", component: capitalCashIncreaseCalculation, meta: {requiresAuth: true}},
  { path: "/hiddenCapital", component: hiddenCapital, meta: {requiresAuth: true}},
  { path: "/rentCalculation", component: rentCalculation, meta: {requiresAuth: true}},
  { path: "/accruedFee", component: accruedFee, meta: {requiresAuth: true}},
  { path: "/currencyImpact", component: currencyImpact, meta: {requiresAuth: true}},
  { path: "/insurance", component: insurance, meta: {requiresAuth: true}},
  { path: "/kkeg", component: kkeg, meta: {requiresAuth: true}},
  { path: "/credit", component: credit, meta: {requiresAuth: true}},
  { path: "/depreciationDidero", component: depreciationDidero, meta: {requiresAuth: true}},
  { path: "/insuranceCalculation", component: insuranceCalculation, meta: {requiresAuth: true}},
  { path: "/rent", component: rent, meta: {requiresAuth: true}},
  { path: "/capitalCashIncrease", component: capitalCashIncrease, meta: {requiresAuth: true}},
  { path: "/insurance2", component: insurance2, meta: {requiresAuth: true}},
  { path: "/depreciationInsert", component: depreciationInsert, meta: {requiresAuth: true}},
  { path: "/hiddenCapitalUpdate", component: hiddenCapitalUpdate, meta: {requiresAuth: true}},
  { path: "/fundingConstraintsUpdate", component: fundingConstraintsUpdate, meta: {requiresAuth: true}},
  { path: "/insuranceUpdate", component: insuranceUpdate, meta: {requiresAuth: true}},
  { path: "/financialLossUpdate", component: financialLossUpdate, meta: {requiresAuth: true}},
  { path: "/creditCalculation", component: creditCalculation, meta: {requiresAuth: true}},
  { path: "/jetTest", component: jetTest, meta: {requiresAuth: true}},
  { path: "/manuelBotTable", component: manuelBotTable, meta: {requiresAuth: true}},
  { path: "/findReceipt", component: findReceipt, meta: {requiresAuth: true}},
  { path: "/previousPeriodMizan", component: previousPeriodMizan, meta: {requiresAuth: true}},
  { path: "/eBillTable", component: eBillTable, meta: {requiresAuth: true}},
  { path: "/staticAccounts", component: staticAccounts, meta: {requiresAuth: true}},
  { path: "/eBillsTest", component: eBillsTest, meta: {requiresAuth: true}},
  { path: "/eBillsUltraTest", component: eBillsUltraTest, meta: {requiresAuth: true, menu: 'update'}},
  { path: '/reviewedEBills', component: eBillsUltraTest, meta: { requiresAuth: true, menu: 'reviewed'} },
  { path: '/eBillsArchive', component: eBillsUltraTest, meta: { requiresAuth: true, menu: 'archived' } },
  
  { path: '*',  redirect: '/loginPage' }    
]

export const router = new VueRouter({
  mode: 'history',
  routes
})
