
<template>
  <div>
    <settings-component :dynamic-elements="dynamicElements" :model="model" />
  </div>
</template>
<script>
import settingsComponent from "../molecules/settingsComponent.vue";
// import { getElement } from "../../../frontend/components/atoms/helper/elementHelper.js";
// import { openSnackbar } from "../../../frontend/components/atoms/helper/elementHelper.js";
import { sendRequest } from "../../../frontend/components/atoms/helper/requestHelper.js";
// import store from "../store/index"
import {
  openSnackbar,
  getDivModel,
  getDiv,
  getTable,
hideLoader,
} from "../../../frontend/components/atoms/helper/elementHelper";
import Enum from "../../../frontend/components/Enum.json"
import { router } from "../router/router";

export default {
  watch: {
    
  },
  components: { "settings-component": settingsComponent },
  data: () => ({
    model : {},
    dynamicElements: [],
    kkegAccCodes: [],
    depAccountCodes: [],
    vehicleRestrictionValue: "",
    yearValue: "",
    ratioValue: "",
  }),
  created() {
    let that = this;
    this.loadData()
    this.querySettings()
    
  },
  methods: {
    querySettings(){
      sendRequest(Enum.requestType.POST,"querySettings","",{},
            function(response){
              // let yearContainer = getDiv("yearContainer");
              // let yearModel = getDivModel("yearContainer");
              let taxContainer = getDiv("taxRatioContainer");
              let taxModel = getDivModel("taxRatioContainer");
              let vehicleContainer = getDiv("vehicleRestrictionContainer");
              let vehicleModel = getDivModel("vehicleRestrictionContainer");
              taxModel.taxRatio = response.data.data.data[0].settings.didero.taxRatio
              //yearModel.year = response.data.data.data[0].settings.auditProject.year
              vehicleModel.vehicleRestriction = response.data.data.data[0].settings.didero.vehicleRestriction
              //yearContainer.forceRender()
              taxContainer.forceRender()
              vehicleContainer.forceRender()
            },
            function (error) {
              openSnackbar(
                "error",
                error.response.data.error[0].detail.message
              );
              hideLoader();
            }       
          )
    },
    loadData() {
      let that = this;
      this.model = {};
      this.dynamicElements = [

        [
          {
            style : "",
            type: Enum.layoutType.div,
            name: "passwordContainer",
            inputElements: [
              [
                {
                  id: "oldPassword",
                  type: "v-text-field",
                  variant: "password",
                  name: "oldPassword",
                  label: "Eski Şifrenizi Giriniz",
                  autocomplete: false,
                  value: "",
                  prependIcon: "lock",
                  placeHolder: "",
                  style: "width: 260px;",
                  classTag: "",
                },
                {
                  id: "newPassword",
                  type: "v-text-field",
                  variant: "password",
                  name: "newPassword",
                  label: "Yeni Şifrenizi Giriniz",
                  value: "",
                  prependIcon: "lock",
                  placeHolder: "",
                  style: "width: 260px;",
                  classTag: "",
                },
                {
                  id: "newPasswordAgain",
                  type: "v-text-field",
                  variant: "password",
                  name: "newPasswordAgain",
                  label: "Yeni Şifrenizi Tekrar Giriniz",
                  value: "",
                  prependIcon: "lock",
                  placeHolder: "",
                  style: "width: 260px;",
                  classTag: "",
                },
                {
                  id: "saveSettings",
                  type: "v-btn",
                  variant: "btn",
                  name: "saveSettings",
                  inputText: "Şifreyi Değiştir",
                  divStyle: "",
                  style: "height: 25px !important;margin-top: 25px;margin-right: 10px;",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function (thisData, model, dynamicElements) {
                    console.log(thisData);
                    console.log(model);
                    console.log(dynamicElements);
                    if (model.newPassword == model.newPasswordAgain) {
                      sendRequest(Enum.requestType.POST,"changePassword",null,
                        {
                          oldPassword: model.oldPassword,
                          newPassword: model.newPassword,
                        },
                        function (response) {
                          openSnackbar("success", "Şifre Değiştirildi");
                          console.log(response);
                        },
                        function (error) {
                          openSnackbar(
                            "error",
                            error.response.data.error[0].detail.message
                          );
                        }
                      );
                    } else {
                      openSnackbar("error", "Şifreler Eşleşmedi");
                    }
                  },
                },
              ],
            ],
          },
        ],
        [
          {
            style : "",
            type: Enum.layoutType.div,
            name: "yearContainer",
            inputElements: [
              [
                {
                  id: "year",
                  type: "v-text-field",
                  variant: "text",
                  name: "year",
                  label: "Yıl Giriniz",
                  autocomplete: false,
                  value: '',
                  prependIcon: "mdi-calendar",
                  placeHolder: "",
                  style: "width: 260px;",
                  classTag: "",
                },
                {
                  id: "yearSettings",
                  type: "v-btn",
                  variant: "btn",
                  name: "yearSettings",
                  inputText: "Kaydet",
                  divStyle: "",
                  style: "height: 25px !important;margin-top: 25px;margin-left: 10px;",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function (thisData, model, dynamicElements) {
                    sendRequest(Enum.requestType.POST,"addSettings",null,
                      {
                        year: model.year,
                        choice: "year"
                      },
                      function (response) {
                        openSnackbar("success", "Yıl Eklendi.");
                        console.log(response);
                      },
                      function (error) {
                        openSnackbar(
                          "error",
                          error.response.data.error[0].detail.message
                        );
                      }
                    );
                  },
                },
              ],
            ],
          },
        ],
        [
          {
            style : "",
            type: Enum.layoutType.div,
            name: "depreciationContainer",
            inputElements: [
              [
                {
                  id : "acc250",
                  type: "v-text-field",
                  variant : "text",
                  name : "acc250",
                  //label: "250 Hesap Kodu",
                  value: "250 Hesap Kodu",
                  style:"font-size: 20px; width: 150px",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  readonly: true,
                  //disabled: true
                },
                {
                  id : "items250",
                  name : "items250",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : true,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 50px; width: 550px; margin-rigth: 50px",
                  placeHolder: "Hesap Kodu 250",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "acc251",
                  type: "v-text-field",
                  variant : "text",
                  name : "acc251",
                  value: "251 Hesap Kodu",
                  style:"font-size: 20px; width: 150px",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  readonly: true
                },
                {
                  id : "items251",
                  name : "items251",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : true,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 550px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "acc252",
                  type: "v-text-field",
                  variant : "text",
                  name : "acc252",
                  value: "252 Hesap Kodu",
                  style:"font-size: 20px; width: 150px",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  readonly: true
                },
                {
                  id : "items252",
                  name : "items252",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : true,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 550px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "acc253",
                  type: "v-text-field",
                  variant : "text",
                  name : "acc253",
                  value: "253 Hesap Kodu",
                  style:"font-size: 20px; width: 150px",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  readonly: true
                },
                {
                  id : "items253",
                  name : "items253",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : true,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 550px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "acc254",
                  type: "v-text-field",
                  variant : "text",
                  name : "acc254",
                  value: "254 Hesap Kodu",
                  style:"font-size: 20px; width: 150px",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  readonly: true
                },
                {
                  id : "items254",
                  name : "items254",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : true,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 550px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "acc255",
                  type: "v-text-field",
                  variant : "text",
                  name : "acc255",
                  value: "255 Hesap Kodu",
                  style:"font-size: 20px; width: 150px",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  readonly: true
                },
                {
                  id : "items255",
                  name : "items255",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : true,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 550px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "acc260",
                  type: "v-text-field",
                  variant : "text",
                  name : "acc260",
                  value: "260 Hesap Kodu",
                  style:"font-size: 20px; width: 150px",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  readonly: true
                },
                {
                  id : "items260",
                  name : "items260",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : true,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 550px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "acc262",
                  type: "v-text-field",
                  variant : "text",
                  name : "acc262",
                  value: "262 Hesap Kodu",
                  style:"font-size: 20px; width: 150px",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  readonly: true
                },
                {
                  id : "items262",
                  name : "items262",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : true,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 550px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "acc263",
                  type: "v-text-field",
                  variant : "text",
                  name : "acc263",
                  value: "263 Hesap Kodu",
                  style:"font-size: 20px; width: 150px",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  readonly: true
                },
                {
                  id : "items263",
                  name : "items263",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : true,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 550px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "acc264",
                  type: "v-text-field",
                  variant : "text",
                  name : "acc264",
                  value: "264 Hesap Kodu",
                  style:"font-size: 20px; width: 150px",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  readonly: true
                },
                {
                  id : "items264",
                  name : "items264",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : true,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 550px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "acc267",
                  type: "v-text-field",
                  variant : "text",
                  name : "acc267",
                  value: "267 Hesap Kodu",
                  style:"font-size: 20px; width: 150px",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  readonly: true
                },
                {
                  id : "items267",
                  name : "items267",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : true,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 550px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id: "saveSettings",
                  type: "v-btn",
                  variant: "btn",
                  name: "saveSettings",
                  inputText: "Kaydet",
                  divStyle: "",
                  style: "height: 25px !important;margin-top: 25px;",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function (thisData, model, dynamicElements) {
                    sendRequest(Enum.requestType.POST,"addSettings",null,
                      {
                        depreciationAccountCodes: {
                          '250': model.items250,
                          '251': model.items251,
                          '252': model.items252,
                          '253': model.items253,
                          '254': model.items254,
                          '255': model.items255,
                          '260': model.items260,
                          '262': model.items262,
                          '263': model.items263,
                          '264': model.items264,
                          "267": model.items267,
                        },
                        choice: "depreciationAccountCodes"
                      },
                      function (response) {
                        openSnackbar("success", "Hesap Kodu Eklendi.");
                        console.log(response);
                        window.location.reload(true);
                      },
                      function (error) {
                        openSnackbar(
                          "error",
                          error.response.data.error[0].detail.message
                        );
                      }
                    );
                  },
                },
              ],
            ],
          },
        ],
        [
          {
            style : "",
            type: Enum.layoutType.div,
            name: "taxRatioContainer",
            inputElements: [
              [
                {
                  id: "taxRatio",
                  type: "v-text-field",
                  variant: "text",
                  name: "taxRatio",
                  label: "Kurumlar Vergisi Oranını Giriniz (%)",
                  autocomplete: false,
                  value: "",
                  placeHolder: "",
                  style: "width: 260px; margin-left: 15px;",
                  classTag: "",
                },
                {
                  id: "saveSettings",
                  type: "v-btn",
                  variant: "btn",
                  name: "saveSettings",
                  inputText: "Kaydet",
                  divStyle: "",
                  style: "height: 25px !important;margin-top: 15px;margin-left: 10px; text-align: center;",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function (thisData, model, dynamicElements) {
                    
                    sendRequest(Enum.requestType.POST,"addSettings",null,
                      {
                        taxRatio: model.taxRatio,
                        choice: "taxRatio"
                      },
                      function (response) {
                        openSnackbar("success", "Kurumlar Vergisi Oranı Eklendi.");
                        console.log(response);
                      },
                      function (error) {
                        openSnackbar(
                          "error",
                          error.response.data.error[0].detail.message
                        );
                      }
                    );
                  },
                },
              ],
            ],
          },
        ],
        [
          {
            style : "",
            type: Enum.layoutType.div,
            name: "vehicleRestrictionContainer",
            inputElements: [
              [
                {
                  id: "vehicleRestriction",
                  type: "v-text-field",
                  variant: "text",
                  name: "vehicleRestriction",
                  label: "Araç Kısıtlaması Oranı Giriniz (%)",
                  autocomplete: false,
                  value: "",
                  placeHolder: "",
                  style: "width: 260px; justify-content: center; margin-left: 15px;",
                  classTag: "",
                },
                {
                  id: "saveSettings",
                  type: "v-btn",
                  variant: "btn",
                  name: "saveSettings",
                  inputText: "Kaydet",
                  divStyle: "",
                  style: "height: 25px !important;margin-top: 15px; margin-left: 10px;",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function (thisData, model, dynamicElements) {
                    
                    sendRequest(Enum.requestType.POST,"addSettings",null,
                      {
                        vehicleRestriction: model.vehicleRestriction,
                        choice: "vehicleRestriction"
                      },
                      function (response) {
                        openSnackbar("success", "Araç Kısıtlaması Eklendi.");
                        console.log(response);
                      },
                      function (error) {
                        openSnackbar(
                          "error",
                          error.response.data.error[0].detail.message
                        );
                      }
                    );
                  },
                },
              ],
            ],
          },
        ],
        [
          {
            style : "",
            type: Enum.layoutType.div,
            name: "kkegContainer",
            inputElements: [
              [
                {
                  id : "KVK. M.11 HÜKÜMLERİNE GÖRE KKEG (FİNANSMAN GİDER KISITLAMASI HARİÇ)",
                  type: "v-text-field",
                  variant : "text",
                  name : "KVK. M.11 HÜKÜMLERİNE GÖRE KKEG (FİNANSMAN GİDER KISITLAMASI HARİÇ)",
                  style:"width: 600px",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  readonly: true,
                  value: "KVK. M.11 HÜKÜMLERİNE GÖRE KKEG (FİNANSMAN GİDER KISITLAMASI HARİÇ)",
                },
                {
                  id : "KVK. M.11 HÜKÜMLERİNE GÖRE KKEG (FİNANSMAN GİDER KISITLAMASI HARİÇ)1",
                  name : "KVK. M.11 HÜKÜMLERİNE GÖRE KKEG (FİNANSMAN GİDER KISITLAMASI HARİÇ)1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "KVK. M.11 HÜKÜMLERİNE GÖRE KKEG (FİNANSMAN GİDER KISITLAMASI)",
                  type: "v-text-field",
                  variant : "text",
                  name : "KVK. M.11 HÜKÜMLERİNE GÖRE KKEG (FİNANSMAN GİDER KISITLAMASI)",
                  inputText : "KVK. M.11 HÜKÜMLERİNE GÖRE KKEG (FİNANSMAN GİDER KISITLAMASI)",
                  style:"width: 550px",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  readonly: true,
                  value: "KVK. M.11 HÜKÜMLERİNE GÖRE KKEG (FİNANSMAN GİDER KISITLAMASI)"
                },
                {
                  id : "KVK. M.11 HÜKÜMLERİNE GÖRE KKEG (FİNANSMAN GİDER KISITLAMASI)1",
                  name : "KVK. M.11 HÜKÜMLERİNE GÖRE KKEG (FİNANSMAN GİDER KISITLAMASI)1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "VUK HÜKÜMLERİNE AYKIRI AYRILAN KARŞILIKLAR",
                  type: "v-text-field",
                  variant : "text",
                  name : "VUK HÜKÜMLERİNE AYKIRI AYRILAN KARŞILIKLAR",
                  style:"width: 380px",
                  inputText : "VUK HÜKÜMLERİNE AYKIRI AYRILAN KARŞILIKLAR",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  readonly: true,
                  value: "VUK HÜKÜMLERİNE AYKIRI AYRILAN KARŞILIKLAR"
                },
                {
                  id : "VUK HÜKÜMLERİNE AYKIRI AYRILAN KARŞILIKLAR1",
                  name : "VUK HÜKÜMLERİNE AYKIRI AYRILAN KARŞILIKLAR1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "VUK HÜKÜMLERİNE AYKIRI AYRILAN REESKONTLAR",
                  type: "v-text-field",
                  variant : "text",
                  name : "VUK HÜKÜMLERİNE AYKIRI AYRILAN REESKONTLAR",
                  style:"width: 380px",
                  inputText : "VUK HÜKÜMLERİNE AYKIRI AYRILAN REESKONTLAR",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  readonly: true,
                  value: "VUK HÜKÜMLERİNE AYKIRI AYRILAN REESKONTLAR"                
                },
                {
                  id : "VUK HÜKÜMLERİNE AYKIRI AYRILAN REESKONTLAR1",
                  name : "VUK HÜKÜMLERİNE AYKIRI AYRILAN REESKONTLAR1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "VUK HÜKÜMLERİNE AYKIRI AYRILAN AMORTİSMANLAR",
                  type: "v-text-field",
                  variant : "text",
                  name : "VUK HÜKÜMLERİNE AYKIRI AYRILAN AMORTİSMANLAR",
                  style:"width: 410px",
                  inputText : "VUK HÜKÜMLERİNE AYKIRI AYRILAN AMORTİSMANLAR",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  readonly: true,
                  value: "VUK HÜKÜMLERİNE AYKIRI AYRILAN AMORTİSMANLAR" 
                },
                {
                  id : "VUK HÜKÜMLERİNE AYKIRI AYRILAN AMORTİSMANLAR1",
                  name : "VUK HÜKÜMLERİNE AYKIRI AYRILAN AMORTİSMANLAR1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "5510 M.88 KAPSAMINDAKİ KKEG",
                  type: "v-text-field",
                  variant : "text",
                  name : "5510 M.88 KAPSAMINDAKİ KKEG",
                  style:"width: 250px",
                  inputText : "5510 M.88 KAPSAMINDAKİ KKEG",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  readonly: true,
                  value: "5510 M.88 KAPSAMINDAKİ KKEG"
                },
                {
                  id : "5510 M.88 KAPSAMINDAKİ KKEG1",
                  name : "5510 M.88 KAPSAMINDAKİ KKEG1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "BAĞIŞ VE YARDIMLAR",
                  type: "v-text-field",
                  variant : "text",
                  name : "BAĞIŞ VE YARDIMLAR",
                  style:"width: 170px",
                  inputText : "BAĞIŞ VE YARDIMLAR",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  readonly: true,
                  value: "BAĞIŞ VE YARDIMLAR"
                },
                {
                  id : "BAĞIŞ VE YARDIMLAR1",
                  name : "BAĞIŞ VE YARDIMLAR1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "GVK M.75 UYARINCA YÖNETİM KUR.ÜYE.ÖDE.KAR PAYLARI",
                  type: "v-text-field",
                  variant : "text",
                  name : "GVK M.75 UYARINCA YÖNETİM KUR.ÜYE.ÖDE.KAR PAYLARI",
                  style:"width: 450px",
                  inputText : "GVK M.75 UYARINCA YÖNETİM KUR.ÜYE.ÖDE.KAR PAYLARI",
                  divStyle:"margin-top: 24.5px; font-weight: bold; display: flex; justify-content: center;",
                  readonly: true,
                  value: "GVK M.75 UYARINCA YÖNETİM KUR.ÜYE.ÖDE.KAR PAYLARI"
                },
                {
                  id : "GVK M.75 UYARINCA YÖNETİM KUR.ÜYE.ÖDE.KAR PAYLARI1",
                  name : "GVK M.75 UYARINCA YÖNETİM KUR.ÜYE.ÖDE.KAR PAYLARI1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "KDV MD.30/d UYARINCA İNDİRİLEMEYEN KDV TUTARI",
                  type: "v-text-field",
                  variant : "text",
                  name : "KDV MD.30/d UYARINCA İNDİRİLEMEYEN KDV TUTARI",
                  style:"width: 400px",
                  inputText : "KDV MD.30/d UYARINCA İNDİRİLEMEYEN KDV TUTARI",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  value : "KDV MD.30/d UYARINCA İNDİRİLEMEYEN KDV TUTARI",
                },
                {
                  id : "KDV MD.30/d UYARINCA İNDİRİLEMEYEN KDV TUTARI1",
                  name : "KDV MD.30/d UYARINCA İNDİRİLEMEYEN KDV TUTARI1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "6802 SAYILI GVK UYARINCA GİDER YAZILAN ÖİV",
                  type: "v-text-field",
                  variant : "text",
                  name : "6802 SAYILI GVK UYARINCA GİDER YAZILAN ÖİV",
                  style:"width: 370px",
                  inputText : "6802 SAYILI GVK UYARINCA GİDER YAZILAN ÖİV",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  value : "6802 SAYILI GVK UYARINCA GİDER YAZILAN ÖİV",
                },
                {
                  id : "6802 SAYILI GVK UYARINCA GİDER YAZILAN ÖİV1",
                  name : "6802 SAYILI GVK UYARINCA GİDER YAZILAN ÖİV1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "MTV KANUNU MADDE 14 UYARINCA GİDER KAYDEDİLEN MTV LER",
                  type: "v-text-field",
                  variant : "text",
                  name : "MTV KANUNU MADDE 14 UYARINCA GİDER KAYDEDİLEN MTV LER",
                  style:"width: 450px",
                  inputText : "MTV KANUNU MADDE 14 UYARINCA GİDER KAYDEDİLEN MTV LER",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  value : "MTV KANUNU MADDE 14 UYARINCA GİDER KAYDEDİLEN MTV LER",
                },
                {
                  id : "MTV KANUNU MADDE 14 UYARINCA GİDER KAYDEDİLEN MTV LER1",
                  name : "MTV KANUNU MADDE 14 UYARINCA GİDER KAYDEDİLEN MTV LER1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "İSTİSNA FAALİYETLERDEN İŞLEMLERDEN DOĞAN ZARARLAR",
                  type: "v-text-field",
                  variant : "text",
                  name : "İSTİSNA FAALİYETLERDEN İŞLEMLERDEN DOĞAN ZARARLAR",
                  style:"width: 450px",
                  inputText : "İSTİSNA FAALİYETLERDEN İŞLEMLERDEN DOĞAN ZARARLAR",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  value : "İSTİSNA FAALİYETLERDEN İŞLEMLERDEN DOĞAN ZARARLAR",
                },
                {
                  id : "İSTİSNA FAALİYETLERDEN İŞLEMLERDEN DOĞAN ZARARLAR1",
                  name : "İSTİSNA FAALİYETLERDEN İŞLEMLERDEN DOĞAN ZARARLAR1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "6736 S. KANUN KAPSAMINDA OLUŞAN KKEG",
                  type: "v-text-field",
                  variant : "text",
                  name : "6736 S. KANUN KAPSAMINDA OLUŞAN KKEG",
                  style:"width: 345px",
                  inputText : "6736 S. KANUN KAPSAMINDA OLUŞAN KKEG",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  value : "6736 S. KANUN KAPSAMINDA OLUŞAN KKEG",
                },
                {
                  id : "6736 S. KANUN KAPSAMINDA OLUŞAN KKEG1",
                  name : "6736 S. KANUN KAPSAMINDA OLUŞAN KKEG1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "7143 S. KANUN KAPSAMINDA OLUŞAN KKEG",
                  type: "v-text-field",
                  variant : "text",
                  name : "7143 S. KANUN KAPSAMINDA OLUŞAN KKEG",
                  style:"width: 345px",
                  inputText : "7143 S. KANUN KAPSAMINDA OLUŞAN KKEG",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  value : "7143 S. KANUN KAPSAMINDA OLUŞAN KKEG",
                },
                {
                  id : "7143 S. KANUN KAPSAMINDA OLUŞAN KKEG1",
                  name : "7143 S. KANUN KAPSAMINDA OLUŞAN KKEG1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "G.V.K. 40. MAD. GEREĞİ BİNEK OTO GİDER, AMORTİSMAN VE KİRALARIN GİDER YAZILMASINA İLİŞKİN SINIRLAMA KAP.OLUŞAN KKEG",
                  type: "v-text-field",
                  variant : "text",
                  name : "G.V.K. 40. MAD. GEREĞİ BİNEK OTO GİDER, AMORTİSMAN VE KİRALARIN GİDER YAZILMASINA İLİŞKİN SINIRLAMA KAP.OLUŞAN KKEG",
                  style:"width: 940px",
                  inputText : "G.V.K. 40. MAD. GEREĞİ BİNEK OTO GİDER, AMORTİSMAN VE KİRALARIN GİDER YAZILMASINA İLİŞKİN SINIRLAMA KAP.OLUŞAN KKEG",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  value : "G.V.K. 40. GEREĞİ BİNEK OTO GİDER, AMORTİSMAN VE KİRALARIN GİDER YAZILMASINA İLİŞKİN SINIRLAMA KAP.OLUŞAN KKEG",
                },
                {
                  id : "G.V.K. 40. MAD. GEREĞİ BİNEK OTO GİDER, AMORTİSMAN VE KİRALARIN GİDER YAZILMASINA İLİŞKİN SINIRLAMA KAP.OLUŞAN KKEG1",
                  name : "G.V.K. 40. MAD. GEREĞİ BİNEK OTO GİDER, AMORTİSMAN VE KİRALARIN GİDER YAZILMASINA İLİŞKİN SINIRLAMA KAP.OLUŞAN KKEG1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "7326 SAYILI KANUN KAPSAMINDA OLUŞAN KKEG",
                  type: "v-text-field",
                  variant : "text",
                  name : "7326 SAYILI KANUN KAPSAMINDA OLUŞAN KKEG",
                  style:"width: 365px",
                  inputText : "7326 SAYILI KANUN KAPSAMINDA OLUŞAN KKEG",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  value : "7326 SAYILI KANUN KAPSAMINDA OLUŞAN KKEG",
                },
                {
                  id : "7326 SAYILI KANUN KAPSAMINDA OLUŞAN KKEG1",
                  name : "7326 SAYILI KANUN KAPSAMINDA OLUŞAN KKEG1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "DİĞER - YUVARLAMA FARKLARI",
                  type: "v-text-field",
                  variant : "text",
                  name : "DİĞER - YUVARLAMA FARKLARI",
                  style:"width: 242px",
                  inputText : "DİĞER - YUVARLAMA FARKLARI",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  value : "DİĞER - YUVARLAMA FARKLARI",
                },
                {
                  id : "DİĞER - YUVARLAMA FARKLARI1",
                  name : "DİĞER - YUVARLAMA FARKLARI1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "DİĞER - CARİ HESAP MUTABAKAT DÜZELTMELERİ",
                  type: "v-text-field",
                  variant : "text",
                  name : "DİĞER - CARİ HESAP MUTABAKAT DÜZELTMELERİ",
                  style:"width: 370px",
                  inputText : "DİĞER - CARİ HESAP MUTABAKAT DÜZELTMELERİ",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  value : "DİĞER - CARİ HESAP MUTABAKAT DÜZELTMELERİ",
                },
                {
                  id : "DİĞER - CARİ HESAP MUTABAKAT DÜZELTMELERİ1",
                  name : "DİĞER - CARİ HESAP MUTABAKAT DÜZELTMELERİ1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "DİĞER - BELGESİZ GİDERLER",
                  type: "v-text-field",
                  variant : "text",
                  name : "DİĞER - BELGESİZ GİDERLER",
                  style:"width: 230px",
                  inputText : "DİĞER - BELGESİZ GİDERLER",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  value : "DİĞER - BELGESİZ GİDERLER",
                },
                {
                  id : "DİĞER - BELGESİZ GİDERLER1",
                  name : "DİĞER - BELGESİZ GİDERLER1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "DİĞER - ÖNCEKİ DÖNEM İLE İLGİLİ GİDERLER",
                  type: "v-text-field",
                  variant : "text",
                  name : "DİĞER - ÖNCEKİ DÖNEM İLE İLGİLİ GİDERLER",
                  style:"width: 330px",
                  inputText : "DİĞER - ÖNCEKİ DÖNEM İLE İLGİLİ GİDERLER",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  value : "DİĞER - ÖNCEKİ DÖNEM İLE İLGİLİ GİDERLER",
                },
                {
                  id : "DİĞER - ÖNCEKİ DÖNEM İLE İLGİLİ GİDERLER1",
                  name : "DİĞER - ÖNCEKİ DÖNEM İLE İLGİLİ GİDERLER1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "DİĞER - TEMSİL AĞIRLAMA GİDERLERİ",
                  type: "v-text-field",
                  variant : "text",
                  name : "DİĞER - TEMSİL AĞIRLAMA GİDERLERİ",
                  style:"width: 285px",
                  inputText : "DİĞER - TEMSİL AĞIRLAMA GİDERLERİ",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  value : "DİĞER - TEMSİL AĞIRLAMA GİDERLERİ",
                },
                {
                  id : "DİĞER - TEMSİL AĞIRLAMA GİDERLERİ1",
                  name : "DİĞER - TEMSİL AĞIRLAMA GİDERLERİ1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "DİĞER - DİĞER",
                  type: "v-text-field",
                  variant : "text",
                  name : "DİĞER - DİĞER",
                  style:"width: 110px",
                  inputText : "DİĞER - DİĞER",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  value : "DİĞER - DİĞER",
                },
                {
                  id : "DİĞER - DİĞER1",
                  name : "DİĞER - DİĞER1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id : "DİĞER - ÖKC FİŞLERİ KDV İADESİ",
                  type: "v-text-field",
                  variant : "text",
                  name : "DİĞER - ÖKC FİŞLERİ KDV İADESİ",
                  style:"width: 250px",
                  inputText : "DİĞER - ÖKC FİŞLERİ KDV İADESİ",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  value : "DİĞER - ÖKC FİŞLERİ KDV İADESİ",
                },
                {
                  id : "DİĞER - ÖKC FİŞLERİ KDV İADESİ1",
                  name : "DİĞER - ÖKC FİŞLERİ KDV İADESİ1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id: "DİĞER - BİNEK ARAÇ AMORTİSMAN KISITLAMASI",
                  type: "v-text-field",
                  variant : "text",
                  name : "DİĞER - BİNEK ARAÇ AMORTİSMAN KISITLAMASI",
                  style:"width: 370px",
                  inputText : "DİĞER - BİNEK ARAÇ AMORTİSMAN KISITLAMASI",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  value: "DİĞER - BİNEK ARAÇ AMORTİSMAN KISITLAMASI",
                },
                {
                  id : "DİĞER - BİNEK ARAÇ AMORTİSMAN KISITLAMASI1",
                  name : "DİĞER - BİNEK ARAÇ AMORTİSMAN KISITLAMASI1",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : false,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 80px; width: 950px;",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id: "saveSettings",
                  type: "v-btn",
                  variant: "btn",
                  name: "saveSettings",
                  inputText: "Kaydet",
                  divStyle: "",
                  style: "height: 25px !important;margin-top: 25px;",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function (thisData, model, dynamicElements) {
                    sendRequest(Enum.requestType.POST,"addSettings",null,
                      {
                        kkegAccountCodes: {
                          "KVK. M.11 HÜKÜMLERİNE GÖRE KKEG (FİNANSMAN GİDER KISITLAMASI HARİÇ)": model["KVK. M.11 HÜKÜMLERİNE GÖRE KKEG (FİNANSMAN GİDER KISITLAMASI HARİÇ)1"],
                          "KVK. M.11 HÜKÜMLERİNE GÖRE KKEG (FİNANSMAN GİDER KISITLAMASI)": model["KVK. M.11 HÜKÜMLERİNE GÖRE KKEG (FİNANSMAN GİDER KISITLAMASI)1"],
                          "VUK HÜKÜMLERİNE AYKIRI AYRILAN KARŞILIKLAR": model["VUK HÜKÜMLERİNE AYKIRI AYRILAN KARŞILIKLAR1"],
                          "VUK HÜKÜMLERİNE AYKIRI AYRILAN REESKONTLAR": model["VUK HÜKÜMLERİNE AYKIRI AYRILAN REESKONTLAR1"],
                          "VUK HÜKÜMLERİNE AYKIRI AYRILAN AMORTİSMANLAR": model["VUK HÜKÜMLERİNE AYKIRI AYRILAN AMORTİSMANLAR1"],
                          "5510 M.88 KAPSAMINDAKİ KKEG": model["5510 M.88 KAPSAMINDAKİ KKEG1"],
                          "BAĞIŞ VE YARDIMLAR": model["BAĞIŞ VE YARDIMLAR1"],
                          "GVK M.75 UYARINCA YÖNETİM KUR.ÜYE.ÖDE.KAR PAYLARI": model["GVK M.75 UYARINCA YÖNETİM KUR.ÜYE.ÖDE.KAR PAYLARI1"],
                          "KDV MD.30/d UYARINCA İNDİRİLEMEYEN KDV TUTARI": model["KDV MD.30/d UYARINCA İNDİRİLEMEYEN KDV TUTARI1"],
                          "6802 SAYILI GVK UYARINCA GİDER YAZILAN ÖİV": model["6802 SAYILI GVK UYARINCA GİDER YAZILAN ÖİV1"],
                          "MTV KANUNU MADDE 14 UYARINCA GİDER KAYDEDİLEN MTV LER": model["MTV KANUNU MADDE 14 UYARINCA GİDER KAYDEDİLEN MTV LER1"],
                          "İSTİSNA FAALİYETLERDEN İŞLEMLERDEN DOĞAN ZARARLAR": model["İSTİSNA FAALİYETLERDEN İŞLEMLERDEN DOĞAN ZARARLAR1"],
                          "6736 S. KANUN KAPSAMINDA OLUŞAN KKEG": model["6736 S. KANUN KAPSAMINDA OLUŞAN KKEG1"],
                          "7143 S. KANUN KAPSAMINDA OLUŞAN KKEG": model["7143 S. KANUN KAPSAMINDA OLUŞAN KKEG1"],
                          "G.V.K. 40. MAD. GEREĞİ BİNEK OTO GİDER, AMORTİSMAN VE KİRALARIN GİDER YAZILMASINA İLİŞKİN SINIRLAMA KAP.OLUŞAN KKEG": model["G.V.K. 40. MAD. GEREĞİ BİNEK OTO GİDER, AMORTİSMAN VE KİRALARIN GİDER YAZILMASINA İLİŞKİN SINIRLAMA KAP.OLUŞAN KKEG1"],
                          "7326 SAYILI KANUN KAPSAMINDA OLUŞAN KKEG": model["7326 SAYILI KANUN KAPSAMINDA OLUŞAN KKEG1"],
                          "DİĞER - YUVARLAMA FARKLARI": model["DİĞER - YUVARLAMA FARKLARI1"],
                          "DİĞER - CARİ HESAP MUTABAKAT DÜZELTMELERİ": model["DİĞER - CARİ HESAP MUTABAKAT DÜZELTMELERİ1"],
                          "DİĞER - BELGESİZ GİDERLER": model["DİĞER - BELGESİZ GİDERLER1"],
                          "DİĞER - ÖNCEKİ DÖNEM İLE İLGİLİ GİDERLER": model["DİĞER - ÖNCEKİ DÖNEM İLE İLGİLİ GİDERLER1"],
                          "DİĞER - TEMSİL AĞIRLAMA GİDERLERİ": model["DİĞER - TEMSİL AĞIRLAMA GİDERLERİ1"],
                          "DİĞER - DİĞER": model["DİĞER - DİĞER1"],
                          "DİĞER - ÖKC FİŞLERİ KDV İADESİ": model["DİĞER - ÖKC FİŞLERİ KDV İADESİ1"],
                          "DİĞER - BİNEK ARAÇ AMORTİSMAN KISITLAMASI": model["DİĞER - BİNEK ARAÇ AMORTİSMAN KISITLAMASI1"],
                        },
                        choice: "kkegAccountCodes"
                      },
                      function (response) {
                        openSnackbar("success", "Hesap Kodu Eklendi.");
                        console.log(response);
                        window.location.reload(true);
                      },
                      function (error) {
                        openSnackbar(
                          "error",
                          error.response.data.error[0].detail.message
                        );
                      }
                    );
                  },
                },
              ],
            ],
          },
        ],
        [
          {
            style : "",
            type: Enum.layoutType.div,
            name: "rentContainer",
            inputElements: [
              [
                {
                  id : "rentAccountCode",
                  type: "v-text-field",
                  variant : "text",
                  name : "rentAccountCode",
                  //label: "250 Hesap Kodu",
                  value: "Kira Hesap Kodu",
                  style:"font-size: 20px; width: 150px",
                  divStyle:"margin-top: 25px; font-weight: bold; display: flex; justify-content: center;",
                  readonly: true,
                  //disabled: true
                },
                {
                  id : "rentCode",
                  name : "rentCode",
                  type : 'autocomplete',
                  label : "İstediğiniz hesap kodunun ilk 3 numarasını giriniz",
                  value : "",
                  multiple : true,
                  classTag : "",
                  url : "getUniqueAccountCodes",
                  color: 'white',
                  style: "heigth: 50px; width: 550px; margin-rigth: 50px",
                  placeHolder: "Kira Hesap Kodu",
                  solo: true,
                  clearable: true,
                  dense: true
                },
                {
                  id: "saveSettings",
                  type: "v-btn",
                  variant: "btn",
                  name: "saveSettings",
                  inputText: "Kaydet",
                  divStyle: "",
                  style: "height: 25px !important;margin-top: 25px;",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function (thisData, model, dynamicElements) {
                    sendRequest(Enum.requestType.POST,"addSettings",null,
                      {
                        rentAccountCodes: {
                          '360': model.rentCode,
                          
                        },
                        choice: "rentAccountCodes"
                      },
                      function (response) {
                        openSnackbar("success", "Hesap Kodu Eklendi.");
                        console.log(response);
                        window.location.reload(true);
                      },
                      function (error) {
                        openSnackbar(
                          "error",
                          error.response.data.error[0].detail.message
                        );
                      }
                    );
                  },
                },
              ],
            ],
          },
        ],
        [
          {
            style : "",
            type: Enum.layoutType.div,
            name: "companyInsertContainer",
            inputElements: [
              [
                {
                  id: "companyName",
                  type: "v-text-field",
                  variant: "",
                  name: "companyName",
                  label: "Yeni Firma Adı",
                  value: "",
                  prependIcon: "",
                  placeHolder: "",
                  style: "width: 260px;",
                  classTag: "",
                },
                {
                  id: "newTinNumber",
                  type: "v-text-field",
                  variant: "",
                  name: "newTinNumber",
                  label: "Vergi Kimlik Numarası",
                  value: "",
                  prependIcon: "",
                  placeHolder: "",
                  style: "width: 260px;",
                  classTag: "",
                },
                {
                  id: "saveSettings",
                  type: "v-btn",
                  variant: "btn",
                  name: "saveSettings",
                  inputText: "Yeni Firma Ekle",
                  divStyle: "",
                  style: "height: 25px !important;margin-top: 25px;margin-right: 10px;",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function (thisData, model, dynamicElements) {
                    if (model.newPassword == model.newPasswordAgain) {
                      sendRequest(Enum.requestType.POST,"insertNewCompany",null,
                        {
                          companyName: model.companyName,
                          newTinNumber: model.newTinNumber,
                        },
                        function (response) {
                          if (response.data?.data?.value == "Bu vergi kimlik numarasına ait kayıt bulunmaktadır.") {
                            openSnackbar("error", response.data.data.value);
                          }else{
                            openSnackbar("success", "Yeni Firma Kayıt Edildi.");
                          }
                        },
                        function (error) {
                          openSnackbar(
                            "error",
                            error.response.data.error[0].detail.message
                          );
                        }
                      );
                    } else {
                      openSnackbar("error", "Şifreler Eşleşmedi");
                    }
                  },
                },
              ],
            ],
          },
        ],
      ];
      return this.dynamicElements;
    },
  },
};
</script>

<style scoped>
.readOnlyText {
  margin-top: 25px; 
  font-weight: bold; 
  display: flex; 
  justify-content: center;
}
</style>