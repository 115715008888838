<template>
  <v-app>
    <table-component :dynamic-element="dynamicElements" :model="model" />
    <loader />
  </v-app>
</template>

<script>
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from '../../../frontend/components/atoms/loader'
import config from "../../config.json";
import {
  getTable,
  openSnackbar,
  openTablePage,
  deleteItem,
  //showDialog,
  //closeDialog,
  //passivePull,
  //excelDownload,
  //getTableModel,
  //showTableColoumn,
  //hideTableColoumn,
  updateItem,
  saveItem
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import {sendRequest} from "../../../frontend/components/atoms/helper/requestHelper.js";

export default {
  components: { "table-component": tableComponent , 'loader' : Loader},

  data() {
    return {
      dynamicElements: {},
      model: {},
      renderKey: 0
    };
  },

  created() {
    this.loadData();
  },
  watch: {},
  methods: {
    loadData() {
      let that = this;
      this.model = {};
      this.dynamicElements = {
        sortBy: [],
        sortDesc: false,
        renderKey: 0,
        selectedRows: [],
        type: "table",
        name: "declarationDefineTable",
        class: "",
        infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
        hidePagination: true,
        filteredColumn: {},
        clientEvents: {},
        filteredColumns: true,
        showDialogColumnsCount: 6,
        selectionUrl: "gettabledata",
        updateUrl: "declarationdefineupdate",
        saveUrl : "insertdeclarationdefine",
        deleteUrl : "deleteDeclarations",
        saveBtnText : 'Yeni Kayıt',
        showDefaultSaveButon : false,
        showDefaultUpdateButon : false,
        showDefaultDeleteButon : false,
        initialFilter:{
          [Enum.initialFilter.AND] :
          [
            {
              project_id: { [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id },
              auditDate: { [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").auiditDate },
              preriorDate: { [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").preriorDate }
            }
          ]
        },
        clientEvents : {
           onBeforeSave :function (selectedItem,creatableObjects,model,tableObject) {
            creatableObjects.initialFilter = tableObject.initialFilter
           },
          //  onBeforeUpdate :function (selectedItem,editableObjects,model,tableObject) {
          //    creatableObjects.initialFilter = tableObject.initialFilter
          //  },
        },
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
              [
                {
                  id: "update",
                  type: "v-btn",
                  variant: "btn",
                  name: "update",
                  inputText: "Güncelle",
                  divStyle: "",
                  style: "height: 25px !important;",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick : function(thisData,model,dynamicElements) {
                    let tableObject = getTable("declarationDefineTable")
                    console.log(thisData,model,dynamicElements)
                    if (tableObject.selectedRows.length > 0) {
                      let options = {
                        showDialog : true
                      }
                      updateItem("declarationDefineTable",tableObject.selectedRows,options,model)
                      dynamicElements.dialogInfo.layout[0].inputElements[1][0].dropDownItems = config["taxStatement" + dynamicElements.selectedRows[0].kebir]
                    }else{
                      openSnackbar('error', 'Lütfen güncellemek istediğiniz satırı seçiniz')
                    }
                    
                  }
                },
              ],
              [
                {
                  id: "copy",
                  type: "v-btn",
                  variant: "btn",
                  name: "copy",
                  inputText: "Hesap Kopyala",
                  divStyle: "",
                  style: "height: 25px !important;",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick : function(thisData,model,dynamicElements) {
                    let tableObject = getTable("declarationDefineTable")
                    console.log(thisData,model,dynamicElements)
                    if (tableObject.selectedRows.length > 0) {
                      let options = {
                        showDialog : true
                      }
                      that.copyItem("declarationDefineTable",tableObject.selectedRows,options,model)
                    }else{
                      openSnackbar('error', 'Lütfen kopyasını oluşturmak istediğiniz satırı seçiniz')
                    }
                  }
                },
              ],
              [
                {
                  id: "delete",
                  type: "v-btn",
                  variant: "btn",
                  name: "delete",
                  inputText: "Sil",
                  divStyle: "",
                  style: "height: 25px !important;",
                  btnTypeText: false,
                  color: "error",
                  prependIcon: "",
                  classTag: "",
                  onClick : function(thisData,model,dynamicElements) {
                    let tableObject = getTable("declarationDefineTable")
                    console.log(thisData,model,dynamicElements)
                    if (tableObject.selectedRows.length > 0) {
                      let options = {
                        showDialog : true
                      }
                      deleteItem("declarationDefineTable",tableObject.selectedRows,options,model)
                    }else{
                      openSnackbar('error', 'Lütfen silmek istediğiniz satırı seçiniz')
                    }
                  }
                },
              ],
              [
                {
                  id: "newRecord",
                  type: "v-btn",
                  variant: "btn",
                  name: "newRecord",
                  inputText: "Yeni Kayıt",
                  divStyle: "",
                  style: "height: 25px !important;",
                  btnTypeText: false,
                  color: "blue",
                  prependIcon: "",
                  classTag: "",
                  onClick : function(thisData,model,dynamicElements) {
                    let tableObject = getTable("declarationDefineTable")
                    console.log(thisData,model,dynamicElements)
                    //if (tableObject.selectedRows.length > 0) {
                      let options = {
                        showDialog : true
                      }
                      dynamicElements.columns.operationType.dropDownItems = config["Diğer İşlemler"]
                     // dynamicElements.dialogInfo.layout[0].inputElements[0][0].dropDownItems = config["taxStatement" + dynamicElements.selectedRows[0].kebir]
                      saveItem("declarationDefineTable",[],options,model)
                    // }else{
                    //   openSnackbar('error', 'Lütfen kopyasını oluşturmak istediğiniz satırı seçiniz')
                    // }
                  }
                },
              ],
            ],
          },
        ],
        headers: [
          { 
            text: "Beyanname Türü", 
            value: "declarationType", 
            width: "80", 
            style: "width:100px", 
            search: false,sortable: true, 
            class : 'threedot', 
            divider: true, 
            switchValue: {1 : "KDV 1", 2 : "KDV 2", 3 : "Muhtasar", 4 : "Damga Vergisi", 10: "SGK-MUH", '': "Tümü"}, 
            items: [{name: "Tümü", value: ''}, {name : "KDV 1", value : 1}, {name : "KDV 2", value : 2}, {name : "Muhtasar", value : 3}, {name : "Damga Vergisi", value : 4}, {name : "SGK-MUH", value : 10} ] 
          },
          { text: "Hesap Kodu", value: "accountCode", width: "150", search: true , sortable: true, divider: true },
          { text: "Hesap Adı", value: "accountName", search: true, style: "width:180px", width: "180", sortable: true, divider: true, class : 'threedot' },
          { text: "Tür Kodu", value: "conciseTypeCodes", width: "100", style: "width:100px", class : 'threedot', search: true , sortable: true, divider: true, dataType : "array" },
          { text: "Vergi Bildirimi", value: "taxType", width:"180", style: "width:180px", search: true, sortable: true, divider: true, class : 'threedot' },
          { text: "Kdv Oranı", value: "vatRate", width: "80", search: true, sortable: true, divider: true },
          { text: "İşlem Türü", value: "operationType", width: "100", style: "width:100px", search: true , sortable: true, divider: true, class : 'threedot' },
          { text: "Tevkifat Oranı", value: "loyaltyRate", width: "80", search: true , sortable: true, divider: true }
        ],
        columns: {
          declarationType : {
            type: "v-select",
            variant : "number",
            editable: true,
            creatable: false,
            inlineEditable:false, 
            label: "Beyanname Türü",
            dropDownItems: ["KDV 1" , "KDV 2" , "SGK-MUH", "Muhtasar", "Damga Vergisi", "Kapsam Dışı"],
            disabled: false,
            multiple : false,
            onChange : function(thisData, model, dynamicElements) {
              console.log(thisData,model,dynamicElements)
              if (model.declarationType == "KDV 1") {
                for (let i = 1; i < dynamicElements.dialogInfo.layout[0].inputElements.length; i++) {
                  if (dynamicElements.dialogInfo.layout[0].inputElements[i][0].id == "conciseTypeCodes" || dynamicElements.dialogInfo.layout[0].inputElements[i][0].id == "loyaltyRate") {
                    dynamicElements.dialogInfo.layout[0].inputElements[i][0].disabled = true
                  }else{
                    dynamicElements.dialogInfo.layout[0].inputElements[i][0].disabled = false
                  }
                }
                dynamicElements.dialogInfo.layout[0].inputElements[2][0].dropDownItems = config["taxStatement" + dynamicElements.selectedRows[0].accountCode.substring(0, 3)]
              }else if(model.declarationType == "KDV 2"){
                for (let i = 1; i < dynamicElements.dialogInfo.layout[0].inputElements.length; i++) {
                  if (dynamicElements.dialogInfo.layout[0].inputElements[i][0].id == "conciseTypeCodes" || dynamicElements.dialogInfo.layout[0].inputElements[i][0].id == "loyaltyRate") {
                    dynamicElements.dialogInfo.layout[0].inputElements[i][0].disabled = true
                  }else{
                    dynamicElements.dialogInfo.layout[0].inputElements[i][0].disabled = false
                  }
                }
                dynamicElements.dialogInfo.layout[0].inputElements[2][0].dropDownItems = config["taxStatement" + dynamicElements.selectedRows[0].accountCode.substring(0, 3)]
              }else if(model.declarationType == "Muhtasar"){
                dynamicElements.dialogInfo.layout[0].inputElements[1][0].dropDownItems = config["conciseTypeCodes"]
                //eğer bir satır seçtiyse muhtasar tür kodlarını otomatik getir
                if (dynamicElements.selectedRows.length == 1) {
                  model["conciseTypeCodes"] = dynamicElements.selectedRows[0].conciseTypeCodes
                }
                for (let i = 1; i < dynamicElements.dialogInfo.layout[0].inputElements.length; i++) {
                  if (dynamicElements.dialogInfo.layout[0].inputElements[i][0].id == "conciseTypeCodes") {
                    dynamicElements.dialogInfo.layout[0].inputElements[i][0].disabled = false
                  }else{
                    dynamicElements.dialogInfo.layout[0].inputElements[i][0].disabled = true
                  }
                  
                }
              }else if(model.declarationType == "Damga Vergisi"){
                console.log('dmaga')
              }else if(model.declarationType == "SGK-MUH"){
                for (let i = 1; i < dynamicElements.dialogInfo.layout[0].inputElements.length; i++) {
                  if (dynamicElements.dialogInfo.layout[0].inputElements[i][0].id == "taxType") {
                    dynamicElements.dialogInfo.layout[0].inputElements[i][0].disabled = false
                  }else{
                    dynamicElements.dialogInfo.layout[0].inputElements[i][0].disabled = true
                  }
                }
                dynamicElements.dialogInfo.layout[0].inputElements[2][0].dropDownItems = config["taxStatementSGK"]
              }else{
                for (let i = 1; i < dynamicElements.dialogInfo.layout[0].inputElements.length; i++) {
                  dynamicElements.dialogInfo.layout[0].inputElements[i][0].disabled = true
                }
                dynamicElements.dialogInfo.layout[0].inputElements[1][0].dropDownItems = []
              }
              console.log(dynamicElements.dialogInfo.layout[0].inputElements)
            }
          },
          conciseTypeCodes : {
            type: "v-select",
            variant : "number",
            editable: true,
            creatable: false,
            inlineEditable: false,
            disabled: false,
            label: "Muhtasar İse Tür Kodu",
            dropDownItems: config["conciseTypeCodes"],//[],
            value: [],
            multiple : true
          },
          taxType : {
            type: "v-select",
            variant : "number",
            editable: true,
            creatable: false,
            inlineEditable:false, 
            label: "Vergi Bildirimi",
            dropDownItems: [],
            multiple : false,
            disabled : false,
            onChange : function(thisData,model,dynamicElements) {
              dynamicElements.dialogInfo.layout[0].inputElements[3][0].dropDownItems = [' ','1','8','18', '10', '20']
              if (model.taxType == "Tam İstisna Kapsamına Giren İşlemler") {
                dynamicElements.dialogInfo.layout[0].inputElements[4][0].dropDownItems = config[model.taxType + dynamicElements.selectedRows[0].accountCode.substring(0, 3)]
              }else if(model.taxType == "Diğer Bilgiler"){
                dynamicElements.dialogInfo.layout[0].inputElements[4][0].dropDownItems = config[model.taxType + dynamicElements.selectedRows[0].accountCode.substring(0, 3)]
              }else{
                dynamicElements.dialogInfo.layout[0].inputElements[4][0].dropDownItems = config[model.taxType]
              }
              console.log(thisData,model,dynamicElements)
            }
          },          
          vatRate : {
            type: "v-select",
            variant : "number",
            editable: true,
            creatable: false,
            inlineEditable:false, 
            disabled: false,
            label: "KDV Oranı",
            dropDownItems: [],
            multiple : false,
            onChange : function(thisData,model,dynamicElements) {
              debugger
              if (model.taxType == "Tam İstisna Kapsamına Giren İşlemler") {
                dynamicElements.dialogInfo.layout[0].inputElements[4][0].dropDownItems = config[model.taxType + dynamicElements.selectedRows[0].accountCode.substring(0, 3)]
              }else if(model.taxType == "Diğer Bilgiler"){
                dynamicElements.dialogInfo.layout[0].inputElements[4][0].dropDownItems = config[model.taxType + dynamicElements.selectedRows[0].accountCode.substring(0, 3)]
              }else{
                dynamicElements.dialogInfo.layout[0].inputElements[4][0].dropDownItems = config[model.taxType]
              }
              console.log(thisData,model,dynamicElements)
            }
          },
          operationType : {
            type: "v-select",
            variant : "number",
            editable: true,
            creatable: true,
            inlineEditable:false,
            disabled: false,
            label: "İşlem Türü",
            dropDownItems: [],
            multiple : false,
            onChange : function(thisData,model,dynamicElements) {
              dynamicElements.dialogInfo.layout[0].inputElements[5][0].value = config[model.operationType]
              //dynamicElements.dialogInfo.layout[0].inputElements[5][0].label = config[model.operationType]
              console.log(thisData,model,dynamicElements)
            }
          },
          loyaltyRate : {
            type : "v-text-field",
            editable: true,
            creatable: false,
            inlineEditable:false,
            disabled: true,
            label: "Tevkifat Oranı",
          },
          accountCode: {
            type: "v-text-field",
            label: "Hesap Kodu",
            editable: false,
            creatable: true,
            inlineEditable:false,
          },
          accountName: {
            type: "v-text-field",
            label: "Hesap Adı",
            editable: false,
            creatable: true,
            inlineEditable:false,
          }
        },
        data: "data",
        showSelecetVisible: true,
        singleSelect: false,
        itemKey: "id",
        footer: [
          {
            type: Enum.layoutType.div,
            name: "footerForm",
            inputElements: [
              [
                {
                  id: "pageSize",
                  type: "v-text-field",
                  variant: "number",
                  name: "pageSize",
                  label: "Görünecek Satır Sayısı",
                  placeHolder: "",
                  style: "width: 45%; margin-left: 5%",
                  classTag: "",
                  onChange: function(thisData, model, dynamicElements) {
                    openTablePage("declarationDefineTable");
                    console.log(thisData);
                    console.log(dynamicElements);
                    console.log(model);
                  },
                },
              ],
              [
                {
                  id: "currentPageNo",
                  type: "v-pagination",
                  variant: "pagination",
                  name: "currentPageNo",
                  divStyle: "",
                  style: "width:850px;",
                  classTag: "my-1",
                  length: "pagesCount",
                  onInput: function(thisData, model, dynamicElements) {
                    openTablePage("declarationDefineTable");
                    console.log(thisData);
                    console.log(dynamicElements);
                    console.log(model);
                  },
                },
              ],
              [
                {
                  id: "infoTotalRowCountHeaderAssistantTable",
                  type: "p",
                  variant: "p",
                  name: "infoTotalRowCountHeaderAssistantTable",
                  inputText: "infoTotalRowCountHeaderAssistantTable",
                  divStyle: "",
                  style:
                    "font-size: 12px;font-weight:500;margin:3px 0;color:#666A6F;margin-right: 2%;margin-left: 60%;",
                  classTag: "",
                },
                {
                  id: "infoTotalRowCountAssistantTable",
                  type: "p",
                  variant: "p",
                  name: "infoTotalRowCountAssistantTable",
                  inputText: "itemCount",
                  divStyle: "",
                  style:
                    "font-size: 15px;font-weight:500;margin:3px 0;color:#666A6F;margin-left: 82%;",
                  classTag: "",
                },
              ],
            ],
          },
        ]
      };
    },
    copyItem(tableName,selectedItem,options,model,onBeforeSave,onAfterSave,errorFunction) {
    if (!Array.isArray(selectedItem)) {
        selectedItem = [selectedItem];
    }

    let dynamicElements = getTable(tableName);
    if (options != undefined && options.dialogText != undefined) {
        dynamicElements.deleteDialogText = options.dialogText;
    }
    
    let title = "Yeni Kayıt Eklemek İstediğinize Emin Misiniz?";
    
    dynamicElements.forceRender();
    model.close = "Hayır",
    model.insertItem = "Evet";
    dynamicElements.dialogVisible = true;
    dynamicElements.dialogInfo = {
        name:"insertDialog",
        maxWidth : "1100",
        dialogVisible : "dialogVisible",
        title : title,
        layout : [
            {
              type : Enum.layoutType.div,
              name : "loginForm",
    
              inputElements : []
            }
        ],
        actions : [
          {
            id : "close",
            type : "v-btn",
            variant : "btn",
            name : "close",
            inputText : "Kapat",
            divStyle:"",
            btnTypeText: true,
            color:"green darken-1",
            classTag : "",
            onClick : function(thisData,model,dialogInfo) {
                var tableObject = getTable(tableName);
                tableObject.dialogVisible = false;
                tableObject.forceRender();
                console.log(thisData,model,dialogInfo);
            }
          },
          {
            id : "insertItem",
            type : "v-btn",
            variant : "btn",
            name : "insertItem",
            inputText : "Ekle",
            divStyle:"",
            btnTypeText: true,
            color:"green darken-1",
            classTag : "",
            onClick : function(thisData,model,dialogInfo) {
                var tableObject = getTable(tableName);
                console.log(thisData,model,dialogInfo);
                tableObject.dialogVisible = false;
                tableObject.forceRender();
                let creatableObjects = {};
                sendRequest(Enum.requestType.POST, dynamicElements.saveUrl,"",{selectedItem,creatableObjects},function (res) {
                    openTablePage(tableName);
                    openSnackbar("success", "Eklemeniz Başarılı Bir Şekilde Gerçekleşti");
                },function (error) {
                    if (errorFunction != undefined) {
                        errorFunction(error,selectedItem,creatableObjects,model,tableObject);
                    } else if(tableObject.clientEvents.onSaveError != undefined){
                        tableObject.clientEvents.onSaveError(error,selectedItem,creatableObjects,model,tableObject);
                    }
                });
            }
          },
        ]
    };
    },
  },
};
</script>

<style scoped>
::v-deep .threedot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 300px;
}
::v-deep .threedot:hover {
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}
</style>
