<template>
    <v-app>
      <div class="header-div">
        <h3 class="header-h3">Krediler</h3>
      </div>
      <v-toolbar-items style="margin-left: 25px; height: 30px;">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text @click="downloadDrafts" v-on="on" style="background-color: #4BB543; color: white; border-radius: 5px;">
              Taslak İndir
            </v-btn>
          </template>
        </v-tooltip>
      </v-toolbar-items>
      <table-component :dynamic-element="tableElements" :model="model" />
      <Loader></Loader>
      <snackbar-component />
    </v-app>
</template>

<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";

import {
  showDialog,
  closeDialog,
  openSnackbar,
  deleteItem,
  openTablePage,
  showLoader, 
  hideLoader 
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import {axiosUploadTypeArrayBuffer, downloadZipFile, checkProject} from "../../helper/uploadHelperForData"
export default {
    components: {
        "table-component": tableComponent,
        "upload-component": uploadComponent,
        Loader: Loader,
        "snackbar-component": snackbarComponent,
    },

    data() {
        return {
          tableElements: {},
          model: {},
          renderKey: 0,
        };
    },
    created() {
      checkProject();
        this.loadData();
    },
    watch: {},
    methods: {
      downloadDrafts() {
        downloadZipFile();
      },
    renderCreditTable(thisData, model, dynamicElements) {
      showLoader('Hesaplamalar Yapılıyor')
      let choiceData = {
        cookieData: window.$cookies.get("selectedItem")
      }
      sendRequest(Enum.requestType.POST, "credit", "", choiceData, 
      function (response) {
        dynamicElements.data = response.data.data.data;
        model.itemCount = dynamicElements.data.length;
        model.pagesCount = dynamicElements.data.length / 50;
        dynamicElements.forceRender();
        hideLoader();
      },
      function (error) {
       errFunc(error);
      })
    },
    loadData() {
      let that = this;
      this.tableElements = {
        sortBy: [],
        sortDesc: false,
        renderKey: 0,
        type: "table",
        tableHeight : 530,
        name: "creditTable",
        class: "",
        infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
        excelDownloadBtnText: "İndir",
        deleteDialogVisible : true,
        deleteBtnText: "Sil",
        hidePagination: true,
        saveUrl : "creditExcelUpload",
        // selectedRows: [],
        // filteredColumn: {},
        // filteredColumns: true,
        showDefaultDeleteButon : true,
        showDefaultSaveButon: false,
        selectionUrl: "credit",
        deleteUrl: "deleteTable",
        deleteBtnSettings : {
            color: "error",
            style:"height: 25px !important; margin-top: 27%",
        },
        clientEvents : {
          onBeforeSave :function (selectedItem,creatableObjects,model,tableObject) {
            creatableObjects.initialFilter = tableObject.initialFilter
          },
        },
        initialFilter:{
          [Enum.initialFilter.AND] :
          [
            {
              projectId: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id
              }
            }
          ]
        },
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
                [
                  {
                    id: "creditInstallment",
                    type: "v-file-input",
                    style:"height: 25px!important; width: 250px; margin-bottom: 25px;",
                    label: "Kredi Taksit",
                    inputHeader: "creditInstallment",
                    name: "creditInstallment",
                    multiple: true,
                    onChange: async function(thisData, model, dynamicElements) {
                      let files = model.creditInstallment[0];
                      let choiceData = {
                          choice : "creditInstallment", 
                          headerAddFlag : true, 
                          fileName : files.name,
                          fileType : 'creditInstallment',
                          cookieData: window.$cookies.get("selectedItem")
                      }
                      showLoader('Dosyanız Hazırlanıyor')
                      uploadHelperFormDataQueue([files], "creditExcelUpload", Enum.requestType.POST, choiceData, 
                      function (response) {
                        if (response.data.data.errorExcel != null) {
                        openSnackbar("error", "Hatalı Excel Taslağı Yüklediniz", "2000")
                        }
                        hideLoader();
                        that.renderCreditTable(thisData, that.model.creditTable, dynamicElements)
                        openSnackbar("success", "Verileriniz Başarılı Bir Şekilde Kaydedildi", "2000")
                      },
                      function (error) {
                          openSnackbar(
                            "error",
                            error.response.data.error[0].detail.message
                          );
                        }
                      )
                    }
                  }
                ],
                [
                  {
                    id: "creditSpot",
                    type: "v-file-input",
                    style:"height: 25px!important; width: 250px; margin-bottom: 25px;",
                    label: "Kredi Spot",
                    inputHeader: "creditSpot",
                    name: "creditSpot",
                    multiple: true,
                    onChange: async function(thisData, model, dynamicElements) {
                      let files = model.creditSpot[0];
                      let choiceData = {
                          choice : "creditSpot", 
                          headerAddFlag : true, 
                          fileName : files.name,
                          fileType : 'creditSpot',
                          cookieData: window.$cookies.get("selectedItem")
                      }
                      showLoader('Dosyanız Hazırlanıyor')
                      uploadHelperFormDataQueue([files], "creditExcelUpload", Enum.requestType.POST, choiceData, 
                      function (response) {
                        if (response.data.data.errorExcel != null) {
                        openSnackbar("error", "Hatalı Excel Taslağı Yüklediniz", "2000")
                        }
                        hideLoader();
                        that.renderCreditTable(thisData, that.model.creditTable, dynamicElements)
                        openSnackbar("success", "Verileriniz Başarılı Bir Şekilde Kaydedildi", "2000")
                      },
                      function (error) {
                        openSnackbar("error", error.response.data.error[0].detail.message);
                      })
                    }
                  }
                ],
            ],
          }
        ],
        headers: [
          { text: "Banka Adı", value: "bankName", width: "100", divider: true,},
          { text: "Kullanılan Kredi", value: "creditAmount", width: "100", dataType: "currency", divider: true, align: "right"},
          { text: "Kredi No", value: "creditNo", width: "100", divider: true, align: "right"},
          { text: "Para Birimi", value: "monetaryType", width: "100", divider: true,},
          { text: "Kredi Alış Tarihi", value: "creditStartDate", width: "100", divider: true, dataType: "date"},
        ],
        columns: {
          creditNo: {
            type: "v-text-field",
            editable: false,
            creatable: true,
            inlineEditable: false,
            cellElement: {
              id: "creditNo",
              type: "v-btn",
              variant: "btn",
              name: "creditNo",
              inputText: "creditNo",
              divStyle: "",
              style: "height:0px;text-decoration: underline;color: blue !important;",
              btnTypeText: true,
              color: "black",
              prependIcon: "",
              classTag: "",
              onClick: function(thisData, model, dynamicElements) {
                console.log(thisData, model, dynamicElements);
                let dialogInfo = {
                  name: "infoTableDialog",
                  title: model.creditNo + " " + "Kredisi",
                  layout: [
                    {
                      type: Enum.layoutType.div,
                      name: "tableDialog",
                      tableObject: {
                        tableHeight: "400",
                        dialogTable: true,
                        renderKey: 50,
                        type: "table",
                        name: "infoTable",
                        class: "",
                        infoTotalRowCountHeaderAssistantTable: "Toplam satır sayısı",
                        selectionUrl: "creditNo",
                        hidePagination: false,
                        initialFilter:{
                          [Enum.initialFilter.AND] :
                          [
                            {
                              projectId: {
                                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem") == null ? undefined : window.$cookies.get("selectedItem").id
                              }
                            }
                          ]
                        },
                        filteredColumn: {
                          creditNo: model.creditNo,
                        },
                        toolbars: [],
                        headers: [
                        {
                            text: "Ödeme Tarihi",
                            value: "paymentDate",
                            search: true,
                            class: 'threedot',
                            divider: true, 
                            dataType: "date"
                          },
                          {
                            text: "Taksit Tutarı",
                            value: "installment",
                            search: true,
                            class: 'threedot',
                            divider: true, 
                            dataType: "currency"
                          },
                          {
                            text: "Faiz",
                            value: "interest",
                            width: "150",
                            search: true,
                            sortable: true,
                            divider: true, 
                            dataType: "currency"
                          },
                          {
                            text: "Anapara",
                            value: "capital",
                            search: true,
                            class: 'threedot',
                            divider: true, 
                            dataType: "currency"
                          },
                          {
                            text: "KKDF",
                            value: "kkdf",
                            width: "150",
                            search: true,
                            sortable: true,
                            divider: true, 
                            dataType: "currency"
                          },
                          {
                            text: "BSMV",
                            value: "bsmv",
                            search: true,
                            class: 'threedot',
                            divider: true, 
                            dataType: "currency"
                          },
                        ],
                        footer: [],
                        singleSelect: true,
                        itemKey: "id",
                        columns: {},
                      },
                    },
                  ],
                  actions: [
                  ],
                  titleActions: [
                    {
                      id: "close",
                      type: "v-btn",
                      variant: "btn",
                      name: "close",
                      divStyle: "",
                      inputText: "Kapat",
                      btnTypeText: true,
                      color: "red",
                      classTag: "",
                      icon: "mdi-close",
                      closeBtn: true,
                      onClick: function(thisData, model, dialogInfo) {
                        console.log(thisData, model, dialogInfo);
                        closeDialog();
                      },
                    },
                  ],
                };
                showDialog(dialogInfo);
              },
            },
          }
        }
      };
    }
  },
  copyItem(tableName,selectedItem,options,model,onBeforeSave,onAfterSave,errorFunction) {
      if (!Array.isArray(selectedItem)) {
          selectedItem = [selectedItem];
      }
      
      let dynamicElements = getTable(tableName);
      if (options != undefined && options.dialogText != undefined) {
          dynamicElements.deleteDialogText = options.dialogText;
      }
      
      let title = "Yeni Kayıt Eklemek İstediğinize Emin Misiniz?";
      
      dynamicElements.forceRender();
      model.close = "Hayır",
      model.insertItem = "Evet";
      dynamicElements.dialogVisible = true;
      dynamicElements.dialogInfo = {
          name:"insertDialog",
          maxWidth : "1100",
          dialogVisible : "dialogVisible",
          title : title,
          layout : [
              {
                type : Enum.layoutType.div,
                name : "loginForm",
      
                inputElements : [
                ]
              }
          ],
          actions : [
            {
              id : "close",
              type : "v-btn",
              variant : "btn",
              name : "close",
              inputText : "Kapat",
              divStyle:"",
              btnTypeText: true,
              color:"green darken-1",
              classTag : "",
              onClick : function(thisData,model,dialogInfo) {
                var tableObject = getTable(tableName);
                tableObject.dialogVisible = false;
                tableObject.forceRender();
                console.log(thisData,model,dialogInfo);
              }
            },
            {
              id : "insertItem",
              type : "v-btn",
              variant : "btn",
              name : "insertItem",
              inputText : "Ekle",
              divStyle:"",
              btnTypeText: true,
              color:"green darken-1",
              classTag : "",
              onClick : function(thisData,model,dialogInfo) {
                var tableObject = getTable(tableName);
                console.log(thisData,model,dialogInfo);
                tableObject.dialogVisible = false;
                tableObject.forceRender();
                let creatableObjects = {}; 
                sendRequest(Enum.requestType.POST, dynamicElements.saveUrl,"",{selectedItem,creatableObjects},function (res) {
                    openTablePage(tableName);
                    openSnackbar("success", "Eklemeniz Başarılı Bir Şekilde Gerçekleşti");
                },function (error) {
                    if (errorFunction != undefined) {
                        errorFunction(error,selectedItem,creatableObjects,model,tableObject);
                    } else if(tableObject.clientEvents.onSaveError != undefined){
                        tableObject.clientEvents.onSaveError(error,selectedItem,creatableObjects,model,tableObject);
                    }
                });
              }
            },
          ]
      };
    },
}

    

</script>

<style>
.header-div {
  text-align: center;
}
</style>