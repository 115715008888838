import { render, staticRenderFns } from "./forgottenPageComponent.vue?vue&type=template&id=37c6de1a&scoped=true&"
import script from "./forgottenPageComponent.vue?vue&type=script&lang=js&"
export * from "./forgottenPageComponent.vue?vue&type=script&lang=js&"
import style0 from "../../../../frontend/components/atoms/css/forgotten.css?vue&type=style&index=0&id=37c6de1a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../vue/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37c6de1a",
  null
  
)

export default component.exports