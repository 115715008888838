<template>
  <v-container fill-height fluid id="containerId">
    <v-row align="center" justify="center">
      <v-col>
        <v-card style="opacity: 85%!important;padding-top:20px"  class="mx-auto my-12 elevation-20" max-width="900" align="center" >
          <template slot="progress">
            <v-progress-linear
              color="deep-purple"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-img max-width="15em" :src="landingDefaultObject.companyScreenLogo" style="background-repeat:no-repeat;" ></v-img>
          <v-card-title style="display: flex; justify-align:center;justify-content: center;">{{landingDefaultObject.projectName}}</v-card-title>
          <v-card-text>
            <v-row align="center" class="mx-0">
            </v-row>      
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-text>
            <v-autocomplete
              label="Şirket Seçim Ekranı"
              :items="landingDefaultObject.companies"
              v-model="landingDefaultObject.selectedCompany"
              variant="underlined"
              item-text="name"
              item-value="name"
            ></v-autocomplete>
          </v-card-text>
          <v-row v-if="landingDefaultObject.projectLoginChoiceButtonItem == undefined ">
            <v-col>
                <button @click="landingDefaultObject.logOut" class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default">GERİ</button>
                <!-- <v-btn @click="setCompany">Giriş</v-btn> -->
                <button @click="landingDefaultObject.setCompany(landingDefaultObject.selectedCompany, landingDefaultObject.companies)" style="margin-left : 2%" class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default">GİRİŞ</button>
            </v-col>
          </v-row>
          <v-row v-if="landingDefaultObject.projectLoginChoiceButtonItem != undefined && landingDefaultObject.selectedCompany != ''">
            <v-col >
                <v-btn @click="landingDefaultObject.logOut">Geri</v-btn>
                <v-btn @click="landingDefaultObject.setCompany('KDVIADEM')" style="margin-left : 2%">KDV İADE HESAPLAMA</v-btn>
                <v-btn @click="landingDefaultObject.setCompany('KARSIT')" style="margin-left : 2%">KARŞIT İNCELEME</v-btn>
            </v-col>
          </v-row>
          <v-card-actions></v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
    props: {
        landingDefaultObject : {
          type: Object,
          default: () => ({
            companies: [],
            selectedCompany : "",
            projectName : "Notitek Yazılım",
            companyScreenLogo : "",
          })
        },
    },
    created() {
      this.landingDefaultObject.getCompany()
    },
    methods: {
      
    },
}
</script>

<style src='../../../../frontend/components/atoms/css/companySelect.css' scoped>


</style>