<template>
    <table-component :dynamic-element="dynamicElements" :model="model"/>
</template>

<script>
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json"
import {openTablePage,getTable,showDialog,closeDialog,hashCode} from "../../../frontend/components/atoms/helper/elementHelper.js"

export default {
    components: { 'table-component' : tableComponent  },
    data() {
        return {
            dynamicElements:{},
            model:{},
            renderKey : hashCode(),
            filters : {}
        };
    },
    created(){
      this.loadData();
    },

    methods :{
      loadData(){
          this.model = {
            
          }
          console.log(this.model)
          this.dynamicElements = {
            sortBy: [],
            sortDesc: false,
            renderKey:0,
            type : "table",
            name : "assistantTable",
            class : "",
            selectedRows : [],
            
            saveBtnText : "Yeni Kayıt",
            updateBtnText : "Güncelle",
            deleteBtnText : "Sil",
            
            filteredColumn : {},
            selectionUrl : "gettable",
            saveUrl : "insertAssistantTable",
            deleteUrl : "deleteAssistantTable",
            updateUrl : "updateAssistantTable",

            //showDefaultDeleteButon : false,
            //showDefaultSaveButon : false,
            //showDefaultUpdateButon : false,

            clientEvents : {
              onCellClick : function (/*row ve Column Index,prop bilgileri*/) {
                
              },
              onBeforeUpdate : function () {
                console.log('yapıldı')
              },
              onBeforeDelete : function () {
                console.log('yapıldı')
              },
              onBeforeSave : function () {
                console.log('yapıldı')
              },
              onAfterUpdate : function () {
                console.log('yapıldı')
              },
              onAfterSave: function () {
                console.log('yapıldı')
              },
              onAfterDelete :function () {
                console.log('yapıldı')
              },
              // onBeforeSelect : function () {
              //   console.log('onBeforeSelect yapıldı')
              // },
              // onAfterSelect : function () {
              //   console.log('onAfterSelect yapıldı')
              // },
              onSelect : function () {
                console.log('onSelect yapıldı')
              },
              onSaveError: function () {
                console.log('yapıldı')
              },
              onUpdateError : function () {
                console.log('yapıldı')
              },
              onDeleteError: function () {
                console.log('yapıldı')
              },
              //table için
              onBeforeunload: function () {
                console.log('yapıldı')
              },
              onafterUpload: function () {
                console.log('yapıldı')
              },
              dataChanged: function () {
                console.log('yapıldı')
              },
              searchOnClick: function () {
                console.log('yapıldı')
              },
            },

            toolbars : [
              {
                type : Enum.layoutType.div,
                name : "toolbarsForm",
                inputElements : [
                  // [
                  //     {
                  //       id : "save",
                  //       type : "v-btn",
                  //       variant : "btn",
                  //       name : "save",
                  //       inputText : "saveBtnText",
                  //       divStyle:"",
                  //       btnTypeText: false,
                  //       color : "primary",
                  //       prependIcon : "",
                  //       classTag : "",
                  //       beforeOnClick : function (thisData,model,dynamicElements) {
                  //           console.log("34" + thisData + dynamicElements)
                  //           console.log("beforeOnClick 1 2")
                  //       },
                  //       afterOnClick : function (thisData,model,dynamicElements) {
                  //           console.log("34" + thisData + dynamicElements)
                  //           console.log("afterOnClick 1 2")
                  //       },
                  //       onClick : function(thisData,model,dynamicElements) {
                  //         console.log("send saveItem" + thisData + dynamicElements)
                  //         let options = {
                  //           showDialog : true
                  //         }
                  //         saveItem('assistantTable',dynamicElements.selectedRows,options,model)
                  //       }
                  //     },
                  // ],
                  // [
                  //     {
                  //       id : "update",
                  //       type : "v-btn",
                  //       variant : "btn",
                  //       name : "update",
                  //       inputText : "updateBtnText",
                  //       divStyle:"",
                  //       btnTypeText: false,
                  //       color : "primary",
                  //       prependIcon : "",
                  //       classTag : "",
                  //       onClick : function(thisData,model,dynamicElements) {
                  //         console.log("send delete" + thisData + dynamicElements)
                  //         let options = {
                  //           showDialog : true
                  //         }
                  //         updateItem("assistantTable",dynamicElements.selectedRows,options,model)
                  //       }
                  //     },
                  // ],
                  // [
                  //     {
                  //       id : "delete",
                  //       type : "v-btn",
                  //       variant : "btn",
                  //       name : "delete",
                  //       inputText : "deleteBtnText",
                  //       divStyle:"",
                  //       btnTypeText: false,
                  //       color : "primary",
                  //       prependIcon : "",
                  //       classTag : "",
                  //       onClick : function(thisData,model,dynamicElements) {
                  //         console.log("send delete" + thisData + dynamicElements)
                  //         let options = {
                  //           showDialog : true
                  //         }
                  //         deleteItem("assistantTable",dynamicElements.selectedRows,options,model)
                  //       }
                  //     }
                  // ],

                  
                  [
                      {
                        id : "tableDialogBtn",
                        type : "v-btn",
                        variant : "btn",
                        name : "tableDialogBtn",
                        inputText : "Test Table",
                        divStyle:"",
                        btnTypeText: false,
                        color : "primary",
                        prependIcon : "",
                        classTag : "",
                        onClick : function(thisData,model,dynamicElements) {
                          console.log("send delete" + thisData + dynamicElements,model)
                          //var tableObject = getTable("assistantTable");
                          //render key olmazsa ekranda çizmez
                          //tableObject.renderKey++;
                          //tableObject.dialogVisible = true;
                          
                          let dialogInfo = {
                              name:"infoTableDialog",
                              maxWidth : "1100",
                              dialogVisible : "dialogVisible",
                              title : "Table Test",
                              layout : [
                                  {
                                    type : Enum.layoutType.div,
                                    name : "tableDialog",
                                    tableObject : {
                                      renderKey:0,
                                      type : "table",
                                      name : "infoTable",
                                      class : "",
                                      infoTotalRowCountHeaderAssistantTable: "Toplam satır sayısı",
                                      selectionUrl : "gettable",
                                      hidePagination : false,
                                      toolbars : [],
                                      headers : [
                                        { text: 'Fat (g)', value: 'fat'},
                                        { text: 'Carbs (g)', value: 'carbs'},
                                        { text: 'Protein (g)', value: 'protein'},
                                        { text: 'Iron (%)', value: 'iron' }
                                      ],
                                      data : "data2",
                                      showSelecetVisible : true,
                                      singleSelect:false,
                                      itemKey: "id",
                                      columns:{}
                                    }
                                  }
                              ],
                              actions : [
                                {
                                  id : "close",
                                  type : "v-btn",
                                  variant : "btn",
                                  name : "close",
                                  inputText : "Kapat",
                                  divStyle:"",
                                  btnTypeText: true,
                                  color:"green darken-1",
                                  classTag : "",
                                  onClick : function(thisData,model,dialogInfo) {
                                      var tableObject = getTable("assistantTable");
                                      tableObject.forceRender()
                                      tableObject.dialogVisible = false
                                      console.log(thisData,model,dialogInfo)
                                      closeDialog()
                                  }
                                }
                              ]
                          }
                          showDialog(dialogInfo)
                        }
                      }
                  ]
                ]
              }
            ],
            headers : [
              { text: 'Fat (g)', value: 'fat',frozen:true},
              { text: 'Carbs (g)', value: 'carbs',class : 'threedot'},
              { text: 'Protein (g)', value: 'protein'},
              { text: 'Iron (%)', value: 'iron' },
            ],
            showDialogColumnsCount : 6,
            //SETLE İŞLEMİ HEM ELEMNT HELPERDA HEMDE TABLEBODYDE OLUYOR DÜZELT!!! TODO
            columns : {
              protein:{
                type : "v-text-field",
                editable: false,
                creatable: true,
                inlineEditable:false,
                //cell için gerekli inputElement hücre özelliştirmesi için
                cellElement : {
                  id : "protein",
                  type : "v-btn",
                  variant : "btn",
                  name : "protein",
                  inputText : "protein",
                  divStyle:"",
                  btnTypeText: true,
                  //to : '/testTable',
                  color : "green",
                  prependIcon : "",
                  classTag : "",
                  onClick : function(thisData,model,dynamicElements) {
                    console.log("send " + thisData + dynamicElements)
                    console.log(model)
                    alert("alert test")
                  }
                }
              },
              iron : {
                type : "v-text-field",
                editable: false,
                creatable: true,
                inlineEditable:true
              },
              id: {
                type: "v-text-field",
                variant : "number",
                editable: false,
                creatable: true
              },
              fat : {
                type: "v-select",
                variant : "number",
                editable: true,
                creatable: true,
                inlineEditable:true, 
                //onsave içerisinde çağırılan bir function çağırılacak onSave den önce paremetre olarak json objesi olacak !!!ANIL
                dropDownItems: ["1", "2" , "3"],
                
                onChange : function(thisData,model,dynamicElements) {
                  alert("DEĞİŞTİM")
                  var tableObject = getTable("assistantTable");
                  tableObject.headers.push({ text: 'Fatsssss (g)', value: 'fat',frozen:true},)
                  dynamicElements.columns.carbs.dropDownItems = ['KDV1','KDV2']
                  //model.carbs = 'KDV1'
                  model.carbs1 = 'KDV'
                  tableObject.dialogInfo.layout[0].inputElements[1][0].dropDownItems = ['KDV1','KDV2']
                  //dynamicElements.dialogInfo.layout[0].inputElements[1][0].dropDownItems = ['KDV1','KDV2']
                  //tableObject.forceRender()
                  console.log(thisData,model,dynamicElements)
                  console.log("----------------")
                  console.log(tableObject)
                }
              },
              carbs : {
                type: "v-select",
                variant : "number",
                editable: true,
                creatable: true,
                inlineEditable:true, 
                //dropDownItems function da alabilecek her seçimde çalıştır
                dropDownItems: ['1','8','18'],
                //node a istek at inlineEditable true ise updateitem url ine gönder
                //multiple : true 
              },
              date : {
                type: "v-date-picker",
                editable: true,
                creatable: true,
                label : "Tarih",
              },
              customName : {
                type: "v-text-field",
                variant : "number",
                editable: true,
                creatable: true,
                label : "Özel İsim",
              },
              iron1 : {
                type : "v-text-field", 
                variant : "number",
                editable: false,
                creatable: true
              },
              id1: {
                type: "v-text-field",
                variant : "number",
                editable: false,
                creatable: true
              },
              carbs1 : {
                type: "v-text-field",
                editable: true,
                creatable: true
              },
              userValidate1 : {
                type: "v-date-picker",
                editable: true,
                creatable: true,
                label : "Kullanıcı Doğrulama",
              }
            },

            showSelecetVisible : true,

            //singleSelect:false,
            itemKey: "id",

            hidePagination : true,
            footer :  [
                {
                  type : Enum.layoutType.div,
                  name : "footerForm",
                  inputElements : [
                      [
                          {
                              id : "pageSize",
                              type : "v-text-field",
                              variant : "number",
                              name : "pageSize",
                              label : "Satır Sayısı",
                              value : "",
                              placeHolder : "",
                              style : "width: 55%; margin-left: 10%",
                              classTag : "",
                              onChange : function(thisData,model,dynamicElements) {
                                openTablePage("assistantTable")
                                console.log(thisData,model,dynamicElements)
                              }
                          },
                      ],
                      [
                          {
                            id : "currentPageNo",
                            type : "v-pagination",
                            variant : "pagination",
                            name : "currentPageNo",
                            divStyle:"",
                            style : "width:850px;",
                            classTag : "my-1",
                            length : "pagesCount",
                            onInput : function(thisData,model,dynamicElements) {
                              openTablePage("assistantTable")
                              console.log(thisData)
                              console.log(dynamicElements)
                              console.log(model)
                            }
                          }
                      ],
                      [
                          {
                            id : "infoTotalRowCountHeaderAssistantTable",
                            type : "p",
                            variant : "p",
                            name : "infoTotalRowCountHeaderAssistantTable",
                            inputText : "Toplam satır sayısı",
                            divStyle:"",
                            style:"font-size: 12px;font-weight:500;margin:3px 0;color:#666A6F;margin-right:10px;",
                            classTag : "",
                          },
                          {
                            id : "infoTotalRowCountAssistantTable",
                            type : "p",
                            variant : "p",
                            name : "infoTotalRowCountAssistantTable",
                            inputText : "itemCount",
                            divStyle:"",
                            style:"font-size: 12px;font-weight:500;margin:3px 0;color:#666A6F;margin-right:10px;",
                            classTag : "",
                          },
                      ]
                  ]
                }
            ]
          }
      },
    }
}
</script>