import { Pie } from './BaseCharts'

export default {
  extends: Pie,
  props: {
    chartObj : { }
  },
  mounted () {
    let vm = this;
    this.renderChart({
      labels: vm.chartObj.labels,
      datasets: vm.chartObj.datasets
    }, {responsive: true, maintainAspectRatio: false})
  }
}