<template>
  <v-app>
    <v-row justify="center">
      <v-col md="5" v-if="projectSelected">
        <upload-component :dynamic-element="netsisElements" :model="model" style="margin-top: 5%;"/>
        <upload-component :dynamic-element="logoElements" :model="model" style="margin-top: 5%;"/>
      </v-col>
      <v-col md="5" v-if="projectSelected">
        <upload-component :dynamic-element="lucaElements" :model="model" style="margin-top: 5%;"/>
        <upload-component :dynamic-element="eBookElements" :model="model" style="margin-top: 5%;"/>
      </v-col>
      <v-col md="5" v-if="projectSelected">
        <upload-component :dynamic-element="sapElements" :model="model" style="margin-bottom: 20%;"/>
      </v-col>
      <v-col md="5" v-if="projectSelected">
        <upload-component :dynamic-element="caniasElements" :model="model" style="margin-bottom: 20%;"/>
      </v-col>
    </v-row>
    <Loader></Loader>
    <snackbar-component />
  </v-app>
</template>

<script>
import tableComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import { showLoader, hideLoader } from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
//import { uploadFileAssistant } from "../tools/uploadFile.js";
import { sendRequest, uploadHelperFormDataQueue, axiosRequestTypeArrayBuffer } from "../../../frontend/components/atoms/helper/requestHelper";
import {openSnackbar} from "../../../frontend/components/atoms/helper/elementHelper.js";
import {axiosUploadTypeArrayBuffer, checkProject} from "../../helper/uploadHelperForData"
export default {
  components: {
    "upload-component": tableComponent,
    Loader: Loader,
    "snackbar-component": snackbarComponent,
  },

  data() {
    return {
      netsisElements: [],
      logoElements: [],
      lucaElements: [],
      eBookElements: [],
      sapElements: [],
      caniasElements: [],
      model: {},
      renderKey: 0,
      projectSelected: false
    };
  },

  created() {
    this.loadData();
    checkProject();
    if (window.$cookies.get("selectedItem") == null || window.$cookies.get("selectedItem") == 'null') {
      this.projectSelected = false
    } else {
      this.projectSelected = true
    }
  },
  watch: {},
  methods: {
    loadData() {
      let that = this;
      this.netsisElements = [
        {
          renderKey: 0,
          name: "sss",
          interfaceName: "NETSIS",
          width: 400,
          layout: [
            {
              type: Enum.layoutType.div,
              name: "loginForms",
              inputElements: [
                [
                  {
                    id: "netsis",
                    type: "v-file-input",
                    label: "Dosyanızı Seçiniz",
                    inputHeader: "Netsis",
                    name: "muavinNetsis",
                    accept: ".xlsx, .xls",
                    onChange: function (thisData, model, dynamicElements) {
                      that.uploadFileOnChange(model,"muavinNetsis")
                    },
                  },
                ],
              ],
            },
          ],
        },
      ];
      this.logoElements = [
        {
          renderKey: 0,
          name: "sss",
          interfaceName: "LOGO",
          width: 400,
          layout: [
            {
              type: Enum.layoutType.div,
              name: "loginForms",
              inputElements: [
                [
                  {
                    id: "logo",
                    type: "v-file-input",
                    label: "Dosyanızı Seçiniz",
                    inputHeader: "Logo",
                    name: "muavinLogo",
                    accept: ".xlsx, .xls",
                    onChange: async function (thisData,model,dynamicElements) {
                      that.uploadFileOnChange(model,"muavinLogo")
                    },
                  },
                ],
              ],
            },
          ],
        },
      ];
      this.lucaElements = [
        {
          renderKey: 0,
          name: "lucaUpload",
          interfaceName: "LUCA",
          width: 400,
          layout: [
            {
              type: Enum.layoutType.div,
              name: "uploadLayout",
              inputElements: [
                [
                  {
                    id: "luca",
                    type: "v-file-input",
                    label: "Dosyanızı Seçiniz",
                    inputHeader: "Luca",
                    name: "muavinLuca",
                    accept: ".xlsx, .xls",
                    onChange: async function (thisData,model,dynamicElements) {
                      that.uploadFileOnChange(model,"muavinLuca")
                    },
                  },
                ],
              ],
            },
          ],
        },
      ];
      this.eBookElements = [
        {
          renderKey: 0,
          name: "eBookUpload",
          interfaceName: "E-DEFTER",
          width: 400,
          layout: [
            {
              type: Enum.layoutType.div,
              name: "uploadLayout",
              inputElements: [
                [
                  {
                    id: "eBook",
                    type: "v-file-input",
                    label: "Dosyanızı Seçiniz",
                    inputHeader: "Ebook",
                    name: "muavinEbook",
                    onChange: async function (thisData,model,dynamicElements) {
                      that.uploadFileOnChange(model,"muavinEbook")
                    },
                  },
                ],
              ],
            },
          ],
        },
      ];
      this.sapElements = [
        {
          renderKey: 0,
          name: "sapUpload",
          interfaceName: "SAP",
          width: 400,
          layout: [
            {
              type: Enum.layoutType.div,
              name: "uploadLayout",
              inputElements: [
                [
                  {
                    id: "sap",
                    type: "v-file-input",
                    label: "Dosyanızı Seçiniz",
                    inputHeader: "sap",
                    name: "sap",
                    onChange: async function (thisData,model,dynamicElements) {
                      that.uploadFileOnChange(model,"sap")
                    },
                  },
                ],
              ],
            },
          ],
        },
      ];
      this.caniasElements = [
        {
          renderKey: 0,
          name: "caniasUpload",
          interfaceName: "CANIAS",
          width: 400,
          layout: [
            {
              type: Enum.layoutType.div,
              name: "uploadLayout",
              inputElements: [
                [
                  {
                    id: "canias",
                    type: "v-file-input",
                    label: "Dosyanızı Seçiniz",
                    inputHeader: "canias",
                    name: "canias",
                    onChange: async function (thisData,model,dynamicElements) {
                      that.uploadFileOnChange(model,"canias")
                    },
                  },
                ],
              ],
            },
          ],
        },
      ];
    },
    uploadFileOnChange(model,fileTypeName){
      let that = this
      let file = model[fileTypeName][0]

      if(model[fileTypeName][0] == undefined){
        file = model[fileTypeName]
      }

      if (file.size > 0 && (file.size / (1024 * 1024)) < 50) {
        showLoader("Dosyanız Yükleniyor. Lütfen Bekleyiniz.");
        //kuyruk için endpoint allFileUploadForFolderNotification
        let files = model[fileTypeName]

        if (!Array.isArray(files)) {
          files = [model[fileTypeName]]
        }

        let choiceData = {
          fileType : fileTypeName,
          projectId : window.$cookies.get("selectedItem").id,
          nextInsertType : "MuavinUpload",
          insertType : "MuavinValidation",
          projectType : "didero",
          choice: fileTypeName,
          fileName : model[fileTypeName],
          projectInfo: window.$cookies.get("selectedItem")
        }
        
        //kuyruksuz için muavinExcelUpload
        // let files = model[fileTypeName]
        // let choiceData = {
        //   fileType : fileTypeName,
        //   cookieData: window.$cookies.get("selectedItem")
        // }
        uploadHelperFormDataQueue(files, "allFileUploadForFolderNotification", Enum.requestType.POST, choiceData, async function (response) {
          if (response.data.data.errorExcel != undefined) {
            // openSnackbar("error",
            //  "Taslak ile form uyumlu değildir. Lütfen yeniden yükleyiniz."
            // );
            await axiosRequestTypeArrayBuffer(response)
            hideLoader();
          } else {
            hideLoader();
            if (response.data.data.value == "Daha öncesinde yüklediğiniz aylara ait muavin yüklemesi yapamazsınız.") {
              openSnackbar("error", response.data.data.value);
            } else if (response.data.data.draftError == 'Hatalı EXCEL formatı') {
              openSnackbar("error", "Taslak Hatası", 3000);
            }
            else{
              openSnackbar("success", response.data.data.value);
              //that.$router.push("testTable")
              that.$router.push("folderNotification")
            }
            
          }
        })
      }
    }
  },
};
</script>
