<template>
  <login-component :projectDesign="projectDesign"/>
</template>

<script>
import  loginComponent from "../../../frontend/components/atoms/container/loginPageComponent.vue";
import {sendRequest} from "../../../frontend/components/atoms/helper/requestHelper.js"
import {openSnackbar , setLoggedUser} from "../../../frontend/components/atoms/helper/elementHelper.js"
import logoLeftBox from"../assets/Notitek-Logo-White.png"
import rightLeftBox from"../assets/Didero-siyah.png"
import { router } from '../router/router'
import store from '../store/index'
import Enum from "../../../frontend/components/Enum.json";
export default {
  components: { 'login-component' : loginComponent  },
  data: () => {
    return{
      dynamicElements:[],
      model:{},
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model1: [],
      projectDesign : {}
    }
    },
  created(){
    this.LoginPageProp()
  },
    
  methods : {
    LoginPageProp(){
      this.projectDesign = { 
        color : "background-color: #4a922a!important;",
        logoBackground : "background-color: #4a922a!important;",
        leftBoxLogo : logoLeftBox,
        rightBoxLogo : rightLeftBox,
        btnStyle : "background-color : #4a922a; color : white; -webkit-border-radius: 16px",
        loginFunction : function (userName,password) {
          if (userName != '' && password!= '') {
            let that = this
            sendRequest(Enum.requestType.POST, "login", "",{ email: userName, password: password },function (response) {
                let that = this
                if (response.data.data.value != undefined && (response.data.data.value[0].toLowerCase() == "ş" || response.data.data.value[0].toLowerCase() == "h" || response.data.data.value[0].toLowerCase() == "k")) {
                  openSnackbar("error", response.data.data.value, 2000)
                  return false
                }
                const userToken = response.data.data.token
                let newInfo = {}
                newInfo.isloggedin = true
                newInfo.token = userToken
                newInfo.userId = response.data.data.userId
                newInfo.userName = response.data.data.userName
                setLoggedUser("loggedUser", newInfo)
              },
              function (error) {
                debugger
                openSnackbar("error", error.response.data.error[0].detail.message);
              }
            );
          }else{
            openSnackbar("error", "Kullanıcı Adı Veya Şifre Hatalı", 2000)
          }
          
        },
        forgetPassword: function () {
          router.push("forgotten");
        }
      }
    }
  }
}
</script>

