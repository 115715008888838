<template>
  <v-app>
    <v-row justify="center">
      <v-col md="3">
        <upload-component :dynamic-element="amortismanElements" :model="model" />
      </v-col>
    </v-row>
    <Loader></Loader>
    <snackbar-component />
  </v-app>
</template>

<script>
import tableComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import { showLoader, hideLoader } from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import { sendRequest,uploadHelperFormDataQueue, axiosRequestTypeArrayBuffer } from "../../../frontend/components/atoms/helper/requestHelper";
import {openSnackbar} from "../../../frontend/components/atoms/helper/elementHelper.js";

export default {
  components: {
    "upload-component": tableComponent,
    Loader: Loader,
    "snackbar-component": snackbarComponent,
  },

  data() {
    return {
      amortismanElements: [],
      model: {},
      renderKey: 0,
    };
  },

  created() {
    this.loadData();
  },
  watch: {},
  methods: {
    loadData() {
      let that = this;
      this.amortismanElements = [
        {
          renderKey: 0,
          name: "sss",
          interfaceName: "Amortisman Excel Yükle",
          width: 400,
          layout: [
            {
              type: Enum.layoutType.div,
              name: "loginForms",
              inputElements: [
                [
                  {
                    id: "amortisman",
                    type: "v-file-input",
                    label: "Dosyanızı Seçiniz",
                    inputHeader: "AmortismanExcel",
                    name: "amortismanExcel",
                    onChange: function (thisData, model, dynamicElements) {
                      that.uploadFileOnChange(model,"amortismanExcel",)
                    },
                  },
                ],
              ],
            },
          ],
        },
      ];
    },
    uploadFileOnChange(model,fileTypeName){
      let that = this
      if (model[fileTypeName].size > 0) {
        showLoader("Dosyanız Yükleniyor. Lütfen Bekleyiniz.");
        sendRequest("POST","tableStatus","",{tableName: 'inflation_report',projectId: window.$cookies.get("selectedItem").id},
          function(response) {
            if (!response.data.data.value) {
              openSnackbar("error", "İlk Önce Mizan Yükleyeniz");
              hideLoader();
            }
            else{
              let files = model[fileTypeName]
              let choiceData = {
                choice : "amortismanExcel", 
                headerAddFlag : true, 
                projectId : window.$cookies.get("selectedItem").id,
                insertType : "AmortismanValidation",
                nextInsertType : "AmortismanDBInsert",
                fileName : model[fileTypeName].name,
                auditDate: window.$cookies.get("selectedItem").auiditDate,
                preriorDate: window.$cookies.get("selectedItem").preriorDate,
                explain : window.$cookies.get("selectedItem").explain
              }
              uploadHelperFormDataQueue([files], "excelUpload", Enum.requestType.POST, choiceData, 
              async function (response) {
                if(response.status == 200){
                  if (response.data.data.errorExcel != undefined) {
                  await axiosRequestTypeArrayBuffer(response)
                  openSnackbar("error", "Taslak Hatası");
                  hideLoader();
                  } 
                  else {
                    hideLoader();
                    if (response.data.data.value == "Daha öncesinde yüklediğiniz aylara ait amortisman yüklemesi yapamazsınız.") {
                      openSnackbar("error", response.data.data.value);
                    }else{
                      //openSnackbar("success", response.data.data.value);
                      that.$router.push("folderNotification")
                    }
                  }
                }else{
                  hideLoader();
                  openSnackbar("error", response.error[0].detail.message);
                }
              })
            }
          },
          function(error) {
            if (error.response != undefined) {
              openSnackbar(
                "error",
                error.response.data.error[0].detail.message
              );
            } else openSnackbar("error", "Sunucu Hatası");
          }
        );
      }
    }
  },
};
</script>
