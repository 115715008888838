<template>
  <v-app>
    <table-component :dynamic-element="tableElements" :model="model" />    
    <Loader></Loader>
    <snackbar-component />
  </v-app>
</template>

<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import {
  openSnackbar,
  openTablePage
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import { sendRequest } from "../../../frontend/components/atoms/helper/requestHelper";
import {axiosUploadTypeArrayBuffer} from "../../helper/uploadHelperForData"
export default {
  components: {
    "table-component": tableComponent,
    "upload-component": uploadComponent,
    Loader: Loader,
    "snackbar-component": snackbarComponent,
  },

  data() {
    return {
      tableElements: {},
      model: {},
      renderKey: 0,
    };
  },

  created() {
    this.loadData();
  },
  watch: {},
  methods: {
    loadData() {
      this.tableElements = {
        sortBy: [],
        sortDesc: false,
        renderKey: 0,
        type: "table",
        tableHeight : 420,
        name: "tufe",
        class: "",
        infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
        deleteBtnText: "Sil",
        searchBtnText: "Ara",
        excelDownloadBtnText: "İndir",
        deleteDialogVisible : false,
        hidePagination: true,
        selectedRows: [],
        filteredColumn: {},
        filteredColumns: true,
        selectionUrl: "tufeTable",
        deleteUrl: "declarationdeletion",
        showDefaultDeleteButon : false,
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
              [
                {
                  id: "excelDownload",
                  type: "v-btn",
                  variant: "btn",
                  name: "excelDownload",
                  inputText: "excelDownloadBtnText",
                  style: "height: 25px !important;",
                  divStyle: "",
                  icon: "mdi-file-excel",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    sendRequest(Enum.requestType.POST,"tufeTable",null,
                          {
                              filter : {
                                choice : "tufe", orderBy : "orderBy"
                              },
                              excelDownload : true
                          },
                          function (response) {
                            let zipName = "Tüfe Güncel Tablo Listesi" + " " /*+ periodName*/
                            axiosUploadTypeArrayBuffer("allExcelZipDownload","tufe", zipName, "Excel İndirildi")
                            console.log(response);
                          },
                          function (error) {
                            openSnackbar(
                              "error",
                              error.response.data.error[0].detail.message
                            );
                          }
                        )
                  },
                },
              ]
            ],
          }
        ],
        headers: [
          { text: "Yıl", value: "year", width: "100" , search: true , sortable: true},
          { text: "Ocak", value: "january", width: "100" , search: true , sortable: true, dataType: "currency"},
          { text: "Şubat", value: "february", width: "100" , search: true , sortable: true , dataType: "currency"},
          { text: "Mart", value: "march", width: "100" , search: true , sortable: true, dataType: "currency" },
          { text: "Nisan", value: "april", width: "100" , width: "100", search: true , sortable: true , dataType: "currency" },
          { text: "Mayıs", value: "may", width: "100" , width: "100", search: true , sortable: true, dataType: "currency" },
          { text: "Haziran", value: "june", width: "100" , width: "100", search: true , sortable: true , dataType: "currency"}, 
          { text: "Temmuz", value: "july", width: "100" , width: "100", search: true , sortable: true , dataType: "currency"}, 
          { text: "Ağustos", value: "agust", width: "100" , width: "100", search: true , sortable: true, dataType: "currency" }, 
          { text: "Eylül", value: "september", width: "100" , width: "100", search: true , sortable: true, dataType: "currency" }, 
          { text: "Ekim", value: "october", width: "100" , width: "100", search: true , sortable: true , dataType: "currency"}, 
          { text: "Kasım", value: "november", width: "100" , width: "100", search: true , sortable: true , dataType: "currency"}, 
          { text: "Aralık", value: "december", width: "100" , width: "100", search: true , sortable: true , dataType: "currency"}, 
        ],
        columns: {

        },
        data: "data",
        showSelecetVisible: false,
        singleSelect: false,
        itemKey: "year",
        footer: [
          {
            type: Enum.layoutType.div,
            name: "footerForm",
            inputElements: [
              [
                {
                  id: "pageSize",
                  type: "v-text-field",
                  variant: "number",
                  name: "pageSize",
                  label: "Görünecek Satır Sayısı",
                  value: "",
                  placeHolder: "",
                  style: "width: 45%; margin-left: 5%",
                  classTag: "",
                  onChange: function(thisData, model, dynamicElements) {
                    openTablePage("tufe")
                  },
                },
              ],
              [
                {
                  id: "currentPageNo",
                  type: "v-pagination",
                  variant: "pagination",
                  name: "currentPageNo",
                  divStyle: "",
                  style: "width:850px;",
                  classTag: "my-1",
                  length: "pagesCount",
                  onInput: function(thisData, model, dynamicElements) {
                    openTablePage("tufe")
                  },
                },
              ],
              [
                {
                  id: "infoTotalRowCountHeaderAssistantTable",
                  type: "p",
                  variant: "p",
                  name: "infoTotalRowCountHeaderAssistantTable",
                  inputText: "infoTotalRowCountHeaderAssistantTable",
                  divStyle: "",
                  style: "font-size: 12px;font-weight:500;margin:3px 0;color:#666A6F;margin-right: 2%;margin-left: 60%;",
                  classTag: ""
                },
                {
                  id: "infoTotalRowCountAssistantTable",
                  type: "p",
                  variant: "p",
                  name: "infoTotalRowCountAssistantTable",
                  inputText: "itemCount",
                  divStyle: "",
                  style: "font-size: 15px;font-weight:500;margin:3px 0;color:#666A6F;margin-left: 82%;",
                  classTag: ""
                },
              ],
            ],
          },
        ]
      };
    }
  },
};
</script>

<style scoped>
::v-deep .threedot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 300px;
}
::v-deep .threedot:hover {
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}
</style>
