export function convertTime(value, selection) {

  let dateArray = value.split("/");
  if (dateArray.length == 1) {
    dateArray = value.split(".");
    if (dateArray.length == 1) {
      dateArray = value.split("-");
    }
  }
  if (dateArray[0].startsWith("0", 0)) {
    dateArray[0] = dateArray[0].slice(1);
  }
  if (dateArray[1].startsWith("0", 0)) {
    dateArray[1] = dateArray[1].slice(1);
  }
  if (selection == "Gun-Ay-Yıl") {
    return dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0]

  } else if (selection == "Ay-Gun-Yıl") {
    return dateArray[2] + "-" + dateArray[0] + "-" + dateArray[1]
  }
}


export function convertTimeLocally(value, selection) {

  let conditions = [".", "/", "-"]
  let comparisionResult = conditions.some(el => value.includes(el));
  if (!comparisionResult) {
    return ""
  }
  const options = { year: 'numeric', month: 'numeric', day: 'numeric' };

  let dateArray = value.split("/");
  if (dateArray.length == 1) {
    dateArray = value.split(".");
    if (dateArray.length == 1) {
      dateArray = value.split("-");
    }
  }
  if (dateArray[0].startsWith("0", 0)) {
    dateArray[0] = dateArray[0].slice(1);
  }
  if (dateArray[1].startsWith("0", 0)) {
    dateArray[1] = dateArray[1].slice(1);
  }
  if (selection == "Gun-Ay-Yıl") {
    let newDate = new Date(dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0])
    return newDate.toLocaleString("tr-TR", options)
  } else if (selection == "Ay-Gun-Yıl") {
    let newDate = new Date(dateArray[2] + "-" + dateArray[0] + "-" + dateArray[1]).toLocaleString("tr-TR", options)
    return newDate.toLocaleString("tr-TR", options)
  }
}

export async function convertTimeLocallyDateValue(country, value) {

  if (value!=undefined) {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour12: false };
    // return new Date(Math.round((date - 25569)*86400*1000));
    options.timeZone = 'UTC';
    options.timeZoneName = 'short';
    return new Date(value).toLocaleString(country)
  } else {
    return ""
  }
}




export function convertDateTurkshFormatSync(date) {
  let formatedDate = ""
  //eğer / içeriyorsa ay/gün/yıl formatında gelir
  if (date.includes("/")) {
    let x = date.split("/")
    if (x[0].length == 1) {
      x[0] = "0" + x[0]
    }
    if (x[1].length == 1) {
      x[1] = "0" + x[1]
    }
    if (x[2].length == 2) {
      x[2] = "20" + x[2]
    }
    formatedDate = x[1] + "." + x[0] + "." + x[2]
  }
  //eğer . içeriyorsa formatlanmadığı gün.ay.yıl şeklinde gelir
  else if (date.includes(".")) {
    let x = date.split(".");
    if (x[0].length == 1) {
      x[0] = "0" + x[0]
    }
    if (x[1].length == 1) {
      x[1] = "0" + x[1]
    }
    if (x[2].length == 2) {
      x[2] = "20" + x[2]
    }
    formatedDate = x[0] + "." + x[1] + "." + x[2]
  }
  return formatedDate
}

//31.12.2019
export async function convertDateTurkshFormat(date) {
  let formatedDate = ""
  //eğer / içeriyorsa ay/gün/yıl formatında gelir
  if (date.includes("/")) {
    let x = date.split("/")
    if (x[0].length == 1) {
      x[0] = "0" + x[0]
    }
    if (x[1].length == 1) {
      x[1] = "0" + x[1]
    }
    if (x[2].length == 2) {
      x[2] = "20" + x[2]
    }
    formatedDate = x[1] + "." + x[0] + "." + x[2]
  }
  //eğer . içeriyorsa formatlanmadığı gün.ay.yıl şeklinde gelir
  else if (date.includes(".")) {
    let x = date.split(".");
    if (x[0].length == 1) {
      x[0] = "0" + x[0]
    }
    if (x[1].length == 1) {
      x[1] = "0" + x[1]
    }
    if (x[2].length == 2) {
      x[2] = "20" + x[2]
    }
    formatedDate = x[0] + "." + x[1] + "." + x[2]
  }
  return formatedDate
}

export function convertDatePeriodTurkshFormatSync(date) {
  let formatedDatePeriod = ""
  if (date.includes("/")) {
    let x = date.split("/")
    if (x[0].length == 1) {
      x[0] = "0" + x[0]
    }
    if (x[1].length == 1) {
      x[1] = "0" + x[1]
    }
    if (x[2].length == 2) {
      x[2] = "20" + x[2]
    }
    formatedDatePeriod = x[0] + "." + x[2]
  }
  else if (date.includes(".")) {
    let x = date.split(".")
    if (x[0].length == 1) {
      x[0] = "0" + x[0]
    }
    if (x[1].length == 1) {
      x[1] = "0" + x[1]
    }
    if (x[2].length == 2) {
      x[2] = "20" + x[2]
    }
    formatedDatePeriod = x[1] + "." + x[2]
  }
  return formatedDatePeriod
}

export async function convertDatePeriodTurkshFormat(date) {
  let formatedDatePeriod = ""
  if (date.includes("/")) {
    let x = date.split("/")
    if (x[0].length == 1) {
      x[0] = "0" + x[0]
    }
    if (x[1].length == 1) {
      x[1] = "0" + x[1]
    }
    if (x[2].length == 2) {
      x[2] = "20" + x[2]
    }
    formatedDatePeriod = x[0] + "." + x[2]
  }
  else if (date.includes(".")) {
    let x = date.split(".")
    if (x[0].length == 1) {
      x[0] = "0" + x[0]
    }
    if (x[1].length == 1) {
      x[1] = "0" + x[1]
    }
    if (x[2].length == 2) {
      x[2] = "20" + x[2]
    }
    formatedDatePeriod = x[1] + "." + x[2]
  }
  return formatedDatePeriod
}

  export function convertDateDDMMYYYY(date){
    return date.toLocaleString("tr-TR").split(" ")[0] 
  }

  export function currencyFormat(num, decimalLength, thousandLength, decimalSeparator, thousandSeparator) {
    let number = typeof num
    if ("number" == number) {
      if(decimalSeparator == thousandSeparator || isNaN(num))
      throw num
      let left = num.toFixed(thousandLength).replace('.', decimalSeparator).replace(new RegExp('(\\d)(?=(\\d{'+thousandLength+'})+(?!\\d))', 'g'), '$1' + thousandSeparator).split(decimalSeparator)[0];
      let right = num.toFixed(decimalLength).replace('.', decimalSeparator).replace(new RegExp('(\\d)(?=(\\d{'+decimalLength+'})+(?!\\d))', 'g'), '$1' + thousandSeparator).split(decimalSeparator)[1];
      const total = left + decimalSeparator + right;
      return total
    }else{
      return num
    }
  }

  export async function selectDateTwoDigit(date){
    let splitDate = date.split("/")
    if (splitDate[0] < 10) {
      date = splitDate[0].substr(1, 2) + "/" + splitDate[1]
      return date
    }else{
      return date
    }
  }

  export  function sortDateArray(dateArray){
    let array = dateArray
    let result = []
    for (let i = 0; i < array.length; i++) {
      let splitArray = array[i].split("/")
      let int = splitArray[1] + splitArray[0]
      result.push(parseInt(int))
    }  
    result.sort() 
    array = []
    for (let i = 0; i < result.length; i++) {
      let period = String(result[i])
      let year = period.substring(0,4)
      let month = period.substring(4,6)
      let resultt = month + "/" + year
      array.push(resultt)
    }
    return array ; 
  }

  
