import axios from "axios";

export function getUser() {
    return axios.get("getUser");
}

export function getPeriods(choice) {
    return axios.post("getselecteddates", { choice });
}

export function startCompanySession(userId, companyId) {
    return axios.post("startCompanySession", {userId, companyId}, { headers: { companyId }})
}

export function logOut(userId, companyId) {
    return axios.post("logOut", {userId, companyId}, { headers: { companyId }})
}

export function checkOnline(companyId) {
    return axios.post("checkonline",{companyId:companyId});
}

export function checkCompanyFlag() {
    return axios.get("checkCompanyFlag")
}