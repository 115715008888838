<template>
    <v-edit-dialog
    :key="renderKey"
    :name="inputElement.name"
    save-text="Kaydet"
    cancel-text="İptal"
    :return-value.sync="model[inputElement.name]"
    permanent
    large
    @save="save(inputElement.onSave,inputElement)"
    @cancel="cancel"
    @open="open"
    @close="close"
  >
  <div v-if="tableHeader.dataType =='date'">{{ (model[inputElement.name] == null) ? null : new Date(model[inputElement.name]).toLocaleString("tr-TR").split(" ")[0] }}</div>
  <div v-else-if="tableHeader.dataType =='currency'">{{ currencyFormat(model[inputElement.name] ,2,3,",",".") }}</div>
  <div v-else-if="tableHeader.dataType =='array'">{{ (model[inputElement.name] == undefined || model[inputElement.name] == null) ?  null : model[inputElement.name].join(", ") }}</div>
  <div v-else-if="tableHeader.switchValue">
    <v-chip
    v-if="tableHeader.colorRow"
    :color="getColor(model[inputElement.name],tableHeader.specialColorRow)"
    dark
    >
      {{typeof  tableHeader.switchValue == 'function' ? tableHeader.switchValue(model[inputElement.name]) :  tableHeader.switchValue[model[inputElement.name]]}}
    </v-chip>
    <v-chip 
      v-else
      color="white"
    >
      {{typeof  tableHeader.switchValue == 'function' ? tableHeader.switchValue(model[inputElement.name]) :  tableHeader.switchValue[model[inputElement.name]]}}
    </v-chip>
  </div>
  <div v-else>{{model[inputElement.name]}}</div>
  <template v-slot:input>
    <input-component :input-element="inputElement" :model="model" :dynamic-elements="dynamicElements"/>
  </template>
  </v-edit-dialog>
</template>

<script>
import {getDiff} from '../helper/jsonHelper.js'
import InputComponent from '../input/inputComponent.vue'
import {hashCode} from '../helper/elementHelper.js'
export default {
    components: { 'input-component' : InputComponent },
    props: {
        inputElement : {
          type: Object,
          default: () => ({

          })
        },
        dynamicElements : {
          type: Array,
          default: () => [{}]
        },
        tableHeader : {},
        model : {}
    },
    data: () => ({
        renderKey : hashCode()
    }),
    created(){
      /// durdurulabilir, elimizde element var, fonksiyon -- elimizdeki itemlar için bu fonksiyon çağırılacak true yada false dönecek (mounted da olabilir)
    },
    methods:{
      getColor (value,specialColorRow) {
        if (specialColorRow != undefined){
          for (let index = 0; index < specialColorRow.length; index++) {
            if (value == specialColorRow[index].value) return specialColorRow[index].color
          }
        } 
        else{
          if (value == 0) return 'green'
          else if (value == 1) return 'red'
          else return 'green'
        }
      },
      currencyFormat(num, decimalLength, thousandLength, decimalSeparator, thousandSeparator) {
        let number = typeof num
        if ("number" == number) {
          if(decimalSeparator == thousandSeparator || isNaN(num))
          throw num
          let left = num.toFixed(thousandLength).replace('.', decimalSeparator).replace(new RegExp('(\\d)(?=(\\d{'+thousandLength+'})+(?!\\d))', 'g'), '$1' + thousandSeparator).split(decimalSeparator)[0];
          let right = num.toFixed(decimalLength).replace('.', decimalSeparator).replace(new RegExp('(\\d)(?=(\\d{'+decimalLength+'})+(?!\\d))', 'g'), '$1' + thousandSeparator).split(decimalSeparator)[1];
          const total = left + decimalSeparator + right;
          return total
        }else{
          return num
        }
      },
      save(onSave,prop) {
        if (onSave != undefined) {
          let cloned = JSON.parse(JSON.stringify(this.model))
          let clonedDynamicElements = JSON.parse(JSON.stringify(this.dynamicElements))
          onSave(prop,this.model,this.dynamicElements)
          let diff = getDiff(this.model,cloned)
          let diffDynamicElements = getDiff(this.dynamicElements,clonedDynamicElements)
          if (Object.keys(diff).length > 0 || Object.keys(diffDynamicElements).length > 0) {
            this.$parent.forceRender  
          }
        }
      },
      cancel() {
      },
      open() {
      },
      close() {
      
      },
    }
}
</script>