<template>
  <div class="width-100">
    <v-flex justify="center">
      <v-dialog v-model="folderNotificationElements.detailDialog" persistent max-width="1100">
          <v-card>
            <v-toolbar color="#2c3e50" dark>
              <v-toolbar-title>Dosya Durum Kontrolü</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn @click="close()" color="white" text>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-title>
              {{ detailDialogCardTitle }}
            </v-card-title>
            <br>
            <v-card-subtitle>{{ detailDialogSubtitle }}</v-card-subtitle>
            <br>
            <v-data-table
              dense
              show-select
              v-model="selectedRows"
              :headers="folderNotificationElements.dialogHeaders"
              :items="folderNotificationElements.dialogData"
              hide-default-footer
              item-key="pdfName"
              :items-per-page="50"
              class="elevation-1"
              height="400px"
            >
            <template v-for="header in folderNotificationElements.dialogHeaders" v-slot:[`item.${header.value}`]="{item}">
              <div :key="header.value" :class="header.class">
                <slot v-if="header.switchStateValue">{{ folderNotificationElements.getSituationName(item.isFound) }}</slot>
                <slot v-else>{{item[header.value]}}</slot>
              </div>
            </template>
            </v-data-table>
          </v-card>
      </v-dialog>
      <v-card>
        <v-data-table
          dense
          :headers="headers"
          :items="desserts"
          show-select
          v-model="selectedRows"
          item-key="id"
          height="580px"
          no-data-text="KAYDINIZ BULUNMAMAKTADIR.."
          no-results-text="Aradığınız Kriterlerde Sonuç Bulunamadı."
          :footer-props="{
            'items-per-page-text': 'Sayfa Başına Dosya Sayısı',
          }"
          hide-default-footer
          :items-per-page="paginationObj.pageSize"
        >
          <template v-for="header in headers" v-slot:[`item.${header.value}`]="{item}">
            <div :key="header.value" :class="header.class">
              <slot v-if="header.switchStateValue">{{ folderNotificationElements.getStateName(item.state) }}</slot>
              <slot v-else-if="header.dataType =='date' && item[header.value] == null">{{ "Beklemede" }}</slot>
              <slot v-else-if="header.dataType =='date' && item[header.value] != null">{{ new Date(item[header.value]).toLocaleString() }}</slot>
              <slot v-else-if="header.switchTypeValue">{{ folderNotificationElements.getTypeName(item.choice) }}</slot>
              <slot v-else-if="header.switchProcessValue">{{ folderNotificationElements.getProcessType(item.type) }}</slot>
              <slot v-else>{{item[header.value]}}</slot>
            </div>
          </template>
          <template v-slot:item.state="{ item }">
            <v-btn
              style="height: 20px !important;margin-right:1%; margin-left:2%;margin-bottom: 1%"
              @click="folderNotificationElements.controlProcessButton(item, selectedRows)"
              :color="folderNotificationElements.getColor(item.state)"
            >
                {{folderNotificationElements.getStateName(item.state, item.choice)}}
                <v-icon right dark :class="folderNotificationElements.getStateIcon(item.state, item.choice)"></v-icon>
            </v-btn>
          </template>
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-tooltip class="mb-8" top>
                <template v-slot:activator="{ on }">
                  <v-btn color="#00a8ff" style="height: 27px !important;margin-right:1%;" @click="searchUpdate()" v-on="on"
                    >Ara</v-btn
                  >
                </template>
                <span>Girilen arama anahtarlarıyla arama yapar</span>
              </v-tooltip>
              <v-tooltip class="mb-8" top>
                <template v-slot:activator="{ on }">
                  <v-btn color="#00a8ff" style="height: 27px !important;margin-right:1%;" @click="clearFilters()" v-on="on"
                    >Temizle</v-btn
                  >
                </template>
                <span>Arama alanlarını temizler</span>
              </v-tooltip>
              <v-tooltip class="mb-8" top>
                <template v-slot:activator="{ on }">
                  <v-btn color="error" style="height: 27px !important;margin-right:1%;" @click="deleteRowsFunc()" v-on="on"
                    >Sil</v-btn
                  >
                </template>
                <span>Seçilen kayıtları siler</span>
              </v-tooltip>
              <v-spacer></v-spacer>
              <v-tooltip class="mb-8" top>
                <template v-slot:activator="{ on }">
                  <v-btn color="white" style="height: 27px !important;margin-right:1%; margin-left:2%" @click="getNotification()" v-on="on">
                    <v-icon>replay</v-icon>
                  </v-btn>
                </template>
                <span>Tabloyu yeniler</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider style="margin-top: 0px;margin-bottom: 0px;"></v-divider>
          </template>
        </v-data-table>
        <div class="text-center" style="padding: 0 0 0 0">
          <v-row style="padding: 0 3%;margin-top: 0px">
            <v-col style="padding-top:0px; padding-bottom:0px">
                <v-text-field
                  type="number"
                  style="width: 55%;"
                  label="Satır sayısı"
                  :rules="rules.pageSizeRules"
                  @change="searchUpdate()"
                  v-model.number="paginationObj.pageSize"
                ></v-text-field>
            </v-col>
            <v-col cols="6" style="padding-top:8px; padding-bottom:0px">
                <v-btn
                  style="min-width: 0;margin-left: 5px"
                  @click="paginationObj.page = 1"
                  v-if="paginationObj.page != 1"
                  ><v-icon>keyboard_arrow_left</v-icon></v-btn
                >
                <v-btn
                  v-if="paginationObj.page - 2 > 0"
                  style="min-width: 0;margin-left: 5px"
                  @click="pageStep(-2)"
                  >{{ paginationObj.page - 2 }}</v-btn
                >
                <v-btn
                  v-if="paginationObj.page - 1 > 0"
                  style="min-width: 0;margin-left: 5px"
                  @click="pageStep(-1)"
                  >{{ paginationObj.page - 1 }}</v-btn
                >
                <v-btn color="primary" style="min-width: 0;margin-left: 5px">{{ paginationObj.page }}</v-btn>
                <v-btn
                  v-if="paginationObj.page + 1 <= paginationObj.pagesCount"
                  style="min-width: 0;margin-left: 5px"
                  @click="pageStep(1)"
                  >{{ paginationObj.page + 1 }}</v-btn
                >
                <v-btn
                  v-if="paginationObj.page + 2 <= paginationObj.pagesCount"
                  style="min-width: 0;margin-left: 5px"
                  @click="pageStep(2)"
                  >{{ paginationObj.page + 2 }}
                </v-btn>
                <v-btn
                  style="min-width: 0;margin-left: 5px"
                  @click="paginationObj.page = paginationObj.pagesCount"
                  v-if="
                    paginationObj.page != paginationObj.pagesCount &&
                      paginationObj.pagesCount > 1
                  "
                  ><v-icon>keyboard_arrow_right</v-icon></v-btn
                >
                <div>
                  <center style="color:grey; font-size:13px;margin-top:7px;">
                    sayfada satır sayısı: {{ filteredDesserts.length }}
                  </center>
                </div>
            </v-col>
            <v-col style="padding-top:0px; padding-bottom:0px">
              <div style="float: right">
                  <p style="font-size: 12px;font-weight:500;margin:3px 0;color:#666A6F">
                    Toplam satır sayısı
                  </p>
                  <p style="float: left; margin-left:1px;">
                    {{ paginationObj.itemCount }}
                  </p>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-flex>
    <delete-dialog :dialogText="deleteDialogText" :visibleDialog="deleteDialogVisible" @continue-func="deleteNotification"></delete-dialog>
    <snackbar-component />
  </div>
</template>

<script>
import deleteDialog from '../../../../vue/src/molecules/deleteDialog.vue';
import {  openSnackbar,  openTablePage,  excelDownloaded,  showDialog,  getDiv,  showLoader,  hideLoader} from "../helper/elementHelper.js";
import { sendRequest } from "../helper/requestHelper";
import * as ObjectMethods from "../../../../vue/src/tools/ObjectMethods";
import { currencyFormat } from "../../../../vue/src/tools/timeConverter";
import Enum from "../../../../vue/src/tools/Enum.json";
import axios from "axios";
import snackbarComponent from "./snackbarComponent.vue";

export default {
  
  components: { deleteDialog , "snackbar-component": snackbarComponent},
  props: {
    folderNotificationElements : {
      type: Object,
      default: () => ({
      })
    },
  },
  async created() {
    let that = this
    if (this.folderNotificationElements.detailDialogWarningCardTitle != undefined && this.folderNotificationElements.detailDialogWarningCardSubtitle != undefined) {
      this.detailDialogCardTitle = this.folderNotificationElements.detailDialogWarningCardTitle
      this.detailDialogSubtitle = this.folderNotificationElements.detailDialogWarningCardSubtitle
    }
    that.headers = that.folderNotificationElements.headers
    that.tableType = that.folderNotificationElements.tableType
    that.deleteDialogText = that.folderNotificationElements.deleteDialogText
    showLoader("Lütfen Bekleyiniz.")
    await this.getNotification()
    hideLoader();
  },
  data () {
    return {
      detailDialogCardTitle: 'Aşağıda durumu dosya adı ve hatanın kaynağını bulabilirsiniz.',
      detailDialogSubtitle: "Yüklemeye Çalıştığınız Dosyalarınız İçin Taslak Bulunma Durumu",
      selectedRowItems : {},
      selectedRows : [],
      deleteDialogVisible : false,
      deleteDialogText : "",
      filters: {
        ticket: [],
        choice: [],
        fileName: [],
        uploadDate: [],
        date: [],
        state: []
      },
      editedItem: {
        rate: ""
      },
      tableType: "",
      editedIndex: -1,
      editeAssistantRate: false,
      dialogText: "",
      selectedTicket : null,
      headers: [],
      continueDialogHeaders: [],
      desserts: [],
      paginationObj: {
        page: 1,
        pagesCount: 0,
        itemCount: 0,
        pageSize: 50,
        filters: {}
      },
      rules: {
        pageSizeRules: [
          (v) => !!v || "!gerekli alan!",
          (v) => v < 1001 || "satır sayısı en fazla 1000 olabilir",
        ]
      },
    }
  },
  mounted()  {
    
  },
  computed: {
    filteredDesserts() {
      return this.desserts;
    },
  },
  methods: {
    sendAdmin(){
      openSnackbar("error", "Hizmetimiz Şu Anda Aktif Değil Lütfen Yetkililerle İletişime Geçin",2000);
      // if (this.selectedRows.length > 0) {
      //   sendRequest(Enum.requestType.POST,"sendAdminForAbroadPdf","",{ selectedRows : this.selectedRows, ticketObj : this.selectedRowItems},
      //     function (response) {
      //       if (response.status == 200) {
      //         //SNACKBAR
      //         this.selectedRows = [];
      //       }
      //       this.getNotification()
      //       this.close()
      //     },
      //     function (error) {
      //       errFunc(error);
      //     }
      //   )
      // }else{
      //   // kullanıcı bir şey seçsin.
      // }
    },
    currencyFormatProcessing(num, decimalLength, thousandLength, decimalSeparator, thousandSeparator) {
      return currencyFormat(num, decimalLength, thousandLength, decimalSeparator, thousandSeparator)
    },
    deleteNotification(userChoice){
      let that = this
      if (userChoice == true) {
        let deleteIds = []
        for (let i = 0; i < this.selectedRows.length; i++) {
          deleteIds.push(this.selectedRows[i].id)
        }
        let crudRequestObj = {
          deleteType : 'folderNotificationTable',
          tableName : "t_process_queue",
          projectId: window.$cookies.get("selectedItem").id,
          auditDate: window.$cookies.get("selectedItem").auiditDate,
          preriorDate: window.$cookies.get("selectedItem").preriorDate,
          isActive: window.$cookies.get("selectedItem").isActive,
          folderNotfDeletedIds : deleteIds
        }
        sendRequest(Enum.requestType.POST,"deleteTable","",{ crudRequestObj : crudRequestObj },
          async function (response) {
            let that2 = that
            if (response.status == 200) {
              //SNACKBAR
              that2.selectedRows = [];
            }
            await that2.getNotification()
            that2.close()
          },
          function (error) {
            openSnackbar("error", "Veriler Silinirken Bir Hata Oluştu");
          }
        )
      }
      this.deleteDialogVisible = false
    },
    close(){
        this.folderNotificationElements.detailDialog = false;
        this.folderNotificationElements.dialogData = [],
        this.folderNotificationElements.dialogHeaders = []
    },
    clearFilters() {
        this.paginationObj = {
          page: 1,
          pagesCount: 0,
          itemCount: 0,
          pageSize: 100,
          filters: {},
        };
        this.searchUpdate();
    },
    async getNotification(){
        showLoader("Lütfen bekleyiniz...")
        //Node daki get data eklenecek bu ona bağlanacak
        let that = this
        let pgObj = JSON.parse(JSON.stringify(that.paginationObj))
        pgObj.filters = await ObjectMethods.searchFilterArrangement(that.headers, pgObj.filters)
        await sendRequest(Enum.requestType.POST,this.folderNotificationElements.getNotificationUrl,"",{choice : this.folderNotificationElements.getNotificationUrlChoice , selection :  null , paginationObj: pgObj, type : that.tableType },
          function (response) {
            let flexCount = response.data.data.value.length==0?0:response.data.data.count
            that.paginationObj.pagesCount = Math.ceil(
              Number(flexCount) / Number(that.paginationObj.pageSize)
            );
            that.paginationObj.itemCount = flexCount;
            that.desserts = response.data.data.value;
            hideLoader()
          },
          function (error) {
            hideLoader()
            openSnackbar("error", "Veriler Getirilirken Bir Hata Oluştu");
          }
        )
    },
    pageStep(step) {
        this.paginationObj.page += step;
    },
    searchUpdate() {
        if ( this.paginationObj.pageSize <= 1000 && this.paginationObj.pageSize !== "" &&  this.vatDirectSelection != null ) {
          this.paginationObj.pagesCount = 0;
          this.paginationObj.itemCount = 0;
          this.paginationObj.page = 1;
        }
        this.getNotification()
    },
    deleteRowsFunc () {
        if (this.selectedRows.length > 0) {
            this.deleteDialogVisible = true
        }else{
            let data = {
            bool : true,
            text : "Lütfen silme işlemi için bir kayıt seçiniz."
        }
            this.$store.commit("SNACKBAR", data);
        }
    }
  },
  watch: {
    "paginationObj.page": function() {
        this.getNotification();
    }
  }
}
</script>
<style scoped>
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.autorenew {
  animation: rotate 2s linear infinite;
}
::v-deep .threedot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 200px;
}
::v-deep .threedot:hover {
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}
</style>