<template>
  <v-app>
    <div class="header-div">
      <h3 class="header-h3">SABİT KIYMET LİSTESİ</h3>
    </div>
    <v-toolbar-items style="margin-left: 25px; height: 30px;">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text @click="downloadDrafts" v-on="on" style="background-color: #4BB543; color: white; border-radius: 5px;">
              Taslak İndir
            </v-btn>
          </template>
        </v-tooltip>
    </v-toolbar-items>
    <table-component :dynamic-element="tableElements" :model="model" />
    <Loader></Loader>
    <snackbar-component />
  </v-app>
</template>
<script>
import uploadComponent from "../../../frontend/components/atoms/container/uploadComponent.vue";
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import Loader from "../../../frontend/components/atoms/loader";
import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";

import {
getTable,
updateItem,
saveItem,
deleteItem,
openSnackbar,
openTablePage,
showLoader, 
hideLoader 
} from "../../../frontend/components/atoms/helper/elementHelper.js";
import snackbarComponent from "../../../frontend/components/atoms/container/snackbarComponent.vue";
import {axiosUploadTypeArrayBuffer, checkProject, downloadZipFile} from "../../helper/uploadHelperForData"
export default {
  components: {
      "table-component": tableComponent,
      "upload-component": uploadComponent,
      Loader: Loader,
      "snackbar-component": snackbarComponent,
  },

  data() {
      return {
        tableElements: {},
        model: {},
        renderKey: 0,
      };
  },
  created() {
    let that = this
    this.loadData();
    checkProject();
  },
  watch: {},
  methods: {
    downloadDrafts() {
        downloadZipFile();
    },
    renderDepreciationTable(thisData, model, dynamicElements) {
      showLoader('Hesaplamalar Yapılıyor')
      let choiceData = {
        cookieData: window.$cookies.get("selectedItem")
      }
      sendRequest(Enum.requestType.POST, "queryDepreciationTable", "", choiceData, 
      function (response) {
        dynamicElements.data = response.data.data.data;
        model.itemCount = dynamicElements.data.length;
        model.pagesCount = dynamicElements.data.length / 50;
        dynamicElements.forceRender();
        hideLoader();
      },
      function (error) {
       errFunc(error);
      })
    },
  loadData() {
    let that = this;
    this.tableElements = {
      sortBy: [],
      sortDesc: false,
      renderKey: 0,
      type: "table",
      tableHeight : 530,
      name: "depreciationTable",
      class: "",
      infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
      excelDownloadBtnText: "İndir",
      deleteDialogVisible : true,
      deleteBtnText: "Sil",
      hidePagination: true,
      selectionUrl: "queryDepreciationTable",
      showDefaultSaveButon : false,
      deleteUrl: "deleteTable",
      // selectedRows: [],
      // filteredColumn: {},
      saveBtnText : 'Yeni Kayıt',
      saveUrl : "",
      //filteredColumns: true,
      showDefaultDeleteButon : true,
      deleteBtnSettings : {
          color: "error",
          style:"height: 25px !important; margin-top: 27%",
      },
      saveBtnSettings: {
          style:"height: 25px !important; margin-top: 15%",
        },
      initialFilter:{
        [Enum.initialFilter.AND] :
        [
          {
            projectId: {
              [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id
            }
          }
        ]
      },
      clientEvents : {
          onBeforeSave :function (selectedItem,creatableObjects,model,tableObject) {
            creatableObjects.initialFilter = tableObject.initialFilter
          },
       },
      toolbars: [
        {
          type: Enum.layoutType.div,
          name: "toolbarsForm",
          inputElements: [
            [
              {
                 id: "amorti",
                 type: "v-file-input",
                 style:"height: 25px!important; width: 200px;",
                 label: "Dosyanızı Seçiniz",
                 inputHeader: "Amorti",
                 name: "amorti",
                 multiple: true,
                 onChange: async function(thisData, model, dynamicElements) {
                  let files = model.amorti[0];
                  let choiceData = {
                      choice : "depreciation", 
                      headerAddFlag : true, 
                      fileName : files.name,
                      fileType : 'depreciation',
                      cookieData: window.$cookies.get("selectedItem"),
                  }
                  showLoader('Dosyanız Hazırlanıyor')
                  uploadHelperFormDataQueue([files], "insertToDepreciation", Enum.requestType.POST, choiceData, 
                  function (response) {
                    if (response.data.data.errorExcel != null) {
                      openSnackbar("error", "Hatalı Excel Taslağı Yüklediniz", "2000")
                    }
                    hideLoader();
                    that.renderDepreciationTable(thisData, that.model.depreciationTable, dynamicElements)
                    },
                  function (error) {
                      openSnackbar("error", error.response.data.error[0].detail.message, "2300");
                    }
                  )
                 }
              }
            ],
          ],
        }
      ],
      headers: [
        { text: "Muhasebe Kodu", value: "accountCode", width: "100" , divider: true,},
        { text: "Sabit Kıymet Kodu", value: "depreciationCode", width:"100" , divider: true,},
        { text: "Sabit Kıymet Adı", value: "depreciationName", width: "100" , divider: true,},
        { text: "Alış Tarihi", value: "date", width: "100", dataType: "date" , divider: true,},
        { text: "Amortisman Oranı", value: "depreciationRate", width: "100", dataType: "currency" , divider: true, align: "center"},
        { text: "Borç", value: "debt", width: "100", dataType: "currency" , divider: true, align: "right"},
        { text: "Net Değer", value: "netValue", width: "100", dataType: "currency" , divider: true, align: "right"},
        { text: "Amortisman Dönemi", value: "periodDepreciation", width: "100", dataType: "currency" , divider: true, align: "right"},
        { text: "Toplam Birikmiş Amortisman", value: "sumOfAccumulatedDepreciation", width: "100", dataType: "currency" , divider: true, align: "right"},
        { text: "Yöntem", value: "method", width: "100" , divider: true,},
        { text: "Süre", value: "duration", width: "100" , divider: true,},
      ],
    };
  },
  copyItem(tableName,selectedItem,options,model,onBeforeSave,onAfterSave,errorFunction) {
    if (!Array.isArray(selectedItem)) {
        selectedItem = [selectedItem];
    }
    
    let dynamicElements = getTable(tableName);
    if (options != undefined && options.dialogText != undefined) {
        dynamicElements.deleteDialogText = options.dialogText;
    }
    
    let title = "Yeni Kayıt Eklemek İstediğinize Emin Misiniz?";
    
    dynamicElements.forceRender();
    model.close = "Hayır",
    model.insertItem = "Evet";
    dynamicElements.dialogVisible = true;
    dynamicElements.dialogInfo = {
        name:"insertDialog",
        maxWidth : "1100",
        dialogVisible : "dialogVisible",
        title : title,
        layout : [
            {
              type : Enum.layoutType.div,
              name : "loginForm",
    
              inputElements : [
              ]
            }
        ],
        actions : [
          {
            id : "close",
            type : "v-btn",
            variant : "btn",
            name : "close",
            inputText : "Kapat",
            divStyle:"",
            btnTypeText: true,
            color:"green darken-1",
            classTag : "",
            onClick : function(thisData,model,dialogInfo) {
              var tableObject = getTable(tableName);
              tableObject.dialogVisible = false;
              tableObject.forceRender();
              console.log(thisData,model,dialogInfo);
            }
          },
          {
            id : "insertItem",
            type : "v-btn",
            variant : "btn",
            name : "insertItem",
            inputText : "Ekle",
            divStyle:"",
            btnTypeText: true,
            color:"green darken-1",
            classTag : "",
            onClick : function(thisData,model,dialogInfo) {
              var tableObject = getTable(tableName);
              console.log(thisData,model,dialogInfo);
              tableObject.dialogVisible = false;
              tableObject.forceRender();
              let creatableObjects = {}; 
              sendRequest(Enum.requestType.POST, dynamicElements.saveUrl,"",{selectedItem,creatableObjects},function (res) {
                  openTablePage(tableName);
                  openSnackbar("success", "Eklemeniz Başarılı Bir Şekilde Gerçekleşti");
              },function (error) {
                  if (errorFunction != undefined) {
                      errorFunction(error,selectedItem,creatableObjects,model,tableObject);
                  } else if(tableObject.clientEvents.onSaveError != undefined){
                      tableObject.clientEvents.onSaveError(error,selectedItem,creatableObjects,model,tableObject);
                  }
              });
            }
          },
        ]
    };
    },
},
}

  

</script>

<style>
.header-div {
text-align: center;
}
</style>