<template>
  <folder-notification-component  :folderNotificationElements="folderNotificationElements"/>
</template>

<script>
import  folderNotificationComponent from "../../../frontend/components/atoms/container/folderNotificationComponent.vue";
import Enum from "../tools/Enum.json";
import axios from "axios";
import { sendRequest, uploadHelperFormDataQueue } from "../../../frontend/components/atoms/helper/requestHelper";
import {  openSnackbar,  openTablePage,  excelDownloaded,  showDialog,  getDiv,  showLoader,  hideLoader} from "../../../frontend/components/atoms/helper/elementHelper";
export default { 
  components: { 'folder-notification-component' : folderNotificationComponent  },
  data () {
    return {
      folderNotificationElements : {
        detailDialogWarningCardTitle: "Yüklemeye Çalıştığınız Dosyalarınız İçin Bulunan Hatalar",
        detailDialogWarningCardSubtitle: "Aşağıda durumu dosya adı ve hatanın kaynağını bulabilirsiniz.",
        dialogHeaders : [],
        detailDialog : false,
        dialogData : [],
        getColor (state, choice) {
      if (state == 1) return 'yellow'
      else if (state == 2) return 'white'
      else if (state == 3) return 'grey'
      else if (state == 4) return 'red'
      else if (state == 5) return 'green'
      else if (state == 6) return 'red'
      else if (choice == 17 && state == 7) return 'dark green'
      else if (state == 7) return 'blue'
      else if (state == 8) return 'blue lighten-4'
      else if (state == 9) return 'blue lighten-4'
      else if (state == 10) return 'red'
      else if (state == 11) return 'blue lighten-4'
      else if (state == 12 || state == 13) return 'grey'
      else if (state == 14) return 'red'
      else if (state == 16) return 'red'
      else if (state == 17) return 'success'
      else if (state == 18) return 'orange'
        },
        getStateName (state, choice) {
      if (state == 1) return 'Beklemede'
      else if (state == 2) return 'İşleniyor'
      else if (state == 3) return 'Taslak Hatası'
      else if (state == 4) return 'Hata Exceli'
      else if (state == 5) return 'Görüntüle'
      else if (state == 6) return 'Hata Oluştu'
      else if (state == 7) return 'Tamamlandı'
      else if (state == 8) return 'İptal Edildi'
      else if (state == 10) return 'Dosya Türü Hatası'
      else if (state == 13) return 'Veri Bulunamadı'
      else if (state == 14) return 'Kullanıcı Adı Veya Şifre Yanlış'
      else if (state == 16) return 'Sistem Hatası'
      else if (state == 17) return 'Dosya İndir'
      else if (state == 18) return 'Mükerrer'
        },
        getStateIcon (state, choice) {
      if (state == 1) return 'access_time'
      else if (state == 2) return 'autorenew'
      else if (state == 3) return 'info_outline'
      else if (state == 4) return 'vertical_align_bottom'
      else if (state == 5) return 'keyboard_return'
      else if (state == 6) return 'priority_high'
      else if (choice == 17 && state == 7) return 'sync_problem'
      else if (state == 7) return 'check'
      else if (state == 8) return 'highlight_off'
      else if (state == 9) return 'check'
      else if (state == 10) return 'keyboard_return'
      else if (state == 11) return 'sync_problem'
      else if (state == 12 || state == 13) return 'info_outline'
      else if (state == 14) return 'priority_high'
      else if (state == 16) return 'warning_amber'
      else if (state == 17) return 'vertical_align_bottom'
      else if (state == 18) return 'warning_amber'

        },
        getTypeName (choice) {
      if (choice == 1 || choice == 11) return "Mizan"
      else if (choice == 2 || choice == 12) return "Amortisman"
      else if (choice == 3 || choice == 13) return "Detay Grup"
      else if (choice == 4 || choice == 14) return "Fiş"
      else if (choice == 28) return "E-Fatura"
      else if (choice == 20) return "E-Fatura (Satış)"
      else if (choice == 21) return "E-Arşiv (Satış)"
      else if (choice == 22) return "E-Fatura (Alış)"
      else if (choice == 18) return "Gib E-Arşiv (Alış)"
      else if (choice == 32) return "GÇB"
      else if (choice == 30 || choice == 31) return "Excel"
        },
        getSituationName(choice){
      if (choice == true) return "Yüklendi"
      else if (choice == false) return "Taslak Bulunamadı"
        },
        getProcessType (choice) {
          let validationProcess = [27]
          let insertProcess = [26, 28, 20, 21, 22, 32, 18]
          let deleteProcess = [50, 51]
          let reportProcess = [30, 31]
          if (validationProcess.includes(choice)) return 'Veri Kontrolü'
          else if (insertProcess.includes(choice)) return 'Veri Tabanı Kaydı'
          else if (deleteProcess.includes(choice)) return 'Silme İşlemi'
          else if (reportProcess.includes(choice)) return 'Rapor İndirme'
        },
        forceFileDownload(response,fileName){
      showLoader("Lütfen bekleyiniz...")
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      hideLoader()
        },
      controlProcessButton(item){
      if (item.state == 5 || item.state == 18) {
        this.selectedRowItems = item
        this.dialogHeaders = [
          {
            text: "Dosya İsmi",
            value: "billNo",
            width: "700",
            divider: true
          },
          {
            text: "Durum",
            value: "errorValue",
            width: "200",
            divider: true,
          }
        ]
        if (item.state == 18) {
          this.dialogHeaders = [
          {
            text: "Mükerrer Aylar",
            value: "period",
            width: "700",
            divider: true
          }
        ]
        }
        let that = this
        sendRequest(Enum.requestType.POST,"continuenotification","",{ ticket: item.ticket, deleteMonths : this.selectedRows, choice: item.choice, sameMonthsControl: false},function (response) {
          debugger
          if (item.state == 18) {
            for (let i = 0; i < response.data.data.months[0].length; i++) {
              that.dialogData.push(response.data.data.months[0][i])
            }
          } else {
            for (let i = 0; i < response.data.data.wrongFiles.length; i++) {
            that.dialogData.push(response.data.data.wrongFiles[i])
            }
          }
          that.detailDialog = true
          },
          function (error) {
            console.log(error)
            openSnackbar("error","bir hata oluştu.");
          }
        ); 
      }else if(item.state == 4 || item.state == 9){
        debugger
        axios({ 
          method: "get",
          url: "notificationZipDownloads",
          responseType: "arraybuffer",
          headers: {
            'ticket': item.ticket,
            'fileName' : Enum.stateZipName[item.state]
          }
        })
        
        .then((response) => {
          debugger
          this.forceFileDownload(response, Enum.stateZipName[item.state] + '.zip'); //enum hatalar ve rapor diye ikişye ayır  Enum['zipName'][item.type] + '.zip'
        })
        .catch(() => {
          console.log("error occured");
          let data = {
            bool: true,
            text: 'Beklenmeyen Bir Hata Oluştu Lütfen İletişime Geçiniz'
          };
          this.$store.commit("SNACKBAR", data);
        });
      }
      else if(item.state == 17){
        axios({ 
          method: "get",
          url: "notificationSuccessZipDownloads",
          responseType: "arraybuffer",
          headers: {
            'ticket': item.ticket,
            'fileName' : Enum.stateZipName[item.state]
          }
        })
        
        .then((response) => {
          this.forceFileDownload(response, "Rapor Excel" + '.zip'); //enum hatalar ve rapor diye ikişye ayır  Enum['zipName'][item.type] + '.zip'
        })
        .catch(() => {
          console.log("error occured");
          let data = {
            bool: true,
            text: 'Beklenmeyen Bir Hata Oluştu Lütfen İletişime Geçiniz'
          };
          this.$store.commit("SNACKBAR", data);
        });
      }
        },
        headers: [
          { text: 'Kontrol Noktası',  width: "150", value: 'state', divider: true, searchType : 'select', selectData : [{value : "7", text: "Tamamlandı"},{value : "2", text: "İşleniyor"},{value : "3", text: "Taslak Hatası"},{value : "4", text: "Hata Exceli İndir"},{value : "1", text: "Beklemede"}, {value : "17", text: "Dosya İndir"}] },
          { text: 'İşlem Kodu', width: "150", sortable: false, value: 'ticket', divider: true},
          { text: 'İşlem Türü', width: "150", value: 'type', divider: true, switchProcessValue: true, searchType : 'select', selectData : [{value : [27], text : "Veri Kontrolü"}, {value : [11,12, 13, 14], text : "Veri Tabanı Kaydı"}]},
          { text: 'Dosya İsmi', width: "150", value: 'fileName', divider: true, class: 'threedot' },
          { text: 'Dosya Türü', width: "190", value: 'choice', divider: true, switchTypeValue: true, searchType : 'select', selectData : [{value : [1,11], text: "Mizan"},{value : [2,12], text: "Amortisman"},{value : [3,13], text: "Detay Grup"},{value : [4,14], text: "Fiş"}, {value: 28, text: "E-Fatura"}] },
          { text: 'Yüklenme Tarihi', width: "170", dataType: "date", value: 'date', divider: true },
          { text: 'İşlem Tarihi', width: "170", dataType: "date", value: 'transactionDate', divider: true },
          { text: 'Proje Adı', width: "170", value: 'projectName', divider: true }
        ],
        tableType: "queueProcessType",
        deleteDialogText : "Seçtiğiniz verileri silmek istediğinize emin misiniz?",
        getNotificationUrl : "gettablesdateselection",
        getNotificationUrlChoice : "folderNotificationDidero"
      }
    }
  },
}
</script>

<style scoped>
::v-deep .threedot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 200px;
}
::v-deep .threedot:hover {
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}
</style>