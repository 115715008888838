<template>
  <v-app>
    <table-component :dynamic-element="dynamicElements" :model="model" style="font-size: 15px;" />
    <v-dialog v-model="selectPeriodForDeleteDialog" width="30%" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Silmek İstediğiniz Dönemleri Seçiniz</span>
        </v-card-title>
        <v-form v-model="selectedPeriodForDeleteValid">
          <v-col cols="7">
            <v-select
              multiple
              style="margin-left:4%"
              v-model="selectedPeriodForDelete"
              :items="periodItemsForDelete"
              label="Dönem Seçiniz"
            >
            </v-select>
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="selectPeriodForDeleteDialog = false">Kapat</v-btn>
            <v-btn color="blue darken-1" text @click="periodsDeleteFunc()">Sil</v-btn>
          </v-card-actions>   
        </v-form>
          </v-card>
      </v-dialog>
      <!-- <v-dialog v-model="selectPeriodForTableDialog" width="30%" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Getirmek İstediğiniz Dönemleri Seçiniz</span>
        </v-card-title>
        <v-form v-model="selectedPeriodForGetValid">
          <v-col cols="7">
            <v-select
              multiple
              style="margin-left:4%"
              v-model="selectedPeriodForGet"
              :items="periodsArray"
              label="Dönem Seçiniz"
            >
            </v-select>
          </v-col>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="selectPeriodForTableDialog = false">Kapat</v-btn>
            <v-btn color="blue darken-1" text @click="getPeriodsFunction()">Getir</v-btn>
          </v-card-actions>   
        </v-form>
          </v-card>
      </v-dialog> -->
    <loader />
  </v-app>
</template>

<script>
import tableComponent from "../../../frontend/components/atoms/container/tableComponent.vue";
import Enum from "../../../frontend/components/Enum.json";
import DideroEnum from "../../../frontend/components/Enum.json"
import Loader from '../../../frontend/components/atoms/loader';
import {sendRequest} from "../../../frontend/components/atoms/helper/requestHelper";
import {axiosUploadTypeArrayBuffer} from "../../helper/uploadHelperForData"

import {
  openSnackbar,
  getTable,
  openTablePage,
  showDialog,
  closeDialog,
  passivePull,
  manualReceiptAssign,
  excelDownload,
  getTableModel,
  showTableColoumn,
  hideTableColoumn,
  showLoader,
  hideLoader,
  deleteItem
} from "../../../frontend/components/atoms/helper/elementHelper.js";
export default {
  components: { "table-component": tableComponent , 'loader' : Loader},

  data() {
    return {
      periodItemsForDelete : [],
      selectedPeriodForDelete : [],
      selectedPeriodForGet : [],
      selectedPeriodForDeleteValid : false,
      selectedPeriodForGetValid : false,
      selectPeriodForDeleteDialog : false,
      selectPeriodForTableDialog : false,
      dynamicElements: {},
      model: {},
      renderKey: 0,
      initialFilterValue: {},
      renderTableName: "",
      periodsArray: []
    };
  },

  created() {
    if (window.$cookies.get("selectedItem") != null && window.$cookies.get("selectedItem") != 'null') {
      this.initialFilterValue = {
        [Enum.initialFilter.AND] :
          [
            {
              project_id: {
                [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id
              },
            }
          ]
      }
      this.renderTableName = "assistantTableAudit"
    } else {
      this.renderTableName = "assistantTable"
    }
    this.loadData();
    this.getPeriodsForSelect()
  },
  watch: {},
  methods: {
    periodsDeleteFunc () {
      let that = this
      if (this.selectedPeriodForDelete.length == 0) {
        alert("Lütfen Dönem Seçiniz")
      }else{
        showLoader("Verileriniz Siliniyor.Lütfen Bekleyiniz.")
        // kuyruksuz deletePeriodsMuavin
        sendRequest(Enum.requestType.POST, "deletePeriodQueue","",{selectedPeriod : this.selectedPeriodForDelete, cookieData: window.$cookies.get("selectedItem") },
        function (res) {
          //if (res.data.data.value == "Verileriniz Başarıyla Silindi") {
          hideLoader()
          that.selectPeriodForDeleteDialog = false
          that.$router.push('folderNotification')
          //}
        })
      }
    },
    getPeriodsForSelect() {
      let that = this
      sendRequest(Enum.requestType.POST, "getperiodmuavin","",{cookieData: window.$cookies.get("selectedItem")},function (res) {
      debugger
      let responsePeriod = res.data.data.value
      if (responsePeriod.length > 0) {
        for (let i = 0; i < responsePeriod.length; i++) {
          that.periodsArray.push({name: responsePeriod[i], item: responsePeriod[i]})
        }
      }
    })
    },
    // getPeriodsFunction () {
    //   let that = this
    //   debugger
    //   showLoader("Verileriniz Getiriliyor.Lütfen Bekleyiniz.")
    //   if (this.selectedPeriodForGet.length == 0) {
    //     alert("Lütfen Dönem Seçiniz")
    //     hideLoader()
    //   }else{
        
    //     // kuyruksuz deletePeriodsMuavin
    //     // sendRequest(Enum.requestType.POST, "deletePeriodQueue","",{selectedPeriod : this.selectedPeriodForGet, cookieData: window.$cookies.get("selectedItem") },
    //     // function (res) {
    //     //   that.selectPeriodForTableDialog = false
    //     //   openTablePage(that.renderTableName);
    //     //   hideLoader()
    //     // })
    //     this.dynamicElements.initialFilter = {
    //       [DideroEnum.initialFilter.AND] :
    //         [
    //           {
    //             project_id: {
    //               [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem").id
    //             }
    //           },
    //           {
    //             fake_period: {
    //               [DideroEnum.initialFilter.EQ]: this.selectedPeriodForGet
    //             }
    //           }
    //         ]
    //     }
    //     this.selectPeriodForTableDialog = false
    //     openTablePage("assistantTableAudit");
    //     hideLoader()
    //   }
    // },
    loadData() {
      let that = this
      this.model = {};
      this.dynamicElements = {
        sortBy: [],
        sortDesc: false,
        clientEvents : {
          onCellClick : function (/*row ve Column Index,prop bilgileri*/) {
            //table ın body sinde
          },
          onBeforeUpdate : function () {
            //console.log('onBeforeUpdate yapıldı')
          },
          onBeforeDelete : function () {
            //console.log('onBeforeDelete yapıldı')
          },
          onBeforeSave : function () {
            //console.log('onBeforeSave yapıldı')
          },
          onAfterUpdate : function () {
            //console.log('onAfterUpdate yapıldı')
          },
          onAfterSave: function () {
            //console.log('onAfterSave yapıldı')
          },
          onAfterDelete :function () {
            //console.log('onAfterDelete yapıldı')
          },
          onBeforeSelect : function () {
            //console.log('onBeforeSelect yapıldı')
          },
          onAfterSelect : function () {
            //console.log('onAfterSelect yapıldı')
          },
          onSelect : function () {
            //console.log('onSelect yapıldı')
          },
          onSaveError: function () {
            //console.log('onSaveError yapıldı')
          },
          onUpdateError : function () {
            //console.log('onUpdateError yapıldı')
          },
          onDeleteError: function () {
            //console.log('onDeleteError yapıldı')
          },
          //table için
          onBeforeunload: function () {
            //console.log('onBeforeunload yapıldı')
          },
          onafterUpload: function () {
            //console.log('onafterUpload yapıldı')
          },
          dataChanged: function () {
            //console.log('dataChanged yapıldı')
          },
          searchOnClick: function () {
            //console.log('searchOnClick yapıldı')
          },
        },
        selectedRows: [],
        renderKey: 0,
        type: "table",
        name: that.renderTableName,
        class: "",
        infoTotalRowCountHeaderAssistantTable: "Toplam Satır Sayısı",
        updateBtnText: "Güncelle",
        deleteBtnText: "Sil",
        searchBtnText: "Ara",
        clearSearchBtnText: "Sıfırla",
        filterBtnText: "Filtre",
        excelDownloadBtnText: "İndir",
        tableHeight : 500,
        divStyle: "font-size: 5px",
        //emailBtnText: "Email",
        hidePagination: true,
        filteredColumn: {},
        filteredColumns: true,
        selectionUrl: "gettabledata",
        // crudRequestObj : {
        //   placeOfAction : "insuranceCalculation",
        //   projectId : window.$cookies.get("selectedItem").id,
        //   company_id: window.$cookies.get("selectedItem").companyId,
        //   auditDate: window.$cookies.get("selectedItem").auiditDate,
        //   preriorDate: window.$cookies.get("selectedItem").preriorDate,
        // },
        initialFilter: that.initialFilterValue,
        deleteUrl: "deletefortable",
        passivePullUrl: "passivepullforassistant",
        showDefaultDeleteButon : false,
        toolbars: [
          {
            type: Enum.layoutType.div,
            name: "toolbarsForm",
            inputElements: [
              [
                {
                  id: "passivePull",
                  type: "v-btn",
                  variant: "btn",
                  name: "passivePull",
                  inputText: "Pasife Çek",
                  divStyle: "",
                  style: "height: 25px !important;",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    console.log(thisData, model, dynamicElements);
                    var tableObject = getTable(that.renderTableName);
                    console.log(tableObject);
                    if (tableObject.showSelecetVisible == true) {
                      if (tableObject.selectedRows.length > 0) {
                        if (tableObject.data.length > 0) {
                          let options = {
                            showDialog: true,
                          };
                          passivePull(
                            tableObject.name,
                            tableObject.selectedRows,
                            options,
                            model
                          );
                          tableObject.selectedRows = []
                          tableObject.showSelecetVisible = false;
                          tableObject.singleSelect = false;
                        } else {
                          alert("Veriniz mevcut değildir");
                        }
                      } else {
                        tableObject.showSelecetVisible = false;
                      }
                    } else {
                      tableObject.singleSelect = false;
                      tableObject.showSelecetVisible = true;
                    }
                  },
                },
              ],
              [
                {
                  id: "manualAssigningReceipt",
                  type: "v-btn",
                  variant: "btn",
                  name: "assigningReceipt",
                  inputText: "Manuel Fiş Atama",
                  divStyle: "",
                  style: "height: 25px !important;",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    var tableObject = getTable(that.renderTableName);
                    if (tableObject.showSelecetVisible == true) {
                      if (tableObject.selectedRows.length > 0) {
                        if (tableObject.data.length > 0) {
                          let options = {
                            showDialog: true,
                          };
                          manualReceiptAssign(
                            tableObject.name,
                            tableObject.selectedRows,
                            options,
                            model
                          );
                          tableObject.selectedRows = []
                          tableObject.showSelecetVisible = false;
                          tableObject.singleSelect = false;
                        } else {
                          alert("Veriniz mevcut değildir");
                        }
                      } else {
                        tableObject.showSelecetVisible = false;
                        tableObject.singleSelect = false;
                      }
                    } else {
                      tableObject.singleSelect = false;
                      tableObject.showSelecetVisible = true;
                    }
                  },
                },
              ],
              [
                {
                  id: "assigningReceipt",
                  type: "v-btn",
                  variant: "btn",
                  name: "assigningReceipt",
                  inputText: "Fiş Atama",
                  divStyle: "",
                  style: "height: 25px !important;",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    showLoader('Verileriniz Kayıt Ediliyor')
                    sendRequest(Enum.requestType.POST, "assignReceipts", "", 
                    {
                      cookieData: window.$cookies.get("selectedItem"),
                      choice: "testTable"
                    }, 
                    function (response) {
                      hideLoader();
                      openTablePage(that.renderTableName);
                      openSnackbar("success", "Verileriniz Başarılı Bir Şekilde Kaydedildi", "2000")
                    },
                      function (error) {
                        errFunc(error);
                        openSnackbar("error", "Verileri Kaydederken Bir Hata Oluştu", "2000")
                        hideLoader();
                        openTablePage(that.renderTableName);
                      }
                      
                    )
                  },
                },
              ],
              [
                {
                  id: "jetTest",
                  type: "v-btn",
                  variant: "btn",
                  name: "jetTest",
                  inputText: "Jet Test",
                  style:"height: 25px !important; color: white;",
                  divStyle: "",
                  btnTypeText: false,
                  color: "blue",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model) {
                    let dialogInfo = {
                      name: "jetTestDialog",
                      maxWidth: "500",
                      title: "Jet Test",
                      actions: [
                        {
                          id: "calculateJetTest",
                          type: "v-btn",
                          variant: "btn",
                          name: "calculateJetTest",
                          divStyle: "",
                          inputText: "Hesapla",
                          btnTypeText: true,
                          color: "success",
                          classTag: "",
                          icon: "mdi-file-excel",
                          closeBtn: true,
                          onClick: function(thisData, model, dialogInfo) {
                            if ((window.$cookies.get("selectedItem") == 'null') || (window.$cookies.get("selectedItem") == null) || (window.$cookies.get("selectedItem") == "null")) {
                              if (model.startDateForNewExcel === "" && model.endDateForNewExcel === "") {
                              openSnackbar("error","Girilen Tarih Değerleri Boş Olamaz");
                              return false;
                              }
                              if (((model.startDateForNewExcel != undefined) && (model.endDateForNewExcel != undefined))) {
                                let startDateNew = model.startDateForNewExcel.split("/")
                                startDateNew = new Date(startDateNew[2] + "-" + startDateNew[1] + "-" +startDateNew[0])
                                let endDateNew = model.endDateForNewExcel.split("/")
                                endDateNew = new Date(endDateNew[2] + "-" + endDateNew[1] + "-" +endDateNew[0])
                                const diffTime = Math.abs(endDateNew - startDateNew);
                                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                                if (diffDays > 370) {
                                  openSnackbar("error","Seçilen Tarihler Arası 1 Yıldan Büyük Olamaz", 2000);
                                  return false;
                                }
                                if (startDateNew > endDateNew) {
                                  openSnackbar("error","Başlangıç Tarihi, Bitiş Tarihinden Büyük Olamaz",2000);
                                  return false;
                                }
                              }
                            }
                            
                            showLoader('Dosyanız Hazırlanıyor')
                            sendRequest(Enum.requestType.POST,"jetTestRounding","",
                              {
                                startDate: (window.$cookies.get("selectedItem") == 'null' || window.$cookies.get("selectedItem") == null)  ? model.startDateForNewExcel : window.$cookies.get("selectedItem").preriorDate, 
                                endDate: (window.$cookies.get("selectedItem") == 'null' || window.$cookies.get("selectedItem") == null) ? model.endDateForNewExcel : window.$cookies.get("selectedItem").auiditDate,
                                excelDownload : true,
                                valueForRounding: model.valueForRounding,
                                choice : "jetTestTable",
                                cookieData: window.$cookies.get("selectedItem")
                              },
                              function (response) {
                                hideLoader()
                                openSnackbar("success", response.data.data.value);
                                that.$router.push('folderNotification')
                              },
                              function (error) {
                                openSnackbar(
                                  "error",
                                  error.response.data.error[0].detail.message
                                );
                                hideLoader();
                              }
                            )
                            closeDialog();
                          },
                        },  
                      ],
                      layout: [
                        {
                          type: Enum.layoutType.div,
                          name: "infoTableDialog",
                          dialogVisible: "dialogVisible",
                          inputElements: [
                            [
                             {
                                id : "startDateForNewExcel",
                                type : "v-date-picker",
                                readonly: true,
                                variant : "text",
                                disabled: false,
                                name : "startDateForNewExcel",
                                label : "Başlangıç Tarihi",
                                value : "",
                                prependIcon : "fas fa-at",
                                placeHolder : "",
                                style : "width: 260px;",
                                classTag : "",
                                onClick : function(thisData,model,dynamicElements) {
                                  console.log(thisData,model,dynamicElements);
                                }
                              },
                            ],
                            [
                             {
                                id : "endDateForNewExcel",
                                type : "v-date-picker",
                                variant : "text",
                                readonly: true,
                                name : "endDateForNewExcel",
                                label : "Bitiş Tarihi",
                                value : "",
                                disabled: false,
                                prependIcon : "fas fa-at",
                                placeHolder : "",
                                style : "width: 260px;",
                                classTag : "",
                                onClick : function(thisData,model,dynamicElements) {
                                  console.log(thisData,model,dynamicElements);
                                }
                              },
                            ],
                            [
                             {
                                id : "valueForRounding",
                                type : "v-text-field",
                                variant : "text",
                                name : "valueForRounding",
                                label : "Performans Önemlilik Seviyesi",
                                value : "",
                                placeHolder : "",
                                style : "width: 260px;",
                                classTag : "",
                                onClick : function(thisData,model,dynamicElements) {
                                  console.log(thisData,model,dynamicElements);
                                }
                              },
                            ],
                          ],
                        }
                      ],
                      titleActions: [
                        {
                          id: "close",
                          type: "v-btn",
                          variant: "btn",
                          name: "close",
                          divStyle: "",
                          inputText: "Kapat",
                          btnTypeText: true,
                          color: "red",
                          classTag: "",
                          icon: "mdi-close",
                          closeBtn: true,
                          onClick: function(thisData, model, dialogInfo) {
                            console.log(thisData, model, dialogInfo);
                            closeDialog();
                          },
                        },
                      ],
                    }
                    if ((window.$cookies.get("selectedItem") == 'null') || (window.$cookies.get("selectedItem") == null) || (window.$cookies.get("selectedItem") == "null")) {
                      dialogInfo.layout[0].inputElements[0][0].disabled = false
                      dialogInfo.layout[0].inputElements[1][0].disabled = false
                    } else {
                      dialogInfo.layout[0].inputElements[0][0].disabled = true
                      dialogInfo.layout[0].inputElements[1][0].disabled = true
                    }
                    showDialog(dialogInfo);
                  },
                }
              ],
              [
                {
                  id: "tableDialogBtn",
                  type: "v-btn",
                  variant: "btn",
                  name: "tableDialogBtn",
                  style: "height: 25px !important;",
                  inputText: "Sütun Ekle",
                  divStyle: "",
                  btnTypeText: false,
                  color: "primary",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    console.log(
                      "send delete" + thisData + dynamicElements,
                      model
                    );
                    model = getTable(that.renderTableName);
                    let dialogInfo = {
                      name: "infoTableDialog",
                      maxWidth: "700",
                      dialogVisible: "dialogVisible",
                      title: "Ekstra Getirilmesi İstenilen Sütunlar",
                      layout: [
                        {
                          type: Enum.layoutType.div,
                          name: "tableDialog",
                          inputElements: [
                            [
                              {
                                id: "kebir",
                                type: "v-checkbox",
                                name: "kebir",
                                label: "Kebir",
                                value: false,
                              },
                              // {
                              //   id: "complementaryAccountCode",
                              //   type: "v-checkbox",
                              //   name: "complementaryAccountCode",
                              //   label: "Tamamlayıcı Hesap Detay",
                              //   value: false,
                              // },
                            ],
                            [
                              {
                                id: "month",
                                type: "v-checkbox",
                                name: "month",
                                label: "Ay Kolonu",
                                value: false,
                              },
                              // {
                              //   id: "counterAccount",
                              //   type: "v-checkbox",
                              //   name: "counterAccount",
                              //   label: "Karşı Hesap Detay",
                              //   value: false,
                              // },
                            ]
                          ],
                        },
                      ],
                      actions: [
                        {
                          id: "save",
                          type: "v-btn",
                          variant: "btn",
                          name: "save",
                          inputText: "Kaydet",
                          divStyle: "",
                          btnTypeText: true,
                          color: "green darken-1",
                          classTag: "",
                          onClick: function(thisData, model, dialogInfo) {
                            let showOrHideTableColoumn = ["complementaryAccountCode" , "counterAccount" , "month" , "kebir"]
                            model = getTableModel(that.renderTableName);
                            for (let index = 0; index < showOrHideTableColoumn.length; index++) {
                              if(model[showOrHideTableColoumn[index]]){
                                showTableColoumn(that.renderTableName , showOrHideTableColoumn[index])
                              }else{
                                hideTableColoumn(that.renderTableName , showOrHideTableColoumn[index])
                              }
                            }
                            console.log(thisData, model, dialogInfo);
                            
                            closeDialog();
                          },
                        },
                      ],
                    };
                    showDialog(dialogInfo);
                  },
                },
              ],
              // [
              //   {
              //     id: "search",
              //     type: "v-btn",
              //     variant: "btn",
              //     name: "search",
              //     inputText: "searchBtnText",
              //     style: "height: 25px !important;",
              //     divStyle: "",
              //     btnTypeText: false,
              //     color: "primary",
              //     prependIcon: "",
              //     classTag: "",
              //     onClick: function(thisData, model, dynamicElements) {
              //       let dynamicElement = getTable(that.renderTableName);
              //       if (dynamicElement.data.length > 0) {
              //         console.log(thisData);
              //         console.log(dynamicElements);
              //         console.log(model);
              //         showLoader("Verileriniz Getiriliyor.Lütfen Bekleyiniz.")
              //         openTablePage(that.renderTableName);
              //         hideLoader()
              //       } else {
              //         alert("Veriniz mevcut değildir");
              //       }
              //     },
              //   },
              // ],
              
              // [
              //   {
              //     id: "filter",
              //     type: "v-btn",
              //     variant: "btn",
              //     name: "filter",
              //     inputText: "filterBtnText",
              //     style: "height: 25px !important;",
              //     divStyle: "",
              //     btnTypeText: false,
              //     color: "primary",
              //     prependIcon: "",
              //     classTag: "",
              //     onClick: function(thisData, model, dynamicElements) {
              //       console.log(thisData);
              //       console.log(dynamicElements);
              //       console.log(model);

              //       var tableObject = getTable(that.renderTableName);
              //       if (thisData.color == "primary") {
              //         thisData.color = "red";
              //         for (
              //           let index = 0;
              //           index < tableObject.headers.length;
              //           index++
              //         ) {
              //           tableObject.headers[index].search = false;
              //           tableObject.headers[index].items = [];
              //         }
              //       } else {
              //         thisData.color = "primary";
              //         for (
              //           let index = 0;
              //           index < tableObject.headers.length;
              //           index++
              //         ) {
              //           tableObject.headers[index].search = true;
              //         }
              //       }
              //     },
              //   },
              // ],
              
              // [
              //   {
              //     id: "email",
              //     type: "v-btn",
              //     variant: "btn",
              //     name: "email",
              //     inputText: "emailBtnText",
              //     style: "height: 25px !important;",
              //     divStyle: "",
              //     btnTypeText: false,
              //     icon: "mark_email_read",
              //     color: "primary",
              //     prependIcon: "",
              //     classTag: "",
              //     onClick: function(thisData, model, dynamicElements) {
              //       console.log(thisData, model, dynamicElements);
              //     },
              //   },
              // ],
              // [
              //   {
              //     id: "excelDownload",
              //     type: "v-btn",
              //     variant: "btn",
              //     name: "excelDownload",
              //     inputText: "excelDownloadBtnText",
              //     style: "height: 25px !important;",
              //     divStyle: "",
              //     icon: "mdi-file-excel",
              //     btnTypeText: false,
              //     color: "success",
              //     prependIcon: "",
              //     classTag: "",
              //     onClick: function(thisData, model, dynamicElements) {
              //       showLoader('Dosyanız Hazırlanıyor')
              //       sendRequest(Enum.requestType.POST,"muavinDownload","",
              //         {
              //           //startDate: model.startDate, endDate: model.endDate,
              //           excelDownload : true,
              //           choice : "muavinViewTable"
              //         },
              //         function (response) {
              //           let zipName = "Rapor Excel"
              //           axiosUploadTypeArrayBuffer("allExcelZipDownload", "muavinViewTable", zipName, "Excel İndirildi")
              //         },
              //         function (error) {
              //           openSnackbar(
              //             "error",
              //             error.response.data.error[0].detail.message
              //           );
              //           hideLoader();
              //         }
              //       )
              //     },
              //   },
              // ],
              [
                {
                  id: "enterDate",
                  type: "v-btn",
                  variant: "btn",
                  name: "enterDate",
                  inputText: "Muavin İndir",
                  icon: "mdi-file-excel",
                  style:"height: 25px !important;",
                  divStyle: "",
                  btnTypeText: false,
                  color: "success",
                  prependIcon: "",
                  classTag: "",
                  onClick: function() {
                    let dialogInfo = {
                      name: "muavinTarihliExcel",
                      maxWidth: "700",
                      title: "Muavin Excel İndir",
                      actions: [
                        {
                          id: "excelDownload",
                          type: "v-btn",
                          variant: "btn",
                          name: "excelDownload",
                          divStyle: "",
                          inputText: "İndir",
                          btnTypeText: true,
                          color: "success",
                          classTag: "",
                          icon: "mdi-file-excel",
                          closeBtn: true,
                          onClick: function(thisData, model, dialogInfo) {
                            if (model.startDateForNewExcel === "" && model.endDateForNewExcel === "") {
                              openSnackbar("error","Girilen Tarih Değerleri Boş Olamaz");
                              return false;
                            }
                            if (((model.startDateForNewExcel != undefined) && (model.endDateForNewExcel != undefined))) {
                              let startDateNew = model.startDateForNewExcel.split("/")
                              startDateNew = new Date(startDateNew[2] + "-" + startDateNew[1] + "-" +startDateNew[0])
                              let endDateNew = model.endDateForNewExcel.split("/")
                              endDateNew = new Date(endDateNew[2] + "-" + endDateNew[1] + "-" +endDateNew[0])
                              const diffTime = Math.abs(endDateNew - startDateNew);
                              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                              if (diffDays > 370) {
                                openSnackbar("error","Seçilen Tarihler Arası 1 Yıldan Büyük Olamaz", 2000);
                                return false;
                              }
                              if (startDateNew > endDateNew) {
                                openSnackbar("error","Başlangıç Tarihi, Bitiş Tarihinden Büyük Olamaz",2000);
                                return false;
                              }
                            }
                            showLoader('Dosyanız Hazırlanıyor')
                            sendRequest(Enum.requestType.POST,"queueGeneralController","",
                              {
                                startDate: model.startDateForNewExcel, endDate: model.endDateForNewExcel,
                                startingKebir: model.ledgerOne,
                                endingKebir: model.ledgerTwo,
                                excelDownload : true,
                                choice : "muavinTable",
                                optionsChoice: "MuavinDownload",
                                cookieData: window.$cookies.get("selectedItem")
                              },
                              function (response) {
                                that.$router.push("folderNotification")
                              },
                              function (error) {
                                openSnackbar(
                                  "error",
                                  error.response.data.error[0].detail.message
                                );
                                hideLoader();
                              }
                            )
                            closeDialog();
                          },
                        },  
                      ],
                      layout: [
                        {
                          type: Enum.layoutType.div,
                          name: "infoTableDialog",
                          dialogVisible: "dialogVisible",
                          inputElements: [
                            [
                             {
                                id : "startDateForNewExcel",
                                type : "v-date-picker",
                                readonly: true,
                                variant : "text",
                                name : "startDateForNewExcel",
                                label : "Başlangıç Tarihi",
                                value : "",
                                prependIcon : "fas fa-at",
                                placeHolder : "",
                                style : "width: 260px;",
                                classTag : "",
                                onClick : function(thisData,model,dynamicElements) {
                                  console.log(thisData,model,dynamicElements);
                                }
                              },
                            ],
                            [
                             {
                                id : "endDateForNewExcel",
                                type : "v-date-picker",
                                variant : "text",
                                readonly: true,
                                name : "endDateForNewExcel",
                                label : "Bitiş Tarihi",
                                value : "",
                                prependIcon : "fas fa-at",
                                placeHolder : "",
                                style : "width: 260px;",
                                classTag : "",
                                onClick : function(thisData,model,dynamicElements) {
                                  console.log(thisData,model,dynamicElements);
                                }
                              },
                            ],
                            [
                              {
                                id : "ledgerOne",
                                type : "v-text-field",
                                variant : "text",
                                name : "ledgerOne",
                                label : "Başlangıç Kebir",
                                value : "",
                                placeHolder : "",
                                style : "width: 140px;",
                                classTag : "",
                              },
                              {
                                id : "ledgerTwo",
                                type : "v-text-field",
                                variant : "text",
                                name : "ledgerTwo",
                                label : "Bitiş Kebir",
                                value : "",
                                placeHolder : "",
                                style : "width: 140px;",
                                classTag : "",
                              },
                            ],
                          ],
                        }
                      ],
                      titleActions: [
                        {
                          id: "close",
                          type: "v-btn",
                          variant: "btn",
                          name: "close",
                          divStyle: "",
                          inputText: "Kapat",
                          btnTypeText: true,
                          color: "red",
                          classTag: "",
                          icon: "mdi-close",
                          closeBtn: true,
                          onClick: function(thisData, model, dialogInfo) {
                            console.log(thisData, model, dialogInfo);
                            closeDialog();
                          },
                        },
                      ],
                    }
                    showDialog(dialogInfo);
                    // if (model.startDate === "" && model.endDate === "") {
                    //       openSnackbar("error","Girilen Tarih Değerleri Boş Olamaz");
                    //       return false;
                    //     }
                    // if (((model.startDate != undefined) && (model.endDate != undefined))) {
                    //     let startDateNew = model.startDate.split("/")
                    //     startDateNew = new Date(startDateNew[2] + "-" + startDateNew[1] + "-" +startDateNew[0])
                    //     let endDateNew = model.endDate.split("/")
                    //     endDateNew = new Date(endDateNew[2] + "-" + endDateNew[1] + "-" +endDateNew[0])
                    //     const diffTime = Math.abs(endDateNew - startDateNew);
                    //     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

                    //     if (diffDays > 370) {
                    //       openSnackbar("error","Seçilen Tarihler Arası Bir Yıldan Büyük Olamaz", 2000);
                    //       return false;
                    //     }
                    //     if (startDateNew > endDateNew) {
                    //       openSnackbar("error","Başlangıç Tarihi, Bitiş Tarihinden Büyük Olamaz",2000);
                    //       return false;
                    //     }
                    //   }
                    // showLoader('Dosyanız Hazırlanıyor')
                    // sendRequest(Enum.requestType.POST,"postMizanDates","",
                    //   {
                    //     startDate: model.startDate, endDate: model.endDate,
                    //     excelDownload : true,
                    //     choice : "mizanViewTable"
                    //   },
                    //   function (response) {
                    //     let zipNameStartDate = model.startDate.replaceAll("/", "_")
                    //     let zipNameEndDate = model.endDate.replaceAll("/", "_")
                    //     let zipName = "Rapor Excel" + zipNameStartDate + "ve" + zipNameEndDate
                    //     axiosUploadTypeArrayBuffer("allExcelZipDownload", "mizanViewTable", zipName, "Excel İndirildi")
                    //   },
                    //   function (error) {
                    //     openSnackbar(
                    //       "error",
                    //       error.response.data.error[0].detail.message
                    //     );
                    //     hideLoader();
                    //   }
                    // )
                  },
                }
              ],
              // [
              //   {
              //     id: "periodSelected",
              //     type: "v-btn",
              //     variant: "btn",
              //     name: "periodSelected",
              //     inputText: "Periyod Seç",
              //     editable: false,
              //     style: "height: 25px !important; color: white;",
              //     creatable: false,
              //     color: "blue",
              //     inlineEditable: false,
              //     value: "receiptKind",
              //     dropDownItems: this.periodsArray,
              //     multiple: false,
              //     onClick: function(thisData, model, dynamicElements) {
              //       debugger
              //       sendRequest(Enum.requestType.POST, "getperiodmuavin","",{cookieData: window.$cookies.get("selectedItem")},function (res) {
              //       let responsePeriod = res.data.data.value
              //       debugger
              //       if (responsePeriod.length > 0) {
              //         that.periodsArray = responsePeriod
              //         that.selectPeriodForTableDialog = true
              //       }
              //     })
              //       // let periodSelected = model.receiptTypeModel
              //       // dynamicElements.initialFilter = {
              //       //   [DideroEnum.initialFilter.AND] :
              //       //     [
              //       //       {
              //       //         fake_period: {
              //       //           [DideroEnum.initialFilter.EQ]: einvoiceType
              //       //         },
              //       //       }
              //       //     ]
              //       // }
              //       // openTablePage(that.renderTableName);
              //       // sendRequest(Enum.requestType.POST, "gettabledata", "",{ tableName2 : 'eBillUniqTable', e_invoice_type : model.receiptTypeModel},
              //       //   function (response) {
              //       //     hideLoader()
              //       //     openTablePage("eBillUniqTable");
              //       //   },
              //       //   function (error) {
              //       //     console.log(error)
              //       //     hideLoader()
              //       //   }
              //       // );
              //     }
              //   },
              // ],
              [
                {
                  id: "deleteMuavin",
                  type: "v-btn",
                  variant: "btn",
                  name: "deleteMuavin",
                  inputText: "Muavini Sil",
                  style:"height: 25px !important; color: white;",
                  divStyle: "",
                  btnTypeText: false,
                  color: "red",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model) {
                    let dialogInfo = {
                      name: "deleteMuavin",
                      maxWidth: "100%",
                      title: "Muavini Komple Silmek İstediğinize Emin Misiniz? Sil Tuşuna Basarsanız Muavin Tamamen Silinecek.",
                      actions: [
                        {
                          id: "deleteMuavin",
                          type: "v-btn",
                          variant: "btn",
                          name: "deleteMuavin",
                          divStyle: "",
                          inputText: "Sil",
                          btnTypeText: true,
                          color: "success",
                          classTag: "",
                          closeBtn: true,
                          onClick: function(thisData, model, dialogInfo) {
                            showLoader('Veriler Siliniyor')
                            sendRequest(Enum.requestType.POST, "deleteMuavinQueue", "", {cookieData: window.$cookies.get("selectedItem")}, 
                              function (response) {
                                openSnackbar("success", response.data.data.value);
                                that.$router.push('folderNotification')
                                // kuyruksuzlar
                                //openSnackbar("success", "Verileriniz başarı ile silindi", 2000)
                                //openTablePage(that.renderTableName);
                                //hideLoader();
                              },
                              function (error) {
                                openSnackbar("success", response.data.data.value);
                                that.$router.push('folderNotification')
                                // kuyruksuzlar
                                //errFunc(error);
                                //openTablePage(that.renderTableName);
                                //openSnackbar("error", "Verileriniz silinirken bir hata oluştu", 2000)
                              }
                            )
                            closeDialog();
                          },
                        },  
                      ],
                      titleActions: [
                        {
                          id: "close",
                          type: "v-btn",
                          variant: "btn",
                          name: "close",
                          divStyle: "",
                          inputText: "Kapat",
                          btnTypeText: true,
                          color: "red",
                          classTag: "",
                          icon: "mdi-close",
                          closeBtn: true,
                          onClick: function(thisData, model, dialogInfo) {
                            console.log(thisData, model, dialogInfo);
                            closeDialog();
                          },
                        },
                      ],
                    }
                    showDialog(dialogInfo);
                  },
                }
              ],
              [
              {
                id : "deletePeriod",
                type : "v-btn",
                variant : "btn",
                name : "deletePeriod",
                inputText : "Dönem Sil",
                divStyle:"",
                style:"height: 25px !important;",
                btnTypeText: false,
                color : "error",
                prependIcon : "",
                classTag : "",
                onClick : function(thisData,model,dynamicElements) {
                  console.log("send delete" + thisData + dynamicElements)
                  // let options = {
                  //   showDialog : true
                  // }
                  // deleteItem(that.renderTableName,[],options,model)
                  //
                  //selectPeriodForDeleteDialog true yapmadna önce periodları çekelim .
                  
                  sendRequest(Enum.requestType.POST, "getperiodmuavin","",{cookieData: window.$cookies.get("selectedItem")},function (res) {
                    let responsePeriod = res.data.data.value
                    if (responsePeriod.length > 0) {
                      that.periodItemsForDelete = responsePeriod
                      that.selectPeriodForDeleteDialog = true
                    }
                  })
                }
              }
              ],
              [
                {
                  id: "clear",
                  type: "v-btn",
                  variant: "btn",
                  name: "clear",
                  inputText: "clearSearchBtnText",
                  style: "height: 25px !important;",
                  divStyle: "",
                  btnTypeText: false,
                  color: "primary",
                  prependIcon: "",
                  classTag: "",
                  onClick: function(thisData, model, dynamicElements) {
                    let dynamicElement = getTable(that.renderTableName);
                    console.log(thisData,dynamicElements,model);
                    dynamicElement.filteredColumn = {};
                    openTablePage(that.renderTableName);
                  },
                },
              ],
            ],
          },
        ],
        //  { text: "Aktif/Pasif", 
        //     value: "isActive", 
        //     width: "110", 
        //     search: false, 
        //     sortable: true, 
        //     divider: true, 
        //     switchValue: {1 : "Aktif", 2 : "Pasif"}, 
        //     items: [{name : "Aktif", value : 1}, {name : "Pasif", value : 2}]
        //   },
        headers: [
          { text: "Kebir", value: "kebir", width: "100", search: true , sortable: false, visible: false , divider: true},
          { text: "Aktif/Pasif", 
            value: "isActive", 
            search: false,
            sortable: true, 
            divider: true,
            height:"100vh",
            switchValue: {1 : "Aktif", 2 : "Pasif"}, 
            items: [{name : "Aktif", value : 1}, {name : "Pasif", value : 2}]
          },
          {
            text: "Hesap Kodu",
            value: "accountCode",
            width: "150",
            search: true,
            sortable: true,
            divider: true,
          },
          {
            text: "Hesap Adı",
            value: "accountName",
            width: "150",
            search: true,
            divider: true,
            class : 'threedot'
          },
          { 
            text: "Ay", 
            value: "month", 
            width: "100", 
            search: true, 
            sortable: false, 
            visible: false, 
            divider: true
          },
          { 
            text: "Tarih", 
            value: "billDate", 
            width: "90", 
            search: true, 
            divider: true, 
            dataType: "date"
          },
          { 
            text: "Periyod",
            items: that.periodsArray,
            search: false,
            value: "fakePeriod",
            width: "120",  
            divider: true, 
            dataType: "text"
          },
          {
            text: "Fiş Türü",
            value: "receiptType",
            width: "100",
            search: true,
            divider: true
          },
          {
            text: "Fiş Atama",
            items: [{name : "Açılış", value : 1}, {name : "Mahsup", value : 2}, {name : "Kur Farkı", value : 3},{name : "Yansıtma", value : 4}, {name : "Kar Zarar", value : 5}, {name : "Kapanış", value : 6}, {name : "Adat", value : 7}],
            value: "receiptTypeAssign",
            divider: true,
            sortable: true,
            align: "center",
            height:"100vh",
            switchValue: {1 : "Açılış", 2 : "Mahsup", 3: "Kur Farkı", 4: "Yansıtma", 5: "Kar Zarar Virman", 6: "Kapanış", 7: "Adat"}
          },
          {
            text: "Fiş No",
            value: "receiptNumber",
            width: "100",
            search: true,
            divider: true
          },
          { 
            text: "Karşı Hesap Detay", 
            value: "counterAccount", 
            width: "160", 
            sortable: true, 
            search: true, 
            class : 'threedot', 
            visible : false 
          },
          { 
            text: "Tamamlayıcı Hesap Detay", 
            value: "complementaryAccountCode",
            width: "160", 
            sortable: true, 
            search: true, 
            class : 'threedot', 
            visible : false 
          },
          {
            text: "Açıklama",
            value: "explanation",
            width: "150",
            search: true,
            class: "threedot",
            divider: true
          },
          {
            text: "Borç",
            value: "debt",
            width: "100",
            align: "right",
            search: true,
            divider: true, 
            dataType: "currency"
          },
          {
            text: "Alacak",
            value: "credit",
            width: "100",
            align: "right",
            search: true,
            divider: true, 
            dataType: "currency"
          },
          {
            text: "Bakiye",
            value: "balance",
            width: "100",
            align: "right",
            search: true,
            divider: true, 
            dataType: "currency"
          },
          {
            text: "Fatura No",
            value: "billNo",
            width: '50',
            search: true,
            divider: true,
            class: "threedot"
          },
          {
            text: "RD Kuru",
            value: "reportExchangeRate",
            width: "100",
            align: "right",
            search: true,
            divider: true, 
            dataType: "currency"
          },
          {
            text: "R. Döv. Tutarı",
            value: "reportCurrencyAmount",
            width: "150",
            align: "right",
            search: true,
            divider: true, 
            dataType: "currency"
          },
          {
            text: "R. Döv. Bakiye",
            value: "reportCurrencyBalance",
            width: "150",
            align: "right",
            search: true,
            divider: true, 
            dataType: "currency"
          },
          { text: "Döviz Adı", value: "currency", width: "120", search: true, divider: true },
          {
            text: "İşlem Döviz Kuru",
            value: "exceptionExchangeRate",
            width: "100",
            align: "right",
            search: true,
            divider: true, 
            dataType: "currency"
          },
          {
            text: "İşlem Döviz Tutarı",
            value: "exceptionCurrencyAmount",
            width: "100",
            align: "right",
            search: true,
            divider: true, 
            dataType: "currency"
          },
          {
            text: "İşlem Döviz Bakiye",
            value: "exceptionCurrencyBalance",
            width: "100",
            align: "right",
            search: true,
            divider: true, 
            dataType: "currency"
          }
        ],
        columns: {
          receiptNumber: {
            type: "v-text-field",
            editable: false,
            creatable: true,
            inlineEditable: false,
            //cell için gerekli inputElement hücre özelliştirmesi için
            cellElement: {
              id: "receiptNumber",
              type: "v-btn",
              variant: "btn",
              name: "receiptNumber",
              inputText: "receiptNumber",
              divStyle: "",
              style: "height:0px;text-decoration: underline;color: blue !important;",
              btnTypeText: true,
              color: "black",
              prependIcon: "",
              classTag: "",
              onClick: function(thisData, model, dynamicElements) {
                console.log(thisData, model, dynamicElements);
                let dialogInfo = {
                  name: "infoTableDialog",
                  title: model.receiptNumber + " " + "Yevmiye Fişi",
                  layout: [
                    {
                      type: Enum.layoutType.div,
                      name: "tableDialog",
                      tableObject: {
                        tableHeight: "400",
                        dialogTable: true,
                        renderKey: 50,
                        type: "table",
                        name: "infoTable",
                        class: "",
                        infoTotalRowCountHeaderAssistantTable: "Toplam satır sayısı",
                        selectionUrl: "getassistantuploadaccountcodes",
                        hidePagination: false,
                        initialFilter:{
                          [Enum.initialFilter.AND] :
                            [
                              {
                                projectId: {
                                  [Enum.initialFilter.EQ]: window.$cookies.get("selectedItem") == null ? undefined : window.$cookies.get("selectedItem").id
                                }
                              }
                            ]
                        },
                        filteredColumn: {
                          receiptNumber: model.receiptNumber,
                          assistantDate: model.billDate,
                        },
                        toolbars: [],
                        headers: [
                          {
                            text: "Hesap Kodu",
                            value: "accountCode",
                            width: "150",
                            search: true,
                            sortable: true,
                            divider: true, 
                          },
                          {
                            text: "Hesap Adı",
                            value: "accountName",
                            search: true,
                            class: 'threedot',
                            divider: true, 
                          },
                          {
                            text: "Tarih",
                            value: "billDate",
                            width: "120",
                            search: true,
                            dataType: "date",
                            divider: true, 
                          },
                          {
                            text: "Fiş No",
                            value: "receiptNumber",
                            width: "160",
                            search: true,
                            divider: true, 
                          },
                          {
                            text: "Açıklama",
                            value: "explanation",
                            search: true,
                            class : 'threedot',
                            divider: true, 
                          },
                          {
                            text: "Borç",
                            value: "debt",
                            width: "150",
                            align: "right",
                            search: true,
                            dataType: "currency",
                            divider: true, 
                          },
                          {
                            text: "Alacak",
                            value: "credit",
                            width: "150",
                            align: "right",
                            search: true,
                            dataType: "currency",
                            divider: true, 
                          },
                          {
                            text: "Bakiye",
                            value: "balance",
                            width: "150",
                            align: "right",
                            search: true,
                            dataType: "currency",
                            divider: true, 
                          }
                        ],
                        footer: [],
                        singleSelect: true,
                        itemKey: "id",
                        columns: {},
                      },
                    },
                  ],
                  actions: [
                  ],
                  titleActions: [
                    {
                      id: "close",
                      type: "v-btn",
                      variant: "btn",
                      name: "close",
                      divStyle: "",
                      inputText: "Kapat",
                      btnTypeText: true,
                      color: "red",
                      classTag: "",
                      icon: "mdi-close",
                      closeBtn: true,
                      onClick: function(thisData, model, dialogInfo) {
                        console.log(thisData, model, dialogInfo);
                        closeDialog();
                      },
                    },
                  ],
                };
                //var dialogObject = getTableModel(that.renderTableName);
                // var tableObject = getTable(that.renderTableName);
                // tableObject.filteredColumn.receiptNumber = model.receiptNumber
                // tableObject.filteredColumn.assistantDate = model.billDate
                showDialog(dialogInfo);
              },
            },
          },
          receiptTypeAssign: {
            type: "v-select",
            variant: "number",
            editable: false,
            creatable: true,
            inlineEditable: false,
            //value: ['Aktif'],
            label: "Fiş Türü",
            dropDownItems: ["Açılış", "Mahsup", "Kur Farkı", "Yansıtma", "Kar Zarar Virman", "Kapanış", "Adat"],
          },
          complementaryAccountCode: {
            type: "v-text-field",
            editable: false,
            creatable: true,
            inlineEditable: true,
          },
          counterAccount: {
            type: "v-text-field",
            variant: "number",
            editable: false,
            creatable: true,
          },
          month: {
            type: "v-text-field",
            variant: "number",
            editable: false,
            creatable: true,
          },
          kebir: {
            type: "v-text-field",
            variant: "number",
            editable: false,
            creatable: true,
          }
        },

        data: "data",
        showSelecetVisible: false,
        singleSelect: true,
        itemKey: "id",
        footer: [
            {
              type: Enum.layoutType.div,
              name: "footerForm",
              inputElements: [
                [
                  {
                    id: "pageSize",
                    type: "v-text-field",
                    variant: "number",
                    name: "pageSize",
                    label: "Görünecek Satır Sayısı",
                    placeHolder: "",
                    style: "width: 45%; margin-left: 5%",
                    classTag: "",
                    onChange: function(thisData, model, dynamicElements) {
                      openTablePage(that.renderTableName);
                      console.log(thisData);
                      console.log(dynamicElements);
                      console.log(model);
                    },
                  },
                ],
                [
                  {
                    id: "currentPageNo",
                    type: "v-pagination",
                    variant: "pagination",
                    name: "currentPageNo",
                    divStyle: "",
                    style: "width:850px;",
                    classTag: "my-1",
                    length: "pagesCount",
                    onInput: function(thisData, model, dynamicElements) {
                      openTablePage(that.renderTableName);
                      console.log(thisData);
                      console.log(dynamicElements);
                      console.log(model);
                    },
                  },
                ],
                [
                  {
                    id: "infoTotalRowCountHeaderAssistantTable",
                    type: "p",
                    variant: "p",
                    name: "infoTotalRowCountHeaderAssistantTable",
                    inputText: "infoTotalRowCountHeaderAssistantTable",
                    divStyle: "",
                    style:
                      "font-size: 12px;font-weight:500;margin:3px 0;color:#666A6F;margin-right: 2%;margin-left: 60%;",
                    classTag: "",
                  },
                  {
                    id: "infoTotalRowCountAssistantTable",
                    type: "p",
                    variant: "p",
                    name: "infoTotalRowCountAssistantTable",
                    inputText: "itemCount",
                    divStyle: "",
                    style:
                      "font-size: 15px;font-weight:500;margin:3px 0;color:#666A6F;margin-left: 70%;",
                    classTag: "",
                  },
                ],
              ],
            },
        ]
      };
    },
  },
};
</script>

<style>
::v-deep .threedot {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 200px;
}
::v-deep .threedot:hover {
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}
</style>
